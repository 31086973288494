import React from 'react';

export const GrayTimerIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    data-testid="pendingVerificationIcon"
    xmlns="http://www.w3.org/2000/svg"
    width={'14'}
    height={'14'}
    viewBox={'0 0 14 14'}
    fill="none"
    {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29917 13.4001C10.8338 13.4001 13.6992 10.5347 13.6992 7.0001C13.6992 3.46548 10.8338 0.600098 7.29917 0.600098C3.76455 0.600098 0.89917 3.46548 0.89917 7.0001C0.89917 10.5347 3.76455 13.4001 7.29917 13.4001ZM8.29917 3.8001C8.29917 3.24781 7.85145 2.8001 7.29917 2.8001C6.74689 2.8001 6.29917 3.24781 6.29917 3.8001V7.0001C6.29917 7.26531 6.40453 7.51967 6.59206 7.7072L8.85481 9.96995C9.24533 10.3605 9.87849 10.3605 10.269 9.96995C10.6595 9.57942 10.6595 8.94626 10.269 8.55573L8.29917 6.58588V3.8001Z"
      fill="#6B7280"
    />
  </svg>
);
