import React, { useState } from 'react';
import Modal from 'react-modal';
import { ValueProps } from './components/ValueProps';
import { Header } from './components/Header';
import { ModalContainer } from './components/ModalContainer';
import { DocumentIcon } from '../Icons/DocumentIcon';
import { SendIcon } from '../Icons/SendIcon';
import { BonusIcon } from '../Icons/BonusIcon';
import { ButtonContainer } from './components/ButtonsContainer';
import { injectIntl } from 'react-intl';
import { ISIntl } from 'src/i18n';
import { compose } from 'src/util/functions';
import { messages } from './translations';
import { customStyles } from './styles';
import { LocationModel } from 'src/models/LocationModel';
import { inject } from 'mobx-react';

type ReferralModalProps = {
  location: LocationModel;
  intl: ISIntl;
};

const ReferralModal = (props: ReferralModalProps) => {
  const { intl, location } = props;
  const [isOpen, setIsOpen] = useState(true);

  const f = intl.formatMessage;

  const redirectTo = (path: string): void => {
    onClose();
    location.navAndScrollTop(path);
  };

  const onClose = (): void => {
    location.history.replace(window.location.pathname);

    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <ModalContainer style="md:max-w-[726px]">
        <Header
          title={f(messages.youAreAllSet)}
          subTitle={f(messages.friendBonus)}
          onClose={onClose}
        />
        <main>
          <div className="grid grid-rows-3 md:grid-cols-2 md:grid-rows-2 gap-4 md:gap-5 mt-3">
            <ValueProps
              title={f(messages.freeDocuments)}
              description={f(messages.freeDocumentsDescription)}
              icon={<DocumentIcon />}
            />
            <ValueProps
              title={f(messages.sendDocument)}
              description={f(messages.sendDocumentDescription)}
              icon={<SendIcon />}
            />
            <div className="md:col-span-2">
              <ValueProps
                title={f(messages.referralBonus)}
                description={f(messages.referralBonusDescription)}
                icon={<BonusIcon />}
              />
            </div>
          </div>
        </main>
        <ButtonContainer
          primaryText={f(messages.claimOffer)}
          secondaryText={f(messages.createInvoice)}
          primaryHandleClick={() => redirectTo('subscription')}
          secondaryHandleClick={() => redirectTo('invoiceCreate')}
        />
      </ModalContainer>
    </Modal>
  );
};

export default compose(injectIntl, inject('location'))(ReferralModal);
