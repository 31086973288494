import React from 'react';
import UserModel from '../models/UserModel';
import { observer } from 'mobx-react';

export const isExperimentEnabled = (experimentName: string): boolean => {
  const user = UserModel.getInstance();
  return !!(
    user &&
    user.abTests &&
    user.abTests[experimentName] &&
    user.abTests[experimentName] === 1
  );
};

export const invokeIfExperimentIsEnabled = (experimentName: string, fn: Function) => {
  if (isExperimentEnabled(experimentName)) {
    fn();
  }
};

/**
 * Returns a React element
 * - `variantView` if experiment `experimentName` is enabled for the current user
 * - `controlView` otherwise
 *
 * @param experimentName - experimentName
 * @param controlView - React Component to show if an experiment is disabled
 * @param variantView - React Component to show if an experiment is enabled
 */
export const showVariantForExperiment = ({
  experimentName,
  controlView,
  variantView
}: {
  experimentName: string;
  controlView: React.ComponentType | null;
  variantView: React.ComponentType | null;
}) =>
  observer(({ children, ...rest }) => {
    if (isExperimentEnabled(experimentName)) {
      return variantView === null ? null : React.createElement(variantView, { ...rest }, children);
    }
    return controlView === null ? null : React.createElement(controlView, { ...rest }, children);
  });

export const hideIfExperimentIsEnabled =
  (experimentName: string) => (Component: React.ComponentType) =>
    showVariantForExperiment({
      experimentName,
      controlView: Component,
      variantView: null
    });

export const showIfExperimentIsEnabled =
  (experimentName: string) => (Component: React.ComponentType) =>
    showVariantForExperiment({
      experimentName,
      controlView: null,
      variantView: Component
    });

export const flattenExperiments = (experiments: { [key: string]: any } = {}): {} => {
  if (!experiments) {
    return {};
  }
  return Object.keys(experiments).reduce((agg, key) => {
    agg[`web-ab-${key}`] = !!experiments[key];
    return agg;
  }, {});
};
