import React from 'react';
import { injectIntl, defineMessages } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';
import InlineButton from '../Form/InlineButton';

const messages = defineMessages({
  approve: {
    id: 'invoice.mobileEdit.approve',
    defaultMessage: 'confirm'
  },
  cancel: {
    id: 'invoice.mobileEdit.cancel',
    defaultMessage: 'cancel'
  },
  delete: {
    id: 'invoice.mobileEdit.delete',
    defaultMessage: 'delete'
  }
});

@injectIntl
export default class ConfirmDelete extends React.Component<
  { onConfirm: () => void; intl?: ISIntl },
  {}
> {
  state = {
    showConfirmation: false
  };
  showConfirmation = () => {
    this.setState({
      showConfirmation: true
    });
  };
  cancelDelete = () => {
    this.setState({
      showConfirmation: false
    });
  };
  render() {
    const { showConfirmation } = this.state;
    const { onConfirm } = this.props;
    const { ft } = this.props.intl!;
    if (showConfirmation) {
      return (
        <div className="invoice-item-deletion">
          <InlineButton label={ft(messages.approve)} onClick={onConfirm} />
          <InlineButton label={ft(messages.cancel)} onClick={this.cancelDelete} inverted />
        </div>
      );
    }
    return (
      <InlineButton
        icon="times"
        label={ft(messages.delete)}
        onClick={this.showConfirmation}
        inverted
      />
    );
  }
}
