import React, { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { startPaypalOnboarding } from 'src/payments/utils/apis/paypal.api';
import { onError } from 'src/payments/utils/utils';
import { AppStore } from 'src/stores/AppStore';
import { isMigratedSettingsEnabled } from 'src/util/isMigratedSettingsEnabled';
import { URLQueryParamKeys } from 'src/util/url';

import {
  PaymentEvent,
  PaymentEventLocation,
  PaymentEventLocationType,
  PaymentEventName
} from '../../types';
import { PaymentsInstructionsLink, SupportedPaymentMethods } from '../PaymentBasicElements';
import { messages } from './messages';

interface PaypalInitialToggleProps {
  location: PaymentEventLocationType;
  onTrackEvent: (eventName: PaymentEvent, metadata?: Record<string, number | string>) => void;
  store?: AppStore;
}

export const PaypalInitialToggle = ({
  onTrackEvent,
  location,
  store
}: PaypalInitialToggleProps) => {
  const country = store?.user.countryCode ? store?.user.countryCode.toUpperCase() : '';
  const eventMetaData = country ? { country } : undefined;

  useEffect(() => {
    onTrackEvent(PaymentEventName.initialCtaShown, { ctaType: 'toggle', ...eventMetaData });
  }, []);

  const trackPaymentOnboardingStartEvent = () => {
    onTrackEvent(PaymentEventName.onboardingStart, {
      ctaType: 'toggle',
      ...eventMetaData
    });
  };
  const handleClick = async () => {
    try {
      if (!store?.user.isSignedUp) {
        await trackPaymentOnboardingStartEvent();
        store?.location.nav('signup', { ref: URLQueryParamKeys.OLD_PAYPAL_ONBOARDING });
        return;
      }
      trackPaymentOnboardingStartEvent();
      await startPaypalOnboarding();
    } catch (error) {
      onError({ title: 'Error initiating PayPal onboarding', body: error.message });
    }
  };

  return (
    <div className={location === PaymentEventLocation.promotionBoxSettings ? 'col-lg-10' : ''}>
      <h4>
        <FormattedMessage {...messages.header} />
      </h4>
      <div>
        <div className="setting-form-group form-group row mb-md-4">
          <div className="col-md-6 col-lg-12 d-flex align-items-center">
            <label className="col-form-label">
              <FormattedMessage {...messages.acceptPayments} />
            </label>
            <div className="ml-2 ml-md-3">
              <ToggleSwitch
                data-testid="payment-initial-toggle"
                checked={false}
                onChange={handleClick}
                disabled={false}
              />
            </div>
          </div>
        </div>

        <div className="setting-form-group form-group row mb-md-3">
          <div className="col-md-6 col-lg-12 d-flex align-items-center w-75">
            <SupportedPaymentMethods />
          </div>
        </div>

        {location === PaymentEventLocation.promotionBoxInvoiceEditor && (
          <div className="setting-form-group form-group row mb-md-4">
            <div className="col-md-6 col-lg-12 d-flex align-items-center w-75">
              <PaymentsInstructionsLink
                onClick={() => {
                  if (isMigratedSettingsEnabled(store?.user.countryCode)) {
                    window.location.assign('/settings');
                    return;
                  }
                  store?.nav('settingList');
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
