import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';
import NumericInput from 'react-numeric-input';
import TextareaAutosize from 'react-textarea-autosize';
import ItemListModel from '../models/ItemListModel';
import { ConfirmDeleteItem } from './ConfirmDeleteButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SyncStatusIndicator from 'src/components/SyncStatusIndicator';
import './ItemEdit.scss';
import { injectIntl, defineMessages } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';
import ItemModel from 'src/models/ItemModel';

interface Prop {
  id?: string;
  list: ItemListModel;
  onCancel: (item: ItemModel) => void;
  onLoad: () => void;
  onDeleted: (item: ItemModel) => void;
  intl?: ISIntl;
}

const messages = defineMessages({
  titleNew: {
    id: 'item.edit.title.new',
    defaultMessage: 'New Item'
  },
  titleEdit: {
    id: 'item.edit.title.edit',
    defaultMessage: 'Edit Item {itemName}'
  }
});

@injectIntl
@observer
export default class ItemEdit extends React.Component<Prop, any> {
  componentDidMount() {
    this.props.onLoad();
  }
  toggleDeletedItem = (item: ItemModel) => {
    item.toggleDeleted();
    item.save().then(() => this.props.onDeleted(item));
  };
  render() {
    const f = this.props.intl!.formatMessage;
    const item = this.props.list.item;
    const title =
      item && item.isNew ? f(messages.titleNew) : f(messages.titleEdit, { itemName: item.code });

    const classString = item.delete
      ? 'item-edit item-delete'
      : item.deleted
      ? 'item-edit item-deleted'
      : 'item-edit';

    return (
      <div className="container">
        <div className={classString}>
          <Helmet>
            <title itemProp="name">{title}</title>
          </Helmet>

          <div className="item-edit-header page-header">
            <h1>{title}</h1>
            <SyncStatusIndicator isLoading={item.isLoading} isSaving={item.isSaving} />
          </div>

          <div className="item-edit-body">
            <form>
              <div className="form-group row">
                <label htmlFor="item-code" className="col-md-2 col-form-label">
                  Description
                </label>
                <div className="col-md-10">
                  <input
                    type="text"
                    id="item-name"
                    disabled={item.isLoading}
                    placeholder="Item Description"
                    value={item.code}
                    onChange={(e) => item.setCode(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="item-rate" className="col-md-2 col-form-label">
                  Rate
                </label>
                <div className="col-md-10">
                  <NumericInput
                    id="item-rate"
                    className="item-rate-input"
                    name="itemRate"
                    style={false}
                    type="text"
                    disabled={item.isLoading}
                    placeholder="0.00"
                    precision={2}
                    value={item.userSetRate ? item.rate : undefined}
                    onChange={(v) => item.setRate(v ?? 0)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="item-unit" className="col-md-2 col-form-label">
                  Unit
                </label>
                <div className="col-md-10">
                  <input
                    type="text"
                    id="item-unit"
                    disabled={item.isLoading}
                    placeholder="hours, days"
                    value={item.unit}
                    onChange={(e) => item.setUnit(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="item-description" className="col-md-2 col-form-label">
                  Additional details
                </label>
                <div className="col-md-10">
                  <TextareaAutosize
                    disabled={item.isLoading}
                    style={{ height: 100 }}
                    placeholder="Additional details for Item"
                    id="item-description"
                    className="item-description-input"
                    minRows={4}
                    maxRows={20}
                    value={item.description}
                    onChange={(e) => item.setDescription(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="item-rate" className="col-md-2 col-form-label">
                  Taxable
                </label>
                <div className="col-md-10">
                  {!item.isLoading && item.taxable && (
                    <a
                      id="item-taxable"
                      className="btn-checkbox"
                      onClick={() => item.setTaxable(false)}>
                      <FontAwesomeIcon icon="check-square" />
                    </a>
                  )}
                  {!item.isLoading && !item.taxable && (
                    <a
                      id="item-taxable"
                      className="btn-checkbox"
                      onClick={() => item.setTaxable(true)}>
                      <FontAwesomeIcon icon="square" />
                    </a>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="item-edit-footer">
            <a className="btn btn-cancel" onClick={() => this.props.onCancel(item)}>
              Close
            </a>
            {!item.isNew && (
              <ConfirmDeleteItem
                displayLabel={true}
                disabled={item.isLoading}
                pending={item.delete}
                confirmed={item.deleted}
                onPending={() => item.toggleDelete()}
                onConfirm={() => this.toggleDeletedItem(item)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
