import React, { FC } from 'react';
import {
  ManageAccountButton,
  PaypalSettingsBoxRowWrapper,
  SettingsAccountDetailsSection
} from '../../Common';

import { PaypalAcceptingBox, PaypalCurrencyMismatchBox } from './SupportingComponents';
import { usePaypalContext } from 'src/payments/contexts/PaypalAccountContext';
import { useISIntl } from 'src/i18n';
import { paypalBoxMessages } from '../messages';
import {
  getPaymentEventSource,
  hasCurrencyMismatch,
  redirectToPaypalDashboard
} from 'src/payments/utils';
import { PaymentMethod, useCTAHandler } from '../../useCTAHandler';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';

export const PaypalSettingsAcceptingComponent: FC<{ store: AppStore }> = ({ store: { user } }) => {
  const paypalAccountContext = usePaypalContext();
  const { fta } = useISIntl();
  const { handleAcceptingClick, loading } = useCTAHandler(PaymentMethod.Paypal);

  if (!paypalAccountContext) {
    return null;
  }

  const paypalSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource()
  };

  const {
    isPaypalEnabled,
    getPaypalMerchantId,
    getPaypalAccountCurrency,
    getPaypalAccountEmail,
    updatePaypalEnabled
  } = paypalAccountContext;

  const paypalStatusEnabled = isPaypalEnabled();
  const paypalPrimaryCurrency = getPaypalAccountCurrency();
  const isPaypalCurrencyMismatch = hasCurrencyMismatch(
    paypalPrimaryCurrency,
    user.settingList.currencyCode
  );

  const defProps = {
    dictionary: {
      accountEmail: fta(paypalBoxMessages.email),
      merchantId: fta(paypalBoxMessages.accountId),
      currency: fta(paypalBoxMessages.currency)
    },
    merchantId: getPaypalMerchantId(),
    currency: paypalPrimaryCurrency,
    email: getPaypalAccountEmail()
  };

  const handlePaypalAcceptingClick = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: !paypalStatusEnabled
        ? PaymentEventComponent.tileGlobalEnabled
        : PaymentEventComponent.tileGlobalDisabled
    });
    handleAcceptingClick(!paypalStatusEnabled, updatePaypalEnabled);
  };

  const handleManagePaypalAccount = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.manageAccount
    });
    redirectToPaypalDashboard();
  };

  const fixUserCurrencyMismatch = () => {
    user.settingList.setCurrencyCode(paypalPrimaryCurrency?.toUpperCase() ?? 'USD');
  };

  if (paypalStatusEnabled && isPaypalCurrencyMismatch) {
    return (
      <PaypalSettingsBoxRowWrapper>
        <PaypalCurrencyMismatchBox onFixUserCurrencyMismatchHandler={fixUserCurrencyMismatch} />
      </PaypalSettingsBoxRowWrapper>
    );
  }

  const paymentsViewedMeta = {
    ...paypalSharedMetadata,
    component: PaymentEventComponent.manageAccount
  };

  return (
    <>
      <PaypalSettingsBoxRowWrapper>
        <PaypalAcceptingBox
          onCTAClick={handlePaypalAcceptingClick}
          checked={paypalStatusEnabled}
          disabled={loading}
        />
      </PaypalSettingsBoxRowWrapper>
      <SettingsAccountDetailsSection {...defProps} />
      <ManageAccountButton
        paymentsViewedMeta={paymentsViewedMeta}
        onClick={handleManagePaypalAccount}>
        {fta(paypalBoxMessages.managePaypalAccount)}
      </ManageAccountButton>
    </>
  );
};

export const PaypalSettingsAcceptingBox = injectStore(PaypalSettingsAcceptingComponent);
