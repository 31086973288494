import React, { useState } from 'react';
import { defineMessages, injectIntl, ISIntl } from 'src/i18n';
import { compose } from 'src/util/functions';
import './InvoicePhotos.scss';

import PhotoModal from './PhotoModal';
import InvoicePhotoModel from 'src/models/InvoicePhotoModel';
import { AppStore } from 'src/stores/AppStore';
import { AddPhoto } from './AddPhoto';
import { ImageFile } from 'src/types/ImageFile';
import { AxiosError } from 'axios';
import { v1 as uuid } from 'uuid';
import isPromise from 'src/util/IsPromise';
import { Photo as ParsePhoto } from 'src/util/IsParseDomain';
import Photo from './Photo';
import { uploadPhoto } from 'src/apis/photosAPI';
import { Alert, useAlert } from '../Alert/Alert';
import { FeatureName } from '@invoice-simple/feature-gate';

const messages = defineMessages({
  photosHeader: {
    id: 'invoice.photos.section.header',
    defaultMessage: 'Photos'
  },
  invoicePhotoFailedUploadErrorDescription: {
    id: 'invoice.photos.error.upload.description',
    defaultMessage: 'Error uploading photo. Please try again.'
  },
  invoicePhotoFailedUploadErrorTitle: {
    id: 'invoice.photos.error.upload.title',
    defaultMessage: 'Unable to upload photo'
  },
  invoicePhotoUnsupportedErrorTitle: {
    id: 'invoice.photos.error.unsupported.title',
    defaultMessage: 'Unable to add image'
  },
  invoicePhotoSaveErrorDescription: {
    id: 'invoice.photos.error.save.description',
    defaultMessage: 'Error saving photo. Please try again.'
  },
  invoicePhotoSaveErrorTitle: {
    id: 'invoice.photos.error.save.title',
    defaultMessage: 'Unable to save photo'
  }
});

export type InvoicePhotoData = {
  remoteId: string;
  name?: string;
  description?: string;
  url: string;
};

interface Props {
  store: AppStore;
  photos: InvoicePhotoModel[];
  intl: ISIntl;
}

export const InvoicePhotos = ({ store, photos, intl }: Props) => {
  return (
    <div className="content-block">
      <div className="clearfix">
        <h3 className="invoice-photos-header">{intl.ft(messages.photosHeader)}</h3>
        <PhotoGrid store={store} photos={photos} intl={intl} />
      </div>
    </div>
  );
};
export default compose(injectIntl)(InvoicePhotos);

const PhotoGrid = ({ store, photos, intl }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [remoteData, setRemoteData] = useState<InvoicePhotoData | null>(null);

  const { closeAlert, isOpen: isAlertOpen, openAlert, title, description } = useAlert();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const onUpload = (file: ImageFile) => {
    store.disableApp();
    setIsEditing(false);
    setIsUploading(true);
    const remoteId = uuid();

    uploadPhoto({
      file,
      accountId: store.user.accountId,
      apiReqOpts: store.user.getApiReqOpts()
    })
      .then(({ uploadRes, urlRes }) => {
        setPreviewUrl(urlRes.data.url);
        setRemoteData({
          remoteId,
          url: urlRes.data.url
        });

        const photo = new ParsePhoto({
          remoteId,
          deleted: false,
          url: urlRes.data.url,
          md5: uploadRes.headers.etag,
          updated: Date.now()
        });

        // Saves the photo to our database
        isPromise(photo.save())
          .then(() => {
            openModal();
          })
          .catch((err) => {
            openAlert({
              description: intl.formatMessage(messages.invoicePhotoFailedUploadErrorDescription),
              title: intl.formatMessage(messages.invoicePhotoFailedUploadErrorTitle),
              variant: 'danger'
            });
            store.user.handleError('photo-save', err);
          });
      })
      .catch((err: AxiosError) => {
        openAlert({
          description: intl.formatMessage(messages.invoicePhotoFailedUploadErrorDescription),
          title: intl.formatMessage(messages.invoicePhotoFailedUploadErrorTitle),
          variant: 'danger'
        });
        store.user.trackError('photo-upload', err);
      })
      .finally(() => {
        setIsUploading(false);
        store.enableApp();
      });
  };

  const onEdit = ({ remoteId, name, description, url }) => {
    setRemoteData({
      remoteId,
      name,
      description,
      url
    });
    setIsEditing(true);
    openModal();
  };

  return (
    <section className="invoice-photo-grid">
      <Alert
        variant="danger"
        title={title}
        description={description}
        isOpen={isAlertOpen}
        isClosable
        onClose={closeAlert}
        dataTestId="invoice-photo-error-alert"
      />
      <ul>
        {photos.map((photo) => {
          return <Photo key={photo.remoteId} onEdit={onEdit} photo={photo} />;
        })}
        <AddPhoto
          user={store.user}
          onUpload={onUpload}
          intl={intl}
          onError={(errorMessage: string) =>
            openAlert({
              description: errorMessage,
              title: intl.formatMessage(messages.invoicePhotoUnsupportedErrorTitle),
              variant: 'danger'
            })
          }
          isUploading={isUploading}
          hasAccess={store.user.canUseFeature(FeatureName.INVOICE_PHOTOS)}
        />
      </ul>

      <PhotoModal
        isOpen={isOpen}
        onClose={closeModal}
        isEditing={isEditing}
        previewUrl={previewUrl}
        remoteData={remoteData}
        store={store}
        onError={() =>
          openAlert({
            description: intl.formatMessage(messages.invoicePhotoSaveErrorDescription),
            title: intl.formatMessage(messages.invoicePhotoSaveErrorTitle),
            variant: 'danger'
          })
        }
      />
    </section>
  );
};
