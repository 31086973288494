import React from 'react';
import { SVGProps } from 'react';

export const SparklesIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 0C4.94036 0 5.5 0.559644 5.5 1.25V2.5H6.75C7.44036 2.5 8 3.05964 8 3.75C8 4.44036 7.44036 5 6.75 5H5.5V6.25C5.5 6.94036 4.94036 7.5 4.25 7.5C3.55964 7.5 3 6.94036 3 6.25V5H1.75C1.05964 5 0.5 4.44036 0.5 3.75C0.5 3.05964 1.05964 2.5 1.75 2.5H3V1.25C3 0.559644 3.55964 0 4.25 0ZM4.25 12.5C4.94036 12.5 5.5 13.0596 5.5 13.75V15H6.75C7.44036 15 8 15.5596 8 16.25C8 16.9404 7.44036 17.5 6.75 17.5H5.5V18.75C5.5 19.4404 4.94036 20 4.25 20C3.55964 20 3 19.4404 3 18.75V17.5H1.75C1.05964 17.5 0.5 16.9404 0.5 16.25C0.5 15.5596 1.05964 15 1.75 15H3V13.75C3 13.0596 3.55964 12.5 4.25 12.5Z"
        fill="#FC5B31"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9999 0C13.5671 0 14.0632 0.381853 14.2083 0.930134L15.6823 6.49866L19.8746 8.91727C20.2615 9.14051 20.4999 9.55328 20.4999 10C20.4999 10.4467 20.2615 10.8595 19.8746 11.0827L15.6823 13.5013L14.2083 19.0699C14.0632 19.6181 13.5671 20 12.9999 20C12.4328 20 11.9367 19.6181 11.7916 19.0699L10.3175 13.5013L6.12534 11.0827C5.7384 10.8595 5.5 10.4467 5.5 10C5.5 9.55328 5.7384 9.14051 6.12534 8.91727L10.3175 6.49867L11.7916 0.930134C11.9367 0.381853 12.4328 0 12.9999 0Z"
        fill="#FC5B31"
      />
    </svg>
  );
};
