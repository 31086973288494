import React from 'react';
import { observer } from 'mobx-react';
import { Desktop, Mobile } from '../Responsive';

export const mayBe = (Wrapped, condition) =>
  observer(() => (
    <>
      <Desktop>
        <Wrapped />
      </Desktop>
      <Mobile>{condition() ? <Wrapped /> : null}</Mobile>
    </>
  ));
