import React from 'react';
import injectStore from '../../util/injectStore';
import { observer } from 'mobx-react';
import { WhenEditingInvoice, WhenViewingInvoice } from '../conditionalRenderer';
import { Mobile, Desktop } from '../Responsive';
import { defineMessages } from 'src/i18n';
import { AppStore } from 'src/stores/AppStore';
import { useISIntl } from 'src/i18n/utils';

const messages = defineMessages({
  edit: {
    id: 'previewEditToggle.edit',
    defaultMessage: 'edit'
  },
  preview: {
    id: 'previewEditToggle.preview',
    defaultMessage: 'preview'
  },
  editInvoice: {
    id: 'previewEditToggle.editInvoice',
    defaultMessage: 'edit {docType}'
  },
  previewInvoice: {
    id: 'previewEditToggle.previewInvoice',
    defaultMessage: 'preview {docType}'
  }
});

const Button = ({ disabled, onClick, label, active, className }) => (
  <button
    type="button"
    disabled={disabled || active}
    className={`btn ${className} ${active ? 'active' : ''}`}
    onClick={onClick}>
    {label}
  </button>
);

const EditButton = injectStore(({ store }: { store: AppStore }) => {
  const intl = useISIntl();
  return (
    <Button
      className="btn-edit"
      label={intl.fta(messages.edit)}
      onClick={() => store.doc.showEditForm()}
      disabled={store.isLoading}
      active={store.location.isEdit}
    />
  );
});

const PreviewButton = injectStore(({ store }: { store: AppStore }) => {
  const intl = useISIntl();
  return (
    <Button
      className="btn-view"
      label={intl.fta(messages.preview)}
      onClick={() => store.doc.showPreview()}
      disabled={store.isLoading}
      active={!store.location.isEdit}
    />
  );
});

const InvoicePreviewEditToggle = () => {
  return (
    <div className="btn-group btn-group-edit" role="group" aria-label="View Edit Invoice">
      <Mobile>
        <WhenEditingInvoice>
          <PreviewButton />
        </WhenEditingInvoice>
        <WhenViewingInvoice>
          <EditButton />
        </WhenViewingInvoice>
      </Mobile>

      <Desktop>
        <PreviewButton />
        <EditButton />
      </Desktop>
    </div>
  );
};

export default observer(InvoicePreviewEditToggle);
