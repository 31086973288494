import { DocType, DocTypes } from '@invoice-simple/common';
import { IReactComponent } from 'mobx-react';
import React from 'react';
import {
  injectIntl as originalInjectIntl,
  IntlShape,
  MessageDescriptor,
  useIntl
} from 'react-intl';

import { app } from './messages';
import { titleize, titleizeAll } from 'src/util/Titleize';
import location from 'src/models/LocationModel';

export interface ISIntl extends IntlShape {
  formatMessage: (messageDescriptor: MessageDescriptor, options?: {}) => string;
  ft: (messageDescriptor: MessageDescriptor, values?: { [key: string]: any }) => string;
  fta: (messageDescriptor: MessageDescriptor, values?: { [key: string]: any }) => string;
  getLocalizedDocumentType: (docType?: DocType) => string;
  getTitleizedDocumentType: (...args: any[]) => string;
  getLocalizedPluralDocumentType: (docType?: DocType) => string;
}

export const docTypeToMessage = (docType: DocType) => {
  switch (docType) {
    case DocTypes.DOCTYPE_STATEMENT:
      return app.receipt;
    case DocTypes.DOCTYPE_ESTIMATE:
      return app.estimate;
    default:
      return app.invoice;
  }
};
export const docTypeToPluralMessage = (docType: DocType) => {
  switch (docType) {
    case DocTypes.DOCTYPE_STATEMENT:
      return app.receipts;
    case DocTypes.DOCTYPE_ESTIMATE:
      return app.estimates;
    default:
      return app.invoices;
  }
};

export interface IntlProp {
  formatMessage: (message: MessageDescriptor) => string;
  ft: (message: MessageDescriptor) => string;
  fta: (message: MessageDescriptor) => string;
}

export function injectIntl<T extends IReactComponent>(target: T): T {
  return originalInjectIntl((props) => {
    const formatMessage = (messageId, options = {}) =>
      props.intl.formatMessage(
        messageId,
        Object.assign(
          {},
          {
            docType: props.intl.formatMessage(docTypeToMessage(location.docType)),
            pluralDocType: props.intl.formatMessage(docTypeToPluralMessage(location.docType))
          },
          options
        )
      );

    const getLocalizedDocumentType = (docType: DocType = location.docType) =>
      formatMessage(docTypeToMessage(docType));
    const getLocalizedPluralDocumentType = (docType: DocType = location.docType) =>
      formatMessage(docTypeToPluralMessage(docType));

    return React.createElement(target as any, {
      ...props,
      intl: {
        ...props.intl,
        formatMessage,
        ft: (...args) => titleize(formatMessage.call(null, ...args)),
        fta: (...args) => titleizeAll(formatMessage.call(null, ...args)),
        getLocalizedDocumentType,
        getTitleizedDocumentType: (...args) => titleize(getLocalizedDocumentType(...args)),
        getLocalizedPluralDocumentType
      } as ISIntl
    });
  }) as unknown as T;
}

export const useISIntl = () => {
  const intl = useIntl();

  const defaults = {
    docType: intl.formatMessage(docTypeToMessage(location.docType)),
    pluralDocType: intl.formatMessage(docTypeToPluralMessage(location.docType))
  };

  const formatMessage = (descriptor: MessageDescriptor, values?: Record<string, unknown>) =>
    intl.formatMessage(descriptor, {
      ...defaults,
      ...values
    });

  const getLocalizedDocumentType = (docType: DocType = location.docType) =>
    formatMessage(docTypeToMessage(docType), defaults);
  const getLocalizedPluralDocumentType = (docType: DocType = location.docType) =>
    formatMessage(docTypeToPluralMessage(docType), defaults);
  const getTitleizedDocumentType = (docType: DocType = location.docType) =>
    titleize(getLocalizedDocumentType(docType));

  const ft = (messageDescriptor: MessageDescriptor, values?: Record<string, unknown>) =>
    titleize(formatMessage(messageDescriptor, { ...defaults, ...values }));
  const fta = (messageDescriptor: MessageDescriptor, values?: Record<string, unknown>) =>
    titleizeAll(formatMessage(messageDescriptor, { ...defaults, ...values }));

  return {
    ...intl,
    formatMessage,
    f: formatMessage,
    ft,
    fta,
    getLocalizedDocumentType,
    getLocalizedPluralDocumentType,
    getTitleizedDocumentType
  };
};
