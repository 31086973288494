import { AxiosError } from 'axios';
import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loadStripe } from '@stripe/stripe-js';

import Alert from './Alert';
import Checkout from './Checkout';
import { CurrencyDisplay } from './Currency';

import { AppStore } from '../stores/AppStore';
import { injectIntl, defineMessages } from 'src/i18n';
import alertModel from 'src/models/AlertModel';

import './Subscription.css';
import { redirectToStripeCheckout } from 'src/util/stripeCheckout';
import { ISIntl } from 'src/i18n/utils';

interface Prop {
  store: AppStore;
  onCancel: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  intl: ISIntl;
}

const messages = defineMessages({
  freeWebTrial: {
    id: 'subscription.title.freeWebTrial',
    defaultMessage: 'free web trial'
  },
  upgradeRequired: {
    id: 'subscription.title.upgradeRequired',
    defaultMessage: 'web upgrade required'
  },
  subscriptionRequired: {
    id: 'subscription.title.subscriptionRequired',
    defaultMessage: 'subscription required'
  },
  subscriptionExpired: {
    id: 'subscription.title.subscriptionExpired',
    defaultMessage: 'your subscription has expired'
  },
  trialDaysRemaining: {
    id: 'subscription.title.trialDaysRemaining',
    defaultMessage: 'you have {daysCount} days remaining of your free trial period.'
  },
  trialExpired: {
    id: 'subscription.title.trialExpired',
    defaultMessage: 'your 14 day free web trial has expired.'
  },
  mobileOnly: {
    id: 'subscription.option.mobileOnly',
    defaultMessage: 'mobile only'
  },
  mobileAndWeb: {
    id: 'subscription.option.mobileAndWeb',
    defaultMessage: 'mobile + web'
  },
  unlimitedWebInvoices: {
    id: 'subscription.list.unlimitedWebInvoice',
    defaultMessage: 'unlimited web invoices'
  },
  unlimitedWebEstimates: {
    id: 'subscription.list.unlimitedWebEstimates',
    defaultMessage: 'unlimited web estimates'
  },
  monthlySubscriptionTitle: {
    id: 'subscription.title.monthly',
    defaultMessage: 'Monthly Subscription'
  },
  noThanks: {
    id: 'subscription.action.noThanks',
    defaultMessage: 'no thanks'
  }
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

@injectIntl
@observer
export default class SubscriptionTrial extends React.Component<Prop, any> {
  componentDidMount() {
    const user = this.props.store.user;
    if (user) {
      user.trackAppEventViaApi('subscription-view-trial', this.props.store.upgradeEventData);
      user.trackAppEventViaApi('subscription-paywall', this.props.store.upgradeEventData);
    }
  }

  state = {
    checkoutSessionLoading: false
  };

  render() {
    const store = this.props.store;
    const user = store.user;
    const sub = user.trialSub;
    const upgrade = user.subUpgrade;
    const isTrial = user.isSubTrialActive;
    const isUpgrade = upgrade.currentPrice > 0;
    const { fta, ft } = this.props.intl;
    const title = isTrial
      ? fta(messages.freeWebTrial)
      : isUpgrade
      ? fta(messages.upgradeRequired)
      : fta(messages.subscriptionRequired);

    const subTitle =
      sub && sub.trialDaysRemaining > 0
        ? ft(messages.trialDaysRemaining, { daysCount: sub.trialDaysRemaining })
        : user.lastPaidSub
        ? ft(messages.subscriptionExpired)
        : ft(messages.trialExpired);

    const currencyCode = upgrade.currency;

    const openStripeCheckout = async () => {
      this.setState({ checkoutSessionLoading: true });
      const stripe = await stripePromise;

      if (!stripe) {
        this.setState({ checkoutSessionLoading: false });
        return;
      }

      try {
        const productTitle: string = fta(messages.monthlySubscriptionTitle);
        const orderSku = upgrade.orderSku;
        const description = orderSku.includes('web-mobile') ? fta(messages.mobileAndWeb) : subTitle;
        const price = upgrade.newPrice;

        await redirectToStripeCheckout({
          store,
          productTitle,
          description,
          price,
          orderSku
        });
      } catch (err) {
        const error = err as AxiosError<{ title?: string; message?: string }>;
        this.setState({ checkoutSessionLoading: false });
        if (error.response) {
          alertModel.setAlert('danger', error.response.data.title, error.response.data.message);
        } else {
          alertModel.setAlert('danger', 'Unable to create checkout session', error.message);
        }
      }
    };

    return (
      <div className="subscription-trial">
        <div className="focus-container">
          <div className="focus-header">
            <a className="brand" href="/" onClick={(e) => this.props.onCancel(e)}>
              <img
                className="brand-logo"
                src="/images/logo.png"
                role="presentation"
                alt="invoice simple logo"
                data-pin-nopin="true"
              />
            </a>
            <h2>{title}</h2>
            <h3>{subTitle}</h3>
          </div>
          <div className="focus-body">
            <Alert />
            <table>
              <tbody>
                <tr>
                  {isUpgrade && (
                    <td>
                      <CurrencyDisplay
                        value={upgrade.currentPrice / 100}
                        currencyCode={currencyCode}
                        suffix={`/${upgrade.planInterval}`}
                      />
                      <span className="subscription-upgrade-name">{fta(messages.mobileOnly)}</span>
                    </td>
                  )}
                  {isUpgrade && (
                    <td>
                      <FontAwesomeIcon icon="arrow-right" />
                    </td>
                  )}
                  <td>
                    <CurrencyDisplay
                      value={upgrade.newPrice / 100}
                      currencyCode={currencyCode}
                      suffix={`/${upgrade.planInterval}`}
                    />
                    {isUpgrade && (
                      <span className="subscription-upgrade-name">
                        {fta(messages.mobileAndWeb)}
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <ul>
              <li>{fta(messages.unlimitedWebInvoices)}</li>
              <li>{fta(messages.unlimitedWebEstimates)}</li>
            </ul>
            <Checkout
              onOpen={() => openStripeCheckout()}
              loading={this.state.checkoutSessionLoading}
            />
          </div>
          {!this.state.checkoutSessionLoading && (
            <div className="focus-footer">
              <a className="btn" href="/invoices" onClick={(e) => this.props.onCancel(e)}>
                {fta(messages.noThanks)}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
