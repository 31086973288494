import React from 'react';
import { SubscriptionTier } from '@invoice-simple/common';
import { LoadingSpinner } from 'src/components/Button';
import { Cadence } from 'src/models/UserModel';

interface Props {
  text: string;
  tier: SubscriptionTier;
  cadence: Cadence;
  loading: boolean;
  onClick: (tier: SubscriptionTier, cadence: Cadence) => void;
}

export const SubscriptionButton = (props: Props) => {
  const { tier, cadence, text, loading, onClick } = props;
  return (
    <button
      type="button"
      onClick={() => onClick(tier, cadence)}
      data-testid={`btn-upgrade-tier-${tier}`}
      className={`no-underline block w-full border-none flex-grow rounded-md bg-orange-is py-[8px] text-center text-xl font-semibold text-white hover:bg-orange-700 mt-4 ${
        loading ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}>
      {loading && <LoadingSpinner loadingTextStyle="text-white" />}
      {text}
    </button>
  );
};
