import React from 'react';

export function Visa(): JSX.Element {
  return (
    <svg width="40" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.619 22.75C39.619 24.5364 38.1333 26 36.3171 26H3.30194C1.48571 26 0 24.5364 0 22.75V3.25C0 1.4625 1.48571 0 3.30194 0H36.3171C38.1333 0 39.619 1.4625 39.619 3.25V22.75Z"
        fill="#0D579C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5234 17.4119L16.8561 8.81543H18.9846L17.653 17.4119H15.5234Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3781 9.02655C24.9574 8.85417 24.2949 8.66602 23.4697 8.66602C21.3651 8.66602 19.8828 9.83324 19.8698 11.503C19.8568 12.7389 20.9281 13.4273 21.7359 13.8385C22.5654 14.2599 22.8441 14.5292 22.8408 14.9055C22.8343 15.4812 22.1783 15.7449 21.5657 15.7449C20.7134 15.7449 20.2602 15.6153 19.5597 15.2931L19.2865 15.1567L18.9883 17.0811C19.4849 17.3199 20.4055 17.5284 21.3608 17.5396C23.5988 17.5396 25.0528 16.3871 25.0691 14.6024C25.0767 13.6233 24.5096 12.8809 23.28 12.268C22.5361 11.868 22.0797 11.6044 22.0851 11.2021C22.0851 10.8439 22.4711 10.4608 23.3049 10.4608C24.0011 10.4507 24.5053 10.6174 24.8989 10.792L25.0897 10.8901L25.3781 9.02655Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8407 8.82422H29.1947C28.684 8.82422 28.3023 8.97745 28.0789 9.5374L24.916 17.4151H27.1529C27.1529 17.4151 27.5184 16.356 27.6008 16.1228C27.8447 16.1228 30.0177 16.1261 30.3278 16.1261C30.3918 16.427 30.588 17.4151 30.588 17.4151H32.5637L30.8407 8.82422ZM28.2138 14.3641C28.3905 13.8706 29.0617 11.9609 29.0617 11.9609C29.0498 11.9845 29.2373 11.464 29.3458 11.1418L29.4889 11.8831C29.4889 11.8831 29.8977 13.9348 29.9823 14.3641H28.2138Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7383 8.82324L11.6542 14.683L11.4309 13.4933C11.0427 12.1199 9.8326 10.6315 8.48047 9.88682L10.3878 17.4039L12.641 17.4028L15.9947 8.82324H13.7383Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.71806 8.81738H6.28405L6.25586 8.9954C8.9276 9.70745 10.6961 11.4256 11.4302 13.4919L10.6842 9.54296C10.5552 8.99765 10.1811 8.83654 9.71806 8.81738Z"
        fill="#F3AA43"
      />
    </svg>
  );
}
