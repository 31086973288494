import React from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISIntl } from 'src/i18n';
import UserModel, { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { titleizeAll } from 'src/util/Titleize';
import { LoadingSpinner } from '../../Button';
import { useTiers } from '../hooks/useTiers';
import { useUpgrade } from '../hooks/useUpgrade';
import { messages } from '../utils/messages';

interface TierProps {
  store: AppStore;
  intl: ISIntl;
  selectedInterval: Cadence;
  shouldDisallowSwitch: (destinationTier: SubscriptionTier, user: UserModel) => boolean;
}

export const TierList = ({ store, intl, selectedInterval, shouldDisallowSwitch }: TierProps) => {
  const { user } = store;
  const { ft, fta } = intl;

  const { switching, loadingCheckout, onUpgradeClick, OriginalPrice, FinalPrice, isCurrentPlan } =
    useUpgrade({
      store,
      intl,
      selectedInterval,
      shouldDisallowSwitch
    });

  const { tiers, featuresHighlighted } = useTiers({ user });

  const loading = loadingCheckout || switching;

  return (
    <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:mx-auto xl:mx-0 xl:max-w-none xl:grid-cols-3">
      {Object.keys(tiers).map((tier: SubscriptionTier) => {
        const targetTier = tier;
        return (
          <div
            key={tier}
            data-testid={`${tier}-tier-details`}
            className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm">
            <div className="p-8">
              <h2 className="text-3xl font-medium leading-6 text-gray-900">
                {titleizeAll(tier)}
                {isCurrentPlan(targetTier) && (
                  <span
                    className="ml-3 font-semibold text-orange-is"
                    style={{ fontSize: '1.5rem' }}>
                    {fta(messages.currentActivePlan)}
                  </span>
                )}
              </h2>

              <p className="mt-8">
                <span className="text-4xl font-bold tracking-tight text-gray-900">
                  {selectedInterval === Cadence.ANNUAL && <OriginalPrice tier={targetTier} />}
                  <FinalPrice tier={targetTier} />
                </span>
              </p>
              <button
                type="button"
                data-testid={`btn-upgrade-tier-${tier}`}
                className={`d-flex items-center justify-center mt-8 block w-full border-none rounded-md bg-orange-is py-3 text-center text-2xl text-white tracking-wide hover:bg-orange-700 ${
                  loading || shouldDisallowSwitch(tier, user)
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
                onClick={() => onUpgradeClick(targetTier)}>
                {loading && <LoadingSpinner loadingTextStyle="text-white" />}
                {ft(messages.upgrade)}
              </button>
            </div>
            <div className="px-6 pt-2 pb-8">
              <ul role="list" className="pt-4 space-y-3">
                {tiers[tier]?.includedFeatures.map((feature) => (
                  <li key={feature} className="flex items-center space-x-3">
                    <FontAwesomeIcon className="h-5 w-5 flex-shrink-0 text-gray-600" icon="check" />
                    <span
                      className={`text-xl text-gray-500 ${
                        featuresHighlighted.includes(feature) ? 'font-semibold' : ''
                      }`}>
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};
