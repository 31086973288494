import React, { FC, useCallback } from 'react';
import { startStripeOnboarding } from 'src/payments';
import { getPaymentEventSource, onError } from 'src/payments/utils/utils';
import { defineMessages } from 'src/i18n';
import {
  OnboardModalProps,
  OnboardModalMessages,
  PaymentEventComponent,
  PaymentEventActivePaymentProvider
} from 'src/payments/types';
import {
  TopContainer,
  Illustration,
  ValueProps,
  ButtonContainer
} from '../../PaymentBasicElements';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';

const messages = defineMessages({
  header: {
    id: 'stripeModal.prompt.title',
    defaultMessage: 'Finish Setup'
  },
  description1: {
    id: 'stripeModal.prompt.description1',
    defaultMessage: "You're almost ready to accept online payments and get paid twice as fast!"
  },
  description2: {
    id: 'stripeModal.prompt.description2',
    defaultMessage: 'Continue to Stripe to finish where you left off.'
  },
  ctaText: { id: 'stripeModal.prompt.ctaText', defaultMessage: 'Continue To Stripe' }
});

const StripePendingModal: FC<
  OnboardModalProps & {
    store: AppStore;
  }
> = ({ origin, closeModal, store: { user } }) => {
  const ft = (message: OnboardModalMessages) => message.defaultMessage;
  const stripeSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    source: getPaymentEventSource()
  };
  const handleClick = useCallback(async () => {
    try {
      user.events.trackAction('payments-cta', {
        ...stripeSharedMetadata,
        component: PaymentEventComponent.modalPending
      });
      await startStripeOnboarding(origin, true);
      closeModal();
    } catch (error) {
      onError({ title: 'Error initiating Stripe onboarding', body: error.message });
    }
  }, []);

  const onClickXIcon = () => {
    user.events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.modalClosePending
    });
    closeModal();
  };

  const paymentsViewedMeta = {
    ...stripeSharedMetadata,
    component: PaymentEventComponent.modalPending
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <>
      <div data-testid="stripePendingModal" />
      <TopContainer
        dataTestId="stripe--modal-x-button"
        onClickXIcon={onClickXIcon}
        headerMessage={ft(messages.header)}
      />
      <main>
        <Illustration url={'/images/kb-illustration.png'} width={826} height={472} />
        <ValueProps>
          <b>{ft(messages.description1)}</b>
          <p className="pt-4">{ft(messages.description2)}</p>
        </ValueProps>
      </main>
      <ButtonContainer
        dataTestId="stripe--modal-submit-button"
        handleClick={handleClick}
        buttonMessage={ft(messages.ctaText)}
      />
    </>
  );
};

export default injectStore(StripePendingModal);
