import { observable, action, computed } from 'mobx';

// scope status as enum
const enum BROWSER_NOTIFICATION_PERMISSION_STATUS {
  LOADING = 'loading',
  UNSUPPORTED = 'unsupported',
  DENIED = 'denied',
  PROMPT = 'prompt',
  GRANTED = 'granted'
}

export class BrowserNotificationStore {
  @observable state: string = BROWSER_NOTIFICATION_PERMISSION_STATUS.LOADING;

  constructor() {
    this.registerObserver();
  }

  // fetch current state
  // observe future state changes
  // assign either to store.state
  @action
  private registerObserver(): void {
    if ('permissions' in navigator && !!window.Notification) {
      navigator.permissions
        .query({ name: 'push', userVisibleOnly: true } as PermissionDescriptor & {
          userVisibleOnly: boolean;
        })
        .then((res) => {
          this.state = res.state;
          res.onchange = (e) => {
            // @ts-ignore
            if (e && e.target && e.target.state) {
              // @ts-ignore
              this.state = e.target.state;
            }
          };
        })
        .catch(() => {
          this.state = BROWSER_NOTIFICATION_PERMISSION_STATUS.UNSUPPORTED;
        });
    } else {
      this.state = BROWSER_NOTIFICATION_PERMISSION_STATUS.UNSUPPORTED;
    }
  }

  @computed
  get isDenied(): boolean {
    return this.state === BROWSER_NOTIFICATION_PERMISSION_STATUS.DENIED;
  }
  @computed
  get isGranted(): boolean {
    return this.state === BROWSER_NOTIFICATION_PERMISSION_STATUS.GRANTED;
  }
  @computed
  get isPrompt(): boolean {
    return this.state === BROWSER_NOTIFICATION_PERMISSION_STATUS.PROMPT;
  }
  @computed
  get isLoading(): boolean {
    return this.state === BROWSER_NOTIFICATION_PERMISSION_STATUS.LOADING;
  }
  @computed
  get isSupported(): boolean {
    return this.state !== BROWSER_NOTIFICATION_PERMISSION_STATUS.UNSUPPORTED;
  }
}

// export instance
export default new BrowserNotificationStore();
