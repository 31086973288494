import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';
import NumericInput from 'react-numeric-input';
import TextareaAutosize from 'react-textarea-autosize';

import DatePickerWrapper from 'src/components/DatePickerWrapper';
import { defineMessages, injectIntl } from 'src/i18n';
import { ModalClose } from 'src/components/ModalClose';
import { CurrencyDisplay } from '../../../Currency';
import InvoiceModel from 'src/models/InvoiceModel';
import { paymentMethodOptions } from 'src/data/paymentMethodOptions';
import { Selectv1Adapter } from 'src/components/Selectv1Adapter';

import 'react-datepicker/dist/react-datepicker.css';
import '../../InvoiceHeader.css';
import './InvoicePaymentList.css';
import { getPaidAmount } from '@invoice-simple/calculator';
import InvoicePaymentModel from 'src/models/InvoicePaymentModel';
import { byDate } from 'src/util/date';
import { ISIntl } from 'src/i18n/utils';
import { InvoicePaymentRow } from '../../../InvoicePaymentRow';

interface Prop {
  isLoading: boolean;
  invoice: InvoiceModel;
  onLoad: () => void;
  onCancel: () => void;
  onSave: () => void;
  intl?: ISIntl;
}

const messages = defineMessages({
  recordPayment: {
    id: 'invoice.recordPayment.header',
    defaultMessage: 'record payment'
  },
  savePayment: {
    id: 'invoice.recordPayment.savePayment',
    defaultMessage: 'save payment'
  },
  description: {
    id: 'invoice.recordPayment.emailButtonTitle',
    defaultMessage: 'record an invoice payment from your client'
  },
  pageTitle: {
    id: 'invoice.recordPayment.pageTitle',
    defaultMessage: 'record payment for invoice {docNumber}'
  },
  closePayment: {
    id: 'invoice.recordPayment.closePayment',
    defaultMessage: 'close invoice payment'
  },
  amount: {
    id: 'invoice.recordPayment.amount',
    defaultMessage: 'amount'
  },
  date: {
    id: 'invoice.recordPayment.date',
    defaultMessage: 'date'
  },
  method: {
    id: 'invoice.recordPayment.method',
    defaultMessage: 'method'
  },
  notes: {
    id: 'invoice.recordPayment.notes',
    defaultMessage: 'notes'
  },
  notesPlaceholder: {
    id: 'invoice.recordPayment.notesPlaceholder',
    defaultMessage: 'optional payment note'
  },
  close: {
    id: 'invoice.recordPayment.close',
    defaultMessage: 'close'
  },
  paymentHistory: {
    id: 'invoice.recordPayment.paymentHistory',
    defaultMessage: 'payment history'
  },
  total: {
    id: 'invoice.recordPayment.total',
    defaultMessage: 'total'
  },
  paymentMethodLabelCash: {
    id: 'payment.method.label.cash',
    defaultMessage: 'Cash'
  },
  paymentMethodLabelCheck: {
    id: 'payment.method.label.check',
    defaultMessage: 'Check'
  },
  paymentMethodLabelBankTransfer: {
    id: 'payment.method.label.banktransfer',
    defaultMessage: 'Bank Transfer'
  },
  paymentMethodLabelCreditCard: {
    id: 'payment.method.label.creditcard',
    defaultMessage: 'Credit Card'
  },
  paymentMethodLabelMobilePaymentApp: {
    id: 'payment.method.label.mobilePaymentApp',
    defaultMessage: 'Mobile Payment App'
  },
  paymentMethodLabelDebit: {
    id: 'payment.method.label.debit',
    defaultMessage: 'Debit'
  },
  paymentMethodLabelOther: {
    id: 'payment.method.label.other',
    defaultMessage: 'Other'
  },
  paymentMethodLabelPayPal: {
    id: 'payment.method.label.paypal',
    defaultMessage: 'PayPal'
  },
  paymentMethodLabelChooseMethod: {
    id: 'payment.method.label.chooseMethod',
    defaultMessage: 'Select Payment Method'
  },
  paymentMethodLabelPointOfSale: {
    id: 'payment.method.label.pointofsale',
    defaultMessage: 'Point-of-sale system'
  },
  paymentMethodLabelPeerToPeer: {
    id: 'payment.method.label.peertopeer',
    defaultMessage: 'Peer-to-peer payment'
  }
});

@injectIntl
@observer
export default class InvoicePaymentList extends React.Component<Prop, any> {
  componentDidMount() {
    this.props.onLoad();
    this.props.invoice.initDraftPayment();
  }
  handleSave = () => {
    this.props.invoice.addDraftPayment();
    this.props.onSave();
  };
  handleCancel = () => {
    this.props.onCancel();
  };
  handleDelete = () => {
    this.props.invoice.initDraftPayment();
  };
  render() {
    const invoice = this.props.invoice;
    const isLoading = this.props.isLoading || this.props.invoice.isLoading;
    const { ft, fta } = this.props.intl!;
    const payment = invoice.draftPayment;
    const btnClass = `btn btn-submit btn-prime${payment && payment.isValid ? '' : ' disabled'}`;

    return (
      <div className="invoice-payment invoice-header d-print-none">
        <Helmet>
          <title itemProp="name">{ft(messages.pageTitle, { docNumber: invoice.invoiceNo })}</title>
          <meta name="description" content={ft(messages.description)} />
        </Helmet>

        <div className="invoice-payment-header">
          <ModalClose title={fta(messages.closePayment)} onClick={this.handleCancel} />
          <h3>{fta(messages.recordPayment)}</h3>
        </div>

        <div className="invoice-payment-body">
          <form>
            <div className="form-group row payment-amount-row">
              <label htmlFor="invoice-payment-amount" className="col-md-2 col-form-label">
                {`${ft(messages.amount)} (${invoice.currencyCode})`}
              </label>
              <div className="col-md-10">
                <NumericInput
                  className="payment-amount-input"
                  name="invoicePaymentAmount"
                  value={payment && payment.amountDisplay}
                  placeholder="0.00"
                  onChange={(v) => payment.setAmount(v ?? 0)}
                  step={1.0}
                  precision={2}
                  min={0}
                  max={100}
                  style={false}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="form-group row payment-date-row">
              <label htmlFor="invoice-payment-date" className="col-md-2 col-form-label">
                {ft(messages.date)}
              </label>
              <div className="col-md-10">
                <DatePickerWrapper
                  disabled={isLoading}
                  id="invoice-payment-date"
                  className="payment-date-input"
                  name="invoicePaymentDate"
                  selected={payment && payment.date}
                  onChange={payment.setDate}
                />
              </div>
            </div>

            <div className="form-group row payment-method-row">
              <label htmlFor="invoice-payment-method" className="col-md-2 col-form-label">
                {ft(messages.method)}
              </label>
              <div className="col-md-10">
                <Selectv1Adapter
                  name="invoiceDiscountType"
                  onChange={(s) => {
                    if (s) {
                      payment.setPaymentMethod(s.value);
                    }
                  }}
                  value={payment.paymentMethod}
                  options={paymentMethodOptions.map((method) => {
                    return {
                      value: method.value,
                      label: ft(messages[method.i18nKey])
                    };
                  })}
                  isDisabled={isLoading}
                  isClearable={false}
                />
              </div>
            </div>

            <div className="form-group row payment-notes-row">
              <label htmlFor="invoice-payment-notes" className="col-md-2 col-form-label">
                {ft(messages.notes)}
              </label>
              <div className="col-md-10">
                <TextareaAutosize
                  style={{ height: 100 }}
                  disabled={isLoading}
                  placeholder={ft(messages.notesPlaceholder)}
                  id="invoice-payment-notes"
                  className="payment-notes-input"
                  minRows={4}
                  maxRows={20}
                  value={payment && payment.notes}
                  onChange={(e) => payment.setNotes(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group row payment-actions-row">
              <div className="col-md-10 offset-md-2">
                <a className={btnClass} onClick={this.handleSave}>
                  {fta(messages.savePayment)}
                </a>
                <a className="btn btn-cancel" onClick={this.handleCancel}>
                  {fta(messages.close)}
                </a>
              </div>
            </div>

            {invoice.visiblePayments.length > 0 && (
              <div className="form-group row payment-history-row">
                <label className="col-md-2 col-form-label">{fta(messages.paymentHistory)}</label>
                <div className="col-md-10">
                  <table className="payment-history">
                    <tbody className="invoice-payments">
                      <PaymentList
                        visiblePayments={invoice.visiblePayments}
                        isLoading={isLoading}
                        handleDelete={this.handleDelete}
                      />
                      <tr className="payment-row">
                        <td>&nbsp;</td>
                        <td className="invoice-summary-label">{fta(messages.total)}</td>
                        <td data-label="Subtotal" className="payment-row-amount">
                          <CurrencyDisplay value={+getPaidAmount(invoice.asUniversal)} />
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

interface PaymentListProps {
  visiblePayments: InvoicePaymentModel[];
  isLoading: boolean;
  handleDelete: () => void;
}

const PaymentList = ({ visiblePayments, isLoading, handleDelete }: PaymentListProps) => {
  return (
    <React.Fragment>
      {visiblePayments
        .sort((a: InvoicePaymentModel, b: InvoicePaymentModel) => byDate(a.date, b.date))
        .map((p) => {
          return (
            <InvoicePaymentRow key={p.id} payment={p} loading={isLoading} onDelete={handleDelete} />
          );
        })}
    </React.Fragment>
  );
};
