import Rollbar from 'src/analytics/rollbar';
import environmentStore from 'src/stores/EnvironmentStore';

type TrackImpactAttributes = {
  userId?: string;
  email?: string;
};

export const IMPACT_USER_SIGNUP_EVENT_ID = 46691;
export const IMPACT_ORDER_ID = 'IR_AN_64_TS';

export const trackImpactIdentifyEvent = (attributes: TrackImpactAttributes) => {
  try {
    // @ts-ignore
    const ire = window.ire;

    if (ire) {
      ire('identify', {
        customerId: attributes?.userId ?? '',
        customerEmail: attributes?.email ?? '',
        customProfileId: environmentStore.installationId ?? ''
      });
    }
  } catch (error) {
    Rollbar.trackError('Failed to track Impact user - legacy', error, { attributes });
  }
};

export const trackImpactSignupEvent = (attributes: TrackImpactAttributes) => {
  try {
    // @ts-ignore
    const ire = window.ire;

    if (ire) {
      ire(
        'trackConversion',
        IMPACT_USER_SIGNUP_EVENT_ID,
        {
          orderId: IMPACT_ORDER_ID,
          customerId: attributes?.userId ?? '',
          customerEmail: attributes?.email ?? '',
          customProfileId: environmentStore.installationId ?? ''
        },
        { verifySiteDefinitionMatch: true }
      );
    }
  } catch (error) {
    Rollbar.trackError('Failed to track Lead event', error, { attributes });
  }
};
