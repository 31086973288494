import React from 'react';
import { observer } from 'mobx-react';
import { compose } from 'src/util/functions';
import { injectIntl } from 'src/i18n';
import localeStore from 'src/stores/LocaleStore';
import envStore from 'src/stores/EnvironmentStore';
import { InvoiceSeoContent } from './InvoiceSeoContent';
import './Content.css';
import { ISIntl } from 'src/i18n/utils';

interface Props {
  intl: ISIntl;
}

const Invoice = ({ intl }: Props) => (
  <InvoiceSeoContent
    appUrl={envStore.appUrl}
    appProxyHost={envStore.appProxyHost}
    currentLocale={localeStore.currentLocale}
    intl={intl}
  />
);

export default compose(injectIntl, observer)(Invoice);
