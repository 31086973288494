export const scrollTo = (tag) => {
  const el = document.querySelector(tag);
  if (el === null) {
    return;
  }
  if (el.scrollIntoView) {
    el.scrollIntoView({
      behavior: 'smooth'
    });
  }
};

export const smoothScrollById = (id: string) => {
  document.getElementById(id)?.scrollIntoView({
    behavior: 'smooth'
  });
};
