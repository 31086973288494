import React from 'react';

export const BadgePremium = (props) => {
  return (
    <div {...props} className={`${props.className} badge-premium`}>
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Frame" clipPath="url(#clip0_3251_36383)">
          <path
            id="Vector"
            d="M14.7812 12.25H3.40625C3.16562 12.25 2.96875 12.4469 2.96875 12.6875V13.5625C2.96875 13.8031 3.16562 14 3.40625 14H14.7812C15.0219 14 15.2188 13.8031 15.2188 13.5625V12.6875C15.2188 12.4469 15.0219 12.25 14.7812 12.25ZM16.5312 3.5C15.8066 3.5 15.2188 4.08789 15.2188 4.8125C15.2188 5.00664 15.2625 5.18711 15.3391 5.35391L13.3594 6.54062C12.9383 6.79219 12.3941 6.65 12.1508 6.22344L9.92227 2.32422C10.2148 2.08359 10.4062 1.72266 10.4062 1.3125C10.4062 0.587891 9.81836 0 9.09375 0C8.36914 0 7.78125 0.587891 7.78125 1.3125C7.78125 1.72266 7.97266 2.08359 8.26523 2.32422L6.03672 6.22344C5.79336 6.65 5.24648 6.79219 4.82812 6.54062L2.85117 5.35391C2.925 5.18984 2.97148 5.00664 2.97148 4.8125C2.97148 4.08789 2.38359 3.5 1.65898 3.5C0.934375 3.5 0.34375 4.08789 0.34375 4.8125C0.34375 5.53711 0.931641 6.125 1.65625 6.125C1.72734 6.125 1.79844 6.11406 1.8668 6.10312L3.84375 11.375H14.3438L16.3207 6.10312C16.3891 6.11406 16.4602 6.125 16.5312 6.125C17.2559 6.125 17.8438 5.53711 17.8438 4.8125C17.8438 4.08789 17.2559 3.5 16.5312 3.5Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_3251_36383">
            <rect width="17.5" height="14" fill="white" transform="translate(0.34375)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
