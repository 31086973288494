import React from 'react';
import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';
import UserModel from 'src/models/UserModel';
import { format } from 'date-fns';
import AlertModel from 'src/models/AlertModel';
import LocationModel from 'src/models/LocationModel';
import { getInvoiceCsv } from 'src/apis/docsAPI';

const messages = defineMessages({
  invoiceData: {
    id: 'settings.list.invoice.data',
    defaultMessage: 'Invoice Data'
  },
  exportSpreadsheet: {
    id: 'settings.list.export.spreadsheet',
    defaultMessage: 'Export as Spreadsheet'
  },
  alertUnexpectedErrorTitle: {
    id: 'app.alert.unexpected.error',
    defaultMessage: 'Unexpected error has occurred'
  },
  alertConnectivityErrorBody: {
    id: 'app.alert.connectivityErrors.body',
    defaultMessage:
      'Please try reloading the app. If the problem persists, contact our support team support@invoicesimple.com'
  }
});

export const ExportSpreadsheet = ({ user }: { user: UserModel }) => {
  const { ft } = useISIntl();

  const downloadInvoiceCSV = async () => {
    try {
      const res = await getInvoiceCsv(user);
      const timestamp = format(new Date(), 'yyyy-MM-dd-hhmmss');
      const filename = `invoices-${timestamp}.csv`;
      const blob = new Blob([res.data], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
      a.remove();

      user.events.trackAction('csv-export');
    } catch (err) {
      AlertModel.setAlert(
        'danger',
        ft(messages.alertUnexpectedErrorTitle),
        ft(messages.alertConnectivityErrorBody)
      );
      LocationModel.scrollTop();
      throw err;
    }
  };

  return (
    <div>
      <div className="setting-form-group form-group row">
        <label className="col-md-4 col-form-label" data-testid="invoice-data-title">
          {ft(messages.invoiceData)}
        </label>
        <div className="col-md-8">
          <button
            type="button"
            data-testid="export-spreadsheet-btn"
            className="btn w-100"
            style={{ fontSize: '12px' }}
            onClick={(e) => {
              e.preventDefault();
              downloadInvoiceCSV();
            }}>
            {ft(messages.exportSpreadsheet)}
          </button>
        </div>
      </div>
    </div>
  );
};
