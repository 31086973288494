import React, { FC } from 'react';
import { PaymentModalWrapper } from 'src/payments/components/PaymentsModals/PaypalModalWrapper';
import { OnboardModalProps } from 'src/payments/types';
import { PaypalModalBase } from '.';
import { useIsPaymentsEnabled } from 'src/payments/hooks/useIsPaymentsEnabled';

export const PaypalModal: FC<OnboardModalProps> = ({ origin, closeModal }) => {
  const isPaymentsEnabled = useIsPaymentsEnabled();

  if (!isPaymentsEnabled) return null;
  return (
    <PaymentModalWrapper isOpen={true} onRequestClose={closeModal}>
      <div id={'tailwind'}>
        <PaypalModalBase closeModal={closeModal} origin={origin} />
      </div>
    </PaymentModalWrapper>
  );
};
