import React, { useEffect } from 'react';
import { PaginatedDocumentsResponse } from './hooks/usePaginatedDocuments';
import { defineMessages } from 'src/i18n';
import { SearchDocumentsResponse } from './hooks/useSearchDocuments';
import { useISIntl } from 'src/i18n/utils';
import { useYearlyBalanceOrTotals } from './hooks/useYearlyBalanceOrTotals';
import { InvoiceRow } from './InvoiceRow';
import { EmptyRow } from './EmptyRow';
import { YearSummaryRow } from './YearSummaryRow';
import { DocType, DocTypes } from '@invoice-simple/common';

interface Props {
  isPaid: boolean;
  onView: (id?: string) => void;
  onCreate: () => void;
  data?: PaginatedDocumentsResponse | SearchDocumentsResponse;
  hasSearchResults: boolean;
  query: string;
  isError: boolean;
  onError: (error: { title?: string; body?: string }) => void;
  docType: DocType;
  state: 'paid' | 'open' | undefined;
}

const messages = defineMessages({
  alertConnectivityErrorTitle: {
    id: 'app.alert.connectivityErrors.title',
    defaultMessage: 'Connectivity problem while contacting our servers.'
  },
  alertConnectivityErrorBody: {
    id: 'app.alert.connectivityErrors.body',
    defaultMessage: `Please try reloading the app. If the problem persists, contact our support team support@invoicesimple.com\n{details}`
  }
});

export const Documents: React.FunctionComponent<Props> = ({
  isPaid,
  onView,
  onCreate,
  data,
  hasSearchResults,
  query,
  isError,
  onError,
  docType,
  state
}) => {
  const { f } = useISIntl();

  const uniqueYears = data?.documents.reduce((acc, invoice) => {
    const year = new Date(invoice.invoiceDate).getUTCFullYear();
    if (!acc.includes(year)) {
      return [...acc, year];
    }
    return acc;
  }, [] as number[]);

  const yearSummaryData = useYearlyBalanceOrTotals({
    docType,
    years: uniqueYears,
    field: docType === DocTypes.DOCTYPE_ESTIMATE || isPaid ? 'total' : 'balance-due',
    state: state ?? 'all',
    isSearching: !!query
  });

  useEffect(() => {
    if (isError) {
      onError({
        title: f(messages.alertConnectivityErrorTitle),
        body: f(messages.alertConnectivityErrorBody, { details: '' })
      });
    }
  }, []);

  if (!data) {
    return (
      <tbody>
        <EmptyRow onCreate={onCreate} hasSearchResults={hasSearchResults} query={query} />
      </tbody>
    );
  }

  // add a row between each year group
  const rows: React.ReactNode[] = [];
  let prevYear: number | null = null;

  for (const invoice of data.documents) {
    const date = new Date(invoice.invoiceDate);
    const year = date.getUTCFullYear();

    if (year !== prevYear) {
      rows.push(
        <YearSummaryRow
          year={year}
          isLoading={yearSummaryData.isInitialLoading}
          isError={yearSummaryData.isError}
          data={yearSummaryData.data}
          key={year}
          isSearching={!!query}
        />
      );
      prevYear = year;
    }

    rows.push(
      <InvoiceRow invoice={invoice} isPaid={isPaid} key={invoice.objectId} onClick={onView} />
    );
  }

  return (
    <tbody>
      {!data.documents.length ? (
        <EmptyRow onCreate={onCreate} hasSearchResults={hasSearchResults} query={query} />
      ) : null}
      {data.documents.length ? rows : null}
    </tbody>
  );
};
