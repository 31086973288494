import React, { FC } from 'react';

import { changeLocationTo } from 'src/components/Navbar/utils';
import { useCommonMetadata } from 'src/payments/hooks/useCommonMetadata';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventComponent } from 'src/payments/types';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { isMigratedSettingsEnabled } from 'src/util/isMigratedSettingsEnabled';

export const ManagePaymentSettingsFC: FC = (props: { store: AppStore }) => {
  const { store } = props;
  const commonMetadata = useCommonMetadata();
  const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    store.user.events.trackAction('payments-cta', {
      ...commonMetadata,
      'from-guest-account': !store.user.isSignedUp,
      component: PaymentEventComponent.managePaymentSettings
    });
    if (isMigratedSettingsEnabled(store.user.countryCode)) {
      window.location.assign('/settings');
      return;
    }
    changeLocationTo(store.navAndScrollTop, 'settingList')(e);
  };

  const paymentsViewedMeta = {
    ...useCommonMetadata(),
    component: PaymentEventComponent.managePaymentSettings
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);

  return (
    <div id="tailwind" data-testid="managePaymentSettings">
      <button
        type="button"
        className="bg-transparent border-none text-blue-600 hover:cursor-pointer hover:underline text-md font-medium p-0"
        rel="nofollow"
        data-testid="managePaymentSettingsButton"
        onClick={onClick}>
        Manage Payment Settings
      </button>
    </div>
  );
};

export const ManagePaymentSettings = injectStore(ManagePaymentSettingsFC);
