import React, { useState } from 'react';
import './PaymentSurchargeFees.scss';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { PassingFeesType } from '../PaymentSetting';
import { CurrencyDisplay } from 'src/components/Currency';
import { PaymentsSurchargeConsentModal } from '../PaymentsModals';

interface PaymentSurchargeFeesProps {
  feesType: number | undefined;
  surcharge: number;
  onFeesTypeToggleChange: (isTransactionFeesEnabled: boolean) => void;
  getIsSurchargeConsentGiven: () => Promise<boolean>;
  setSurchargeConsentGiven: (consentMessage: string) => Promise<void>;
}

export const PaymentSurchargeFees = ({
  feesType,
  surcharge,
  onFeesTypeToggleChange,
  getIsSurchargeConsentGiven,
  setSurchargeConsentGiven
}: PaymentSurchargeFeesProps) => {
  const [shouldShowConsentModal, setShouldShowConsentModal] = useState(false);

  const handleCoverTransactionFeeChange = async (value: boolean) => {
    if (!value) {
      onFeesTypeToggleChange(false);
      return;
    }
    const hasSurchargeConsentGiven = await getIsSurchargeConsentGiven();
    if (hasSurchargeConsentGiven) {
      onFeesTypeToggleChange(true);
    } else {
      setShouldShowConsentModal(true);
    }
  };

  return (
    <>
      <PaymentsSurchargeConsentModal
        isOpen={shouldShowConsentModal}
        onSubmit={async (consentMessage: string) => {
          try {
            await setSurchargeConsentGiven(consentMessage);
            onFeesTypeToggleChange(true);
          } catch (e) {
            console.error(e);
          }
        }}
        onClose={() => {
          setShouldShowConsentModal(false);
        }}
      />
      <div className="payment-surcharge-fees" id="tailwind">
        <h4 data-testid="transaction-fees-h4">PROCESSING FEES</h4>
        <div className="setting-form-group form-group row mb-md-4">
          <div className="col-md-6 col-lg-12 d-flex align-items-center">
            <label className="col-form-label">Cover Processing Fees</label>
            <div className="ml-2 ml-md-3">
              <ToggleSwitch
                checked={feesType === PassingFeesType.SURCHARGE}
                disabled={false}
                onChange={handleCoverTransactionFeeChange}
              />
            </div>
          </div>
          <div className="surcharge-info">
            <p>
              Adds{' '}
              <span className="font-bold">
                <CurrencyDisplay value={surcharge} /> surcharge
              </span>{' '}
              to invoice when a customer pays with Invoice Simple Payments.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
