import React, { FC } from 'react';
import { AlertDialogConfig as RequestAccountDeletionConfig } from '../DialogModal/DialogModalContext';
import { useDialog } from '../DialogModal/useDialog';
import AlertModel from 'src/models/AlertModel';
import LocationModel from 'src/models/LocationModel';
import { ISIntl, useISIntl } from 'src/i18n/utils';
import { defineMessages } from 'react-intl';
import UserModel from 'src/models/UserModel';
import { requestAccountDeletion as requestAPIDeletion } from 'src/apis/accountAPI';

export const testIds = {
  header: 'account-settings-header',
  labelDeleteAccount: 'account-settings-delete-label',
  buttonDeleteAccount: 'account-settings-delete-button'
};

const messages = defineMessages({
  accountSettingsHeader: {
    id: 'account.delete.header',
    defaultMessage: 'Account Deletion'
  },
  deleteAccountTitle: {
    id: 'account.delete.title',
    defaultMessage: 'Delete Account'
  },
  deleteAccountConfirmationMessage: {
    id: 'account.delete.confirmationmessage',
    defaultMessage: 'Are you sure you want to delete your account?'
  },
  deleteAccountFailed: {
    id: 'account.delete.failed',
    defaultMessage: 'Failed to delete account'
  },
  deleteAccountRequestReceived: {
    id: 'account.delete.request.received',
    defaultMessage: 'Deletion Request Received'
  },
  deleteAccountRequestReceivedMessage: {
    id: 'account.delete.request.receivedmessage',
    defaultMessage:
      'Account deletion request received. Please check your email for further instructions.'
  },
  deleteAccountFailedHasPaidSubMessage: {
    id: 'account.delete.failed.submessage',
    defaultMessage:
      'You cannot delete your account while you have an active subscription. Please contact support to cancel your subscription.'
  },
  deleteAccountFailedMessage: {
    id: 'account.delete.failed.defaultmessage',
    defaultMessage:
      'Unexpected error while attempting to delete your account. Please try again later.'
  }
});

const requestAccountDeletion = async (formatMessage: ISIntl['formatMessage'], user: UserModel) => {
  if (user.isSubPaid) {
    AlertModel.setAlert(
      'danger',
      formatMessage(messages.deleteAccountFailed),
      formatMessage(messages.deleteAccountFailedHasPaidSubMessage)
    );
    LocationModel.scrollTop();
    return;
  }

  try {
    await requestAPIDeletion(user);
  } catch (e) {
    AlertModel.setAlert(
      'danger',
      formatMessage(messages.deleteAccountFailed),
      formatMessage(messages.deleteAccountFailedMessage)
    );
    LocationModel.scrollTop();
    return;
  }

  AlertModel.setAlert(
    'success',
    formatMessage(messages.deleteAccountRequestReceived),
    formatMessage(messages.deleteAccountRequestReceivedMessage)
  );
  LocationModel.scrollTop();
  return;
};

const requestAccountDeletionDialogConfig = (
  formatMessage: ISIntl['formatMessage'],
  user: UserModel
): RequestAccountDeletionConfig => ({
  title: formatMessage(messages.deleteAccountTitle),
  message: formatMessage(messages.deleteAccountConfirmationMessage),
  type: 'alert',
  onContinue: async () => requestAccountDeletion(formatMessage, user)
});

const AccountSettings: FC<{ user: UserModel }> = (props) => {
  const { formatMessage } = useISIntl();
  const { user } = props;
  const { openDialogModal } = useDialog();
  return (
    <>
      <h4 data-testid>{formatMessage(messages.accountSettingsHeader)}</h4>
      <div className="setting-form-group form-group row">
        <label className="col-md-4 col-form-label" data-testid={testIds.labelDeleteAccount}>
          {formatMessage(messages.deleteAccountTitle)}
        </label>
        <div className="col-md-8">
          <button
            type="button"
            className={`btn btn-danger w-100`}
            data-testid={testIds.buttonDeleteAccount}
            style={{ background: '#a94442', color: 'white' }}
            onClick={(e) => {
              e.preventDefault();
              openDialogModal(requestAccountDeletionDialogConfig(formatMessage, user));
            }}>
            {formatMessage(messages.deleteAccountTitle)}
          </button>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
