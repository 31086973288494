import React from 'react';

type GiftIconProps = React.SVGProps<SVGSVGElement> & { fillColor?: string };

export const GiftIcon: React.FC<GiftIconProps> = ({ fillColor = '#15803D', ...props }) => {
  return (
    <svg
      width="27"
      height="25"
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M23.5 12.0748V21.6998C23.5 22.1639 23.3156 22.609 22.9874 22.9372C22.6592 23.2654 22.2141 23.4498 21.75 23.4498H5.125C4.66087 23.4498 4.21575 23.2654 3.88756 22.9372C3.55937 22.609 3.375 22.1639 3.375 21.6998V12.0748M13 4.63727C13 4.03156 12.8204 3.43946 12.4839 2.93584C12.1474 2.43221 11.6691 2.03968 11.1095 1.80789C10.5499 1.57609 9.9341 1.51545 9.34004 1.63361C8.74597 1.75178 8.20028 2.04346 7.77199 2.47175C7.34369 2.90005 7.05201 3.44574 6.93384 4.03981C6.81568 4.63387 6.87633 5.24964 7.10812 5.80924C7.33991 6.36884 7.73244 6.84713 8.23607 7.18364C8.73969 7.52016 9.33179 7.69977 9.9375 7.69977H13M13 4.63727V7.69977M13 4.63727C13 4.03156 13.1796 3.43946 13.5161 2.93584C13.8526 2.43221 14.3309 2.03968 14.8905 1.80789C15.4501 1.57609 16.0659 1.51545 16.66 1.63361C17.254 1.75178 17.7997 2.04346 18.228 2.47175C18.6563 2.90005 18.948 3.44574 19.0662 4.03981C19.1843 4.63387 19.1237 5.24964 18.8919 5.80924C18.6601 6.36884 18.2676 6.84713 17.7639 7.18364C17.2603 7.52016 16.6682 7.69977 16.0625 7.69977H13M13 7.69977V23.4498M2.9375 12.0748H23.9375C24.662 12.0748 25.25 11.4868 25.25 10.7623V9.01227C25.25 8.28777 24.662 7.69977 23.9375 7.69977H2.9375C2.213 7.69977 1.625 8.28777 1.625 9.01227V10.7623C1.625 11.4868 2.213 12.0748 2.9375 12.0748Z"
        stroke={fillColor}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
