import React from 'react';

import { PaypalIcon } from 'src/components/Icons';
import { useISIntl } from 'src/i18n';
import { useIsPaypalVStripeVariantFlagEnabled } from 'src/payments/hooks/useIsPaypalVStripeVariantFlagEnabled';
import { paypalBoxMessages } from './messages';

export const PaypalBaseBox = ({
  icon,
  actionElement,
  footer
}: {
  icon?: JSX.Element;
  actionElement: JSX.Element;
  footer?: JSX.Element;
}): JSX.Element => {
  const { fta } = useISIntl();

  const isPaypalVStripeVariantFlagEnabled = useIsPaypalVStripeVariantFlagEnabled();

  const logoText = () => {
    if (isPaypalVStripeVariantFlagEnabled) {
      return fta(paypalBoxMessages.paypalVenmo);
    }

    return fta(paypalBoxMessages.creditDebitPaypal);
  };

  return (
    <div
      data-testid="paypalBaseBox"
      style={{
        border: '1px solid'
      }}
      className="flex flex-column justify-between rounded-[0.4rem] !border-gray-300 w-full bg-white">
      <div className="flex flex-row justify-between px-4 py-3">
        <div className="max-w-[70%] grid grid-cols-1 gap-y-[0.4rem]">
          <div className="flex flex-row justify-start gap-2">
            <PaypalIcon />
            {icon}
          </div>
          <p className="text-lg leading-5 font-medium text-[#374151]">{logoText()}</p>
        </div>
        {actionElement}
      </div>
      {footer}
    </div>
  );
};
