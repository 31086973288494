import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { Provider } from 'mobx-react';

import { FeatureFlagsProvider } from 'src/feature-flags';
import { IntlProvider } from 'src/i18n';
import locationModel from 'src/models/LocationModel';
import { PaypalAccountProvider, StripeAccountProvider } from 'src/payments';
import { AppStore } from 'src/stores/AppStore';
import environmentStore from 'src/stores/EnvironmentStore';
import App from './App';
import { DialogModalProvider } from './DialogModal/DialogModalContext';
import { ModalManagerContextProvider } from './ModalManagerContext';

const queryClient = new QueryClient();

const Root = (props: { store: AppStore }) => {
  
  return (
    <Provider
      settingList={props.store.user.settingList}
      store={props.store}
      location={locationModel}
      environment={environmentStore}>
      <QueryClientProvider client={queryClient}>
        <IntlProvider>
          <FlagsmithProvider flagsmith={flagsmith} serverState={environmentStore.flagsmithState}>
            <FeatureFlagsProvider>
              <DialogModalProvider>
                <PaypalAccountProvider>
                  <StripeAccountProvider>
                    <ModalManagerContextProvider>
                      <App {...props} />
                    </ModalManagerContextProvider>
                  </StripeAccountProvider>
                </PaypalAccountProvider>
              </DialogModalProvider>
            </FeatureFlagsProvider>
          </FlagsmithProvider>
        </IntlProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default Root;
