import React, { SVGProps } from 'react';

export const UploadCloudIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.875 15.1997C2.45875 15.1997 0.5 13.241 0.5 10.8247C0.5 8.56424 2.21433 6.70418 4.41377 6.47373C4.30691 6.06698 4.25 5.63999 4.25 5.19971C4.25 2.43828 6.48858 0.199707 9.25 0.199707C11.5986 0.199707 13.5689 1.81893 14.1056 4.00189C14.3572 3.96748 14.614 3.94971 14.875 3.94971C17.9816 3.94971 20.5 6.46811 20.5 9.57471C20.5 12.6813 17.9816 15.1997 14.875 15.1997H11.75V10.7175L13.3661 12.3336C13.8543 12.8217 14.6457 12.8217 15.1339 12.3336C15.622 11.8454 15.622 11.054 15.1339 10.5658L11.3839 6.81582C10.8957 6.32767 10.1043 6.32767 9.61612 6.81582L5.86612 10.5658C5.37796 11.054 5.37796 11.8454 5.86612 12.3336C6.35427 12.8217 7.14573 12.8217 7.63388 12.3336L9.25 10.7175L9.25 15.1997H4.875Z"
        fill="#FC5B31"
      />
      <path
        d="M9.25 15.1997H11.75L11.75 21.4497C11.75 22.1401 11.1904 22.6997 10.5 22.6997C9.80964 22.6997 9.25 22.1401 9.25 21.4497L9.25 15.1997Z"
        fill="#FC5B31"
      />
    </svg>
  );
};
