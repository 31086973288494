import React from 'react';
import { observer } from 'mobx-react';
import { injectIntl, defineMessages } from 'src/i18n';
import { compose } from 'src/util/functions';
import { Cadence } from 'src/models/UserModel';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISIntl } from 'src/i18n/utils';

interface Prop {
  onOpen: () => void;
  loading: boolean;
  intl?: ISIntl;
  label?: string;
  subLabel?: string;
  cadence?: string;
}

const messages = defineMessages({
  upgradeNow: {
    id: 'checkout.action.upgrade',
    defaultMessage: 'upgrade now'
  },
  loading: {
    id: 'checkout.action.loading',
    defaultMessage: 'loading'
  },
  upgrading: {
    id: 'checkout.action.upgrading',
    defaultMessage: 'upgrading'
  }
});

export class Checkout extends React.Component<Prop, any> {
  handleOpen(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.props.onOpen();
  }
  render() {
    const loading = this.props.loading;
    const { fta } = this.props.intl!;
    const btnText = this.props.label || fta(messages.upgradeNow);
    const btnClassNames = cn(
      'btn',
      'btn-block',
      'btn-prime',
      `btn-checkout-${this.props.cadence || Cadence.MONTHLY}`
    );

    return (
      <div className={loading ? 'stripe-checkout loading' : 'stripe-checkout'}>
        <button className={btnClassNames} onClick={(e) => this.handleOpen(e)} disabled={loading}>
          <div className={loading ? 'loader' : 'label'}>
            {loading ? <FontAwesomeIcon icon="spinner" size="lg" color="black" spin /> : btnText}
          </div>
          {!loading && <div className="sub-label">{this.props.subLabel}</div>}
        </button>
      </div>
    );
  }
}

export default compose(injectIntl, observer)(Checkout);
