import React from 'react';
import cn from 'classnames';

interface Props extends React.HTMLProps<HTMLAnchorElement> {
  isActive?: boolean;
  isDisabled?: boolean;
  customClasses?: string;
}

const NavMenuItem = ({ children, isActive = false, customClasses, ...rest }: Props) => {
  const classes = cn(`${customClasses ? customClasses : ''}`, {
    active: isActive
  });
  return (
    <a rel="nofollow" className={classes} {...rest}>
      {children}
    </a>
  );
};

export default NavMenuItem;
