import React from 'react';
import env from 'src/stores/EnvironmentStore';
import './Footer.css';
import { getLocalePath, getLocaleTitle } from 'src/i18n/enabledLocales';
import { injectIntl, defineMessages } from 'src/i18n';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { getCurrentYear } from 'src/util/date';

const messages = defineMessages({
  column0Header: {
    id: 'footer.column.0.header',
    defaultMessage: 'Create an Invoice'
  },
  column1Header: {
    id: 'footer.column.1.header',
    defaultMessage: 'Invoice Tools'
  },
  column2Header: {
    id: 'footer.column.2.header',
    defaultMessage: 'Contact'
  }
});

@injectIntl
@observer
export default class Footer extends React.Component<any, any> {
  render() {
    const baseUrl = `https://${env.appProxyHost}`;

    return (
      <div className="footer">
        <div className="footer-body">
          <div className="container d-print-none">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <h4>
                  <FormattedMessage {...messages.column0Header} />
                </h4>
                <ul className="nav nav-pills">
                  <li>
                    <a
                      href={baseUrl + getLocalePath('en-US')}
                      title={`${getLocaleTitle('en-US')} | from Invoice Simple`}>
                      🇺🇸 {getLocaleTitle('en-US')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={baseUrl + getLocalePath('fr-FR')}
                      title={`${getLocaleTitle('fr-FR')} | de facture simple`}>
                      🇫🇷 {getLocaleTitle('fr-FR')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={baseUrl + getLocalePath('es-ES')}
                      title={`${getLocaleTitle('es-ES')} | de factura simple`}>
                      🇪🇸 {getLocaleTitle('es-ES')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={baseUrl + getLocalePath('nl-NL')}
                      title={`${getLocaleTitle('nl-NL')} | van factuur eenvoudig`}>
                      🇳🇱 {getLocaleTitle('nl-NL')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-6">
                <h4>
                  <FormattedMessage {...messages.column1Header} />
                </h4>
                <ul className="nav nav-pills">
                  <li>
                    <a href={`${baseUrl}/invoice-generator`}>Invoice Generator</a>
                  </li>
                  <li>
                    <a href={`${baseUrl}/estimate-template/estimate-maker`}>Estimate Maker</a>
                  </li>
                  <li>
                    <a href={`${baseUrl}/receipt-template/receipt-maker`}>Receipt Maker</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-6">
                <h4>
                  <FormattedMessage {...messages.column2Header} />
                </h4>
                <ul className="nav nav-pills">
                  <li>
                    <a
                      href="mailto:hello@invoicesimple.com?Subject=Hello"
                      target="_blank"
                      rel="noopener noreferrer">
                      hello@invoicesimple.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://goo.gl/maps/KojxJCmhrC82"
                      target="_blank"
                      rel="noopener noreferrer">
                      Vancouver, BC
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-6">
                <h4>&copy; {getCurrentYear()} Invoice Simple</h4>
                <ul className="nav nav-pills">
                  <li>
                    <a href={`${baseUrl}/terms/`}>Terms of Use</a>
                  </li>
                  <li>
                    <a href={`${baseUrl}/privacy/`}>Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
