import React, { FC } from 'react';
import { OrangeWarningIcon } from 'src/components/Icons';
import { PaypalBaseBox } from '../../PaypalBaseBox';
import { BlueCTAButton } from '../../../Common';
import { usePaymentOnboardModal } from 'src/payments/hooks/usePaymentsOnboardModal';
import { defineMessages, useISIntl } from 'src/i18n';
import { PaymentsCurrencyMismatchFixModal } from 'src/payments/components/PaymentsModals';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { getPaymentEventSource } from 'src/payments/utils';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';

const messages = defineMessages({
  fix: {
    id: 'payments.box.currencyMismatch.fix',
    defaultMessage: 'Fix'
  }
});

const PaypalCurrencyMismatchBoxComponent: FC<
  {
    onFixUserCurrencyMismatchHandler: () => void;
    footer?: JSX.Element;
  } & { store: AppStore }
> = ({ onFixUserCurrencyMismatchHandler, footer, store: { user } }) => {
  const paypalSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource()
  };
  const { isOpen, showModal, hideModal, origin } = usePaymentOnboardModal();
  const { ft } = useISIntl();

  const setCurrency = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.modalCurrencyMismatchFix
    });
    onFixUserCurrencyMismatchHandler();
    hideModal();
  };

  const onClick = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.tileCurrencyMismatchFix
    });
    showModal();
  };

  const onClickXIcon = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.modalCurrencyMismatchFixClose
    });
    hideModal();
  };

  const paymentsViewedMeta = {
    ...paypalSharedMetadata,
    component: PaymentEventComponent.tileCurrencyMismatchFix
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <div data-testid="paypalCurrencyMismatchBox">
      <PaypalBaseBox
        icon={<OrangeWarningIcon />}
        actionElement={<BlueCTAButton onClick={onClick}>{ft(messages.fix)}</BlueCTAButton>}
        footer={footer}
      />
      <PaymentsCurrencyMismatchFixModal
        activePaymentProvider={PaymentEventActivePaymentProvider.paypal}
        isOpen={isOpen}
        hideModal={onClickXIcon}
        setCurrency={setCurrency}
        origin={origin}
      />
    </div>
  );
};

export const PaypalCurrencyMismatchBox = injectStore(PaypalCurrencyMismatchBoxComponent);
