import { SettingKeys } from '@invoice-simple/common';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import React from 'react';

import { defineMessages, useISIntl } from 'src/i18n';
import alertModel from 'src/models/AlertModel';
import InvoiceModel from 'src/models/InvoiceModel';
import UserModel from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { handleMakeInvoice } from 'src/util/estimateToInvoice';

const messages = defineMessages({
  makeInvoice: {
    id: 'kebab.make.invoice',
    defaultMessage: 'Make Invoice'
  },
  signed: {
    id: 'doc.history.signed',
    defaultMessage: 'Signed'
  }
});

interface Props {
  invoice: InvoiceModel;
  store: AppStore;
}

export const SignedEstimateMakeInvoice = observer(({ invoice, store }: Props) => {
  const intl = useISIntl();
  const user = UserModel.getInstance();
  const dateFormat = user.settingList.getSetting(SettingKeys.LocaleDateFormat)?.value || 'PP';
  const formattedSignedDate = format(new Date(invoice.setting.estimateSignedAt!), dateFormat);

  return (
    <div
      data-testid="estimate-client-signature-wrapper"
      className="make-invoice-wrapper"
      onClick={() => handleMakeInvoice({ store, intl, alertModel })}>
      <div data-testid="estimate-client-signature-title" className="signed-date">
        {intl.formatMessage(messages.signed) + ' ' + formattedSignedDate}
      </div>
      <div data-testid="estimate-client-signature-button" className="make-invoice-link">
        {intl.formatMessage(messages.makeInvoice)}
      </div>
    </div>
  );
});
