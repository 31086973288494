import React from 'react';

export const BackgroundGradient: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 1440 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...props}>
      <path
        d="M0 14.5251H1440V201.899C1440 201.899 1190.4 260 720 260C249.6 260 0 201.899 0 201.899V14.5251Z"
        fill="#657DF7"
      />
      <path
        d="M0 0H1440V187.374C1440 187.374 1190.4 245.475 720 245.475C249.6 245.475 0 187.374 0 187.374V0Z"
        fill="url(#paint0_linear_40_1594)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_40_1594"
          x1="720"
          y1="0"
          x2="720"
          y2="187.374"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#455EDE" />
          <stop offset="1" stopColor="#635BFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
