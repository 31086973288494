import React from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { Cadence } from 'src/models/UserModel';
import { tierCardData } from '../../../utils';
import { TierCard } from './TierCard';

interface DesktopTierCardsProps {
  selectedTier: SubscriptionTier;
  selectedInterval: Cadence;
  loading: boolean;
  handleSubscriptionPurchase: (tier: SubscriptionTier) => void;
}

export const DesktopTierCards = ({
  selectedTier,
  selectedInterval,
  loading,
  handleSubscriptionPurchase
}: DesktopTierCardsProps) => {
  const tierData = tierCardData();

  return (
    <div
      className="md:flex flex-row justify-center items-end gap-5 lg:gap-10 hidden"
      data-testid="desktop-tier-cards">
      {tierData.map((tier) => {
        return (
          <TierCard
            key={tier.name}
            tier={tier}
            selectedTier={selectedTier}
            selectedInterval={selectedInterval}
            loading={loading}
            handleSubscriptionPurchase={handleSubscriptionPurchase}
          />
        );
      })}
    </div>
  );
};
