import React from 'react';

import { messages } from 'src/components/SubscriptionPaywall/utils';
import { useISIntl } from 'src/i18n';
import { SubscriptionBadgeV2 } from '../../SubscriptionBadgeV2';

interface CardHeaderProps {
  name: string;
  description: string;
  isPlusTier: boolean;
  subscriptionBadgeDiscount?: string;
}

export const CardHeader = ({
  name,
  description,
  isPlusTier,
  subscriptionBadgeDiscount
}: CardHeaderProps) => {
  const { ft } = useISIntl();

  return (
    <>
      <div className="flex items-center gap-6">
        <h3 className="text-3xl text-gray-900 capitalize font-semibold">{name}</h3>
        {subscriptionBadgeDiscount && (
          <SubscriptionBadgeV2
            text={ft(messages.discountedPaywallBadge, {
              discount: subscriptionBadgeDiscount
            })}
            variant={`${isPlusTier ? 'dark' : 'light'}`}
          />
        )}
      </div>
      <p className="mt-4 mb-8 text-gray-800 text-xl lg:text-2xl font-medium h-22 lg:h-auto">
        {description}
      </p>
    </>
  );
};
