import { observable, action } from 'mobx';

class UIStore {
  @observable
  isNavMenuCollapsed: boolean = true;

  @action.bound
  closeNavMenu() {
    this.isNavMenuCollapsed = true;
  }

  @action.bound
  openNavMenu() {
    this.isNavMenuCollapsed = false;
  }

  @action.bound
  toggleNavMenu() {
    this.isNavMenuCollapsed = !this.isNavMenuCollapsed;
  }
}

export default new UIStore();
