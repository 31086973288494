import React, { useState } from 'react';

interface ItemQuantityInputProps {
  placeholder: string;
  disabled: boolean;
  style?: React.CSSProperties;
  value: number | undefined;
  onChange: (quantity: number) => void;
}

export default function ItemQuantityInput(props: ItemQuantityInputProps): JSX.Element {
  const [rawValue, setRawValue] = useState(props.value?.toString());

  const RE_NUMBER = /^-?((\.\d+)|(\d+(\.\d+)?))$/;
  const RE_INCOMPLETE_NUMBER = /^([+-]|\.0*|[+-]\.0*|[+-]?\d+\.)?$/;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    const value = e.target.value;
    if (value === '') {
      setRawValue(value);
      props.onChange(0);
    } else if (RE_NUMBER.test(value) || RE_INCOMPLETE_NUMBER.test(value)) {
      setRawValue(value);
      if (!isNaN(Number(value))) {
        props.onChange(Number(value));
      }
    }
  }

  function countDecimals(value: number) {
    if (Math.floor(value) === value) {
      return 0;
    }
    return value.toString().split('.')[1].length || 0;
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    const KEYCODE_UP = 38;
    const KEYCODE_DOWN = 40;
    if (e.keyCode === KEYCODE_UP) {
      e.preventDefault();
      if (!isNaN(Number(rawValue))) {
        const newValue = (Number(rawValue) + 1).toFixed(countDecimals(Number(rawValue)));
        setRawValue(newValue);
        props.onChange(Number(newValue));
      }
    }
    if (e.keyCode === KEYCODE_DOWN) {
      e.preventDefault();
      if (!isNaN(Number(rawValue))) {
        const newValue = (Number(rawValue) - 1).toFixed(countDecimals(Number(rawValue)));
        setRawValue(newValue);
        props.onChange(Number(newValue));
      }
    }
  }

  return (
    <input
      type="text"
      className="item-quantity-input"
      style={props.style}
      placeholder={props.placeholder}
      disabled={props.disabled}
      value={rawValue}
      onChange={handleChange}
      onBlur={(): void => setRawValue(props.value?.toString())}
      onKeyDown={handleKeyDown}
      maxLength={15}
    />
  );
}
