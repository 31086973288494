import React, { SVGProps } from 'react';

export const PhotographIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.25 4.69971C8.55964 4.69971 8 5.25935 8 5.94971C8 6.64006 8.55964 7.19971 9.25 7.19971H16.75C17.4404 7.19971 18 6.64006 18 5.94971C18 5.25935 17.4404 4.69971 16.75 4.69971H9.25Z"
        fill="#FF5721"
      />
      <path
        d="M5.5 9.69971C5.5 9.00935 6.05964 8.44971 6.75 8.44971H19.25C19.9404 8.44971 20.5 9.00935 20.5 9.69971C20.5 10.3901 19.9404 10.9497 19.25 10.9497H6.75C6.05964 10.9497 5.5 10.3901 5.5 9.69971Z"
        fill="#FF5721"
      />
      <path
        d="M3 14.6997C3 13.319 4.11929 12.1997 5.5 12.1997H20.5C21.8807 12.1997 23 13.319 23 14.6997V19.6997C23 21.0804 21.8807 22.1997 20.5 22.1997H5.5C4.11929 22.1997 3 21.0804 3 19.6997V14.6997Z"
        fill="#FF5721"
      />
    </svg>
  );
};
