import React, { FC } from 'react';
import { startPaypalOnboardingNoTerm } from 'src/payments';
import { getPaymentEventSource, onError, saveConsent } from 'src/payments/utils/utils';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { defineMessages } from 'src/i18n';
import { TopContainer, ValueProps, ButtonContainer } from '../../PaymentBasicElements';
import {
  OnboardModalMessages,
  OnboardModalProps,
  PaymentEventActivePaymentProvider,
  PaymentEventComponent
} from 'src/payments/types';
import { PaypalModalImg } from './PaypalModalBase';
import { ConsentType } from '@invoice-simple/common';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';

const messages = defineMessages({
  header: {
    id: 'paypalModal.prompt.title',
    defaultMessage: 'Set Up PayPal'
  },
  description1: {
    id: 'paypalModal.prompt.description1',
    defaultMessage: "Here's what you'll need"
  },
  listItem1Description: {
    id: 'paypalModal.prompt.listItem1Description',
    defaultMessage:
      'Basic details about your business, such as business type, address, and phone number'
  },

  listItem2Description: {
    id: 'paypalModal.prompt.listItem2Description',
    defaultMessage: 'Your Social Security Number and/or Employer Identification Number (EIN)'
  },

  listItem3Description: {
    id: 'paypalModal.prompt.listItem3Description',
    defaultMessage: "Bank account info for where you'd like to receive payments"
  },

  tosPrefix: {
    id: 'paypalModal.prompt.tosPrefix',
    defaultMessage: "By proceeding, you're agreeing to our"
  },
  tos: {
    id: 'paypalModal.prompt.tos',
    defaultMessage: 'Payments Terms of Use'
  },
  goToPaypal: { id: 'paypalModal.prompt.goToPaypal', defaultMessage: 'Continue To PayPal' }
});

const PaypalInitialModalComponent: FC<
  OnboardModalProps & {
    store: AppStore;
  }
> = ({ closeModal, store: { user } }) => {
  const ft = (message: OnboardModalMessages) => message.defaultMessage;
  const paypalSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource()
  };
  const handleConsent = async () => {
    const consentMessage = `${messages.tosPrefix} ${messages.tos}`;
    await saveConsent({
      user,
      consentMessage,
      consentValue: 'v0',
      consentType: ConsentType.PAYPAL_ONBOARDING
    });
  };

  const handleClick = async () => {
    try {
      user.events.trackAction('payments-cta', {
        ...paypalSharedMetadata,
        component: PaymentEventComponent.modalInitial
      });
      await startPaypalOnboardingNoTerm({
        isNewTab: true,
        paypalRedesign: true
      });
      handleConsent();

      closeModal();
    } catch (error) {
      onError({ title: 'Error initiating PayPal onboarding', body: error.message });
    }
  };

  const onClickXIcon = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.modalCloseInitial
    });
    closeModal();
  };

  const onClickTosLink = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.tos
    });
  };

  const paymentsViewedMeta = {
    ...paypalSharedMetadata,
    component: PaymentEventComponent.modalInitial
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <>
      <TopContainer
        onClickXIcon={onClickXIcon}
        headerMessage={ft(messages.header)}
        dataTestId="paypal--modal-x-button"
      />
      <main>
        <PaypalModalImg />
        <ValueProps>
          <b>{ft(messages.description1)}</b>
          <ul className={'py-0 p-8 marker:font-bold'}>
            <li className={'my-6'}>{ft(messages.listItem1Description)}</li>
            <li className={'my-6'}>{ft(messages.listItem2Description)}</li>
            <li className={'my-6'}>{ft(messages.listItem3Description)}</li>
          </ul>
          <p>
            {ft(messages.tosPrefix)}&nbsp;
            <a
              onClick={onClickTosLink}
              data-testid="paypal--modal-tos-link"
              className={'text-[#2563EB] font-bold'}
              href={'https://www.invoicesimple.com/payments-terms'}
              target="_blank"
              rel="noopener noreferrer">
              {ft(messages.tos)}
            </a>
          </p>
        </ValueProps>
      </main>
      <ButtonContainer
        dataTestId="paypal--modal-submit-button"
        handleClick={handleClick}
        buttonMessage={ft(messages.goToPaypal)}
      />
    </>
  );
};

export const PaypalInitialModal = injectStore(PaypalInitialModalComponent);
