import { SettingKeys } from '@invoice-simple/common';
import React from 'react';

import { Option, RadioButton } from '../../../components/shared/RadioButton';
import SettingModel from 'src/models/SettingModel';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';

interface PaymentPassingFeesSurchargeProps {
  setting: SettingModel | undefined;
  options: Option[];
  defaultValue?: string;
  store: AppStore;
}

export enum PassingFeesType {
  MARKUP = 0,
  SURCHARGE = 1
}

export const FeesTypeSetting = ({
  setting,
  options,
  defaultValue,
  store
}: PaymentPassingFeesSurchargeProps) => {
  const handleFeesTypeChange = (value: number) => {
    const feature = 'payments-surcharge-invoice-cover-transaction-fees';
    const alwaysAddSurchargefeature = 'payments-surcharge-invoice-always-add-surcharge';
    setting?.setValue(value);
    store.user.events.trackButtonClick({
      text: value === PassingFeesType.SURCHARGE ? 'Surcharge' : 'Mark up',
      action: 'clicked',
      value: value === PassingFeesType.SURCHARGE ? 'Surcharge' : 'Mark up',
      feature
    });
    if (value !== PassingFeesType.SURCHARGE) {
      const alwaysAddsurchargeValue = false;
      store.user.settingList
        .getSetting(SettingKeys.PaymentAlwaysAddSurcharge)
        ?.setValue(alwaysAddsurchargeValue);
      store.user.events.trackButtonClick({
        text: 'Always add surcharge',
        action: 'clicked',
        value: alwaysAddsurchargeValue,
        feature: alwaysAddSurchargefeature
      });
    } else {
      store.user.events.trackAction('surcharge-turned-on');
    }
  };

  return (
    <div>
      <RadioButton
        options={options}
        selectedOption={Number(setting?.value) || Number(defaultValue)}
        onChange={(e) => handleFeesTypeChange(e)}
      />
    </div>
  );
};

export const FeesTypeSettingFormGroup = injectStore(FeesTypeSetting);
