import { v4 as uuid } from 'uuid';
import { fromPromise } from 'mobx-utils';
import UserModel from 'src/models/UserModel';
import { syncTransactions } from 'src/models/syncTransactions';

export interface SyncTransaction {
  resultPromise: Promise<Parse.Object>;
  transactionId: string;
}

export const syncEntity = ({ entity }: { entity: Parse.Object }): SyncTransaction => {
  const user = UserModel.getInstance();
  const currentAccount = user.getParseAccount();

  if (entity.has('account')) {
    if (currentAccount.id !== entity.get('account').id) {
      throw new Error('Current account is different from entity account');
    }
  }

  entity.set('updated', new Date().valueOf());
  entity.set('account', currentAccount);

  const resultPromise = entity.save();

  const transactionId = uuid();
  syncTransactions[transactionId] = fromPromise(resultPromise);

  resultPromise.then(() => {
    user.debounceMobileSync();
  });

  return {
    resultPromise,
    transactionId
  };
};
