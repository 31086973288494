import { ToggleSwitch } from '../ToggleSwitch/';
import React from 'react';

interface IProps {
  disabled: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
}
export const ClientSignatureToggle = ({ disabled, checked, onChange }: IProps) => {
  return (
    <div className="col-sm-8 col-md">
      <ToggleSwitch checked={checked} onChange={onChange} disabled={disabled} />
    </div>
  );
};
