import { defineMessages } from 'src/i18n';

export const messages = defineMessages({
  alertCheckoutSuccessTitle: {
    id: 'app.alert.checkout.success.title',
    defaultMessage: 'Thanks for paying!'
  },
  alertPurchaseTitle: {
    id: 'invoice.list.purchase.alert.success.title',
    defaultMessage: 'Upgraded to unlimited!'
  },
  alertTierPurchaseTitle: {
    id: 'purchase.alert.title.tier',
    defaultMessage: 'Upgraded to {tier}!'
  },
  alertPurchaseBody: {
    id: 'invoice.list.purchase.alert.success.body',
    defaultMessage: 'Thank you for your payment.'
  },
  alertPurchaseBodySignup: {
    id: 'signup.purchase.alert.success.body',
    defaultMessage: `Please create an account with the email {email} to save your subscription.`
  },
  alertPurchaseBodyLogin: {
    id: 'login.purchase.alert.success.body',
    defaultMessage: 'Please login or create an account to save your subscription.'
  },
  alertPurchaseBodySignupNoEmail: {
    id: 'signup.purchase.alert.success.body.noEmail',
    defaultMessage: 'Please create an account to save your subscription.'
  },
  purchaseFailedAlertTitle: {
    id: 'invoice.list.purchase.alert.failure.title',
    defaultMessage: 'Subscription purchase failed'
  },
  purchaseFailedAlertBody: {
    id: 'invoice.list.purchase.alert.failure.body',
    defaultMessage: 'An error occurred while purchasing your subscription.'
  },
  premiumUpgradeSuccessAppLink: {
    id: 'subscription.premium.alert.upgradeSuccessAppLink',
    defaultMessage: 'Access our mobile app <link>here</link>'
  }
});
