import React from 'react';
import injectStore from '../util/injectStore';

class BaseConditionalRenderer extends React.Component<any, any> {
  render() {
    const { store, children, conditionFunction } = this.props;
    const result = conditionFunction(store);
    if (typeof result === 'undefined' || result === null || result === false) {
      return null;
    }
    if (React.Children.count(children) === 0) {
      return null;
    }
    return this.props.children;
  }
}

export const ConditionalRenderer = injectStore(BaseConditionalRenderer);

export const conditionalRenderer = (conditionFunction) => (props) =>
  <ConditionalRenderer conditionFunction={conditionFunction} {...props} />;

export const WhenEditingInvoice = conditionalRenderer((store) => store.location.isEdit);
export const WhenViewingInvoice = conditionalRenderer((store) => !store.location.isEdit);
export const WhenInvoiceEditable = conditionalRenderer((store) => store.location.isEditable);
export const WhenInvoiceNotEditable = conditionalRenderer((store) => !store.location.isEditable);
export const WhenInvoice = conditionalRenderer((store) => store.location.isInvoice);

export const WhenLegacyPdfDownload = conditionalRenderer((store) => {
  const legacyDate = new Date('2018-11-21T00:00:00');
  return store.user.createdAt < legacyDate;
});
