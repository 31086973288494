import { observable, computed, action } from 'mobx';
import { v1 as uuid } from 'uuid';
import Parse from 'parse';
import { Platform } from '@invoice-simple/common';

import { getValueOrDefault } from 'src/util/getValueOrDefault';
import InvoiceModel from './InvoiceModel';

export default class InvoicePhotoModel {
  @observable doc: InvoiceModel;

  @observable remoteId: string;
  @observable url: string;
  @observable md5: string;
  @observable description: string | undefined;
  @observable name: string | undefined;

  get = getValueOrDefault(this.default);

  constructor(doc: InvoiceModel, data: Parse.Object | any = {}) {
    this.doc = doc;
    this.update(data);
  }

  @computed
  public get parseData() {
    return {
      name: this.name,
      description: this.description,
      remoteId: this.remoteId,
      url: this.url,
      md5: this.md5,
      updated: Date.now()
    };
  }

  @action
  public async deletePhoto() {
    await this.doc.deleteInvoicePhoto(this.remoteId);
    this.doc.user.events.trackAction('invoice-photo', {
      platform: Platform.WEB,
      action: 'delete'
    });
    await this.doc.loadInvoicePhotos();
  }

  @action
  public async addPhotoToInvoice(remoteId: string): Promise<void> {
    const newPhoto = {
      name: this.name || '',
      description: this.description || '',
      remoteId: remoteId,
      photoRemoteId: this.remoteId
    };
    this.doc.photos = [...this.doc.photos, newPhoto];
    await this.doc.save();
    this.doc.user.events.trackAction('invoice-photo', {
      platform: Platform.WEB,
      action: 'add',
      number: 1
    });
    await this.doc.loadInvoicePhotos();
  }

  @action
  public async editInvoicePhoto(remoteId: string): Promise<void> {
    const oldPhoto = this.doc.photos.find((photo) => photo.photoRemoteId === remoteId);
    if (!oldPhoto) throw new Error('The invoice photo being edited does not exist.');

    const editedPhoto = {
      name: this.name || '',
      description: this.description || '',
      remoteId: oldPhoto?.remoteId,
      photoRemoteId: this.remoteId
    };

    const newPhotos = this.doc.photos.map((photo) => {
      if (photo.photoRemoteId === remoteId) {
        return editedPhoto;
      }
      return photo;
    });

    this.doc.photos = [...newPhotos];
    await this.doc.save();
    this.doc.user.events.trackAction('invoice-photo', {
      platform: Platform.WEB,
      action: 'update',
      ...(!!this.name && { description: this.name }),
      ...(!!this.description && { additionalDetails: this.description })
    });
    await this.doc.loadInvoicePhotos();
  }

  @action
  private update(data: Parse.Object | any): void {
    this.name = this.get(data, 'name');
    this.description = this.get(data, 'description');
    this.remoteId = this.get(data, 'remoteId');
    this.url = this.get(data, 'url');
    this.md5 = this.get(data, 'md5') && this.get(data, 'md5').replace(/\W/g, '').trim();
  }

  private default(name: string): any {
    return { remoteId: uuid(), name: '', description: '', url: '', md5: '' }[name];
  }
}
