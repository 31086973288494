import React from 'react';
import { observer } from 'mobx-react';
import { defineMessages } from 'src/i18n';
import alertModel from '../../models/AlertModel';
import { useISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';
import { handleMakeInvoice } from 'src/util/estimateToInvoice';

const messages = defineMessages({
  makeInvoice: {
    id: 'kebab.make.invoice',
    defaultMessage: 'Make Invoice'
  }
});

const ConvertToInvoiceButton = (props: { store: AppStore }) => {
  const intl = useISIntl();
  const store = props.store;
  const disabled = store.isLoading;
  const btnClass = disabled ? 'btn disabled' : 'btn';
  return (
    <button
      disabled={disabled}
      className={`${btnClass} btn-convert`}
      onClick={() => handleMakeInvoice({ store, intl, alertModel })}>
      {intl.formatMessage(messages.makeInvoice)}
    </button>
  );
};

export default observer(ConvertToInvoiceButton);
