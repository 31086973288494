import React from 'react';
import InputWithLabel from '../InputWithLabel';
import injectStore from '../../util/injectStore';
import invoiceInputFieldsUIStore from 'src/stores/InvoiceInputFieldsUIStore';
import { injectIntl, defineMessages } from 'src/i18n';
import { compose } from 'src/util/functions';
import { AppStore } from 'src/stores/AppStore';
import { ISIntl } from 'src/i18n/utils';
import { mayBe } from './MaybeRender';

const messages = defineMessages({
  businessOwner: {
    id: 'settings.list.contact.name',
    defaultMessage: 'business owner name'
  }
});

const _BusinessOwnerInput = compose(
  injectIntl,
  injectStore
)(({ store, intl }: { store: AppStore; intl: ISIntl }) => {
  const invoice = store.doc;

  return (
    <InputWithLabel
      baseline
      label={
        <label htmlFor="invoice-company-business-owner" title="Business Owner Name">
          Owner
        </label>
      }
      content={
        <input
          type="text"
          disabled={store.isLoading}
          data-testid="invoice-company-business-owner"
          id="invoice-company-business-owner"
          name="invoice-company-business-owner"
          placeholder={intl.fta(messages.businessOwner)}
          value={invoice.businessOwnerName}
          onChange={(e) => {
            invoice.setBusinessOwner(e.target.value);
          }}
        />
      }
    />
  );
});

export const BusinessOwnerInput = mayBe(
  _BusinessOwnerInput,
  () => invoiceInputFieldsUIStore.shouldShowBusinessWebsite
);
