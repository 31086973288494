import React, { FC } from 'react';
import { useStripeContext } from 'src/payments/contexts/StripeAccountContext';
import { StripeInitialBox } from './StripeInitialBox';
import { StripePendingBox } from './StripePendingBox';
import { StripePendingVerificationBox } from './StripePendingVerificationBox';
import { PaymentMethod, useCTAHandler } from '../useCTAHandler';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { StripeInvoiceAcceptingBox } from './StripeAcceptingBox/StripeInvoiceAcceptingBox';
import { getPaymentEventSource } from 'src/payments/utils';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';

const StripeInvoiceBoxWithStore: FC<{ store: AppStore }> = ({ store }) => {
  const stripeAccountContext = useStripeContext();

  const { handleInitialClick } = useCTAHandler(PaymentMethod.Stripe);

  if (!stripeAccountContext?.isStripeEligible) {
    return <></>;
  }

  const stripeSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    source: getPaymentEventSource()
  };

  const events = store.user.events;

  const {
    isStripeInitial,
    isStripePending,
    isStripePendingVerification,
    isStripeAccepting,
    showStripeModal
  } = stripeAccountContext;

  function handleStripePendingClick() {
    events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.tilePending
    });
    showStripeModal();
  }

  function handleStripePendingVerificationClick() {
    events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.tilePendingVerification
    });
    showStripeModal();
  }

  return (
    <div data-testid="StripeInvoiceBox" id="tailwind">
      {isStripeInitial && (
        <StripeInitialBox onCTAClick={() => handleInitialClick(store, showStripeModal)} />
      )}
      {isStripePending && <StripePendingBox onCTAClick={handleStripePendingClick} />}
      {isStripePendingVerification && (
        <StripePendingVerificationBox onCTAClick={handleStripePendingVerificationClick} />
      )}
      {isStripeAccepting && <StripeInvoiceAcceptingBox />}
    </div>
  );
};

export const InvoiceStripeBox = injectStore(StripeInvoiceBoxWithStore);
