import axios, { AxiosInstance } from 'axios';
import environmentStore from 'src/stores/EnvironmentStore';
import { defaultsStore } from 'src/stores/DefaultsStore';

export const API_V3_BASE_PATH = '/api/v3/app';

export const client: AxiosInstance = axios.create({
  baseURL: environmentStore.appApiUrl,
  timeout: 30000,
  headers: {
    common: {
      'x-is-platform': 'web',
      'x-is-app': 'app.invoicesimple.com',
      'x-is-version': environmentStore.getReactAppVersion(),
      'x-is-installation': environmentStore.installationId,
      'x-is-request-id': defaultsStore.requestId
    }
  }
});
