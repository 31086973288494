import React from 'react';

import cn from 'classnames';
import { observer } from 'mobx-react';

import { getInvoiceTotal } from '@invoice-simple/calculator';
import { ZERO } from '@invoice-simple/common';

import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';

interface Props {
  store: AppStore;
  className?: string;
}

const messages = defineMessages({
  recordPayment: {
    id: 'invoice.recordPaymentButton.recordPayment',
    defaultMessage: 'record payment'
  },
  recordPaymentHint: {
    id: 'invoice.recordPaymentButton.recordPaymentHint',
    defaultMessage: 'record a payment from your client'
  }
});
const InvoiceRecordPaymentButton = (props: Props) => {
  const intl = useISIntl();
  const store = props.store;
  const doc = store.doc;
  const disabled = store.isLoading || doc.isNew || getInvoiceTotal(doc.asUniversal).eq(ZERO);
  const classNames = cn('btn', 'btn-payment', props.className, {
    active: store.location.isPayment
  });

  return (
    <button
      disabled={disabled}
      title={intl.ft(messages.recordPaymentHint)}
      className={classNames}
      onClick={() => (disabled ? null : doc.showPaymentForm())}>
      {intl.fta(messages.recordPayment)}
    </button>
  );
};

export default observer(InvoiceRecordPaymentButton);
