import React from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import TextareaAutosize from 'react-textarea-autosize';

import { defineMessages, injectIntl } from 'src/i18n';
import { Desktop, Mobile } from 'src/components/Responsive';
import { ModalClose } from 'src/components/ModalClose';
import EmailInput from 'src/components/EmailInput';
import Modal from 'src/components/ModalPortal';

import InvoiceModel from 'src/models/InvoiceModel';
import environmentStore from 'src/stores/EnvironmentStore';
import './InvoiceHeader.css';
import './InvoiceEmail.scss';
import { ISIntl } from 'src/i18n/utils';
import { isDepositEligible } from '@invoice-simple/calculator';
import { getURLQueryParam } from 'src/util/url';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { getDaysRemaining } from 'src/util/date';

interface Prop {
  store: AppStore;
  invoice: InvoiceModel;
  onLoad: () => void;
  onSend: (sendEmail: () => void) => void;
  onCancel: () => void;
  onSuccess: () => void;
  onError: (err: Error) => void;
  intl?: ISIntl;
}

const messages = defineMessages({
  sendEmail: {
    id: 'invoice.sendEmail.header',
    defaultMessage: 'send {docType}'
  },
  sendEmailForDeposit: {
    id: 'invoice.sendEmail.emailButtonTitleForDeposit',
    defaultMessage: 'send {docType} for deposit'
  },
  description: {
    id: 'invoice.sendEmail.emailButtonTitle',
    defaultMessage: 'email this {docType} to your client'
  },
  pageTitle: {
    id: 'invoice.sendEmail.pageTitle',
    defaultMessage: 'email {docType} {docNumber}'
  },
  closeEmail: {
    id: 'invoice.sendEmail.closeEmail',
    defaultMessage: 'close {docType} email'
  },
  sendingState: {
    id: 'invoice.sendEmail.sendingState',
    defaultMessage: 'sending...'
  },
  required: {
    id: 'invoice.sendEmail.required',
    defaultMessage: 'required'
  },
  to: {
    id: 'invoice.sendEmail.to',
    defaultMessage: 'to'
  },
  from: {
    id: 'invoice.sendEmail.from',
    defaultMessage: 'from'
  },
  message: {
    id: 'invoice.sendEmail.message',
    defaultMessage: 'message'
  },
  messagePlaceholder: {
    id: 'invoice.sendEmail.messagePlaceholder',
    defaultMessage: 'optional message to your client'
  },
  close: {
    id: 'invoice.sendEmail.close',
    defaultMessage: 'close'
  },
  sendReminderMessagesDueInFuture: {
    defaultMessage:
      'Hello,\n\nThis is a friendly reminder that your invoice is due in {daysRemaining} {daysRemaining, plural, one {day} other {days}}. We appreciate your prompt payment.\n\nIf you already made a payment, please ignore this message.\n\nThank you,\n{businessName}',
    id: 'invoice.sendEmail.reminder.messageDueInFuture'
  },
  sendReminderMessagesOverdue: {
    defaultMessage:
      'Hello,\n\nThis is a friendly reminder that your invoice is overdue. We appreciate your prompt payment.\n\nIf you already made a payment, please ignore this message.\n\nThank you,\n{businessName}',
    id: 'invoice.sendEmail.reminder.messageOverdue'
  },
  sendReminderMessagesDueToday: {
    defaultMessage:
      'Hello,\n\nThis is a friendly reminder that your invoice is due today. We appreciate your prompt payment.\n\nIf you already made a payment, please ignore this message.\n\nThank you,\n{businessName}',
    id: 'invoice.sendEmail.reminder.messageDueToday'
  }
});

@injectIntl
@injectStore
@observer
export class InvoiceEmail extends React.Component<Prop> {
  private shouldSetReminderMessage = getURLQueryParam('message') === 'reminder';

  componentDidMount() {
    this.props.onLoad();
    this.props.invoice.initDraftEmail();

    if (this.shouldSetReminderMessage) {
      this.props.invoice.draftEmail.setMessage(this.getReminderMessage());
    }
  }

  getReminderMessage() {
    const invoice = this.props.invoice;
    const intl = this.props.intl!;
    const businessName = invoice.businessName ?? '';
    const daysRemaining = getDaysRemaining(invoice.getDueDate());

    switch (Math.sign(daysRemaining)) {
      case -1:
        return intl.formatMessage(messages.sendReminderMessagesOverdue, {
          businessName
        });

      case 1:
        return intl.formatMessage(messages.sendReminderMessagesDueInFuture, {
          daysRemaining,
          businessName
        });

      default:
        return intl.formatMessage(messages.sendReminderMessagesDueToday, {
          businessName
        });
    }
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleSend = (e: React.MouseEvent<HTMLButtonElement>) => {
    const user = this.props.store.user;
    e.preventDefault();
    this.props.onSend(() => {
      const message = getURLQueryParam('message');
      if (message === 'reminder') {
        user.events.trackAction('payment-reminder-email-send', {
          invoice: this.props.invoice.id,
          docType: this.props.invoice.docType
        });
      }
      this.props.invoice.sendEmail().then(
        (_res) => {
          this.props.onSuccess();
        },
        (err) => {
          this.props.onError(err);
        }
      );
    });
  };

  render() {
    const invoice = this.props.invoice;
    const email = invoice.draftEmail;
    const { fta, ft, formatMessage } = this.props.intl!;
    const title = ft(messages.pageTitle, { docNumber: invoice.invoiceNo });
    return (
      <div className="invoice-email invoice-header modal-box d-print-none">
        <Helmet>
          <title itemProp="name">{title}</title>
          <meta name="description" content={ft(messages.description)} />
        </Helmet>

        <div className="invoice-email-header">
          <h3>{fta(messages.sendEmail)}</h3>
        </div>

        <div className="invoice-email-body">
          <ModalClose title={ft(messages.closeEmail)} onClick={this.handleCancel} />
          <form>
            <div className="form-group">
              <label htmlFor="invoice-email-to">
                {ft(messages.to)}{' '}
                <small className="text-muted">({formatMessage(messages.required)})</small>
              </label>

              <EmailInput
                key={`email-to-${invoice.remoteId}`}
                autoComplete="new-password"
                disabled={invoice.isLoading}
                name="invoice-email-to"
                placeholder="name@client.com"
                value={email.toEmail}
                onChange={(e) => email.setToEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="invoice-email-from">
                {ft(messages.from)}{' '}
                <small className="text-muted">({formatMessage(messages.required)})</small>
              </label>

              <EmailInput
                key={`email-from-${invoice.remoteId}`}
                disabled={invoice.isLoading}
                name="invoice-email-from"
                placeholder="name@business.com"
                value={email.fromEmail}
                onChange={(e) => email.setFromEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email-message-input">{ft(messages.message)}</label>
              <TextareaAutosize
                style={{ height: 100 }}
                placeholder={ft(messages.messagePlaceholder)}
                id="email-message-input"
                className="email-message-input"
                minRows={4}
                maxRows={20}
                value={email.message}
                onChange={(e) => email.setMessage(e.target.value)}
              />
            </div>

            <div className="form-group">
              <div className="d-flex justify-content-between">
                <button
                  data-testid="send-email-button"
                  className="btn btn-submit btn-prime"
                  onClick={this.handleSend}
                  disabled={
                    invoice.isLoading ||
                    email.isSending ||
                    !email.isValid ||
                    environmentStore.isSnapshot()
                  }>
                  {email.isSending
                    ? fta(messages.sendingState)
                    : fta(
                        isDepositEligible(invoice.asUniversal)
                          ? messages.sendEmailForDeposit
                          : messages.sendEmail
                      )}
                </button>
                <button className="btn btn-cancel" onClick={this.handleCancel}>
                  {ft(messages.close)}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const MobileOnlyModal = (props) => (
  <>
    <Desktop>
      <InvoiceEmail {...props} />
    </Desktop>
    <Mobile>
      <Modal>
        <InvoiceEmail {...props} />
      </Modal>
    </Mobile>
  </>
);

export default MobileOnlyModal;
