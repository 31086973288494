import React from 'react';
import cn from 'classnames';
import { defineMessages } from 'src/i18n';
import injectStore from 'src/util/injectStore';
import currentDocStore from '../../stores/CurrentDocumentStore';

import './InvoiceEmailButton.css';
import { useISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';
import { isDepositEligible } from '@invoice-simple/calculator';

const messages = defineMessages({
  title: {
    id: 'controls.email-document-type.title',
    description: 'Button title to email the document',
    defaultMessage: 'Email the {docType} to your client'
  },
  label: {
    id: 'controls.email-document-type.label',
    description: 'Button label to email the document',
    defaultMessage: 'Email {docType}'
  },
  labelForDeposit: {
    id: 'controls.email-document-type.labelForDeposit',
    description: 'Button label to email the document for deposit',
    defaultMessage: 'Email {docType} For Deposit'
  }
});

const InvoiceEmailButton = (props: { store: AppStore }) => {
  const intl = useISIntl();
  const showEmailForm = () => props.store.doc.showEmailForm();
  const isForDeposit = isDepositEligible(props.store.doc.asUniversal);
  const f = intl.formatMessage;
  const docType = intl.getTitleizedDocumentType(currentDocStore.currentDoc.docType);
  const isActive = props.store.location.isEmail;

  return (
    <>
      <button
        data-testid="invoice-email-button"
        disabled={props.store.isLoading || isActive}
        title={f(messages.title, { docType })}
        className={cn('btn btn-email btn-prime', {
          active: isActive
        })}
        onClick={showEmailForm}>
        {f(isForDeposit ? messages.labelForDeposit : messages.label, { docType })}
      </button>
    </>
  );
};

export default injectStore(InvoiceEmailButton);
