import React from 'react';

import { messages } from 'src/components/SubscriptionPaywall/utils';
import { useISIntl } from 'src/i18n';

interface CardHeaderProps {
  name: string;
  description: string;
  subscriptionBadgeDiscount?: string;
}

export const CardHeader = ({ name, description, subscriptionBadgeDiscount }: CardHeaderProps) => {
  const { ft } = useISIntl();

  return (
    <>
      <div className="flex items-center gap-4">
        <h3 className="text-3xl text-gray-950 font-bold capitalize">{name}</h3>
        {subscriptionBadgeDiscount && (
          <p className="text-2xl text-green-700 font-bold">{`${ft(messages.discountedPaywallBadge, { discount: subscriptionBadgeDiscount })}`}</p>
        )}
      </div>
      <p className="mt-1 mb-3 text-gray-500 text-2xl font-medium max-w-[90%]">{description}</p>
    </>
  );
};
