import { Cookies, useCookies } from 'react-cookie';

type CookieSetFunction = ReturnType<typeof useCookies>[1];
type CookieSetOptions = Parameters<CookieSetFunction>[1];

function getDefaultCookieOptions() {
  return {
    maxAge: 31536000, // 1 year
    path: '/',
    domain: window.location.hostname.replace(/^(www\.|app\.)/, '.'),
    sameSite: 'lax',
    secure: true
  };
}

const cookieStore = new Cookies();

export const setCookie = (key: string, value: any, options: CookieSetOptions = {}): void => {
  return cookieStore.set(key, value, { ...getDefaultCookieOptions(), ...options });
};

export const getCookie = (cookieName: string): any => {
  return cookieStore.get(cookieName);
};

export const removeCookie = (cookieName: string, options: CookieSetOptions = {}): void => {
  return cookieStore.remove(cookieName, { ...getDefaultCookieOptions(), ...options });
};
