import React from 'react';
import { LoadingSpinnerIcon } from 'src/components/Icons';
import { messages } from './messages';
import { useISIntl } from 'src/i18n';

export const PaymentsOrdersLoading: React.FC = () => {
  const { f } = useISIntl();

  return (
    <td colSpan={5} aria-label="empty" id="tailwind">
      <div className="w-full flex flex-col text-center my-10 gap-4 align-middle">
        <div>
          <LoadingSpinnerIcon color={'black'} className="h-16 w-16" />
        </div>
        <div className="font-semibold text-3xl leading-8">{f(messages.paypalPayments)}</div>
        <div className="font-normal text-2xl max-w-md self-center">
          {f(messages.fetchingPaypalDetails)}
        </div>
      </div>
    </td>
  );
};
