import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  youAreAllSet: {
    id: 'referral.modal.all.set',
    defaultMessage: 'You’re All Set'
  },
  friendBonus: {
    id: 'referral.modal.friend.bonus',
    defaultMessage: 'A friend has sent you a referral bonus.'
  },
  freeDocuments: {
    id: 'referral.modal.free.documents',
    defaultMessage: '2 Free Documents'
  },
  freeDocumentsDescription: {
    id: 'referral.modal.free.documents.description',
    defaultMessage: 'Create up to 2 professional invoices or estimates.'
  },
  sendDocument: {
    id: 'referral.modal.send.document',
    defaultMessage: 'Send 1 Document'
  },
  sendDocumentDescription: {
    id: 'referral.modal.send.document.description',
    defaultMessage: 'Send invoices or estimates to customers via SMS or email.'
  },
  referralBonus: {
    id: 'referral.modal.bonus',
    defaultMessage: 'Referral Bonus'
  },
  referralBonusDescription: {
    id: 'referral.modal.bonus.description',
    defaultMessage: 'When you subscribe, you’ll get up to 50% off for 3 months.'
  },
  claimOffer: {
    id: 'referral.modal.claim.offer',
    defaultMessage: 'Claim Offer'
  },
  createInvoice: {
    id: 'referral.modal.create.invoice',
    defaultMessage: 'Create an Invoice'
  },
  alertTierPurchaseTitle: {
    id: 'purchase.alert.title.tier',
    defaultMessage: 'Upgraded to {tier}!'
  },
  welcomeToPlan: {
    id: 'referral.modal.welcome.plan',
    defaultMessage: "Welcome to Invoice Simple's {tier} plan!"
  },
  welcomeToPlanDescriptionMonthly: {
    id: 'referral.modal.welcome.plan.description',
    defaultMessage: "As part of your referral bonus, you've received 50% off your first 3 months."
  },
  welcomeToPlanDescriptionAnnual: {
    id: 'referral.modal.welcome.plan.description.annual',
    defaultMessage: "As part of your referral bonus, you've received 25% off your first year."
  },
  continueButton: {
    id: 'common.continue',
    defaultMessage: 'Continue'
  },
  downloadMobileApp: {
    id: 'referral.modal.download.mobile.app',
    defaultMessage: 'Download Mobile App'
  }
});
