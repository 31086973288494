import React, { FC } from 'react';
import { StripeBaseBox } from '../../StripeBaseBox';
import { GreenCheckMarkIcon } from 'src/components/Icons';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { getPaymentEventSource } from 'src/payments/utils';

const StripeInvoicePaymentToggleBoxComponent: FC<{
  isPaymentSuppressed: boolean;
  store: AppStore;
  onToggleChange: (value: boolean) => void;
}> = ({ isPaymentSuppressed, onToggleChange, store }) => {
  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    component: isPaymentSuppressed
      ? PaymentEventComponent.tileDisabledInvoiceAccepting
      : PaymentEventComponent.tileEnabledInvoiceAccepting
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);

  return (
    <div data-testid="stripeInvoicePaymentToggleBox">
      <StripeBaseBox
        icon={<GreenCheckMarkIcon />}
        actionElement={
          <div className="w-auto max-w-[30%] flex items-center justify-center">
            <ToggleSwitch
              checked={!isPaymentSuppressed}
              onChange={() => onToggleChange(isPaymentSuppressed)}
              disabled={false}
            />
          </div>
        }
      />
    </div>
  );
};

export const StripeInvoicePaymentToggleBox = injectStore(StripeInvoicePaymentToggleBoxComponent);
