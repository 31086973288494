import React from 'react';

import { ISIntl } from 'src/i18n';
import { Cadence } from 'src/models/UserModel';
import { messages } from '../utils/messages';

interface PlanIntervalSelectorProps {
  selectedInterval: Cadence;
  onToggleInterval: (interval: Cadence) => void;
  formatMessage: ISIntl['formatMessage'];
  style?: string;
  selectStyle?: string;
  isHidden: boolean;
  className?: string;
}

export const PlanIntervalSelector = ({
  selectedInterval,
  onToggleInterval,
  formatMessage,
  style,
  selectStyle,
  isHidden,
  className
}: PlanIntervalSelectorProps) => {
  const intervalSelectClass = (interval: Cadence) =>
    `${
      interval === selectedInterval
        ? 'bg-orange-is text-white'
        : `bg-gray-100 text-gray-600 ${selectStyle}`
    } leading-6 relative w-1/2 border-none whitespace-nowrap rounded-md py-3 font-medium sm:w-auto ${style}`;

  const intervalSelectStyle: React.CSSProperties = {
    paddingLeft: '4rem',
    paddingRight: '4rem'
  };

  const toggleInterval = (interval: Cadence) => {
    if (interval !== selectedInterval) {
      onToggleInterval(interval);
    }
  };

  if (isHidden) return null;

  return (
    <div
      data-testid="interval-select"
      className={`relative flex self-center rounded-lg bg-gray-100 sm:p-1 text-xl ${className}`}>
      <button
        type="button"
        data-testid="btn-monthly"
        style={intervalSelectStyle}
        className={intervalSelectClass(Cadence.MONTHLY)}
        onClick={() => toggleInterval(Cadence.MONTHLY)}>
        {formatMessage(messages.monthly)}
      </button>
      <button
        type="button"
        data-testid="btn-annual"
        style={intervalSelectStyle}
        className={intervalSelectClass(Cadence.ANNUAL)}
        onClick={() => toggleInterval(Cadence.ANNUAL)}>
        {formatMessage(messages.annual)}
      </button>
    </div>
  );
};
