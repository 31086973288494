import React from 'react';
import { SVGProps } from 'react';

export const FrameIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.25C3.61929 4.25 2.5 5.36929 2.5 6.75V19.25C2.5 20.6307 3.61929 21.75 5 21.75H20C21.3807 21.75 22.5 20.6307 22.5 19.25V6.75C22.5 5.36929 21.3807 4.25 20 4.25H5ZM20 19.25H5L10 9.25L13.75 16.75L16.25 11.75L20 19.25Z"
        fill="#FF5721"
      />
    </svg>
  );
};
