import React, { FC } from 'react';
import { BlueCTAButton } from '../Common';
import { PaypalBaseBox } from './PaypalBaseBox';
import { useISIntl } from 'src/i18n/utils';
import { paypalBoxMessages } from './messages';
import { OrangeWarningIcon } from 'src/components/Icons';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { getPaymentEventSource } from 'src/payments/utils';

export const PaypalClosedBoxComponent: FC<{ onCTAClick: () => void; store: AppStore }> = ({
  onCTAClick,
  store
}) => {
  const { ft } = useISIntl();

  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    component: PaymentEventComponent.tileClosed
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);

  return (
    <>
      <div data-testid="paypalClosedBox" />
      <PaypalBaseBox
        actionElement={
          <BlueCTAButton onClick={onCTAClick}>{ft(paypalBoxMessages.closedCTA)}</BlueCTAButton>
        }
        icon={<OrangeWarningIcon />}
      />
    </>
  );
};

export const PaypalClosedBox = injectStore(PaypalClosedBoxComponent);
