// Do not update this file, we will remove it after we release is-stripe and new design to all users
import { PaypalWebEventName } from '@invoice-simple/is-events';

export const PaymentEventName: Record<string, PaypalWebEventName> = {
  paymentsEnable: 'payments-enable',
  paymentsDisable: 'payments-disable',
  onboardingStart: 'payments-onboarding-start',
  initialCtaShown: 'payments-initial-cta-shown',
  onboardingContinue: 'payments-onboarding-continue',
  staticOnboarding: 'payments-static-onboarding-viewed',
  staticLearnMore: 'payments-static-learn-more-viewed',
  paymentModalViewed: 'payment-modal-viewed'
} as const;

type ValueOf<T> = T[keyof T];
export type PaymentEvent = ValueOf<typeof PaymentEventName>;
