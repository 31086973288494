import React from 'react';
import { SVGProps } from 'react';

export const ClockIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20ZM11.5 5C11.5 4.44772 11.0523 4 10.5 4C9.94771 4 9.5 4.44772 9.5 5V10C9.5 10.2652 9.60536 10.5196 9.79289 10.7071L13.3284 14.2426C13.719 14.6332 14.3521 14.6332 14.7426 14.2426C15.1332 13.8521 15.1332 13.219 14.7426 12.8284L11.5 9.58579V5Z"
        fill="#FC5B31"
      />
    </svg>
  );
};
