import React from 'react';
import cn from 'classnames';
import './InputWithLabel.scss';
import { Desktop } from './Responsive';

const InputWithLabel = ({ label, content, baseline = false, alwaysShowLabel = false }) => {
  const labelClasses = cn('label', {
    'with-baseline': baseline
  });
  return (
    <div className="input-with-label-wrapper">
      {alwaysShowLabel ? (
        <div className={labelClasses}>{label}</div>
      ) : (
        <Desktop>
          <div className={labelClasses}>{label}</div>
        </Desktop>
      )}

      <div className="content">{content}</div>
    </div>
  );
};

export default InputWithLabel;
