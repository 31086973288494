import { DocType, DocTypes } from '@invoice-simple/common';
import React, { useContext, useEffect } from 'react';
import UserModel from 'src/models/UserModel';
import { PaypalAccountContext } from 'src/payments/contexts/PaypalAccountContext';
import './PaymentMarkupFees.scss';
import { usePaymentsUsability } from 'src/payments/hooks/usePaymentsUsability';
import { useStripeContext } from 'src/payments/contexts';
import { PaymentFees } from '@invoice-simple/calculator/dist/typings/paymentFees/types';

interface PaymentMarkupFeesProps {
  document: {
    docType: DocType;
    paymentSuppressed: boolean | undefined;
    stripePaymentSuppressed: boolean | undefined;
    currencyCode: string | undefined;
    invoiceId: string | undefined;
  };
  onMarkupPaymentFeesButtonClick: (paymentFees: PaymentFees) => void;
  isMarkingUpPaymentFees: boolean;
}

export const PaymentMarkupFees = (props: PaymentMarkupFeesProps) => {
  const { docType, currencyCode, paymentSuppressed, stripePaymentSuppressed } = props.document;
  const paypalAccountContext = useContext(PaypalAccountContext);
  const stripeAccountContext = useStripeContext();
  const { isPaypalUsable, isStripeUsable } = usePaymentsUsability(currencyCode);
  const paypalFees = paypalAccountContext?.getPaypalFees();
  const stripeFees = stripeAccountContext?.getStripeFees;

  const paymentFee: PaymentFees | null = paypalFees || stripeFees || null;

  if (docType !== DocTypes.DOCTYPE_INVOICE || !paymentFee) return null;

  const paypalUsableForSurcharge = isPaypalUsable && !paymentSuppressed && !!paypalFees;
  const stripeUsableForSurcharge = isStripeUsable && !stripePaymentSuppressed && !!stripeFees;
  const isEligibleForPF = paypalUsableForSurcharge || stripeUsableForSurcharge;

  return (
    <PaymentMarkupFeesComponent
      isEligibleForPF={isEligibleForPF}
      paymentFees={paymentFee}
      {...props}
    />
  );
};

const PaymentMarkupFeesComponent = ({
  isMarkingUpPaymentFees,
  isEligibleForPF,
  paymentFees,
  document,
  onMarkupPaymentFeesButtonClick
}: PaymentMarkupFeesProps & { isEligibleForPF: boolean; paymentFees: PaymentFees }) => {
  useEffect(() => {
    if (!document.invoiceId) {
      return;
    }

    UserModel.getInstance().trackAppEventViaApi('paypal-invoice-passingFeesShown', {
      invoiceId: document.invoiceId,
      isEligibleForPF
    });
  }, [document.invoiceId]);

  return (
    <div className="payment-markup-fees">
      <h4>PAYMENT FEES</h4>

      <div className="setting-form-group form-group row">
        <div className="col-md-6 col-lg-12">
          <label className="col-form-label text-justify">
            {isEligibleForPF ? (
              <>
                Add markup to invoice to cover payment processing costs, automatically calculated
                and distributed across items.
              </>
            ) : (
              <>
                Start accepting Online Payments and use this feature to cover your payment
                processing fees.
              </>
            )}
          </label>

          {isEligibleForPF && (
            <div className="mt-2">
              <button
                disabled={isMarkingUpPaymentFees}
                title="Add fees"
                className={`btn btn-block btn-prime btn-markup-payment-fees ${
                  isMarkingUpPaymentFees ? 'animate' : ''
                }`}
                onClick={() => {
                  onMarkupPaymentFeesButtonClick(paymentFees);
                }}>
                <span className="initial">Add fees</span>
                <span className="success">&#10004; Payment fees added to invoice items</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
