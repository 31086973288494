import React from 'react';

import { twMerge } from 'tailwind-merge';

interface SubscriptionBadgeV2Props {
  text: string;
  variant: 'light' | 'dark';
  className?: string;
}

export const SubscriptionBadgeV2 = ({ text, variant, className }: SubscriptionBadgeV2Props) => {
  const color = {
    light: 'bg-green-600/20 text-green-800',
    dark: 'bg-green-800 text-white'
  };

  return (
    <div
      className={twMerge(
        'inline-flex items-center justify-center rounded-full px-4 py-1.5 text-lg font-bold text-nowrap',
        color[variant],
        className
      )}
      data-testid="subscription-badge-v2">
      {text}
    </div>
  );
};
