import { defineMessages, injectIntl, ISIntl } from 'src/i18n';
import React, { useState } from 'react';
import { compose } from 'src/util/functions';
import { PencilIcon } from './PencilIcon';
import { XIcon } from './XIcon';
import './InvoicePhotos.scss';
import { getCloudinaryUrl } from './getCloudinaryUrl';
import InvoicePhotoModel from 'src/models/InvoicePhotoModel';
import { InvoicePhotoData } from './InvoicePhotos';
import { useAlert } from '../../components/Alert/Alert';
import { LoadingSpinnerIcon } from '../Icons';

const messages = defineMessages({
  confirmDelete: {
    id: 'invoice.photos.form.confirmDelete',
    defaultMessage: 'Confirm'
  },
  cancel: {
    id: 'invoice.photos.form.cancel',
    defaultMessage: 'Cancel'
  },
  invoicePhotoRemoveErrorDescription: {
    id: 'invoice.photos.error.remove.description',
    defaultMessage: 'Error removing photo. Please try again.'
  },
  invoicePhotoRemoveErrorTitle: {
    id: 'invoice.photos.error.remove.title',
    defaultMessage: 'Unable to remove photo'
  }
});

interface Props {
  intl: ISIntl;
  onEdit: ({ remoteId, name, description, url }: InvoicePhotoData) => void;
  photo: InvoicePhotoModel;
  openAlert: ReturnType<typeof useAlert>['openAlert'];
}

const Photo = ({ onEdit, intl, photo, openAlert }: Props) => {
  return (
    <div className="invoice-photo">
      <article>
        <header>
          <img src={getCloudinaryUrl(photo.url, 'c_fit,w_400,h_400')} />
        </header>
        <main>
          <strong>{photo.name}</strong>
          <p>{photo.description}</p>
        </main>
        <PhotoActions intl={intl} onEdit={onEdit} photo={photo} openAlert={openAlert} />
      </article>
    </div>
  );
};
export default compose(injectIntl)(Photo);

const PhotoActions = ({ onEdit, intl, photo, openAlert }: Props) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onRemove = async () => {
    setIsLoading(true);
    setIsRemoving(false);
    await photo.deletePhoto().catch(() => {
      setIsLoading(false);
      setIsRemoving(false);
      openAlert({
        description: intl.ft(messages.invoicePhotoRemoveErrorDescription),
        title: intl.ft(messages.invoicePhotoRemoveErrorTitle),
        variant: 'danger'
      });
    });
  };

  if (isLoading) {
    return (
      <footer
        className="invoice-photo-action-footer"
        data-testid="photo-delete-loading"
        id="tailwind">
        <LoadingSpinnerIcon color={'black'} className="w-7 h-7" />
      </footer>
    );
  }

  if (isRemoving) {
    return (
      <footer className="invoice-photo-action-footer" data-testid="confirm">
        <button data-testid="confirm-cancel" onClick={() => setIsRemoving(false)}>
          {intl.ft(messages.cancel)}
        </button>
        <button onClick={onRemove} className="danger">
          {intl.ft(messages.confirmDelete)}
        </button>
      </footer>
    );
  }

  return (
    <footer className="invoice-photo-action-footer">
      <button
        onClick={() =>
          onEdit({
            remoteId: photo.remoteId,
            name: photo.name,
            description: photo.description,
            url: photo.url
          })
        }>
        <PencilIcon />
      </button>
      <button onClick={() => setIsRemoving(true)} data-testid="invoice-photo-remove-button">
        <XIcon />
      </button>
    </footer>
  );
};
