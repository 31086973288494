import { PaymentInstructions } from '@invoice-simple/common';
import { observer } from 'mobx-react';
import React from 'react';

import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';
import './PaymentInstructions.scss';

const messages = defineMessages({
  paypal: {
    id: 'paymentInstructions.paypal',
    defaultMessage: 'PayPal'
  },
  paymentInstructions: {
    id: 'paymentInstructions.paymentLabel',
    defaultMessage: 'payment instructions'
  },
  check: {
    id: 'paymentInstructions.check',
    defaultMessage: 'by check'
  },
  other: {
    id: 'paymentInstructions.other',
    defaultMessage: 'other'
  },
  paymentInfoTitle: {
    id: 'paymentInstructions.title',
    defaultMessage: 'offline payment info'
  }
});

const PaymentInstructions = (props: { paymentInstructions: PaymentInstructions }) => {
  const { fta } = useISIntl();
  const data = props.paymentInstructions;
  if (!data) {
    return null;
  }
  return (
    <div>
      <div className="invoice-detail-footer content-block">
        <div className="payment-instructions">
          <h3 className="payment-instruction-header">{fta(messages.paymentInfoTitle)}</h3>

          {data.bank && (
            <div className="payment-instruction">
              <span className="payment-instruction-label">{fta(messages.paymentInstructions)}</span>
              <span className="payment-instructions-value">{data.bank}</span>
            </div>
          )}

          {data.paypalEmail && (
            <div className="payment-instruction">
              <span className="payment-instruction-label">{fta(messages.paypal)}</span>
              <span className="payment-instructions-value">{data.paypalEmail}</span>
            </div>
          )}

          {data.cheque && (
            <div className="payment-instruction">
              <span className="payment-instruction-label">{fta(messages.check)}</span>
              <span className="payment-instructions-value">{data.cheque}</span>
            </div>
          )}

          {data.other && (
            <div className="payment-instruction">
              <span className="payment-instruction-label">{fta(messages.other)}</span>
              <span className="payment-instructions-value">{data.other}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(PaymentInstructions);
