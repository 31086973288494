import React from 'react';
import InvoiceModel from 'src/models/InvoiceModel';
import { useIsPaypalRedesignFlagEnabled } from 'src/payments/hooks/useIsPaypalRedesignFlagEnabled';
import { AppStore } from 'src/stores/AppStore';
import { InvoiceSimplePayments } from './InvoiceSimplePayments';
import { PaymentEventLocation } from 'src/payments/types';
import { InvoicePaymentToggle } from './InvoicePaymentToggle';
import { SettingKeys, PassingFeesType } from '@invoice-simple/common';
import { InvoicePaymentDeposit } from './InvoicePaymentsDeposit/InvoicePaymentDeposit';
import { InvoicePaymentSurchargeFees } from './InvoicePaymentsSurcharge/InvoicePaymentSurchargeFees';
import { useIsPaymentsEnabled } from 'src/payments/hooks/useIsPaymentsEnabled';

export const InvoicePayments = (props: { store: AppStore }) => {
  const isPaypalRedesignFlagEnabled = useIsPaypalRedesignFlagEnabled();
  const isPaymentsEnabled = useIsPaymentsEnabled();

  if (!isPaymentsEnabled) {
    return null;
  }
  const { store } = props;
  const user = store.user;
  const invoice: InvoiceModel = store.doc;
  return (
    <>
      {isPaypalRedesignFlagEnabled ? (
        <InvoiceSimplePayments />
      ) : (
        <InvoicePaymentToggle
          title="PAYMENTS"
          invoice={invoice}
          onTrackEvent={(eventName, metadata = {}) => {
            user.trackAppEventViaApi(eventName, {
              ...metadata,
              location: metadata.location || PaymentEventLocation.paymentConfigInvoiceEditor
            });
          }}
        />
      )}
      <InvoicePaymentSurchargeFees
        invoice={invoice}
        feesType={
          store.user.settingList.getSetting(SettingKeys.PaymentFeesType)?.valNum === 1
            ? PassingFeesType.SURCHARGE
            : PassingFeesType.MARKUP
        }
      />
      <InvoicePaymentDeposit invoice={invoice} />
    </>
  );
};
