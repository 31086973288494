import React from 'react';

import { observer } from 'mobx-react';
import { defineMessages, injectIntl } from 'src/i18n';

import { AppStore } from '../stores/AppStore';

import './TrialAlert.css';

import flagsmith from 'flagsmith';
import { ISIntl } from 'src/i18n/utils';

interface Prop {
  store: AppStore;
  intl?: ISIntl;
}

const messages = defineMessages({
  trialDaysRemaining: {
    id: 'subscriptionAlert.trialDaysRemaining',
    defaultMessage: 'you have {daysCount} days remaining of your free trial period.'
  },
  trialExpired: {
    id: 'subscriptionAlert.trialExpired',
    defaultMessage: 'your 14 day free web trial has expired.'
  },
  subscriptionExpired: {
    id: 'subscription.title.subscriptionExpired',
    defaultMessage: 'your subscription has expired'
  }
});

@injectIntl
@observer
export default class TrialAlert extends React.Component<Prop, any> {
  handleClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.props.store.nav('subscription');
  }
  getAlertMessage(user) {
    const { ft } = this.props.intl!;
    let message = ft(messages.trialExpired);
    if (user.trialSub && user.trialSub.trialDaysRemaining > 0) {
      message = ft(messages.trialDaysRemaining, { daysCount: user.trialSub.trialDaysRemaining });
    } else if (user.lastPaidSub) {
      message = ft(messages.subscriptionExpired);
    }
    return message;
  }
  render() {
    const user = this.props.store.user;
    const showTrialBanner = this.isHideTrialMessageBannerEnabled()
      ? user.isSubTrial && user.lastPaidSub
      : user.isSubTrial;
    if (showTrialBanner) {
      return (
        <header className="trial-alert d-print-none clearfix">
          <nav className="container">
            <div>
              <a
                className="nav-link nav-link-subscription float-md-right"
                href="/subscription"
                onClick={(e) => this.handleClick(e)}>
                {this.getAlertMessage(user)}
              </a>
            </div>
          </nav>
        </header>
      );
    } else {
      return null;
    }
  }

  isHideTrialMessageBannerEnabled() {
    return flagsmith.hasFeature('hide_trial_message_banner');
  }
}
