import React from 'react';

export enum ModalName {
  None = 'None',
  UnbrandedUpgradePrompt = 'UnbrandedUpgradePrompt',
  SurchargePromoModal = 'SurchargePromoModal',
  PaymentPromo = 'PaymentPromo',
  SurchargeConsent = 'SurchargeConsent',
  AdvocateConsent = 'AdvocateConsent',
  ReactivationPrompt = 'ReactivationPrompt'
}

interface ModalManagerContextProps {
  modalNameVisible: ModalName;
  setModalNameVisible: (modalName: ModalName) => void;
}

export const ModalManagerContext = React.createContext<ModalManagerContextProps>({
  modalNameVisible: ModalName.None,
  setModalNameVisible: () => {}
});

export const useModalManagerContext = () => React.useContext(ModalManagerContext);

export const ModalManagerContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalNameVisible, setModalNameVisible] = React.useState<ModalName>(ModalName.None);

  return (
    <ModalManagerContext.Provider value={{ modalNameVisible, setModalNameVisible }}>
      {children}
    </ModalManagerContext.Provider>
  );
};
