import React from 'react';
import { observer } from 'mobx-react';
import { compose } from 'src/util/functions';

import { WhenInvoiceEditable } from '../conditionalRenderer';
import InvoiceRecordPaymentButton from './InvoiceRecordPaymentButton';
import ConvertToInvoiceButton from './ConvertToInvoiceButton';
import ConfirmInvoiceDeleteButton from '../ConfirmInvoiceDeleteButton';
import CloseInvoiceButton from '../CloseInvoiceButton';
import { Mobile, Desktop } from '../Responsive';

class InvoiceControls extends React.Component<any, any> {
  render() {
    const props = this.props;
    const invoice = props.store.doc;
    return (
      <WhenInvoiceEditable>
        <div className="invoice-footer d-print-none">
          <Mobile>
            {invoice.isInvoice && <InvoiceRecordPaymentButton store={props.store} />}
            {invoice.isEstimate && <ConvertToInvoiceButton store={props.store} />}
            <CloseInvoiceButton />
            <ConfirmInvoiceDeleteButton className="btn-block" />
          </Mobile>
          <Desktop>
            <CloseInvoiceButton />
            <ConfirmInvoiceDeleteButton />
          </Desktop>
        </div>
      </WhenInvoiceEditable>
    );
  }
}

export default compose(observer)(InvoiceControls);
