import { observable, computed, action, reaction, IReactionDisposer, comparer } from 'mobx';
import compact from 'lodash/compact';

import Parse from 'parse';
import { v4 as uuid } from 'uuid';
import InvoiceModel from './InvoiceModel';
import UserModel from './UserModel';
import ClientModel from './ClientModel';

import { getByRemoteId } from 'src/apis/clientAPI';

import EnvironmentStore from 'src/stores/EnvironmentStore';
import { getValueOrDefault } from 'src/util/getValueOrDefault';
import { SyncExecutionQueue } from 'src/util/syncQueue';

export default class InvoiceClientModel {
  static createFromInvoiceData(invoice: InvoiceModel, data: {}): InvoiceClientModel {
    // invoice has clientRemoteId and remoteId
    return new InvoiceClientModel(invoice, data);
  }

  // expect maintain invoice.client.remoteId
  // and keep pointer to parent ClientModel via
  // clientRemoteId
  static createFromClientModel(
    invoice: InvoiceModel,
    clientModel: ClientModel
  ): InvoiceClientModel {
    return new InvoiceClientModel(invoice, {
      name: clientModel.name,
      email: clientModel.email,
      phone: clientModel.phone,
      mobile: clientModel.mobile,
      fax: clientModel.fax,
      address1: clientModel.address1,
      address2: clientModel.address2,
      address3: clientModel.address3,
      clientRemoteId: clientModel.remoteId
    });
  }

  syncQueue: SyncExecutionQueue = new SyncExecutionQueue();

  // save
  @observable autoSave?: IReactionDisposer;

  //  parent
  @observable user: UserModel;
  @observable invoice: InvoiceModel;

  // attributes
  @observable clientRemoteId?: string; // mobile app uses clientRemoteId to find the actualy client
  @observable id?: string;
  @observable remoteId: string;
  @observable name: string;
  @observable email: string;
  @observable address1?: string;
  @observable address2?: string;
  @observable address3?: string;
  @observable phone?: string;
  @observable mobile?: string;
  @observable fax?: string;

  // contact + shipping required for UniversalInvoice
  @observable contact?: string;
  @observable shipFob?: string;
  @observable shipVia?: string;
  @observable shipAmount?: string;
  @observable shipTracking?: string;
  @observable shippingName?: string;
  @observable shippingAddress1?: string;
  @observable shippingAddress2?: string;
  @observable shippingAddress3?: string;

  get = getValueOrDefault(this.default);

  @computed
  get isValid(): boolean {
    return (
      !!this.name &&
      (!!this.email ||
        !!this.address1 ||
        !!this.address2 ||
        !!this.address3 ||
        !!this.phone ||
        !!this.mobile ||
        !!this.fax)
    );
  }

  @computed
  get address() {
    return compact([this.address1, this.address2, this.address3]).join(', ');
  }

  // change of those fields will trigger save
  @computed
  get asInvoiceData() {
    return {
      name: this.name.trim(),
      email: this.email,
      address1: this.address1,
      address2: this.address2,
      address3: this.address3,
      phone: this.phone,
      mobile: this.mobile,
      fax: this.fax,
      remoteId: this.remoteId,
      clientRemoteId: this.clientRemoteId,

      // required for UniversalInvoice
      contact: this.contact,
      shipFob: this.shipFob,
      shipVia: this.shipVia,
      shipAmount: this.shipAmount,
      shipTracking: this.shipTracking,
      shippingName: this.shippingName,
      shippingAddress1: this.shippingAddress1,
      shippingAddress2: this.shippingAddress2,
      shippingAddress3: this.shippingAddress3
    };
  }

  // change of those fields will trigger save
  @computed
  get autoSaveData() {
    return {
      name: this.name,
      email: this.email,
      address1: this.address1,
      address2: this.address2,
      address3: this.address3,
      phone: this.phone,
      mobile: this.mobile,
      fax: this.fax
    };
  }

  constructor(invoice: InvoiceModel, client: any = {}) {
    this.invoice = invoice;
    this.update(client);
    this.autoSave = reaction(() => this.autoSaveData, this.save, {
      equals: comparer.structural,
      delay: EnvironmentStore.debounceRate
    });
  }

  async fetchByRemoteId() {
    if (!this.clientRemoteId) {
      throw new Error('Missing clientRemoteId');
    }

    try {
      const remoteClient = await getByRemoteId(this.clientRemoteId);
      if (remoteClient) {
        this.id = remoteClient.id;
      }
    } catch (error) {
      throw new Error('Cannot fetch remote client for invoice');
    }
  }

  @action.bound
  public async save(): Promise<void> {
    if (!this.isValid) {
      return;
    }
    try {
      await this.syncQueue.push(async () => {
        await this.fetchByRemoteId();
        await new ClientModel(this.asClientModel()).save();
      });
    } catch (error) {
      this.invoice.user.handleError('invoice-client-save', error);
    }
  }

  @action
  public update(data: Parse.Object | any = {}): void {
    this.clientRemoteId = this.get(data, 'clientRemoteId');
    this.remoteId = this.get(data, 'remoteId');
    this.name = this.get(data, 'name');
    this.email = this.get(data, 'email');
    this.address1 = this.get(data, 'address1');
    this.address2 = this.get(data, 'address2');
    this.address3 = this.get(data, 'address3');
    this.phone = this.get(data, 'phone');
    this.mobile = this.get(data, 'mobile');
    this.fax = this.get(data, 'fax');

    // contact + shipping
    this.contact = this.get(data, 'contact');
    this.shipFob = this.get(data, 'shipFob');
    this.shipVia = this.get(data, 'shipVia');
    this.shipAmount = this.get(data, 'shipAmount');
    this.shipTracking = this.get(data, 'shipTracking');
    this.shippingName = this.get(data, 'shippingName');
    this.shippingAddress1 = this.get(data, 'shippingAddress1');
    this.shippingAddress2 = this.get(data, 'shippingAddress2');
    this.shippingAddress3 = this.get(data, 'shippingAddress3');
  }

  private asClientModel() {
    return {
      id: this.id,
      email: this.email,
      billingName: this.name,
      billingAddress1: this.address1,
      billingAddress2: this.address2,
      billingAddress3: this.address3,
      phone: this.phone,
      mobile: this.mobile,
      fax: this.fax,
      deleted: false,
      // mobile app uses invoice.client.clientRemoteId to reference
      // ClientModel instance within a local storage
      remoteId: this.clientRemoteId
    };
  }

  private default(name: string) {
    return {
      remoteId: uuid(),
      clientRemoteId: uuid(),
      name: '',
      email: '',
      address1: '',
      address2: '',
      address3: '',
      phone: '',
      fax: '',
      mobile: ''
    }[name];
  }
}
