import { useState } from 'react';
import UserModel, { Cadence } from 'src/models/UserModel';
import { SubscriptionTier } from '@invoice-simple/common';

export const useInterval = (cadence: Cadence | null) => {
  const [selectedInterval, setSelectedInterval] = useState(cadence || Cadence.MONTHLY);

  const toggleInterval = (interval: Cadence) => {
    if (interval !== selectedInterval) {
      setSelectedInterval(interval);
    }
  };

  const shouldDisallowSwitch = (destinationTier: SubscriptionTier, user: UserModel) => {
    const userCurrentSubTier = user.activeSubscriptionTier;

    // User is a tiered subscriber
    if (userCurrentSubTier) {
      const userCurrentSubInterval = user.lastActivePaidWebSub(userCurrentSubTier)?.planInterval;
      const isSubscribedToSelectedInterval = userCurrentSubInterval === selectedInterval;
      const isSubscribedToSelectedTier = userCurrentSubTier === destinationTier;
      return isSubscribedToSelectedInterval && isSubscribedToSelectedTier;
    }

    return false;
  };

  return {
    selectedInterval,
    toggleInterval,
    shouldDisallowSwitch
  };
};
