import React from 'react';
import InputWithLabel from '../InputWithLabel';
import injectStore from '../../util/injectStore';
import invoiceInputFieldsUIStore from 'src/stores/InvoiceInputFieldsUIStore';
import { injectIntl, defineMessages } from 'src/i18n';
import { compose } from 'src/util/functions';
import { AppStore } from 'src/stores/AppStore';
import { ISIntl } from 'src/i18n/utils';
import { mayBe } from './MaybeRender';

const messages = defineMessages({
  label: {
    id: 'businessNumber.label',
    defaultMessage: 'business number'
  },
  placeholder: {
    id: 'businessNumber.placeholder',
    defaultMessage: 'e.g. 123-45-6789'
  },
  businessNumberTitle: {
    id: 'form.businessNumberInput.businessNumberTitle',
    defaultMessage: 'business number'
  }
});

const _BusinessNumberInput = compose(
  injectIntl,
  injectStore
)(({ store, intl }: { store: AppStore; intl: ISIntl }) => (
  <InputWithLabel
    baseline
    label={
      <label
        htmlFor="invoice-company-business-number"
        title={intl.fta(messages.businessNumberTitle)}>
        {intl.fta(messages.label)}
      </label>
    }
    content={
      <input
        type="text"
        disabled={store.isLoading}
        id="invoice-company-business-number"
        name="invoice-company-business-number"
        placeholder={intl.fta(messages.placeholder)}
        value={store.doc.businessNumber}
        onChange={(e) => store.doc.setBusinessNumber(e.target.value)}
      />
    }
  />
));

export const BusinessNumberInput = mayBe(
  _BusinessNumberInput,
  () => invoiceInputFieldsUIStore.shouldShowBusinessNumber
);
