import React, { useLayoutEffect } from 'react';

import Modal from 'react-modal';

import { useWindowSize } from '../Responsive';

const defaultStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    borderRadius: '12px',
    minWidth: '450px',
    overFlowX: 'hidden'
  } as React.CSSProperties,
  overlay: {
    position: 'fixed',
    inset: '0px',
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
    zIndex: 50
  } as React.CSSProperties
};

if (process.env.NODE_ENV !== 'test') {
  // Hide page content for screenreaders when modal is open
  Modal.setAppElement('#app');
}

type CommonProps = {
  children: React.ReactNode;
  isOpen: boolean;
  preventOverlayScroll?: boolean;
};

type CloseOnOutsideClick = {
  shouldCloseOnOverlayClick: true;
  onRequestClose: () => void;
};

type DoNotCloseOnOutsideClick = {
  shouldCloseOnOverlayClick: false;
  onRequestClose?: () => void;
};

type Props = CommonProps & (CloseOnOutsideClick | DoNotCloseOnOutsideClick);

export const ModalWrapper = ({
  children,
  isOpen,
  preventOverlayScroll = true,
  shouldCloseOnOverlayClick,
  onRequestClose
}: Props) => {
  useLayoutEffect(() => {
    if (!preventOverlayScroll) return;

    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const { width } = useWindowSize();
  const isMobile = width && width <= 480;
  const style = {
    ...defaultStyles,
    content: {
      ...defaultStyles.content,
      minWidth: isMobile ? '92%' : defaultStyles.content.minWidth
    }
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      style={style}
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      shouldReturnFocusAfterClose={true}
      shouldFocusAfterRender={true}
      shouldCloseOnEsc={true}
      preventScroll={true}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}>
      {children}
    </Modal>
  );
};
