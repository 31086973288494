import settingsDocMap from './data/settingsDocMap';
import { SettingsDocMap } from '../types/SettingsDocMap';

/** return setting doc map array for doc -> settings sync */
export const getDocFromSettingsMap = (docType?: number): SettingsDocMap[] =>
  settingsDocMap.filter(
    (m) => docType === undefined || m.docType === undefined || m.docType === docType
  );

/** return setting doc map array for settings -> doc sync, reject notes */
export const getSettingsFromDocMap = (docType?: number): SettingsDocMap[] =>
  getDocFromSettingsMap(docType).filter((m) => !/notes/i.test(m.path));
