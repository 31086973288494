import { observable, action, reaction, IReactionDisposer } from 'mobx';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import axios from 'axios';

import UserModel from './UserModel';
import ItemModel from './ItemModel';
import isPromise from '../util/IsPromise';
import EnvironmentStore from '../stores/EnvironmentStore';

type ItemModelFields = {
  remoteId: string;
  rate: number;
  taxable: boolean;
  code: string;
  description: string;
  unit: string;
};

export default class ItemSuggestModel {
  @observable user: UserModel;
  @observable autoLoad?: IReactionDisposer;
  @observable limitMax: number = 10;
  @observable startSuggestionAt: number = 2;
  @observable endSuggestionAt: number = 15;
  @observable items: ItemModel[] = [];
  @observable code: string | undefined;

  constructor(user: UserModel) {
    this.user = user;
    this.startAutoLoad();
  }

  private _enableSuggestion() {
    if (
      this.code &&
      this.code.length >= this.startSuggestionAt &&
      this.code.length <= this.endSuggestionAt
    )
      return true;
    return false;
  }

  private async _query() {
    if (this._enableSuggestion()) {
      const res = await axios.post(
        `${EnvironmentStore.appUrl}/api/autocomplete/item`,
        {
          code: this.code
        },
        { headers: { 'x-user-sessiontoken': this.user?.sessionToken }, validateStatus: () => true }
      );
      if (res.status === 400) return [];
      return res.data;
    }
    return [];
  }

  @action.bound
  public setCode(value: string): void {
    this.code = value;
  }
  @action.bound
  public clearCode(): void {
    this.reset();
  }
  @action.bound
  public reset(): void {
    this.code = undefined;
    this.items = [];
  }

  @action.bound
  private startAutoLoad(): void {
    if (!this.autoLoad) {
      this.autoLoad = reaction(() => this.code, debounce(this.load, EnvironmentStore.debounceRate));
    }
  }
  @action.bound
  private async load(): Promise<void> {
    if (!this.code) {
      return this.clearCode();
    }
    try {
      const res = await isPromise(this._query());
      this.update(res);
    } catch (error) {
      this.user.handleError('item-suggest-load', error);
    }
  }
  @action
  private update(data: ItemModelFields[]): void {
    this.items = map(data, (i) => new ItemModel(i));
  }
}
