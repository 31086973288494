import environmentStore from '../stores/EnvironmentStore';

const mayBeInvoke =
  (fn) =>
  (...args) => {
    if (window.Rollbar) {
      return window.Rollbar[fn].call(window.Rollbar, ...args);
    }
  };

const trackError = (title: string, error: Error | Parse.Error, data?: {}) =>
  mayBeInvoke('error')(title, error, data);

interface RollbarPayload {
  request_id: string;
  installation_id: string;
  person: {
    id?: string;
    account_id: string;
    email?: string;
  };
}
const update = (payload: { payload: RollbarPayload }) =>
  mayBeInvoke('configure')({
    payload: {
      ...payload,
      ...{ app_version: environmentStore.getReactAppVersion() }
    }
  });

const Rollbar = {
  trackError,
  update
};

export default Rollbar;
