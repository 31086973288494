import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uiStore from '../../stores/UIStore';

export default observer(() => (
  <div className="nav-toggler" onClick={uiStore.toggleNavMenu}>
    {uiStore.isNavMenuCollapsed ? (
      <FontAwesomeIcon icon="bars" className="mobile-icon" />
    ) : (
      <FontAwesomeIcon icon="times" className="mobile-icon" />
    )}
  </div>
));
