import React from 'react';
import { useStripeContext } from 'src/payments/contexts/StripeAccountContext';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import {
  ManageAccountButton,
  SettingsAccountDetailsSection,
  StripeSettingsBoxRowWrapper
} from '../Common';
import { StripeInitialBox } from './StripeInitialBox';
import { StripePendingBox } from './StripePendingBox';
import { StripePendingVerificationBox } from './StripePendingVerificationBox';
import { StripeSettingsAcceptingBox } from './StripeAcceptingBox/StripeSettingsAcceptingBox';
import { PaymentMethod, useCTAHandler } from '../useCTAHandler';
import { getPaymentEventSource } from 'src/payments/utils';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';

interface PaymentSettingDictionary {
  onlinePayments: string;
  stripeAccountEmail: string;
  stripeMerchantId: string;
  stripeCurrency: string;
}

const SettingsStripeBoxComponent = ({
  dictionary,
  store
}: {
  dictionary: PaymentSettingDictionary;
  store: AppStore;
}) => {
  const stripeAccountContext = useStripeContext();
  const { handleInitialClick } = useCTAHandler(PaymentMethod.Stripe);

  if (!stripeAccountContext?.isStripeEligible) {
    return null;
  }

  const stripeSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    source: getPaymentEventSource()
  };

  const events = store.user.events;

  const {
    isStripeInitial,
    isStripePending,
    isStripePendingVerification,
    isStripeAccepting,
    stripeMerchantId,
    stripePrimaryCurrency,
    stripePrimaryEmail,
    showStripeModal
  } = stripeAccountContext;

  const defProps = {
    dictionary: {
      accountEmail: dictionary.stripeAccountEmail,
      merchantId: dictionary.stripeMerchantId,
      currency: dictionary.stripeCurrency
    },
    merchantId: stripeMerchantId,
    currency: stripePrimaryCurrency,
    email: stripePrimaryEmail
  };

  function handleStripePendingClick() {
    events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.tilePending
    });
    showStripeModal();
  }

  function handleStripePendingVerificationClick() {
    events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.tilePendingVerification
    });
    showStripeModal();
  }

  function handleStripePendingVerificationManageAccountClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.manageAccountPendingVerification
    });
    e.preventDefault();
    window.open('https://dashboard.stripe.com/dashboard', '_blank');
  }

  const paymentsViewedMeta = {
    ...stripeSharedMetadata,
    component: PaymentEventComponent.manageAccountPendingVerification
  };

  return (
    <div data-testid="IntegratedPaymentsStripeBox" id="tailwind">
      {isStripeInitial && (
        <StripeSettingsBoxRowWrapper>
          <StripeInitialBox onCTAClick={() => handleInitialClick(store, showStripeModal)} />
        </StripeSettingsBoxRowWrapper>
      )}
      {isStripePending && (
        <StripeSettingsBoxRowWrapper>
          <StripePendingBox onCTAClick={handleStripePendingClick} />
        </StripeSettingsBoxRowWrapper>
      )}
      {isStripePendingVerification && (
        <>
          <StripeSettingsBoxRowWrapper>
            <StripePendingVerificationBox onCTAClick={handleStripePendingVerificationClick} />
          </StripeSettingsBoxRowWrapper>
          <SettingsAccountDetailsSection {...defProps} />
          <ManageAccountButton
            paymentsViewedMeta={paymentsViewedMeta}
            onClick={handleStripePendingVerificationManageAccountClick}>
            Manage Stripe Account
          </ManageAccountButton>
        </>
      )}
      {isStripeAccepting && <StripeSettingsAcceptingBox defProps={defProps} />}
    </div>
  );
};

export const SettingsStripeBox = injectStore(SettingsStripeBoxComponent);
