import { observable, action, computed } from 'mobx';
import startsWith from 'lodash/startsWith';
import UserModel from './UserModel';
import { DocType, DocTypes } from '@invoice-simple/common';

export default class DocListModel {
  // parent
  @observable user: UserModel;

  // filter
  @observable docType: DocType = DocTypes.DOCTYPE_INVOICE;
  @observable state: 'paid' | 'open' | undefined;
  @observable isDeleted: boolean = false;

  // order
  @observable orderByCol: 'date' = 'date' as const;
  @observable orderByDir: 'desc' | 'asc' = 'desc';

  // paginate
  @observable url: string;
  @observable limit: number = 50;

  constructor(user: UserModel, docType: DocType) {
    this.docType = docType;
    this.user = user;
  }

  @computed
  get isPaid(): boolean {
    return this.state === 'paid';
  }

  @computed
  get isOpen(): boolean {
    return this.state === 'open';
  }

  @computed
  get isInvoice(): boolean {
    return this.docType === DocTypes.DOCTYPE_INVOICE;
  }

  @computed
  get isEstimate(): boolean {
    return this.docType === DocTypes.DOCTYPE_ESTIMATE;
  }

  @computed
  get isOrderAsc(): boolean {
    return this.orderByDir === 'asc';
  }

  @computed
  get isOrderDesc(): boolean {
    return this.orderByDir === 'desc';
  }

  isOrderBy(col: string): boolean {
    return startsWith(this.orderByCol, col);
  }

  isOrderByWithDir(col: string, dir: string): boolean {
    return this.isOrderBy(col) && this.orderByDir === dir;
  }

  @action
  setFilterBy(name: 'open' | 'paid' | undefined): void {
    this.state = name;
    this.orderByCol = 'date';
  }

  @action
  setOrderByCol(): void {
    this.toggleOrderByDir();
  }

  @action
  toggleOrderByDir(): void {
    this.setUrl('');
    this.orderByDir = this.isOrderAsc ? 'desc' : 'asc';
  }

  /**
   * Sets the URL for the next OR prev page in paginating through documents
   * @param url
   */
  @action
  setUrl(url: string): void {
    this.url = url;
  }
}
