import React, { useCallback, useState } from 'react';

import { AxiosError } from 'axios';

import { getIntlCurrency, Platform, SubscriptionTier } from '@invoice-simple/common';
import {
  getCouponDiscount,
  getCouponSku,
  ISPaywallCoupon,
  PaywallCouponConfig
} from '@invoice-simple/is-coupon';

import { loadStripe } from '@stripe/stripe-js';

import { trackFacebookEvent } from 'src/analytics/facebook';
import { ISIntl } from 'src/i18n';
import alertModel from 'src/models/AlertModel';
import LocationModel from 'src/models/LocationModel';
import Subscription from 'src/models/SubscriptionModel';
import UserModel, { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { defaultsStore } from 'src/stores/DefaultsStore';
import { getFormattedDateString } from 'src/util/date';
import { wholePriceToFloat } from 'src/util/number';
import { redirectToStripeCheckout } from 'src/util/stripeCheckout';
import { shouldOfferLocalizedPrices } from 'src/util/subscriptions';
import { titleizeAll } from 'src/util/Titleize';
import {
  getPaywallCouponInfo,
  getSubscriptionSwitchType,
  isEndOfTermSwitch,
  maybeGetOldTierFromNewTier,
  SubscriptionSwitchType
} from '../utils';
import { messages } from '../utils/messages';

export interface UpgradeProps {
  store: AppStore;
  intl: ISIntl;
  selectedInterval: Cadence;
  shouldDisallowSwitch?: (destinationTier: SubscriptionTier, user: UserModel) => boolean;
}

interface StripeCheckoutOpts {
  toBeTransferred?: boolean;
  successUrl?: string;
  cancelUrl?: string;
  couponSku?: string;
  couponName?: ISPaywallCoupon;
  description?: string;
  price?: number;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

export const useUpgrade = ({
  store,
  intl,
  selectedInterval,
  shouldDisallowSwitch
}: UpgradeProps) => {
  const { formatMessage } = intl;
  const [switching, setSwitching] = useState(false);
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const { user, upgradeSubscription } = store;

  const onUpgradeClick = async (
    tier: SubscriptionTier,
    stripeCheckoutOpts?: StripeCheckoutOpts
  ) => {
    const disallowSwitch = !!shouldDisallowSwitch?.(tier, user);
    const subscriptionToSwitch = user.lastActivePaidWebSub();
    const mobileSubscription = user.lastActivePaidMobileSub();
    const isSwitching = !disallowSwitch && !!subscriptionToSwitch;
    const switchType = getSubscriptionSwitchType(subscriptionToSwitch?.tier || null, tier);
    const endOfTerm = isEndOfTermSwitch(switchType);
    const newOrderSku = user.getGeoSubOrderSku({
      tier,
      cadence: selectedInterval
    });

    // Disallow mobile-only subscribers from using the web paywall
    if (!subscriptionToSwitch && mobileSubscription) {
      const storeName = user.isSubAndroid ? 'Google Play Store' : 'Apple App Store';
      alertModel.setAlert(
        'danger',
        formatMessage(messages.activeMobileSub, { store: storeName }),
        formatMessage(messages.activeMobileSubMessage)
      );

      LocationModel.scrollTop();

      user.events.trackAction('subscription-switch', {
        type: switchType,
        platform: Platform.WEB,
        status: 'failed',
        orderId: mobileSubscription.orderId,
        oldSku: mobileSubscription.orderSku,
        newSku: newOrderSku,
        changeDate: new Date(),
        interPlatform: true,
        price: wholePriceToFloat(user.getGeoSubAmount({ tier, cadence: selectedInterval })),
        currency: user.geoSubCurrencyCode,
        tier: tier,
        oldTier: mobileSubscription.tier,
        version: ''
      });

      return;
    }

    const oldTierVariant = maybeGetOldTierFromNewTier(tier);
    const currentUserTier = user.activeSubscriptionTier;
    if (currentUserTier) {
      const currentUserInterval = user.lastActivePaidWebSub(currentUserTier)?.planInterval;
      const isSubscribedToSelectedInterval = currentUserInterval === selectedInterval;
      const isSubscribedToSelectedTier = currentUserTier === tier;

      // Trying to change to the same tier and interval - just ignore and show no error message
      if (isSubscribedToSelectedInterval && isSubscribedToSelectedTier) {
        return;
      }
    }

    try {
      // if user is switching from payments free tier, he will open stripe checkout instead of calling upgrade api
      if (isSwitching && !user.isSubTier(SubscriptionTier.PAYMENTS_TIER)) {
        setSwitching(true);

        const switched = await switchSub({
          subscriptionToSwitch,
          newTier: tier,
          newOrderSku,
          switchType
        });

        if (switched) {
          await user.syncSubscriptions();

          let alertMessage: string | undefined;
          if (endOfTerm) {
            const isDowngrading = switchType === SubscriptionSwitchType.DOWNGRADE;
            alertMessage = formatMessage(
              getScheduledSwitchMessage(selectedInterval, isDowngrading),
              {
                tier: titleizeAll(oldTierVariant),
                date: getFormattedDateString(subscriptionToSwitch.expiryTimestamp!)
              }
            );
          } else {
            switch (tier) {
              case SubscriptionTier.PREMIUM_LEGACY:
                alertMessage = formatMessage(messages.switchSuccessAlertMessagePremiumLegacy);
                break;
              case SubscriptionTier.ESSENTIALS:
              case SubscriptionTier.STARTER:
                alertMessage = formatMessage(messages.switchSuccessAlertMessageStarter, {
                  docLimit: user.tierOneMonthlyQuota
                });
                break;
              case SubscriptionTier.PLUS:
                alertMessage = formatMessage(messages.switchSuccessAlertMessageStarter, {
                  docLimit: user.plusMonthlyQuota
                });
                break;
              default:
                alertMessage = formatMessage(messages.switchSuccessAlertMessageUnlimited);
            }
          }

          alertModel.setAlert(
            'success',
            formatMessage(
              selectedInterval === Cadence.MONTHLY
                ? messages.switchSuccessAlertTitleMonthly
                : messages.switchSuccessAlertTitleAnnual,
              {
                tier: titleizeAll(oldTierVariant)
              }
            ),
            alertMessage
          );

          LocationModel.scrollTop();
        }
        return;
      }

      setLoadingCheckout(true);
      await openStripeCheckout(tier, stripeCheckoutOpts);
    } catch (err) {
      const error = err as AxiosError<{ title?: string; message?: string }>;

      const defaultErrorTitle = isSwitching
        ? 'Error switching subscription'
        : 'Error creating checkout';
      const defaultErrorMessage = 'Please contact support.';
      if (error.response) {
        alertModel.setAlert(
          'danger',
          error.response.data?.title || defaultErrorTitle,
          error.response.data?.message || defaultErrorMessage
        );
      } else {
        alertModel.setAlert('danger', defaultErrorTitle, error.message || defaultErrorMessage);
      }
      LocationModel.scrollTop();
    } finally {
      setLoadingCheckout(false);
      setSwitching(false);
    }
  };

  const switchSub = async ({
    subscriptionToSwitch,
    newTier,
    newOrderSku,
    switchType
  }: {
    subscriptionToSwitch: Subscription;
    newTier: SubscriptionTier;
    newOrderSku: string;
    switchType: SubscriptionSwitchType;
  }) => {
    const endOfTerm = isEndOfTermSwitch(switchType);
    const isDowngrading = switchType === SubscriptionSwitchType.DOWNGRADE;

    const downgradingMessage = formatMessage(messages.downgradeConfirmMessage, {
      oldTier: titleizeAll(
        subscriptionToSwitch.tier
          ? maybeGetOldTierFromNewTier(subscriptionToSwitch.tier)
          : 'Non-tier'
      ),
      newTier: titleizeAll(maybeGetOldTierFromNewTier(newTier))
    });
    const scheduledSwitchMessage = formatMessage(
      getScheduledSwitchMessage(selectedInterval, isDowngrading),
      {
        tier: titleizeAll(maybeGetOldTierFromNewTier(newTier)),
        date: getFormattedDateString(subscriptionToSwitch.expiryTimestamp!)
      }
    );
    const confirmPrompt = formatMessage(messages.confirmPrompt);
    const confirmationMessage = `${
      isDowngrading ? downgradingMessage.concat(' ') : ''
    }${scheduledSwitchMessage} ${confirmPrompt}`;

    // If end of term, only upgrade on confirmation
    if (endOfTerm ? window.confirm(confirmationMessage) : true) {
      await upgradeSubscription({
        subscriptionToSwitch,
        newOrderSku,
        switchType,
        newPrice: user.getGeoSubAmount({ tier: newTier, cadence: selectedInterval })
      });

      return true;
    }
    return false;
  };

  const openStripeCheckout = async (tier: SubscriptionTier, opts?: StripeCheckoutOpts) => {
    const stripe = await stripePromise;
    if (!stripe) {
      return;
    }
    const productTitle = formatMessage(
      selectedInterval === Cadence.MONTHLY
        ? messages.monthlyTierSubscriptionTitle
        : messages.annualTierSubscriptionTitle,
      { tier: titleizeAll(maybeGetOldTierFromNewTier(tier)) }
    );
    const price = user.getGeoSubAmount({ tier, cadence: selectedInterval });
    const orderSku = user.getGeoSubOrderSku({ tier, cadence: selectedInterval });
    // Using CustomizeProduct because InitiateCheckout is already being used
    trackFacebookEvent('track', 'CustomizeProduct', {
      value: price,
      order_sku: orderSku,
      currency: user.geoSubCurrencyCode
    });

    await redirectToStripeCheckout({
      store,
      productTitle,
      description: opts?.description,
      price: opts?.price ?? price,
      orderSku,
      successUrl: opts?.successUrl,
      cancelUrl: opts?.cancelUrl ?? '/subscription',
      couponSku: opts?.couponSku,
      couponName: opts?.couponName,
      tier,
      cadence: selectedInterval,
      toBeTransferred: opts?.toBeTransferred
    });
  };

  const getScheduledSwitchMessage = (cadence: Cadence, isDowngrading: boolean) => {
    if (isDowngrading) {
      return messages.switchedAtEndofCycle;
    }
    return cadence === Cadence.ANNUAL
      ? messages.scheduledSwitchNoticeAnnual
      : messages.scheduledSwitchNoticeMonthly;
  };

  const OriginalPrice = ({
    tier,
    shouldHidePerYear
  }: {
    tier: SubscriptionTier;
    shouldHidePerYear?: boolean;
  }) => {
    const isCadOrAud = ['CAD', 'AUD'].includes(user.geoSubCurrencyCode);
    const hidePerYear = shouldHidePerYear || (shouldOfferLocalizedPrices() && isCadOrAud);

    return (
      <span className="mr-3 leading-6 text-gray-600 line-through strikethrough-price">
        {getIntlCurrency({
          number: (user.getGeoSubAmount({ tier, cadence: Cadence.MONTHLY }) / 100) * 12,
          currencyCode: user.geoSubCurrencyCode,
          localeCode: defaultsStore.locale.name
        })}
        {!hidePerYear && <span className="text-2xl">/yr</span>}
      </span>
    );
  };

  const FinalPrice = ({
    tier,
    isTieredMatrix
  }: {
    tier: SubscriptionTier;
    isTieredMatrix?: boolean;
  }) => (
    <span>
      {getIntlCurrency({
        number: user.getGeoSubAmount({ tier, cadence: selectedInterval }) / 100,
        currencyCode: user.geoSubCurrencyCode,
        localeCode: defaultsStore.locale.name
      })}
      {!isTieredMatrix ? (
        <span className="text-2xl font-medium text-gray-500">
          /{selectedInterval === Cadence.MONTHLY ? 'mo' : 'yr'}
        </span>
      ) : null}
    </span>
  );

  const isCurrentPlan = (tier: SubscriptionTier) => {
    const currentPlanTier = user.activeSubscriptionTier;
    if (currentPlanTier === tier && user.lastActivePaidWebSub()?.isWebPaymentsTier) return true;
    const currentPlanInterval = user.lastActivePaidWebSub()?.planInterval;

    return currentPlanTier === tier && currentPlanInterval === selectedInterval;
  };

  const getCouponPrices = useCallback(
    (coupon: PaywallCouponConfig, tier: SubscriptionTier, cadence: Cadence) => {
      const originalPrice = user.getGeoSubAmount({
        tier,
        cadence
      });
      const discountPrice = user.getGeoSubAmount({
        tier,
        cadence,
        coupon
      });

      const originalPriceDisplay = originalPrice / 100;
      const discountPriceDisplay = discountPrice / 100;
      const isDiscounted = discountPrice < originalPrice;

      return {
        originalPrice,
        discountPrice,
        originalPriceDisplay,
        discountPriceDisplay,
        isDiscounted
      };
    },
    []
  );

  const onBuyClick = async (tier: SubscriptionTier, cadence: Cadence) => {
    const paywallCouponConfig = getPaywallCouponInfo();

    if (!user.shouldOfferPaywallDiscount || !paywallCouponConfig) {
      return;
    }

    const couponSku =
      getCouponSku({ paywallCouponConfig, tier, cadence, platform: Platform.WEB }) ?? undefined;

    const couponName = couponSku ? paywallCouponConfig.name : undefined;

    const { originalPriceDisplay, discountPrice } = getCouponPrices(
      paywallCouponConfig,
      tier,
      cadence
    );

    const description = getCouponDiscountDurationMessage({
      paywallCouponConfig,
      originalPriceDisplay,
      currencyCode: user.geoSubCurrencyCode,
      ft: intl.ft,
      cadence,
      tier
    });

    await onUpgradeClick(tier, {
      toBeTransferred: user.isGuest,
      couponSku,
      couponName,
      description,
      price: discountPrice,
      cancelUrl: '/subscription',
      ...(user.isGuest && { successUrl: '/login' })
    });
  };

  return {
    switching,
    loadingCheckout,
    onUpgradeClick,
    OriginalPrice,
    FinalPrice,
    isCurrentPlan,
    getCouponPrices,
    onBuyClick
  };
};

const getCouponDiscountDurationMessage = ({
  paywallCouponConfig,
  currencyCode,
  originalPriceDisplay,
  ft,
  cadence,
  tier
}: {
  paywallCouponConfig: PaywallCouponConfig;
  currencyCode: string;
  originalPriceDisplay: number;
  ft: ISIntl['ft'];
  cadence: Cadence;
  tier: SubscriptionTier;
}) => {
  const isPlanDiscounted = !!getCouponDiscount({
    paywallCouponConfig,
    platform: Platform.WEB,
    tier,
    cadence
  });

  if (!paywallCouponConfig.durationMonths || !isPlanDiscounted) {
    return;
  }

  const isMonthly = cadence === Cadence.MONTHLY;

  const monthlyDurationMessage =
    paywallCouponConfig.durationMonths === 1
      ? ft(messages.subscriptionDiscountDurationMonth)
      : ft(messages.subscriptionDiscountDurationMonthly, {
          months: isMonthly ? paywallCouponConfig.durationMonths : 12
        });

  const description =
    monthlyDurationMessage +
    ' ' +
    ft(messages.subscriptionCouponDesc, {
      price: getIntlCurrency({
        number: originalPriceDisplay,
        currencyCode,
        localeCode: defaultsStore.locale.name
      }),
      cadence: ft(isMonthly ? messages.perMonth : messages.perYear)
    });

  return description;
};
