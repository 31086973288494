export type MobileOperatingSystem = 'Windows Phone' | 'Android' | 'iOS' | 'unknown';

export const iosAppstoreURL =
  'https://apps.apple.com/us/app/invoice-simple-receipt-maker/id694831622';
export const androidPlaystoreURL =
  'https://play.google.com/store/apps/details?id=com.aadhk.woinvoice';

export const getMobileOperatingSystem = (): MobileOperatingSystem => {
  const userAgent = window.navigator.userAgent;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }

  return 'unknown';
};

export const openMobileLink = (iOS: string, android: string) => {
  try {
    const operatingSystem = getMobileOperatingSystem();

    switch (operatingSystem) {
      case 'iOS':
        window.open(iOS, '_blank');
        break;
      case 'Android':
        window.open(android, '_blank');
        break;
      default:
        break;
    }
  } catch (err) {
    // Reload window to have correct document
    window.location.reload();
  }
};
