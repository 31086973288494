import { DocTypes } from '@invoice-simple/common';
import { PaginatedDocsParams } from './hooks/usePaginatedDocuments';

/**
 * The pagination endpoint returns URLs for both next/prev pages. If the user selects the next/prev button,
 * we should pass the appropriate URL to fetch the new batch of documents.
 * @returns a URL to send to the pagination endpoint
 */
export const buildUrl = ({
  docType = DocTypes.DOCTYPE_INVOICE,
  dir = 'desc',
  sortBy = 'invoiceDate',
  limit,
  state,
  url
}: PaginatedDocsParams) => {
  if (url) return url;

  const qParams = [
    `doctype=${docType}`,
    `dir=${dir}`,
    `sortby=${sortBy}`,
    ...(limit ? [`limit=${limit}`] : []),
    ...(state ? [`state=${state}`] : [])
  ];
  return `${process.env.REACT_APP_URL ?? 'https://localhost:3000'}/api/docs?${qParams.join('&')}`;
};
