import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Prop {
  name: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  label?: string;
  maxLength?: number;
  disabled?: boolean;
  errorMessage?: string;
  hideIcon?: boolean;
}

export default class TextInput extends React.Component<Prop, any> {
  static defaultProps = {
    disabled: false
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { name, disabled, label, onChange, errorMessage, hideIcon = false, ...rest } = this.props;

    const isValid = !errorMessage;
    const _errorMessage = `${label} ${errorMessage}`;
    const validIcon = isValid ? (
      <FontAwesomeIcon icon="check-circle" color="grey" />
    ) : (
      <FontAwesomeIcon icon="exclamation-circle" color="red" />
    );
    const icon = disabled || hideIcon ? null : validIcon;
    return (
      <div className="text-with-icon input-with-error">
        {icon}
        <input
          type="text"
          id={name}
          className={name}
          onChange={this.handleChange}
          disabled={disabled}
          {...rest}
        />
        {!isValid && !disabled && <span className="error-message">{_errorMessage}</span>}
      </div>
    );
  }
}
