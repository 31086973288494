/** return string with first letter upcase
 *
 * input: "hello there"
 *
 * return: "Hello there"
 */
export const titleize = (value: string = ''): string =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const titleizeAll = (value: string = ''): string => value.split(' ').map(titleize).join(' ');

export default titleize;
