import React, { useContext, useState } from 'react';
import * as storage from './featureFlagsLocalStorage';
import { Feature } from './featureFlags';
// Can be used with server side storage or other, as long as it has get/set functions

export interface FeatureContext {
  isFeatureEnabled: (feature: Feature) => boolean;
  setFeature: (feature: Feature, value: boolean) => void;
}

const FeatureFlagsContext = React.createContext<FeatureContext | null>(null);

export const useFeatureFlags = () => useContext<FeatureContext | null>(FeatureFlagsContext);

export const FeatureFlagsProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState(storage.getAllFeatures());
  const isFeatureEnabled = (feature: Feature) => {
    const env = process.env.REACT_APP_ENV;
    const isProd = !!env && /production/i.test(env);
    if (isProd) {
      return false;
    }

    return !!featureFlags[feature];
  };
  const setFeature = (feature: Feature, value: boolean) => {
    const features = {
      ...featureFlags,
      [feature]: value
    };

    setFeatureFlags(features);
    storage.setAllFeatures(features);
  };
  return (
    <FeatureFlagsContext.Provider value={{ isFeatureEnabled, setFeature }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

// HOC to support render component based on feature flag
export const FeatureEnabled = ({ feature, children }) => {
  const featureFlagsValue = useFeatureFlags();
  if (featureFlagsValue) {
    const { isFeatureEnabled } = featureFlagsValue;
    return isFeatureEnabled(feature) ? children : null;
  }
};
