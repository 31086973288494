import React from 'react';
import InvoicePaymentList from './InvoicePaymentList';
import { observer } from 'mobx-react';

const InvoicePaymentController = (props) => {
  const store = props.store;
  const invoice = props.store.doc;
  const user = props.store.user;

  if (!store.location.isPayment) {
    return null;
  }

  return (
    <InvoicePaymentList
      isLoading={store.isLoading}
      invoice={invoice}
      onLoad={() => {
        user.trackAppEventViaApi('invoice-payment-view');
      }}
      onCancel={() => {
        user.trackAppEventViaApi('invoice-payment-cancel');
        store.doc.showPreview();
      }}
      onSave={() => {
        user.trackAppEventViaApi('invoice-payment-save');
      }}
    />
  );
};

export default observer(InvoicePaymentController);
