import enabledLocales from '../languages/enabledLocales.json';

interface EnabledLocaleDef {
  locale: string;
  title: string;
  supportedLocales?: string[];
  paths: string[];
  screenshotPaths: string[];
}

export const ENABLED_LOCALES: EnabledLocaleDef[] = enabledLocales;

export const DEFAULT_LOCALE: EnabledLocaleDef = ENABLED_LOCALES[0];

export const ENABLED_LOCALE_CODES = ENABLED_LOCALES.map((l) => l.locale);

export const SUPPORTED_COUNTRY_CODES = ENABLED_LOCALE_CODES.map((i) => i.split('-')[0]);

export const normalizeLocaleCode = (localeCode: string) => {
  const normalized = localeCode.replace('_', '-');
  const chunks = normalized.split('-');
  if (chunks[1]) {
    chunks[1] = chunks[1].toUpperCase();
  }

  return chunks.join('-');
};

export const getEnabledLocaleOrDefault = (locale: string): EnabledLocaleDef => {
  const enabledLocale = ENABLED_LOCALES.find((o) => {
    if (o.locale === locale) {
      return true;
    }

    if (o.supportedLocales) {
      return o.supportedLocales.indexOf(locale) !== -1;
    }

    return false;
  });

  if (!enabledLocale) {
    return DEFAULT_LOCALE;
  }

  return enabledLocale;
};

export const getLocaleScreenshotPath = (locale: string, index: number): string => {
  const screenshotPaths = getEnabledLocaleOrDefault(locale).screenshotPaths;
  if (!screenshotPaths[index]) {
    throw new Error(`Screenshot at ${index} not found for enabled locale ${locale}`);
  }
  return screenshotPaths[index];
};

export const getLocalePath = (locale: string): string => {
  const path = getEnabledLocaleOrDefault(locale).paths[0];
  if (!path) {
    throw new Error(`Path not found for enabled locale ${locale}`);
  }
  return path;
};

export const getLocaleTitle = (locale: string): string => {
  const title = getEnabledLocaleOrDefault(locale).title;
  if (!title) {
    throw new Error(`Title not found for enabled locale ${locale}`);
  }
  return title;
};
