import React from 'react';
import { EventType } from './types';

const SentIcon = () => (
  <svg
    data-testid="sent-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_21_1206)">
      <path
        d="M13.968 1.168C13.778 1.0645 13.564 1 13.3335 1H1.33352C1.10302 1 0.889024 1.0645 0.699524 1.168L7.33352 5.2745L13.968 1.168Z"
        fill="#737373"
      />
      <path
        d="M14.468 1.64197L7.49799 5.94997C7.39449 6.00997 7.27249 6.00997 7.16949 5.94997L0.198988 1.64197C0.0754883 1.84447 0.000488281 2.07947 0.000488281 2.33347V9.66697C0.000488281 10.4015 0.598488 11.0005 1.33399 11.0005H6.68399C6.39549 6.73297 11.2645 4.11247 14.6675 6.67697V2.33347C14.6675 2.07947 14.592 1.84497 14.469 1.64197H14.468Z"
        fill="#737373"
      />
      <path
        d="M11.6665 6.3335C9.27301 6.3335 7.33301 8.2735 7.33301 10.667C7.33301 13.0605 9.27301 15.0005 11.6665 15.0005C14.06 15.0005 16 13.0605 16 10.667C16 8.2735 14.06 6.3335 11.6665 6.3335ZM13.3335 10.3335C13.2035 10.4635 12.992 10.4635 12.862 10.3335L12 9.4715V12.667C12 12.851 11.8505 13.0005 11.6665 13.0005C11.4825 13.0005 11.333 12.851 11.333 12.667V9.4715L10.471 10.3335C10.341 10.4635 10.1295 10.4635 9.99951 10.3335C9.86951 10.2035 9.86951 9.992 9.99951 9.862L11.43 8.4305C11.56 8.3005 11.7715 8.3005 11.9015 8.4305L13.333 9.862C13.463 9.992 13.463 10.2035 13.333 10.3335H13.3335Z"
        fill="#737373"
      />
    </g>
    <defs>
      <clipPath id="clip0_21_1206">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DeliveredIcon = () => (
  <svg
    data-testid="delivered-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_21_1218)">
      <path
        d="M13.968 0.834504C13.778 0.731004 13.564 0.666504 13.3335 0.666504H1.33352C1.10302 0.666504 0.889024 0.731004 0.699524 0.834504L7.33352 4.941L13.968 0.834504Z"
        fill="#737373"
      />
      <path
        d="M11.6665 6C9.27701 6 7.33301 7.944 7.33301 10.3335C7.33301 12.723 9.27701 14.667 11.6665 14.667C14.056 14.667 16 12.723 16 10.3335C16 7.944 14.056 6 11.6665 6ZM14.398 9.2665L11.6675 12.171C11.606 12.2355 11.5215 12.2735 11.432 12.2755C11.4295 12.2755 11.4265 12.2755 11.424 12.2755C11.3375 12.2755 11.254 12.242 11.192 12.1815L9.24151 10.2875C9.11001 10.161 9.10601 9.948 9.23501 9.817C9.36251 9.685 9.57351 9.6815 9.70651 9.8095L11.414 11.466L13.912 8.808C14.0375 8.674 14.25 8.6675 14.3825 8.794C14.517 8.9215 14.524 9.132 14.3985 9.266L14.398 9.2665Z"
        fill="#737373"
      />
      <path
        d="M14.468 1.3085L7.49799 5.6165C7.39449 5.6765 7.27249 5.6765 7.16949 5.6165L0.198988 1.3085C0.0754883 1.511 0.000488281 1.746 0.000488281 2V9.3335C0.000488281 10.068 0.598488 10.667 1.33399 10.667H6.68399C6.67649 10.5565 6.66749 10.4465 6.66749 10.3335C6.66749 7.577 8.91099 5.3335 11.6675 5.3335C12.794 5.3335 13.831 5.712 14.6675 6.3435V2C14.6675 1.746 14.592 1.5115 14.469 1.3085H14.468Z"
        fill="#737373"
      />
    </g>
    <defs>
      <clipPath id="clip0_21_1218">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const OpenedIcon = () => (
  <svg
    data-testid="opened-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_21_1224)">
      <path
        d="M5 2.00001H6.3335C6.5175 2.00001 6.667 1.85051 6.667 1.66651C6.667 1.48251 6.5175 1.33301 6.3335 1.33301H5C4.816 1.33301 4.6665 1.48251 4.6665 1.66651C4.6665 1.85051 4.816 2.00001 5 2.00001Z"
        fill="#737373"
      />
      <path
        d="M11 3.3335H6.3335C6.1495 3.3335 6 3.483 6 3.667C6 3.851 6.1495 4.0005 6.3335 4.0005H11C11.184 4.0005 11.3335 3.851 11.3335 3.667C11.3335 3.483 11.184 3.3335 11 3.3335Z"
        fill="#737373"
      />
      <path
        d="M11 5.3335H5C4.816 5.3335 4.6665 5.483 4.6665 5.667C4.6665 5.851 4.816 6.0005 5 6.0005H11C11.184 6.0005 11.3335 5.851 11.3335 5.667C11.3335 5.483 11.184 5.3335 11 5.3335Z"
        fill="#737373"
      />
      <path
        d="M11 7.3335H5C4.816 7.3335 4.6665 7.483 4.6665 7.667C4.6665 7.851 4.816 8.0005 5 8.0005H11C11.184 8.0005 11.3335 7.851 11.3335 7.667C11.3335 7.483 11.184 7.3335 11 7.3335Z"
        fill="#848995"
      />
      <path
        d="M11.8505 10.6115C11.697 10.7135 11.4905 10.672 11.3885 10.519C11.2865 10.3665 11.328 10.159 11.481 10.057L15.6695 7.26449L13.333 5.65849V0.333988C13.333 0.149988 13.1835 0.000488281 12.9995 0.000488281H2.99952C2.81552 0.000488281 2.66602 0.149988 2.66602 0.333988V5.65849L0.330023 7.26499L4.51852 10.0575C4.67202 10.1595 4.71302 10.367 4.61102 10.5195C4.50902 10.672 4.30252 10.714 4.14902 10.612L-0.000976562 7.84549V14.667C-0.000976562 15.4025 0.597023 16.0005 1.33252 16.0005H14.666C15.4015 16.0005 15.9995 15.4025 15.9995 14.667V7.84549L11.85 10.6115H11.8505ZM3.33352 8.66649V0.666488H12.667V8.66649H3.33352ZM13.9275 13.8745C13.812 14.0185 13.603 14.041 13.459 13.9265L10.217 11.333H5.78352L2.54152 13.927C2.39752 14.0425 2.18802 14.0175 2.07302 13.875C1.95852 13.731 1.98152 13.5215 2.12502 13.4065L5.45852 10.74C5.51802 10.6925 5.59102 10.6665 5.66702 10.6665H10.3335C10.4095 10.6665 10.483 10.6925 10.5415 10.739L13.875 13.4065C14.019 13.521 14.0425 13.731 13.9275 13.8745Z"
        fill="#848995"
      />
    </g>
    <defs>
      <clipPath id="clip0_21_1224">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const FailedIcon = () => (
  <svg
    data-testid="failed-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.968 1.168C13.778 1.0645 13.564 1 13.3335 1H1.33352C1.10302 1 0.889024 1.0645 0.699524 1.168L7.33352 5.2745L13.968 1.168Z"
      fill="#EF4444"
    />
    <path
      d="M10.772 6.21948C10.9395 5.88748 11.2815 5.68198 11.6665 5.68198C12.0515 5.68198 12.394 5.88748 12.5605 6.21798L14.6665 10.4305V2.33298C14.6665 2.07898 14.591 1.84448 14.468 1.64148L7.49799 5.94948C7.39449 6.00948 7.27249 6.00948 7.16949 5.94948L0.198988 1.64148C0.0754883 1.84398 0.000488281 2.07898 0.000488281 2.33298V9.66648C0.000488281 10.401 0.598488 11 1.33399 11H8.38249L10.7725 6.21948H10.772Z"
      fill="#EF4444"
    />
    <path
      d="M15.9645 14.5175L11.9645 6.51751C11.851 6.29201 11.481 6.29201 11.368 6.51751L7.36802 14.5175C7.31652 14.621 7.32202 14.7435 7.38202 14.8415C7.44402 14.94 7.55152 15 7.66652 15H15.6665C15.782 15 15.889 14.94 15.9505 14.842C16.011 14.7435 16.016 14.6215 15.9645 14.5175ZM11.6665 13.6665C11.4825 13.6665 11.333 13.517 11.333 13.333C11.333 13.149 11.4825 12.9995 11.6665 12.9995C11.8505 12.9995 12 13.149 12 13.333C12 13.517 11.8505 13.6665 11.6665 13.6665ZM12 12.3335C12 12.5175 11.8505 12.667 11.6665 12.667C11.4825 12.667 11.333 12.5175 11.333 12.3335V10C11.333 9.81601 11.4825 9.66651 11.6665 9.66651C11.8505 9.66651 12 9.81601 12 10V12.3335Z"
      fill="#EF4444"
    />
  </svg>
);

const SignedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_663_6049)">
      <path
        d="M11 16H0.3335C0.1495 16 0 15.8505 0 15.6665C0 15.4825 0.1495 15.333 0.3335 15.333H11C11.1655 15.3315 12 15.2745 12 14.333C12 13.3915 11.1655 13.335 10.998 13.333H3C2.4235 13.333 1.3335 12.985 1.3335 11.6665C1.3335 10.348 2.4235 10 3 10H5.3335C5.5175 10 5.667 10.1495 5.667 10.3335C5.667 10.5175 5.5175 10.667 5.3335 10.667H3C2.8345 10.669 2 10.7255 2 11.667C2 12.6085 2.8345 12.6655 3.002 12.667H11C11.5765 12.667 12.6665 13.015 12.6665 14.3335C12.6665 15.652 11.5765 16 11 16Z"
        fill="#15803D"
      />
      <path
        d="M12.4541 3.54558C11.2841 2.37608 9.38063 2.37608 8.21163 3.54558C7.18363 4.57208 6.02113 9.20608 6.00963 9.25358C5.99013 9.33208 6.00963 9.41108 6.04713 9.48208L9.43463 6.09408C9.25813 5.72258 9.32013 5.26608 9.62663 4.96008C10.0161 4.57058 10.6506 4.56958 11.0401 4.95858C11.4301 5.34908 11.4306 5.98458 11.0416 6.37408C10.7341 6.68008 10.2776 6.74158 9.90713 6.56608L6.51913 9.95358C6.58963 9.99108 6.66863 10.0101 6.74763 9.99108C6.79413 9.97958 11.4241 8.81908 12.4551 7.78858C13.6246 6.61858 13.6246 4.71608 12.4551 3.54608L12.4541 3.54558Z"
        fill="#15803D"
      />
      <path
        d="M15.9973 3.03998C16.0098 2.93848 15.9748 2.83648 15.9018 2.76398L13.2358 0.097478C13.1633 0.024978 13.0613 -0.010522 12.9593 0.00197797C12.8578 0.014478 12.7673 0.073978 12.7148 0.161978L11.7148 1.82848C11.6353 1.95848 11.6568 2.12648 11.7648 2.23448L13.7648 4.23598C13.8728 4.34448 14.0408 4.36548 14.1723 4.28598L15.8383 3.28448C15.9263 3.23248 15.9848 3.14198 15.9978 3.03998H15.9973Z"
        fill="#15803D"
      />
    </g>
    <defs>
      <clipPath id="clip0_663_6049">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const getIconFromMessageEvent = (eventType: EventType): JSX.Element => {
  switch (eventType) {
    case 'sent':
      return <SentIcon />;
    case 'delivered':
      return <DeliveredIcon />;
    case 'opened':
      return <OpenedIcon />;
    case 'signed':
      return <SignedIcon />;
    case 'dropped':
    case 'failed_to_send':
      return <FailedIcon />;
    default:
      return <></>;
  }
};
