import { SubscriptionTier } from '@invoice-simple/common';
import UserModel, { Cadence } from 'src/models/UserModel';
import { shouldOfferLocalizedPrices } from './subscriptions';

export const getTierSku = ({
  tier,
  cadence
}: {
  tier: SubscriptionTier;
  cadence: Cadence;
}): string => {
  switch (tier) {
    case SubscriptionTier.ESSENTIALS:
    case SubscriptionTier.PLUS:
    case SubscriptionTier.PREMIUM:
    case SubscriptionTier.PREMIUM_LEGACY:
      return getNewTierSku({ tier, cadence });
    case SubscriptionTier.STARTER:
    case SubscriptionTier.PRO:
      return getLegacyTierSku({ tier, cadence });
    case SubscriptionTier.PAYMENTS_TIER:
      return 'com.invoicesimple.web.payments.free-tier';
    default:
      throw new Error(`Unexpected tier: ${tier}`);
  }
};

const getNewTierSku = ({
  tier,
  cadence
}: {
  tier: Extract<
    SubscriptionTier,
    | SubscriptionTier.ESSENTIALS
    | SubscriptionTier.PLUS
    | SubscriptionTier.PREMIUM
    | SubscriptionTier.PREMIUM_LEGACY
  >;
  cadence: Cadence;
}): string => {
  const user = UserModel.getInstance();
  const offerNewTieredPricing = user.shouldOfferNewTieredPricing;
  const currencyCode = user.geoSubCurrencyCode.toLowerCase();
  const showLocalizedPrices = shouldOfferLocalizedPrices();
  const isMonthly = cadence === Cadence.MONTHLY;
  const skuCadence = isMonthly ? 'monthly' : 'annual';

  const localSuffix = offerNewTieredPricing && showLocalizedPrices ? '.local' : '';

  const getPremiumSku = () => {
    const premiumTierSuffix = offerNewTieredPricing ? '.premium.tier' : '';
    const monthlyUsdPrice = offerNewTieredPricing ? '20' : '12';
    const annualUsdPrice = offerNewTieredPricing ? '200' : '100';
    const usdPrice = isMonthly ? monthlyUsdPrice : annualUsdPrice;
    return `com.invoicesimple.web-mobile.${skuCadence}.${usdPrice}.${currencyCode}${premiumTierSuffix}${localSuffix}`;
  };

  const getPremiumLegacySku = () => {
    const monthlyUsdPrice = '20';
    const annualUsdPrice = '200';
    const usdPrice = isMonthly ? monthlyUsdPrice : annualUsdPrice;
    const localSuffix = showLocalizedPrices ? '.local' : '';
    return `com.invoicesimple.web-mobile.${skuCadence}.${usdPrice}.${currencyCode}.premium.legacy.tier${localSuffix}`;
  };

  const getEssentialsSku = () => {
    const monthlyUsdPrice = '7';
    const annualUsdPrice = '70';
    const usdPrice = isMonthly ? monthlyUsdPrice : annualUsdPrice;
    return `com.invoicesimple.web-mobile.${skuCadence}.${usdPrice}.${currencyCode}.essentials.tier${localSuffix}`;
  };

  const getPlusSku = () => {
    const usdPrice = isMonthly ? '14' : '135';
    return `com.invoicesimple.web-mobile.${skuCadence}.${usdPrice}.${currencyCode}.plus.tier${localSuffix}`;
  };

  switch (tier) {
    case SubscriptionTier.PREMIUM_LEGACY:
      return getPremiumLegacySku();
    case SubscriptionTier.PREMIUM:
      return getPremiumSku();
    case SubscriptionTier.ESSENTIALS:
      return getEssentialsSku();
    case SubscriptionTier.PLUS:
      return getPlusSku();
  }
};

const getLegacyTierSku = ({
  tier,
  cadence
}: {
  tier: Extract<SubscriptionTier, SubscriptionTier.STARTER | SubscriptionTier.PRO>;
  cadence: Cadence;
}): string => {
  const user = UserModel.getInstance();
  const currencyCode = user.geoSubCurrencyCode.toLowerCase();
  const isMonthly = cadence === Cadence.MONTHLY;
  let usdPrice: number = 0;

  switch (tier) {
    case SubscriptionTier.STARTER:
      usdPrice = isMonthly ? 3 : 30;
      break;
    case SubscriptionTier.PRO:
      usdPrice = isMonthly ? 7 : 70;
      break;
  }

  return `com.invoicesimple.web.${cadence}.${usdPrice}.${currencyCode}.${tier}.tier`;
};
