import { useEffect, useState } from 'react';
import { TokenValidationMessages, validateAccountDeletionToken } from 'src/apis/accountAPI';
import { defineMessages } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';
import AlertModel from 'src/models/AlertModel';
import { AppStore } from 'src/stores/AppStore';

const messages = defineMessages({
  deleteTokenInvalidTitle: {
    id: 'delete.token.alert.invalidTitle',
    defaultMessage: 'Account deletion link has expired.'
  },
  deleteTokenInvalidMessage: {
    id: 'delete.token.alert.invalidMessage',
    defaultMessage: 'Initiate your account deletion request again from the app.'
  },
  alertSessionExpiredTitle: {
    id: 'login.alert.sessionExpired.title',
    defaultMessage: 'Session Expired'
  },
  alertSessionExpiredBody: {
    id: 'login.alert.sessionExpired.body',
    defaultMessage: 'Please login to continue.'
  }
});

export const useTokenValidation = ({
  store,
  formatMessage
}: {
  store: AppStore;
  formatMessage: ISIntl['formatMessage'];
}) => {
  const [isTokenActive, setIsTokenActive] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = store.user;
  const tokenId = store.location.options.token || '';

  useEffect(() => {
    if (user.isGuest) {
      store.nav('login', { returnPath: `/account-delete?token=${tokenId}` });
      AlertModel.setAlert(
        'danger',
        formatMessage(messages.alertSessionExpiredTitle),
        formatMessage(messages.alertSessionExpiredBody)
      );
    }

    setIsLoading(true);
    const checkTokenValid = async () => {
      try {
        const res = await validateAccountDeletionToken(user, tokenId);
        if (res === TokenValidationMessages.activeToken) return setIsTokenActive(true);
      } catch (err) {
        switch (err.message) {
          case TokenValidationMessages.subActive ||
            TokenValidationMessages.invalidToken ||
            TokenValidationMessages.tokenNotFound ||
            TokenValidationMessages.tokenNotProvided:
            return setIsTokenActive(false);
          default:
            user.trackError('delete-token-validation', err);
            return setIsTokenActive(false);
        }
      } finally {
        setIsLoading(false);
      }
    };
    checkTokenValid();
  }, []);

  useEffect(() => {
    // redirects to invoice list if token is invalid
    if (!tokenId || (isTokenActive === false && !user.isSubPaid)) {
      store.nav('invoiceList');
      AlertModel.setAlert(
        'danger',
        formatMessage(messages.deleteTokenInvalidTitle),
        formatMessage(messages.deleteTokenInvalidMessage)
      );
    }
  }, [isTokenActive, user.isLoaded]);

  return { isLoading, isTokenActive, tokenId };
};
