import { observable, action, computed } from 'mobx';
import environmentStore from 'src/stores/EnvironmentStore';
import location from 'src/models/LocationModel';
import React from 'react';

export type AlertType = 'danger' | 'success' | 'warning' | 'hidden';
export type VisibleAlertType = Exclude<AlertType, 'hidden'>;

interface AlertObject {
  type?: AlertType;
  titleMessageId?: string;
  bodyMessageId?: string;
  title?: string;
  body?: React.ReactNode;
  isClosable?: boolean;
  params?: Record<string, any>;
}

class Alert {
  @observable alert: AlertObject;

  @action.bound
  resetAlert() {
    this.alert = {};
  }

  @action.bound
  setAlertObject(alert: AlertObject) {
    if (alert.type === 'danger' && environmentStore.isSnapshot()) {
      return;
    }
    this.alert = { isClosable: true, ...alert };
  }

  @action.bound
  setAlert(type: AlertType, title?: string, body?: React.ReactNode, isClosable: boolean = true) {
    return this.setAlertObject({ type, title, body, isClosable });
  }

  @action.bound
  showDocMissingAlert() {
    this.setAlert(
      'danger',
      `${location.docTypeLabel} not found`,
      'Please make sure the link is correct.'
    );
  }

  @action.bound
  showFailedToLoadPhotosAlert() {
    this.setAlert(
      'danger',
      `Failed to load invoice photos`,
      'Please try refreshing the page. If the problem persists, please contact support.'
    );
  }

  @computed
  get isVisible() {
    return !!this.alert?.type && this.alert.type !== 'hidden';
  }
}

export default new Alert();
