import flagsmith from 'flagsmith';
import UserModel from 'src/models/UserModel';
import { getStripeCheckoutSession } from './stripeCheckout';

export async function isStripePurchaseTransferrable(sessionId: string): Promise<boolean> {
  if (!sessionId) {
    return false;
  }

  const user = UserModel.getInstance();
  const checkoutSession = await getStripeCheckoutSession(sessionId);
  const accountIdMatches = checkoutSession?.metadata?.accountId === user.accountId;
  const toBeTransferred = !!checkoutSession?.metadata?.toBeTransferred;
  return accountIdMatches && toBeTransferred;
}

export function shouldOfferLocalizedPrices() {
  const user = UserModel.getInstance();
  const userCurrencyCode = user.countrySettings.subscription.currencyCode;
  const shouldEnableABTest = false;

  if (!shouldEnableABTest) {
    const localizedPriceCurrencies = ['CAD', 'AUD', 'INR', 'GBP'];
    return localizedPriceCurrencies.includes(userCurrencyCode);
  }

  const hasFeature = flagsmith.hasFeature('localized_prices');

  if (hasFeature && user.isSubPaid) {
    if (user.lastPaidSub?.orderSku.includes('local')) return true;
  }

  return hasFeature && !user.isSubPaid;
}
