import { DiscountTypes } from '@invoice-simple/common';
import { defineMessages } from 'src/i18n';
import { ISIntl } from './utils';

const messages = defineMessages({
  percent: {
    id: 'i18n.discount.type.label.percent',
    defaultMessage: 'Percent'
  },
  flat: {
    id: 'i18n.discount.type.label.flat',
    defaultMessage: 'Flat Amount'
  },
  none: {
    id: 'i18n.discount.type.label.none',
    defaultMessage: 'None'
  }
});

// value=1 item discount removed
export const intlDiscountTypeOptions = (intl: ISIntl) => {
  return [
    { value: DiscountTypes.DISCOUNT_NONE, label: intl.formatMessage(messages.none) },
    { value: DiscountTypes.DISCOUNT_PERCENTAGE, label: intl.formatMessage(messages.percent) },
    { value: DiscountTypes.DISCOUNT_FLAT_AMOUNT, label: intl.formatMessage(messages.flat) }
  ];
};
