import React from 'react';

import { XIcon } from '../InvoicePhotos/XIcon';

import './PromoModalContent.scss';

import { PromoModalFooter } from './PromoModalFooter';

interface Props {
  title: string;
  body: React.ReactNode;
  image: React.ReactNode;
  footerText?: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  confirmButtonText: string;
  dismissButtonText?: string;
}

export const PromoModalContent = ({
  title,
  body,
  image,
  onClose,
  onConfirm,
  confirmButtonText,
  dismissButtonText,
  footerText
}: Props) => {
  return (
    <div id="tailwind" className="promo-modal-content">
      <header>
        <h3>{title}</h3>
        <button onClick={onClose} data-testid="promo-modal-x-button" type="button">
          <XIcon />
        </button>
      </header>

      <div>
        <figure>{image}</figure>
        <div className="promo-modal-body-container">{body}</div>
      </div>

      <PromoModalFooter
        confirmButtonText={confirmButtonText}
        dismissButtonText={dismissButtonText}
        onClose={onClose}
        onConfirm={onConfirm}
        footerText={footerText}
      />
    </div>
  );
};
