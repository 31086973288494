import { PaymentProviders } from '@invoice-simple/is-events';

import { usePaypalContext, useStripeContext } from '../contexts';
import { useIsPaypalVStripeVariantFlagEnabled } from './useIsPaypalVStripeVariantFlagEnabled';

export function useEligiblePaymentProviders() {
  const stripeContext = useStripeContext();
  const paypalContext = usePaypalContext();
  const isPaypalVStripeVariantFlagEnabled = useIsPaypalVStripeVariantFlagEnabled();

  const eligiblePaymentProviders: PaymentProviders[] = [];
  if (isPaypalVStripeVariantFlagEnabled && stripeContext?.isStripeEligible) {
    eligiblePaymentProviders.push('Stripe');
  }
  if (paypalContext?.isPaypalEligible()) {
    eligiblePaymentProviders.push('PayPal');
  }
  return eligiblePaymentProviders;
}
