import React from 'react';

export const BlueCTAButton = (
  buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>
): JSX.Element => {
  return (
    <button
      data-testid="blueCTAButton"
      type="button"
      className="bg-transparent text-[#2563EB] leading-5 decoration-0 border-0 inline-block cursor-pointer text-[12px] font-semibold flex-shrink-0"
      {...buttonProps}></button>
  );
};
