import { observable, computed, toJS } from 'mobx';
import {
  getUniversalInvoice,
  UniversalInvoiceOptions
} from '@invoice-simple/transformer-universal';

import SettingListModel from 'src/models/SettingListModel';
import { PublicInvoicePhoto, UniversalInvoice } from '@invoice-simple/common';
import InvoiceModel from './InvoiceModel';
import UserModel from './UserModel';

export class UniversalInvoiceModel {
  @observable settings: SettingListModel = UserModel.getInstance().settingList;
  @observable invoice: InvoiceModel;

  constructor({ invoice }) {
    this.invoice = invoice;
  }

  @computed get options(): UniversalInvoiceOptions {
    return {
      invoice: {
        ...toJS(this.invoice.toJSON()),
        logo: this.invoice.logo && toJS(this.invoice.logo.parseData)
      },
      signature: {
        remoteId: this.invoice.signature ?? '',
        signDate: this.invoice.signDate,
        url: this.invoice.signaturePhotoUrl ?? ''
      },
      settings: this.settings.toJSON(),
      photos: this.invoice.invoicePhotos.map(({ name, description, url }): PublicInvoicePhoto => {
        return {
          name: name || '',
          description: description || '',
          url: url
        };
      })
    };
  }

  @computed get value(): UniversalInvoice {
    return getUniversalInvoice(this.options);
  }
}
