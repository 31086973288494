import React, { useContext } from 'react';
import { DocTypes } from '@invoice-simple/common';
import { PaypalAccountContext } from 'src/payments/contexts/PaypalAccountContext';
import type InvoiceModel from 'src/models/InvoiceModel';
import { PaymentSurchargeFees } from 'src/payments';
import { PassingFeesType } from 'src/payments/components/PaymentSetting';
import { getInvoiceSurcharge } from '@invoice-simple/calculator';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { usePaymentsUsability } from 'src/payments/hooks/usePaymentsUsability';
import { InvoicePaymentFees } from './InvoicePaymentFees';
import { useStripeContext } from 'src/payments/contexts';

type Props = {
  invoice: InvoiceModel;
  feesType: PassingFeesType;
  store: AppStore;
};

const InvoicePaymentSurchargeFeesComponent = ({ invoice, feesType, store }: Props) => {
  const docType = invoice.docType;
  const paymentSuppressed = invoice.paymentSuppressed;
  const stripePaymentSuppressed = invoice.stripePaymentSuppressed;
  const { isPaypalUsable, isStripeUsable } = usePaymentsUsability(
    store.user.settingList.currencyCode
  );
  const paypalAccountContext = useContext(PaypalAccountContext);
  const paypalFees = paypalAccountContext?.getPaypalFees();
  const stripeAccountContext = useStripeContext();
  const stripeFees = stripeAccountContext?.getStripeFees;
  const feature = 'payments-surcharge-invoice-cover-transaction-fees';

  const paypalUsableForSurcharge = isPaypalUsable && !paymentSuppressed && paypalFees;
  const stripeUsableForSurcharge = isStripeUsable && !stripePaymentSuppressed && stripeFees;

  if (!paypalUsableForSurcharge && !stripeUsableForSurcharge) return null;
  if (docType !== DocTypes.DOCTYPE_INVOICE) return null;

  const surcharge = Math.max(
    paypalFees ? +getInvoiceSurcharge(invoice.asUniversal, paypalFees) : 0,
    stripeFees ? +getInvoiceSurcharge(invoice.asUniversal, stripeFees) : 0
  );

  return feesType && feesType === PassingFeesType.SURCHARGE ? (
    <PaymentSurchargeFees
      surcharge={surcharge}
      onFeesTypeToggleChange={(isCoverTransactionFees) => {
        invoice.setFeesType(
          isCoverTransactionFees ? PassingFeesType.SURCHARGE : PassingFeesType.MARKUP
        );
        store.user.events.trackButtonClick({
          text: 'Cover Processing Fees',
          action: 'toggled',
          invoiceId: invoice.id,
          value: isCoverTransactionFees,
          feature
        });
      }}
      feesType={invoice.setting.feesType}
      getIsSurchargeConsentGiven={() => {
        return store.user.getIsSurchargeConsentGiven();
      }}
      setSurchargeConsentGiven={(consentMessage: string) => {
        return store.user.setSurchargeConsentGiven(consentMessage);
      }}
    />
  ) : (
    <InvoicePaymentFees invoice={invoice} />
  );
};

export const InvoicePaymentSurchargeFees = injectStore(InvoicePaymentSurchargeFeesComponent);
