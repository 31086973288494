import { observable, action, reaction, IReactionDisposer } from 'mobx';
import map from 'lodash/map';
import axios from 'axios';

import UserModel from './UserModel';
import ClientModel from './ClientModel';
import isPromise from '../util/IsPromise';
import EnvironmentStore from '../stores/EnvironmentStore';

type ClientModelFields = {
  remoteId: string;
  email: string;
  billingName: string;
  billingAddress1: string;
  billingAddress2: string;
  billingAddress3: string;
  phone: string;
  mobile: string;
  fax: string;
};

export default class ClientSuggestModel {
  @observable user: UserModel;
  @observable autoLoad?: IReactionDisposer;
  @observable limitMax: number = 10;
  @observable startSuggestionAt: number = 2;
  @observable endSuggestionAt: number = 15;
  @observable clients: ClientModel[] = [];
  @observable name: string | undefined;

  constructor(user: UserModel) {
    this.user = user;
    this.startAutoLoad();
  }

  private _enableSuggestion() {
    if (
      this.name &&
      this.name.length >= this.startSuggestionAt &&
      this.name.length <= this.endSuggestionAt
    )
      return true;
    return false;
  }

  private async _query() {
    if (this._enableSuggestion()) {
      const res = await axios.post(
        `${EnvironmentStore.appUrl}/api/autocomplete/client`,
        {
          billingName: this.name
        },
        { headers: { 'x-user-sessiontoken': this.user?.sessionToken }, validateStatus: () => true }
      );
      if (res.status === 400) return [];
      return res.data;
    }
    return [];
  }

  @action.bound
  public setName(value: string): void {
    // We might want limit client suggestions to accounts
    // with less than X clients. Had performance issues in the past.
    this.name = value;
  }

  @action.bound
  public clearName(): void {
    this.reset();
  }
  @action.bound
  public reset(): void {
    this.name = undefined;
    this.clients = [];
  }
  @action.bound
  private startAutoLoad(): void {
    if (!this.autoLoad) {
      this.autoLoad = reaction(() => this.name, this.load, {
        delay: EnvironmentStore.debounceRate
      });
    }
  }
  @action.bound
  private async load(): Promise<void> {
    if (!this.name) {
      return this.clearName();
    }
    try {
      const res = await isPromise(this._query());
      this.update(res);
    } catch (error) {
      this.user.handleError('client-suggest-load', error);
    }
  }
  @action
  private update(data: ClientModelFields[]): void {
    this.clients = map(data, (c) => new ClientModel(c));
  }
}
