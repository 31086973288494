import React from 'react';
import './PaymentsInstructionsLink.css';

export const PaymentsInstructionsLink = ({
  onClick
}: {
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}) => (
  <a
    data-testid="payments-instructions-link"
    onClick={onClick}
    className="payments-instructions-link"
    href="/settings#payments-instructions">
    Edit payment instructions
  </a>
);
