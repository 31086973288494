import React, { FC } from 'react';
import { OrangeWarningIcon } from 'src/components/Icons';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { PaypalBaseBox } from '../../PaypalBaseBox';
import { PaymentMethod, useCTAHandler } from '../../../useCTAHandler';
import { usePaymentOnboardModal } from 'src/payments/hooks/usePaymentsOnboardModal';
import { PaymentsEnablingModal } from 'src/payments/components/PaymentsModals/PaymentsEnablingModal';
import { AppStore } from 'src/stores/AppStore';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import injectStore from 'src/util/injectStore';
import { getPaymentEventSource } from 'src/payments/utils';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';

const PaypalGlobalPaymentToggleBoxComponent: FC<
  {
    checked: boolean;
    activatePayments: () => Promise<void>;
  } & { store: AppStore }
> = ({ checked, activatePayments, store: { user } }) => {
  const paypalSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource()
  };
  const { isOpen, showModal, hideModal } = usePaymentOnboardModal();
  const { handleActivatingPaymentsFromInvoice, loading } = useCTAHandler(PaymentMethod.Paypal);

  const onClickHandler = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.modalEnabling
    });
    handleActivatingPaymentsFromInvoice(activatePayments);
  };

  const onHideModal = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.modalEnablingClose
    });
    hideModal();
  };

  useTrackPaymentsViewedEvent(
    { ...paypalSharedMetadata, component: PaymentEventComponent.tileGlobalDisabled },
    user.events
  );

  return (
    <div data-testid="paypalGlobalPaymentToggleBox">
      <PaypalBaseBox
        icon={<OrangeWarningIcon />}
        actionElement={
          <div className="w-auto max-w-[30%] flex items-center justify-center">
            <ToggleSwitch checked={checked} onChange={showModal} disabled={loading} />
          </div>
        }
      />
      <PaymentsEnablingModal
        activePaymentProvider={PaymentEventActivePaymentProvider.paypal}
        isOpen={isOpen}
        hideModal={onHideModal}
        onClickHandler={onClickHandler}
      />
    </div>
  );
};

export const PaypalGlobalPaymentToggleBox = injectStore(PaypalGlobalPaymentToggleBoxComponent);
