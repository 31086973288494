import { observable, action } from 'mobx';
import ErrorModel from './ErrorModel';
import environmentStore from '../stores/EnvironmentStore';

class ErrorListModel {
  @observable errors: ErrorModel[] = [];

  @action.bound
  pushError(data: {}) {
    if (environmentStore.appDev) {
      this.errors.unshift(new ErrorModel(data));
      if (this.errors.length >= 5) {
        this.errors = this.errors.slice(0, 5);
      }
    }
  }
}

export default new ErrorListModel();
