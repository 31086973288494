import React, { useState } from 'react';
import { confirmAccountDeletion } from 'src/apis/accountAPI';
import { defineMessages } from 'src/i18n';
import { injectIntl, ISIntl } from 'src/i18n/utils';
import AlertModel from 'src/models/AlertModel';
import { AppStore } from 'src/stores/AppStore';
import { compose } from 'src/util/functions';
import { observer } from 'mobx-react';
import { Loading } from '../Loading';
import { clearUserSession } from 'src/models/UserAuth';
import { Button } from '../Button';
import { useTokenValidation } from './hooks/useTokenValidation';

const messages = defineMessages({
  deleteAccountTitle: {
    id: 'delete.account.title',
    defaultMessage: 'Delete your account?'
  },
  deleteAccountBody: {
    id: 'delete.account.body',
    defaultMessage:
      'You are about to delete your account. You will lose all of your data. Are you sure you want to proceed?'
  },
  deleteAccountActiveSubBody: {
    id: 'delete.account.active.sub.body',
    defaultMessage:
      'Please reach out to our Customer Support team for help deleting an account with an active subscription'
  },
  deleteAccountBtn: {
    id: 'delete.account.btn',
    defaultMessage: 'Delete account'
  },
  deleteAccountBtnCancel: {
    id: 'delete.account.cancel.btn',
    defaultMessage: 'Cancel'
  },
  accountDeletionSuccessTitle: {
    id: 'account.deletion.alert.successTitle',
    defaultMessage: 'Your account has been deleted!'
  },
  accountDeletionSuccessMessage: {
    id: 'account.deletion.alert.successMessage',
    defaultMessage:
      "We're sad to see you go! If you need any help please contact us: support@invoicesimple.com"
  },
  deleteTokenInvalidTitle: {
    id: 'delete.token.alert.invalidTitle',
    defaultMessage: 'Account deletion link has expired.'
  },
  deleteTokenInvalidMessage: {
    id: 'delete.token.alert.invalidMessage',
    defaultMessage: 'Initiate your account deletion request again from the app.'
  },
  accountDeletionErrorTitle: {
    id: 'account.deletion.error.title',
    defaultMessage: 'Something went wrong'
  },
  accountDeletionErrorMessage: {
    id: 'account.deletion.error.message',
    defaultMessage: 'If you need any help please contact us: support@invoicesimple.com'
  }
});

interface DeleteAccountProps {
  store: AppStore;
  intl: ISIntl;
}
const DeleteAccount = ({ store, intl }: DeleteAccountProps) => {
  const { formatMessage, fta } = intl;
  const user = store.user;

  const [isDeleting, setIsDeleting] = useState<boolean | undefined>(false);

  const { isLoading, isTokenActive, tokenId } = useTokenValidation({ store, formatMessage });

  const deleteAccount = async () => {
    // deletes account and redirects user to login page
    setIsDeleting(true);
    if (isTokenActive) {
      try {
        await confirmAccountDeletion(user, tokenId);
        await clearUserSession();
        store.location.navigateTo(`/login`);
        AlertModel.setAlert(
          'warning',
          formatMessage(messages.accountDeletionSuccessTitle),
          formatMessage(messages.accountDeletionSuccessMessage)
        );
      } catch (err) {
        user.trackError('account-deletion', err);
        AlertModel.setAlert(
          'warning',
          formatMessage(messages.accountDeletionErrorTitle),
          formatMessage(messages.accountDeletionErrorMessage)
        );
      } finally {
        setIsDeleting(false);
      }
    }
  };

  if (isLoading) return <Loading />;

  return (
    <div id="tailwind" className="upgrade-section">
      <div className="max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-white text-center rounded-lg shadow-lg py-12 px-10 sm:px-12 lg:py-16 lg:px-16">
          <p className="mt-10 text-4xl leading-10 font-bold">{fta(messages.deleteAccountTitle)}</p>
          <p className="mt-4 text-3xl leading-10">
            {user.isSubPaid
              ? formatMessage(messages.deleteAccountActiveSubBody)
              : formatMessage(messages.deleteAccountBody)}
          </p>
          <div className="mt-12 flex sm:flex-row flex-col justify-center gap-4 sm:gap-10">
            {!user.isSubPaid && (
              <>
                <Button onClick={() => store.nav('invoiceList')} style="gray">
                  {formatMessage(messages.deleteAccountBtnCancel)}
                </Button>
                <Button loading={isDeleting} onClick={() => deleteAccount()} style="red">
                  {fta(messages.deleteAccountBtn)}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(injectIntl, observer)(DeleteAccount);
