import axios from 'axios';
import { API_V3_BASE_PATH } from './httpClient';
import { type SubscriptionTier } from '@invoice-simple/common';
import UserModel from 'src/models/UserModel';

export interface SubscriptionsResponse {
  upgrades: Record<string, any>[];
  subscriptions: Record<string, any>[];
  docsThresholdCount: number;
  docsCountLastMonth: number;
  accountCreationTimestamp: number | null;
  hadPaidSubsInthePast?: boolean;
}

interface SubscriptionStatus {
  active: boolean;
  activeUntil: number;
  hasFreeSub: boolean;
  hasPaidSub: boolean;
  isWebAndMobile?: boolean;
  subscriptionTier: SubscriptionTier | null;
  planInterval: string | null;
  hadPaidSubsInThePast: boolean;
  docsThresholdCount: number;
  allDocsCount?: number;
  docsCountLastMonth?: number;
  accountCreationTimestamp: number;
  orderSku: string;
}

export const getSubscriptionStatusV3 = async (user: UserModel) => {
  const { baseURL, headers } = user.getApiV3ReqOpts();
  const path = baseURL + API_V3_BASE_PATH + '/subscription-status';
  const { data } = await axios.post<SubscriptionStatus>(path, undefined, { headers });

  return data;
};

export const getSubscriptionsV3 = async (user: UserModel) => {
  const { baseURL, headers } = user.getApiV3ReqOpts();
  const path = baseURL + API_V3_BASE_PATH + '/subscriptions';
  const { data } = await axios.get<Record<string, any>[]>(path, { headers });

  return data;
};

export const cancelSubscription = async (user: UserModel) => {
  const { baseURL, headers } = user.getApiV3ReqOpts();
  const path = baseURL + API_V3_BASE_PATH + '/subscription-cancel';
  const { data } = await axios.post(path, undefined, { headers });

  return data;
};
