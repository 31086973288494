import React from 'react';

import {
  InvoicePaypalBox,
  InvoiceStripeBox,
  LearnMore
} from 'src/payments/components/IntegratedPayments';
import { usePaypalContext } from 'src/payments/contexts';
import { useStripeContext } from 'src/payments/contexts/StripeAccountContext';
import { useIsPaypalVStripeVariantFlagEnabled } from 'src/payments/hooks/useIsPaypalVStripeVariantFlagEnabled';
import { ManagePaymentSettings } from './ManagePaymentSettings';

export const InvoiceSimplePayments = () => {
  const stripeContext = useStripeContext();
  const paypalContext = usePaypalContext();
  const isPaypalVStripeVariantFlagEnabled = useIsPaypalVStripeVariantFlagEnabled();

  if (!paypalContext?.isPaypalEligible() && !stripeContext?.isStripeEligible) {
    return <></>;
  }

  const shouldShowManagePaymentSettings =
    stripeContext?.isStripeAccepting ||
    stripeContext?.isStripePendingVerification ||
    (paypalContext?.isPaypalEligible() && !paypalContext?.isPaypalInitial());

  return (
    <div id="tailwind">
      <div data-testid="invoice-simple-payments" />
      <div
        style={{
          borderBottom: '1px solid'
        }}
        className="uppercase text-md text-zinc-800 !border-black/10 md:!border-zinc-800 pt-12 pb-2 px-0 font-medium leading-4 tracking-tight mb-2">
        Invoice Simple Payments
      </div>
      <div className="grid grid-cols-1 gap-4">
        <LearnMore />
        {isPaypalVStripeVariantFlagEnabled && <InvoiceStripeBox />}
        {<InvoicePaypalBox />}
        {shouldShowManagePaymentSettings && <ManagePaymentSettings />}
      </div>
    </div>
  );
};
