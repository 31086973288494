import React from 'react';
import { observer } from 'mobx-react';
import './WelcomeAlert.css';
import { injectIntl, defineMessages } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';

interface Prop {
  show: boolean;
  onDismiss: () => void;
  intl?: ISIntl;
}

// default
const messages = defineMessages({
  buttonCloseLabel: {
    id: 'welcome.alert.button.close.label',
    defaultMessage: 'Ok, thanks!'
  }
});

const getDocMessages = (path: string) => {
  // estimate
  if (/estimate/i.test(path)) {
    return defineMessages({
      h1: {
        id: 'welcome.alert.estimate.header.1',
        defaultMessage: 'Estimate Maker'
      },
      h2: {
        id: 'welcome.alert.estimate.header.2',
        defaultMessage: 'Printable estimates using our online estimate maker'
      },
      body: {
        id: 'welcome.alert.estimate.description',
        defaultMessage:
          'Use our attractive estimate templates to make estimates straight from your web browser. No sign up necessary. The estimate maker makes downloadable and printable estimates.'
      }
    });
  }

  // receipt
  if (/receipt/i.test(path)) {
    return defineMessages({
      h1: {
        id: 'welcome.alert.receipt.header.1',
        defaultMessage: 'Receipt Maker'
      },
      h2: {
        id: 'welcome.alert.receipt.header.2',
        defaultMessage: 'Use our receipt maker to create professional receipts'
      },
      body: {
        id: 'welcome.alert.receipt.description',
        defaultMessage:
          'Use our attractive receipt templates to make receipts straight from your web browser. No sign up necessary. The receipt maker makes downloadable and printable receipts.'
      }
    });
  }

  // default
  return defineMessages({
    h1: {
      id: 'welcome.alert.invoice.header.1',
      defaultMessage: 'Invoice Generator'
    },
    h2: {
      id: 'welcome.alert.invoice.header.2',
      defaultMessage: 'Use our invoice generator to create professional invoices'
    },
    body: {
      id: 'welcome.alert.invoice.description',
      defaultMessage:
        'Our invoice generator lets you create invoices straight from your web browser, no sign up necessary. The invoices you make can be sent and paid online or downloaded as a PDF.'
    }
  });
};

@injectIntl
@observer
export default class WelcomeAlert extends React.Component<Prop, any> {
  handleDismiss = () => {
    this.props.onDismiss();
  };
  render() {
    const docMessages = getDocMessages(location.pathname);
    const { formatMessage, fta } = this.props.intl!;
    if (this.props.show) {
      return (
        <header className="welcome-alert d-print-none clearfix">
          <div className="container">
            <div className="col-lg-9">
              <h1>{fta(docMessages.h1)}</h1>
              <h2>{formatMessage(docMessages.h2)}</h2>
              <div className="welcome-alert-body">{formatMessage(docMessages.body)}</div>
              <a
                className="btn btn-light"
                rel="nofollow"
                id="welcome-ok"
                onClick={this.handleDismiss}>
                {formatMessage(messages.buttonCloseLabel)}
              </a>
            </div>
          </div>
        </header>
      );
    }
    {
      return null;
    }
  }
}
