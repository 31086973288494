import React, { useEffect } from 'react';

import { Banner } from '../Banner';
import { useDocLimitBanner } from './useDocLimitBanner';
import { trackDocLimitBannerEvent } from './utils';

export const DocLimitBanner = () => {
  const bannerConfig = useDocLimitBanner();

  useEffect(() => {
    if (!bannerConfig) return;
    trackDocLimitBannerEvent('show');
  }, [bannerConfig]);

  if (!bannerConfig) return null;

  return <Banner {...bannerConfig} />;
};
