import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { hexToRgb } from './hexToRgb';

interface Props {
  onChange: (color: string) => void;
  isOpen: boolean;
  isLoading: boolean | undefined;
  value: string | undefined;
}

export const CustomColorWidget: React.FunctionComponent<Props> = observer((props) => {
  const [color, setColor] = useState<number[]>(hexToRgb(props.value));
  const handleChange = (color: ColorObject) => setColor(hexToRgb(color.hex));
  const handleUpstreamChange = (color: ColorObject) => props.onChange(color.hex);

  if (!props.isOpen) {
    return null;
  }

  return (
    <div
      id="color-widget"
      style={{
        maxWidth: '100%',
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `12px`,
        boxShadow: 'none'
      }}>
      <ChromePicker
        color={{
          r: color[0],
          g: color[1],
          b: color[2]
        }}
        onChangeComplete={handleUpstreamChange}
        onChange={handleChange}
        disableAlpha
        styles={{
          default: {
            picker: {
              borderRadius: '0px',
              boxShadow: 'none',
              fontFamily: 'Roboto'
            },
            body: {
              padding: '8px'
            }
          }
        }}
      />
    </div>
  );
});

interface HSLA {
  h: number;
  s: number;
  l: number;
  a: number;
}

interface HSVA {
  h: number;
  s: number;
  v: number;
  a: number;
}

interface RGBA {
  r: number;
  g: number;
  b: number;
  a: number;
}

interface ColorObject {
  hex: string;
  hsl: HSLA;
  hsv: HSVA;
  oldHue: number;
  rgb: RGBA;
  source: string;
}
