import axios, { AxiosPromise, AxiosError } from 'axios';
import User from 'src/models/UserModel';

export interface InvoiceEmailData {
  sendToSelf: boolean;
  toEmail: string;
  fromEmail: string;
  ccEmail?: string;
  msgBody: string;
  no_branding: boolean;
  invoiceUrl: string;
  color: string;
  accountId: string;
  invoiceId: string;
  invoiceNo: string;
}

/**
 * replaces url in type of 'https://is-web-app-pr-****.reviewapp.getinvoicesimple.com/v/****' with 'https://app.staging.invoicesimple.com/v/****'
 * @param invoiceUrl string
 */
export const findAndReplacePreviewUrlWithStagingUrl = (invoiceUrl: string): string => {
  const previewUrl = 'reviewapp';
  const stagingHost = process.env.REACT_APP_HOST;
  if (!invoiceUrl.includes(previewUrl) || !stagingHost) return invoiceUrl;

  const url = new URL(invoiceUrl);
  return `https://${stagingHost}${url.pathname}`;
};

export const sendInvoiceEmail = async (sendData: InvoiceEmailData): Promise<void> => {
  const user = User.getInstance();

  try {
    // leave recepients as array to allow easy update to multi email
    const recepients = [sendData.toEmail];

    if (recepients.length > 10) {
      throw new Error('No more than 10 recipients are allowed');
    }

    const sendRequests: AxiosPromise[] = [];
    recepients.forEach((destination) =>
      sendRequests.push(
        axios.post(
          '/api/v2/email-invoice',
          {
            ...sendData,
            toEmail: destination,
            invoiceUrl: findAndReplacePreviewUrlWithStagingUrl(sendData.invoiceUrl)
          },
          user.getApiReqOpts()
        )
      )
    );
    await Promise.all(sendRequests);
  } catch (err) {
    const error = err as AxiosError<{ errorMessage?: string }>;

    error.message =
      (error.response && error.response.data && error.response.data.errorMessage) || error.message;
    user.trackError('invoice-email-deliver', error);
    throw error;
  }
};

export interface SubscriptionEmailData {
  accountId: string;
  firstName: string | undefined;
  email: string | undefined;
}

export const sendPremiumSubscriptionEmail = async (data: SubscriptionEmailData): Promise<void> => {
  const user = User.getInstance();

  try {
    axios.post('/api/v2/subscription/email/premium', data, user.getApiReqOpts());
  } catch (err) {
    const error = err as AxiosError<{ errorMessage?: string }>;

    error.message =
      (error.response && error.response.data && error.response.data.errorMessage) || error.message;
    user.trackError('subscription-email-deliver', error);
    throw error;
  }
};
