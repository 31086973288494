import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';

import { useISIntl } from 'src/i18n';
import { AppStore } from 'src/stores/AppStore';
import { Loading } from '../Loading';
import { getIconFromMessageEvent } from './Icons';
import { DocHistoryInfo } from './types';
import { getDisplayTextFromEventType, getDocHistory } from './util';

import './DocHistory.scss';

export const DocHistory = ({ store }: { store: AppStore }) => {
  const intl = useISIntl();
  const [docHistory, setDocHistory] = useState<DocHistoryInfo[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadHistory();

    async function loadHistory() {
      setIsLoading(true);
      const history = await getDocHistory(store.doc.remoteId, store.user);
      setIsLoading(false);
      setDocHistory(history);
    }
  }, [store.doc.remoteId]);

  // manually inject signed event to history if this is a signed estimate
  useEffect(() => {
    if (
      store.doc.isSignedEstimate &&
      docHistory &&
      docHistory.find((h) => h.status?.eventType === 'signed') === undefined
    ) {
      const eventTime = +new Date(store.doc.setting.estimateSignedAt!);
      const newItem: DocHistoryInfo = {
        _id: 'new',
        status: { eventType: 'signed', eventTime },
        to: store.doc.setting.estimateSignedText || '',
        sendTime: eventTime
      };
      setDocHistory([...(docHistory ? docHistory : []), newItem]);
    }
  }, [docHistory, store.doc.isSignedEstimate]);

  if (store.isLoading) {
    return <Loading />;
  }

  function renderRow(history: DocHistoryInfo, key: number) {
    if (!history.status) {
      history.status = { eventType: 'sent', eventTime: history.sendTime };
    }
    const eventDateTime = new Date(history.status.eventTime);
    const formattedDate = format(eventDateTime, 'PP');
    const formattedTime = format(eventDateTime, 'pp');
    const { eventType } = history.status;
    return (
      <tr data-testid="doc-history-row" className="invoice-row" key={key}>
        <td style={{ width: '12%' }}>
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <i className="status-icon">{getIconFromMessageEvent(eventType)}</i>
            <p>{getDisplayTextFromEventType(eventType, intl)}</p>
          </div>
        </td>
        <td className="share-recipient">{history.to}</td>
        <td className="event-time">{formattedDate}</td>
        <td className="event-time">{formattedTime}</td>
      </tr>
    );
  }

  return (
    <div className="doc-history container">
      {!isLoading && (
        <div className="page-header" style={{ display: 'block' }}>
          <h1>
            {store.doc.invoiceNo} {intl.ft({ id: 'doc.history.title', defaultMessage: 'History' })}
          </h1>
          {store.doc.deleted && (
            <p className="text-error">
              {intl.ft({ id: 'doc.deleted', defaultMessage: 'Deleted' })}
            </p>
          )}
        </div>
      )}
      <div className="invoice-list invoice-list-body panel">
        <table className="table">
          {isLoading ? (
            <Loader />
          ) : (
            <tbody>
              {docHistory?.length ? (
                docHistory.map((history, key) => renderRow(history, key))
              ) : (
                <tr>
                  <td>
                    {intl.ft({
                      id: 'doc.history.empty.text',
                      defaultMessage: 'Invoice sharing history will show up here.'
                    })}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

function Loader() {
  return (
    <tbody style={{ textAlign: 'center' }}>
      <tr>
        <td>
          <FontAwesomeIcon icon="spinner" size="2x" spin />
        </td>
      </tr>
    </tbody>
  );
}
