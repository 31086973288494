import React from 'react';
import { observer } from 'mobx-react';
import { IntlProvider } from 'react-intl';

import localeStore from 'src/stores/LocaleStore';

export const IntlMobxProvider = observer((props) => (
  <IntlProvider
    {...props}
    key={localeStore.currentLocale}
    locale={localeStore.currentLocale}
    messages={localeStore.currentMessages}
  />
));
