import axios from 'axios';
import countryToCurrency, { Currencies } from 'country-to-currency';
import isObject from 'lodash/isObject';
import Parse from 'parse';

import { DocTypes } from '@invoice-simple/common';

import { changeUser, getUser } from '@braze/web-sdk';

import { updateAnalyticsProviders } from 'src/analytics/controller';
import { trackFacebookEvent } from 'src/analytics/facebook';
import Intercom from 'src/analytics/intercom';
import {
  LOGIN_REQUEST_ERRORS,
  PASSWORD_RESET_REQUEST_RESULTS,
  requestLogin,
  requestPasswordReset
} from 'src/apis/authAPI';
import { trackFriendbuySignup } from 'src/apis/friendbuyAPI';
import { parseUserLogout, parseUserSaveData } from 'src/apis/userAPI';
import { redirectToPaymentsLandingPage } from 'src/payments';
import { clearPaypalToken } from 'src/payments/utils/apis/paypalToken';
import { clearStripeToken } from 'src/payments/utils/apis/stripeToken';
import { defaultsStore } from 'src/stores/DefaultsStore';
import environmentStore from 'src/stores/EnvironmentStore';
import LocaleStore from 'src/stores/LocaleStore';
import { LocalStorageKey } from 'src/types/LocalStorageKey';
import { SMSSignupData, SMSSignupResult } from 'src/types/SMS';
import { UserAttributes } from 'src/util/braze';
import { removeCookie, setCookie } from 'src/util/cookie';
import { isSupportedCurrency } from 'src/util/currency';
import { trackImpactSignupEvent } from 'src/util/impact';
import { isMigratedEditorEnabled } from 'src/util/isMigratedEditorEnabled';
import { isMigratedSettingsEnabled } from 'src/util/isMigratedSettingsEnabled';
import { validateUser, VALIDATION_ERRORS } from 'src/util/isValidUser';
import { isOnboardingV1Enabled, shouldRedirectToOnboarding } from 'src/util/onboarding';
import { EVENTS, pubsub } from 'src/util/pubsub';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import alertModel from './AlertModel';
import location from './LocationModel';
import UserModel from './UserModel';

const SIGNUP_PASSWORD_LIMIT = 6;
const LOGIN_PASSWORD_LIMIT = 0;

const trackFacebookEventWhenNewSession = (user: UserModel) => {
  // only start trial for new user
  if (user.isSessionNew) {
    trackFacebookEvent('trackCustom', 'CreateGuestUser', {
      userId: user.id,
      ...user.abTests
    });
  }
};

export const initFromServerData = async (user: UserModel): Promise<void> => {
  const data = defaultsStore.bootstrapData;
  try {
    if (data.error) {
      throw new Error(data.error.message);
    }

    if (data.settings && data.settings.length > 0) {
      user.settingList.initFromServerData(data.settings);
    }

    if (data.user && isObject(data.user)) {
      // Ensures that this function doesn't fail when called with incomplete user data (i.e. automated tests)
      if (!data.user.className) {
        data.user.className = 'UserModel';
      }

      await Parse.User.hydrate(data.user);
      user.update(Parse.User.fromJSON(data.user));
    }

    if (data.request && data.request.id) {
      user.requestId = data.request.id;
    }
  } catch (err) {
    user.trackError('user-init-from-server-data', err);
    alertModel.setAlert(
      'danger',
      'Error creating guest account',
      'Please reload the page or contact support.'
    );
  }
};

export const init = async (user: UserModel): Promise<void> => {
  try {
    await initFromServerData(user);
    updateAnalyticsProviders({ user });
    trackFacebookEventWhenNewSession(user);

    // only load entities when user is known
    // i.e. was provided by the server
    if (!location.isPublicPath()) {
      user.loadUserEntities();
    }
  } catch (error) {
    user.handleError('user-init', error);
  }
};

export const clearUserSession = async () => {
  removeCookie('sessionToken');
  removeCookie('lastOnboardingPage');
  clearPaypalToken();
  clearStripeToken();
  await parseUserLogout();
};

const clearOnboardingCookies = () => {
  removeCookie('lastOnboardingPage');
  removeCookie('onboardingV1Enabled');
};

export const login = async ({
  user,
  postLoginReturnPath = '/invoices',
  withSubscriptionTransfer,
  onboardingV1Enabled = false
}: {
  user: UserModel;
  postLoginReturnPath?: string;
  withSubscriptionTransfer?: boolean;
  onboardingV1Enabled?: boolean;
}): Promise<string | undefined> => {
  try {
    alertModel.resetAlert();
    user.isLoggingIn = true;

    validateUser(user.loginData, LOGIN_PASSWORD_LIMIT);

    const { sessionToken, subscriptionTransferred } = await requestLogin(
      {
        username: user.loginData.email,
        password: user.loginData.password,
        installation: environmentStore.getInstallData()
      },
      withSubscriptionTransfer
    );

    setCookie('sessionToken', sessionToken);
    clearOnboardingCookies();

    let queryParams = '';
    if (subscriptionTransferred !== undefined) {
      queryParams = new URLSearchParams({
        [URLQueryParamKeys.SUB_TRANSFER]: subscriptionTransferred ? 'true' : 'false'
      }).toString();
    }
    if (postLoginReturnPath === URLQueryParamKeys.PAYMENTS_LANDING) {
      await redirectToPaymentsLandingPage(sessionToken);
      return;
    }

    location.navigateHard(
      postLoginReturnPath.concat(`${queryParams ? `?${queryParams}` : queryParams}`)
    );
    return;
  } catch (error) {
    switch (error.message) {
      case VALIDATION_ERRORS.EMPTY_FIELDS:
        if (onboardingV1Enabled) return VALIDATION_ERRORS.EMPTY_FIELDS;
        alertModel.setAlertObject({
          type: 'danger',
          titleMessageId: 'alertLoginFailedTitle',
          bodyMessageId: 'alertEmptyFields'
        });
        return;
      case VALIDATION_ERRORS.WRONG_EMAIL:
        if (onboardingV1Enabled) return VALIDATION_ERRORS.WRONG_EMAIL;
        alertModel.setAlertObject({
          type: 'danger',
          titleMessageId: 'alertLoginFailedTitle',
          bodyMessageId: 'alertWrongEmailBody',
          params: {
            email: user.loginData.email
          }
        });
        return;
      case VALIDATION_ERRORS.SHORT_PASSWORD:
        if (onboardingV1Enabled) return VALIDATION_ERRORS.SHORT_PASSWORD;
        alertModel.setAlertObject({
          type: 'danger',
          titleMessageId: 'alertLoginFailedTitle',
          bodyMessageId: 'alertShortPassword',
          params: {
            passwordLimit: LOGIN_PASSWORD_LIMIT
          }
        });
        return;
      case LOGIN_REQUEST_ERRORS.UNKNOWN_EMAIL:
        if (onboardingV1Enabled) return LOGIN_REQUEST_ERRORS.UNKNOWN_EMAIL;
        alertModel.setAlertObject({
          type: 'danger',
          titleMessageId: 'alertLoginFailedTitle',
          bodyMessageId: 'alertUnknownEmailBody',
          params: {
            email: user.loginData.email
          }
        });
        return;
      case LOGIN_REQUEST_ERRORS.WRONG_PASSWORD:
        if (onboardingV1Enabled) return LOGIN_REQUEST_ERRORS.WRONG_PASSWORD;
        alertModel.setAlertObject({
          type: 'danger',
          titleMessageId: 'alertLoginFailedTitle',
          bodyMessageId: 'alertWrongPasswordBody'
        });
        return;
      case LOGIN_REQUEST_ERRORS.NO_ACCOUNT:
        Intercom('show');
        if (onboardingV1Enabled) return LOGIN_REQUEST_ERRORS.NO_ACCOUNT;
        alertModel.setAlertObject({
          type: 'danger',
          titleMessageId: 'alertLoginFailedTitle',
          bodyMessageId: 'alertAccountMissingBody'
        });
        return;
      case LOGIN_REQUEST_ERRORS.INVALID_EMAIL:
        if (onboardingV1Enabled) return LOGIN_REQUEST_ERRORS.INVALID_EMAIL;
        alertModel.setAlertObject({
          type: 'danger',
          titleMessageId: 'alertLoginFailedTitle',
          bodyMessageId: 'alertWrongEmailBody',
          params: {
            email: user.loginData.email
          }
        });
        return;
      case LOGIN_REQUEST_ERRORS.INVALID_RESPONSE:
        if (onboardingV1Enabled) return LOGIN_REQUEST_ERRORS.INVALID_RESPONSE;
        alertModel.setAlertObject({
          type: 'danger',
          titleMessageId: 'alertLoginFailedTitle',
          bodyMessageId: 'alertConnectionFailedBody',
          params: {
            email: user.loginData.email
          }
        });
        return;
      case LOGIN_REQUEST_ERRORS.SUBSCRIPTION_TRANSFER_ERROR:
        alertModel.setAlertObject({
          type: 'danger',
          titleMessageId: 'alertHasActiveSubscriptionTitle',
          bodyMessageId: 'alertHasActiveSubscriptionBody'
        });
        return;
      default:
        UserModel.getInstance().trackError('user-login', error);
        if (onboardingV1Enabled) return LOGIN_REQUEST_ERRORS.INVALID_RESPONSE;
        alertModel.setAlertObject({
          type: 'danger',
          titleMessageId: 'alertLoginFailedTitle',
          body: error.message
        });
        return;
    }
  } finally {
    user.isLoggingIn = false;
  }
};

export const saveUser = async (user: UserModel, data?: any): Promise<void> => {
  try {
    if (user.isSaving) {
      return;
    }
    user.isSaving = true;
    user.update(await parseUserSaveData(data || user.parseData));
  } catch (err) {
    user.handleError('user-save', err);
    throw err;
  } finally {
    user.isSaving = false;
  }
};

const signupErrorHandler = (user: UserModel, error: any) => {
  switch (error.message) {
    case VALIDATION_ERRORS.EMPTY_FIELDS:
      alertModel.setAlertObject({
        type: 'danger',
        titleMessageId: 'alertSignupFailedTitle',
        bodyMessageId: 'alertEmptyFields'
      });
      break;
    case VALIDATION_ERRORS.WRONG_EMAIL:
      alertModel.setAlertObject({
        type: 'danger',
        titleMessageId: 'alertSignupFailedTitle',
        bodyMessageId: 'alertWrongEmailBody',
        params: {
          email: user.signupData.email
        }
      });
      break;
    case VALIDATION_ERRORS.SHORT_PASSWORD:
      alertModel.setAlertObject({
        type: 'danger',
        titleMessageId: 'alertSignupFailedTitle',
        bodyMessageId: 'alertShortPassword',
        params: {
          passwordLimit: SIGNUP_PASSWORD_LIMIT
        }
      });
      break;
    default:
      switch (error.code) {
        /* An account with the given email already exists */
        case 202:
          /* For Signup V2 design (enabled for Onboarding V1 users) */
          if (isOnboardingV1Enabled()) {
            const f = LocaleStore.formatMessage;
            const body = `${f({ id: 'signup.alert.failed.accountExists' })} ${f({
              id: 'signup.alert.failed.loginInstead'
            })}`;
            alertModel.setAlertObject({
              type: 'warning',
              title: '',
              body,
              isClosable: false
            });
          } else {
            /* For Signup V1 (legacy signup design) */
            alertModel.setAlertObject({
              type: 'danger',
              titleMessageId: 'alertSignupFailedTitle',
              body: error.message
            });
          }
          break;
        case 1005:
          alertModel.setAlertObject({
            type: 'danger',
            titleMessageId: 'alertSignupFailedTitle',
            bodyMessageId: 'alertWrongEmailBody',
            params: {
              email: user.signupData.email
            }
          });
          break;

        default:
          alertModel.setAlertObject({
            type: 'danger',
            titleMessageId: 'alertSignupFailedTitle',
            body: error.message
          });
          break;
      }
      break;
  }
};

export const smsSignup = async (user: UserModel, token: string): Promise<boolean> => {
  const url = `/sms/signup/${token}`;
  const formData: SMSSignupData = user.signupData;

  let result = false;
  try {
    user.isSigningUp = true;
    validateUser(formData, SIGNUP_PASSWORD_LIMIT);
    const { data } = await axios.post<SMSSignupResult>(url, formData, user.getApiReqOpts());

    if (data.succeeded) {
      alertModel.setAlertObject({
        type: 'success',
        titleMessageId: 'alertSignupSmsSuccessTitle',
        bodyMessageId: 'alertSignupSuccessBody'
      });
      result = true;
    } else {
      alertModel.setAlertObject({
        type: 'danger',
        titleMessageId: 'alertSignupFailedTitle',
        bodyMessageId: data.usernameExists
          ? 'alertSignupFailedEmailExists'
          : 'alertAccountMissingBody'
      });
    }
  } catch (error) {
    signupErrorHandler(user, error);
  } finally {
    user.isSigningUp = false;
  }
  return result;
};

export const handleSignupNav = (user: UserModel) => {
  const ref = getURLQueryParam(URLQueryParamKeys.REF);

  if (shouldRedirectToOnboarding()) {
    if (ref) {
      localStorage.setItem(LocalStorageKey.SIGNUP_REF, ref);
    }

    getUser()?.setCustomUserAttribute(UserAttributes.WEB_ONBOARDING_V1_START, new Date());
    user.events.trackAction('onboarding-start', {
      'onboarding-version': 'v1'
    });

    location.navigateHard('/onboarding');
    return;
  }

  const documentId = getURLQueryParam(URLQueryParamKeys.ID);
  const docType = getURLQueryParam(URLQueryParamKeys.EMAIL_DOCTYPE);
  const returnPath = getURLQueryParam(URLQueryParamKeys.RETURN_PATH);

  if (returnPath?.includes(URLQueryParamKeys.SETTINGS)) {
    if (isMigratedSettingsEnabled(user.countryCode)) {
      window.location.assign('/settings');
      return;
    }
    location.nav('settingList');
    return;
  }

  if (user.advocateReferralCode) {
    location.nav('invoiceList', { ref: URLQueryParamKeys.REFERRAL_SIGNUP });
    return;
  }

  const shouldRedirectToInvoiceList =
    ref !== URLQueryParamKeys.SEND_DOCUMENT || !documentId || !docType;
  if (shouldRedirectToInvoiceList) {
    location.nav('invoiceList');
    return;
  }

  if (+docType === DocTypes.DOCTYPE_INVOICE) {
    if (isMigratedEditorEnabled(user.countryCode)) {
      // if on the NextJS app directory, we use params instead of path to
      // default the email modal open on the invoice-editor
      window.location.href = `${window.location.origin}/invoices/${documentId}?email=true`;
      return;
    }
    location.nav('invoiceEmail', { id: documentId });
    return;
  }

  if (isMigratedEditorEnabled(user.countryCode)) {
    // if on the NextJS app directory, we use params instead of path to
    // default the email modal open on the invoice-editor
    window.location.href = `${window.location.origin}/estimates/${documentId}?email=true`;
    return;
  }

  location.nav('estimateEmail', { id: documentId });
  return;
};

export const signup = async (user: UserModel, countryCode?: string): Promise<boolean> => {
  try {
    user.isSigningUp = true;
    // added signing up boolean validateUser for cases separate from login
    validateUser(user.signupData, SIGNUP_PASSWORD_LIMIT, user.isSigningUp);

    await saveUser(user, {
      ...user.parseData,
      ...user.signupData,
      ...(countryCode && { countryCode }),
      username: `new_${user.signupData.email}`.trim().toLowerCase()
    });

    // Sync after signup since their eligibility may now change for certain coupons
    await user.syncCoupons();

    // Set currency code based on country code selected during signup
    if (countryCode) {
      const countryCurrency: Currencies | undefined = countryToCurrency[countryCode]?.toUpperCase();
      const isValidCurrency = !!countryCurrency && isSupportedCurrency(countryCurrency);

      if (isValidCurrency) {
        user.settingList.setCurrencyCode(countryCurrency);
      }
    }

    updateAnalyticsProviders({ user });
    user.trackAppEventViaApi('signup', {
      'user-id': user.id,
      email: user.email
    });

    handleSignupNav(user);

    if (!user.advocateReferralCode) {
      alertModel.setAlertObject({
        type: 'success',
        titleMessageId: 'alertSignupSuccessTitle',
        bodyMessageId: 'alertSignupSuccessBody'
      });
    }

    setBrazeUserAttributes(user);
    trackFriendbuySignup(user);
    if (user?.accountSource === 'impact') {
      trackImpactSignupEvent({ userId: user.id, email: user.email });
    }

    return true;
  } catch (error) {
    signupErrorHandler(user, error);
  } finally {
    user.isSigningUp = false;
  }

  function setBrazeUserAttributes(user: UserModel) {
    if (user.id) {
      changeUser(user.id);
    }
    getUser()?.setFirstName(user.signupData.firstName);
    getUser()?.setLastName(user.signupData.lastName);
    getUser()?.setEmail(user.signupData.email);
    getUser()?.setCustomUserAttribute(UserAttributes.IS_GUEST, false);
    getUser()?.setCustomUserAttribute(UserAttributes.SIGNED_UP_DATE, new Date());

    if (user.advocateReferralCode) {
      getUser()?.setCustomUserAttribute(
        UserAttributes.ADVOCATE_REFERRAL_CODE,
        user.advocateReferralCode
      );
    }
  }
  return false;
};

export const logout = async (user: UserModel): Promise<void> => {
  try {
    user.isLoggingOut = true;

    user.trackAppEventViaApi('logout', { 'user-id': user.id });

    await clearUserSession();

    location.navigateHard(`/login#loggedOut`);
  } catch (err) {
    user.isLoggingOut = false;
    user.handleError('user-logout', err);
  }
};

export const resetPassword = async (user: UserModel): Promise<void> => {
  if (!user.passwordResetDataValid) {
    return alertModel.setAlert('danger', undefined, 'Please enter a valid email.');
  }

  try {
    const email = user.passwordResetData.email.trim().toLowerCase();
    const result = await requestPasswordReset({ email });
    switch (result) {
      case PASSWORD_RESET_REQUEST_RESULTS.SUCCESS:
        location.nav('login');
        alertModel.setAlert(
          'success',
          'Reset password email sent',
          `Please check ${email} for instructions.`
        );
        break;

      case PASSWORD_RESET_REQUEST_RESULTS.UNKNOWN_EMAIL:
        alertModel.setAlert('warning', undefined, `User with email ${email} not found.`);
        break;

      default:
        throw new Error('Unexepected result for password reset request');
    }
  } catch (error) {
    alertModel.setAlert(
      'danger',
      undefined,
      'Error requesting password reset, please contact support@invoicesimple.com'
    );
  }
};

pubsub.on(EVENTS.SESSION_EXPIRED, async () => {
  await clearUserSession();
  return location.navigateTo(`/login#sessionExpired`);
});

pubsub.on(EVENTS.ACCOUNT_MISSING, async () => {
  return location.navigateTo(`/login#accountMissing`);
});
