import React from 'react';

const scrollAware = (Wrapped: React.ComponentClass) => {
  return class ScrollAware extends React.Component<any, any> {
    static displayName = `ScrollAware(${Wrapped.displayName})`;

    initialPosition: number;
    touchStartPosition: number;
    lastScrollY: number;
    directionChangedPosition: number;
    directionDistance: number;
    lastDirection: string | null;

    constructor(props) {
      super(props);
      this.initialPosition = window.pageYOffset;
      this.lastScrollY = window.pageYOffset;
      this.lastDirection = null;
      this.directionDistance = 0;
      this.touchStartPosition = window.pageYOffset;
      this.directionChangedPosition = window.pageYOffset;

      this.state = {
        // touchstart - current Y position ;
        scrolledSinceTouchStarted: null,
        // current scrolling direction
        direction: null,
        // absolute value velocity
        velocity: null
      };
    }

    updateTouchPosition = () => {
      window.requestAnimationFrame(() => {
        this.touchStartPosition = window.pageYOffset;
      });
    };

    updateMetrics = () => {
      window.requestAnimationFrame(() => {
        const scrolledSinceTouchStarted = window.pageYOffset - this.touchStartPosition;

        // set velocity
        const velocity = this.lastScrollY - window.pageYOffset;
        this.lastScrollY = window.pageYOffset;

        // set direction
        let direction = 'up';
        if (velocity <= 0) {
          direction = 'down';
        }

        // set continiousDirection distance
        // increment
        if (direction === this.lastDirection) {
          this.directionDistance = Math.abs(this.directionChangedPosition - window.pageYOffset);
        } else {
          // reset
          this.directionDistance = 0;
          this.directionChangedPosition = window.pageYOffset;
        }
        this.lastDirection = direction;

        this.setState({
          velocity: Math.abs(velocity),
          scrolledSinceTouchStarted,
          directionDistance: this.directionDistance,
          direction
        });
      });
    };

    UNSAFE_componentWillMount() {
      document.addEventListener('touchstart', this.updateTouchPosition);
      document.addEventListener('scroll', this.updateMetrics);
    }

    componentWillUnmount() {
      document.removeEventListener('touchstart', this.updateTouchPosition);
      document.removeEventListener('scroll', this.updateMetrics);
    }

    render() {
      const { children, ...rest } = this.props;
      return React.createElement(
        Wrapped,
        {
          ...rest,
          ...this.state
        },
        children
      );
    }
  };
};

export default scrollAware;
