import React, { FC } from 'react';
import { CloseIcon } from 'src/components/Icons/CloseIcon';

type Props = {
  title: string;
  subTitle: string;
  onClose: () => void;
};

export const Header: FC<Props> = (props: Props) => {
  const { title, subTitle, onClose } = props;

  return (
    <header>
      <div className="flex flex-col text-start md:text-center mt-10 md:mt-0">
        <p className="w-full text-gray-800 text-4xl font-bold leading-loose">{title} 🎉</p>
        <p className="w-full text-gray-800 text-3xl font-normal leading-normal mt-1 md:mt-0">
          {subTitle}
        </p>
      </div>
      <button
        data-testid="close-button"
        className="p-3 border-none absolute top-[10px] right-[20px]"
        style={{ background: 'none' }}
        onClick={onClose}>
        <CloseIcon />
      </button>
    </header>
  );
};
