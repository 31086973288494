import React, { useState } from 'react';
import SettingModel from 'src/models/SettingModel';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { PaymentsSurchargeConsentModal } from '../PaymentsModals';

interface PaymentsEnableSurchargeProps {
  setting: SettingModel | undefined;
  store: AppStore;
}
export const text = 'Always add surcharge';
export const action = 'toggled';
export const feature = 'payments-surcharge-invoice-always-add-surcharge';

const PaymentsSurchargeToggle = ({ setting, store }: PaymentsEnableSurchargeProps) => {
  const [shouldShowConsentModal, setShouldShowConsentModal] = useState(false);

  const user = store.user;
  const trackToggleClicked = (value: boolean) => {
    user.events.trackButtonClick({
      text,
      action,
      value,
      feature
    });
  };

  const handleAlwaysAddSurchargeChange = async (value: boolean) => {
    if (!value) {
      setting?.setValue(false);
      trackToggleClicked(false);
    } else {
      const hasSurchargeConsentGiven = await user.getIsSurchargeConsentGiven();
      if (hasSurchargeConsentGiven) {
        setting?.setValue(true);
        trackToggleClicked(true);
      } else {
        setShouldShowConsentModal(true);
      }
    }
  };

  const onModalSubmit = async (consentMessage: string) => {
    try {
      await user.setSurchargeConsentGiven(consentMessage);
      setting?.setValue(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <PaymentsSurchargeConsentModal
        isOpen={shouldShowConsentModal}
        onSubmit={onModalSubmit}
        onClose={() => {
          setShouldShowConsentModal(false);
        }}
      />
      <div className="h-full flex items-center" data-testid="always-add-surcharge-toggle">
        <ToggleSwitch
          checked={setting?.valBool || false}
          disabled={false}
          onChange={handleAlwaysAddSurchargeChange}
        />
      </div>
    </>
  );
};

export const PaymentsSurchargeToggleFormGroup = injectStore(PaymentsSurchargeToggle);
