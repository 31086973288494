import React, { useEffect, useState } from 'react';
import { RoundedButton } from './rounded-button';
import { IconSpinner } from './icon-spinner';
import { useISIntl } from 'src/i18n';

interface FooterLink {
  text: string;
  href: string;
}

interface FooterProps {
  primaryButtonLoading?: boolean;
  primaryButtonDisabled?: boolean;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  formId?: string;
  footerLink?: FooterLink;
}

export const Footer = (props: FooterProps) => {
  const [secondaryButtonLoading, setSecondaryButtonLoading] = useState(false);
  const {
    formId,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    primaryButtonLoading,
    primaryButtonDisabled,
    footerLink
  } = props;

  useEffect(() => {
    return () => {
      setSecondaryButtonLoading(false);
    };
  }, []);

  const { ft } = useISIntl();

  const iconSpinnerClassName = 'ml-[2px]';
  const isLoading = primaryButtonLoading || secondaryButtonLoading;

  return (
    <footer className="flex flex-col w-full h-full self-stretch pt-[25px]">
      {footerLink && <FooterLink {...footerLink} />}
      <div className="md:h-px md:bg-gray-200" />

      <div className="flex flex-col md:flex-row justify-end md:pt-[20px] h-full md:h-fit gap-2">
        <RoundedButton
          {...{ type: 'button' }}
          color="secondary"
          disabled={isLoading}
          onClick={() => {
            if (onSecondaryButtonClick) {
              onSecondaryButtonClick();
              setSecondaryButtonLoading(true);
            }
          }}
          style={{
            border: '1px solid #999'
          }}>
          {ft({ id: 'dialog.modal.cancel.button' })}
          {secondaryButtonLoading && <IconSpinner color="black" className={iconSpinnerClassName} />}
        </RoundedButton>

        <RoundedButton
          {...(formId && { type: 'submit' })}
          form={formId}
          color="primary"
          onClick={onPrimaryButtonClick}
          disabled={primaryButtonDisabled || isLoading}>
          {ft({ id: 'dialog.modal.continue.button' })}
          {primaryButtonLoading && <IconSpinner color="white" className={iconSpinnerClassName} />}
        </RoundedButton>
      </div>
    </footer>
  );
};

const FooterLink = ({ href, text }: FooterLink) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const onClick = () => {
    setLoading(true);
  };

  return (
    <a
      href={href}
      className="text-center text-[14px] mb-4 underline text-gray-600 underline-offset-[2.5px]"
      onClick={onClick}>
      <span data-testid="footer-link" className="relative">
        {text}
        {loading && (
          <IconSpinner color="black" className="absolute right-[-25px] inline cursor-default" />
        )}
      </span>
    </a>
  );
};
