import React from 'react';
import { defineMessages, useISIntl } from 'src/i18n';

const messages = defineMessages({
  footer: {
    id: 'payments.box.currencyMismatch.footer',
    defaultMessage: 'Change invoice currency to match Payments currency.'
  }
});

function InvoiceCurrencyMismatchFooter({ dataTestId }: { dataTestId: string }) {
  const { ft } = useISIntl();
  return (
    <div
      data-testid={dataTestId}
      className="px-4 py-3 bg-gray-100 font-normal text-base leading-2 text-slate-800 font-sans">
      {ft(messages.footer)}
    </div>
  );
}

export default InvoiceCurrencyMismatchFooter;
