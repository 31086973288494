import React, { FC, useCallback } from 'react';
import { startPaypalOnboardingNoTerm } from 'src/payments';
import { getPaymentEventSource, onError } from 'src/payments/utils/utils';
import { defineMessages } from 'src/i18n';
import {
  OnboardModalProps,
  OnboardModalMessages,
  PaymentEventComponent,
  PaymentEventActivePaymentProvider
} from 'src/payments/types';
import { TopContainer, ValueProps, ButtonContainer } from '../../PaymentBasicElements';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';

import { PaypalModalImg } from './PaypalModalBase';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';

const messages = defineMessages({
  header: {
    id: 'paypalModal.prompt.finishSetup',
    defaultMessage: 'Finish Setup'
  },
  description1: {
    id: 'paypalModal.prompt.almostReadyToAccept',
    defaultMessage: "You're almost ready to accept online payments and get paid twice as fast!"
  },
  description2: {
    id: 'paypalModal.prompt.continueToPaypalToFinish',
    defaultMessage: 'Continue to PayPal to finish where you left off.'
  },
  ctaText: { id: 'paypalModal.prompt.continueToPaypal', defaultMessage: 'Continue To PayPal' }
});

const PaypalPendingModalComponent: FC<
  OnboardModalProps & {
    store: AppStore;
  }
> = ({ closeModal, store: { user } }) => {
  const paypalSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource()
  };
  const ft = (message: OnboardModalMessages) => message.defaultMessage;

  const handleClick = useCallback(async () => {
    try {
      user.events.trackAction('payments-cta', {
        ...paypalSharedMetadata,
        component: PaymentEventComponent.modalPending
      });
      await startPaypalOnboardingNoTerm({
        isNewTab: true,
        paypalRedesign: true
      });
      closeModal();
    } catch (error) {
      onError({ title: 'Error initiating PayPal onboarding', body: error.message });
    }
  }, []);

  const onClickXIcon = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.modalClosePending
    });
    closeModal();
  };

  const paymentsViewedMeta = {
    ...paypalSharedMetadata,
    component: PaymentEventComponent.modalPending
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <>
      <TopContainer
        onClickXIcon={onClickXIcon}
        headerMessage={ft(messages.header)}
        dataTestId="paypal--modal-x-button"
      />
      <main>
        <PaypalModalImg />
        <ValueProps>
          <b>{ft(messages.description1)}</b>
          <p className="pt-4">{ft(messages.description2)}</p>
        </ValueProps>
      </main>
      <ButtonContainer
        dataTestId="paypal--modal-submit-button"
        handleClick={handleClick}
        buttonMessage={ft(messages.ctaText)}
      />
    </>
  );
};

export const PaypalPendingModal = injectStore(PaypalPendingModalComponent);
