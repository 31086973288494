import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { injectIntl, defineMessages } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';

interface Prop {
  titleRemove?: string;
  titleConfirm?: string;
  titleCancel?: string;
  titleRestore?: string;
  onPending: () => void;
  onConfirm: () => void;
  pending: boolean;
  confirmed: boolean;
  disabled: boolean;
  displayLabel?: boolean;
  displayIcon?: boolean;
  style?: React.CSSProperties;
  intl?: ISIntl;
  className?: string;
}

const messages = defineMessages({
  deleteItem: {
    id: 'confirmDelete.deleteItem',
    defaultMessage: 'remove item'
  },
  confirmItem: {
    id: 'confirmDelete.confirmItem',
    defaultMessage: 'remove item'
  },
  cancelItem: {
    id: 'confirmDelete.cancelItem',
    defaultMessage: 'cancel item removal'
  },
  restoreItem: {
    id: 'confirmDelete.restoreItem',
    defaultMessage: 'restore item'
  },
  deleteClient: {
    id: 'confirmDelete.deleteClient',
    defaultMessage: 'remove client'
  },
  confirmClient: {
    id: 'confirmDelete.confirmClient',
    defaultMessage: 'remove client'
  },
  cancelClient: {
    id: 'confirmDelete.cancelClient',
    defaultMessage: 'cancel client removal'
  },
  restoreClient: {
    id: 'confirmDelete.restoreClient',
    defaultMessage: 'restore client'
  },
  deletePayment: {
    id: 'confirmDelete.deletePayment',
    defaultMessage: 'remove payment'
  },
  confirmPayment: {
    id: 'confirmDelete.confirmPayment',
    defaultMessage: 'remove payment'
  },
  cancelPayment: {
    id: 'confirmDelete.cancelPayment',
    defaultMessage: 'cancel payment removal'
  },
  restorePayment: {
    id: 'confirmDelete.restorePayment',
    defaultMessage: 'restore payment'
  }
});

@observer
export class ConfirmDeleteButton extends React.Component<Prop, any> {
  render() {
    const pending = this.props.pending;
    const confirmed = this.props.confirmed;
    const disabled = this.props.disabled;
    const displayLabel = this.props.displayLabel;
    const displayIcon = this.props.displayIcon;
    const btnClassString = cn('btn', this.props.className, { disabled: disabled });
    const classNames = cn('confirm-delete-button', this.props.className);
    return (
      <div className={classNames}>
        {!confirmed && !pending && (
          <button
            disabled={disabled}
            title={this.props.titleRemove}
            style={this.props.style}
            className={`${btnClassString} btn-remove`}
            onClick={() => this.props.onPending()}>
            {!!displayIcon && <FontAwesomeIcon icon="times" />}
            {!!displayLabel && <span>{this.props.titleRemove}</span>}
          </button>
        )}
        {!confirmed && pending && (
          <button
            disabled={disabled}
            title={this.props.titleCancel}
            style={this.props.style}
            className={`${btnClassString} btn-cancel`}
            onClick={() => this.props.onPending()}>
            {!!displayIcon && <FontAwesomeIcon icon="undo" />}
            {!!displayLabel && <span>{this.props.titleCancel}</span>}
          </button>
        )}
        {!confirmed && pending && (
          <button
            disabled={disabled}
            title={this.props.titleConfirm}
            style={this.props.style}
            className={`${btnClassString} btn-delete`}
            onClick={() => this.props.onConfirm()}>
            {!!displayIcon && <FontAwesomeIcon icon="trash" />}
            {!!displayLabel && <span>{this.props.titleConfirm}</span>}
          </button>
        )}
        {confirmed && (
          <button
            disabled={disabled}
            title={this.props.titleRestore}
            style={this.props.style}
            className={`${btnClassString} btn-restore`}
            onClick={() => this.props.onConfirm()}>
            {!!displayIcon && <FontAwesomeIcon icon="undo" />}
            {!!displayLabel && <span>{this.props.titleRestore}</span>}
          </button>
        )}
      </div>
    );
  }
}

export const ConfirmDeleteItem = injectIntl((props: Prop) => (
  <ConfirmDeleteButton
    titleRemove={props.intl!.fta(messages.deleteItem)}
    titleConfirm={props.intl!.fta(messages.confirmItem)}
    titleCancel={props.intl!.fta(messages.cancelItem)}
    titleRestore={props.intl!.fta(messages.restoreItem)}
    {...props}
  />
));

export const ConfirmDeleteClient = injectIntl((props: Prop) => (
  <ConfirmDeleteButton
    titleRemove={props.intl!.fta(messages.deleteClient)}
    titleConfirm={props.intl!.fta(messages.confirmClient)}
    titleCancel={props.intl!.fta(messages.cancelClient)}
    titleRestore={props.intl!.fta(messages.restoreClient)}
    {...props}
  />
));

export const ConfirmDeletePayment = injectIntl((props: Prop) => (
  <ConfirmDeleteButton
    titleRemove={props.intl!.fta(messages.deletePayment)}
    titleConfirm={props.intl!.fta(messages.confirmPayment)}
    titleCancel={props.intl!.fta(messages.cancelPayment)}
    titleRestore={props.intl!.fta(messages.restorePayment)}
    {...props}
  />
));
