import { defineMessages } from 'src/i18n';

export const app = defineMessages({
  invoice: {
    id: 'app.invoice',
    defaultMessage: 'invoice'
  },
  estimate: {
    id: 'app.estimate',
    defaultMessage: 'estimate'
  },
  receipt: {
    id: 'app.receipt',
    defaultMessage: 'receipt'
  },
  invoices: {
    id: 'app.invoices',
    defaultMessage: 'invoices'
  },
  estimates: {
    id: 'app.estimates',
    defaultMessage: 'estimates'
  },
  receipts: {
    id: 'app.receipts',
    defaultMessage: 'receipts'
  }
});
