import React, { FC } from 'react';
import { RoundedButton } from 'src/components/Onboarding/rounded-button';

type Props = {
  primaryText: string;
  secondaryText: string;
  primaryHandleClick: () => void;
  secondaryHandleClick: () => void;
};

export const ButtonContainer: FC<Props> = (props) => {
  const { primaryText, secondaryText, primaryHandleClick, secondaryHandleClick } = props;

  return (
    <footer className="h-full justify-center md:justify-end pb-4 md:pb-0 md:pt-4">
      <div className="flex flex-col md:flex-row justify-end h-full md:h-fit gap-3">
        <RoundedButton
          {...{ type: 'button' }}
          data-testid="secondary-button"
          color="secondary"
          onClick={secondaryHandleClick}
          style={{
            border: '1px solid #999'
          }}>
          {secondaryText}
        </RoundedButton>

        <RoundedButton data-testid="primary-button" color="primary" onClick={primaryHandleClick}>
          {primaryText}
        </RoundedButton>
      </div>
    </footer>
  );
};
