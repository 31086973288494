import React, { useState } from 'react';
import Rollbar from 'src/analytics/rollbar';
import { deletePhoto } from 'src/apis/photosAPI';
import { LoadingSpinnerIcon } from 'src/components/Icons';
import { PencilIcon } from 'src/components/InvoicePhotos/PencilIcon';
import { XIcon } from 'src/components/InvoicePhotos/XIcon';
import AlertModel from 'src/models/AlertModel';
import InvoiceModel from 'src/models/InvoiceModel';
import LocationModel from 'src/models/LocationModel';

interface SignaturePhotoActionsProps {
  invoice: InvoiceModel;
  onEdit: () => void;
  removeSigFromEditor: () => void;
}
export const SignaturePhotoActions = ({
  invoice,
  onEdit,
  removeSigFromEditor
}: SignaturePhotoActionsProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function onDelete() {
    setIsLoading(true);
    try {
      if (invoice.signature) {
        await deletePhoto(invoice.signature);
        await invoice.deleteSignature();
        removeSigFromEditor();
      }
    } catch (err) {
      AlertModel.setAlert(
        'danger',
        'Error deleting signature. Please try again.',
        'Unable to delete signature'
      );
      LocationModel.scrollTop();
      Rollbar.trackError('Error deleting signature', err, { invoice });
    } finally {
      setIsLoading(false);
      setIsDeleting(false);
    }
  }

  if (isLoading) {
    return (
      <footer
        className="invoice-photo-action-footer"
        data-testid="photo-delete-loading"
        id="tailwind">
        <LoadingSpinnerIcon color={'black'} className="w-7 h-7" />
      </footer>
    );
  }

  if (isDeleting) {
    return (
      <footer className="invoice-photo-action-footer" data-testid="confirm">
        <button data-testid="confirm-cancel" onClick={() => setIsDeleting(false)}>
          Cancel
        </button>
        <button data-testid="confirm-delete" onClick={onDelete} className="danger">
          Confirm
        </button>
      </footer>
    );
  }

  return (
    <footer data-testid="invoice-signature-photo-actions" className="invoice-photo-action-footer">
      <button data-testid="invoice-signature-edit" onClick={onEdit}>
        <PencilIcon />
      </button>
      <button
        onClick={() => {
          setIsDeleting(true);
        }}
        data-testid="invoice-signature-remove">
        <XIcon />
      </button>
    </footer>
  );
};
