import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ItemRow.scss';
import { CurrencyDisplay } from '../Currency';
import { PaginatedItemsResponse } from './usePaginatedItems';

interface Prop {
  index: number;
  currencySymbol: string;
  item: PaginatedItemsResponse['items'][0];
  onClick: (objectId: string) => void;
}
@observer
export default class ItemRow extends React.Component<Prop, any> {
  handleClick = () => {
    this.props.onClick(this.props.item.objectId);
  };
  render() {
    const item = this.props.item;
    const code = item.code || `Item #${this.props.index + 1}`;
    const rowClass = item.code ? 'item-row' : 'item-row item-row-empty';
    return (
      <tr className={rowClass} key={item.remoteId} onClick={this.handleClick}>
        <td className="item-col-code">{code}</td>
        <td className="item-col-description">{item.description}</td>
        <td className="item-col-rate">
          <CurrencyDisplay value={item.rate} />
          {item.unit && <span className="item-unit">/{item.unit}</span>}
        </td>
        <td className="item-col-taxable">
          {item.taxable && <FontAwesomeIcon icon="check-square" />}
        </td>
      </tr>
    );
  }
}
