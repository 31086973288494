import React from 'react';

type CashIconProps = React.SVGProps<SVGSVGElement> & { fillColor?: string };

export const CashIcon: React.FC<CashIconProps> = ({ fillColor = '#212121', ...props }) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.875 6.5625C6.48261 6.5625 5.14726 7.11562 4.16269 8.10019C3.17812 9.08476 2.625 10.4201 2.625 11.8125V13.125H39.375V11.8125C39.375 10.4201 38.8219 9.08476 37.8373 8.10019C36.8527 7.11562 35.5174 6.5625 34.125 6.5625H7.875Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.375 17.0625H2.625V30.1875C2.625 31.5799 3.17812 32.9152 4.16269 33.8998C5.14726 34.8844 6.48261 35.4375 7.875 35.4375H34.125C35.5174 35.4375 36.8527 34.8844 37.8373 33.8998C38.8219 32.9152 39.375 31.5799 39.375 30.1875V17.0625ZM7.875 23.625C7.875 23.2769 8.01328 22.9431 8.25942 22.6969C8.50556 22.4508 8.8394 22.3125 9.1875 22.3125H19.6875C20.0356 22.3125 20.3694 22.4508 20.6156 22.6969C20.8617 22.9431 21 23.2769 21 23.625C21 23.9731 20.8617 24.3069 20.6156 24.5531C20.3694 24.7992 20.0356 24.9375 19.6875 24.9375H9.1875C8.8394 24.9375 8.50556 24.7992 8.25942 24.5531C8.01328 24.3069 7.875 23.9731 7.875 23.625ZM9.1875 27.5625C8.8394 27.5625 8.50556 27.7008 8.25942 27.9469C8.01328 28.1931 7.875 28.5269 7.875 28.875C7.875 29.2231 8.01328 29.5569 8.25942 29.8031C8.50556 30.0492 8.8394 30.1875 9.1875 30.1875H14.4375C14.7856 30.1875 15.1194 30.0492 15.3656 29.8031C15.6117 29.5569 15.75 29.2231 15.75 28.875C15.75 28.5269 15.6117 28.1931 15.3656 27.9469C15.1194 27.7008 14.7856 27.5625 14.4375 27.5625H9.1875Z"
        fill={fillColor}
      />
    </svg>
  );
};
