// required by is-parse-server
// https://github.com/invoice-simple/is-parse-server/blob/ca69de1513ddccb6760e9868abc91076ebd6fdde/cloud/validation-rules/invoice-rule.ts#L132
// valid ['cash', 'check', 'bank', 'creditCard', 'paypal', 'other']

type StringWithAutocomplete<T> = T | (string & Record<never, never>);
type InvoicePaymentValue = StringWithAutocomplete<
  'cash' | 'check' | 'bank' | 'creditCard' | 'debit' | 'mobilePaymentApp' | 'other'
>;
type PaymentOption = {
  value: InvoicePaymentValue;
  label: string;
  i18nKey: string;
};

export const paymentMethodOptions: PaymentOption[] = [
  {
    value: 'chooseMethod',
    label: 'Select Payment Method',
    i18nKey: 'paymentMethodLabelChooseMethod'
  },
  {
    value: 'bank',
    label: 'Bank Transfer',
    i18nKey: 'paymentMethodLabelBankTransfer'
  },
  {
    value: 'cash',
    label: 'Cash',
    i18nKey: 'paymentMethodLabelCash'
  },
  {
    value: 'check',
    label: 'Check',
    i18nKey: 'paymentMethodLabelCheck'
  },
  {
    value: 'creditCard',
    label: 'Credit',
    i18nKey: 'paymentMethodLabelCreditCard'
  },
  {
    value: 'debit',
    label: 'Debit',
    i18nKey: 'paymentMethodLabelDebit'
  },
  {
    value: 'mobilePaymentApp',
    label: 'Mobile Payment App',
    i18nKey: 'paymentMethodLabelMobilePaymentApp'
  },
  {
    value: 'other',
    label: 'Other',
    i18nKey: 'paymentMethodLabelOther'
  }
];

// this is to handle paypal and stripe, as we don't want them to show in the payment method dropdown, however we want to show them in the payment method label
export const paymentMethodOptionsPaymentList: PaymentOption[] = [
  ...paymentMethodOptions,
  {
    value: 'paypal',
    label: 'Paypal',
    i18nKey: 'paymentMethodLabelPaypal'
  },
  {
    value: 'stripe',
    label: 'Stripe',
    i18nKey: 'paymentMethodLabelStripe'
  }
];
