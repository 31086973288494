import { observer } from 'mobx-react';
import React from 'react';
import { Helmet } from 'react-helmet';
import ClientListModel from 'src/models/ClientListModel';
import ClientModel from 'src/models/ClientModel';
import './ClientEdit.css';
import { ConfirmDeleteClient } from './ConfirmDeleteButton';
import EmailInput from './EmailInput';
import SyncStatusIndicator from 'src/components/SyncStatusIndicator';
import { defineMessages, injectIntl } from 'src/i18n';
import { FormattedMessage } from 'react-intl';
import { ISIntl } from 'src/i18n/utils';

interface Prop {
  list: ClientListModel;
  onCancel: () => void;
  onLoad: () => void;
  onDeleted: (item: ClientModel) => void;
  intl?: ISIntl;
}

const messages = defineMessages({
  titleNew: {
    id: 'client.edit.title.new',
    defaultMessage: 'New Client'
  },
  titleEdit: {
    id: 'client.edit.title.edit',
    defaultMessage: 'Edit Client {clientName}'
  },
  formLabelName: {
    id: 'client.edit.form.label.name',
    defaultMessage: 'Name'
  },
  formLabelEmail: {
    id: 'client.edit.form.label.email',
    defaultMessage: 'Email'
  },
  formLabelAddress: {
    id: 'client.edit.form.label.address',
    defaultMessage: 'Address'
  },
  formLabelPhone: {
    id: 'client.edit.form.label.phone',
    defaultMessage: 'Phone'
  },
  formLabelMobile: {
    id: 'client.edit.form.label.mobile',
    defaultMessage: 'Mobile'
  },
  formLabelFax: {
    id: 'client.edit.form.label.fax',
    defaultMessage: 'Fax'
  },
  linkBack: {
    id: 'client.edit.links.back',
    defaultMessage: 'Close'
  },
  formPlaceholderName: {
    id: 'client.edit.form.placeholder.name',
    defaultMessage: 'Client Name'
  },
  formPlaceholderEmail: {
    id: 'client.edit.form.placeholder.email',
    defaultMessage: 'name@client.com'
  },
  formPlaceholderAddress1: {
    id: 'client.edit.form.placeholder.address.1',
    defaultMessage: '123 Happy Client Street'
  },
  formPlaceholderAddress2: {
    id: 'client.edit.form.placeholder.address.2',
    defaultMessage: 'City'
  },
  formPlaceholderAddress3: {
    id: 'client.edit.form.placeholder.address.3',
    defaultMessage: 'Country'
  },
  formPlaceholderPhone: {
    id: 'client.edit.form.placeholder.phone',
    defaultMessage: 'Client Phone'
  },
  formPlaceholderMobile: {
    id: 'client.edit.form.placeholder.mobile',
    defaultMessage: 'Client Mobile'
  },
  formPlaceholderFax: {
    id: 'client.edit.form.placeholder.fax',
    defaultMessage: 'Client Fax'
  }
});

@injectIntl
@observer
export default class ClientEdit extends React.Component<Prop, any> {
  componentDidMount() {
    this.props.onLoad();
  }
  toggleDeletedClient = (client: ClientModel) => {
    client.toggleDeleted();
    client.save().then(() => this.props.onDeleted(client));
  };
  render() {
    const f = this.props.intl!.formatMessage;
    const client = this.props.list.client;
    const title =
      client && client.isNew
        ? f(messages.titleNew)
        : f(messages.titleEdit, { clientName: client.name });

    const classString = client.delete
      ? 'client-edit client-delete'
      : client.deleted
      ? 'client-edit client-deleted'
      : 'client-edit';

    return (
      <div className="container">
        <div className={classString}>
          <Helmet>
            <title itemProp="name">{title}</title>
          </Helmet>

          <div className="client-edit-header page-header">
            <h1>{title}</h1>
            <SyncStatusIndicator isLoading={client.isLoading} isSaving={client.isSaving} />
          </div>

          <div className="client-edit-body">
            <form>
              <div className="form-group row row-client-email">
                <label htmlFor="client-name" className="col-md-2 col-form-label">
                  <FormattedMessage {...messages.formLabelName} />
                </label>
                <div className="col-md-10">
                  <input
                    type="text"
                    id="client-name"
                    placeholder={f(messages.formPlaceholderName)}
                    disabled={client.isLoading}
                    value={client.name}
                    onChange={(e) => (client.name = e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row row-client-email">
                <label htmlFor="client-email" className="col-md-2 col-form-label">
                  <FormattedMessage {...messages.formLabelEmail} />
                </label>
                <div className="col-md-10">
                  <EmailInput
                    key={client.remoteId}
                    name="client-email"
                    placeholder={f(messages.formPlaceholderEmail)}
                    disabled={client.isLoading}
                    value={client.email}
                    onValidEmail={(email) => {
                      client.email = email;
                    }}
                  />
                </div>
              </div>

              <div className="form-group row row-client-address">
                <label htmlFor="client-address1" className="col-md-2 col-form-label">
                  <FormattedMessage {...messages.formLabelAddress} />
                </label>
                <div className="col-md-10">
                  <input
                    type="text"
                    id="client-address1"
                    placeholder={f(messages.formPlaceholderAddress1)}
                    disabled={client.isLoading}
                    value={client.address1}
                    onChange={(e) => (client.address1 = e.target.value)}
                  />
                  <input
                    type="text"
                    id="client-address2"
                    placeholder={f(messages.formPlaceholderAddress2)}
                    disabled={client.isLoading}
                    value={client.address2}
                    onChange={(e) => (client.address2 = e.target.value)}
                  />
                  <input
                    type="text"
                    id="client-address3"
                    placeholder={f(messages.formPlaceholderAddress3)}
                    disabled={client.isLoading}
                    value={client.address3}
                    onChange={(e) => (client.address3 = e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row row-client-phone">
                <label htmlFor="client-phone" className="col-md-2 col-form-label">
                  <FormattedMessage {...messages.formLabelPhone} />
                </label>
                <div className="col-md-10">
                  <input
                    type="tel"
                    id="client-phone"
                    maxLength={200}
                    placeholder={f(messages.formPlaceholderPhone)}
                    disabled={client.isLoading}
                    value={client.phone}
                    onChange={(e) => (client.phone = e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row row-client-mobile">
                <label htmlFor="client-mobile" className="col-md-2 col-form-label">
                  <FormattedMessage {...messages.formLabelMobile} />
                </label>
                <div className="col-md-10">
                  <input
                    type="tel"
                    id="client-mobile"
                    maxLength={200}
                    placeholder={f(messages.formPlaceholderMobile)}
                    disabled={client.isLoading}
                    value={client.mobile}
                    onChange={(e) => (client.mobile = e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row row-client-fax">
                <label htmlFor="client-fax" className="col-md-2 col-form-label">
                  <FormattedMessage {...messages.formLabelFax} />
                </label>
                <div className="col-md-10">
                  <input
                    type="tel"
                    id="client-fax"
                    maxLength={200}
                    placeholder={f(messages.formPlaceholderFax)}
                    disabled={client.isLoading}
                    value={client.fax}
                    onChange={(e) => (client.fax = e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="client-edit-footer">
            <a className="btn btn-cancel" onClick={() => this.props.onCancel()}>
              <FormattedMessage {...messages.linkBack} />
            </a>
            {!client.isNew && (
              <ConfirmDeleteClient
                displayLabel={true}
                disabled={client.isLoading}
                pending={client.delete}
                confirmed={client.deleted}
                onPending={() => client.toggleDelete()}
                onConfirm={() => this.toggleDeletedClient(client)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
