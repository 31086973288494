import React from 'react';
import SettingInput from './SettingInput';
import SettingModel from 'src/models/SettingModel';
import { Option } from '../shared/RadioButton';
import { LockIcon } from '../Icons/LockIcon';

interface SettingFormGroup {
  setting: SettingModel | undefined;
  label?: string;
  wrapperClasses?: string;
  reverse?: boolean;
  defaultValue?: string;
  placeholder?: string;
  meta?: Record<string, unknown>;
  radioOptions?: Option[];
  showLockIcon?: boolean;
  helperText?: string;
}

const SettingFormGroup = ({
  wrapperClasses = '',
  setting,
  showLockIcon,
  label,
  helperText,
  ...rest
}: SettingFormGroup) => {
  if (!setting) {
    return null;
  }
  return (
    <div className={`setting-form-group form-group row ${wrapperClasses}`}>
      <label htmlFor={setting.key} className="col-md-4 col-form-label">
        <div className="d-flex align-items-center">
          {label}
          {showLockIcon && <LockIcon height="30px" width="30px" className="ml-2" />}
        </div>
      </label>
      {helperText ? (
        <div className="col-md-8 my-auto flex items-center">
          <SettingInput setting={setting} {...rest} />
          <p className="ml-3 text-md font-medium">{helperText}</p>
        </div>
      ) : (
        <div className="col-md-8 my-auto">
          <SettingInput setting={setting} {...rest} />
        </div>
      )}
    </div>
  );
};

export default SettingFormGroup;
