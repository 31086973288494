import React from 'react';
import { SVGProps } from 'react';

export const PhoneNotificationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_2163_4793)">
        <path
          d="M13 7.75C13.4142 7.75 13.75 7.41421 13.75 7C13.75 6.58579 13.4142 6.25 13 6.25V7.75ZM7 7.75H13V6.25H7V7.75Z"
          fill="#FF5721"
        />
        <path
          d="M3.75 32.25H22.75V36C22.75 37.7949 21.2949 39.25 19.5 39.25H7C5.20507 39.25 3.75 37.7949 3.75 36V32.25Z"
          stroke="#FF5721"
          strokeWidth="1.5"
        />
        <circle cx="13.25" cy="35.75" r="1.75" fill="#FF5721" />
        <path
          d="M13 14.5C13.4142 14.5 13.75 14.1642 13.75 13.75C13.75 13.3358 13.4142 13 13 13V14.5ZM4 14.5H13V13H4V14.5Z"
          fill="#FF5721"
        />
        <path d="M11 10.5H12.5" stroke="#FF5721" strokeWidth="2" strokeLinecap="round" />
        <path d="M3.75 31.5V9.5" stroke="#FF5721" strokeWidth="1.5" />
        <path d="M3.75 9.5C3.75 8.66667 4.45 7 7.25 7" stroke="#FF5721" strokeWidth="1.5" />
        <path
          d="M23.5 23.5C23.5 23.0858 23.1642 22.75 22.75 22.75C22.3358 22.75 22 23.0858 22 23.5H23.5ZM23.5 31.5V23.5H22V31.5H23.5Z"
          fill="#FF5721"
        />
        <path
          d="M29.375 16.625H35L33.4195 15.0445C32.9908 14.6158 32.75 14.0344 32.75 13.4283V9.875C32.75 6.93601 30.8717 4.43572 28.25 3.50909V3.125C28.25 1.88236 27.2426 0.875 26 0.875C24.7574 0.875 23.75 1.88236 23.75 3.125V3.50909C21.1283 4.43572 19.25 6.93601 19.25 9.875V13.4283C19.25 14.0344 19.0092 14.6158 18.5805 15.0445L17 16.625H22.625M29.375 16.625V17.75C29.375 19.614 27.864 21.125 26 21.125C24.136 21.125 22.625 19.614 22.625 17.75V16.625M29.375 16.625H22.625"
          stroke="#FF5721"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2163_4793">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
