import { computed, action, observable } from 'mobx';
import { defaultsStore } from 'src/stores/DefaultsStore';
import locationModel from 'src/models/LocationModel';
import environmentStore from 'src/stores/EnvironmentStore';
import { registerLocale } from 'react-datepicker';
import { Dictionary } from '@invoice-simple/invoice-viewer';

import {
  SUPPORTED_COUNTRY_CODES,
  normalizeLocaleCode,
  getEnabledLocaleOrDefault
} from 'src/i18n/enabledLocales';

import axios from 'axios';

const DEFAULT_LOCALE_CODE = 'en-US';

export class LocaleStore {
  @observable public datesLocale: any;
  @observable public viewerDictionary: Dictionary = defaultsStore.locale.viewerDictionary;
  @observable private messages = defaultsStore.locale.messages;
  @observable private locale: string;

  constructor() {
    this.formatMessage = this.formatMessage.bind(this);
    this.setCurrentLocaleCode(defaultsStore.locale.name);

    const pathLocale = locationModel.getLocale();
    if (environmentStore.isSnapshot() && pathLocale) {
      const language = pathLocale.split('-')[0];
      import(`../languages/${language}`).then((response) => {
        this.messages = response.default;
        this.setCurrentLocaleCode(pathLocale);
      });
    }
    this.loadDatesLocale(this.currentLocaleCountryCode);
  }

  importDatesLocale(language: string) {
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "locale" */ `../i18n/locales/${language}`
    ).then((locale) => {
      registerLocale(language, locale.default);
      this.datesLocale = locale.default;
    });
  }

  loadDatesLocale(localeCountryCode: string) {
    if (SUPPORTED_COUNTRY_CODES.indexOf(localeCountryCode) === -1) {
      localeCountryCode = 'en';
    }
    this.importDatesLocale(localeCountryCode);
  }

  @computed
  get currentLocaleCountryCode() {
    return this.currentLocale.split('-')[0];
  }

  @computed
  get currentLocale() {
    return this.locale;
  }

  @computed
  get currentMessages() {
    return this.messages;
  }

  // Due to https://github.com/yahoo/react-intl/issues/631
  // `formatMessage` cannot be used directly.
  // We need this method in order to translate terms on MobX models.
  // It does not have the default
  formatMessage(message) {
    return this.messages[message.id] || message.defaultMessage || message.id;
  }

  @action.bound
  setCurrentLocaleCode(localeCode: string = DEFAULT_LOCALE_CODE) {
    const candidate = normalizeLocaleCode(localeCode);

    this.locale = getEnabledLocaleOrDefault(candidate).locale;
  }

  // fetch does not work on is-www-proxy
  @action
  async loadLocale(locale: string = DEFAULT_LOCALE_CODE): Promise<void> {
    if (locale === this.currentLocale) {
      return;
    }
    const response = await axios({
      method: 'get',
      url: `/locale/${locale}`,
      responseType: 'json'
    });
    this.messages = response.data.messages;
    this.viewerDictionary = response.data.viewerDictionary;
    this.setCurrentLocaleCode(response.data.locale);
    this.loadDatesLocale(this.currentLocaleCountryCode);
  }
}

export default new LocaleStore();
