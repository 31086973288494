import React from 'react';

import { PassingFeesType } from '@invoice-simple/common';

import SettingModel from 'src/models/SettingModel';
import {
  LearnMore,
  SettingsPaypalBox,
  SettingsStripeBox
} from 'src/payments/components/IntegratedPayments';
import { ISPaymentsQRSettingFormGroup } from 'src/payments/components/PaymentQRCode';
import { SurchargeSetting } from 'src/payments/components/PaymentSetting/SurchargeSetting';
import { usePaypalContext, useStripeContext } from 'src/payments/contexts';
import { useIsPaypalVStripeVariantFlagEnabled } from 'src/payments/hooks/useIsPaypalVStripeVariantFlagEnabled';

export const SettingsPayments = ({
  label,
  alwaysAddSurchargeSetting,
  qrCodeEnabledSetting,
  feesTypeSetting
}: {
  label: string;
  alwaysAddSurchargeSetting: SettingModel | undefined;
  qrCodeEnabledSetting: SettingModel | undefined;
  feesTypeSetting: SettingModel | undefined;
}): JSX.Element => {
  const stripeContext = useStripeContext();
  const paypalContext = usePaypalContext();

  if (!paypalContext?.isPaypalEligible() && !stripeContext?.isStripeEligible) {
    return <></>;
  }

  const isPaypalVStripeVariantFlagEnabled = useIsPaypalVStripeVariantFlagEnabled();

  return (
    <div id="tailwind">
      <h4 data-testid="IntegratedPaymentsLabel" className="mb-2">
        {label}
      </h4>
      <LearnMore />
      <div className="grid grid-cols-1 gap-y-0.5 mt-3 mb-1.5">
        {isPaypalVStripeVariantFlagEnabled && (
          <SettingsStripeBox
            dictionary={{
              stripeAccountEmail: 'Email',
              onlinePayments: 'Online Payments',
              stripeMerchantId: 'Account ID',
              stripeCurrency: 'Currency'
            }}
          />
        )}
        {<SettingsPaypalBox />}
      </div>
      <SurchargeSetting
        transactionFeeslabel="Payment Processing Fees"
        feesTypeSetting={feesTypeSetting}
        options={[
          {
            id: 'passing-fees-markup',
            value: PassingFeesType.MARKUP,
            label: 'Mark up all items'
          },
          {
            id: 'passing-fees-surcharge',
            value: PassingFeesType.SURCHARGE,
            label: 'Add surcharge to invoice'
          }
        ]}
        alwaysAddSurchargeSetting={alwaysAddSurchargeSetting}
      />
      <ISPaymentsQRSettingFormGroup setting={qrCodeEnabledSetting} />
    </div>
  );
};
