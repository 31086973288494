import { DiscountTypes } from '@invoice-simple/common';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import NumericInput from 'react-numeric-input';

import { defineMessages, useISIntl } from 'src/i18n';
import { intlDiscountTypeOptions } from 'src/i18n/intlDiscountTypeOptions';
import InvoiceModel from 'src/models/InvoiceModel';
import { Selectv1Adapter } from '../Selectv1Adapter';

const messages = defineMessages({
  discount: {
    id: 'invoiceSettings.discount',
    defaultMessage: 'Discount'
  },
  discountType: {
    id: 'invoiceSettings.discount.type',
    defaultMessage: 'Type'
  },
  discountAmount: {
    id: 'invoiceSettings.discount.amount',
    defaultMessage: 'Amount'
  },
  discountPercent: {
    id: 'invoiceSettings.discount.percent',
    defaultMessage: 'Percent'
  }
});

interface DiscountSettingsProps {
  invoice: InvoiceModel;
  isLoading: boolean;
}

export const DiscountSettings: React.FC<DiscountSettingsProps> = observer(
  ({ isLoading, invoice }) => {
    const intl = useISIntl();

    useEffect(() => {}), [invoice];

    return (
      <>
        <h4>{intl.formatMessage(messages.discount)}</h4>
        <table className="invoice-detail-about">
          <tbody>
            <tr>
              <td>
                <label htmlFor="invoice-discount-type">
                  {intl.formatMessage(messages.discountType)}
                </label>
              </td>
              <td>
                <Selectv1Adapter
                  id="invoice-discount-type"
                  isDisabled={isLoading}
                  className="invoice-discount-type-input"
                  name="invoiceDiscountType"
                  isClearable={false}
                  value={invoice.setting.discountType || DiscountTypes.DISCOUNT_NONE}
                  options={intlDiscountTypeOptions(intl)}
                  onChange={(s) => {
                    invoice.setDiscountType(s.value);
                  }}
                />
              </td>
            </tr>

            {invoice.isDiscountFlat && (
              <tr>
                <td>
                  <label htmlFor="invoice-discount-amount">
                    {intl.formatMessage(messages.discountAmount)}
                  </label>
                </td>
                <td>
                  <NumericInput
                    id="invoice-discount-amount"
                    className="invoice-discount-amount-input"
                    name="invoiceDiscountAmount"
                    min={0}
                    step={1.0}
                    precision={2}
                    value={invoice.setting.discountAmount}
                    onChange={(v) => invoice.setDiscountAmount(v ?? 0)}
                    disabled={isLoading}
                  />
                </td>
              </tr>
            )}

            {invoice.isDiscountPercent && (
              <tr>
                <td>
                  <label htmlFor="invoice-discount-percent">
                    {intl.formatMessage(messages.discountPercent)}
                  </label>
                </td>
                <td>
                  <span>
                    <NumericInput
                      id="invoice-discount-percent"
                      className="invoice-discount-rate-input"
                      name="invoiceTaxRate"
                      value={invoice.setting.discountRate}
                      step={1.0}
                      precision={3}
                      min={0}
                      max={100}
                      format={(n) => {
                        return n + '%';
                      }}
                      onChange={(v = 0) => {
                        invoice.setDiscountRate(v ?? 0);
                      }}
                      disabled={isLoading}
                    />
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  }
);
