import React from 'react';
import ClientRow from './ClientRow';
import { PaginatedClientsResponse } from './usePaginatedClients';
import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';
import { SearchClientsResponse } from './useSearchClients';
import { useClientSummaries } from './useClientSummaries';

interface Props {
  onView: (id?: string) => void;
  onCreate: (e: React.MouseEvent<HTMLElement>) => void;
  data?: PaginatedClientsResponse | SearchClientsResponse;
  query: string;
  isError: boolean;
  hasSearchResults: boolean;
  onError: (error: { title?: string; body?: string }) => void;
}

const messages = defineMessages({
  rowEmpty: {
    id: 'client.list.row.empty',
    defaultMessage: 'You have no Clients'
  },
  rowEmptySearch: {
    id: 'client.list.row.empty.search',
    defaultMessage: 'No clients match this search.'
  },
  rowButtonNew: {
    id: 'client.list.row.button.new',
    defaultMessage: 'add your first Client today.'
  }
});

export const Clients: React.FunctionComponent<Props> = ({
  onView,
  onCreate,
  data,
  query,
  hasSearchResults
}) => {
  const intl = useISIntl();
  const { formatMessage: f } = intl;

  const clientRemoteIds =
    (data?.clients &&
      data?.clients.length &&
      data?.clients.map((client) => client.remoteId as string)) ||
    [];

  const { data: clientSummaries, isInitialLoading } = useClientSummaries({ clientRemoteIds });

  if (!data || (data && !data.clients.length)) {
    return (
      <tr className="invoice-row-empty">
        <td colSpan={6}>
          {!hasSearchResults && !!query ? (
            <span>{f(messages.rowEmptySearch)}</span>
          ) : (
            <>
              <span>{f(messages.rowEmpty)}</span>
              <span>
                ,
                <a className={`btn-link`} onClick={onCreate}>
                  {f(messages.rowButtonNew)}
                </a>
              </span>
            </>
          )}
        </td>
      </tr>
    );
  }

  return (
    <>
      {data.clients.map((client, index) => {
        const totalBilled =
          clientSummaries?.find((s) => s.clientRemoteId === (client.remoteId as string))
            ?.totalBilled ?? 0;

        const invoiceCount =
          clientSummaries?.find((s) => s.clientRemoteId === (client.remoteId as string))
            ?.invoiceCount ?? 0;
        return (
          <ClientRow
            client={client}
            key={client.objectId}
            onClick={onView}
            index={index}
            intl={intl}
            totalBilled={totalBilled}
            invoiceCount={invoiceCount}
            isLoading={isInitialLoading}
          />
        );
      })}
    </>
  );
};
