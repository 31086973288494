import { AxiosError } from 'axios';
import React from 'react';
import { observer } from 'mobx-react';
import Alert from './Alert';
import Checkout from './Checkout';
import { AppStore } from '../stores/AppStore';
import alertModel from '../models/AlertModel';
import './Subscription.css';
import { injectIntl, defineMessages } from 'src/i18n';
import { Cadence } from 'src/models/UserModel';
import { getIntlCurrency } from '@invoice-simple/common';
import { CurrencyDisplay } from './Currency';
import { loadStripe } from '@stripe/stripe-js';
import { redirectToStripeCheckout } from 'src/util/stripeCheckout';
import { ISIntl } from 'src/i18n/utils';
import { defaultsStore } from 'src/stores/DefaultsStore';

interface Prop {
  store: AppStore;
  onCancel: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  intl: ISIntl;
}

const messages = defineMessages({
  title: {
    id: 'subscription.free.title',
    defaultMessage: 'Upgrade Now'
  },
  subtitleBelowLimit: {
    id: 'subscription.free.belowlimit',
    defaultMessage:
      "you've used {docsCount} of your {freeQuota} free invoices & estimates, upgrade now for unlimited."
  },
  subtitleReachedLimit: {
    id: 'subscription.free.reachedLimit',
    defaultMessage:
      "you've used your {freeQuota} free invoices & estimates, upgrade now for unlimited."
  },
  month: {
    id: 'subscription.free.month',
    defaultMessage: 'month'
  },
  unlimitedInvoices: {
    id: 'subscription.free.benefits.invoices',
    defaultMessage: 'unlimited invoices'
  },
  unlimitedEstimates: {
    id: 'subscription.free.benefits.estimates',
    defaultMessage: 'unlimited estimates'
  },
  cancelAnytime: {
    id: 'subscription.free.benefits.cancel',
    defaultMessage: 'cancel anytime'
  },
  annualSublabel: {
    id: 'subscription.free.annual',
    defaultMessage: '({amount} billed annually)'
  },
  annualSubscriptionTitle: {
    id: 'subscription.title.annual',
    defaultMessage: 'Annual Subscription'
  },
  monthlySubscriptionTitle: {
    id: 'subscription.title.monthly',
    defaultMessage: 'Monthly Subscription'
  },
  monthlySubscriptionCouponTitle: {
    id: 'subscription.coupon.monthly.title',
    defaultMessage: '1 month of Invoice Simple Web for only'
  },
  monthlySubscriptionCouponDesc: {
    id: 'subscription.coupon.monthly.desc',
    defaultMessage: 'Then {price}{cadence}.'
  },
  cancel: {
    id: 'subscription.cancel',
    defaultMessage: 'continue in read-only mode'
  },
  perYear: {
    id: 'per.year',
    defaultMessage: '/yr'
  },
  perMonth: {
    id: 'per.month',
    defaultMessage: '/mo'
  }
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

@injectIntl
@observer
export default class SubscriptionFree extends React.Component<Prop, any> {
  componentDidMount() {
    const user = this.props.store.user;
    user.trackAppEventViaApi('subscription-view-free', this.props.store.upgradeEventData);
    user.trackAppEventViaApi('subscription-paywall', this.props.store.upgradeEventData);

    const monthlyPrice = user.getGeoSubAmount();
    const lastPage = '/invoices';
    this.openStripeCheckout(Cadence.MONTHLY, monthlyPrice, lastPage);
  }

  state = {
    checkoutSessionLoading: false
  };

  render() {
    const store = this.props.store;
    const user = store.user;
    const price = user.geoSubAmount;
    const currencyCode = user.geoSubCurrencyCode;
    const { ft, fta, formatMessage } = this.props.intl;

    const checkoutButtons: any = [];
    let featuresStyle;
    if (user.isLegacySubscriber) {
      checkoutButtons.push(this.getCheckoutButton(Cadence.MONTHLY, price));
    } else {
      const monthlyPrice = user.getGeoSubAmount();
      checkoutButtons.push(this.getCheckoutButton(Cadence.MONTHLY, monthlyPrice));

      featuresStyle = {
        borderTop: 'none'
      };
    }

    const subTitle = user.isWithinFreeQuota
      ? ft(messages.subtitleBelowLimit, {
          docsCount: user.docsCount,
          freeQuota: user.freeQuota
        })
      : ft(messages.subtitleReachedLimit, {
          freeQuota: user.freeQuota
        });
    return (
      <div className="subscription-free">
        <div className="focus-container">
          <div className="focus-header">
            <a className="brand" href="/" onClick={(e) => this.props.onCancel(e)}>
              <img
                className="brand-logo"
                src="/images/logo.png"
                role="presentation"
                alt="invoice simple logo"
                data-pin-nopin="true"
              />
            </a>
            <h2>{ft(messages.title)}</h2>
            {user.docsCount ? <h3>{subTitle}</h3> : null}
          </div>
          <div className="focus-body">
            <Alert />
            {user.isLegacySubscriber && (
              <table>
                <tbody>
                  <tr>
                    <td>
                      <CurrencyDisplay
                        value={price / 100}
                        currencyCode={currencyCode}
                        suffix={`/${formatMessage(messages.month)}`}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <ul style={featuresStyle}>
              <li>{fta(messages.unlimitedInvoices)}</li>
              <li>{fta(messages.unlimitedEstimates)}</li>
              <li>{fta(messages.cancelAnytime)}</li>
            </ul>
            {checkoutButtons}
          </div>

          {!this.state.checkoutSessionLoading && (
            <div className="focus-footer">
              <a className="btn btn-dark" href="/invoices" onClick={(e) => this.props.onCancel(e)}>
                {ft(messages.cancel)}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }

  private async openStripeCheckout(cadence: Cadence, price: number, cancelUrl?: string) {
    this.setState({ checkoutSessionLoading: true });
    const store = this.props.store;
    const user = store.user;
    const stripe = await stripePromise;

    if (!stripe) {
      this.setState({ checkoutSessionLoading: false });
      return;
    }

    try {
      const { formatMessage } = this.props.intl;
      const isMonthlySub = cadence === Cadence.MONTHLY;
      const orderSku = store.user.getGeoSubOrderSku();

      const isCouponApplied = orderSku.includes('coupon');
      const couponSku = isCouponApplied ? user.getCouponSku() : undefined;

      const productTitle: string = isCouponApplied
        ? formatMessage(messages.monthlySubscriptionCouponTitle)
        : isMonthlySub
        ? formatMessage(messages.monthlySubscriptionTitle)
        : formatMessage(messages.annualSubscriptionTitle);

      const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      const description = isCouponApplied
        ? formatMessage(messages.monthlySubscriptionCouponDesc, {
            price: getIntlCurrency({
              number: user.getGeoSubAmount({ isNextMonthPrice: true }) / 100,
              currencyCode: store.user.geoSubCurrencyCode,
              localeCode: defaultsStore.locale.name
            }),
            cadence: formatMessage(isMonthlySub ? messages.perMonth : messages.perYear)
          })
        : user.docsCount !== user.freeQuota
        ? formatMessage(messages.subtitleBelowLimit, {
            docsCount: user.docsCount,
            freeQuota: user.freeQuota
          })
        : formatMessage(messages.subtitleReachedLimit, {
            freeQuota: user.freeQuota
          });

      await redirectToStripeCheckout({
        store,
        productTitle,
        price,
        orderSku,
        cancelUrl,
        couponSku,
        description: capitalizeFirstLetter(description)
      });
    } catch (err) {
      const error = err as AxiosError<{ title?: string; message?: string }>;

      this.setState({ checkoutSessionLoading: false });
      if (error.response) {
        alertModel.setAlert('danger', error.response.data?.title, error.response.data?.message);
      } else {
        alertModel.setAlert('danger', 'Unable to create checkout session', error.message);
      }
    }
  }

  private getCheckoutButton(cadence: Cadence, price: number) {
    const store = this.props.store;
    const currencyCode = store.user.geoSubCurrencyCode;
    const localeCode = store.user.countryCode;
    let label, subLabel;
    if (!store.user.isLegacySubscriber) {
      label = this.getCheckoutLabel({ cadence, price, currencyCode, localeCode });
      subLabel = this.getCheckoutSubLabel({ cadence, price, currencyCode, localeCode });
    }

    const options = {
      cadence: cadence.toLowerCase(),
      key: `checkout-${cadence.toLowerCase()}`,
      onOpen: () => {
        const cancelUrl = '/invoices';
        store.subCadence = cadence;
        this.openStripeCheckout(cadence, price, cancelUrl);
      },
      loading: this.state.checkoutSessionLoading,
      label,
      subLabel
    };
    return <Checkout {...options} />;
  }

  private getCheckoutLabel({
    cadence,
    price,
    currencyCode,
    localeCode
  }: {
    cadence: Cadence;
    price: number;
    currencyCode: string;
    localeCode: string | undefined;
  }) {
    if (cadence === Cadence.ANNUAL) {
      price = Math.floor(price / 12);
    }
    const { formatMessage } = this.props.intl;
    return `${getIntlCurrency({
      number: price / 100,
      currencyCode,
      localeCode
    })}/${formatMessage(messages.month)}`;
  }

  private getCheckoutSubLabel({
    cadence,
    price,
    currencyCode,
    localeCode
  }: {
    cadence: Cadence;
    price: number;
    currencyCode: string;
    localeCode: string | undefined;
  }) {
    if (cadence === Cadence.ANNUAL) {
      const { formatMessage } = this.props.intl;
      return `${formatMessage(messages.annualSublabel, {
        amount: getIntlCurrency({
          number: price / 100,
          currencyCode,
          localeCode
        })
      })}`;
    }
    return;
  }
}
