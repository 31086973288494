import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type BannerType = 'warning' | 'danger';

export interface BannerProps {
  type: BannerType;
  title?: string;
  body: React.ReactNode;
  isClosable: boolean;
  icon?: React.ReactNode;
}

const textStyles = {
  warning: 'text-yellow-800',
  danger: 'text-red-800'
};

const containerStyles = {
  warning: `bg-yellow-50 ${textStyles.warning}`,
  danger: `bg-red-50 ${textStyles.danger}`
};

export const Banner = ({ type, title, body, isClosable, icon }: BannerProps) => {
  const [showBanner, setShowBanner] = useState(true);

  const containerStyle = containerStyles[type];
  const textStyle = textStyles[type];

  if (!showBanner) return null;

  return (
    <div id="tailwind">
      <div
        data-testid="banner"
        className={`flex items-center justify-between p-[12px] rounded-[4px] text-xl mb-[18px] ${containerStyle}`}>
        <div className="flex items-center">
          {icon}
          <div>
            {!!title && (
              <h3 data-testid="title" className={`font-bold text-xl ${textStyle}`}>
                {title}
              </h3>
            )}
            <p data-testid="body" className="text-xl">
              {body}
            </p>
          </div>
        </div>
        {isClosable && (
          <FontAwesomeIcon
            data-testid="x-icon"
            icon="times"
            size="lg"
            role="button"
            onClick={() => {
              setShowBanner(false);
            }}
            className="cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};
