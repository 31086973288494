import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InvoiceItemRow from './InvoiceItemRow';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { injectIntl, defineMessages } from 'src/i18n';
import { getTaxLabel } from 'src/models/TaxLabelModel';
import './InvoiceItemList.css';
import { ISIntl } from 'src/i18n/utils';

export const messages = defineMessages({
  description: {
    id: 'invoiceItemsList.description',
    defaultMessage: 'description'
  },
  price: {
    id: 'invoiceItemsList.price',
    defaultMessage: 'price'
  },
  quantity: {
    id: 'invoiceItemsList.quantity',
    defaultMessage: 'qty'
  },
  amount: {
    id: 'invoiceItemsList.amount',
    defaultMessage: 'amount'
  },
  addItem: {
    id: 'invoiceItemList.addItem',
    defaultMessage: 'add item'
  }
});

interface Props {
  intl?: ISIntl;
  store?: AppStore;
}

@injectIntl
@injectStore
export default class InvoiceItemList extends React.Component<Props, {}> {
  handleItemAdd = () => {
    this.props.store!.doc.addItem();
  };
  render() {
    const store = this.props.store!;
    const invoice = store.doc;
    const user = store.user;
    const isLoading = store.isLoading;
    const btnClass = isLoading ? 'btn disabled' : 'btn';
    const { fta } = this.props.intl!;

    const items = invoice.visibleItems.map((item, i, allItems) => {
      return (
        <InvoiceItemRow
          item={item}
          suggest={user.itemSuggest}
          key={item.remoteId}
          index={i}
          isLoading={isLoading}
          isLast={i === allItems.length - 1}
          isMarkingUpPaymentFees={invoice.isMarkingUpPaymentFees}
        />
      );
    });
    return (
      <div className="invoice-item-list content-block">
        <table className={`table invoice-table  invoice-table-edit`}>
          <thead className="thead">
            <tr>
              <th className="invoice-detail-margin">&nbsp;</th>
              <th className="invoice-detail-summary">{fta(messages.description)}</th>
              <th className="invoice-detail-rate">{fta(messages.price)}</th>
              <th className="invoice-detail-quantity">{fta(messages.quantity)}</th>
              <th className="invoice-detail-total">{fta(messages.amount)}</th>

              {!invoice.isTaxNone && (
                <th className="invoice-detail-tax">{getTaxLabel(invoice, fta)}</th>
              )}
            </tr>
          </thead>

          <tbody className="invoice-items">
            {items}
            <tr className="item-row item-row-n">
              <td className="item-row-actions" colSpan={6}>
                <button
                  id="invoice-item-add"
                  title={fta(messages.addItem)}
                  className={`${btnClass} btn-prime`}
                  onClick={this.handleItemAdd}
                  disabled={isLoading}>
                  <FontAwesomeIcon icon="plus" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
