import React from 'react';
import { defineMessages } from 'src/i18n';
import env from 'src/stores/EnvironmentStore';

const baseUrl = `https://${env.appProxyHost}`;

export const messages = defineMessages({
  title: {
    id: 'signup.title',
    defaultMessage: 'Create an Account'
  },
  description: {
    id: 'signup.description',
    defaultMessage: 'Create your Invoice Simple account, and view your invoices on any device.'
  },
  header: {
    id: 'signup.header',
    defaultMessage: 'Sign Up'
  },
  subHeader: {
    id: 'signup.body',
    defaultMessage: "You're a few seconds away from your FREE Invoice Simple account!"
  },
  formLabelFirstName: {
    id: 'signup.form.labels.firstName',
    defaultMessage: 'First Name'
  },
  formLabelLastName: {
    id: 'signup.form.labels.lastName',
    defaultMessage: 'Last Name'
  },
  formLabelEmail: {
    id: 'signup.form.labels.email',
    defaultMessage: 'Email'
  },
  formLabelPassword: {
    id: 'signup.form.labels.password',
    defaultMessage: 'Password'
  },
  formPlaceholderFirstName: {
    id: 'signup.form.placeholders.firstName',
    defaultMessage: 'Johnny'
  },
  formPlaceholderLastName: {
    id: 'signup.form.placeholders.lastName',
    defaultMessage: 'Appleseed'
  },
  formPlaceholderEmail: {
    id: 'signup.form.placeholders.email',
    defaultMessage: 'name@example.com'
  },
  formButtonLabel: {
    id: 'signup.form.button.label',
    defaultMessage: 'Sign up'
  },
  formButtonLabelPending: {
    id: 'signup.form.button.label.pending',
    defaultMessage: 'Creating Account...'
  },
  formButtonLabelLoading: {
    id: 'signup.form.button.label.loading',
    defaultMessage: 'Loading...'
  },
  cancelButtonLabel: {
    id: 'signup.cancel.button.label',
    defaultMessage: 'Cancel'
  },
  loginLinkQuestion: {
    id: 'signup.link.login.question',
    defaultMessage: 'Already have an account?'
  },
  loginLinkLabel: {
    id: 'signup.link.login.label',
    defaultMessage: 'Login'
  },
  signupTermsAgreement: {
    id: 'signup.terms.agreement',
    defaultMessage:
      'By signing up, you agree to the <termsUrl>terms of use</termsUrl> & <privacyUrl>privacy policy</privacyUrl>.',
    values: {
      termsUrl: (chunks): JSX.Element => <a href={`${baseUrl}/terms`}>{chunks}</a>,
      privacyUrl: (chunks) => <a href={`${baseUrl}/privacy`}>{chunks}</a>
    }
  },
  consentVisible: {
    id: 'signup.consent.visible',
    defaultMessage: 'I want to receive emails from Invoice Simple and its Affiliates about'
  },
  consentHidden: {
    id: 'signup.consent.hidden',
    defaultMessage:
      'their products, services, news, events, and promotions. Read our <privacyUrl>Privacy Policy</privacyUrl>.',
    values: {
      privacyUrl: (chunks) => (
        <a href={`${baseUrl}/privacy`} className="showmore-link">
          {chunks}
        </a>
      )
    }
  },
  consentShowMore: {
    id: 'signup.consent.showmore',
    defaultMessage: 'show more'
  }
});
