import React from 'react';
import filter from 'lodash/filter';
import { observer } from 'mobx-react';
import { DocTypes } from '@invoice-simple/common';

interface Prop {
  className: string;
  objectId?: string;
  accountId?: string;
  sessionToken?: string;
  docType?: 0 | 1;
  installationId?: string;
  children?: React.ReactNode;
}

@observer
export default class ParseAdminLink extends React.Component<Prop> {
  render() {
    const appName = 'Invoice Simple Staging';
    const className = this.props.className
      .replace('User', '_User')
      .replace('Session', '_Session')
      .replace('Installation', '_Installation');

    const objectFilter = this.props.objectId && {
      field: 'objectId',
      constraint: 'eq',
      compareTo: this.props.objectId
    };
    const docFilter = (this.props.docType === DocTypes.DOCTYPE_ESTIMATE ||
      this.props.docType === DocTypes.DOCTYPE_INVOICE) && {
      field: 'docType',
      constraint: 'eq',
      compareTo: this.props.docType
    };
    const accountFilter = this.props.accountId && {
      field: 'account',
      constraint: 'eq',
      compareTo: { __type: 'Pointer', className: 'Account', objectId: this.props.accountId }
    };
    const sessionTokenFilter = this.props.sessionToken && {
      field: 'sessionToken',
      constraint: 'eq',
      compareTo: this.props.sessionToken
    };
    const installationIdFilter = this.props.installationId && {
      field: 'installationId',
      constraint: 'eq',
      compareTo: this.props.installationId
    };
    const filters = JSON.stringify(
      filter([objectFilter, docFilter, accountFilter, sessionTokenFilter, installationIdFilter])
    );
    const url = encodeURI(
      `https://parse-dashboard.getinvoicesimple.com/apps/${appName}/browser/${className}?filters=${filters}`
    );
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {this.props.children || 'Parse'}
      </a>
    );
  }
}
