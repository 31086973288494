import { PassingFeesType, SettingKeys } from '@invoice-simple/common';
import React, { useEffect } from 'react';

import { PaymentModalWrapper } from '../PaypalModalWrapper';
import { defineMessages, useISIntl } from 'src/i18n';
import { XIcon } from '../../PaymentBasicElements/XIcon';
import './PaymentsSurchargePromoModal.scss';
import { useSurchargePromoModal } from './useSurchargePromoModal';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';

const messages = defineMessages({
  title: {
    id: 'surchargePromo.modal.title',
    defaultMessage: 'Save Money on Payment Processing Fees'
  },
  introduction: {
    id: 'surchargePromo.modal.introduction',
    defaultMessage: 'Choose from two methods to add payment processing fees to your invoices:'
  },
  markUp: {
    id: 'surchargePromo.modal.markUp',
    defaultMessage: 'Mark up'
  },
  markUpDetails: {
    id: 'surchargePromo.modal.markUpDetails',
    defaultMessage: 'Distribute the cost of payment processing fees across all invoice items.'
  },
  surcharge: {
    id: 'surchargePromo.modal.surcharge',
    defaultMessage: 'Surcharge'
  },
  surchargeDetails: {
    id: 'surchargePromo.modal.surchargeDetails',
    defaultMessage:
      'Automatically add a surcharge only if a customer pays with Invoice Simple Payments.'
  },
  useMarkup: {
    id: 'surchargePromo.modal.useMarkup',
    defaultMessage: 'Use Markup'
  },
  useSurcharge: {
    id: 'surchargePromo.modal.useSurcharge',
    defaultMessage: 'Use Surcharge'
  }
});

enum PaymentsSurchargePromoModalActions {
  CLICKED = 'clicked',
  VIEWED = 'viewed'
}
const feature = 'payments-surcharge-promo-modal-prompt';

export const PaymentsSurchargePromoModalComponent = ({ store }: { store: AppStore }) => {
  const { ft } = useISIntl();
  const surchargeSetting = store?.user?.settingList.getSetting(SettingKeys.PaymentFeesType);

  const {
    shouldShowSurchargePromoModal,
    showSurchargePromoModal,
    hideSurchargePromoModal,
    isEligibleForSurchargePromoModal
  } = useSurchargePromoModal();

  useEffect(() => {
    if (
      surchargeSetting?.valNum !== PassingFeesType.SURCHARGE &&
      isEligibleForSurchargePromoModal
    ) {
      showSurchargePromoModal();
      store?.user?.events.trackModalView({
        action: PaymentsSurchargePromoModalActions.VIEWED,
        feature
      });
    }
  }, [isEligibleForSurchargePromoModal]);

  const onClickXIcon = () => {
    hideSurchargePromoModal();
    store?.user?.events.trackButtonClick({
      text: 'X',
      action: PaymentsSurchargePromoModalActions.CLICKED,
      feature
    });
  };

  const onClickUseMarkup = () => {
    hideSurchargePromoModal();
    store?.user?.events.trackButtonClick({
      text: 'useMarkup',
      action: PaymentsSurchargePromoModalActions.CLICKED,
      feature
    });
  };
  const onClickUseSurcharge = () => {
    hideSurchargePromoModal();
    store?.user?.events.trackButtonClick({
      text: 'useSurcharge',
      action: PaymentsSurchargePromoModalActions.CLICKED,
      feature
    });
    store?.user?.events.trackAction('surcharge-turned-on');
    surchargeSetting?.setValue(PassingFeesType.SURCHARGE);
  };

  const onClickOutsideModal = () => {
    store?.user?.events.trackButtonClick({
      text: 'Outside modal',
      action: PaymentsSurchargePromoModalActions.CLICKED,
      feature
    });
  };

  return (
    <PaymentModalWrapper
      isOpen={shouldShowSurchargePromoModal}
      onRequestClose={onClickOutsideModal}>
      <div className="surcharge-promo-modal-content" data-testid="surcharge-promo-modal-content">
        <header>
          <h3>{ft(messages.title)}</h3>
          <button onClick={onClickXIcon} data-testid="surcharge-promo-modal-x-button" type="button">
            <XIcon />
          </button>
        </header>
        <main>
          <section>
            <img className="surcharge-promo-img" src="/images/surcharge-promo.png" />
          </section>

          <section className="surcharge-promo-pf-choices">
            <div> {ft(messages.introduction)}</div>
            <p>
              <b>{ft(messages.markUp)}</b>
              {`: ${ft(messages.markUpDetails)}`}
            </p>
            <p>
              <b>{ft(messages.surcharge)}</b>
              {`: ${ft(messages.surchargeDetails)}`}
            </p>
          </section>
        </main>

        <footer className="modal-content-footer" id="tailwind">
          <button
            data-testid="surcharge-promo-modal-reject-button"
            onClick={onClickUseMarkup}
            type="button">
            {ft(messages.useMarkup)}
          </button>
          <button
            data-testid="surcharge-promo-modal-submit-button"
            className="flex justify-center items-center"
            onClick={onClickUseSurcharge}
            style={{ minWidth: '97px', maxHeight: '30px' }}>
            {ft(messages.useSurcharge)}
          </button>
        </footer>
      </div>
    </PaymentModalWrapper>
  );
};

export const PaymentsSurchargePromoModal = injectStore(PaymentsSurchargePromoModalComponent);
