import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { injectIntl, defineMessages } from 'src/i18n';
import { compose } from 'src/util/functions';
import injectStore from 'src/util/injectStore';
import currentDocStore from '../stores/CurrentDocumentStore';
import { ISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';

const messages = defineMessages({
  close: {
    id: 'invoice.invoiceControls.close',
    defaultMessage: 'Close {docType}'
  }
});

interface Prop extends React.HTMLProps<HTMLButtonElement> {
  store: AppStore;
  intl: ISIntl;
}

@observer
class ConfirmInvoiceDeleteButton extends React.Component<Prop, any> {
  render() {
    const intl = this.props.intl;
    const docType = intl.getTitleizedDocumentType(currentDocStore.currentDoc.docType);
    const classNames = cn('btn', 'btn-close', this.props.className);
    return (
      <button className={classNames} onClick={() => this.props.store.docListNav()}>
        {intl.ft(messages.close, { docType })}
      </button>
    );
  }
}

export default compose(injectIntl, injectStore, observer)(ConfirmInvoiceDeleteButton);
