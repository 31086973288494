import React, { useEffect } from 'react';
import { trackEvent } from 'src/analytics/controller';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import { Loading } from '../Loading';

export const RequestReviewRedirect = () => {
  const reviewLink = getURLQueryParam(URLQueryParamKeys.REVIEW_LINK);
  const invoiceId = getURLQueryParam(URLQueryParamKeys.INVOICE_ID);

  useEffect(() => {
    if (reviewLink) {
      trackEvent('review-link-clicked', {
        reviewLink,
        invoiceId
      });
      window.location.assign(reviewLink);
    }
  }, []);

  return <Loading />;
};
