import React, { FC, ReactNode } from 'react';
import { XIcon } from '../PaymentBasicElements';

export const TopContainer: FC<{
  onClickXIcon: () => void;
  headerMessage: string;
  dataTestId?: string;
}> = ({ onClickXIcon, headerMessage, dataTestId }) => {
  return (
    <header className={'flex items-center justify-between pt-2'}>
      <h3
        className={
          'font-medium text-3xl flex-1 capitalize px-9 py-2.5 text-zinc-800 text-left [@media(max-width:400px)]:px-4'
        }>
        {headerMessage}
      </h3>
      <button
        className={
          'bg-inherit !border-none p-4 h-full hover:bg-neutral-100 hover:transition-all hover:duration-200 hover:ease-in-out hover:!rounded-lg'
        }
        onClick={onClickXIcon}
        data-testid={dataTestId}
        type="button">
        <XIcon />
      </button>
    </header>
  );
};

export const Illustration: FC<{ url: string; width?: number; height?: number }> = ({
  url,
  width,
  height
}) => {
  return (
    <section className={'w-full'}>
      <img
        style={{ border: 'none', borderTop: '1px solid' }}
        className="w-full h-full object-contain border-solid !border-gray-200"
        src={url}
        width={width}
        height={height}
      />
    </section>
  );
};

export const ValueProps: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <section
      className="w-full leading-8 p-8 text-2xl [@media(max-width:400px)]:overflow-y-scroll [@media(max-width:400px)]:h-48"
      data-testid="new-features">
      {children}
    </section>
  );
};

export const ButtonContainer: FC<{
  handleClick: () => void;
  buttonMessage: string;
  dataTestId?: string;
}> = ({ handleClick, buttonMessage, dataTestId }) => {
  return (
    <footer className="flex justify-end bg-gray-100 px-6 py-4 flex-row">
      <button
        data-testid={dataTestId}
        type="button"
        style={{
          border: 'none'
        }}
        className="px-4 py-3 outline outline-1 outline-neutral-400 font-medium text-xl !rounded-lg transition-all duration-200 ease-in-out capitalize flex justify-center items-center bg-neutral-600 text-white"
        onClick={handleClick}>
        {buttonMessage}
      </button>
    </footer>
  );
};
