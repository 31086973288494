import omitBy from 'lodash/omitBy';

/**
 * shallow object diff
 *
 * returns key/value which changed
 */

export default function DiffObject(oldObj: {}, newObj: {}) {
  return omitBy(newObj, (v, k) => {
    return v === oldObj[k];
  });
}
