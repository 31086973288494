import { usePaypalContext } from '../contexts/PaypalAccountContext';
import { useStripeContext } from '../contexts/StripeAccountContext';
import { hasCurrencyMismatch } from '../utils';

interface PaymentsUsability {
  isPaypalUsable: boolean;
  isStripeUsable: boolean;
}

export const usePaymentsUsability = (currentCurrency?: string): PaymentsUsability => {
  const paypalAccountContext = usePaypalContext();
  const stripeAccountContext = useStripeContext();

  const isPaypalUsable = !!(
    paypalAccountContext &&
    paypalAccountContext.isPaypalAccepting() &&
    paypalAccountContext.isPaypalEnabled() &&
    !hasCurrencyMismatch(paypalAccountContext?.getPaypalAccountCurrency(), currentCurrency)
  );

  const isStripeUsable = !!(
    stripeAccountContext &&
    stripeAccountContext?.isStripeAcceptingAndEnabled &&
    !hasCurrencyMismatch(stripeAccountContext?.stripePrimaryCurrency, currentCurrency)
  );

  return {
    isPaypalUsable,
    isStripeUsable
  };
};
