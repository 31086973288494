import React, { useContext, useState } from 'react';
import { differenceInHours } from 'date-fns';
import { PaypalAccountContext } from 'src/payments/contexts/PaypalAccountContext';
import { useIsPaypalRedesignFlagEnabled } from 'src/payments/hooks/useIsPaypalRedesignFlagEnabled';
import { useIsPaymentsEnabled } from 'src/payments/hooks/useIsPaymentsEnabled';

const localStorageKey = 'payment-modal-last-shown';

interface UsePaymentPromoModal {
  shouldShowPaymentPromoModal: boolean;
  showPaymentPromoModal: () => void;
  hidePaymentPromoModal: () => void;
  isEligibleForPaymentPromoModal: () => boolean;
}

export const usePaymentPromoModal = (initialState: boolean = false): UsePaymentPromoModal => {
  const isPaypalRedesignFlagEnabled = useIsPaypalRedesignFlagEnabled();
  const isPaymentsEnabled = useIsPaymentsEnabled();
  const [shouldShowPaymentPromoModal, setShouldShowPaymentPromoModal] = useState<boolean>(
    !isPaymentsEnabled ? false : isPaypalRedesignFlagEnabled ? false : initialState
  );
  const paypalAccountContext = useContext(PaypalAccountContext);

  function getPromoModalLastShown() {
    return localStorage.getItem(localStorageKey);
  }

  function setPromoModalLastShown() {
    localStorage.setItem(localStorageKey, new Date().toString());
  }

  function isEligibleForPaymentPromoModal(): boolean {
    if (!isPaymentsEnabled) {
      return false;
    }
    if (
      isPaypalRedesignFlagEnabled ||
      !paypalAccountContext?.isPaypalEligible() ||
      paypalAccountContext?.isPaypalAccepting()
    ) {
      return false;
    }
    const modalLastShown = getPromoModalLastShown();
    if (!modalLastShown) {
      return true;
    }
    const hoursSinceLastShown = differenceInHours(new Date(), new Date(Date.parse(modalLastShown)));
    return hoursSinceLastShown >= 12;
  }

  function showPaymentPromoModal() {
    setPromoModalLastShown();
    return setShouldShowPaymentPromoModal(true);
  }

  function hidePaymentPromoModal() {
    setShouldShowPaymentPromoModal(false);
  }

  return {
    shouldShowPaymentPromoModal,
    showPaymentPromoModal,
    hidePaymentPromoModal,
    isEligibleForPaymentPromoModal
  };
};

export const withPaymentPromoModalHook = (Component) => {
  return function WrappedComponent(props) {
    const { shouldShowPaymentPromoModal, showPaymentPromoModal, isEligibleForPaymentPromoModal } =
      usePaymentPromoModal(false);
    return (
      <Component
        {...props}
        shouldShowPaymentPromoModal={shouldShowPaymentPromoModal}
        showPaymentPromoModal={showPaymentPromoModal}
        isEligibleForPaymentPromoModal={isEligibleForPaymentPromoModal}
      />
    );
  };
};
