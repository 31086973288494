import React from 'react';
import { DangerIcon, InfoIcon, SuccessIcon, XIcon } from './Icons';

type AlertVariant = 'success' | 'warning' | 'info' | 'danger';

interface Props {
  variant: AlertVariant;
  isClosable?: boolean;
  onClose?: () => void;
  title: string;
  description: string;
  isOpen?: boolean;
  dataTestId?: string;
}

export const Alert = (props: Props) => {
  const colorMap = {
    success: '#00B87C',
    warning: '#FFC107',
    info: '#2196F3',
    danger: '#F44336'
  };

  const textColor = colorMap[props.variant];

  if (!props.isOpen) {
    return null;
  }

  return (
    <article id="tailwind" role="alert" data-testid={props.dataTestId}>
      <section className="flex p-[8px] bg-[#F9FAFB] rounded-[12px] mb-4">
        <div className="p-[12px]">
          <IconComponent variant={props.variant} />
        </div>
        <div className="flex flex-col relative w-full">
          <h2
            className={`m-0 text-[14px] py-[8px] font-roboto font-semibold`}
            style={{ color: textColor }}>
            {props.title}
          </h2>
          <p className={`text-[#555555] text-[12px] pb-[8px] font-normal`}>{props.description}</p>

          <button
            onClick={props.onClose}
            className="m-0 border-none absolute top-0 right-0 p-3 hover:bg-[#F9FAFB] focus:outline-none"
            style={{ background: 'none' }}>
            <XIcon />
          </button>
        </div>
      </section>
    </article>
  );
};

const IconComponent = ({ variant }: { variant: AlertVariant }) => {
  const variantIcons: Record<AlertVariant, JSX.Element> = {
    success: <SuccessIcon />,
    warning: <InfoIcon />,
    info: <InfoIcon />,
    danger: <DangerIcon />
  };

  return variantIcons[variant];
};

type AlertPayload = {
  variant: AlertVariant;
  title: string;
  description: string;
};

export const useAlert = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [variant, setVariant] = React.useState<AlertVariant>('info');
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');

  const openAlert = ({ variant, title, description }: AlertPayload) => {
    setVariant(variant);
    setTitle(title);
    setDescription(description);
    setIsOpen(true);
  };

  const closeAlert = () => {
    setIsOpen(false);
  };

  return { isOpen, variant, title, description, openAlert, closeAlert };
};
