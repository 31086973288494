import { observable, action } from 'mobx';
import UserModel from './UserModel';
import ItemModel from './ItemModel';
import environmentStore from 'src/stores/EnvironmentStore';

export default class ItemListModel {
  // parent
  @observable user: UserModel;

  // state
  @observable isLoading: boolean = false;
  @observable isLoaded: boolean = false;
  @observable loadError: Error | undefined = undefined;

  // current item
  @observable public item: ItemModel;

  constructor(user: UserModel) {
    this.user = user;
    this.createNewItem();
  }

  @action
  createNewItem() {
    this.item = new ItemModel({}, this.user);
    this.item.startAutoSave();
  }

  @action.bound
  public async setItem(id: string | undefined): Promise<void> {
    if (!environmentStore.isSnapshot()) {
      this.item = new ItemModel({ id });
      await this.item.load();
      this.item.startAutoSave();
    }
  }
}
