import React from 'react';

import { useISIntl } from 'src/i18n';
import { PaywallVariant } from '../SubscriptionTieredV2';
import { messages } from '../utils/messages';

interface Props {
  paywallVariant: PaywallVariant;
}

export const PaywallTitle = ({ paywallVariant }: Props) => {
  const { formatMessage, ft } = useISIntl();

  if (paywallVariant !== 'default') {
    const planPaywallVariantMap = {
      'essentials-to-plus': 'Plus',
      'essentials-to-premium': 'Premium',
      'plus-to-premium': 'Premium'
    };

    return (
      <>
        <h2
          data-testid="upgrade-now-title"
          className="my-4 text-gray-900 font-bold sm:text-center"
          style={{ fontSize: 30, lineHeight: '36px' }}>
          {formatMessage(messages.paywallTitleUpgrade, {
            plan: planPaywallVariantMap[paywallVariant]
          })}
        </h2>
        <p
          data-testid="upgrade-now-subtitle"
          className="text-center text-gray-800 my-0 mx-auto"
          style={{ fontSize: 18, fontWeight: 400, lineHeight: '28px' }}>
          {ft(messages.paywallSubtitleUpgrade)}
        </p>
      </>
    );
  }

  return (
    <>
      <h2
        data-testid="upgrade-now-title"
        className="my-4 text-gray-900 font-bold sm:text-center"
        style={{ fontSize: 30, lineHeight: '36px' }}>
        {ft(messages.paywallTitle)}
      </h2>
      <p
        data-testid="upgrade-now-subtitle"
        className="text-left sm:text-center text-gray-800 my-0 mx-auto"
        style={{ fontSize: 18, fontWeight: 400, lineHeight: '28px' }}>
        {ft(messages.paywallSubtitle)}
      </p>
    </>
  );
};
