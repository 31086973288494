import React from 'react';
import { observer } from 'mobx-react';
import NumericInput from 'react-numeric-input';
import { SettingKeys, TemplateCode, TemplateCodes } from '@invoice-simple/common';
import { FeatureName } from '@invoice-simple/feature-gate';

import { defineMessages, injectIntl } from 'src/i18n';
import { AppStore } from 'src/stores/AppStore';
import { compose } from 'src/util/functions';
import injectStore from 'src/util/injectStore';
import { SettingsCurrencySelector } from '../Currency';
import ColorSelect from '../ColorSelect/ColorSelect';
import DocNumberInput from '../DocNumberInput';
import EmailInput from '../EmailInput';
import TextareaAutosize from 'react-textarea-autosize';
import * as SettingDisplayTypes from 'src/data/settingsDisplayTypes';
import { intlTaxTypeOptions } from 'src/i18n/intlTaxTypeOptions';
import { Selectv1Adapter } from 'src/components/Selectv1Adapter';
import { PaymentQRCodeToggle } from 'src/payments';
import { intlTaxYearMonthsOptions } from 'src/i18n/intlTaxYearMonthsOptions';
import SettingModel from 'src/models/SettingModel';
import { dateFormatOptions, localeOptions } from '../Settings/RegionSettings';
import { useISIntl } from 'src/i18n/utils';
import { Option } from '../shared/RadioButton';
import { PaymentsSurchargeToggleFormGroup } from 'src/payments/components/PaymentSetting/';
import { FeesTypeSettingFormGroup } from 'src/payments/components/PaymentSetting';
import { ClientSignatureToggle } from '../ClientSignature/ClientSignatureToggle';
import { navToPaywallWithFeatureAndRef } from 'src/util/navigation';

const messages = defineMessages({
  customizeTemplate: {
    id: 'settings.list.customizeTemplate',
    defaultMessage: 'customize template'
  }
});

interface SettingInputProps {
  store: AppStore;
  setting: SettingModel;
  defaultValue?: string;
  placeholder?: string;
  reverse?: boolean;
  meta: Record<string, unknown>;
  radioOptions?: Option[];
}

const SettingInput = ({
  store,
  meta,
  setting,
  defaultValue,
  placeholder,
  reverse = false,
  radioOptions
}: SettingInputProps) => {
  const intl = useISIntl();
  const isPhoneInput = setting.name === SettingKeys.ContactPhone;

  switch (setting.getDisplayType()) {
    case SettingDisplayTypes.EMAIL:
      return (
        <EmailInput
          key={setting.key}
          name={setting.key}
          disabled={setting.isLoading}
          value={setting.value}
          onValidEmail={(email) => setting.setValue(email)}
        />
      );
    case SettingDisplayTypes.THEME_TEMPLATE:
      const selectedTemplate = setting.value as TemplateCode | undefined;
      const encodedTemplate = encodeURIComponent(selectedTemplate || TemplateCodes.STYLE_1);

      const themeColor = (meta.themeColorSetting as SettingModel).value as string | undefined;
      const encodedColor = encodeURIComponent(themeColor || '');

      const heightToWidthRatio = 1.2;
      const scale = 0.17; // of original size
      const iframeWidth = 1000;
      const iframeHeight = iframeWidth * heightToWidthRatio;
      const previewWidth = iframeWidth * scale;
      const previewHeight = previewWidth * heightToWidthRatio;
      const previewUrl = `/template-preview?template=${encodedTemplate}&color=${encodedColor}`;

      return (
        <>
          <div
            className="shadow mb-2"
            style={{
              width: `${previewWidth}px`,
              height: `${previewHeight}px`,
              overflow: 'hidden'
            }}>
            <iframe
              src={previewUrl}
              className="origin-top-left"
              style={{
                width: `${iframeWidth}px`,
                height: `${iframeHeight}px`,
                transform: `scale(${scale})`,
                transformOrigin: 'top left',
                overflow: 'hidden',
                pointerEvents: 'none',
                userSelect: 'none',
                WebkitUserSelect: 'none', // For Safari
                MozUserSelect: 'none', // For Firefox
                msUserSelect: 'none' // For IE/Edge
              }}
            />
          </div>

          <button
            title={intl.fta(messages.customizeTemplate)}
            className={`btn btn-print btn-block mt-3`}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              store.location.redirectHard(`/templates`);
            }}>
            <span>{intl.fta(messages.customizeTemplate)}</span>
          </button>
        </>
      );
    case SettingDisplayTypes.THEME_COLOR:
      return (
        <ColorSelect
          isLoading={setting.isLoading}
          value={setting.value || ''}
          onChange={(s) => setting.setValue(s)}
        />
      );
    case SettingDisplayTypes.CURRENCY_CODE:
      return <SettingsCurrencySelector id={setting.key} isLoading={setting.isLoading} />;
    case SettingDisplayTypes.LOCALE_LANGUAGE:
      return (
        <Selectv1Adapter
          id={setting.key}
          isClearable={false}
          value={setting.value || 'en-US'}
          options={localeOptions}
          onChange={async (s) => {
            setting.setValue(s.value);
            await setting.save();
            location.reload();
          }}
        />
      );
    case SettingDisplayTypes.LOCALE_TAX_MONTH:
      return (
        <Selectv1Adapter
          id={setting.key}
          isClearable={false}
          value={setting.value || 1}
          options={intlTaxYearMonthsOptions(intl)}
          onChange={(s) => setting.setValue(s.value)}
        />
      );
    case SettingDisplayTypes.LOCALE_DATE_FORMAT:
      return (
        <Selectv1Adapter
          id={setting.key}
          isClearable={false}
          value={setting.value || 'MM/dd/yyyy'}
          options={dateFormatOptions()}
          onChange={(s) => setting.setValue(s.value)}
        />
      );
    case SettingDisplayTypes.TAX_TYPE:
      return (
        <Selectv1Adapter
          id={setting.key}
          isClearable={false}
          value={setting.value}
          options={intlTaxTypeOptions(intl)}
          onChange={(s) => setting.setValue(s.value)}
        />
      );

    case SettingDisplayTypes.NOTES:
      return (
        <TextareaAutosize
          id={setting.key}
          minRows={5}
          maxRows={20}
          value={setting.value}
          onChange={(e) => setting.setValue(e.target.value)}
        />
      );
    case SettingDisplayTypes.DEFAULT_EMAIL_MESSAGE:
      return (
        <TextareaAutosize
          id={setting.key}
          minRows={5}
          maxRows={20}
          value={setting.value}
          onChange={(e) => setting.setValue(e.target.value)}
        />
      );
    case SettingDisplayTypes.TAX_RATE:
      return (
        <NumericInput
          id={setting.key}
          value={setting.value}
          step={1.0}
          precision={3}
          min={0}
          max={100}
          format={(n) => {
            return n + '%';
          }}
          onChange={(v) => {
            setting.setValue(v || 0);
          }}
        />
      );
    case SettingDisplayTypes.DOCUMENT_NUMBER:
      return (
        <DocNumberInput
          disabled={setting.isLoading}
          name={`${setting.getVerboseDocType().toLowerCase()}-number`}
          label={`${setting.getVerboseDocType()} Number`}
          source={setting}
          fieldName={'valStr'}
          placeholder={`${setting.getVerboseDocShortType()}001`}
        />
      );
    case SettingDisplayTypes.PAYMENTS_QR_CODE_TOGGLE:
      return (
        <PaymentQRCodeToggle
          disabled={setting.isLoading}
          checked={setting.value || false}
          onChange={setting.setValue}
        />
      );
    case SettingDisplayTypes.CLIENT_SIGNATURE:
      const hasClientSignatureAccess = store.user.canUseFeature(FeatureName.CLIENT_SIGNATURE);
      const toggled = hasClientSignatureAccess && !!setting.value;

      const onChange = (value: boolean) => {
        if (!hasClientSignatureAccess) {
          return navToPaywallWithFeatureAndRef(FeatureName.CLIENT_SIGNATURE, 'settings');
        }
        setting.setValue(value);
      };

      return (
        <ClientSignatureToggle
          disabled={setting.isLoading}
          checked={toggled}
          onChange={(v) => onChange(v)}
        />
      );
    case SettingDisplayTypes.PAYMENTS_FEES_TYPE:
      return (
        <FeesTypeSettingFormGroup
          options={radioOptions || []}
          setting={setting}
          defaultValue={defaultValue}
        />
      );
    case SettingDisplayTypes.PAYMENTS_ALWAYS_ADD_SURCHARGE:
      return <PaymentsSurchargeToggleFormGroup setting={setting} />;
    default:
      return setting.isTypeBool ? (
        <input
          type="checkbox"
          checked={reverse ? !setting.value : setting.value || false}
          onChange={(e) => setting.setValue(reverse ? !e.target.checked : e.target.checked)}
        />
      ) : (
        <input
          type="text"
          id={setting.key}
          maxLength={isPhoneInput ? 200 : undefined}
          value={setting.value || defaultValue || ''}
          placeholder={placeholder || ''}
          onChange={(e) => setting.setValue(e.target.value)}
          onBlur={() => setting.onBlur()}
        />
      );
  }
};

export default compose(injectStore, injectIntl, observer)(SettingInput);
