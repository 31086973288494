import React, { FC, ReactNode } from 'react';

export const ModalContainer: FC<{ children: ReactNode; style?: string }> = ({
  children,
  style
}) => {
  return (
    <div id="tailwind" data-testid="modal-container">
      <div className="flex justify-center">
        <div
          className={`fixed top-40 overflow-y-scroll md:overflow-y-auto [@media(max-width:768px)]:inset-0 px-[35px] pt-4 md:py-[25px] bg-white rounded-none md:rounded-[10px] shadow border gap-5 flex flex-col ${style}`}>
          {children}
        </div>
      </div>
    </div>
  );
};
