import React, { useEffect, useState } from 'react';
import { InvoiceCompany } from '@invoice-simple/common';

import { Mobile, Desktop } from '../Responsive';
import { BusinessOwnerInput } from './BusinessOwnerInput';
import { BusinessWebsiteInput } from './BusinessWebsiteInput';
import { BusinessAddFieldsButtons } from './AddFieldButtons';

export const BusinessAdditionalFields = ({ company }: { company: InvoiceCompany }) => {
  const [showAdditionalFields, setShowAdditionalFields] = useState<boolean>(false);

  useEffect(() => {
    setShowAdditionalFields(!!company.website || !!company.ownerName);
  }, [company.website, company.ownerName]);

  const toggleShowAdditionalFields = () => setShowAdditionalFields(!showAdditionalFields);

  return (
    <>
      <Mobile>
        <BusinessWebsiteInput />
        <BusinessOwnerInput />
        <BusinessAddFieldsButtons />
      </Mobile>

      <Desktop>
        <>
          <div className="input-with-label-wrapper">
            <div className="label with-baseline">
              <label>&nbsp;</label>
            </div>
            <div className="content">
              <a
                className="cursor-pointer"
                onClick={toggleShowAdditionalFields}
                style={{
                  color: '#2e77bb',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}>
                {showAdditionalFields
                  ? 'Hide additional business details'
                  : 'Show additional business details'}
              </a>
            </div>
          </div>
          {showAdditionalFields && (
            <>
              <BusinessWebsiteInput />
              <BusinessOwnerInput />
            </>
          )}
        </>
      </Desktop>
    </>
  );
};
