import React from 'react';

interface Props {
  confirmButtonText: string;
  dismissButtonText?: string;
  footerText?: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
}

export const PromoModalFooter = ({
  confirmButtonText,
  dismissButtonText,
  footerText,
  onClose,
  onConfirm
}: Props) => {
  const fullWidthButtonStyle: React.CSSProperties = {
    width: '100%',
    maxHeight: '50px',
    padding: '1rem',
    fontSize: '16px',
    fontWeight: 500
  };
  const confirmButtonStyle = !dismissButtonText ? fullWidthButtonStyle : {};

  return (
    <footer className="promo-modal-content-footer">
      <div className="promo-modal-footer-btn-container">
        {!!dismissButtonText && (
          <button
            className="promo-modal-reject-button"
            data-testid="promo-modal-reject-button"
            onClick={onClose}
            type="button">
            {dismissButtonText}
          </button>
        )}
        <button
          data-testid="promo-modal-confirm-button"
          className="promo-modal-confirm-button flex justify-center items-center"
          onClick={onConfirm}
          style={confirmButtonStyle}>
          {confirmButtonText}
        </button>
      </div>
      {!!footerText && (
        <p data-testid="promo-modal-footer-text" className="text-center">
          {footerText}
        </p>
      )}
    </footer>
  );
};
