import { DocTypes, SettingKeys, TemplateCodes } from '@invoice-simple/common';
import { getUser } from '@braze/web-sdk';
import { computed, observable, action, reaction, IReactionDisposer } from 'mobx';
import Parse from 'parse';
import debounce from 'lodash/debounce';
import isInteger from 'lodash/isInteger';

import UserModel from './UserModel';
import titleize from '../util/Titleize';
import { Setting } from '../util/IsParseDomain';
import * as SettingDisplayTypes from '../data/settingsDisplayTypes';
import environmentStore from 'src/stores/EnvironmentStore';
import { getValueOrDefault } from 'src/util/getValueOrDefault';
import { createSettingIntercomSyncReaction } from './SettingIntercomSyncReaction';
import SyncableEntity from './SyncableEntity';
import { UserAttributes } from 'src/util/braze';
import isEmpty from 'lodash/isEmpty';

export default class SettingModel extends SyncableEntity {
  updateIntercomReaction: IReactionDisposer;

  // parent
  @observable user: UserModel;

  // save
  @observable autoSave?: IReactionDisposer;

  // attributes
  @observable accountId: string;
  @observable name: string;
  @observable type: 'str' | 'bool' | 'num' | 'dec';
  @observable valStr?: string;
  @observable valBool?: boolean;
  @observable valNum?: number;
  @observable valDec?: number;
  @observable deleted: boolean;
  @observable invoicePath: string;
  @observable changed: boolean = false;

  get = getValueOrDefault(this.default);

  constructor(user: UserModel, data: Parse.Object | any = {}) {
    super(Setting, null, 'setting');
    this.user = user;
    this.update(data);
    this.updateIntercomReaction = createSettingIntercomSyncReaction(this.user, this);
    this.save = this.save.bind(this);
  }

  toJSON() {
    return { [this.name]: this.value };
  }

  @computed
  get isLoading(): boolean {
    return this.user.settingList.isLoading;
  }

  getDisplayType(): string {
    switch (this.name) {
      case SettingKeys.TaxType:
        return SettingDisplayTypes.TAX_TYPE;
      case SettingKeys.TaxRate:
        return SettingDisplayTypes.TAX_RATE;
      case SettingKeys.LocaleCurrencyCode:
        return SettingDisplayTypes.CURRENCY_CODE;
      case SettingKeys.LocaleTaxYearStartMonth:
        return SettingDisplayTypes.LOCALE_TAX_MONTH;
      case SettingKeys.LocaleDateFormat:
        return SettingDisplayTypes.LOCALE_DATE_FORMAT;
      case SettingKeys.LocaleLanguage:
        return SettingDisplayTypes.LOCALE_LANGUAGE;
      case SettingKeys.ThemeColor:
        return SettingDisplayTypes.THEME_COLOR;
      case SettingKeys.ThemeTemplate:
        return SettingDisplayTypes.THEME_TEMPLATE;
      case SettingKeys.EstimateSignatureRequired:
        return SettingDisplayTypes.CLIENT_SIGNATURE;
      case SettingKeys.PaymentOther:
        return SettingDisplayTypes.NOTES;
      case SettingKeys.DefaultEstimateNote:
        return SettingDisplayTypes.NOTES;
      case SettingKeys.DefaultInvoiceNote:
        return SettingDisplayTypes.NOTES;
      case SettingKeys.EmailDefaultMessage:
        return SettingDisplayTypes.DEFAULT_EMAIL_MESSAGE;
      case SettingKeys.InvoiceLastNo:
        return SettingDisplayTypes.DOCUMENT_NUMBER;
      case SettingKeys.EstimateLastNo:
        return SettingDisplayTypes.DOCUMENT_NUMBER;
      case SettingKeys.ContactEmail:
        return SettingDisplayTypes.EMAIL;
      case SettingKeys.PaymentQRCodeEnabled:
        return SettingDisplayTypes.PAYMENTS_QR_CODE_TOGGLE;
      case SettingKeys.EmailCopySender:
        return SettingDisplayTypes.EMAIL_COPY_SENDER;
      case SettingKeys.PaymentFeesType:
        return SettingDisplayTypes.PAYMENTS_FEES_TYPE;
      case SettingKeys.PaymentAlwaysAddSurcharge:
        return SettingDisplayTypes.PAYMENTS_ALWAYS_ADD_SURCHARGE;
      default:
        return SettingDisplayTypes.UNSPECIFIED;
    }
  }

  @computed
  get isValid(): boolean {
    switch (this.type) {
      case 'str':
        return this.isValueString;
      case 'bool':
        return this.isValueBoolean;
      case 'num':
        return this.isValueNumber && this.isValueInteger;
      case 'dec':
        return this.isValueNumber;
      default:
        return false;
    }
  }
  @computed
  get docType(): number | undefined {
    if (/invoice/.test(this.name)) {
      return 0;
    }
    if (/estimate/.test(this.name)) {
      return 1;
    }
    return undefined;
  }

  getVerboseDocType(): string {
    return this.docType === DocTypes.DOCTYPE_ESTIMATE ? 'Estimate' : 'Invoice';
  }

  getVerboseDocShortType(): string {
    return this.docType === DocTypes.DOCTYPE_ESTIMATE ? 'EST' : 'INV';
  }

  isValidDocType(docType: number): boolean {
    return this.docType === undefined || this.docType === docType;
  }

  @computed
  get valueCol() {
    return `val${titleize(this.type)}`;
  }
  @computed
  get value() {
    return this[this.valueCol];
  }
  @computed
  get key(): string {
    return this.name.replace(/\./g, '-');
  }

  // check db types
  @computed
  get isTypeNum() {
    return this.type === 'num';
  }
  @computed
  get isTypeDec() {
    return this.type === 'dec';
  }
  @computed
  get isTypeStr() {
    return this.type === 'str';
  }
  @computed
  get isTypeBool() {
    return this.type === 'bool';
  }

  // check data type
  @computed
  get isValueString() {
    return typeof this.value === 'string';
  }
  @computed
  get isValueNumber() {
    return typeof this.value === 'number';
  }

  @computed
  get isValueInteger(): boolean {
    return isInteger(this.value);
  }
  @computed
  get isValueBoolean() {
    return typeof this.value === 'boolean';
  }
  @computed
  get parseData() {
    return {
      id: this.id,
      remoteId: this.name,
      type: this.type,
      valStr: this.valStr,
      valBool: this.valBool,
      valNum: this.valNum,
      valDec: this.valDec,
      deleted: false
    };
  }
  @computed
  get autoSaveData() {
    return this.value;
  }

  @computed
  get isPremiumTemplate() {
    if (this.name !== SettingKeys.ThemeTemplate) {
      return false;
    }

    const premiumTemplateCodes: number[] = [
      TemplateCodes.STYLE_5,
      TemplateCodes.STYLE_6,
      TemplateCodes.STYLE_7
    ];

    if (this.valNum && !premiumTemplateCodes.includes(this.valNum)) {
      return false;
    }

    return true;
  }

  @action.bound
  public setValue(value: any): void {
    this[this.valueCol] = value;
    this.changed = true;
  }
  @action.bound
  public onBlur(): void {
    if (this.changed) {
      this.checkForBrazeCustomAttributes();
      this.changed = false;
    }
  }
  @action.bound
  public startAutoSave() {
    if (this.autoSave) {
      return;
    }
    this.autoSave = reaction(
      () => this.autoSaveData,
      debounce(() => {
        this.save();
      }, environmentStore.debounceRate)
    );
  }
  @action
  protected update(data: any): void {
    this.id = data.id;
    this.name = this.get(data, 'remoteId') || this.get(data, 'name');
    this.accountId = this.get(data, 'account') && this.get(data, 'account').id;
    this.type = this.get(data, 'type');
    this.valStr = this.get(data, 'valStr');
    this.valBool = this.get(data, 'valBool');
    this.valNum = this.get(data, 'valNum');
    this.valDec = this.get(data, 'valDec');
    this.deleted = this.get(data, 'deleted');
  }

  private async checkForBrazeCustomAttributes() {
    if (this.name === SettingKeys.CompanyName) {
      getUser()?.setCustomUserAttribute(
        UserAttributes.COMPANY_NAME,
        this.valStr !== undefined && !isEmpty(this.valStr.trim()) ? this.valStr.trim() : null
      );
    }

    if (this.name === SettingKeys.ContactPhone) {
      if (this.valStr && !isEmpty(this.valStr.trim())) {
        getUser()?.setPhoneNumber(this.valStr);
      } else {
        const mobile = this.user.settingList.getSetting(SettingKeys.ContactMobile)?.valStr;

        if (mobile !== undefined && !isEmpty(mobile.trim())) {
          getUser()?.setPhoneNumber(mobile.trim());
          return;
        }

        getUser()?.setPhoneNumber(null);
      }
    }

    if (this.name === SettingKeys.ContactMobile) {
      const phone = this.user.settingList.getSetting(SettingKeys.ContactPhone)?.valStr;

      if (phone !== undefined && !isEmpty(phone.trim())) {
        return;
      }

      getUser()?.setPhoneNumber(
        this.valStr !== undefined && !isEmpty(this.valStr.trim()) ? this.valStr.trim() : null
      );
    }
  }

  private default(key: string) {
    return { deleted: false }[key];
  }
}
