import React, { FC } from 'react';
import { StripeBaseBox } from '../../StripeBaseBox';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { GreenCheckMarkIcon } from 'src/components/Icons';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { getPaymentEventSource } from 'src/payments/utils';

const StripeAcceptingBoxComponent: FC<{
  onCTAClick: (checked: boolean) => void;
  checked: boolean;
  disabled: boolean;
  store: AppStore;
}> = ({ onCTAClick, checked, disabled, store }) => {
  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    component: checked
      ? PaymentEventComponent.tileGlobalEnabled
      : PaymentEventComponent.tileGlobalDisabled
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);

  return (
    <>
      <div data-testid="stripeAcceptingBox" />
      <StripeBaseBox
        icon={<GreenCheckMarkIcon />}
        actionElement={
          <div className="w-auto max-w-[30%] flex items-center justify-center">
            <ToggleSwitch checked={checked} onChange={onCTAClick} disabled={disabled} />
          </div>
        }
      />
    </>
  );
};

export const StripeAcceptingBox = injectStore(StripeAcceptingBoxComponent);
