import { AppStore } from 'src/stores/AppStore';
import uiStore from 'src/stores/UIStore';

const handleNav = (
  navigateMethod: AppStore['nav'],
  name: string,
  event: React.MouseEvent<HTMLElement>
) => {
  event.preventDefault();
  navigateMethod(name);
};

export const changeLocationTo =
  (navigateMethod: AppStore['nav'], name: string) => (event: React.MouseEvent<HTMLElement>) => {
    uiStore.closeNavMenu();
    handleNav(navigateMethod, name, event);
  };
