import React from 'react';
import InvoiceRecordPaymentButton from './InvoiceRecordPaymentButton';
import ConvertToInvoiceButton from './ConvertToInvoiceButton';
import PreviewEditToggle from './PreviewEditToggle';
import InvoicePDFButton from './InvoicePDFButton';
import InvoiceEmailButton from './InvoiceEmailButton';
import { Desktop, Mobile } from '../Responsive';

import SyncStatusIndicator from '../SyncStatusIndicator';

import { compose } from 'src/util/functions';
import { AppStore } from 'src/stores/AppStore';
import { injectStore } from 'src/util/injectStore';
import { defineMessages, injectIntl } from 'src/i18n';
import { WhenInvoiceNotEditable, WhenInvoiceEditable } from '../conditionalRenderer';
import './InvoiceActions.scss';
import { isInvoicePayable } from '@invoice-simple/calculator';
import { ISIntl } from 'src/i18n/utils';

interface Props {
  store: AppStore;
  intl?: ISIntl;
}

const messages = defineMessages({
  payDocTitle: {
    id: 'invoiceActions.pay.title',
    defaultMessage: 'pay your {docType}'
  },
  printDocTitle: {
    id: 'invoiceActions.print.title',
    defaultMessage: 'print your {docType}'
  },
  print: {
    id: 'invoiceActions.print.label',
    defaultMessage: 'print'
  },
  pay: {
    id: 'invoiceActions.pay.label',
    defaultMessage: 'pay'
  }
});

class InvoiceActions extends React.Component<Props, {}> {
  render() {
    const store = this.props.store;
    const doc = store.doc;
    const isLoading = store.isLoading;

    const { ft } = this.props.intl!;

    return (
      <div className="invoice-actions d-print-none">
        <WhenInvoiceNotEditable>
          {doc.asUniversal.meta.stripePublishableKey && isInvoicePayable(doc.asUniversal) && (
            <button
              disabled={isLoading}
              title={ft(messages.payDocTitle)}
              className="btn btn-pay"
              onClick={() => {
                store.docNav('PublicCheckout');
              }}>
              <span>{ft(messages.pay)}</span>
            </button>
          )}
          <InvoicePDFButton value="PDF" />

          <button
            disabled={isLoading}
            title={ft(messages.printDocTitle)}
            className="btn btn-print"
            onClick={doc.print}>
            <span>{ft(messages.print)}</span>
          </button>
        </WhenInvoiceNotEditable>

        <WhenInvoiceEditable>
          <PreviewEditToggle />
          <SyncStatusIndicator isLoading={doc.isLoading} isSaving={doc.isSaving} />
          <div className="invoice-actions-export">
            <Desktop>
              {doc.isInvoice && <InvoiceRecordPaymentButton store={store} />}
              {doc.isEstimate && <ConvertToInvoiceButton store={store} />}
              <InvoicePDFButton value="PDF" />
              <InvoiceEmailButton />
            </Desktop>

            <Mobile>
              <InvoicePDFButton value="PDF" />
              <InvoiceEmailButton />
            </Mobile>
          </div>
        </WhenInvoiceEditable>
      </div>
    );
  }
}

export default compose(injectIntl, injectStore)(InvoiceActions);
