import { observable, action } from 'mobx';
import UserModel from './UserModel';
import ClientModel from './ClientModel';
import environmentStore from 'src/stores/EnvironmentStore';

export default class ClientListModel {
  // parent
  @observable user: UserModel;

  // state
  @observable isLoading: boolean = false;
  @observable isLoaded: boolean = false;
  @observable loadError: Error | undefined = undefined;

  // current client
  @observable client: ClientModel;

  constructor(user: UserModel) {
    this.user = user;
    this.createNewClient();
  }

  @action
  createNewClient() {
    this.client = new ClientModel();
    this.client.startAutoSave();
  }

  @action.bound
  public async setClient(id: string): Promise<void> {
    if (environmentStore.isSnapshot()) {
      return;
    }

    this.client = new ClientModel({ id });
    await this.client.load();
    this.client.startAutoSave();
  }
}
