import React from 'react';

import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISIntl } from 'src/i18n/utils';
import UserModel, { Cadence } from 'src/models/UserModel';
import { LoadingSpinner } from '../Button';
import { useTiers } from '../SubscriptionPaywall/hooks/useTiers';
import { messages } from './messages';
import { UpgradeState } from './UpgradeSection';

interface PremiumUpgradeSectionProps {
  user: UserModel;
  formatMessage: ISIntl['formatMessage'];
  onUpgrade: (cadence: Cadence) => Promise<void>;
  interval: Cadence;
  monthlyPricePerYear: string;
  label: string | null;
  upgradeState: UpgradeState;
}

export const PremiumUpgradeSection = ({
  user,
  formatMessage,
  onUpgrade,
  interval,
  monthlyPricePerYear,
  label,
  upgradeState
}: PremiumUpgradeSectionProps) => {
  const { tiers } = useTiers({ user });

  const premiumTierList = tiers.premium.includedFeatures;
  const advancedReporting = premiumTierList.pop();
  const reorderedTierList = [advancedReporting, ...premiumTierList];

  return (
    <>
      <div className="flex flex-col md:flex-row justify-center">
        <div className="flex flex-col border bg-white md:rounded-l-2xl rounded-t-2xl md:rounded-tr-none">
          <div className="px-6 py-7 border-bottom">
            <p className="text-2xl font-semibold text-gray-800">
              <FormattedMessage {...messages.premiumPaywallTitle} />{' '}
              {formatMessage(messages.premiumFeature1)}
            </p>
          </div>
          <img
            className="max-w-2xl lg:max-w-screen-sm"
            src="/images/report_feature_gate.svg"
            role="presentation"
            alt="report feature gate graphic"
            data-pin-nopin="true"
          />
          <div className="px-6 pt-8 pb-12">
            <p className="text-2xl font-semibold text-gray-800">
              {formatMessage(messages.premiumIncomeInsights)}
            </p>
            <p className="text-xl mt-2 font-medium text-gray-800">
              {formatMessage(messages.premiumIncomeInsightsSubtext)}
            </p>
          </div>
        </div>
        <div className="flex flex-col bg-white border rounded-r-none rounded-b-2xl md:rounded-bl-none md:rounded-r-2xl md:max-w-lg -mt-[1px] md:-ml-[1px] md:mt-0">
          <div className="px-7 pt-10 md:pt-20 border-bottom">
            <p className="text-4xl font-semibold mb-4 text-gray-800">
              {formatMessage(messages.premiumPlanTitle)}
            </p>
            <p className="mt-8 text-4xl text-gray-800 font-bold">
              {interval === Cadence.ANNUAL && (
                <span className="mt-8 text-3xl text-gray-400 font-semibold line-through mr-3">
                  {monthlyPricePerYear}
                </span>
              )}
              {label}
            </p>
            <button
              type="button"
              onClick={() => onUpgrade(interval)}
              className="mt-8 mb-6 w-full border-none rounded-md bg-orange-is font-semibold py-3 text-center text-2xl text-white hover:bg-orange-700">
              {upgradeState === 'loading' && <LoadingSpinner />}
              {formatMessage(messages.upgradeNowBtn)}
            </button>
          </div>
          <div className="flex flex-col p-6">
            <p className="mb-3 font-semibold">{formatMessage(messages.premiumFeatureList)}</p>
            <ul role="list" className="space-y-2 pl-0">
              {reorderedTierList.map((feature) => (
                <li key={feature} className="flex items-start">
                  <FontAwesomeIcon
                    className="h-5 w-5 mt-1 flex-shrink-0 text-gray-600 mr-2"
                    icon="check"
                  />
                  <span
                    className={`text-xl text-gray-500 font-medium ${
                      reorderedTierList[0] === feature ? 'font-bold' : ''
                    }`}>
                    {feature}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
