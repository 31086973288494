import React from 'react';
import { observer } from 'mobx-react';

import { compose } from 'src/util/functions';
import { WhenInvoiceEditable } from '../conditionalRenderer';
import { InvoiceSettings } from './InvoiceSettings';

const InvoiceSidebar = (props) => {
  const store = props.store;
  return (
    <WhenInvoiceEditable>
      <div className="col-lg-3 d-print-none">
        <div className="invoice-sidebar">
          <InvoiceSettings store={store} />
        </div>
      </div>
    </WhenInvoiceEditable>
  );
};

export default compose(observer)(InvoiceSidebar);
