import React from 'react';

export function Paypal(): JSX.Element {
  return (
    <svg width="78" height="20" viewBox="0 0 78 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.7083 8.6357C29.479 10.1878 28.2973 10.1878 27.1508 10.1878H26.4983L26.9568 7.29524C26.9921 7.11886 27.1332 6.9954 27.3096 6.9954H27.6094C28.3855 6.9954 29.1263 6.9954 29.4967 7.43634C29.7259 7.7009 29.7965 8.08893 29.7083 8.6357ZM29.2145 4.59668H24.9109C24.611 4.59668 24.3641 4.80833 24.3112 5.10817L22.5651 16.1493C22.5298 16.361 22.7062 16.555 22.9178 16.555H24.9814C25.2813 16.555 25.5282 16.3433 25.5811 16.0435L26.0573 13.0627C26.1102 12.7629 26.3572 12.5513 26.6394 12.5513H27.9975C30.8371 12.5513 32.4774 11.1755 32.9007 8.45932C33.0947 7.2776 32.9007 6.34281 32.354 5.67258C31.7543 4.98471 30.6607 4.59668 29.2145 4.59668Z"
        fill="#253B80"
      />
      <path
        d="M39.2157 12.6041C39.0217 13.7858 38.0869 14.5795 36.8875 14.5795C36.2878 14.5795 35.8116 14.3855 35.4941 14.0151C35.1943 13.6623 35.0708 13.1332 35.1767 12.5688C35.3707 11.4047 36.3055 10.5757 37.4872 10.5757C38.0692 10.5757 38.5454 10.7698 38.8629 11.1401C39.1804 11.5105 39.3039 12.022 39.2157 12.6041ZM42.0906 8.58269H40.027C39.8506 8.58269 39.6919 8.70615 39.6743 8.88253L39.5861 9.46457L39.445 9.25292C39.004 8.60033 37.9987 8.38867 37.011 8.38867C34.7357 8.38867 32.7779 10.1172 32.4075 12.5335C32.2135 13.7329 32.4957 14.897 33.1836 15.7083C33.8186 16.4491 34.7181 16.7489 35.7763 16.7489C37.6107 16.7489 38.6336 15.5672 38.6336 15.5672L38.5454 16.1316C38.5102 16.3432 38.6865 16.5549 38.8982 16.5549H40.7501C41.05 16.5549 41.2969 16.3432 41.3498 16.0434L42.461 8.98835C42.4786 8.7767 42.3199 8.58269 42.0906 8.58269Z"
        fill="#253B80"
      />
      <path
        d="M53.0768 8.58301H51.0132C50.8192 8.58301 50.6252 8.68883 50.5194 8.84757L47.6621 13.063L46.4451 9.02395C46.3745 8.77702 46.1276 8.60064 45.863 8.60064H43.8347C43.5878 8.60064 43.4114 8.84757 43.4996 9.07686L45.7749 15.7792L43.6231 18.8128C43.4467 19.0598 43.6231 19.3772 43.9229 19.3772H45.9865C46.1805 19.3772 46.3745 19.2891 46.4804 19.1127L53.3767 9.16505C53.5354 8.91812 53.359 8.58301 53.0768 8.58301Z"
        fill="#253B80"
      />
      <path
        d="M60.4329 8.6357C60.2036 10.1878 59.0218 10.1878 57.8754 10.1878H57.2228L57.6814 7.29524C57.7167 7.11886 57.8578 6.9954 58.0341 6.9954H58.334C59.11 6.9954 59.8508 6.9954 60.2212 7.43634C60.4505 7.7009 60.521 8.08893 60.4329 8.6357ZM59.939 4.59668H55.6354C55.3356 4.59668 55.0887 4.80833 55.0534 5.10817L53.3073 16.1493C53.272 16.361 53.4484 16.555 53.66 16.555H55.8647C56.0764 16.555 56.2527 16.4139 56.2704 16.2022L56.7642 13.0804C56.8171 12.7805 57.0641 12.5689 57.3463 12.5689H58.7044C61.544 12.5689 63.1843 11.1932 63.6076 8.47696C63.8016 7.29524 63.6076 6.36044 63.0609 5.69021C62.4612 4.98471 61.3853 4.59668 59.939 4.59668Z"
        fill="#179BD7"
      />
      <path
        d="M69.9403 12.6041C69.7463 13.7858 68.8115 14.5795 67.6121 14.5795C67.0124 14.5795 66.5362 14.3855 66.2187 14.0151C65.9189 13.6623 65.7954 13.1332 65.9013 12.5688C66.0953 11.4047 67.0301 10.5757 68.2118 10.5757C68.7938 10.5757 69.2701 10.7698 69.5875 11.1401C69.905 11.5105 70.0285 12.022 69.9403 12.6041ZM72.8152 8.58269H70.7516C70.5752 8.58269 70.4165 8.70615 70.3989 8.88253L70.3107 9.46457L70.1696 9.25292C69.7286 8.60033 68.7233 8.38867 67.7356 8.38867C65.4603 8.38867 63.5025 10.1172 63.1322 12.5335C62.9381 13.7329 63.2203 14.897 63.9082 15.7083C64.5432 16.4491 65.4427 16.7489 66.5009 16.7489C68.3353 16.7489 69.3582 15.5672 69.3582 15.5672L69.2701 16.1316C69.2348 16.3432 69.4112 16.5549 69.6228 16.5549H71.4748C71.7746 16.5549 72.0215 16.3432 72.0568 16.0434L73.168 8.98835C73.2032 8.7767 73.0269 8.58269 72.8152 8.58269Z"
        fill="#179BD7"
      />
      <path
        d="M75.2312 4.89658L73.4674 16.1494C73.4321 16.361 73.6085 16.5551 73.8202 16.5551H75.6016C75.9014 16.5551 76.1483 16.3434 76.2012 16.0436L77.9474 5.0024C77.9826 4.79075 77.8063 4.5791 77.5946 4.5791H75.6016C75.4252 4.59674 75.2664 4.73784 75.2312 4.89658Z"
        fill="#179BD7"
      />
      <path
        d="M4.6968 18.7073L5.03192 16.6084L4.29114 16.5908H0.78125L3.21524 1.15782C3.21524 1.10491 3.25052 1.06963 3.28579 1.03436C3.32107 0.999083 3.37398 0.981445 3.40926 0.981445H9.31787C11.2756 0.981445 12.6337 1.38711 13.3392 2.19844C13.6744 2.56883 13.886 2.9745 13.9918 3.3978C14.0977 3.85638 14.0977 4.40315 13.9918 5.05574V5.10865V5.53196L14.327 5.72597C14.6092 5.86707 14.8208 6.04345 14.9972 6.23746C15.2794 6.55494 15.4558 6.97824 15.544 7.45446C15.6321 7.94831 15.5969 8.54799 15.4734 9.21822C15.3147 9.99428 15.0854 10.6645 14.7503 11.2289C14.4504 11.7404 14.0624 12.1637 13.6038 12.4812C13.1629 12.7987 12.6514 13.028 12.0517 13.1867C11.4873 13.3278 10.8347 13.416 10.1116 13.416H9.65298C9.31787 13.416 9.00039 13.5394 8.75346 13.7511C8.50653 13.9627 8.33016 14.2626 8.27725 14.5801L8.24197 14.7741L7.65993 18.4603L7.64229 18.6014C7.64229 18.6367 7.62465 18.672 7.60701 18.672C7.58938 18.6896 7.57174 18.6896 7.5541 18.6896H4.6968V18.7073Z"
        fill="#253B80"
      />
      <path
        d="M14.6277 5.17969C14.6101 5.28551 14.5925 5.40898 14.5748 5.53244C13.7988 9.53618 11.1355 10.9119 7.73142 10.9119H6.00293C5.57963 10.9119 5.24451 11.2118 5.17396 11.6174L4.29208 17.2438L4.04515 18.8312C4.00988 19.0958 4.20389 19.3427 4.48609 19.3427H7.55504C7.92543 19.3427 8.22527 19.0781 8.27818 18.7254L8.31346 18.5667L8.8955 14.898L8.93078 14.704C8.98369 14.3513 9.30117 14.0867 9.65392 14.0867H10.1125C13.0933 14.0867 15.4214 12.8697 16.1093 9.37745C16.3915 7.91352 16.2504 6.69652 15.492 5.83228C15.2451 5.56771 14.9628 5.35606 14.6277 5.17969Z"
        fill="#179BD7"
      />
      <path
        d="M13.8163 4.86124C13.6929 4.82597 13.5694 4.79069 13.4459 4.77306C13.3225 4.73778 13.1814 4.72014 13.0579 4.70251C12.5993 4.63196 12.0702 4.59668 11.5234 4.59668H6.90238C6.79655 4.59668 6.67309 4.61432 6.5849 4.66723C6.37325 4.77306 6.21451 4.96707 6.17923 5.214L5.19153 11.4401L5.15625 11.6165C5.2268 11.2108 5.57955 10.911 5.98522 10.911H7.71371C11.1178 10.911 13.7811 9.53522 14.5571 5.53148C14.5747 5.40801 14.5924 5.30219 14.61 5.17872C14.416 5.0729 14.2044 4.98471 13.9751 4.91416C13.9398 4.89652 13.8869 4.87888 13.8163 4.86124Z"
        fill="#222D65"
      />
      <path
        d="M6.17988 5.19605C6.21516 4.94913 6.37389 4.75511 6.58555 4.64928C6.69137 4.59637 6.7972 4.57874 6.90302 4.57874H11.5241C12.0708 4.57874 12.5823 4.61401 13.0586 4.68456C13.1997 4.7022 13.3231 4.73747 13.4466 4.75511C13.57 4.79039 13.6935 4.80802 13.817 4.8433C13.8699 4.86094 13.9404 4.87857 13.9934 4.89621C14.2226 4.96676 14.4343 5.05495 14.6283 5.16078C14.8576 3.67921 14.6283 2.67387 13.8346 1.77435C12.9527 0.769005 11.3653 0.345703 9.33702 0.345703H3.42841C3.00511 0.345703 2.65235 0.645543 2.59944 1.05121L0.147808 16.6429C0.0948949 16.9427 0.341822 17.2249 0.641661 17.2249H4.29265L5.20981 11.4221L6.17988 5.19605Z"
        fill="#253B80"
      />
    </svg>
  );
}
