import React, { FC } from 'react';
import { StripeBaseBox } from './StripeBaseBox';
import { BlueCTAButton } from '../Common';
import { OrangeWarningIcon } from 'src/components/Icons';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { getPaymentEventSource } from 'src/payments/utils';

export const StripePendingBoxComponent: FC<{ onCTAClick: () => void; store: AppStore }> = ({
  onCTAClick,
  store
}) => {
  function handleSetupClick() {
    onCTAClick();
  }

  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    component: PaymentEventComponent.tilePending
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);
  return (
    <>
      <div data-testid="stripePendingBox" />
      <StripeBaseBox
        icon={<OrangeWarningIcon />}
        actionElement={<BlueCTAButton onClick={handleSetupClick}>Finish Setup</BlueCTAButton>}
      />
    </>
  );
};

export const StripePendingBox = injectStore(StripePendingBoxComponent);
