import { useCallback, useState } from 'react';
import { OnboardOrigin } from '../types';
import { useIsPaymentsEnabled } from './useIsPaymentsEnabled';

interface PaymentsModalHook {
  isOpen: boolean;
  showModal: () => void;
  hideModal: () => void;
  origin: OnboardOrigin;
}

export const usePaymentOnboardModal = (): PaymentsModalHook => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isPaymentsEnabled = useIsPaymentsEnabled();

  const origin = useCallback(() => {
    const urlPath = window.location.pathname.slice(1);
    return urlPath === 'settings' ? OnboardOrigin.webSettings : OnboardOrigin.webInvoiceList;
  }, []);

  return {
    isOpen,
    showModal: isPaymentsEnabled ? () => setIsOpen(true) : () => {},
    hideModal: () => setIsOpen(false),
    origin: origin()
  };
};
