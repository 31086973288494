import React from 'react';

export interface HeaderProps {
  title: string;
  subTitle?: string | JSX.Element;
}

export const Header = ({ title }: HeaderProps) => {
  return (
    <header className="pt-[24px] md:pt-[20px] pb-[24px]">
      <h2 className="text-gray-800 text-[24px] font-semibold leading-8">{title}</h2>
    </header>
  );
};
