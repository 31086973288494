import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useCancelSubscription } from '../CancelSubscriptionContext';
import UserModel from 'src/models/UserModel';
import './CancelScreens.scss';
import { ReturnToAppButton } from '../ReturnToAppButton';
import { Platform } from '@invoice-simple/common';
import { SubscriptionCancelFlowVersion } from '../common';
import { getFormattedDateString } from 'src/util/date';
import { URLQueryParamKeys, getURLQueryParam } from 'src/util/url';

interface Props {
  user: UserModel;
  platform: Platform;
  version: SubscriptionCancelFlowVersion;
  onError: (error: { title?: string; body?: string }) => void;
}

const CompletedScreen = ({ user, platform, version }: Props) => {
  const { metadata } = useCancelSubscription();

  useEffect(() => {
    user.trackAppEventViaApi('subscription-cancel-pageViewed', {
      page: 'confirmation'
    });
  }, []);

  /* Shouldn't reach this screen if from mobile app WebView */
  if (platform !== Platform.WEB) {
    return null;
  }

  const CompletedV1 = () => (
    <>
      {metadata.hasRedeemedOffer ? (
        <>
          <h1>Congrats on your discount!</h1>
          <p className="cancel-content">
            Your request to apply the discount has been received and you will receive an email
            confirmation shortly.
          </p>
        </>
      ) : (
        <>
          <h1>Thank you for your business</h1>
          <p className="cancel-content">
            Your request to cancel the subscription has been received and you will receive an email
            confirmation shortly.
          </p>
        </>
      )}
    </>
  );

  const CompletedV2 = () => {
    let subscriptionExpiry: string;

    const expiryFromQueryParam = getURLQueryParam(URLQueryParamKeys.SUB_EXPIRY_MS);
    if (expiryFromQueryParam) {
      subscriptionExpiry = getFormattedDateString(+expiryFromQueryParam);
    } else {
      const expiryFromUser = user.lastActivePaidWebSub(
        user.activeSubscriptionTier
      )?.expiryTimestamp;
      subscriptionExpiry = expiryFromUser
        ? getFormattedDateString(expiryFromUser)
        : 'the end of your current billing period';
    }

    return (
      <>
        <h1>Thank you for your business</h1>
        <p className="cancel-content">
          {`Your subscription has been canceled. You have access to your subscribed tier till ${subscriptionExpiry}. You will also receive an email confirmation shortly.`}
        </p>
      </>
    );
  };

  return (
    <section className="text-center cancel-screen">
      <img src="/images/logo-gradient-and-title.png" />
      {version === SubscriptionCancelFlowVersion.V1 && <CompletedV1 />}
      {version === SubscriptionCancelFlowVersion.V2 && <CompletedV2 />}
      <ReturnToAppButton platform={platform} />
    </section>
  );
};

export default observer(CompletedScreen);
