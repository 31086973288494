import { observable, computed, action, IReactionDisposer } from 'mobx';
import { v1 as uuid } from 'uuid';
import axios, { AxiosError } from 'axios';
import Parse from 'parse';
import UserModel from './UserModel';
import { ImageFile } from '../types/ImageFile';
import fileExt from '../util/FileExt';
import { Photo } from '../util/IsParseDomain';
import isPromise from '../util/IsPromise';
import { getValueOrDefault } from 'src/util/getValueOrDefault';

export default class PhotoModel {
  // hooks
  onSave: (photo: PhotoModel) => void;
  onLoad: (photo: PhotoModel) => void;

  // photo
  @observable user: UserModel;
  @observable id: string;
  @observable remoteId: string;
  @observable deleted: boolean;
  @observable updated: number;
  @observable url: string | undefined;
  @observable md5: string | undefined;
  @observable previewUrl: string | undefined;

  // save
  @observable autoSave?: IReactionDisposer;
  @observable isSaving: boolean = false;
  @observable isSaved: boolean = false;
  @observable saveError: Error | undefined = undefined;

  // state
  @observable isUploading: boolean = false;
  @observable uploadError: string | undefined;

  get = getValueOrDefault(this.default);

  constructor(user: UserModel, onSave: (photo: PhotoModel) => void, data: Parse.Object | any = {}) {
    this.user = user;
    this.onSave = onSave;
    this.update(data);
  }
  @computed
  public get isNew(): boolean {
    return !this.id;
  }
  @computed
  public get isUploaded() {
    return !!this.url;
  }
  @computed
  public get parseData() {
    return {
      remoteId: this.remoteId,
      deleted: this.deleted,
      url: this.url,
      md5: this.md5,
      updated: Date.now()
    };
  }

  @action
  public loadByRemoteId(remoteId: string): Promise<void> {
    const q = new Parse.Query(Photo);
    q.equalTo('remoteId', remoteId);
    return isPromise(q.first())
      .then((res) => {
        if (res) {
          this.update(res);
        }
        if (this.onLoad) {
          this.onLoad(this);
        }
      })
      .catch((err) => {
        this.user.handleError('photo-load-remote', err);
      });
  }

  @action.bound
  public load(): Promise<void> {
    const q = new Parse.Query(Photo);
    return isPromise(q.get(this.id))
      .then((res) => {
        if (res) {
          this.update(res);
        }
        if (this.onLoad) {
          this.onLoad(this);
        }
      })
      .catch((err) => {
        this.user.handleError('photo-load', err);
      });
  }

  @action.bound
  public save(): void {
    if (!this.isSaving) {
      this.startSaving();
      const photo = new Photo(this.parseData);
      if (this.isNew) {
        photo.set('account', this.user.getParseAccount());
      }
      isPromise(photo.save())
        .then((res) => {
          if (res) {
            this.update(res);
          }
          if (this.onSave) {
            this.onSave(this);
          }
          this.stopSaving();
        })
        .catch((err) => {
          this.user.handleError('photo-save', err);
          this.stopSaving(err);
        });
    }
  }

  @action.bound
  public async duplicatePhoto(): Promise<string | void> {
    const newData = {
      ...this.parseData,
      remoteId: uuid(),
      updated: Date.now()
    };
    this.startSaving();
    const photo = new Photo(newData);
    if (this.isNew) {
      photo.set('account', this.user.getParseAccount());
    }
    try {
      const res = await isPromise(photo.save());
      if (res) {
        this.update(res);
      }
      if (this.onSave) {
        this.onSave(this);
      }
      this.stopSaving();
      return photo.get('remoteId');
    } catch (err) {
      this.user.handleError('photo-duplicate', err);
      this.stopSaving(err);
    }
  }

  @action
  public upload(file: ImageFile): Promise<void> {
    return new Promise((resolve, reject) => {
      this.previewUrl = this.getPreviewUrl(file);
      this.startUploading();
      const remoteId = uuid();
      const url = '/api/v2/request-upload-photo';
      const opts = { headers: { 'Content-Type': file.type } };
      const data = {
        photoId: remoteId,
        accountId: this.user.accountId,
        extension: fileExt(file.name)
      };
      axios
        .post(url, data, this.user.getApiReqOpts())
        .then((urlRes) => {
          axios.put(urlRes.data.signedRequest, file, opts).then((uploadRes) => {
            this.update({
              url: urlRes.data.url,
              remoteId,
              md5: uploadRes.headers.etag
            });
            this.save();
            this.stopUploading();
            if (this.previewUrl) {
              URL.revokeObjectURL(this.previewUrl);
              this.previewUrl = undefined;
            }
            resolve();
          });
        })
        .catch((err: AxiosError<{ title?: string; message?: string }>) => {
          this.user.trackError('photo-upload', err);
          this.stopUploading(err);
          reject(err);
        });
    });
  }

  @action
  public async delete() {
    this.update({
      deleted: true
    });
    const q = new Parse.Query(Photo);
    q.equalTo('remoteId', this.user.settingList.logoRemoteId);
    const photo = await q.first();
    if (!photo) {
      return;
    }
    isPromise(photo.save({ deleted: true, updated: Date.now() }))
      .then((res) => {
        if (res) {
          this.update(res);
        }
        if (this.onSave) {
          this.onSave(this);
        }
      })
      .catch((err) => {
        this.user.trackError('photo-delete', err);
      });
  }

  private getPreviewUrl(file: File): string | undefined {
    try {
      return URL.createObjectURL(file);
    } catch {
      return undefined;
    }
  }

  @action
  private startSaving(): void {
    this.isSaving = true;
  }
  @action
  private stopSaving(error?: Error) {
    this.isSaving = false;
    this.isSaved = !error;
    this.saveError = error;
  }
  @action
  private startUploading(): void {
    this.isUploading = true;
  }
  @action
  private stopUploading(error?: AxiosError<{ title?: string; message?: string }>) {
    this.isUploading = false;
    this.uploadError = error
      ? error.response
        ? error.response.data.message
        : error.message
      : undefined;
  }
  @action
  private update(data: Parse.Object | any): void {
    this.id = data.id;
    this.remoteId = this.get(data, 'remoteId');
    this.deleted = this.get(data, 'deleted');
    this.updated = this.get(data, 'updated');
    this.md5 = this.get(data, 'md5') && this.get(data, 'md5').replace(/\W/g, '').trim();
    this.url = this.get(data, 'url');
  }

  private default(name: string): any {
    return { remoteId: uuid(), deleted: false }[name];
  }
}
