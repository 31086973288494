import React from 'react';
import { CashIcon } from 'src/components/Icons/CashIcon';
import { useISIntl } from 'src/i18n';
import { messages } from './messages';

export const PaymentsOrdersEmpty: React.FC = () => {
  const { f } = useISIntl();
  return (
    <td colSpan={5} aria-label="empty" id="tailwind" data-testid="PaymentsOrdersEmpty">
      <div className="w-full flex flex-col text-center my-10 gap-4 align-middle">
        <div>
          <CashIcon className="w-16 h-16" />
        </div>
        <div className="font-semibold text-3xl leading-8">{f(messages.paypalPayments)}</div>
        <div className="font-normal text-2xl max-w-md self-center">
          {f(messages.paymentsWillAppear)}
        </div>
      </div>
    </td>
  );
};
