import flagsmith from 'flagsmith';

import { ConsentType } from '@invoice-simple/common';

import { isConsentGiven } from 'src/apis/consentAPI';
import UserModel from 'src/models/UserModel';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';

export const hasReferralConsent = async () => {
  const user = UserModel.getInstance();

  if (!user.isSignedUp) {
    return false;
  }

  const isReferralTermsEnabled = flagsmith.hasFeature('referral_terms');

  const isReferralConsentGiven = isReferralTermsEnabled
    ? await isConsentGiven({
        user,
        consentType: ConsentType.REFERRAL_PROGRAM
      })
    : true;

  return isReferralConsentGiven;
};

export const isReferralShareRef = () =>
  getURLQueryParam(URLQueryParamKeys.REF) === URLQueryParamKeys.REFERRAL_SHARE;
