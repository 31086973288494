import React from 'react';
import { SVGProps } from 'react';

export const PCMobileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_20_238)">
        <path
          d="M28.125 41.6672H27.0828V37.5H28.125L28.1266 34.375H4.66837C4.09337 34.375 3.125 33.4081 3.125 32.8331C3.125 32.8331 3.125 11.4998 3.125 10.9248C3.125 10.3498 4.08772 9.375 4.66272 9.375H39.0749C39.6499 9.375 40.6032 10.3348 40.6032 10.9098V12.4889H43.75V9.375C43.75 7.65156 42.3484 6.25 40.625 6.25H3.125C1.40156 6.25 0 7.65156 0 9.375V34.375C0 36.0984 1.40156 37.5 3.125 37.5H16.6672V41.6672H14.5844C14.0094 41.6672 13.5422 42.1344 13.5422 42.7094C13.5422 43.2844 14.0094 43.7516 14.5844 43.7516H28.1266C28.7016 43.7516 29.1687 43.2844 29.1687 42.7094C29.1687 42.1344 28.7 41.6672 28.125 41.6672Z"
          fill="#FF5721"
        />
        <path
          d="M45.459 14.0625H32.4707C30.9399 14.0625 29.6875 15.3149 29.6875 16.8457V40.9668C29.6875 42.4976 30.9399 43.75 32.4707 43.75H45.459C46.9897 43.75 48.2422 42.4976 48.2422 40.9668V16.8457C48.2422 15.3149 46.9897 14.0625 45.459 14.0625ZM35.2539 15.4541H42.6758V16.3818H35.2539V15.4541ZM38.9648 41.8945C37.9397 41.8945 37.1094 41.0642 37.1094 40.0391C37.1094 39.0139 37.9397 38.1836 38.9648 38.1836C39.99 38.1836 40.8203 39.0139 40.8203 40.0391C40.8203 41.0642 39.99 41.8945 38.9648 41.8945ZM46.3867 36.3281H31.543V17.7734H46.3867V36.3281Z"
          fill="#FF5721"
        />
      </g>
      <defs>
        <clipPath id="clip0_20_238">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
