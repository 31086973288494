import React, { FC } from 'react';
import { usePaypalContext } from 'src/payments/contexts/PaypalAccountContext';
import { OnboardModalProps } from 'src/payments/types';
import { PaypalInitialModal } from './PaypalInitialModal';
import { PaypalClosedModal } from './PaypalClosedModal';
import { PaypalPendingModal } from './PaypalPendingModal';
import { PaypalOnboardedModal } from './PaypalOnboardedModal';
import { Illustration } from '../../PaymentBasicElements';
import { useIsPaypalVStripeVariantFlagEnabled } from 'src/payments/hooks/useIsPaypalVStripeVariantFlagEnabled';
import { useIsPaypalRedesignFlagEnabled } from 'src/payments/hooks/useIsPaypalRedesignFlagEnabled';
import { useIsPaymentsEnabled } from 'src/payments/hooks/useIsPaymentsEnabled';

export const PaypalModalBase: FC<OnboardModalProps> = (props) => {
  const paypalAccountContext = usePaypalContext();
  const isPaymentsEnabled = useIsPaymentsEnabled();

  if (!paypalAccountContext || !isPaymentsEnabled) return null;

  const {
    isPaypalInitial,
    isPaypalClosed,
    isPaypalPending,
    isPaypalOnboarded,
    isPrimaryEmailConfirmed
  } = paypalAccountContext;

  return (
    <div
      className="h-full w-full relative max-w-[480px] [@media(min-height:663px)]:h-auto"
      data-testid="paypal--modal-content">
      {isPaypalInitial() && <PaypalInitialModal {...props} />}
      {isPaypalClosed() && <PaypalClosedModal {...props} />}
      {isPaypalPending() && <PaypalPendingModal {...props} />}
      {isPaypalOnboarded() && (
        <PaypalOnboardedModal {...props} emailConfirmed={isPrimaryEmailConfirmed()} />
      )}
    </div>
  );
};

export const PaypalModalImg: FC = () => {
  const isPaypalVStripeVariantFlagEnabled = useIsPaypalVStripeVariantFlagEnabled();
  const isPaypalRedesignFlagEnabled = useIsPaypalRedesignFlagEnabled();
  return (
    <Illustration
      url={
        isPaypalVStripeVariantFlagEnabled && isPaypalRedesignFlagEnabled
          ? '/images/paypal-onboarding-modal-paypal_v_stripe_variant.png'
          : '/images/paypal-onboarding-modal.png'
      }
      height={236}
      width={413}
    />
  );
};
