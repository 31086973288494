import React from 'react';

interface LoadingSpinnerIconProps extends React.SVGProps<SVGSVGElement> {
  color: 'black' | 'white';
  className?: string;
}

export const LoadingSpinnerIcon = ({ color, className, ...props }: LoadingSpinnerIconProps) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="loading-spinner"
      className={`animate-spin ${className}`}
      {...props}>
      <path
        opacity="0.6"
        d="M10.5 5.3125C11.2767 5.3125 11.9062 4.6829 11.9062 3.90625C11.9062 3.1296 11.2767 2.5 10.5 2.5C9.72335 2.5 9.09375 3.1296 9.09375 3.90625C9.09375 4.6829 9.72335 5.3125 10.5 5.3125Z"
        fill={color}
      />
      <path
        opacity="0.8"
        d="M14.8086 7.09766C15.5852 7.09766 16.2148 6.46806 16.2148 5.69141C16.2148 4.91476 15.5852 4.28516 14.8086 4.28516C14.0319 4.28516 13.4023 4.91476 13.4023 5.69141C13.4023 6.46806 14.0319 7.09766 14.8086 7.09766Z"
        fill={color}
      />
      <path
        d="M16.5937 11.4064C17.3704 11.4064 18 10.7768 18 10.0001C18 9.22347 17.3704 8.59387 16.5937 8.59387C15.8171 8.59387 15.1875 9.22347 15.1875 10.0001C15.1875 10.7768 15.8171 11.4064 16.5937 11.4064Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M14.8086 15.715C15.5852 15.715 16.2148 15.0854 16.2148 14.3087C16.2148 13.5321 15.5852 12.9025 14.8086 12.9025C14.0319 12.9025 13.4023 13.5321 13.4023 14.3087C13.4023 15.0854 14.0319 15.715 14.8086 15.715Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M10.5 17.5C11.2767 17.5 11.9062 16.8704 11.9062 16.0937C11.9062 15.3171 11.2767 14.6875 10.5 14.6875C9.72335 14.6875 9.09375 15.3171 9.09375 16.0937C9.09375 16.8704 9.72335 17.5 10.5 17.5Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M6.19141 15.715C6.96806 15.715 7.59766 15.0854 7.59766 14.3087C7.59766 13.5321 6.96806 12.9025 6.19141 12.9025C5.41476 12.9025 4.78516 13.5321 4.78516 14.3087C4.78516 15.0854 5.41476 15.715 6.19141 15.715Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M4.40625 11.4064C5.1829 11.4064 5.8125 10.7768 5.8125 10.0001C5.8125 9.22347 5.1829 8.59387 4.40625 8.59387C3.6296 8.59387 3 9.22347 3 10.0001C3 10.7768 3.6296 11.4064 4.40625 11.4064Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M6.19141 7.09766C6.96806 7.09766 7.59766 6.46806 7.59766 5.69141C7.59766 4.91476 6.96806 4.28516 6.19141 4.28516C5.41476 4.28516 4.78516 4.91476 4.78516 5.69141C4.78516 6.46806 5.41476 7.09766 6.19141 7.09766Z"
        fill={color}
      />
    </svg>
  );
};
