import React, { FC } from 'react';

import { defineMessages } from 'react-intl';

import {
  ButtonContainer,
  Illustration,
  TopContainer,
  ValueProps
} from 'src/payments/components/PaymentBasicElements';
import { PaymentModalWrapper } from 'src/payments/components/PaymentsModals/PaypalModalWrapper';
import { useCommonMetadata } from 'src/payments/hooks/useCommonMetadata';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { OnboardModalMessages, PaymentEventComponent } from 'src/payments/types';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';

const messages = defineMessages({
  header: {
    id: 'learnMoreModal.header',
    defaultMessage: 'Get Paid Faster'
  },
  description1: {
    id: 'learnMoreModal.description1',
    defaultMessage:
      'Invoice Simple Payments is a hassle-free way to receive payments from your clients'
  },
  description2: {
    id: 'learnMoreModal.description2',
    defaultMessage:
      "Your clients will love the simplicity and you'll love getting your invoices paid twice as fast"
  },
  description3: {
    id: 'learnMoreModal.description3',
    defaultMessage:
      '*Low processing fees, or at no cost to you when you pass them on to your clients'
  },
  cta1: {
    id: 'learnMoreModal.cta1',
    defaultMessage: 'Got It'
  }
});

const LearnMoreModalComponent: FC<{ hideModal: () => void } & { store: AppStore }> = ({
  hideModal,
  store: { user }
}) => {
  const ft = (message: OnboardModalMessages) => message.defaultMessage;
  const commonMetadata = useCommonMetadata();

  const onClickCTA = () => {
    user.events.trackAction('payments-cta', {
      ...commonMetadata,
      'from-guest-account': !user.isSignedUp,
      component: PaymentEventComponent.modalLearnMore
    });
    hideModal();
  };
  const onClickClose = () => {
    user.events.trackAction('payments-cta', {
      ...commonMetadata,
      'from-guest-account': !user.isSignedUp,
      component: PaymentEventComponent.modalLearnMoreClose
    });
    hideModal();
  };

  const paymentsViewedMeta = {
    ...useCommonMetadata(),
    component: PaymentEventComponent.modalLearnMore
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <PaymentModalWrapper isOpen={true} onRequestClose={onClickClose}>
      <TopContainer onClickXIcon={onClickClose} headerMessage={ft(messages.header)} />
      <main>
        <div className="border">
          <Illustration url={'/images/learn-more-modal-logo.png'} width={826} height={472} />
        </div>
        <ValueProps>
          <div className="grid grid-cols-1 gap-10 leading-9 p-2">
            <div className="font-bold">{ft(messages.description1)}</div>
            <div>{ft(messages.description2)}</div>
            <div>{ft(messages.description3)}</div>
          </div>
        </ValueProps>
      </main>
      <ButtonContainer handleClick={onClickCTA} buttonMessage={ft(messages.cta1)} />
    </PaymentModalWrapper>
  );
};

export const LearnMoreModal = injectStore(LearnMoreModalComponent);
