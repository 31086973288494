import React, { useEffect } from 'react';
import { PaymentModalWrapper } from '../PaypalModalWrapper';
import { defineMessages, useISIntl } from 'src/i18n';
import { XIcon } from '../../PaymentBasicElements/XIcon';
import './PaymentsSurchargeConsentModal.scss';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';

const messages = defineMessages({
  title: {
    id: 'surchargeConsent.modal.title',
    defaultMessage: 'Important note about Surcharges'
  },
  introduction: {
    id: 'surchargeConsent.modal.introduction',
    defaultMessage:
      'Depending on where your business and customer are located, local laws may not allow you to add a surcharge to your invoices to cover processing fees. It is your responsibility to understand and comply with your local laws and Card Network Rules.'
  },
  learnMore: {
    id: 'surchargeConsent.modal.learnMore',
    defaultMessage: 'Learn more about surcharges.'
  },
  dismiss: {
    id: 'surchargeConsent.modal.dismiss',
    defaultMessage: 'Dismiss'
  },
  understand: {
    id: 'surchargeConsent.modal.understand',
    defaultMessage: 'I Understand'
  }
});

// const surchargeLearnMoreLink =
// 'https://www.invoicesimple.com/support/articles/adding-a-surcharge-to-invoices';

interface PaymentSurchargeFeesProps {
  isOpen: boolean;
  onSubmit: (consentMessage: string) => void;
  onClose: () => void;
  store: AppStore;
}

export enum PaymentsSurchargeConsentModalActions {
  CLICKED = 'clicked',
  VIEWED = 'viewed'
}
export const feature = 'payments-surcharge-consent-modal-prompt';

export const PaymentsSurchargeConsentModalComponent = ({
  isOpen,
  onSubmit,
  onClose,
  store: {
    user: {
      events: { trackButtonClick, trackModalView }
    }
  }
}: PaymentSurchargeFeesProps) => {
  const { ft } = useISIntl();
  const consentMessage = `${ft(messages.understand)}: ${ft(messages.introduction)}`;

  const trackModalButtonClick = (text: string) => {
    trackButtonClick({ text, action: PaymentsSurchargeConsentModalActions.CLICKED, feature });
  };

  useEffect(() => {
    if (isOpen) {
      trackModalView({
        action: PaymentsSurchargeConsentModalActions.VIEWED,
        feature
      });
    }
  }, [isOpen]);

  const onClickXIcon = () => {
    onClose();
    trackModalButtonClick('X');
  };

  const onClickDismiss = () => {
    onClose();
    trackModalButtonClick('Dismiss');
  };

  const onClickIUnderstand = () => {
    onClose();
    onSubmit(consentMessage);
    trackModalButtonClick('I Understand');
  };

  const onClickOutsideModal = () => {
    onClose();
    trackModalButtonClick('Outside modal');
  };

  return (
    <PaymentModalWrapper isOpen={isOpen} onRequestClose={onClickOutsideModal}>
      <div
        className="surcharge-consent-modal-content"
        data-testid="surcharge-consent-modal-content">
        <header>
          <h3>{ft(messages.title)}</h3>
          <button
            onClick={onClickXIcon}
            data-testid="surcharge-consent-modal-x-button"
            type="button">
            <XIcon />
          </button>
        </header>
        <main>
          <section>
            <img className="surcharge-consent-img" src="/images/surcharge-consent.png" />
          </section>
          <section className="surcharge-consent-intro">
            <p> {ft(messages.introduction)}</p>
            {/* @todo: add this back in, once we have a real KB article link to use. https://app.asana.com/0/1204788891822685/1205477513615811/f
            <p>
              <a href={surchargeLearnMoreLink} target="blank">
                {ft(messages.learnMore)}
              </a>
            </p> */}
          </section>
        </main>

        <footer className="modal-content-footer" id="tailwind">
          <button
            data-testid="surcharge-consent-modal-reject-button"
            onClick={onClickDismiss}
            type="button">
            {ft(messages.dismiss)}
          </button>
          <button
            data-testid="surcharge-consent-modal-submit-button"
            className="flex justify-center items-center"
            onClick={onClickIUnderstand}
            style={{ minWidth: '97px', maxHeight: '30px' }}>
            {ft(messages.understand)}
          </button>
        </footer>
      </div>
    </PaymentModalWrapper>
  );
};

export const PaymentsSurchargeConsentModal = injectStore(PaymentsSurchargeConsentModalComponent);
