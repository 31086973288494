// Do not update this file, we will remove it after we release is-stripe and new design to all users
export const PaymentEventLocation = {
  paymentConfigSettings: 'payment-config-settings',
  promotionBoxSettings: 'promotion-box-settings',
  paymentConfigInvoiceEditor: 'payment-config-invoice-editor',
  promotionBoxInvoiceEditor: 'promotion-box-invoice-editor',
  paymentModal: 'payment-modal',
  invoiceEditor: 'invoice-editor',
  subscriptionPurchase: 'subscription-purchase-payment-modal'
} as const;

type PaymentEventLocationKeys = keyof typeof PaymentEventLocation;
export type PaymentEventLocationType = (typeof PaymentEventLocation)[PaymentEventLocationKeys];
