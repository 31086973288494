import React, { useEffect } from 'react';

import { defineMessages, useISIntl } from 'src/i18n';
import LocationModel from 'src/models/LocationModel';
import UserModel from 'src/models/UserModel';
import LocaleStore from 'src/stores/LocaleStore';

import { Platform } from '@invoice-simple/common';

import { ModalWrapper } from '../ModalWrapper';
import { PromoModalContent } from '../PromoModalContent';
import { Graphic } from './Graphic';

import './ReactivationPrompt.css';

import { ModalName } from '../ModalManagerContext';
import { usePromoModal } from '../PromoModalContent/usePromoModal';

const messages = defineMessages({
  title: {
    id: 'reactivation.prompt.title',
    defaultMessage: 'Easier Choices for Invoicing & Payments'
  },
  new: {
    id: 'doc.head.title.new',
    defaultMessage: 'New'
  },
  qrCodes: {
    id: 'reactivation.prompt.qrCode',
    defaultMessage: 'Add QR Codes & offer a secure online payment option'
  },
  multiplePhotos: {
    id: 'reactivation.prompt.multiplePhotos',
    defaultMessage: 'Include multiple photos on invoices'
  },
  templates: {
    id: 'reactivation.prompt.templates',
    defaultMessage: 'Apply easy-to-use templates'
  },
  subscribeNow: {
    id: 'reactivation.prompt.subscribeNow',
    defaultMessage: 'Subscribe Now'
  },
  noThanks: {
    id: 'subscription.action.noThanks',
    defaultMessage: 'No thanks'
  }
});

export const ReactivationPrompt = ({ user }: { user: UserModel }) => {
  const { shouldShowModal, hideModal } = usePromoModal({
    modalConfig: { modalName: ModalName.ReactivationPrompt },
    initialState: user.shouldShowReactivationPrompt()
  });
  const { ft, fta } = useISIntl();

  useEffect(() => {
    if (user.shouldShowReactivationPrompt()) {
      user.setReactivationPromptShown();
      trackReactivationEvent('shown');
    }
  }, []);

  if (!shouldShowModal) {
    return null;
  }

  function trackReactivationEvent(action: string) {
    user.events.trackEvent('prospect-reactivation', {
      platform: Platform.WEB,
      action
    });
  }

  function onClose() {
    hideModal();
  }

  function onSubscribeNow() {
    trackReactivationEvent('clicked');
    hideModal();
    LocationModel.navAndScrollTop('subscription');
  }

  const modalBody = (
    <div className="new-features">
      <h4>{ft(messages.new).toLocaleUpperCase(LocaleStore.currentLocale)}</h4>
      <ul>
        <li>{ft(messages.qrCodes)}</li>
        <li>{ft(messages.multiplePhotos)}</li>
        <li>{ft(messages.templates)}</li>
      </ul>
    </div>
  );

  return (
    <div data-testid="reactivation-modal-container">
      <ModalWrapper isOpen={shouldShowModal} shouldCloseOnOverlayClick={false}>
        <PromoModalContent
          title={ft(messages.title)}
          body={modalBody}
          image={<Graphic />}
          onClose={onClose}
          onConfirm={onSubscribeNow}
          confirmButtonText={fta(messages.subscribeNow)}
          dismissButtonText={fta(messages.noThanks)}
        />
      </ModalWrapper>
    </div>
  );
};
