import React from 'react';
import { observer } from 'mobx-react';
import { LocalizedCurrency } from 'src/components/Localized/LocalizedCurrency';
import './CurrencyDisplay.css';

interface Prop {
  value: number;
  prefix?: string;
  suffix?: string;
  className?: string;
  currencyCode?: string;
  containerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

@observer
export default class CurrencyDisplay extends React.Component<Prop, any> {
  render() {
    const className = this.props.className ? `currency ${this.props.className}` : 'currency';
    return (
      <span className={className} style={this.props.containerStyle}>
        {!!this.props.prefix && (
          <span className="currency-prefix" style={this.props.textStyle}>
            {this.props.prefix}
          </span>
        )}
        <LocalizedCurrency
          value={this.props.value}
          currencyCode={this.props.currencyCode}
          textStyle={this.props.textStyle}
        />
        {!!this.props.suffix && (
          <span className="currency-suffix" style={this.props.textStyle}>
            {this.props.suffix}
          </span>
        )}
      </span>
    );
  }
}
