import { UAParser } from 'ua-parser-js';
import device from 'current-device';
import { getCookie } from 'src/util/cookie';
import Parse from 'parse';

interface Browser {
  name?: string;
  version?: string;
}

interface Device {
  model?: string;
  type: string;
  vendor?: string;
  orientation: string;
  os: string;
}

export class EnvironmentStore {
  _ua = new UAParser();
  debounceRate: number = 500;
  chromeExtId: string = 'hlhgkeikggdbhfhlmkapkmaogoohepml';

  pdfHost: string = process.env.REACT_APP_PDF_HOST || '';
  appEnv?: string = process.env.REACT_APP_ENV;
  appUrl: string = process.env.REACT_APP_URL || '';
  appName: string = process.env.REACT_APP_NAME || '';
  appHost: string = process.env.REACT_APP_HOST || '';
  appApiUrl: string = process.env.REACT_APP_API_URL || '';
  appParseId: string = process.env.REACT_APP_PARSE_ID || '';
  assetsURL: string = process.env.REACT_APP_ASSET_URL || '';
  appAdminUrl: string = process.env.REACT_APP_ADMIN_URL || '';
  appProxyHost: string = process.env.REACT_APP_PROXY_HOST || '';
  appStripeKey?: string = process.env.REACT_APP_STRIPE_API_KEY;
  intercomAppId: string = process.env.REACT_APP_INTERCOM_APP_ID || '';
  pricingAppUrl: string = process.env.REACT_APP_PRICING_APP_URL || '';
  appVersion: string = window.__is_defaults?.app?.appVersion || '';
  flagsmithState = window.__is_defaults?.flagsmithSeverState;
  installationIdCookieName: string =
    process.env.INSTALLATION_ID_COOKIE_NAME || 'is-installationId-eyJpZCI6Mj';
  cdnURL: string = process.env.REACT_APP_CDN_URL || 'https://cdn.invoicesimple.com';
  appStripeRequireBillingAddress: boolean = /true/i.test(
    process.env.REACT_APP_STRIPE_REQUIRE_BILLING_ADDR || ''
  );
  isCypress: boolean = 'Cypress' in window;
  isProxy: boolean = /^www\./.test(window.location.host);
  isReviewApp: boolean = this.appUrl.includes('reviewapp.getinvoicesimple.com');
  isChromeExtVisit: boolean = window.location.search === '?utm_source=chrome&utm_medium=ext';
  appDev: boolean = /dev|stag/i.test(this.appEnv || '');

  ua: string;
  os: Browser;
  browser: Browser;
  device: Device;
  connectionType: string = navigator && navigator.connection && navigator.connection.effectiveType;
  installationId: string;

  constructor() {
    this.browser = this._ua.getBrowser();
    this.device = Object.assign({}, this._ua.getDevice(), device);
    this.ua = this._ua.getUA();
    this.os = this._ua.getOS();
    this.installationId = getCookie(this.installationIdCookieName);
  }

  getReactAppVersion() {
    return this.appVersion || `99999.0.0-${this.appEnv}`;
  }

  getCodeVersion() {
    return process.env.REACT_APP_SLUG_COMMIT;
  }

  getInstallData() {
    return {
      locale: window.navigator && window.navigator.language,
      appName: this.appHost,
      os: [this.os.name, this.os.version].filter((i) => i).join(' '),
      device: [this.device.type, this.device.vendor, this.device.model].filter((i) => i).join(' '),
      appsflyerId: 'web',
      // @ts-ignore
      parseVersion: Parse.CoreManager.get('VERSION') as string
    };
  }

  isMobileDevice() {
    return ['desktop', 'unknown'].indexOf(this.device.type) === -1;
  }

  isSnapshot() {
    return /reactsnap/i.test(navigator.userAgent);
  }

  getChromeExtVersion() {
    return window.document.body.dataset.invoicesimpleExt;
  }
}

export default new EnvironmentStore();
