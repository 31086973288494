// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb

/**
 * Accepts both #FFF {3 character} and #FFFFFF {6 character} Hexidecimal strings
 * @returns an array of numbers of RGB e.g. [0, 0, 0],
 * If no string is passed or an invalid string is passed,
 * we return an empty array.
 */
export const hexToRgb = (hex): number[] => {
  if (!hex) return [];
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m: any, r: string, g: string, b: string) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x: string) => parseInt(x, 16));
};
