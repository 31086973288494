import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { startPaypalOnboarding } from '../../utils/apis/paypal.api';
import { PaymentEventName, PaymentEvent } from '../../types';
import { PaymentSetupButton } from '../PaymentBasicElements';

import './PaypalPromotionBox.scss';
import { messages } from './messages';
import { onError } from 'src/payments/utils/utils';

interface Props {
  onTrackEvent: (eventName: PaymentEvent, metadata?: Record<string, number | string>) => void;
}

export const PaypalPendingBox = ({ onTrackEvent }: Props) => {
  const [loadingUrl, setLoadingUrl] = useState(false);

  const goToPaypalContinueSetup = async () => {
    try {
      setLoadingUrl(true);
      onTrackEvent(PaymentEventName.onboardingContinue);
      await startPaypalOnboarding();
    } catch (error) {
      onError({ title: 'Error loading PayPal onboarding', body: error.message });
    } finally {
      setLoadingUrl(false);
    }
  };

  return (
    <div className="row">
      <p className="text-body">
        <FormattedMessage {...messages.pendingBody} />
      </p>
      <p className="text-note">
        <FormattedMessage {...messages.pendingNote} />
      </p>
      <PaymentSetupButton
        className="col-9 pending-onboarded-button"
        buttonText={<FormattedMessage {...messages.pendingButton} />}
        buttonBackgroundColor="orange"
        loading={loadingUrl}
        onButtonClick={goToPaypalContinueSetup}
      />
    </div>
  );
};
