import React from 'react';
import { observer } from 'mobx-react';
import { compose } from 'src/util/functions';
import { injectIntl, defineMessages } from 'src/i18n';
import { FormattedMessage } from 'react-intl';
import './Content.css';

const docUrl = 'https://www.invoicesimple.com/receipt-template';
const contactUrl = 'https://www.invoicesimple.com/contact';
const featureUrl = 'https://www.invoicesimple.com/features-invoicing-billing-tools';

const messages = defineMessages({
  header: {
    id: 'seo.receipt.header',
    defaultMessage: 'Receipt Maker'
  },
  description: {
    id: 'seo.receipt.description',
    defaultMessage:
      'Everyone knows what a receipt is — but often small businesses can get tripped up on how to write a receipt. Here is the why, what and how of writing receipts using Invoice Simple.'
  },
  link0: {
    id: 'seo.receipt.link.0',
    defaultMessage: 'Why use a receipt maker'
  },
  link1: {
    id: 'seo.receipt.link.1',
    defaultMessage: 'What to put on a receipt'
  },
  link2: {
    id: 'seo.receipt.link.2',
    defaultMessage: 'Why use our receipt maker'
  },
  link3: {
    id: 'seo.receipt.link.3',
    defaultMessage: 'Receipt maker alternatives'
  },
  link4: {
    id: 'seo.receipt.link.4',
    defaultMessage: 'Downloadable receipt templates'
  },
  link5: {
    id: 'seo.receipt.link.5',
    defaultMessage: 'Receipt maker FAQ'
  },

  // feature
  featureHeader: {
    id: 'seo.receipt.feature.header',
    defaultMessage: 'Benefits of using a receipt maker'
  },
  featureBody: {
    id: 'seo.receipt.feature.body',
    defaultMessage:
      "Instead of working hard, we should work smart! The smart thing is to use the right tool for the job, a receipt maker! When you use Invoice Simple's receipt maker you save yourself a lot of time and effort. Here are some of the reasons why:"
  },
  featureItem0: {
    id: 'seo.receipt.feature.item.0',
    defaultMessage:
      "The app saves your clients and items, having them ready for use the next time you're making a receipt"
  },
  featureItem1: {
    id: 'seo.receipt.feature.item.1',
    defaultMessage: 'You can easily change receipts to invoices with a single click'
  },
  featureItem2: {
    id: 'seo.receipt.feature.item.2',
    defaultMessage: 'Organize your receipts without needing to put in any additional time in'
  },
  featureItem3: {
    id: 'seo.receipt.feature.item.3',
    defaultMessage:
      "Use a professionally designed receipt template that's compatible with printers and mobile devices"
  },
  featureItem4: {
    id: 'seo.receipt.feature.item.4',
    defaultMessage: 'See an overview of your business at a glance, without any effort'
  },
  featureItem5: {
    id: 'seo.receipt.feature.item.5',
    defaultMessage: 'Know when customers receive your receipts'
  },
  featureItem6: {
    id: 'seo.receipt.feature.item.6',
    defaultMessage: 'Get notified when customers view your receipt'
  },
  featureItem7: {
    id: 'seo.receipt.feature.item.7',
    defaultMessage:
      'Present a professional demeanour by showing that you use the right tool for each job'
  },
  featureItemLink: {
    id: 'seo.receipt.feature.item.link',
    defaultMessage: 'and <link>many more amazing benefits</link>',
    values: {
      link: (chunks) => <a href={featureUrl}>{chunks}</a>
    }
  },

  // how to
  howtoHeader: {
    id: 'seo.receipt.howto.header',
    defaultMessage: 'How to Write a Receipt'
  },
  howtoDescription: {
    id: 'seo.receipt.howto.description',
    defaultMessage:
      'You’ve already seen how the receipt maker can save you time and keep you organized. Writing a receipt using our online generator couldn’t be easier.'
  },
  howtoGuideDescription: {
    id: 'seo.receipt.howto.guide.description',
    defaultMessage: 'There are just five steps to writing a receipt with Invoice Simple:'
  },
  howtoGuideStep1: {
    id: 'seo.receipt.howto.guide.step1',
    defaultMessage: 'Add in your company details (name, address) in From section'
  },
  howtoGuideStep2: {
    id: 'seo.receipt.howto.guide.step2',
    defaultMessage:
      'Fill out your client’s details in the <b>For</b> section, including name, email, and address',
    values: { b: (chunks) => <code>{chunks}</code> }
  },
  howtoGuideStep3: {
    id: 'seo.receipt.howto.guide.step3',
    defaultMessage: 'Write out line items with description, rate and quantity'
  },
  howtoGuideStep4: {
    id: 'seo.receipt.howto.guide.step4',
    defaultMessage: 'Finish with the date, invoice number and your personalized brand'
  },
  howtoGuideStep5: {
    id: 'seo.receipt.howto.guide.step5',
    defaultMessage: 'Either download a PDF or email the receipt to your customers directly.'
  },

  // special
  specialQuestion: {
    id: 'seo.receipt.special.question',
    defaultMessage: "What's special about our free receipt template?"
  },
  specialAnswer: {
    id: 'seo.receipt.special.answer',
    defaultMessage:
      "Firstly, our receipt template has been designed and tweaked by expert user experience designers and graphic designers. People who are paid to make products easy to use and beautiful. This means your receipts will look great on mobile, desktop and paper. We've worked on making sure that receipts are always easy to read. This is done by removing any section that's not filled in from the final receipt PDF, as well as keeping the colorized elements minimal."
  },

  // alt
  alternativeHeader: {
    id: 'seo.receipt.alternative.header',
    defaultMessage: 'Alternatives to using a receipt maker'
  },
  alternativeBody: {
    id: 'seo.receipt.alternative.body',
    defaultMessage:
      "You may have heard about using a simple excel or word <link>receipt template</link> to make your receipts. Using those applications is fine at the start but when you get to making your second or third receipt you'll realize that you're wasting a lot of your time on repetitive tasks. For example:",
    values: {
      link: (chunks) => <a href={docUrl}>{chunks}</a>
    }
  },
  alternativeItem0: {
    id: 'seo.receipt.alternative.item.0',
    defaultMessage:
      'You need to duplicate and rename your original file every time you want to make a new receipt.'
  },
  alternativeItem1: {
    id: 'seo.receipt.alternative.item.1',
    defaultMessage:
      "There will be moments of intense frustration! Since Word and Excel aren't made for designing these sorts of documents and they will refuse to do what you want them to do."
  },
  alternativeItem2: {
    id: 'seo.receipt.alternative.item.2',
    defaultMessage:
      "You end up re-typing client and item details even though you've sent them a receipt before."
  },
  alternativeItem3: {
    id: 'seo.receipt.alternative.item.3',
    defaultMessage: "It's a hassle to organize and find old receipts."
  },
  alternativeItem4: {
    id: 'seo.receipt.alternative.item.4',
    defaultMessage:
      "If you want to know how much you've billed over a certain period you'll have to open up every single file and manually add up the totals."
  },
  alternativeItem5: {
    id: 'seo.receipt.alternative.item.5',
    defaultMessage:
      'Turning an receipt into an invoice requires several additional steps (duplication of file, renaming titles, updating dates...)'
  },
  alternativeItem6: {
    id: 'seo.receipt.alternative.item.6',
    defaultMessage:
      "This is time you spend doing administrative work. Time that you're not being paid by anyone to do. My guess is you didn't start your own business to do administrative work."
  },

  // template
  templateQuestion: {
    id: 'seo.receipt.template.question',
    defaultMessage: 'More receipt templates'
  },
  templateAnswer: {
    id: 'seo.receipt.template.answer',
    defaultMessage:
      "If at the end of the day you still feel like the receipt maker isn't for you, you can always fall back onto traditional methods. We've created a selection of commonly used <docLink>free receipt templates</docLink> to help with that. Any questions or comments? Feel free to <contactLink>reach out to us</contactLink>.",
    values: {
      docLink: (chunks) => <a href={docUrl}>{chunks}</a>,
      contactLink: (chunks) => <a href={contactUrl}>{chunks}</a>
    }
  },

  // faq
  faqHeader: {
    id: 'seo.receipt.faq.header',
    defaultMessage: 'Frequently asked questions'
  },
  faqItem0Q: {
    id: 'seo.receipt.faq.question.0',
    defaultMessage: 'Can I put my own logo on the receipt template?'
  },
  faqItem0A: {
    id: 'seo.receipt.faq.answer.0',
    defaultMessage:
      'Yes. Click on the logo box in the top right corner. From there, you can either upload the logo for your receipt template, or simply drag and drop it into place. JPEG and PNG images are supported.'
  },
  faqItem1Q: {
    id: 'seo.receipt.faq.question.1',
    defaultMessage: 'How do I send a receipt?'
  },
  faqItem1A: {
    id: 'seo.receipt.faq.answer.1',
    defaultMessage:
      'You can email your receipt, download a PDF copy, or send your receipt as a link, all using our receipt maker. If you email your receipt directly from Invoice Simple, we’ll notify you when your customer views the receipt — useful for following up with them later. If you send a link to your customer, they can download or print the receipt from the link.'
  },
  faqItem2Q: {
    id: 'seo.receipt.faq.question.2',
    defaultMessage: "Will my clients see 'Invoice Simple' on my receipt?"
  },
  faqItem2A: {
    id: 'seo.receipt.faq.answer.2',
    defaultMessage:
      'Never. Regardless of whether you have a registered account or not, your generated receipts are always 100% unbranded.'
  },
  faqItem3Q: {
    id: 'seo.receipt.faq.question.3',
    defaultMessage: 'Can I generate a PDF receipt?'
  },
  faqItem3A: {
    id: 'seo.receipt.faq.answer.3',
    defaultMessage:
      'Absolutely. The PDF button at the top of the receipt lets you instantly download a PDF receipt that you can save for your records or send to your customer.'
  },
  faqItem4Q: {
    id: 'seo.receipt.faq.question.4',
    defaultMessage: 'Can I change the currency?'
  },
  faqItem4A: {
    id: 'seo.receipt.faq.answer.4',
    defaultMessage:
      'Yes. Our receipt maker should detect your currency automatically. However, if you want to make an receipt in a different currency, you can easily change currencies using the Settings panel on the right.'
  },
  faqItem5Q: {
    id: 'seo.receipt.faq.question.5',
    defaultMessage: 'Can I save my receipts and customer details?'
  },
  faqItem5A: {
    id: 'seo.receipt.faq.answer.5',
    defaultMessage:
      'Yes. Your receipts are automatically saved to your receipts list. There is also a clients list where customer information is automatically saved to.'
  },
  faqItem6Q: {
    id: 'seo.receipt.faq.question.6',
    defaultMessage: 'As a freelancer, how do I make a receipt for time?'
  },
  faqItem6A: {
    id: 'seo.receipt.faq.answer.6',
    defaultMessage:
      'What works well is to put your hours into the quantity field and your hourly rate into the price field. The receipt maker will then calculate the amount for you.'
  },
  faqItem8Q: {
    id: 'seo.receipt.faq.question.8',
    defaultMessage: 'How do I charge taxes using the receipt maker?'
  },
  faqItem8A: {
    id: 'seo.receipt.faq.answer.8',
    defaultMessage:
      "If you'd like your receipt to include taxes on top of your prices, use the Settings pane on the right to enable taxes. You can also enter a tax rate and label."
  },
  faqItem9Q: {
    id: 'seo.receipt.faq.question.9',
    defaultMessage: 'What about line items that already have tax applied?'
  },
  faqItem9A: {
    id: 'seo.receipt.faq.answer.9',
    defaultMessage:
      "If your prices already include tax, and you'd like your receipt to show the amount of tax included, then select the Inclusive? checkbox when enabling taxes."
  }
});

const ReceiptSeoContent = () => {
  const featureList = [0, 1, 2, 3, 4, 5, 6, 7].map((i) => {
    const item = messages[`featureItem${i}`];
    return (
      <li key={`feature-${i}`}>
        <FormattedMessage {...item} />
      </li>
    );
  });

  const howtoStepsList = [
    messages.howtoGuideStep1,
    messages.howtoGuideStep2,
    messages.howtoGuideStep3,
    messages.howtoGuideStep4,
    messages.howtoGuideStep5
  ].map((step, i) => (
    <li key={`howto-step-${i}`}>
      <FormattedMessage {...step} />
    </li>
  ));

  const alternativeList = [0, 1, 2, 3, 4, 5, 6].map((i) => {
    const item = messages[`alternativeItem${i}`];
    return (
      <li key={`alternative-${i}`}>
        <FormattedMessage {...item} />
      </li>
    );
  });

  const faqList = [0, 1, 2, 3, 4, 5, 6, 8, 9].map((i) => {
    const question = messages[`faqItem${i}Q`];
    const answer = messages[`faqItem${i}A`];
    return (
      <li key={`faq-${i}`}>
        <h4>
          <FormattedMessage {...question} />
        </h4>
        <p>
          <FormattedMessage {...answer} />
        </p>
      </li>
    );
  });

  return (
    <div id="seo-content" className="d-print-none seo-content">
      <div className="container d-print-none">
        <div className="col-md">
          <div>
            <h1 id="seoTitle">
              <FormattedMessage {...messages.header} />
            </h1>
            <p>
              <FormattedMessage {...messages.description} />
            </p>
            {/* links */}
            <ul>
              <li>
                <a href="#receipt-maker-features">
                  <FormattedMessage {...messages.link0} />
                </a>
              </li>
              <li>
                <a href="#receipt-maker-howto">
                  <FormattedMessage {...messages.link1} />
                </a>
              </li>
              <li>
                <a href="#receipt-maker-special">
                  <FormattedMessage {...messages.link2} />
                </a>
              </li>
              <li>
                <a href="#receipt-maker-alternatives">
                  <FormattedMessage {...messages.link3} />
                </a>
              </li>
              <li>
                <a href="#receipt-maker-templates">
                  <FormattedMessage {...messages.link4} />
                </a>
              </li>
              <li>
                <a href="#receipt-maker-faq">
                  <FormattedMessage {...messages.link5} />
                </a>
              </li>
            </ul>

            {/* features */}
            <section id="receipt-maker-features">
              <h2>
                <FormattedMessage {...messages.featureHeader} />
              </h2>
              <p>
                <FormattedMessage {...messages.featureBody} />
              </p>
              <ul>
                {featureList}
                <li>
                  <FormattedMessage {...messages.featureItemLink} />
                </li>
              </ul>
            </section>

            {/* how to */}
            <section id="receipt-maker-howto">
              <h2>
                <FormattedMessage {...messages.howtoHeader} />
              </h2>
              <p>
                <FormattedMessage {...messages.howtoDescription} />
              </p>
              <p>
                <FormattedMessage {...messages.howtoGuideDescription} />
              </p>
              <ol>{howtoStepsList}</ol>
            </section>

            {/* special */}
            <section id="receipt-maker-special">
              <h2>
                <FormattedMessage {...messages.specialQuestion} />
              </h2>
              <p>
                <FormattedMessage {...messages.specialAnswer} />
              </p>
            </section>

            {/* alternatives */}
            <section id="receipt-maker-alternatives">
              <h2>
                <FormattedMessage {...messages.alternativeHeader} />
              </h2>
              <p>
                <FormattedMessage {...messages.alternativeBody} />
              </p>
              <ul>{alternativeList}</ul>
            </section>

            {/* templates */}
            <section id="receipt-maker-templates">
              <h2>
                <FormattedMessage {...messages.templateQuestion} />
              </h2>
              <p>
                <FormattedMessage {...messages.templateAnswer} />
              </p>
            </section>

            {/* faq */}
            <section id="receipt-maker-faq">
              <h2>
                <FormattedMessage {...messages.faqHeader} />
              </h2>
              <ul className="list-columns">{faqList}</ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(injectIntl, observer)(ReceiptSeoContent);
