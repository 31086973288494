import React from 'react';

export const FeatureBox: React.FC<{ featureText: string }> = ({ featureText }) => {
  return (
    <div
      className="bg-white px-3 py-2.5 rounded-2xl leading-5 text-xl text-center"
      style={{
        border: '1px dotted #97A8FF'
      }}>
      {featureText}
    </div>
  );
};
