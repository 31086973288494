import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import { Loading } from 'src/components/Loading';
import { useISIntl } from 'src/i18n/utils';
import { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { SubscriptionUpgradeType } from 'src/types/Subscription';
import { PlanIntervalSelector } from '../SubscriptionPaywall/components/PlanIntervalSelector';
import { BackToInvoiceList } from './BackToInvoiceList';
import { UpgradeSection } from './UpgradeSection';

import '../../styles/tailwind-legacy.css';
import './Upgrade.scss';

import { FeatureName } from '@invoice-simple/feature-gate';

import {
  getPaywallVariant,
  getReasonForUpgrade,
  getTierRequired
} from '../SubscriptionPaywall/utils';
import { messages } from './messages';

interface Props {
  store: AppStore;
  onError: (error: { title?: string; body?: string }) => void;
  onLoad: () => void;
}

const PremiumUpgrade = ({ store, onError, onLoad }: Props) => {
  const [selectedInterval, setSelectedInterval] = useState<Cadence>(Cadence.MONTHLY);
  const feature = new URLSearchParams(window.location.search).get('feature') as FeatureName;
  const tierRequired = getTierRequired(getReasonForUpgrade(feature, store.user));
  const currentTier = store.user.activeSubscriptionTier;
  const paywallVariant = getPaywallVariant(currentTier, tierRequired);
  useEffect(() => {
    onLoad();
  }, []);
  const intl = useISIntl();
  const { formatMessage: f } = intl;

  if (!store.user.isLoaded) return <Loading />;

  const user = store.user;

  const shouldShowUpgradeSection =
    user.isSubPaid && !!user.lastPaidWebSub && !user.isLegacySubscriber;

  const shouldShowIntervalSelector =
    shouldShowUpgradeSection && !user.isGuest && !user.isSubWebMobile;

  const onToggleInterval = (interval: Cadence) => {
    if (selectedInterval !== interval) {
      setSelectedInterval(interval);
    }
  };

  return (
    <div id="tailwind" className="upgrade-section">
      <Helmet>
        <title itemProp="name">{f(messages.premiumTitle)}</title>
        <meta name="description" content={f(messages.premiumDescription)} />
      </Helmet>

      <div className="py-16 px-4 sm:px-6 lg:px-8">
        {shouldShowIntervalSelector && (
          <div className="w-100 d-flex justify-center mb-5">
            <PlanIntervalSelector
              selectedInterval={selectedInterval}
              onToggleInterval={onToggleInterval}
              formatMessage={f}
              style={'font-semibold'}
              selectStyle={'bg-gray-200'}
              isHidden={user.hasAnnualSub && paywallVariant !== 'default'}
            />
          </div>
        )}
        <div>
          {shouldShowUpgradeSection ? (
            <UpgradeSection
              store={store}
              subscription={user.lastPaidWebSub}
              onError={onError}
              upgradeParams={{
                type: SubscriptionUpgradeType.PREMIUM,
                planInterval: selectedInterval
              }}
            />
          ) : (
            <div className="bg-white max-w-7xl mx-auto text-center rounded-lg shadow-lg py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
              <BackToInvoiceList store={store}>{f(messages.premiumIneligible)}</BackToInvoiceList>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(PremiumUpgrade);
