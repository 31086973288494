import React from 'react';
import { observer } from 'mobx-react';
import InvoicePaymentModel from '../models/InvoicePaymentModel';
import { CurrencyDisplay } from './Currency';
import { ConfirmDeletePayment } from './ConfirmDeleteButton';
import './InvoicePaymentRow.css';
import { LocalizedDate } from './Localized/LocalizedDate';

interface Props {
  payment: InvoicePaymentModel;
  loading: boolean;
  onDelete: () => void;
}

export const InvoicePaymentRow = observer((props: Props) => {
  const handlePending = () => {
    props.payment.toggleDelete();
  };
  const handleConfirm = () => {
    props.payment.toggleDeleted();
    props.onDelete();
  };
  const payment = props.payment;
  const rowClass = `payment-row ${payment.delete ? 'payment-row-delete' : ''}`;
  const paymentMethod = payment.isMethodOther
    ? 'Payment'
    : payment.isMethodBank
    ? payment.paymentMethodLabel
    : `${payment.paymentMethodLabel} payment`;
  const paymentMessage = (
    <span>
      {paymentMethod} received
      {payment.date && payment.date instanceof Date && (
        <>
          &nbsp;on <LocalizedDate date={payment.date} />
        </>
      )}
    </span>
  );

  return (
    <tr className={rowClass}>
      <td className="payment-row-actions">
        <ConfirmDeletePayment
          disabled={props.loading}
          pending={payment.delete}
          confirmed={payment.deleted}
          onPending={handlePending}
          onConfirm={handleConfirm}
          displayIcon={true}
        />
      </td>
      <td className="payment-row-summary">
        <span className="payment-row-date">{paymentMessage}</span>
        <span className="payment-row-notes">{payment.notes}</span>
      </td>
      <td className="payment-row-amount">
        <CurrencyDisplay value={payment.amount} />
      </td>
      <td className="payment-row-padding">&nbsp;</td>
    </tr>
  );
});
