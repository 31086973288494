import React from 'react';
import classNames from 'classnames';

import { ColumnProps } from './Table.types';

interface Props<TData extends object> {
  onRowClick?: (row: TData) => unknown | void;
  rowKey: keyof TData;
  row: TData;
  columns: ColumnProps<TData>[];
  /**
   * Would be applied per row
   */
  afterEach?: (row: TData) => React.ReactNode | React.ReactNode | string;
}

export function Row<TData extends object>({
  onRowClick,
  rowKey,
  row,
  columns,
  afterEach
}: Props<TData>) {
  return (
    <tr
      {...(onRowClick
        ? {
            onClick: () => {
              onRowClick?.(row);
            }
          }
        : {})}>
      {columns.map((column) => (
        <td
          key={`table-row-column-${column.columnName.replace(/\s+/g, '_')}-${String(
            column.columnKey
          )}__${row[rowKey]}`}
          className={classNames(column.className, {
            'text-right': column.alignText === 'right'
          })}>
          {column.render ? column.render(row, column.columnKey) : <>{row[column.columnKey]}</>}
        </td>
      ))}

      {afterEach && <td>{afterEach(row)}</td>}
    </tr>
  );
}
