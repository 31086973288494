import React, { Component } from 'react';
import TextInput from './TextInput';
import { observer } from 'mobx-react';
import InvoiceNoModel from '../models/InvoiceNoModel';

interface Props {
  name: string;
  placeholder: string;
  disabled?: boolean;
  label?: string;
  errorMessage?: string;
  source: any;
  fieldName: string;
}

@observer
class DocNumberInput extends Component<Props, any> {
  invoiceNumberModel: any;

  constructor(props) {
    super(props);
    const { source, fieldName } = props;
    this.invoiceNumberModel = new InvoiceNoModel(source, fieldName);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { source, fieldName } = newProps;
    this.invoiceNumberModel = new InvoiceNoModel(source, fieldName);
  }

  render() {
    const { source, fieldName, ...rest } = this.props;

    return (
      <TextInput
        maxLength={16}
        value={this.invoiceNumberModel.value}
        errorMessage={this.invoiceNumberModel.invoiceNumberValidationError}
        onChange={this.invoiceNumberModel.setValue}
        {...rest}
      />
    );
  }
}
export default DocNumberInput;
