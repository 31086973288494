import { DocType } from '@invoice-simple/common';
import { Document } from '@invoice-simple/domain-invoicing';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import UserModel from 'src/models/UserModel';
import { buildUrl } from '../helpers';

export interface PaginatedDocumentsResponse {
  documents: Document[];
  hasNextPage: boolean;
  prev: string;
  next: string;
}

interface QueryError {
  message: string;
}

export interface PaginatedDocsParams {
  docType?: DocType;
  dir?: 'asc' | 'desc';
  sortBy?: 'invoiceDate';
  limit?: number;
  state?: 'paid' | 'open';
  url?: string;
}

export const fetchDocs = async ({
  queryKey
}: QueryFunctionContext): Promise<PaginatedDocumentsResponse> => {
  const { headers } = UserModel.getInstance().getApiReqOpts();

  // Not using _key here
  const [_key, { docType, dir, sortBy, limit, state, url }] = queryKey as any;

  try {
    const { data } = await axios.get<PaginatedDocumentsResponse>(
      buildUrl({ docType, dir, sortBy, limit, state, url }),
      {
        headers
      }
    );

    return data;
  } catch (error) {
    // Error is handled by react query
    // (error, isError, errorUpdatingAt, loadingError)
    throw new Error(error);
  }
};

export const usePaginatedDocuments = ({
  docType,
  dir,
  sortBy,
  limit,
  state,
  url
}: PaginatedDocsParams) => {
  return useQuery<PaginatedDocumentsResponse, QueryError>(
    ['documents', { docType, dir, sortBy, limit, state, url }],
    fetchDocs
  );
};
