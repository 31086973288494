import { observer } from 'mobx-react';
import React from 'react';
import { Selectv1Adapter } from 'src/components/Selectv1Adapter';
import currencyOptions, { CurrencyOption } from 'src/data/currencyOptions';
import './CurrencySelect.scss';

const CurrencyOptionRenderer = (v: CurrencyOption) => (
  <div className="invoice-currency-option" title={v.value}>
    <div>
      <span className="currency-select-code">{v.value}</span>
    </div>
    <div className="currency-flag">
      <span className="currency-select-symbol">{v.label}</span>
      {v.country && (
        <img className="currency-select-flag" src={v.imageUrl} role="presentation" alt="flag" />
      )}
    </div>
  </div>
);

const CurrencySelect = (props) => {
  const styles = {
    singleValue: (provided: React.CSSProperties): React.CSSProperties => ({
      ...provided,
      width: '100%',
      paddingRight: '1.5rem',
      cursor: 'pointer',
      margin: 0
    }),
    dropdownIndicator: (): React.CSSProperties => ({
      display: 'none'
    })
  };
  return (
    <Selectv1Adapter
      className="currency-select"
      disabled={props.isLoading}
      placeholder="Select Currency"
      isClearable={false}
      value={props.settingList.currencyCode}
      options={currencyOptions}
      valueRenderer={CurrencyOptionRenderer}
      optionRenderer={CurrencyOptionRenderer}
      styles={styles}
      {...props}
    />
  );
};

export default observer(CurrencySelect);
