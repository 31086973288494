import { defineMessages } from 'src/i18n';

export const messages = defineMessages({
  title: {
    id: 'invoice.list.title',
    defaultMessage: '{pluralDocType}'
  },
  search: {
    id: 'invoice.list.search.placeholder',
    defaultMessage: 'Search by Client Name'
  },
  sortByInvoice: {
    id: 'invoice.list.sort.invoice',
    defaultMessage: '{docType}'
  },
  description: {
    id: 'invoice.list.description',
    defaultMessage:
      'All your {pluralDocType} in one place! Invoice Simple keeps track of payments and the outstanding balance for all your {pluralDocType}.'
  },
  filterAll: {
    id: 'invoice.list.filter.all',
    defaultMessage: 'All {pluralDocType}'
  },
  filterUnpaid: {
    id: 'invoice.list.filter.unpaid',
    defaultMessage: 'Outstanding'
  },
  filterPaid: {
    id: 'invoice.list.filter.paid',
    defaultMessage: 'Paid'
  },
  buttonNew: {
    id: 'invoice.list.buttons.new',
    defaultMessage: 'new {docType}'
  },
  rowLoading: {
    id: 'invoice.list.row.loading',
    defaultMessage: 'loading your {pluralDocType}...'
  },
  tableHeadClient: {
    id: 'invoice.list.table.head.client',
    defaultMessage: 'Client'
  },
  tableHeadDate: {
    id: 'invoice.list.table.head.date',
    defaultMessage: 'Date'
  },
  tableHeadTotal: {
    id: 'invoice.list.table.head.total',
    defaultMessage: 'Total'
  },
  tableHeadBalance: {
    id: 'invoice.list.table.head.balance',
    defaultMessage: 'Balance Due'
  },
  tableFooterHint: {
    id: 'invoice.list.table.footer.hint',
    defaultMessage: `All your {pluralDocType} are auto saved here`
  },
  purchaseGratitude: {
    id: 'invoice.list.purchase.gratitude',
    defaultMessage: 'Thank you for your purchase!'
  },
  support: {
    id: 'signup.alert.success.body',
    defaultMessage: 'If you need any help please contact us: support@invoicesimple.com'
  },
  transferErrorTitle: {
    id: 'invoice.list.purchase.alert.transferError.title',
    defaultMessage: 'There was an error saving your subscription.'
  },
  transferErrorBody: {
    id: 'invoice.list.purchase.alert.transferError.body',
    defaultMessage: 'Please reach out to Customer Support for assistance.'
  }
});
