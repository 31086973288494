import { useEffect, useState } from 'react';

import flagsmith from 'flagsmith';
import { useISIntl } from 'src/i18n';
import UserModel from 'src/models/UserModel';

import { DocLimitBannerConfig, getBannerConfig } from './utils';

export const useDocLimitBanner = (): DocLimitBannerConfig | null => {
  const [config, setConfig] = useState<DocLimitBannerConfig | null>(null);

  const user = UserModel.getInstance();
  const intl = useISIntl();

  useEffect(() => {
    const abTestEnabled = flagsmith.hasFeature('doc_limit_messaging');
    if (!user.isLoaded || !abTestEnabled) return;

    if (user.isInFreeMode && !user.hadPaidSubsInThePast) {
      setConfig(getBannerConfig(user.docsRemaining, intl));
    }
  }, [user.isLoaded, user.docsRemaining, user.isSubPaid]);

  return config;
};
