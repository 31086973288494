import repeat from 'lodash/repeat';

const incrementWithPaddingZeros = (input: string) => {
  const newInterger = parseInt(input, 10) + 1;
  const delta = input.length - `${newInterger}`.length;
  return delta > 0 ? repeat('0', delta) + `${newInterger}` : newInterger;
};

const getElements = (input: string) => {
  let prefix;
  let suffix;
  const endsWithDigits = input.match(/(.*?)(\d+)$/);
  if (endsWithDigits) {
    prefix = endsWithDigits[1];
    suffix = endsWithDigits[2];
  } else {
    prefix = input;
  }
  if (!suffix || isNaN(suffix)) {
    suffix = 0;
  }
  return { prefix, suffix };
};

/**
 * return next padded invoice/estimate number
 */
const NextNumber = (last?: string) => {
  if (!last) {
    last = '';
  }
  const { prefix, suffix } = getElements(last);
  return `${prefix}${incrementWithPaddingZeros(suffix)}`;
};

export default NextNumber;
