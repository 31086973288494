import React from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { LoadingSpinner } from 'src/components/Button';
import { getSubscriptionBadgeDiscount, messages } from 'src/components/SubscriptionPaywall/utils';
import { useISIntl } from 'src/i18n';
import { CardFeatureList } from './CardFeatureList';
import { CardHeader } from './CardHeader';
import { CardPrice } from './CardPrice';
import { PopularHeader } from './PopularHeader';

export const TierCard = ({
  tier,
  handleSubscriptionPurchase,
  loading,
  selectedTier,
  selectedInterval
}) => {
  const { ft } = useISIntl();

  const isPlusTier = tier.name === SubscriptionTier.PLUS;

  const subscriptionBadgeDiscount = getSubscriptionBadgeDiscount(tier.name, selectedInterval);

  return (
    <div
      data-testid={`desktop-${tier.name}-tier-card`}
      className={`max-w-[290px] rounded-[30px] ${isPlusTier && 'bg-orange-50'}`}>
      {isPlusTier && <PopularHeader />}

      <div
        className={`p-[20px] ${!isPlusTier && 'rounded-t-[30px]'}`}
        style={{
          border: `2px solid ${isPlusTier ? '#FED7AA' : '#E5E7EB'}`,
          borderTopWidth: isPlusTier ? '0px' : '2px'
        }}>
        <CardHeader
          name={tier.name}
          description={tier.description}
          isPlusTier={isPlusTier}
          subscriptionBadgeDiscount={subscriptionBadgeDiscount}
        />

        <CardPrice name={tier.name} selectedInterval={selectedInterval} />

        <button
          disabled={loading}
          onClick={() => handleSubscriptionPurchase(tier.name)}
          type="button"
          data-testid={`desktop-${tier.name}-buy-button`}
          className="mt-8 mb-1 no-underline block w-full flex-grow rounded-[4px] border-[1px] border-solid py-3 text-center text-[14px] font-bold hover:opacity-70 bg-orange-is text-white border-orange-is">
          {loading && selectedTier === tier.name && (
            <LoadingSpinner loadingTextStyle="text-white" />
          )}
          {ft(messages.subscriptionBuyNowButton)}
        </button>
      </div>

      <CardFeatureList tier={tier} />
    </div>
  );
};
