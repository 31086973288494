import { IAccount } from '@invoice-simple/common';
import UserModel from 'src/models/UserModel';

export function isAbTestEnabled(abTests: IAccount['abtests'], testName: string): boolean {
  return !!abTests && !!abTests[testName];
}

/**
 * Removed because of https://app.asana.com/0/1200380663730997/1204278211343542
 * leaving implementation in case we need to re-enable
 */
export function isPoNumberEnabled(user: UserModel): boolean {
  return false;
}
