import React from 'react';
import { SVGProps } from 'react';

export const PhoneIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0.5 1.25C0.5 0.559644 1.05964 0 1.75 0H4.44109C5.05214 0 5.57363 0.441766 5.67408 1.0445L6.59822 6.58934C6.68844 7.13063 6.41507 7.66746 5.92425 7.91288L3.98907 8.88046C5.38445 12.3479 8.1521 15.1156 11.6195 16.5109L12.5871 14.5758C12.8325 14.0849 13.3694 13.8116 13.9107 13.9018L19.4555 14.8259C20.0582 14.9264 20.5 15.4479 20.5 16.0589V18.75C20.5 19.4404 19.9404 20 19.25 20H16.75C7.77537 20 0.5 12.7246 0.5 3.75V1.25Z"
        fill="#FC5B31"
      />
    </svg>
  );
};
