import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { PaymentEventLocation, PaymentPromoModal } from 'src/payments';
import { AppStore } from 'src/stores/AppStore';
import { compose } from 'src/util/functions';
import { getURLQueryParam } from 'src/util/url';
import {
  handleNewPurchaseFailure,
  handleNewPurchaseSuccess,
  handleNewSMSCampaignPurchase
} from './handleNewPurchase';
import { SubscriptionTrackerStore } from './SubTrackerProviderStore';
import { injectIntl, ISIntl } from 'src/i18n/utils';
import Rollbar from 'src/analytics/rollbar';

export const POST_PURCHASE_PATHS = ['/signup', '/login', '/invoices'];

export function _SubscriptionPurchaseTracker({ intl, store }: SubscriptionPurchaseTrackerProps) {
  const sessionId = getURLQueryParam('session_id');
  const success = getURLQueryParam('success') === 'true';
  const smsToken = getURLQueryParam('token');
  const formatMessage = intl.formatMessage;
  const user = store.user;

  const path = location.pathname;
  const isPostPurchasePath = POST_PURCHASE_PATHS.includes(path);

  useEffect(() => {
    if (!success || !sessionId || !isPostPurchasePath) {
      return;
    }

    // user purchased sub
    if (user.isSubPaid) {
      SubscriptionTrackerStore.isVerifying = true;

      handleNewPurchaseSuccess(sessionId, formatMessage, store).finally(() => {
        SubscriptionTrackerStore.isVerifying = false;
      });
    }

    // callback URL from stripe, but no sub created
    if (!user.isSubPaid) {
      if (smsToken) {
        SubscriptionTrackerStore.isVerifying = true;
        const smsCampaignData = { smsToken, formatMessage, store, sessionId, user };
        handleNewSMSCampaignPurchase(smsCampaignData)
          .catch((err) => Rollbar.trackError('SMS Campaign Purchase Error', err, smsCampaignData))
          .finally(() => {
            SubscriptionTrackerStore.isVerifying = false;
          });
      } else {
        handleNewPurchaseFailure(formatMessage);
      }
    }
  }, [user.isSubPaid, success, sessionId, smsToken, path]);

  return (
    <>
      {SubscriptionTrackerStore.showPaypalBox && (
        <PaymentPromoModal
          onTrackEventAndLocation={(eventName) => {
            store.user.trackAppEventViaApi(eventName, {
              location: PaymentEventLocation.subscriptionPurchase
            });
          }}
        />
      )}
    </>
  );
}

export const SubscriptionPurchaseTracker = compose(
  inject('store'),
  injectIntl,
  observer
)(_SubscriptionPurchaseTracker);

interface SubscriptionPurchaseTrackerProps {
  store: AppStore;
  intl: ISIntl;
}
