import React, { FC } from 'react';
import { LearnMoreModal } from '../../PaymentsModals/LearnMoreModal';
import { usePaymentOnboardModal } from 'src/payments/hooks/usePaymentsOnboardModal';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { PaymentEventComponent } from 'src/payments/types';
import { useCommonMetadata } from 'src/payments/hooks/useCommonMetadata';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';

const LearnMoreComponent: FC<{ store: AppStore }> = ({ store: { user } }) => {
  const { isOpen, showModal, hideModal } = usePaymentOnboardModal();

  const paymentsViewedMeta = {
    ...useCommonMetadata(),
    component: PaymentEventComponent.learnMore
  };

  const onClickLearnMore = () => {
    user.events.trackAction('payments-cta', {
      ...paymentsViewedMeta,
      'from-guest-account': !user.isSignedUp
    });
    showModal();
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <div id="tailwind" data-testid="learnMore">
      {isOpen && <LearnMoreModal hideModal={hideModal} />}
      <div className="text-justify text-md font-medium py-2">
        Take charge of how you get paid through our checkout partners.{' '}
        <button
          type="button"
          onClick={onClickLearnMore}
          className="bg-transparent border-none inline text-blue-600 hover:cursor-pointer hover:underline break-words p-0">
          Learn more
        </button>
      </div>
    </div>
  );
};

export const LearnMore = injectStore(LearnMoreComponent);
