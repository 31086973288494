import React from 'react';

export type DocumentIconProps = React.ComponentPropsWithRef<'svg'>;

export const DocumentIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4.76355C4 3.65898 4.89543 2.76355 6 2.76355H10.5858C11.1162 2.76355 11.6249 2.97426 12 3.34934L15.4142 6.76355C15.7893 7.13862 16 7.64733 16 8.17776V16.7635C16 17.8681 15.1046 18.7635 14 18.7635H6C4.89543 18.7635 4 17.8681 4 16.7635V4.76355ZM6 10.7635C6 10.2113 6.44772 9.76355 7 9.76355H13C13.5523 9.76355 14 10.2113 14 10.7635C14 11.3158 13.5523 11.7635 13 11.7635H7C6.44772 11.7635 6 11.3158 6 10.7635ZM7 13.7635C6.44772 13.7635 6 14.2113 6 14.7635C6 15.3158 6.44772 15.7635 7 15.7635H13C13.5523 15.7635 14 15.3158 14 14.7635C14 14.2113 13.5523 13.7635 13 13.7635H7Z"
      fill="#111827"
    />
  </svg>
);

DocumentIcon.displayName = 'DocumentIcon';
