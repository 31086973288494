import React from 'react';

export type SendIconProps = React.ComponentPropsWithRef<'svg'>;

export const SendIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none">
    <path
      d="M17.4472 11.658C17.786 11.4886 18 11.1423 18 10.7636C18 10.3848 17.786 10.0385 17.4472 9.86913L3.44721 2.86913C3.09251 2.69178 2.66653 2.74079 2.36136 2.99405C2.0562 3.24731 1.92953 3.65697 2.03848 4.03828L3.46704 9.03827C3.5897 9.46757 3.98209 9.76355 4.42857 9.76355H9C9.55229 9.76355 10 10.2113 10 10.7635C10 11.3158 9.55229 11.7635 9 11.7635H4.42857C3.98209 11.7635 3.58971 12.0595 3.46705 12.4888L2.03848 17.4888C1.92953 17.8701 2.0562 18.2798 2.36136 18.5331C2.66653 18.7863 3.09251 18.8353 3.44721 18.658L17.4472 11.658Z"
      fill="#111827"
    />
  </svg>
);

SendIcon.displayName = 'SendIcon';
