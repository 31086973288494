import React from 'react';

import { defineMessages, FormattedMessage } from 'react-intl';

import { getLocalePath, getLocaleScreenshotPath, getLocaleTitle } from 'src/i18n/enabledLocales';
import { ISIntl } from 'src/i18n/utils';

const messages = defineMessages({
  header: {
    id: 'seo.invoice.header',
    defaultMessage: 'Invoice Generator'
  },
  link0: {
    id: 'seo.invoice.link.0',
    defaultMessage: 'How to make an invoice'
  },
  link1: {
    id: 'seo.invoice.link.1',
    defaultMessage: 'How to send an invoice'
  },
  link2: {
    id: 'seo.invoice.link.2',
    defaultMessage: 'What to put on invoice'
  },
  link3: {
    id: 'seo.invoice.link.3',
    defaultMessage: 'Invoice generator alternatives'
  },
  link4: {
    id: 'seo.invoice.link.4',
    defaultMessage: 'Why use an invoice generator'
  },
  link5: {
    id: 'seo.invoice.link.5',
    defaultMessage: 'Invoice generator FAQ'
  },

  // how to make
  guide0Header: {
    id: 'seo.invoice.guide.0.header',
    defaultMessage: 'How to make an invoice using the invoice generator'
  },
  guide0Body: {
    id: 'seo.invoice.guide.0.body',
    defaultMessage:
      'These step by step instructions will show you how to create an invoice that includes all the standard elements of a professional invoice. Below, we’ll dive into how you can customize your invoice to be unique to your business.'
  },
  guide0Body2: {
    id: 'seo.invoice.guide.0.body2',
    defaultMessage:
      "The first time you create an invoice, you'll need to add some information to the blank invoice template."
  },
  guide0Item0: {
    id: 'seo.invoice.guide.0.item.0',
    defaultMessage:
      'Add your company details in the <b>From section</b>, including the name, phone number, and address',
    values: { b: (chunks) => <code>{chunks}</code> }
  },
  guide0Item1: {
    id: 'seo.invoice.guide.0.item.1',
    defaultMessage:
      'Fill out your client’s details in the <b>For</b> section, including name, email, and address',
    values: { b: (chunks) => <code>{chunks}</code> }
  },
  guide0Item2: {
    id: 'seo.invoice.guide.0.item.2',
    defaultMessage: 'Add each line item, along with a description, rate, and quantity'
  },
  guide0Item3: {
    id: 'seo.invoice.guide.0.item.3',
    defaultMessage: 'If applicable, enter the tax rate, type of currency, and discount amount'
  },
  guide0Item4: {
    id: 'seo.invoice.guide.0.item.4',
    defaultMessage: 'Write payment instructions and terms in <b>Notes</b> section',
    values: { b: (chunks) => <code>{chunks}</code> }
  },
  guide0Item5: {
    id: 'seo.invoice.guide.0.item.5',
    defaultMessage: 'Customize your invoice by adding a logo and selecting your brand color'
  },
  guide0Item6: {
    id: 'seo.invoice.guide.0.item.6',
    defaultMessage:
      'Click <code>Email Invoice</code> or <code>Download PDF</code> from the top of the page',
    values: { code: (chunks) => <code>{chunks}</code> }
  },
  guide0ImageAlt: {
    id: 'seo.invoice.guide.0.image.alt',
    defaultMessage: 'sample invoice from invoice generator'
  },

  // send
  guide1Header: {
    id: 'seo.invoice.guide.1.header',
    defaultMessage: 'How to send an invoice'
  },
  guide1Body1: {
    id: 'seo.invoice.guide.1.body1',
    defaultMessage:
      'Once your first invoice is complete, you’ll need to learn how to send it to your client. Our invoice generator gives you two easy options to send an invoice.'
  },
  guide1Body2: {
    id: 'seo.invoice.guide.1.body2',
    defaultMessage:
      'First, compose an email to your client. You can include a link to your invoice in the email or send them a PDF of the invoice as an attachment.  The client simply clicks on the link or the attachment to see the invoice.'
  },
  guide1Body3: {
    id: 'seo.invoice.guide.1.body3',
    defaultMessage:
      'Then, check in on the status of your sent invoice. If you email your invoice directly from Invoice Simple, you will receive a notification when your customer views the invoice. The notification is useful for following up with clients to confirm payment.'
  },

  // elements of invoice
  guide2Header: {
    id: 'seo.invoice.guide.2.header',
    defaultMessage: 'Essential elements of an invoice'
  },
  guide2Body: {
    id: 'seo.invoice.guide.2.body',
    defaultMessage:
      'There are 9 main elements of an invoice. Some of these elements are necessary, while others are extra or used only for customization.'
  },
  guide2ImageAlt: {
    id: 'seo.invoice.guide.2.image.alt',
    defaultMessage: 'nine main elements of an invoice, showing you how to make an invoice'
  },
  guide2Item0Header: {
    id: 'seo.invoice.guide.2.item.0.header',
    defaultMessage: 'Invoice header'
  },
  guide2Item0Body: {
    id: 'seo.invoice.guide.2.item.0.body',
    defaultMessage:
      'Headers should clearly state the purpose of a document. Our software allows you to generate estimates, invoices, and receipts. Including a header allows you and the client to tell them apart quickly. This will save you time when reviewing your records or completing taxes.<br></br>Note the image marked “Company Logo” to the right of the header. Here, you can add in your own logo for more professional estimates, invoices, and receipts. If your business does not have a logo, you can leave this section blank as long as the rest of the invoice is laid out properly.',
    values: { br: () => <br /> }
  },

  guide2Item1Header: {
    id: 'seo.invoice.guide.2.item.1.header',
    defaultMessage: 'Company name and details'
  },
  guide2Item1Body: {
    id: 'seo.invoice.guide.2.item.1.body',
    defaultMessage:
      'When creating an invoice, you should include the legal name and contact details of your business. This should include your address, phone number, and email address. Usually, company information is written near the top of the invoice to create a clear differentiation between the company providing the goods or services and the company receiving them.'
  },

  guide2Item2Header: {
    id: 'seo.invoice.guide.2.item.2.header',
    defaultMessage: 'Name and Details of Buyers'
  },
  guide2Item2Body: {
    id: 'seo.invoice.guide.2.item.2.body',
    defaultMessage:
      'Clearly state the Name of the customer so there is no confusion between the Purchaser and Buyer.'
  },

  guide2Item3Header: {
    id: 'seo.invoice.guide.2.item.3.header',
    defaultMessage: 'Invoice number'
  },
  guide2Item3Body: {
    id: 'seo.invoice.guide.2.item.3.body',
    defaultMessage:
      'Each invoice has a unique identifier called an invoice number. Invoice numbers can help keep track of multiple invoices. Invoice numbers can be formatted in various ways. These formats can include file numbers (INV0001), unique billing codes, or date-based purchase order numbers.<br></br>If you use Invoice Simple invoicing software to automatically manage your invoice number, the software will create a unique invoice number for each document. If you wish to use your own custom format for your invoice number, you can update each invoice number yourself each time you use the invoice generator.',
    values: { br: () => <br /> }
  },

  guide2Item4Header: {
    id: 'seo.invoice.guide.2.item.4.header',
    defaultMessage: 'Date'
  },
  guide2Item4Body: {
    id: 'seo.invoice.guide.2.item.4.body',
    defaultMessage:
      'It’s important to include the date the invoice is issued. This can clear up confusion when there are multiple invoices going to the same customer.'
  },

  guide2Item5Header: {
    id: 'seo.invoice.guide.2.item.5.header',
    defaultMessage: 'Description of goods or services rendered'
  },
  guide2Item5Body: {
    id: 'seo.invoice.guide.2.item.5.body',
    defaultMessage:
      'In box six, you should include separate line items for each good or service you are providing. Each row should include the following:<ul><li>Name of the goods or service provided.</li><li>Unit price or hourly rate.</li><li>Quantity or number of hours worked.</li></ul>',
    values: { ul: (chunks) => <ul>{chunks}</ul>, li: (chunks) => <li>{chunks}</li> }
  },

  guide2Item6Header: {
    id: 'seo.invoice.guide.2.item.6.header',
    defaultMessage: 'Itemized fees'
  },
  guide2Item6Body: {
    id: 'seo.invoice.guide.2.item.6.body',
    defaultMessage:
      'Add any tax, handling or additional fees that are additional to the goods or services provided. These should be listed out separately as line items as some companies use different internal budgets to cover these fees.'
  },

  guide2Item7Header: {
    id: 'seo.invoice.guide.2.item.7.header',
    defaultMessage: 'Amount Owed'
  },
  guide2Item7Body: {
    id: 'seo.invoice.guide.2.item.7.body',
    defaultMessage: 'It might be obvious, but always clearly show the total amount due.'
  },

  guide2Item8Header: {
    id: 'seo.invoice.guide.2.item.8.header',
    defaultMessage: 'Terms, Conditions and payment instructions'
  },
  guide2Item8Body: {
    id: 'seo.invoice.guide.2.item.8.body',
    defaultMessage:
      'The terms and conditions of payment on an invoice is the part most frequently overlooked. The standard payment terms can vary by industry, your company’s policy, or previous history with the customer.<br></br>It’s important to clearly state the consequences of either party breaking this agreement. These penalties can include late fees or additional charges.<br></br>You can also add your preferred payment method here, whether it’s cheque, cash, bank transfer, PayPal, or credit card. Then, add in the payment instructions. For example, write your account number and bank name if clients are supposed to pay via a direct transfer.',
    values: { br: () => <br /> }
  },

  // alt
  alternativeHeader: {
    id: 'seo.invoice.alternative.header',
    defaultMessage: 'Alternatives to using an Invoice Generator'
  },
  alternativeBody: {
    id: 'seo.invoice.alternative.body',
    defaultMessage:
      "You may initially start out using a simple excel or word <link>invoice template</link>, but when you get to making your second or third invoice you'll realize that you're wasting a lot of your time on repetitive tasks. For example:",
    values: {
      link: (chunks) => <a href="https://www.invoicesimple.com/invoice-template">{chunks}</a>
    }
  },
  alternativeItem0: {
    id: 'seo.invoice.alternative.item.0',
    defaultMessage:
      'You need to duplicate and rename your original file every time you want to make a new Invoice.'
  },
  alternativeItem1: {
    id: 'seo.invoice.alternative.item.1',
    defaultMessage:
      'You end up copying or re-typing client and item details despite sending the same client an invoice in the past.'
  },
  alternativeItem2: {
    id: 'seo.invoice.alternative.item.2',
    defaultMessage: "It's a hassle to organize and find old Invoices."
  },
  alternativeItem3: {
    id: 'seo.invoice.alternative.item.3',
    defaultMessage:
      "If you want to know how much you've invoiced over a certain period you'll have to open up every single file and manually add up the totals."
  },
  alternativeFooter: {
    id: 'seo.invoice.alternative.footer',
    defaultMessage:
      "This is time you spend doing administrative work. Time that you're not being paid by anyone to do. My guess is you didn't start your own business to do administrative work."
  },

  // feature
  featureHeader: {
    id: 'seo.invoice.feature.header',
    defaultMessage: 'Benefits of using an Invoice Generator'
  },

  feature0Header: {
    id: 'seo.invoice.feature.0.header',
    defaultMessage: 'Saving you from retyping information'
  },
  feature0Body0: {
    id: 'seo.invoice.feature.0.body.0',
    defaultMessage:
      'The first time you use the Invoice Simple invoice generator, you’ll see a blank invoice template.<br></br>The next time you create an invoice, the invoice template will automatically fill in all of your relevant business information, items, and client information. The invoice generator will even use predictive text to autofill client information such as phone number, email address, and mailing address as you type the client’s name.',
    values: { br: () => <br /> }
  },
  feature0Body1: {
    id: 'seo.invoice.feature.0.body.1',
    defaultMessage:
      "The next time you want to create an invoice, the invoice template will automatically be filled with all of your relevant information. The same applies to your clients and items. We'll fill out your entire client information (phone, email, address) as you start typing the client name."
  },

  feature1Header: {
    id: 'seo.invoice.feature.1.header',
    defaultMessage: 'Get shareable and printable online invoice in one go'
  },
  feature1Body0: {
    id: 'seo.invoice.feature.1.body.0',
    defaultMessage: `You can share or download a PDF of your invoice with the click of a button. This saves a lot of time compared to Excel and Word invoice templates, for which you need to complete several steps to export a document as a PDF or be sure that your client can open files of a specific format.`
  },
  feature1Body1: {
    id: 'seo.invoice.feature.1.body.1',
    defaultMessage:
      "There are a few additional benefits to emailing your invoice through Invoice Generator. One is that your invoice will be suitable for mobile and desktop viewing. Second is that you'll be notified when your client receives and views the invoice. And thirdly if your client hasn't viewed you invoice after a couple days, Invoice Simple will automatically send them a polite reminder."
  },
  feature1Body2: {
    id: 'seo.invoice.feature.1.body.2',
    defaultMessage:
      'Instead of working hard, we should work smart! The smart thing is to use the right tool for the job, an invoice generator.'
  },

  feature2Header: {
    id: 'seo.invoice.feature.2.header',
    defaultMessage: 'Our free invoice generator saves you time'
  },
  feature2Body0: {
    id: 'seo.invoice.feature.2.body.0',
    defaultMessage:
      "When you use Invoice Simple's invoice generator you save yourself a lot of time and effort. Here are some of the reasons why:"
  },
  feature2Body1: {
    id: 'seo.invoice.feature.2.body.1',
    defaultMessage:
      'See our guide to <link>invoicing software for small businesses</link> for more detail on all your options.',
    values: {
      link: (chunks) => (
        <a href="https://www.invoicesimple.com/invoice-generator/small-business-invoicing-software">
          {chunks}
        </a>
      )
    }
  },

  feature2Item0: {
    id: 'seo.invoice.feature.2.item.0',
    defaultMessage:
      "Automatically save your clients and items so they’re available the next time you're writing an invoice"
  },
  feature2Item1: {
    id: 'seo.invoice.feature.2.item.1',
    defaultMessage: 'Organize your invoices in seconds'
  },

  feature2Item2: {
    id: 'seo.invoice.feature.2.item.2',
    defaultMessage:
      "Use a professionally designed template that's compatible with printers and mobile devices"
  },

  feature2Item3: {
    id: 'seo.invoice.feature.2.item.3',
    defaultMessage: 'See a full overview of your business effortlessly and in moments'
  },

  feature2Item4: {
    id: 'seo.invoice.feature.2.item.4',
    defaultMessage: 'Know when your invoice is viewed by a customer'
  },

  feature2Item5: {
    id: 'seo.invoice.feature.2.item.5',
    defaultMessage: 'Process credit cards online or on location'
  },
  feature2ItemLink: {
    id: 'seo.invoice.feature.2.item.link',
    defaultMessage: 'And access <link>many more amazing benefits</link>',
    values: {
      link: (chunks) => (
        <a href="https://www.invoicesimple.com/features-invoicing-billing-tools">{chunks}</a>
      )
    }
  },

  // faq
  faqHeader: {
    id: 'seo.invoice.faq.header',
    defaultMessage: 'Online invoice generator Frequently Asked Questions'
  },
  faqImageQuestion: {
    id: 'seo.invoice.faq.image.question',
    defaultMessage: 'Q: Can I see a sample invoice?'
  },
  faqImageAlt: {
    id: 'seo.invoice.faq.image.alt',
    defaultMessage: 'invoice generator sample invoice'
  },
  faqImageBody: {
    id: 'seo.invoice.faq.image.body',
    defaultMessage:
      "A: You can easily preview what your invoice will look like by pressing the preview button on the top left. We've also put together a sample invoice for you, so you can see what a fully filled out invoice would look like. For more sample invoices you can checkout our page <link>here</link>",
    values: {
      link: (chunks) => (
        <a href="https://www.invoicesimple.com/invoice-template/sample-invoice-template">
          {chunks}
        </a>
      )
    }
  },

  faqItem0Q: {
    id: 'seo.invoice.faq.question.0',
    defaultMessage: 'Q: Can I put my own logo on the invoice template?'
  },
  faqItem0A: {
    id: 'seo.invoice.faq.answer.0',
    defaultMessage:
      'A: Yes. Select the logo box in the top right corner. Then, you can upload your logo or drag and drop it into place. Both JPEG and PNG images are supported.'
  },
  faqItem1Q: {
    id: 'seo.invoice.faq.question.1',
    defaultMessage: 'Q: How do I send my Invoice?'
  },
  faqItem1A: {
    id: 'seo.invoice.faq.answer.1',
    defaultMessage:
      'A: The Invoice Simple invoice generator allows you to email your invoice, download a PDF copy, or send a link to your invoice. If you email your Invoice directly from Invoice Simple, we’ll notify you when your customer views it. This can be useful for following up with them later. If you send a link to your customer, they can download or print the invoice from the link.'
  },
  faqItem2Q: {
    id: 'seo.invoice.faq.question.2',
    defaultMessage: "Q: Will my clients see 'Invoice Simple' on my Invoices?"
  },
  faqItem2A: {
    id: 'seo.invoice.faq.answer.2',
    defaultMessage:
      'A: Never. Regardless of whether you have a registered account or not, your generated invoices are always 100% unbranded.'
  },
  faqItem3Q: {
    id: 'seo.invoice.faq.question.3',
    defaultMessage: 'Q: Can I generate a PDF Invoice?'
  },
  faqItem3A: {
    id: 'seo.invoice.faq.answer.3',
    defaultMessage:
      'A: Absolutely. The PDF button at the top of the invoice page lets you instantly download a PDF file of your invoice that you can save for your records or send to your customer.'
  },
  faqItem4Q: {
    id: 'seo.invoice.faq.question.4',
    defaultMessage: 'Q: Can I change the currency?'
  },
  faqItem4A: {
    id: 'seo.invoice.faq.answer.4',
    defaultMessage:
      'A: Yes. Our invoice generator should detect your currency automatically. However, if you want to make an invoice in a different currency, you can easily change the selected currency using the Settings panel on the right.'
  },
  faqItem5Q: {
    id: 'seo.invoice.faq.question.5',
    defaultMessage: 'Q: Can I save my invoice and customer details?'
  },
  faqItem5A: {
    id: 'seo.invoice.faq.answer.5',
    defaultMessage:
      'A: Yes. Your invoices are automatically saved to your invoices list. There is also a clients list That automatically saves customer information.'
  },
  faqItem6Q: {
    id: 'seo.invoice.faq.question.6',
    defaultMessage: 'Q: As a freelancer, how do I make an Invoice for time?'
  },
  faqItem6A: {
    id: 'seo.invoice.faq.answer.6',
    defaultMessage:
      'A: We’ve found that what works well is to enter the number of hours worked into the quantity field and your hourly rate into the price field. The invoice generator will then calculate the total amount due.'
  },
  faqItem8Q: {
    id: 'seo.invoice.faq.question.8',
    defaultMessage: 'Q: How do I charge taxes using the invoice generator?'
  },
  faqItem8A: {
    id: 'seo.invoice.faq.answer.8',
    defaultMessage:
      "A: If you'd like your invoice to include taxes on top of your prices, use the Settings panel on the right to enable a tax setting. Then, you can enter a tax rate and label."
  },
  faqItem9Q: {
    id: 'seo.invoice.faq.question.9',
    defaultMessage: 'Q: What about line items that already have tax applied?'
  },
  faqItem9A: {
    id: 'seo.invoice.faq.answer.9',
    defaultMessage:
      "A: If your prices already include tax, and you'd like your invoice to show the amount of tax included, then select the “Inclusive?” checkbox when enabling taxes."
  },
  faqItem10Q: {
    id: 'seo.invoice.faq.question.10',
    defaultMessage: 'Q: What is an invoice?'
  },
  faqItem10A: {
    id: 'seo.invoice.faq.answer.10',
    defaultMessage:
      "A: Looking to find out what an invoice really means? Here's a complete explanation of <invoiceLink> what an invoice is </invoiceLink>. If that isn't enough of an explanation, then see what <wikiLink>Wikipedia</wikiLink> has to say about it. Short version though, an invoice or a bill is a document you give your customer, containing what you did for the customer and what they owe you for that service or product.",
    values: {
      invoiceLink: (chunks) => (
        <a href="https://www.invoicesimple.com/invoice-generator/what-is-an-invoice">{chunks}</a>
      ),
      wikiLink: (chunks) => <a href="https://en.wikipedia.org/wiki/Invoice">{chunks}</a>
    }
  },
  faqItem11Q: {
    id: 'seo.invoice.faq.question.11',
    defaultMessage: 'Q: How can I process credit cards for my business?'
  },
  faqItem11A: {
    id: 'seo.invoice.faq.answer.11',
    defaultMessage:
      'A: We use payment processing services, Stripe and PayPal, depending on location. Simply sign up for Invoice Simple via our mobile apps or online, and go to the settings section to integrate payments.'
  },
  faqItem12Q: {
    id: 'seo.invoice.faq.question.12',
    defaultMessage: 'Q: Can I create an invoice in another language?'
  },
  faqItem12A: {
    id: 'seo.invoice.faq.answer.12',
    defaultMessage: 'A: Yes, you can easily generate an invoice in 4 languages:'
  }
});

export const InvoiceSeoContent = (props: {
  intl: ISIntl;
  appUrl: string;
  appProxyHost: string;
  currentLocale: string;
}) => {
  const { intl, appUrl, appProxyHost, currentLocale } = props;
  const f = intl.formatMessage;
  const proxyUrl = `https://${appProxyHost}`;

  const guide0Items = [0, 1, 2, 3, 4, 5].map((i) => {
    const item = messages[`guide0Item${i}`];
    return (
      <li key={`guide-0-item-${i}`}>
        <FormattedMessage {...item} />
      </li>
    );
  });

  const guide2Items = [0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => {
    const head = messages[`guide2Item${i}Header`];
    const body = messages[`guide2Item${i}Body`];
    return (
      <li key={`guide-2-item-${i}`}>
        <h4>
          <FormattedMessage {...head} />
        </h4>
        <div>
          <FormattedMessage {...body} />
        </div>
      </li>
    );
  });

  const faqItems = [0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 11].map((i) => {
    const question = messages[`faqItem${i}Q`];
    const answer = messages[`faqItem${i}A`];

    return (
      <li key={`faq-${i}`}>
        <h3>
          <FormattedMessage {...question} />
        </h3>
        <p>
          <FormattedMessage {...answer} />
        </p>
      </li>
    );
  });

  const alternativeItems = [0, 1, 2, 3].map((i) => {
    const item = messages[`alternativeItem${i}`];
    return (
      <li key={`alternative-${i}`}>
        <FormattedMessage {...item} />
      </li>
    );
  });

  const featureItems = [0, 1, 2, 3, 4, 5].map((i) => {
    const item = messages[`feature2Item${i}`];
    return (
      <li key={`feature-${i}`}>
        <FormattedMessage {...item} />
      </li>
    );
  });

  return (
    <div id="seo-content" className="d-print-none seo-content">
      <div className="container d-print-none">
        <div className="col-md">
          <div>
            <h1 id="seoTitle">{props.intl.fta(messages.header)}</h1>

            <ul>
              <li>
                <a href="#howtomakeaninvoice">
                  <FormattedMessage {...messages.link0} />
                </a>
              </li>
              <li>
                <a href="#howtosendaninvoice">
                  <FormattedMessage {...messages.link1} />
                </a>
              </li>
              <li>
                <a href="#whattoputoninvoice">
                  <FormattedMessage {...messages.link2} />
                </a>
              </li>
              <li>
                <a href="#invoicegeneratoralternatives">
                  <FormattedMessage {...messages.link3} />
                </a>
              </li>
              <li>
                <a href="#whyusethegenerator">
                  <FormattedMessage {...messages.link4} />
                </a>
              </li>
              <li>
                <a href="#faq">
                  <FormattedMessage {...messages.link5} />
                </a>
              </li>
            </ul>

            <section id="invoice-generator-guide">
              <h2 id="howtomakeaninvoice">
                <FormattedMessage {...messages.guide0Header} />
              </h2>
              <p>
                <FormattedMessage {...messages.guide0Body} />
              </p>
              <p>
                <FormattedMessage {...messages.guide0Body2} />
              </p>
              <ol>{guide0Items}</ol>
              <img
                id="locale-screenshot-0"
                className="locale-screenshot"
                src={appUrl + getLocaleScreenshotPath(currentLocale, 0)}
                alt={props.intl.formatMessage(messages.guide0ImageAlt)}
              />

              <h2 id="howtosendaninvoice">
                <FormattedMessage {...messages.guide1Header} />
              </h2>
              <p>
                <FormattedMessage {...messages.guide1Body1} />
              </p>
              <p>
                <FormattedMessage {...messages.guide1Body2} />
              </p>
              <p>
                <FormattedMessage {...messages.guide1Body3} />
              </p>

              <h2 id="whattoputoninvoice">{f(messages.guide2Header)}</h2>
              <p>
                <FormattedMessage {...messages.guide2Body} />
              </p>
              <img
                id="locale-screenshot-1"
                className="locale-screenshot"
                src={appUrl + getLocaleScreenshotPath(currentLocale, 1)}
                alt={props.intl.formatMessage(messages.guide2ImageAlt)}
              />
              <ol>{guide2Items}</ol>
            </section>

            <section id="invoice-generator-alternatives">
              <h2>
                <FormattedMessage {...messages.alternativeHeader} />
              </h2>
              <p>
                <FormattedMessage {...messages.alternativeBody} />
              </p>
              <ul>{alternativeItems}</ul>
              <p>
                <FormattedMessage {...messages.alternativeFooter} />
              </p>
            </section>

            <section id="invoice-generator-features">
              <h2>
                <FormattedMessage {...messages.featureHeader} />
              </h2>
              <h3>
                <FormattedMessage {...messages.feature0Header} />
              </h3>
              <p>
                <FormattedMessage {...messages.feature0Body0} />
              </p>
              <p>
                <FormattedMessage {...messages.feature0Body1} />
              </p>
              <h3>
                <FormattedMessage {...messages.feature1Header} />
              </h3>
              <p>
                <FormattedMessage {...messages.feature1Body0} />
              </p>
              <p>
                <FormattedMessage {...messages.feature1Body1} />
              </p>
              <p>
                <FormattedMessage {...messages.feature1Body2} />
              </p>
              <h3>
                <FormattedMessage {...messages.feature2Header} />
              </h3>
              <p>
                <FormattedMessage {...messages.feature2Body0} />
              </p>
              <ul>
                {featureItems}
                <li>
                  <FormattedMessage {...messages.feature2ItemLink} />
                </li>
              </ul>
              <p>
                <FormattedMessage {...messages.feature2Body1} />
              </p>
            </section>

            <section id="invoice-generator-faq">
              <h2 id="faq">
                <FormattedMessage {...messages.faqHeader} />
              </h2>
              <ul className="list-columns">
                <li>
                  <h3>
                    <FormattedMessage {...messages.faqImageQuestion} />
                  </h3>
                  <p>
                    <img
                      id="locale-screenshot-2"
                      className="locale-screenshot"
                      src={appUrl + getLocaleScreenshotPath(currentLocale, 2)}
                      alt={props.intl.formatMessage(messages.faqImageAlt)}
                    />
                  </p>
                  <p>
                    <FormattedMessage {...messages.faqImageBody} />
                  </p>
                </li>
                {faqItems}

                <li key={`faq-13`}>
                  <h3>
                    <FormattedMessage {...messages.faqItem12Q} />
                  </h3>
                  <p>
                    <FormattedMessage {...messages.faqItem12A} />
                  </p>
                  <ul className="localized-links">
                    <li>
                      English &nbsp;
                      <a href={proxyUrl + getLocalePath('en-US')} title={getLocaleTitle('en-US')}>
                        {getLocaleTitle('en-US')}
                      </a>
                    </li>
                    <li>
                      Français &nbsp;
                      <a href={proxyUrl + getLocalePath('fr-FR')} title={getLocaleTitle('fr-FR')}>
                        {getLocaleTitle('fr-FR')}
                      </a>
                    </li>
                    <li>
                      Español &nbsp;
                      <a href={proxyUrl + getLocalePath('es-ES')} title={getLocaleTitle('es-ES')}>
                        {getLocaleTitle('es-ES')}
                      </a>
                    </li>
                    <li>
                      Nederlands &nbsp;
                      <a href={proxyUrl + getLocalePath('nl-NL')} title={getLocaleTitle('nl-NL')}>
                        {getLocaleTitle('nl-NL')}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
