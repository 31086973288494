import { getCookie, setCookie } from './cookie';
import UserModel from 'src/models/UserModel';

export const shouldRedirectToOnboarding = (): boolean => {
  const user = UserModel.getInstance();
  const onboardingV1Enabled = isOnboardingV1Enabled();

  // Avoid showing onboarding to users who signed up before the released
  if (user.createdAt && user.createdAt < new Date('2024-02-29')) {
    return false;
  }

  return onboardingV1Enabled && user.isSignedUp && !user.onboardingCompleted;
};

export const isOnboardingV1Enabled = (): boolean => {
  updateOnboardingCookieFromFlags();
  return false;
  /*
  const onboardingV1CookieEnabled = getCookie('onboardingV1Enabled') === 'true';
  return onboardingV1CookieEnabled;
  */
};

/**
 * Update the onboarding cookie to match the Flagsmith feature flag value.
 */
const updateOnboardingCookieFromFlags = (): void => {
  const onboardingV1Cookie = getCookie('onboardingV1Enabled');
  // const featureFlagEnabled = flagsmith.hasFeature('web_onboarding_v1');
  const featureFlagEnabled = false;

  const user = UserModel.getInstance();
  if (!user?.id) {
    return;
  }

  if (onboardingV1Cookie !== featureFlagEnabled.toString()) {
    setCookie('onboardingV1Enabled', featureFlagEnabled);
  }
};
