import React from 'react';
import injectStore from 'src/util/injectStore';

import localeStore from 'src/stores/LocaleStore';
import { getIntlCurrency } from '@invoice-simple/common';
import './Number.scss';

// Number(value), currencyCode || store.user.settingList.currencyCode || 'USD')
export const LocalizedCurrency = injectStore(({ store, value, currencyCode, textStyle }) => (
  <span className="localized-number" style={textStyle}>
    {getIntlCurrency({
      number: value,
      currencyCode: currencyCode || store.user.settingList.currencyCode,
      localeCode: localeStore.currentLocale
    })}
  </span>
));
