import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EmailInput from '../EmailInput';
import ClientSuggest from '../ClientSuggest';
import InputWithLabel from '../InputWithLabel';
import {
  ClientAddressInput,
  BusinessAdditionalFields,
  BusinessAddressInput,
  BusinessPhoneInput,
  ClientPhoneInput,
  BusinessNumberInput,
  ClientAddFieldsButtons
} from '../Form/';

import { defineMessages } from 'src/i18n';
import { AppStore } from 'src/stores/AppStore';
import { useISIntl } from 'src/i18n/utils';

const messages = defineMessages({
  from: {
    id: 'invoiceCredentials.from',
    defaultMessage: 'from'
  },
  to: {
    id: 'invoiceCredentials.to',
    defaultMessage: 'to'
  },
  name: {
    id: 'invoiceCredentials.name',
    defaultMessage: 'name'
  },
  email: {
    id: 'invoiceCredentials.email',
    defaultMessage: 'email'
  },
  businessEmailPlaceholder: {
    id: 'invoiceCredentials.business.email.placeholder',
    defaultMessage: 'name@business.com'
  },
  phone: {
    id: 'invoiceCredentials.phone',
    defaultMessage: 'phone'
  },
  phoneNumber: {
    id: 'invoiceCredentials.phoneNumber',
    defaultMessage: 'phone number'
  },
  businessName: {
    id: 'invoiceCredentials.businesName',
    defaultMessage: 'business name',
    description: 'Placeholder text for business name'
  },
  businessNumberTitle: {
    id: 'invoiceCredentials.businessNumberTitle',
    defaultMessage: 'business number'
  }
});

const InvoiceCredentials = (props: { store: AppStore }) => {
  const invoice = props.store.doc;
  const user = props.store.user;

  const { fta, formatMessage: f } = useISIntl();

  return (
    <div className="invoice-detail-header content-block">
      <div className="row">
        <div className="col-md invoice-address invoice-address-company">
          <h3>{fta(messages.from)}</h3>
          <form>
            <InputWithLabel
              label={<label htmlFor="invoice-company-name">{fta(messages.name)}</label>}
              content={
                <input
                  type="text"
                  disabled={props.store.isLoading}
                  id="invoice-company-name"
                  name="invoice-company-name"
                  autoComplete="organization"
                  placeholder={fta(messages.businessName)}
                  value={invoice.businessName}
                  onChange={(e) => invoice.setCompanyName(e.target.value)}
                />
              }
            />
            <InputWithLabel
              label={<label htmlFor="invoice-company-email">{fta(messages.email)}</label>}
              content={
                <EmailInput
                  key={invoice.company.remoteId}
                  name="invoice-company-email"
                  disabled={props.store.isLoading}
                  placeholder={f(messages.businessEmailPlaceholder)}
                  value={invoice.company.email}
                  onValidEmail={invoice.setCompanyEmail}
                />
              }
            />
            <BusinessAddressInput />
            <BusinessPhoneInput storeAccessValue={'phone'} />
            <BusinessNumberInput />
            <BusinessAdditionalFields company={invoice.company} />
          </form>
        </div>

        <div className="col-md invoice-address invoice-address-client">
          <h3>{fta(messages.to)}</h3>
          <InputWithLabel
            label={<label htmlFor="invoice-client-name">{fta(messages.name)}</label>}
            content={
              <>
                <ClientSuggest
                  disabled={props.store.isLoading}
                  suggest={user.clientSuggest}
                  value={invoice.client.name}
                  onChange={(v: string) => (invoice.client.name = v)}
                  onSelect={props.store.doc.setClient}
                />
                {!!invoice.client.name && (
                  <span className="clear-client-autosuggest">
                    <button
                      className="btn"
                      type="button"
                      onClick={() => props.store.doc.resetClient()}>
                      <FontAwesomeIcon icon="times" />
                    </button>
                  </span>
                )}
              </>
            }
          />
          <InputWithLabel
            label={<label htmlFor="invoice-client-email">{fta(messages.email)}</label>}
            content={
              <EmailInput
                key={invoice.client.remoteId}
                name="invoice-client-email"
                disabled={props.store.isLoading}
                placeholder="name@client.com"
                autoComplete="off"
                value={invoice.client.email}
                onValidEmail={invoice.setClientEmail}
              />
            }
          />

          <ClientAddressInput />
          <ClientPhoneInput storeAccessValue="phone" />
          <ClientPhoneInput storeAccessValue="mobile" />
          <ClientPhoneInput storeAccessValue="fax" />

          <ClientAddFieldsButtons />
        </div>
      </div>
    </div>
  );
};

export default observer(InvoiceCredentials);
