import React, { FC } from 'react';
import { PaypalBaseBox } from '../../PaypalBaseBox';
import { GreenCheckMarkIcon } from 'src/components/Icons';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { getPaymentEventSource } from 'src/payments/utils';

const PaypalInvoicePaymentToggleBoxComponent: FC<{
  isPaymentsSuppressed: boolean;
  updatePaymentsSuppressed: (currentValue: boolean) => void;
  store: AppStore;
}> = ({ isPaymentsSuppressed, updatePaymentsSuppressed, store }) => {
  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    component: isPaymentsSuppressed
      ? PaymentEventComponent.tileDisabledInvoiceAccepting
      : PaymentEventComponent.tileEnabledInvoiceAccepting
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);
  return (
    <div data-testid="paypalInvoicePaymentToggleBox">
      <PaypalBaseBox
        icon={<GreenCheckMarkIcon />}
        actionElement={
          <div className="w-auto max-w-[30%] flex items-center justify-center">
            <ToggleSwitch
              checked={!isPaymentsSuppressed}
              onChange={() => updatePaymentsSuppressed(isPaymentsSuppressed)}
              disabled={false}
            />
          </div>
        }
      />
    </div>
  );
};

export const PaypalInvoicePaymentToggleBox = injectStore(PaypalInvoicePaymentToggleBoxComponent);
