import React from 'react';
import { FormattedMessage } from 'react-intl';

import './PaypalPromotionBox.scss';
import { messages } from './messages';

export const PaypalClosedBox = () => {
  const handleContactSupportButtonClick = () => {
    window.Intercom('show');
  };

  return (
    <div className="row">
      <p className="text-body">
        <FormattedMessage {...messages.closedBody} />
      </p>
      <p className="text-note">
        <FormattedMessage {...messages.closedNote} />
      </p>
      <button className="col-9 btn onboarding-button" onClick={handleContactSupportButtonClick}>
        <FormattedMessage {...messages.closedButton} />
      </button>
    </div>
  );
};
