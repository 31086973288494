import React, { FC } from 'react';
import { getPaymentEventSource, onError } from 'src/payments/utils/utils';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { defineMessages } from 'src/i18n';
import { TopContainer, ValueProps, ButtonContainer } from '../../PaymentBasicElements';
import {
  OnboardModalMessages,
  OnboardModalProps,
  PaymentEventActivePaymentProvider,
  PaymentEventComponent
} from 'src/payments/types';
import { PaypalModalImg } from './PaypalModalBase';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';

const messages = defineMessages({
  header: {
    id: 'paypalModal.prompt.closed.title',
    defaultMessage: 'Account Disconnected'
  },
  description: {
    id: 'paypalModal.prompt.closed.description',
    defaultMessage:
      'Your PayPal account is not connected. Please contact support to connect a valid PayPal account.'
  },
  contactSupport: {
    id: 'paypalModal.prompt.contactSupport',
    defaultMessage: 'Contact Support'
  }
});

const PaypalClosedModalComponent: FC<
  OnboardModalProps & {
    store: AppStore;
  }
> = ({ closeModal, store: { user } }) => {
  const paypalSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource()
  };
  const ft = (message: OnboardModalMessages) => message.defaultMessage;

  const handleClick = async () => {
    try {
      user.events.trackAction('payments-cta', {
        ...paypalSharedMetadata,
        component: PaymentEventComponent.modalClosed
      });

      window.Intercom('show');

      closeModal();
    } catch (error) {
      onError({ title: 'Error contact support for closed status', body: error.message });
    }
  };

  const onClickXIcon = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.modalCloseClosed
    });
    closeModal();
  };

  const paymentsViewedMeta = {
    ...paypalSharedMetadata,
    component: PaymentEventComponent.modalClosed
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <>
      <TopContainer
        onClickXIcon={onClickXIcon}
        headerMessage={ft(messages.header)}
        dataTestId="paypal--modal-x-button"
      />
      <main>
        <PaypalModalImg />
        <ValueProps>
          <b>{ft(messages.description)}</b>
        </ValueProps>
      </main>
      <ButtonContainer
        dataTestId="paypal--modal-submit-button"
        handleClick={handleClick}
        buttonMessage={ft(messages.contactSupport)}
      />
    </>
  );
};

export const PaypalClosedModal = injectStore(PaypalClosedModalComponent);
