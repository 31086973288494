import React, { SVGProps } from 'react';

export const CheveronUpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6485 7.19826C11.1799 7.66689 10.4201 7.66689 9.95147 7.19826L6 3.24679L2.04853 7.19826C1.5799 7.66689 0.820101 7.66689 0.351472 7.19826C-0.117157 6.72963 -0.117157 5.96983 0.351472 5.5012L5.15147 0.701203C5.6201 0.232574 6.3799 0.232574 6.84853 0.701203L11.6485 5.5012C12.1172 5.96983 12.1172 6.72963 11.6485 7.19826Z"
        fill="#111827"
      />
    </svg>
  );
};
