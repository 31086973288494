import React from 'react';
import { useQuery } from '@tanstack/react-query';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { CurrencyDisplay } from 'src/components/Currency';
import { LocalizedDate } from 'src/components/Localized/LocalizedDate';
import { PaginationButtons } from 'src/components/PaginationButtons';
import { useISIntl } from 'src/i18n/utils';
import { PaymentsOrderListModel } from 'src/models/PaymentsOrderListModel';
import environmentStore from 'src/stores/EnvironmentStore';

import { CaptureStatus } from './PaymentsOrder.types';
import { PaymentsOrderStatus } from './PaymentsOrderStatus';

import './PaymentsOrders.scss';

import UserModel from 'src/models/UserModel';
import { useEligiblePaymentProviders } from 'src/payments/hooks/useEligiblePaymentProviders';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { getPaymentEventSource } from 'src/payments/utils';

import { Table } from './components/Table.component';
import { messages } from './messages';
import { PaymentsOrdersEmpty } from './PaymentsOrdersEmpty';
import { PaymentsOrdersLoading } from './PaymentsOrdersLoading';

export interface PaypalPaymentsOrdersProps {
  dateFormat: string;
  paymentsOrderList: PaymentsOrderListModel;
  onError: (error: { title?: string; body?: string }) => void;
}

export function PaymentsOrders({
  dateFormat,
  onError,
  paymentsOrderList: {
    sortDirection,
    page,
    totalPages,
    captureStatus,
    setCaptureStatusFilter,
    loadPaymentsOrders,
    sortColumn,
    setPage,
    toggleOrderDirection
  }
}: PaypalPaymentsOrdersProps) {
  const { formatMessage: f, fta, ft } = useISIntl();
  const { data, isInitialLoading } = useQuery(
    [
      'payments',
      {
        sort: sortDirection,
        page,
        captureStatus
      }
    ],
    loadPaymentsOrders,
    {
      onError: (error) => {
        onError({
          title: 'Unable to fetch Payments',
          body: (error as Error)?.message || 'Something went wrong'
        });
      }
    }
  );

  const eligiblePaymentProviders = useEligiblePaymentProviders();

  const paymentsViewedMeta = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource(),
    eligiblePaymentProviders,
    component: PaymentEventComponent.paypalPaymentsTab
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, UserModel.getInstance().events);

  return (
    <div className="payments-orders-list" data-testid="PayPalPaymentsOrders">
      <div className="container">
        {!environmentStore.isSnapshot() && (
          <Helmet>
            <title itemProp="name">{ft(messages.title)}</title>
            <meta name="description" content={f(messages.description)} />
          </Helmet>
        )}

        <div
          className={classNames([
            'page-header doc-search-container',
            'payments-orders-list-header'
          ])}>
          <div className="btn-group btn-group-sm btn-group-filter" role="group">
            <button
              type="button"
              disabled={false}
              data-selector="all-payments-filter-button"
              className={classNames('btn btn-filter', {
                active: captureStatus === 'ALL'
              })}
              onClick={() => setCaptureStatusFilter('ALL')}>
              {fta(messages.filterAll)}
            </button>
            <button
              type="button"
              data-selector="paid-payments-filter-button"
              className={classNames('btn btn-filter', {
                active:
                  captureStatus === CaptureStatus.COMPLETED ||
                  (Array.isArray(captureStatus) && captureStatus.includes(CaptureStatus.COMPLETED))
              })}
              onClick={() => setCaptureStatusFilter(CaptureStatus.COMPLETED)}>
              {fta(messages.filterPaid)}
            </button>
            <button
              type="button"
              data-selector="refunded-payments-filter-button"
              className={classNames('btn btn-filter', {
                active:
                  captureStatus === CaptureStatus.REFUNDED ||
                  (Array.isArray(captureStatus) && captureStatus.includes(CaptureStatus.REFUNDED))
              })}
              onClick={() => setCaptureStatusFilter(CaptureStatus.REFUNDED)}>
              {fta(messages.filterRefunded)}
            </button>
          </div>
        </div>

        <div className="payments-orders-list-body panel">
          <Table
            caption="Payments Dashboard"
            isLoading={isInitialLoading}
            loading={() => <PaymentsOrdersLoading />}
            className="table"
            rowKey="orderId"
            empty={() => <PaymentsOrdersEmpty />}
            sortColumn={{
              field: sortColumn,
              direction: sortDirection
            }}
            columns={[
              {
                columnName: fta(messages.tableHeadInvoice),
                columnKey: 'invoiceNo'
              },
              {
                columnName: fta(messages.tableHeadClient),
                columnKey: 'clientName'
              },
              {
                columnName: fta(messages.tableHeadAmount),
                columnKey: 'amountCents',
                render: (row) => <CurrencyDisplay value={row.amountCents / 100} />
              },
              {
                className: 'd-none d-sm-table-cell',
                columnName: fta(messages.tableHeadDate),
                columnKey: 'createdAt',
                onSort: () => toggleOrderDirection(),
                render: (row) => (
                  <LocalizedDate date={new Date(row.createdAt)} dateFormat={dateFormat} />
                )
              },
              {
                alignText: 'right',
                columnName: fta(messages.tableHeadStatus),
                columnKey: 'captureStatus',
                render: (row) => <PaymentsOrderStatus status={row.captureStatus} />
              }
            ]}
            data={data ?? []}
          />
        </div>

        {!isInitialLoading && !!data?.length && (
          <PaginationButtons
            data={{
              hasNextPage: totalPages > page,
              next: totalPages > page ? `${page + 1}` : null,
              prev: page > 1 ? `${page - 1}` : null
            }}
            onPrev={() => {
              setPage(page - 1);
            }}
            onNext={() => {
              setPage(page + 1);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default observer(PaymentsOrders);
