import React from 'react';
import InputWithLabel from '../InputWithLabel';
import injectStore from '../../util/injectStore';
import invoiceInputFieldsUIStore from 'src/stores/InvoiceInputFieldsUIStore';
import { injectIntl, defineMessages } from 'src/i18n';
import { compose } from 'src/util/functions';
import { AppStore } from 'src/stores/AppStore';
import { ISIntl } from 'src/i18n/utils';
import { mayBe } from './MaybeRender';

const messages = defineMessages({
  businessWebsite: {
    id: 'settings.list.company.website',
    defaultMessage: 'website'
  }
});

const _BusinessWebsiteInput = compose(
  injectIntl,
  injectStore
)(({ store, intl }: { store: AppStore; intl: ISIntl }) => (
  <InputWithLabel
    baseline
    label={
      <label htmlFor="invoice-company-business-website" title={intl.fta(messages.businessWebsite)}>
        {intl.fta(messages.businessWebsite)}
      </label>
    }
    content={
      <input
        type="text"
        disabled={store.isLoading}
        data-testid="invoice-company-business-website"
        id="invoice-company-business-website"
        name="invoice-company-business-website"
        placeholder="https://example-website.com"
        value={store.doc.businessWebsite}
        onChange={(e) => store.doc.setBusinessWebsite(e.target.value)}
      />
    }
  />
));

export const BusinessWebsiteInput = mayBe(
  _BusinessWebsiteInput,
  () => invoiceInputFieldsUIStore.shouldShowBusinessWebsite
);
