import { DEFAULT_FEATURE_LIST, FeatureList } from './featureFlags';
const LOCAL_STORAGE_KEY = 'featureFlag';

/**
 * Set the value of all features to local storage
 * @param features
 */
export const setAllFeatures = (features: FeatureList) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(features));
};

/**
 * Return the current setting of feature flags on localStorage if exist
 * Set and return default value for feature flags on localStorage if not exist
 */
export const getAllFeatures = () => {
  const featureStr = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (featureStr) {
    return JSON.parse(featureStr);
  } else {
    setAllFeatures(DEFAULT_FEATURE_LIST);
    return DEFAULT_FEATURE_LIST;
  }
};
