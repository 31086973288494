import React from 'react';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { normalize } from 'uri-js';

import { defineMessages, injectIntl, ISIntl } from 'src/i18n';
import { ENABLED_LOCALES } from 'src/i18n/enabledLocales';
import locationModel from 'src/models/LocationModel';
import appStore from 'src/stores/AppStore';
import environmentStore from 'src/stores/EnvironmentStore';
import { compose } from 'src/util/functions';

const messages = defineMessages({
  titleDefault: {
    id: 'app.title.default',
    defaultMessage: 'Online invoice generator - Create invoices in seconds'
  },
  titlePrefix: {
    id: 'app.title.prefix',
    defaultMessage: 'Invoice Simple'
  }
});

const GlobalHelmet = ({ intl }: { intl: ISIntl }) => {
  return (
    <Helmet
      defaultTitle={intl.formatMessage(messages.titleDefault)}
      titleTemplate={intl.formatMessage(messages.titlePrefix) + ' - %s'}>
      <html lang={environmentStore.isSnapshot() ? '__LOCALE_NAME__' : intl.locale} />

      {locationModel.isGuestPath() &&
        !environmentStore.isSnapshot() &&
        !appStore.user.isSignedUp && (
          // Cookie consent handler. Must be sync and on <head>.
          // https://docs.osano.com/installing-osano
          <script src="https://cmp.osano.com/AzyXY1T6rlk2816Od/14dae4f2-96c0-4be5-bd1e-0ab755d40a0e/osano.js" />
        )}

      <body className="app-theme" />
      {locationModel.isCanonical && (
        <link
          rel="canonical"
          href={normalize(`https://${environmentStore.appProxyHost}${locationModel.path}`)}
        />
      )}
      {locationModel.isCanonical && (
        <meta
          property="og:url"
          content={normalize(`https://${environmentStore.appProxyHost}${locationModel.path}`)}
        />
      )}

      {locationModel.isHrefLang && (
        <link
          rel="alternate"
          href={normalize(`https://${environmentStore.appProxyHost}${ENABLED_LOCALES[0].paths[0]}`)}
          hrefLang="x-default"
        />
      )}

      {locationModel.isHrefLang &&
        ENABLED_LOCALES.map((localeDef) =>
          localeDef.paths.map((path) => (
            <link
              rel="alternate"
              href={normalize(`https://${environmentStore.appProxyHost}${path}`)}
              hrefLang={localeDef.locale}
              key={localeDef.locale}
            />
          ))
        )}
    </Helmet>
  );
};

export default compose(injectIntl, observer)(GlobalHelmet);
