import Parse from 'parse';

import store from 'src/stores/AppStore';

export const markInvoicePaidUnPaid = async ({
  invoiceRemoteId,
  paid = false,
  paymentMethodValue
}: {
  invoiceRemoteId: string;
  paid?: boolean;
  paymentMethodValue?: string;
}) => {
  try {
    await Parse.Cloud.run('markPaymentsPaidOrUnpaid', {
      invoiceRemoteId,
      paid,
      paymentMethodValue
    });

    const eventObject = {
      action: paid ? 'mark-invoice-paid' : 'mark-invoice-unpaid',
      text: paid ? 'mark-paid' : 'mark-unpaid',
      paymentMethodValue: paid ? paymentMethodValue : undefined
    };

    store?.user?.events?.trackButtonClick(eventObject);
  } catch (error) {
    console.error('Error while marking invoice as paid/unpaid', error);
  }
};
