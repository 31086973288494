import { DocTypes, SettingKeys } from '@invoice-simple/common';

// mapping between settings remoteId path and invoicePath
export default [
  {
    path: SettingKeys.CompanyName,
    type: 'str',
    invoicePath: 'company.company'
  },
  {
    path: SettingKeys.CompanyAddressLine1,
    type: 'str',
    invoicePath: 'company.address1'
  },
  {
    path: SettingKeys.CompanyAddressLine2,
    type: 'str',
    invoicePath: 'company.address2'
  },
  {
    path: SettingKeys.CompanyAddressLine3,
    type: 'str',
    invoicePath: 'company.address3'
  },
  {
    path: SettingKeys.CompanyBusinessNumber,
    type: 'str',
    invoicePath: 'company.businessNumber'
  },
  {
    path: SettingKeys.ContactName,
    type: 'str',
    invoicePath: 'company.businessName'
  },
  {
    path: SettingKeys.ContactName,
    type: 'str',
    invoicePath: 'company.name'
  },
  {
    path: SettingKeys.ContactEmail,
    type: 'str',
    invoicePath: 'company.email'
  },
  {
    path: SettingKeys.ContactMobile,
    type: 'str',
    invoicePath: 'company.mobile'
  },
  {
    path: SettingKeys.ContactPhone,
    type: 'str',
    invoicePath: 'company.phone'
  },
  {
    path: SettingKeys.ContactWebsiteUrl,
    type: 'str',
    invoicePath: 'company.website'
  },
  {
    path: SettingKeys.TaxType,
    type: 'num',
    invoicePath: 'setting.taxType'
  },
  {
    path: SettingKeys.TaxRate,
    type: 'dec',
    invoicePath: 'setting.taxRate'
  },
  {
    path: SettingKeys.TaxLabel,
    type: 'str',
    invoicePath: 'setting.taxLabel'
  },
  {
    path: SettingKeys.TaxInclusive,
    type: 'bool',
    invoicePath: 'setting.taxInclusive'
  },
  {
    path: SettingKeys.ThemeColor,
    type: 'str',
    invoicePath: 'setting.color'
  },
  {
    path: SettingKeys.ThemeTemplate,
    type: 'num',
    invoicePath: 'setting.template'
  },
  {
    path: SettingKeys.InvoiceLastNo,
    type: 'str',
    docType: DocTypes.DOCTYPE_INVOICE,
    invoicePath: 'invoiceNo',
    valStr: 'INV0000'
  },
  {
    path: SettingKeys.EstimateLastNo,
    type: 'str',
    docType: DocTypes.DOCTYPE_ESTIMATE,
    invoicePath: 'invoiceNo',
    valStr: 'EST0000'
  },
  {
    path: SettingKeys.RenameInvoiceTitle,
    type: 'str',
    docType: DocTypes.DOCTYPE_INVOICE,
    invoicePath: 'title'
  },
  {
    path: SettingKeys.RenameEstimateTitle,
    type: 'str',
    docType: DocTypes.DOCTYPE_ESTIMATE,
    invoicePath: 'title'
  },
  {
    path: SettingKeys.RenameItemQuantity,
    type: 'str'
  },
  {
    path: SettingKeys.RenameItemRate,
    type: 'str'
  },
  {
    path: SettingKeys.InvoiceHideQuantityAndRate,
    type: 'bool'
  },
  {
    path: SettingKeys.DefaultInvoiceNote,
    type: 'str',
    docType: DocTypes.DOCTYPE_INVOICE,
    invoicePath: 'setting.comment'
  },
  {
    path: SettingKeys.DefaultEstimateNote,
    type: 'str',
    docType: DocTypes.DOCTYPE_ESTIMATE,
    invoicePath: 'setting.comment'
  },
  // no sync
  {
    path: SettingKeys.LocaleCurrencyCode,
    type: 'str'
  },
  {
    path: SettingKeys.LocaleTaxYearStartMonth,
    type: 'num'
  },
  {
    path: SettingKeys.LocaleDateFormat,
    type: 'str'
  },
  {
    path: SettingKeys.LocaleLanguage,
    type: 'str'
  },
  {
    path: SettingKeys.RenameCompanyBusinessNumber,
    type: 'str'
  },
  {
    path: SettingKeys.CompanyLogo,
    type: 'str'
  },
  {
    path: SettingKeys.PaymentPaypalEmail,
    type: 'str'
  },
  {
    path: SettingKeys.PaymentBank,
    type: 'str'
  },
  {
    path: SettingKeys.PaymentCheque,
    type: 'str'
  },
  {
    path: SettingKeys.PaymentFeesType,
    type: 'num'
  },
  {
    path: SettingKeys.PaymentAlwaysAddSurcharge,
    type: 'bool'
  },
  {
    path: SettingKeys.PaymentOther,
    type: 'str'
  },
  {
    path: SettingKeys.PaymentQRCodeEnabled,
    type: 'bool'
  },
  {
    path: SettingKeys.RequestReviewEnabled,
    type: 'bool'
  },
  {
    path: SettingKeys.RequestReviewLink1,
    type: 'str'
  },
  {
    path: SettingKeys.EmailCopySender,
    type: 'bool'
  },
  {
    path: SettingKeys.EstimateSignatureRequired,
    type: 'bool',
    docType: DocTypes.DOCTYPE_ESTIMATE,
    invoicePath: 'setting.estimateSignatureRequired'
  }
];
