import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './ItemList.scss';
import { defineMessages } from 'src/i18n';
import { FormattedMessage } from 'react-intl';
import { usePaginatedItems } from './usePaginatedItems';
import { useSearchItems } from './useSearchItems';
import { trackEvent } from 'src/analytics/controller';
import { PaginationButtons } from '../PaginationButtons';
import { Items } from './Items';
import ItemListModel from 'src/models/ItemListModel';
import { useDebounce } from 'src/util/useDebounce';
import classNames from 'classnames';
import { useISIntl } from 'src/i18n/utils';

interface ItemListProps {
  onNav: (key: string, opts?: any) => void;
  onError: (error: { title?: string; body?: string }) => void;
  list: ItemListModel;
}

const messages = defineMessages({
  alertConnectivityErrorTitle: {
    id: 'app.alert.connectivityErrors.title',
    defaultMessage: 'Connectivity problem while contacting our servers.'
  },
  alertConnectivityErrorBody: {
    id: 'app.alert.connectivityErrors.body',
    defaultMessage: `Please try reloading the app. If the problem persists, contact our support team support@invoicesimple.com.`
  },
  title: {
    id: 'item.list.title',
    defaultMessage: 'Items'
  },
  description: {
    id: 'item.list.description',
    defaultMessage:
      'Need to manage your item list? Invoice Simple makes it easy, and will auto-complete items next time you make an invoice.'
  },
  buttonNew: {
    id: 'item.list.buttons.new',
    defaultMessage: 'New Item'
  },
  rowLoading: {
    id: 'item.list.row.loading',
    defaultMessage: 'Loading your Items...'
  },
  search: {
    id: 'item.list.search.placeholder',
    defaultMessage: 'Search by Item Name'
  },
  tableHeadCode: {
    id: 'item.list.table.head.code',
    defaultMessage: 'Description'
  },
  tableHeadDescription: {
    id: 'item.list.table.head.description',
    defaultMessage: 'Additional details'
  },
  tableHeadRate: {
    id: 'item.list.table.head.rate',
    defaultMessage: 'Rate'
  },
  tableHeadTaxable: {
    id: 'item.list.table.head.taxable',
    defaultMessage: 'Taxable'
  },
  tableFooterHint: {
    id: 'item.list.table.footer.hint',
    defaultMessage: 'All your items are auto saved here'
  }
});

const ItemList = ({ onNav, onError, list }: ItemListProps) => {
  const intl = useISIntl();
  const [url, setUrl] = useState('');
  const [query, setQuery] = useState('');

  const {
    data: paginationData,
    isInitialLoading: isPaginationLoading,
    isError: isPaginationError
  } = usePaginatedItems({
    dir: 'desc',
    limit: 200,
    url
  });

  const {
    data: searchData,
    isInitialLoading: isSearchLoading,
    isError: isSearchError
  } = useSearchItems(
    { searchTerm: useDebounce(query, 500) },
    {
      onSuccess: () => {
        list.user.trackAppEventViaApi('web-search', {
          page: 'items'
        });
      }
    }
  );

  const data = searchData || paginationData;
  const isLoading = isSearchLoading || isPaginationLoading;
  const isError = isSearchError || isPaginationError;

  const hasSearchResults = !!searchData?.items?.length;

  const resetPagination = () => {
    setUrl('');
  };

  function handleSearch(searchTerm: string) {
    setQuery(searchTerm);
  }

  const handleCreate = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    resetPagination();
    onNav('itemCreate');
  };
  const handleView = (id: string) => {
    resetPagination();
    onNav('itemEdit', { id });
  };

  const f = intl.formatMessage;

  const onNextPage = () => {
    if (paginationData) {
      trackEvent('pagination-click', {
        path: window.location.pathname,
        action: 'next',
        page:
          paginationData.prev && paginationData.next
            ? 'other'
            : paginationData.next
            ? 'first'
            : 'last'
      });
      setUrl(paginationData.next);
    }
  };

  const onPrevPage = () => {
    if (paginationData) {
      trackEvent('pagination-click', {
        path: window.location.pathname,
        action: 'prev',
        page:
          paginationData.prev && paginationData.next
            ? 'other'
            : paginationData.next
            ? 'first'
            : 'last'
      });
      setUrl(paginationData.prev);
    }
  };

  useEffect(() => {
    if (isError) {
      onError({
        title: f(messages.alertConnectivityErrorTitle),
        body: f(messages.alertConnectivityErrorBody, { details: '' })
      });
    }
  }, [isError]);

  return (
    <div className={classNames(['item-list', { loading: isLoading }])}>
      <div className="container">
        <Helmet>
          <title itemProp="name">{f(messages.title)}</title>
          <meta name="description" content={f(messages.description)} />
        </Helmet>

        <div className={'item-list-header page-header item-search-container'}>
          <h1>
            <FormattedMessage {...messages.title} />
          </h1>
          <div className="item-list-actions w-full">
            <input
              type="text"
              id="item-search"
              placeholder={f(messages.search)}
              onChange={(e) => handleSearch(e.target.value)}
              className="mr-2 w-auto"
              value={query}
            />

            <a className={`btn btn-prime btn-item-new`} onClick={handleCreate}>
              <FormattedMessage {...messages.buttonNew} />
            </a>
          </div>
        </div>

        <div className="item-list-body panel">
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="invoice-head-code invoice-head-invoice">
                  <FormattedMessage {...messages.tableHeadCode} />
                </th>
                <th className="invoice-head-description">
                  <FormattedMessage {...messages.tableHeadDescription} />
                </th>
                <th className="invoice-head-rate">
                  <FormattedMessage {...messages.tableHeadRate} />
                </th>
                <th className="invoice-head-taxable">
                  <FormattedMessage {...messages.tableHeadTaxable} />
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr className="invoice-row-loading">
                  <td colSpan={5}>
                    <span>{f(messages.rowLoading)}</span>
                  </td>
                </tr>
              ) : (
                <Items
                  onView={handleView}
                  onCreate={handleCreate}
                  data={data}
                  query={query}
                  isError={isError}
                  hasSearchResults={hasSearchResults}
                  onError={onError}
                />
              )}
            </tbody>
          </table>
        </div>
        <div className="item-list-footer">
          <span className="help-message">
            <FormattedMessage {...messages.tableFooterHint} />
          </span>
        </div>

        <PaginationButtons
          hidden={!!query}
          data={paginationData}
          onPrev={onPrevPage}
          onNext={onNextPage}
        />
      </div>
    </div>
  );
};

export default ItemList;
