import InvoiceModel from 'src/models/InvoiceModel';
import UserModel from 'src/models/UserModel';
import { Invoice } from './IsParseDomain';
import Parse from 'parse';
import { Document } from '@invoice-simple/domain-invoicing';

export const getInvoiceModel = (doc: Document | Parse.Object<Parse.Attributes>) => {
  const isParseObject = (obj: any): obj is Parse.Object<Parse.Attributes> => {
    return obj instanceof Parse.Object;
  };
  return new InvoiceModel(
    UserModel.getInstance(),
    isParseObject(doc) ? doc : new Invoice({ ...doc })
  );
};
