import { observable, computed, action, toJS } from 'mobx';

import { getPaymentsOrders } from '../payments/utils/Accounts';
import { PaypalOrderFormatted, CaptureStatus } from '../payments/components/PaymentsDashboard';

export class PaymentsOrderListModel {
  @observable captureStatus: CaptureStatus | CaptureStatus[] | 'ALL' = 'ALL';
  // sort
  @observable sort: { field: keyof PaypalOrderFormatted; direction: 'asc' | 'desc' } = {
    field: 'createdAt',
    direction: 'desc'
  };

  // pagination
  @observable limit: number = 50;
  @observable page: number = 1;
  @observable totalPages: number = 1;

  @computed
  get sortDirection() {
    return this.sort.direction;
  }

  @computed
  get sortColumn() {
    return this.sort.field;
  }

  @action.bound
  toggleOrderDirection() {
    this.sort = {
      ...this.sort,
      direction: this.sort.direction === 'asc' ? 'desc' : 'asc'
    };
  }

  @action.bound
  setCaptureStatusFilter(captureStatus: CaptureStatus | CaptureStatus[] | 'ALL' = 'ALL') {
    this.captureStatus = captureStatus;
  }

  @action.bound
  setPage(page: number) {
    if (this.page !== page) {
      this.page = page;
    }
  }

  @action.bound
  async loadPaymentsOrders() {
    const response = await getPaymentsOrders({
      sort: `${this.sort.direction === 'desc' ? '-' : ''}${this.sort.field}`,
      captureStatus: toJS(this.captureStatus),
      page: this.page,
      limit: this.limit
    });

    this.totalPages = response.pagination.totalPages;

    return response.orders;
  }
}
export default new PaymentsOrderListModel();
