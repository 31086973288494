import React, { FC } from 'react';

import { defineMessages } from 'src/i18n';
import {
  OnboardModalProps,
  OnboardModalMessages,
  PaymentEventComponent,
  PaymentEventActivePaymentProvider
} from 'src/payments/types';
import {
  TopContainer,
  Illustration,
  ValueProps,
  ButtonContainer
} from '../../PaymentBasicElements';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { getPaymentEventSource } from 'src/payments/utils';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';

const messages = defineMessages({
  header: {
    id: 'stripeModal.prompt.title',
    defaultMessage: 'Pending Verification'
  },
  description1: {
    id: 'stripeModal.prompt.description1',
    defaultMessage:
      "You've completed your account setup—nicely done! Now just hang tight while Stripe verifies your account."
  },
  description2: {
    id: 'stripeModal.prompt.description2',
    defaultMessage:
      "This step typically takes less than 24 hours and you'll be notified once it's done. Then, you'll be able to turn on online payments for your clients and get paid twice as fast!"
  },
  ctaText: { id: 'stripeModal.prompt.ctaText', defaultMessage: 'Got It' }
});

const StripePendingVerificationModal: FC<
  OnboardModalProps & {
    store: AppStore;
  }
> = ({ closeModal, store: { user } }) => {
  const ft = (message: OnboardModalMessages) => message.defaultMessage;
  const stripeSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    source: getPaymentEventSource()
  };
  const handleClick = () => {
    user.events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.modalPendingVerification
    });
    closeModal();
  };

  const onClickXIcon = () => {
    user.events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.modalClosePendingVerification
    });
    closeModal();
  };

  const paymentsViewedMeta = {
    ...stripeSharedMetadata,
    component: PaymentEventComponent.modalPendingVerification
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <>
      <div data-testid="stripePendingVerificationModal" />
      <TopContainer
        dataTestId="stripe--modal-x-button"
        onClickXIcon={onClickXIcon}
        headerMessage={ft(messages.header)}
      />
      <main>
        <Illustration url={'/images/kb-illustration.png'} width={826} height={472} />
        <ValueProps>
          <b>{ft(messages.description1)}</b>
          <p className="pt-4">{ft(messages.description2)}</p>
        </ValueProps>
      </main>
      <ButtonContainer
        dataTestId="stripe--modal-submit-button"
        handleClick={handleClick}
        buttonMessage={ft(messages.ctaText)}
      />
    </>
  );
};

export default injectStore(StripePendingVerificationModal);
