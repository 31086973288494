import React from 'react';

import { useISIntl } from 'src/i18n';
import {
  CameraIcon,
  ChartIcon,
  ClipboardCheckIcon,
  ClockIcon,
  DocIcon,
  FrameIcon,
  InvoicesIcon,
  MoneyIcon,
  PCMobileIcon,
  PencilIcon,
  PenWriteIcon,
  PhoneIcon,
  PhoneNotificationIcon,
  PhotographIcon,
  SparklesIcon,
  StarIcon,
  Templates2Icon,
  TemplatesIcon,
  UploadCloudIcon
} from '../icons';
import { PaywallVariant } from '../SubscriptionTieredV2';
import { messages } from '../utils/messages';

interface ValuePropProps {
  paywallVariant: PaywallVariant;
}

export const ValueProps = (props: ValuePropProps) => {
  const { ft } = useISIntl();

  const variant: Record<PaywallVariant, JSX.Element> = {
    default: (
      <>
        <ValueProp message={ft(messages.paywallValueProp1)} iconKey="invoices-icon" />
        <ValueProp message={ft(messages.paywallValueProp2)} iconKey="phone-notification-icon" />
        <ValueProp message={ft(messages.paywallValueProp3)} iconKey="pc-mobile-icon" />
        <ValueProp message={ft(messages.paywallValueProp4)} iconKey="pen-write-icon" />
      </>
    ),
    'essentials-to-plus': (
      <>
        <ValueProp message={ft(messages.paywallValuePropUpToTenInvoices)} iconKey="doc-icon" />
        <ValueProp message={ft(messages.paywallValuePropAddPhotos)} iconKey="frame-icon" />
        <ValueProp
          message={ft(messages.paywallValuePropBusinessOverSignature)}
          iconKey="pencil-icon"
        />
        <ValueProp message={ft(messages.paywallValuePropSummaryReports)} iconKey="chart-icon" />
      </>
    ),
    'essentials-to-premium': (
      <>
        <ValueProp message={ft(messages.paywallValuePropUnlimitedInvoices)} iconKey="doc-icon" />
        <ValueProp message={ft(messages.paywallValuePropAddPhotos)} iconKey="frame-icon" />
        <ValueProp
          message={ft(messages.paywallValuePropSignatures)}
          iconKey="clipboard-check-icon"
        />
        <ValueProp message={ft(messages.paywallValuePropSummaryReports)} iconKey="chart-icon" />
      </>
    ),
    'plus-to-premium': (
      <>
        <ValueProp message={ft(messages.paywallValuePropUnlimitedInvoices)} iconKey="doc-icon" />
        <ValueProp
          message={ft(messages.paywallValuePropSignatures)}
          iconKey="clipboard-check-icon"
        />
        <ValueProp
          message={ft(messages.paywallValuePropPremiumTemplates)}
          iconKey="templates-icon"
        />
        <ValueProp message={ft(messages.paywallValuePropCustomerRatings)} iconKey="star-icon" />
      </>
    ),
    'premium-only': (
      <>
        <ValueProp message={ft(messages.paywallValuePropSignatures)} iconKey="doc-icon" />
        <ValueProp
          message={ft(messages.paywallValuePropCustomizeWithTemplates)}
          iconKey="photograph-icon"
        />
        <ValueProp message={ft(messages.paywallValuePropReceiptScanner)} iconKey="camera-icon" />
        <ValueProp
          message={ft(messages.paywallValuePropVersionHistory)}
          iconKey="upload-cloud-icon"
        />
      </>
    )
  };

  return (
    <div className="valueProps tracking-normal gap-4 grid grid-cols-1 md:grid-cols-2 mt-[30px]">
      {variant[props.paywallVariant]}
    </div>
  );
};

const Icons = {
  'chart-icon': <ChartIcon />,
  'clipboard-check-icon': <ClipboardCheckIcon />,
  'clock-icon': <ClockIcon />,
  'doc-icon': <DocIcon />,
  'frame-icon': <FrameIcon />,
  'pencil-icon': <PencilIcon />,
  'phone-icon': <PhoneIcon />,
  'sparkles-icon': <SparklesIcon />,
  'star-icon': <StarIcon />,
  'pen-write-icon': <PenWriteIcon />,
  'pc-mobile-icon': <PCMobileIcon />,
  'invoices-icon': <InvoicesIcon />,
  'phone-notification-icon': <PhoneNotificationIcon />,
  'templates-icon': <TemplatesIcon />,
  'templates2-icon': <Templates2Icon />,
  'camera-icon': <CameraIcon />,
  'money-icon': <MoneyIcon />,
  'upload-cloud-icon': <UploadCloudIcon />,
  'photograph-icon': <PhotographIcon />
};

const ValueProp: React.FC<{
  message: string;
  iconKey: keyof typeof Icons;
}> = (props) => {
  const { message, iconKey } = props;
  return (
    <div className="flex flex-row justify-center items-center">
      <div className="flex justify-center items-center pr-4">
        <div className="w-[30px]">{Icons[iconKey]}</div>
      </div>
      <div
        className="valueProp flex-grow-1 text-gray-800"
        style={{ fontSize: 14, fontWeight: 400, lineHeight: '20px', textAlign: 'left' }}>
        {message}
      </div>
    </div>
  );
};
