import React from 'react';
import InvoiceLogo from './InvoiceLogo';
import injectStore from '../../util/injectStore';
import { Desktop, Mobile } from '../Responsive';
import { AppStore } from 'src/stores/AppStore';
import { useISIntl } from 'src/i18n/utils';

const InvoiceTitle = ({ store }: { store: AppStore }) => {
  const intl = useISIntl();
  const invoice = store.doc;

  return (
    <div className="invoice-detail-title content-block">
      <div className="d-flex justify-content-between">
        <div className="invoice-title">
          <h2>
            <Mobile>
              <span data-selector="invoice-title">{invoice.title}</span>
            </Mobile>
            <Desktop>
              <input
                type="text"
                id="invoice-title"
                name="invoice-title"
                disabled={store.isLoading}
                placeholder={intl.getTitleizedDocumentType()}
                value={invoice.title || ''}
                onChange={(e) => (invoice.title = e.target.value)}
              />
            </Desktop>
          </h2>
        </div>
        <div className="invoice-logo">
          <InvoiceLogo />
        </div>
      </div>
    </div>
  );
};

export default injectStore(InvoiceTitle);
