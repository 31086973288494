import Parse from 'parse';

function registerParseObjectWithName(name: string) {
  class ParseObject extends Parse.Object {
    constructor(options?: any) {
      super(name, options);
    }
  }
  Parse.Object.registerSubclass(name, ParseObject);
  return ParseObject;
}

export const Photo = registerParseObjectWithName('Photo');
export const Client = registerParseObjectWithName('Client');
export const Item = registerParseObjectWithName('Item');
export const Invoice = registerParseObjectWithName('Invoice');
export const Account = registerParseObjectWithName('Account');
export const Setting = registerParseObjectWithName('Setting');
export const Msg = registerParseObjectWithName('Msg');
export const MsgEvent = registerParseObjectWithName('MsgEvent');
