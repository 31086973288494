import React from 'react';
import { defineMessages } from 'react-intl';
import SettingFormGroup from 'src/components/Settings/SettingFormGroup';
import { useISIntl } from 'src/i18n/utils';
import SettingModel from 'src/models/SettingModel';
import { usePaymentsUsability } from 'src/payments/hooks/usePaymentsUsability';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';

const messages = defineMessages({
  qrCodeLabel: {
    id: 'paymentInstructions.qrCode.label',
    defaultMessage: 'QR Code'
  },
  qrCodeEnabledTitle: {
    id: 'paymentInstructions.qrCodeEnabled.title',
    defaultMessage: 'QR Code To Online Payments'
  }
});

interface IProps {
  setting: SettingModel | undefined;
}

const PaymentsQRSettingFormGroupComponent = ({ store, setting }: IProps & { store: AppStore }) => {
  const { fta } = useISIntl();
  const { isPaypalUsable, isStripeUsable } = usePaymentsUsability(
    store.user.settingList.currencyCode
  );
  if (!isPaypalUsable && !isStripeUsable) return null;

  return (
    <div id="tailwind">
      <SettingFormGroup label={fta(messages.qrCodeEnabledTitle)} setting={setting} />
    </div>
  );
};

const ISPaymentsQRSettingFormGroupComponent = ({
  store,
  setting
}: IProps & { store: AppStore }) => {
  const { fta } = useISIntl();
  const { isPaypalUsable, isStripeUsable } = usePaymentsUsability(
    store.user.settingList.currencyCode
  );
  if (!isPaypalUsable && !isStripeUsable) return null;

  return (
    <div id="tailwind">
      <SettingFormGroup
        label={fta(messages.qrCodeLabel)}
        setting={setting}
        helperText={'Enable clients to scan from invoice and pay online'}
      />
    </div>
  );
};

export const ISPaymentsQRSettingFormGroup = injectStore(ISPaymentsQRSettingFormGroupComponent);
export const PaymentsQRSettingFormGroup = injectStore(PaymentsQRSettingFormGroupComponent);
