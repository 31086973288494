import React from 'react';
import UserModel from 'src/models/UserModel';
import './screens/CancelScreens.scss';
import { Platform } from '@invoice-simple/common';

export const ReturnToAppButton = ({ platform }: { platform: Platform }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (platform === Platform.WEB) {
      UserModel.getInstance().trackAppEventViaApi('subscription-cancel-returnToAppButtonClicked');
      return window.location.assign('/');
    }

    /* Must be from mobile */
    window.location.href = 'settings://index';
  };

  return (
    <div className="flex content-center flex-col space-y-6 self-center mt-16">
      <button
        className="rounded-xl bg-orange-is border-transparent self-center py-3 px-4 text-white text-3xl font-bold"
        onClick={handleClick}>
        Back to Invoice Simple
      </button>
    </div>
  );
};
