import { PAYMENTS_QR_PLACEHOLDER_URL } from '../utils/utils';
import { usePaymentsUsability } from './usePaymentsUsability';

export function usePaymentsQRPlaceholder({
  currencyCode,
  paymentSuppressed,
  stripePaymentSuppressed
}: {
  currencyCode?: string;
  paymentSuppressed: boolean | undefined;
  stripePaymentSuppressed: boolean | undefined;
}): string | undefined {
  const { isPaypalUsable, isStripeUsable } = usePaymentsUsability(currencyCode);

  const paypalUsable = isPaypalUsable && !paymentSuppressed;
  const stripeUsable = isStripeUsable && !stripePaymentSuppressed;
  const shouldShowPlaceholder = paypalUsable || stripeUsable;

  return (shouldShowPlaceholder && PAYMENTS_QR_PLACEHOLDER_URL) || undefined;
}
