import { PaginatedItemsParams } from './usePaginatedItems';

/**
 * The pagination endpoint returns URLs for both next/prev pages. If the user selects the next/prev button,
 * we should pass the appropriate URL to fetch the new batch of items.
 * @returns a URL to send to the pagination endpoint
 */
export const buildUrl = ({ dir = 'desc', sortBy = 'code', limit, url }: PaginatedItemsParams) => {
  if (url) return url;

  const params = [`dir=${dir}`, `sortBy=${sortBy}`, ...(limit ? [`limit=${limit}`] : [])];
  return `${process.env.REACT_APP_URL ?? 'https://localhost:3000'}/api/items?${params.join('&')}`;
};
