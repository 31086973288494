import React, { useState } from 'react';
import { PaymentEventName, PaymentEvent } from '../../types';
import { startPaypalOnboarding } from '../../utils/apis/paypal.api';
import { PaymentSetupButton } from '../PaymentBasicElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onError } from 'src/payments/utils/utils';

export interface PaypalSetupAlertDictionary {
  alertBody: string;
  continueSetupButtonText: string;
}

interface Props {
  dictionary: PaypalSetupAlertDictionary;
  onTrackEvent: (eventName: PaymentEvent) => void;
}

export function PaypalSetupAlert({ dictionary, onTrackEvent }: Props) {
  const [loadingUrl, setLoadingUrl] = useState(false);

  async function goToPaypalOnboarding() {
    try {
      onTrackEvent(PaymentEventName.onboardingContinue);
      setLoadingUrl(true);
      await startPaypalOnboarding();
    } catch (error) {
      onError({ title: 'Failed to proceed to PayPal Onboarding', body: error.message });
    } finally {
      setLoadingUrl(false);
    }
  }

  return (
    <>
      <div className="payment-setup-progress-container col-md-12 my-4">
        <div className="payment-setup-progress-body d-flex align-items-center rounded p-4">
          <div>
            <FontAwesomeIcon icon="exclamation-circle" color="orange" size="3x" />
          </div>
          <div className="payment-alert-body pl-4">{dictionary.alertBody}</div>
        </div>
      </div>
      <div className="col-md-12">
        <PaymentSetupButton
          buttonText={dictionary.continueSetupButtonText}
          buttonBackgroundColor="orange"
          loading={loadingUrl}
          onButtonClick={goToPaypalOnboarding}
        />
      </div>
    </>
  );
}
