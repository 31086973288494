import React, { useEffect } from 'react';
import WelcomeAlert from '../WelcomeAlert';
import environmentStore from '../../stores/EnvironmentStore';
import Sticky from 'react-stickynode';

import { WhenEditingInvoice } from '../conditionalRenderer';
import InvoiceEmailController from './InvoiceEmailController';
import InvoicePaymentController from './InvoicePayments/InvoicePaymentsList/InvoicePaymentController';
import InvoiceActions from './InvoiceActions';
import InvoiceHelmet from './InvoiceHelmet';
import InvoiceBody from './InvoiceBody';
import InvoiceSidebar from './InvoiceSidebar';
import InvoiceControls from './InvoiceControls';
import { Mobile, Desktop } from '../Responsive';
import { compose } from 'src/util/functions';
import injectStore from 'src/util/injectStore';

import 'react-datepicker/dist/react-datepicker.css';
import './Invoice.scss';
import './InvoiceResponsive.scss';
import { PaymentsSurchargePromoModal, PaymentsUnbrandedUpgradePrompt } from 'src/payments';
import { URLQueryParamKeys, getURLQueryParam } from 'src/util/url';
import { usePaypalContext, useStripeContext } from 'src/payments/contexts';

export const InvoiceView = (props) => {
  const store = props.store;
  const loc = store.location;
  const user = store.user;
  const invoice = store.doc || {};
  const isLoading = store.isLoading;
  const isSeoHost = environmentStore.isCypress || loc.isSeoHost(environmentStore.appHost);
  let classString = 'invoice-detail edit';
  classString += ` invoice-detail-${invoice.isComplete ? 'complete' : 'draft'}`;
  classString += ` invoice-${isLoading ? 'loading' : 'loaded'}`;

  if (invoice.delete) {
    classString += ' invoice-delete';
  }
  if (invoice.deleted) {
    classString += ' invoice-deleted';
  }

  const showPaypalModal = usePaypalContext()?.showPaypalModal;
  const showStripeModal = useStripeContext()?.showStripeModal;

  useEffect(() => {
    const signupRef = getURLQueryParam(URLQueryParamKeys.REF);

    switch (signupRef) {
      case URLQueryParamKeys.PAYPAL_ONBOARDING:
        showPaypalModal?.();
        break;
      case URLQueryParamKeys.STRIPE_ONBOARDING:
        showStripeModal?.();
        break;
    }
  }, []);

  return (
    <div className={classString}>
      <InvoiceHelmet store={store} />

      <PaymentsUnbrandedUpgradePrompt />
      <PaymentsSurchargePromoModal />
      <WhenEditingInvoice>
        <WelcomeAlert
          show={isSeoHost && !user.hasWelcomeAlertCookie && !environmentStore.isMobileDevice()}
          onDismiss={user.handleWelcomeAlertDismiss}
        />
      </WhenEditingInvoice>

      <div className="container">
        <div className="row">
          <Mobile>
            <Sticky innerZ={3} activeClass="sticky">
              <InvoiceActions store={store} />
            </Sticky>
          </Mobile>
          <div className="col-lg-9">
            <Desktop>
              <InvoiceActions store={store} />
            </Desktop>
            <InvoiceEmailController store={store} />
            <InvoicePaymentController store={store} />
            <InvoiceBody store={store} />
            <InvoiceControls store={store} />
          </div>
          <InvoiceSidebar store={store} />
        </div>
      </div>
    </div>
  );
};

export default compose(injectStore)(InvoiceView);
