import { Cadence } from 'src/models/UserModel';

export enum SubscriptionUpgradeType {
  PREMIUM = 'premium'
}

interface PremiumUpgrade {
  type: SubscriptionUpgradeType.PREMIUM;
  planInterval: Cadence;
}

export type SubscriptionUpgrade = PremiumUpgrade;

export interface ChargebeeSubscription {
  id: string;
}
