import Parse from 'parse';
import { Client } from 'src/util/IsParseDomain';
import ErrorListModel from 'src/models/ErrorListModel';

export const getById = async (id: string): Promise<Parse.Object> => {
  try {
    const query = new Parse.Query<Parse.Object>(Client);
    return await query.get(id);
  } catch (error) {
    ErrorListModel.pushError(error);
    throw error;
  }
};

export const getByRemoteId = async (remoteId: string): Promise<Parse.Object | void> => {
  try {
    const query = new Parse.Query(Client);
    query.equalTo('remoteId', remoteId);
    return await query.first();
  } catch (error) {
    ErrorListModel.pushError(error);
    throw error;
  }
};
