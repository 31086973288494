import React from 'react';

import { CurrencyDisplay } from '../Currency';
import {
  isYearlyTotalSummary,
  YearlyBalanceDueSummary,
  YearlySummaryResponse,
  YearlyTotalSummary
} from './hooks/useYearlyBalanceOrTotals';

interface Props {
  year: number;
  isLoading: boolean;
  data: YearlySummaryResponse | undefined;
  isSearching: boolean;
  isError: boolean;
}

export const YearSummaryRow = ({ year, isLoading, data, isSearching, isError }: Props) => {
  const balanceDueOrTotal = () => {
    if (isLoading || !data || data.results.length === 0) {
      return 0;
    }

    if (isYearlyTotalSummary(data.results[0])) {
      return (data.results as YearlyTotalSummary[]).filter((res) => res.year === year)[0].total;
    } else {
      return (data.results as YearlyBalanceDueSummary[]).filter((res) => res.year === year)[0]
        .balanceDue;
    }
  };

  const balanceDueOrTotalValue = balanceDueOrTotal();

  return (
    <tr key={`year-${year}`} className="invoice-row-year">
      <td>{year}</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td className="d-none d-sm-table-cell">&nbsp;</td>
      <td
        style={{
          paddingRight: 7.5
        }}>
        <div style={{ textAlign: 'right', minWidth: 100 }}>
          {!isSearching && !isError && (
            <CurrencyDisplay
              value={balanceDueOrTotalValue}
              textStyle={{
                fontSize: '12px',
                fontWeight: 500,
                opacity: isLoading ? 0.5 : 1
              }}
            />
          )}
        </div>
      </td>
      <td className="d-none d-sm-table-cell">&nbsp;</td>
    </tr>
  );
};
