import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { FormattedMessage } from 'react-intl';

const _ConsentCheckbox: ForwardRefRenderFunction<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = (props, ref) => {
  const { form } = props;

  return (
    <div className="flex gap-3">
      <input
        data-testid="consent-checkbox"
        id="consent"
        form={form}
        className="leading-tight rounded-sm mt-1"
        type="checkbox"
        ref={ref}
        {...props}
      />
      <label data-testid="consent-label" className="text-slate-600" form={form} htmlFor="consent">
        <FormattedMessage id="signup.form.labels.consent" />{' '}
        <FormattedMessage
          id="signup.form.labels.privacy"
          values={{
            privacyUrl: (chunks) => (
              <a
                href="https://www.invoicesimple.com/privacy"
                target="_blank"
                className="underline underline-offset-[2.5px]"
                rel="noreferrer">
                {chunks}
              </a>
            )
          }}
        />
      </label>
    </div>
  );
};

export const ConsentCheckbox = forwardRef(_ConsentCheckbox);
