import { SettingKeys } from '@invoice-simple/common';
import UserModel from 'src/models/UserModel';
import { defineMessages } from 'src/i18n';
import InvoiceModel from 'src/models/InvoiceModel';

const messages = defineMessages({
  tax: {
    id: 'taxLabel.tax',
    defaultMessage: 'tax'
  }
});

/**
 * Return tax label in order of preference:
 * 1. Invoice specific tax label, if set
 * 2. Global settings tax label, if set
 * 3. Translated "tax" term
 * @param doc - current document
 * @param formatMessage  - react-intl formatMessage
 */
export const getTaxLabel = (doc: InvoiceModel, formatMessage: (message) => string): string => {
  if (typeof doc.taxLabel === 'string' && doc.taxLabel.trim() !== '') {
    return doc.taxLabel;
  }
  const settingsValue = UserModel.getInstance().settingList.getSetting(SettingKeys.TaxLabel);
  if (settingsValue && settingsValue.valStr && settingsValue.valStr.trim() !== '') {
    return settingsValue.valStr;
  }
  return formatMessage(messages.tax);
};
