import { QueryClient } from '@tanstack/react-query';
import { IReactionDisposer, action, autorun, observable, when } from 'mobx';

import alertModel from 'src/models/AlertModel';
import { InvoiceModel } from 'src/models/InvoiceModel';
import locationModel from 'src/models/LocationModel';
import UserModel from 'src/models/UserModel';
import { Invoice as ParseInvoice } from 'src/util/IsParseDomain';
import { navInvoiceLimit } from 'src/util/navigation';
import { getSafeNumber } from 'src/util/safeNumber';
import { URLQueryParamKeys, getURLQueryParam } from 'src/util/url';

const queryClient = new QueryClient();

class CurrentDocumentStore {
  user: UserModel;

  locationChangeReaction: IReactionDisposer;
  @observable currentDoc: InvoiceModel;
  @observable currentDocId: string | null;

  constructor() {
    this.loadDocument = this.loadDocument.bind(this);
    this.locationChangeReaction = autorun(this.loadDocument);
  }

  private async loadDocument() {
    // accessing locationModel.fullPath to trigger reaction
    locationModel.fullPath;

    if (locationModel.fullPath && locationModel.isGuestDoc) {
      await this.createNewDocument();
    } else if (locationModel.isPrivateDoc || locationModel.isDocHistory) {
      await this.setCurrentDocById(locationModel.id);
    }
  }

  @action.bound
  setCurrentDoc(doc: InvoiceModel) {
    this.currentDoc = doc;
  }

  @action.bound
  public async setCurrentDocById(id: string) {
    // do nothing if browsing the same doc
    if (!!this.currentDoc && this.currentDoc.id === id && id !== undefined) {
      return Promise.resolve();
    }
    this.setCurrentDoc(new InvoiceModel(UserModel.getInstance(), new ParseInvoice({ id })));
    await this.currentDoc.load();
    this.currentDoc.startAutoSave();
  }

  @action.bound
  public async createNewDocument() {
    try {
      const user = UserModel.getInstance();

      if (!user.canCreateNewDoc(locationModel.docType)) {
        return navInvoiceLimit(user);
      }

      // create empty doc
      this.setCurrentDoc(
        new InvoiceModel(
          user,
          new ParseInvoice(user.settingList.getDocumentsDefaults(locationModel.docType))
        )
      );

      when(
        () => !user.settingList.isLoading,
        () => {
          // update the current doc with defaults
          this.currentDoc.update(
            new ParseInvoice(user.settingList.getDocumentsDefaults(locationModel.docType))
          );

          const laborCostsStr = getURLQueryParam(URLQueryParamKeys.LABOR_COSTS);
          const materialCostsStr = getURLQueryParam(URLQueryParamKeys.MATERIAL_COSTS);
          if (laborCostsStr || materialCostsStr) {
            const laborCosts = Number(laborCostsStr) || 0;
            const materialCosts = Number(materialCostsStr) || 0;

            this.currentDoc.items = [];
            if (laborCosts) {
              this.currentDoc.addItem({
                code: 'Labor costs',
                rate: getSafeNumber(laborCosts)
              });
            }
            if (materialCosts) {
              this.currentDoc.addItem({
                code: 'Material costs',
                rate: getSafeNumber(materialCosts)
              });
            }
            this.currentDoc.save();
          }

          this.currentDoc.startAutoSave();
        }
      );
      await queryClient.invalidateQueries();
    } catch (err) {
      locationModel.nav(`${locationModel.canonicalDocName}List`);

      alertModel.setAlertObject({
        type: 'danger',
        titleMessageId: 'alertInvoiceCreateFailedTitle',
        body: err.message
      });
    }
  }
}

export default new CurrentDocumentStore();
