import { useEffect, useState } from 'react';

import UserEventModel from 'src/models/UserEventModel';

import { PaymentEventActivePaymentProvider, PaymentEventComponent } from '../types';

export type PaymentsCtaViewedEventMeta = {
  activePaymentProvider?: PaymentEventActivePaymentProvider;
  component: PaymentEventComponent;
};

export const useTrackPaymentsViewedEvent = (
  meta: PaymentsCtaViewedEventMeta,
  events: UserEventModel
) => {
  const [eventCalled, setEventCalled] = useState(false);

  useEffect(() => {
    if (!eventCalled) {
      events.trackAction('payments-viewed', meta);
      setEventCalled(true);
    }
  }, [eventCalled, events]);
};
