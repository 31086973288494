import React from 'react';
import { observer } from 'mobx-react';
import { injectIntl, defineMessages } from 'src/i18n';
import { compose } from 'src/util/functions';
import { ConfirmDeleteButton } from './ConfirmDeleteButton';
import injectStore from 'src/util/injectStore';
import currentDocStore from '../stores/CurrentDocumentStore';
import { ISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';

const messages = defineMessages({
  deleteDocument: {
    id: 'invoice.invoiceControls.delete',
    defaultMessage: 'delete {docType}'
  },
  confirmDocumentRemoval: {
    id: 'invoice.invoiceControls.confirmRemoval',
    defaultMessage: 'confirm'
  },
  cancelDocumentRemoval: {
    id: 'invoice.invoiceControls.cancelRemoval',
    defaultMessage: 'cancel'
  },
  restoreDocument: {
    id: 'invoice.invoiceControls.restoreDocument',
    defaultMessage: 'restore the {docType}'
  }
});

interface Prop extends React.HTMLProps<HTMLButtonElement> {
  store: AppStore;
  intl: ISIntl;
}

class ConfirmInvoiceDeleteButton extends React.Component<Prop, any> {
  constructor(opt) {
    super(opt);
    this.state = {
      isPendingTransaction: false
    };
  }
  render() {
    const props = this.props;
    const invoice = props.store.doc;
    const { fta, ft, getTitleizedDocumentType } = this.props.intl;
    const docType = getTitleizedDocumentType(currentDocStore.currentDoc.docType);
    if (this.state.isPendingTransaction) return null;
    return (
      <ConfirmDeleteButton
        className={props.className}
        titleRemove={fta(messages.deleteDocument, { docType })}
        titleConfirm={fta(messages.confirmDocumentRemoval)}
        titleCancel={fta(messages.cancelDocumentRemoval)}
        titleRestore={ft(messages.restoreDocument)}
        displayLabel={true}
        disabled={props.store.isLoading || invoice.isNew}
        pending={invoice.delete}
        confirmed={invoice.deleted}
        onPending={() => invoice.toggleDelete()}
        onConfirm={() => {
          this.setState({ isPendingTransaction: true });
          if (invoice.deleted) {
            return invoice.markUndeleted();
          } else {
            return invoice.markDeleted();
          }
        }}
      />
    );
  }
}

export default compose(injectIntl, injectStore, observer)(ConfirmInvoiceDeleteButton);
