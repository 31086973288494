import React, { FC, useCallback, useEffect, useState } from 'react';

import lodashIsEqual from 'lodash/isEqual';
import { trackEvent } from 'src/analytics/controller';
import { useISIntl } from 'src/i18n';
import { usePaymentsDashboardEligibility } from 'src/payments/hooks/usePaymentsDashboardEligibility';
import {
  PaymentEventActivePaymentProvider,
  PaymentEventComponent,
  PaymentsProviderName
} from 'src/payments/types';

import { PaymentsOrders } from '../PaymentsOrders';
import { PaypalPaymentsOrdersProps } from '../PaymentsOrders/PaymentsOrders';
import { messages } from './messages';
import { StripePayouts } from './StripePayouts';
import { StripeTransactions } from './StripeTransactions';

enum Tabs {
  STRIPE_TRANSACTIONS_TAB = 'stripeTransactions',
  STRIPE_PAYOUTS_TAB = 'stripePayouts',
  PAYPAL_TAB = 'paypalPayments'
}

const tabsToEventMeta = {
  [Tabs.STRIPE_TRANSACTIONS_TAB]: {
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    component: PaymentEventComponent.stripePaymentsTab
  },
  [Tabs.STRIPE_PAYOUTS_TAB]: {
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    component: PaymentEventComponent.stripePayoutsTab
  },
  [Tabs.PAYPAL_TAB]: {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    component: PaymentEventComponent.paypalPaymentsTab
  }
};

export const PaymentsDashboardOnboarded: FC<PaypalPaymentsOrdersProps> = (props) => {
  const { fta } = useISIntl();
  const [activeTab, setActiveTab] = useState<Tabs | null>(null);
  const paymentsDashboardEligibility = usePaymentsDashboardEligibility();
  const [tabs, setTabs] = useState<Tabs[]>([]);

  useEffect(() => {
    const tempTabs: Tabs[] = [];
    if (paymentsDashboardEligibility.accepting.includes(PaymentsProviderName.STRIPE)) {
      tempTabs.push(Tabs.STRIPE_TRANSACTIONS_TAB);
      tempTabs.push(Tabs.STRIPE_PAYOUTS_TAB);
    }
    if (paymentsDashboardEligibility.accepting.includes(PaymentsProviderName.PAYPAL)) {
      tempTabs.push(Tabs.PAYPAL_TAB);
    }

    if (lodashIsEqual(tempTabs, tabs)) {
      return;
    }
    setTabs(tempTabs);
  }, [paymentsDashboardEligibility.accepting]);

  const onClickHandlerWithEvents = useCallback((tab: Tabs) => {
    setActiveTab(tab);
    const { activePaymentProvider, component } = tabsToEventMeta[tab];
    trackEvent('payments-cta', {
      activePaymentProvider,
      component,
      source: 'payments-dashboard'
    });
  }, []);

  useEffect(() => {
    if (activeTab === null && tabs.length > 0) {
      setActiveTab(tabs[0]);
    }
  }, [tabs]);

  return (
    <div id="tailwind" data-testid="PaymentsDashboardOnboarded">
      <div className="w-full">
        <div className="min-[576px]:w-[540px] min-[768px]:w-[720px] min-[992px]:w-[960px] min-[1200px]:w-[1140px] mx-auto px-4">
          <div className="flex text-start w-fit border-2 border-gray-300 rounded-xl overflow-hidden mb-4">
            {tabs.map((tab: Tabs) => (
              <div
                className={`cursor-pointer w-auto bg-gray-200 px-4 py-3 text-2xl text-gray-700 font-medium ${
                  tab === activeTab
                    ? '[box-shadow:inset_0_1px_5px_rgba(0,_0,_0,_0.3),_0_1px_0_rgba(255,_255,_255,_0.5)]'
                    : ''
                } transition-all ease-in border`}
                key={tab}
                onClick={() => onClickHandlerWithEvents(tab)}>
                {fta(messages[tab])}
              </div>
            ))}
          </div>
          {activeTab === Tabs.STRIPE_TRANSACTIONS_TAB && <StripeTransactions />}
          {activeTab === Tabs.STRIPE_PAYOUTS_TAB && <StripePayouts />}
        </div>
        {activeTab === Tabs.PAYPAL_TAB && <PaymentsOrders {...props} />}
      </div>
    </div>
  );
};
