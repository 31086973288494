import { parse, format, differenceInCalendarDays, startOfDay } from 'date-fns';

const DATE_FORMAT = 'yyyy-MM-dd';

export const stringToDate = (dateString: string) => parse(dateString, DATE_FORMAT, new Date());
export const dateToString = (date: Date) => {
  try {
    return format(date, DATE_FORMAT);
  } catch (_) {
    return format(new Date(), DATE_FORMAT);
  }
};

export const byDate = (a: Date, b: Date): number => a.getTime() - b.getTime();

export const getCurrentYear = (): string => {
  return new Date().getFullYear().toString();
};

/**
 * Get days remaining till invoice due date
 * @param dueDate invoice due date
 */
export const getDaysRemaining = (dueDate: Date): number =>
  differenceInCalendarDays(startOfDay(dueDate), Date.now());

/**
 * Get date string without the day part
 * (e.g. Jun 25 2023)
 */
export const getFormattedDateString = (timestamp: number): string => {
  return new Date(timestamp).toDateString().split(' ').slice(1).join(' ');
};
