import { useContext } from 'react';
import { DialogModalContext } from 'client/src/components/DialogModal/DialogModalContext';

export const useDialog = () => {
  const context = useContext(DialogModalContext);
  if (context === null) {
    throw new Error('Component has no Provider to consume context from');
  }
  return context;
};
