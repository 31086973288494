import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './InlineButton.scss';

interface Props {
  label: string;
  icon?: IconProp;
  inverted?: boolean;
  [key: string]: any;
}

const InlineButton = ({ label, icon, inverted = false, ...rest }: Props) => (
  <div className={`add-input-field-button ${cn({ inverted })}`} role="button" {...rest}>
    {icon && <FontAwesomeIcon icon={icon} />}
    <span>{label}</span>
  </div>
);

export default InlineButton;
