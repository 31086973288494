import React, { FC } from 'react';
import { usePaypalContext } from 'src/payments/contexts/PaypalAccountContext';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { PaypalClosedBox } from './PaypalClosedBox';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { PaypalInitialBox } from './PaypalInitialBox';
import { PaymentMethod, useCTAHandler } from '../useCTAHandler';
import { PaypalInvoiceAcceptingBox } from './PaypalAcceptingBox/PaypalInvoiceAcceptingBox';
import { PaypalPendingOnboardedBox } from './PaypalPendingOnboardedBox';
import { getPaymentEventSource } from 'src/payments/utils';

const InvoicePaypalBoxWithStore: FC<{ store: AppStore }> = ({ store }) => {
  const paypalSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource()
  };
  const paypalAccountContext = usePaypalContext();
  const { handleInitialClick } = useCTAHandler(PaymentMethod.Paypal);
  const { events } = store.user;

  const handlePaypalClosedClick = () => {
    events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.tileClosed
    });
    showPaypalModal();
  };

  if (!paypalAccountContext?.isPaypalEligible()) {
    return null;
  }
  const {
    isPaypalClosed,
    isPaypalInitial,
    isPaypalPending,
    isPaypalOnboarded,
    isPaypalAccepting,
    isPrimaryEmailConfirmed,
    showPaypalModal
  } = paypalAccountContext;

  const handlePaypalInitialClick = () => {
    handleInitialClick(store, showPaypalModal);
  };

  function handlePaypalPendingClick() {
    events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.tilePending
    });
    showPaypalModal();
  }

  function handlePaypalOnboardedClick() {
    events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: isPrimaryEmailConfirmed()
        ? PaymentEventComponent.tileOnboardedEmailConfirmed
        : PaymentEventComponent.tileOnboarded
    });
    showPaypalModal();
  }

  return (
    <div data-testid="PaypalInvoiceBox" id="tailwind">
      {isPaypalClosed() && <PaypalClosedBox onCTAClick={handlePaypalClosedClick} />}
      {isPaypalInitial() && <PaypalInitialBox onCTAClick={handlePaypalInitialClick} />}
      {isPaypalPending() && <PaypalPendingOnboardedBox onCTAClick={handlePaypalPendingClick} />}
      {isPaypalOnboarded() && <PaypalPendingOnboardedBox onCTAClick={handlePaypalOnboardedClick} />}
      {isPaypalAccepting() && <PaypalInvoiceAcceptingBox />}
    </div>
  );
};

export const InvoicePaypalBox = injectStore(InvoicePaypalBoxWithStore);
