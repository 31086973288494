import React from 'react';

export function Amex(): JSX.Element {
  return (
    <svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.82059 0.645508H37.7431C39.12 0.645508 40.2375 1.6274 40.2375 2.83839V23.6608C40.2375 24.8708 39.12 25.8526 37.7431 25.8526H2.82059C1.4424 25.8528 0.326172 24.8708 0.326172 23.661V2.83839C0.326172 1.6274 1.44363 0.645508 2.82059 0.645508Z"
        fill="#2E77BB"
      />
      <path
        d="M8.05375 9.34277L4.52734 17.1466H8.74895L9.27231 15.9023H10.4686L10.9919 17.1466H15.6387V16.197L16.0528 17.1466H18.4565L18.8705 16.1769V17.1466H28.5346L29.7097 15.9347L30.8101 17.1466L35.7737 17.1566L32.2362 13.2665L35.7737 9.34277H30.887L29.7432 10.5323L28.6775 9.34277H18.1642L17.2614 11.357L16.3375 9.34277H12.1247V10.2601L11.656 9.34277C11.656 9.34277 8.05375 9.34277 8.05375 9.34277ZM8.87061 10.4509H10.9284L13.2675 15.7427V10.4509H15.5217L17.3284 14.2451L18.9934 10.4509H21.2364V16.0507H19.8716L19.8605 11.6627L17.8707 16.0507H16.6498L14.6489 11.6627V16.0507H11.8412L11.3089 14.7953H8.43317L7.90199 16.0496H6.39764L8.87061 10.4509ZM22.4851 10.4509H28.0347L29.732 12.2844L31.4841 10.4509H33.1814L30.6025 13.2654L33.1814 16.0474H31.4071L29.7097 14.1926L27.9487 16.0474H22.4851V10.4509ZM9.87167 11.3984L8.92422 13.6348H10.818L9.87167 11.3984ZM23.8555 11.6104V12.6326H26.8831V13.772H23.8555V14.8879H27.2513L28.8292 13.2442L27.3183 11.6094H23.8555V11.6104Z"
        fill="white"
      />
    </svg>
  );
}
