import { getStripeToken } from './stripeToken';
import environmentStore from 'src/stores/EnvironmentStore';
import { OnboardOrigin } from 'src/payments/types';
import { ContentTypes } from '@invoice-simple/common';

async function getHeaders(isPublicEndpoint?: boolean) {
  const headers: Record<string, string> = {
    'Content-Type': ContentTypes.APP_JSON,
    'x-is-platform': 'web',
    'x-is-version': environmentStore.getReactAppVersion()
  };
  if (isPublicEndpoint) {
    return headers;
  }

  const token = await getStripeToken();
  headers.Authorization = `Bearer ${token}`;
  return headers;
}

export async function invokeIsStripe<T = unknown, U = unknown>(
  method: 'POST' | 'GET' | 'PUT' | 'PATCH',
  endpoint: string,
  body?: U,
  isPublicEndpoint?: boolean
): Promise<T> {
  const url = `${process.env.REACT_APP_STRIPE_URL}${endpoint}`;
  const headers = await getHeaders(isPublicEndpoint);
  try {
    const res = await fetch(url, { method, headers, body: JSON.stringify(body) });
    if (res.status >= 400) {
      throw new Error(res.statusText);
    }
    return res.json();
  } catch (error) {
    throw new Error(`Unexpected error calling api ${method} ${url}: ${error.message}`);
  }
}

const navigateHard = (url: string, isNewTab: boolean = false) => {
  if (isNewTab) {
    window.open(url, '_blank');
    return;
  }
  window.location.assign(url);
};

export async function startStripeOnboarding(origin: OnboardOrigin, isNewTab: boolean = true) {
  try {
    navigateHard(
      `${
        process.env.REACT_APP_STRIPE_URL
      }/connect?token=${await getStripeToken()}&origin=${origin}`,
      isNewTab
    );
  } catch (error) {
    throw new Error(`Error starting Stripe onboarding: ${error.message}`);
  }
}
