import React from 'react';
import { observer } from 'mobx-react';
import Autosuggest from 'react-autosuggest';

import { injectIntl, defineMessages } from 'src/i18n';
import ItemSuggestModel from '../models/ItemSuggestModel';
import './AutoSuggest.css';
import './ItemSuggest.css';
import { ISIntl } from 'src/i18n/utils';
import ItemModel from 'src/models/ItemModel';

interface Prop {
  value: string;
  disabled: boolean;
  onChange: (newValue: any) => void;
  onSelect: (suggestion: any) => void;
  suggest: ItemSuggestModel;
  intl?: ISIntl;
}

const messages = defineMessages({
  itemDescription: {
    id: 'invoice.itemSuggest',
    defaultMessage: 'item description'
  }
});

@injectIntl
@observer
export default class ItemSuggest extends React.Component<Prop, any> {
  onChange = (_event, { newValue }) => {
    this.props.onChange(newValue);
  };
  onSuggestionsClearRequested = () => {
    this.props.suggest.clearCode();
  };
  onSuggestionsFetchRequested = ({ value }) => {
    this.props.suggest.setCode(value);
  };
  onSuggestionSelected = (_, data) => {
    this.props.onSelect(data.suggestion);
  };
  getSuggestionValue = (suggestion: ItemModel) => {
    return suggestion.code;
  };
  renderSuggestion(suggestion: ItemModel) {
    return (
      <div className="invoice-suggestion-option clearfix">
        <span className="invoice-suggestion-name">{suggestion.code}</span>
        <span className="invoice-suggestion-meta">{suggestion.description}</span>
      </div>
    );
  }
  render() {
    const suggestions = this.props.suggest.items.slice();
    return (
      <div className="item-suggest">
        <Autosuggest
          inputProps={{
            id: 'invoice-item-code',
            placeholder: this.props.intl!.fta(messages.itemDescription),
            disabled: this.props.disabled,
            value: this.props.value,
            onChange: this.onChange
          }}
          suggestions={suggestions}
          getSuggestionValue={this.getSuggestionValue}
          onSuggestionSelected={this.onSuggestionSelected}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          renderSuggestion={this.renderSuggestion}
        />
      </div>
    );
  }
}
