import React from 'react';
import './styles/RadioButton.scss';

export interface Option {
  id: string;
  value: number;
  label: string;
}

export interface RadioButtonProps {
  options: Option[];
  selectedOption: number;
  onChange: (event: number) => void;
}

export const RadioButton: React.FC<RadioButtonProps> = ({ options, selectedOption, onChange }) => {
  return (
    <div>
      {options.map((option) => (
        <div key={option.value}>
          <input
            type="radio"
            value={option.value}
            checked={selectedOption === option.value}
            className="radio-button"
            onChange={() => {}}
            id={option.id}
          />
          <label
            key={option.value}
            onClick={() => onChange(option.value)}
            htmlFor={option.id}
            data-testid={option.id}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};
