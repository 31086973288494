import React from 'react';
import { onError, redirectToPaypalDashboard } from '../../utils/utils';
import { PaymentSetupButton } from '../PaymentBasicElements';

export interface ManageAccountDictionary {
  manageAccountButtonText: string;
}

interface Props {
  dictionary: ManageAccountDictionary;
}

export function ManageAccount({ dictionary }: Props) {
  const goToPaypalDashboard = () => {
    try {
      redirectToPaypalDashboard();
    } catch (error) {
      onError({ title: 'Error loading PayPal Dashboard', body: error.message });
    }
  };
  return (
    <div className="col-md-12 mt-3 mb-4">
      <PaymentSetupButton
        buttonText={dictionary.manageAccountButtonText}
        buttonBackgroundColor="linear-gradient"
        loading={false}
        onButtonClick={goToPaypalDashboard}
        buttonTextColor="#555555"
      />
    </div>
  );
}
