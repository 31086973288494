import React from 'react';

import { messages } from 'src/components/SubscriptionPaywall/utils';
import { useISIntl } from 'src/i18n';

interface PopularBadgeProps {
  isTierSelected: boolean;
}

export const PopularBadge = ({ isTierSelected }: PopularBadgeProps) => {
  const { ft } = useISIntl();
  return (
    <div
      className={`rounded-full w-fit px-4 py-1 absolute -top-6 left-0 right-0 mx-auto ${isTierSelected ? 'bg-orange-50' : 'bg-white'}`}
      style={{ border: `2px solid ${isTierSelected ? '#FF5721' : '#6B7280'}` }}>
      <p className="text-center text-3xl font-bold text-gray-950 capitalize">
        {ft(messages.mostPopular)}
      </p>
    </div>
  );
};
