import React, { useEffect } from 'react';

import { Platform, SubscriptionTier } from '@invoice-simple/common';
import { FeatureName } from '@invoice-simple/feature-gate';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISIntl } from 'src/i18n';
import alertModel from 'src/models/AlertModel';
import { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { titleizeAll } from 'src/util/Titleize';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import { LoadingSpinner } from '../Button';
import { BackButton } from './components/BackButton';
import { PlanIntervalSelector } from './components/PlanIntervalSelector';
import { ValueProps } from './components/ValueProps';
import { useInterval } from './hooks/useInterval';
import { useUpgrade } from './hooks/useUpgrade';
import { getAlertMessageFromFeature, getIntervalText } from './utils';
import { messages } from './utils/messages';

interface Props {
  store: AppStore;
  intl: ISIntl;
  paywallTier: SubscriptionTier.PREMIUM | SubscriptionTier.PREMIUM_LEGACY;
}

export const PremiumOnly = ({ store, intl, paywallTier }: Props) => {
  const { user } = store;
  const { formatMessage, ft, fta } = intl;

  const { selectedInterval, toggleInterval, shouldDisallowSwitch } = useInterval(
    user.activeSubscriptionCadence
  );

  useEffect(() => {
    const userTier = user.activeSubscriptionTier || undefined;
    const userCadence = user.activeSubscriptionCadence ?? selectedInterval;
    const feature = getURLQueryParam(URLQueryParamKeys.FEATURE) as FeatureName;
    const campaign = getURLQueryParam(URLQueryParamKeys.CAMPAIGN) as string;
    const ref = getURLQueryParam(URLQueryParamKeys.REF);

    user.events.trackAction('subscription-paywall', {
      ...store.getTieredPaywallEventData(userTier, userCadence),
      tier: userTier,
      from: feature ?? ref,
      campaign: campaign || undefined,
      path: ref,
      platform: Platform.WEB
    });

    /* Feature gating upgrade message */
    if (feature) {
      const { title, body } = getAlertMessageFromFeature(feature, ft);
      alertModel.setAlert('warning', title, body);
    }
  }, []);

  const { switching, loadingCheckout, onUpgradeClick, OriginalPrice, FinalPrice, isCurrentPlan } =
    useUpgrade({
      store,
      intl,
      selectedInterval,
      shouldDisallowSwitch
    });

  const loading = loadingCheckout || switching;

  const intervalText = getIntervalText(selectedInterval);

  const getButtonText = () =>
    isCurrentPlan(paywallTier) ? fta(messages.currentActivePlan) : ft(messages.upgrade);

  const onSupportClick = async () => {
    window.open('https://help.invoicesimple.com');
  };

  return (
    <div id="tailwind">
      <div
        className="relative mx-auto sm:py-24 px-4 sm:px-6 lg:px-8"
        style={{ maxWidth: '1000px' }}>
        <BackButton />
        <div className="sm:align-center sm:flex sm:flex-col">
          <div className="md:max-w-[650px] text-5xl font-bold text-gray-900 sm:text-center my-0 mx-auto">
            <img
              data-testid="is-logo"
              className="h-24 sm:h-28 w-auto rounded"
              src="images/is-logo.png"
              alt="Invoice Simple Logo"
            />
            <>
              <h2
                data-testid="upgrade-now-title"
                className="my-4 text-gray-900 font-bold"
                style={{ fontSize: 30, lineHeight: '36px' }}>
                {ft(messages.paywallTitle)}
              </h2>
              <p
                data-testid="upgrade-now-subtitle"
                className="text-gray-800 my-0 mx-auto"
                style={{ fontSize: 18, fontWeight: 400, lineHeight: '28px' }}>
                {ft(messages.paywallSubtitleUpgrade)}
              </p>
            </>
            <ValueProps paywallVariant={'premium-only'} />
          </div>
        </div>
        <div className="mt-[20px]">
          <div className="bg-white pt-[10px] items-center flex flex-col sticky top-0">
            <PlanIntervalSelector
              selectedInterval={selectedInterval}
              onToggleInterval={toggleInterval}
              formatMessage={formatMessage}
              isHidden={user.hasAnnualSub}
            />

            <div className="flex-col w-[300px] border rounded-xl shadow-md justify-center mt-[20px]">
              <div className="h-[130px] border-bottom border-gray-200 p-[24px]">
                <span className="w-1/4 pb-4 text-left text-3xl font-medium text-gray-900">
                  {titleizeAll(paywallTier)}
                </span>
                <p className={'mt-10 flex flex-col'}>
                  <span className="font-medium">
                    {selectedInterval === Cadence.ANNUAL && (
                      <OriginalPrice tier={paywallTier} shouldHidePerYear={true} />
                    )}
                  </span>
                  <span className="text-4xl font-extrabold tracking-tight text-gray-900">
                    <FinalPrice tier={paywallTier} isTieredMatrix={true} />{' '}
                    <span className="text-xl font-medium text-gray-500">{intervalText}</span>
                  </span>
                </p>
              </div>
              <div
                data-testid={`${paywallTier}-tier-details`}
                className="h-full align-top p-[24px]">
                <div>
                  <div className="text-xl font-normal space-y-4">
                    <p>
                      <span>{formatMessage(messages.cardBodyCurrentPlan)}</span>{' '}
                      <span className="font-bold">
                        {formatMessage(messages.cardBodyPlus)}
                        {':'}
                      </span>
                    </p>
                    <p className="font-bold">
                      <FontAwesomeIcon
                        icon={faCheck}
                        width={15}
                        height={15}
                        style={{ marginRight: 5 }}
                        color="#4B5563"
                      />
                      {formatMessage(messages.proDocLimit)}
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faCheck}
                        width={15}
                        height={15}
                        style={{ marginRight: 5 }}
                        color="#4B5563"
                      />
                      {formatMessage(messages.featureClientSignature)}
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faCheck}
                        width={15}
                        height={15}
                        style={{ marginRight: 5 }}
                        color="#4B5563"
                      />
                      {formatMessage(messages.deposits)}
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faCheck}
                        width={15}
                        height={15}
                        style={{ marginRight: 5 }}
                        color="#4B5563"
                      />
                      {formatMessage(messages.featurePremiumTemplates)}
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faCheck}
                        width={15}
                        height={15}
                        style={{ marginRight: 5 }}
                        color="#4B5563"
                      />
                      {formatMessage(messages.versionHistory)}
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => onUpgradeClick(paywallTier)}
                    data-testid={`btn-upgrade-tier-${paywallTier}`}
                    disabled={
                      loading ||
                      shouldDisallowSwitch(paywallTier, user) ||
                      isCurrentPlan(paywallTier)
                    }
                    className={`mt-10 no-underline block w-full flex-grow rounded-[4px] transition ${
                      isCurrentPlan(paywallTier)
                        ? 'bg-gray-100 text-gray-900 border border-solid border-gray-200'
                        : 'bg-orange-is text-white border border-orange-is border-solid'
                    } py-3 text-center text-xl font-semibold hover:opacity-70 ${
                      loading || shouldDisallowSwitch(paywallTier, user)
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}>
                    {loading && <LoadingSpinner loadingTextStyle="text-white" />}
                    {getButtonText()}
                  </button>
                </div>
              </div>
            </div>

            <button
              type="button"
              onClick={onSupportClick}
              className="mt-12 !rounded-[6px] border-0 bg-gray-200 px-[21px] py-[13px] w-fit font-medium"
              style={{ fontSize: 14, lineHeight: '22px' }}>
              {ft(messages.discountedPaywallContactSupport)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
