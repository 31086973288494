import { TaxTypes } from '@invoice-simple/common';
import { observer } from 'mobx-react';
import React from 'react';
import NumericInput from 'react-numeric-input';

import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';
import InvoiceModel from 'src/models/InvoiceModel';
import { getTaxLabel } from 'src/models/TaxLabelModel';
import { intlTaxTypeOptions } from '../../i18n/intlTaxTypeOptions';
import { Selectv1Adapter } from '../Selectv1Adapter';

const messages = defineMessages({
  tax: {
    id: 'invoiceSettings.tax',
    defaultMessage: 'Tax'
  },
  taxType: {
    id: 'invoiceSettings.tax.type',
    defaultMessage: 'Type'
  },
  taxLabel: {
    id: 'invoiceSettings.tax.label',
    defaultMessage: 'Label'
  },
  taxRate: {
    id: 'invoiceSettings.tax.rate',
    defaultMessage: 'Rate'
  },
  taxInclusive: {
    id: 'invoiceSettings.tax.inclusive',
    defaultMessage: 'Inclusive?'
  }
});

interface TaxSettingsProps {
  invoice: InvoiceModel;
  isLoading: boolean;
}

export const TaxSettings = observer(({ invoice, isLoading }: TaxSettingsProps) => {
  const intl = useISIntl();
  const taxTitle = getTaxLabel(invoice, intl.fta);

  return (
    <>
      <h4>{taxTitle}</h4>
      <table className="invoice-detail-about">
        <tbody>
          <tr>
            <td>
              <label htmlFor="invoice-tax-type">{intl.formatMessage(messages.taxType)}</label>
            </td>
            <td>
              <Selectv1Adapter
                id="invoice-tax-type"
                className="invoice-tax-type-input"
                name="invoiceTaxType"
                isClearable={false}
                value={invoice.setting.taxType || TaxTypes.TAX_NONE}
                options={intlTaxTypeOptions(intl)}
                onChange={(s) => {
                  if (s) {
                    invoice.setTaxType(s.value);
                  }
                }}
                isDisabled={isLoading}
              />
            </td>
          </tr>
          {!invoice.isTaxNone && (
            <tr>
              <td>
                <label htmlFor="invoice-tax-label">{intl.formatMessage(messages.taxLabel)}</label>
              </td>
              <td>
                <input
                  id="invoice-tax-label"
                  className="invoice-tax-label-input"
                  name="invoiceTaxLabel"
                  type="text"
                  value={taxTitle}
                  onChange={(e) => invoice.setTaxLabel(e.target.value)}
                  disabled={isLoading}
                />
              </td>
            </tr>
          )}

          {!invoice.isTaxNone && (invoice.isTaxTotal || invoice.isTaxDeducted) && (
            <tr>
              <td>
                <label htmlFor="invoice-tax-rate">{intl.formatMessage(messages.taxRate)}</label>
              </td>
              <td>
                <span>
                  <NumericInput
                    id="invoice-tax-rate"
                    className="invoice-tax-rate-input"
                    name="invoiceTaxRate"
                    value={invoice.setting.taxRate}
                    step={1.0}
                    precision={3}
                    min={0}
                    max={100}
                    format={(n) => {
                      return n + '%';
                    }}
                    onChange={(v) => {
                      invoice.setting.taxRate = v ?? 0;
                    }}
                    disabled={isLoading}
                    style={{
                      input: {
                        paddingLeft: 16
                      }
                    }}
                  />
                </span>
              </td>
            </tr>
          )}

          {!invoice.isTaxNone && (invoice.isTaxTotal || invoice.isTaxItems) && (
            <tr>
              <td>
                <label htmlFor="invoice-tax-inclusive">
                  {intl.formatMessage(messages.taxInclusive)}
                </label>
              </td>
              <td>
                <input
                  id="invoice-tax-inclusive"
                  className="invoice-tax-inclusive-input"
                  name="invoiceTaxInclusive"
                  type="checkbox"
                  checked={invoice.setting.taxInclusive || false}
                  onChange={(e) => {
                    invoice.setTaxInclusive(e.target.checked);
                  }}
                  disabled={isLoading}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
});
