import React from 'react';
import './ToggleSwitch.css';

interface ToggleSwitchProps {
  checked: boolean;
  disabled: boolean;
  wrapperClasses?: string;
  onChange: (checked: boolean) => void;
}

export function ToggleSwitch({
  checked,
  disabled,
  wrapperClasses = '',
  onChange
}: ToggleSwitchProps): JSX.Element {
  return (
    <label className={`switch ${wrapperClasses}`}>
      <input
        data-testid="toggleSwitch"
        type="checkbox"
        className="switch-input"
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span className="slider" />
    </label>
  );
}
