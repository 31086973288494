import React from 'react';
import { observer } from 'mobx-react';
import type InvoiceModel from 'src/models/InvoiceModel';
import { PaymentMarkupFees } from 'src/payments';

const InvoicePaymentFeesObserver = observer(PaymentMarkupFees);

type Props = {
  invoice: InvoiceModel;
};

export const InvoicePaymentFees = observer(({ invoice }: Props) => (
  <InvoicePaymentFeesObserver
    document={{
      docType: invoice.docType,
      paymentSuppressed: invoice.paymentSuppressed,
      stripePaymentSuppressed: invoice.stripePaymentSuppressed,
      currencyCode: invoice.currencyCode,
      invoiceId: invoice.id
    }}
    onMarkupPaymentFeesButtonClick={(paypalFees) => {
      invoice.markupPaymentFees(paypalFees);
    }}
    isMarkingUpPaymentFees={invoice.isMarkingUpPaymentFees}
  />
));
