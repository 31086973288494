import { SettingKeys } from '@invoice-simple/common';

export const TAX_TYPE = 'TAX_TYPE';
export const TAX_RATE = 'TAX_RATE';
export const CURRENCY_CODE = 'CURRENCY_CODE';
export const THEME_COLOR = 'THEME_COLOR';
export const THEME_TEMPLATE = 'THEME_TEMPLATE';
export const CLIENT_SIGNATURE = 'CLIENT_SIGNATURE';
export const DOCUMENT_NUMBER = 'DOCUMENT_NUMBER';
export const NOTES = 'NOTES';
export const UNSPECIFIED = 'UNSPECIFIED';
export const EMAIL = 'EMAIL';
export const PAYMENTS_QR_CODE_TOGGLE = 'PAYMENTS_QR_CODE_TOGGLE';
export const DEFAULT_EMAIL_MESSAGE = 'DEFAULT_EMAIL_MESSAGE';
export const LOCALE_TAX_MONTH = 'LOCALE_TAX_MONTH';
export const LOCALE_DATE_FORMAT = 'LOCALE_DATE_FORMAT';
export const LOCALE_LANGUAGE = 'LOCALE_LANGUAGE';
export const EMAIL_COPY_SENDER = 'EMAIL_COPY_SENDER';
export const PAYMENTS_FEES_TYPE = 'PAYMENTS_FEES_TYPE';
export const PAYMENTS_ALWAYS_ADD_SURCHARGE = 'PAYMENTS_ALWAYS_ADD_SURCHARGE';

export const SETTING_LABELS = {
  LOCALE: SettingKeys.LocaleLanguage,
  COMPANY_NAME: SettingKeys.CompanyName,
  COMPANY_ADDRESS_1: SettingKeys.CompanyAddressLine1,
  COMPANY_ADDRESS_2: SettingKeys.CompanyAddressLine2,
  COMPANY_ADDRESS_3: SettingKeys.CompanyAddressLine3,
  COMPANY_BUSINESS_NUMBER: SettingKeys.CompanyBusinessNumber,
  COMPANY_EMAIL: SettingKeys.ContactEmail,
  COMPANY_PHONE: SettingKeys.ContactPhone,
  COMPANY_LOGO: SettingKeys.CompanyLogo,
  INVOICE: {
    TITLE: SettingKeys.RenameInvoiceTitle
  },
  REQUEST_REVIEW_ENABLED: SettingKeys.RequestReviewEnabled,
  REQUEST_REVIEW_LINK_1: SettingKeys.RequestReviewLink1,
  DEFAULT_EMAIL_MESSAGE: SettingKeys.EmailDefaultMessage
};
