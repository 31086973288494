import React, { FC } from 'react';
import StripeInitialModal from './StripeInitialModal';
import StripePendingModal from './StripePendingModal';
import StripePendingVerificationModal from './StripePendingVerificationModal';
import { useStripeContext } from 'src/payments/contexts/StripeAccountContext';
import { OnboardModalProps } from 'src/payments/types';

export const StripeModalBase: FC<OnboardModalProps> = (props) => {
  const stripeAccountContext = useStripeContext();
  const { isStripeInitial, isStripePending, isStripePendingVerification } =
    stripeAccountContext || {};

  return (
    <>
      <div data-testid="stripeModalBase" />
      {isStripeInitial && <StripeInitialModal {...props} />}
      {isStripePending && <StripePendingModal {...props} />}
      {isStripePendingVerification && <StripePendingVerificationModal {...props} />}
    </>
  );
};
