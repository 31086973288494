import React from 'react';
import ReactSelect, { CSSObjectWithLabel, StylesConfig, components } from 'react-select';

import { IconCheck2 } from './Onboarding/icon-check2';
import { twMerge } from 'tailwind-merge';

type ReactSelectProps = React.ComponentProps<typeof ReactSelect>;

type OptionType<T> = { label: string; value: T };

const styles: StylesConfig = {
  container: (baseStyles): CSSObjectWithLabel => ({
    ...baseStyles,
    width: '100%'
  }),
  control: (): CSSObjectWithLabel => ({
    display: 'flex',
    borderWidth: 1,
    borderRadius: 6,
    borderStyle: 'solid',
    borderColor: '#D1D5DB',
    cursor: 'pointer'
  }),
  indicatorSeparator: (): CSSObjectWithLabel => ({
    display: 'none'
  }),
  menu: (baseStyles): CSSObjectWithLabel => ({
    ...baseStyles,
    marginTop: 10
  }),
  valueContainer: (baseStyles): CSSObjectWithLabel => ({
    ...baseStyles,
    color: '#111827',
    fontSize: 14,
    fontWeight: 400
  }),
  option: (baseStyles, state): CSSObjectWithLabel => ({
    ...baseStyles,
    fontSize: 14,
    cursor: 'pointer',
    fontWeight: state.isSelected ? 600 : 400,
    color: state.isFocused ? '#FFF' : '#111827',
    backgroundColor: state.isFocused ? '#525252' : 'transparent'
  })
};

type DropdownSelectProps<T> = Omit<ReactSelectProps, 'onChange' | 'options' | 'value'> & {
  value: T | undefined;
  label?: string;
  options: OptionType<T>[];
  onChange: (selectedValue: T) => void;
  extStyles?: StylesConfig;
  useValue?: boolean;
};

const { Option } = components;
const IconOption = (props: any) => (
  <Option {...props} className="flex flex-row justify-between active:bg-neutral-600">
    {props.data.label}
    {props.isSelected && <IconCheck2 />}
  </Option>
);

export const DropdownSelect = <T extends string | number>(props: DropdownSelectProps<T>) => {
  const { label, useValue, value, options } = props;

  const selectId = props.id ?? 'dropdown-select';
  const inputId = `${selectId}-input`;

  const formatOptionLabel = (props: any) => {
    const baseClasses = 'w-[70px] sm:w-[45px]';
    const customClasses = useValue ? 'ml-3' : '';

    const classes = twMerge(baseClasses, customClasses);
    return <div className={classes}>{useValue ? props.value : props.label}</div>;
  };

  const Select = () => (
    <ReactSelect
      id={selectId}
      inputId={inputId}
      value={options.find((option) => option.value === value)}
      options={options}
      formatOptionLabel={formatOptionLabel}
      styles={{ ...styles, ...props.extStyles }}
      maxMenuHeight={props.maxMenuHeight ?? 250}
      placeholder={props.placeholder ?? ''}
      isSearchable={props.isSearchable}
      onChange={(selectedOption: any) => props.onChange(selectedOption.value)}
      components={{ Option: IconOption }}
    />
  );

  if (label) {
    return (
      <div className="flex justify-start items-start w-full flex-col">
        <label
          className="text-gray-700 text-[14px] font-semibold leading-tight pb-1"
          htmlFor={inputId}>
          {label}
        </label>

        <Select />
      </div>
    );
  }

  return <Select />;
};

DropdownSelect.displayName = 'DropdownSelect';
