import { SettingKeys } from '@invoice-simple/common';
import { FeatureName } from '@invoice-simple/feature-gate';
import { useFlags } from 'flagsmith/react';
import React, { useEffect, useState } from 'react';

import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { useISIntl } from 'src/i18n';
import InvoiceModel from 'src/models/InvoiceModel';
import UserModel from 'src/models/UserModel';
import { LockIcon } from '../Icons/LockIcon';
import { navToPaywallWithFeatureAndRef } from 'src/util/navigation';
import { URLQueryParamKeys } from 'src/util/url';

interface RequestReviewProps {
  user: UserModel;
  invoice: InvoiceModel;
}

export const RequestClientSignature = ({ user, invoice }: RequestReviewProps) => {
  const intl = useISIntl();
  const clientSignatureGlobalSetting = user.settingList.getSetting(
    SettingKeys.EstimateSignatureRequired
  );

  const hasSignatureAccess = user.canUseFeature(FeatureName.CLIENT_SIGNATURE);
  const [toggleSwitch, setToggleSwitch] = useState(
    hasSignatureAccess && !!invoice.setting.estimateSignatureRequired
  );

  useEffect(() => {
    setToggleSwitch(hasSignatureAccess && !!invoice.setting.estimateSignatureRequired);
  }, [hasSignatureAccess]);

  const { client_signatures } = useFlags(['client_signatures']);
  if (!client_signatures.enabled) return null;

  const handleToggle = (checked: boolean) => {
    if (!hasSignatureAccess) {
      return navToPaywallWithFeatureAndRef(
        FeatureName.CLIENT_SIGNATURE,
        URLQueryParamKeys.ESTIMATE_EDITOR
      );
    }
    setToggleSwitch(checked);
    clientSignatureGlobalSetting?.setValue(checked);
    invoice.setEstimateSignatureRequired(checked);
  };

  return (
    <>
      <h4>
        {intl.formatMessage({
          id: 'settings.list.clientSignatures',
          defaultMessage: 'client signatures'
        })}
      </h4>
      <div className="request-client-signatures mt-3">
        <span
          data-testid="request-client-signatures-heading"
          className={`d-inline-flex align-items-center`}>
          {intl.formatMessage({
            id: 'invoiceSettings.clientSignatures.request',
            defaultMessage: 'Request client signatures'
          })}
        </span>
        {!hasSignatureAccess && <LockIcon height="15px" width="15px" className="ml-1" />}
        <ToggleSwitch
          wrapperClasses="ml-3"
          disabled={false}
          checked={toggleSwitch}
          onChange={handleToggle}
        />
      </div>
    </>
  );
};
