import React from 'react';

import { defineMessages } from 'src/i18n';

const onboardedLink = {
  connectBankAccount:
    'https://www.paypal.com/us/smarthelp/article/how-do-i-link-a-bank-account-to-my-paypal-account-faq3275',
  turnOnAutoWithdrawls:
    "https://www.paypal-community.com/t5/PayPal-Mobile-Other-Services/how-do-I-turn-auto-sweep-on/td-p/372380#:~:text=Here's%20how%20to%20use%20Auto,to%2C%20and%20click%20'Save."
};

export const messages = defineMessages({
  header: {
    id: 'setting.payment.promotionbox.header',
    defaultMessage: 'PAYMENTS'
  },
  acceptPayments: {
    id: 'payment.acceptingPayment',
    defaultMessage: 'Accept Online Payments'
  },
  onboardingBody: {
    id: 'setting.payment.promotionbox.onboarding.body',
    defaultMessage: 'Get paid faster and grow your business'
  },
  onboardingNote: {
    id: 'setting.payment.promotionbox.onboarding.note',
    defaultMessage:
      'Impress your clients and boost your sales by accepting credit cards, debit cards, PayPal, and other payment methods online.'
  },
  onboardingButton: {
    id: 'setting.payment.promotionbox.onboarding.button',
    defaultMessage: 'Start now'
  },
  pendingBody: {
    id: 'setting.payment.promotionbox.pending.body',
    defaultMessage: 'You’re almost ready to accept payments!'
  },
  pendingNote: {
    id: 'setting.payment.promotionbox.pending.note',
    defaultMessage:
      'You’re almost ready to accept credit cards, debit cards, and PayPal payments online. Please continue your setup below.'
  },
  pendingButton: {
    id: 'setting.payment.promotionbox.pending.button',
    defaultMessage: 'Continue setup'
  },
  onboardedBody: {
    id: 'setting.payment.promotionbox.onboarded.body',
    defaultMessage: 'Your account setup is incomplete.'
  },
  onboardedNote1: {
    id: 'payment.promotionbox.onboarded.noteText.1',
    defaultMessage: 'Check your inbox to verify email'
  },
  onboardedNote2: {
    id: 'payment.promotionbox.onboarded.noteText.2',
    defaultMessage: '<link>Connect</link> your bank account',
    values: {
      link: (chunks) => (
        <a href={onboardedLink.connectBankAccount} target="_blank" rel="noreferrer">
          <span className="text-link">{chunks}</span>
        </a>
      )
    }
  },
  onboardedNote3: {
    id: 'payment.promotionbox.onboarded.noteText.3',
    defaultMessage: '<link>Turn on</link> auto withdrawals',
    values: {
      link: (chunks) => (
        <a href={onboardedLink.turnOnAutoWithdrawls} target="_blank" rel="noreferrer">
          <span className="text-link">{chunks}</span>
        </a>
      )
    }
  },
  onboardedButton: {
    id: 'setting.payment.promotionbox.onboarded.button',
    defaultMessage: 'Manage PayPal Account'
  },
  closedBody: {
    id: 'setting.payment.promotionbox.closed.body',
    defaultMessage: 'Your PayPal account is not connected'
  },
  closedNote: {
    id: 'setting.payment.promotionbox.closed.note',
    defaultMessage: 'Please contact support to connect a valid PayPal account.'
  },
  closedButton: {
    id: 'setting.payment.promotionbox.closed.button',
    defaultMessage: 'Contact Support'
  }
});
