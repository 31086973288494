import { Client } from '@invoice-simple/domain-invoicing';
import { QueryFunctionContext, QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import UserModel from 'src/models/UserModel';

export interface SearchClientsResponse {
  clients: Client[];
}

interface QueryError {
  message: string;
}

export interface SearchClientsParams {
  searchTerm?: string;
}

export const fetchClients = async ({
  queryKey
}: QueryFunctionContext): Promise<SearchClientsResponse> => {
  const { headers } = UserModel.getInstance().getApiReqOpts();

  const [_, searchTerm] = queryKey;

  const { data } = await axios.get<SearchClientsResponse>(
    `${process.env.REACT_APP_URL ?? 'https://localhost:3000'}/api/clients/search`,
    {
      headers,
      params: {
        q: searchTerm
      }
    }
  );

  return data;
};

export const useSearchClients = (
  { searchTerm }: SearchClientsParams,
  options?: UseQueryOptions<SearchClientsResponse, QueryError, SearchClientsResponse, QueryKey>
) => {
  return useQuery<SearchClientsResponse, QueryError>(['clientsSearch', searchTerm], fetchClients, {
    enabled: !!searchTerm,
    ...options
  });
};
