import { ContentTypes } from '@invoice-simple/common';

const exchangeToken = async (parseToken: string): Promise<{ token: string }> => {
  const url = '/is-payments/token/exchange';
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': ContentTypes.APP_JSON
      },
      body: JSON.stringify({ parseToken })
    });
    return await res.json();
  } catch (err) {
    throw new Error(`Unexpected error exchanging token: ${err.message}`);
  }
};

export async function getIsPaymentsTokenFromParseToken(sessionToken: string): Promise<string> {
  try {
    const exchangeResult = await exchangeToken(sessionToken);

    return exchangeResult.token;
  } catch (err) {
    throw new Error(err.message);
  }
}
