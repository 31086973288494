import { ISIntl, defineMessages } from 'src/i18n';

const messages = defineMessages({
  january: {
    defaultMessage: 'January',
    id: 'taxYearMonths.january'
  },
  february: {
    defaultMessage: 'February',
    id: 'taxYearMonths.february'
  },
  march: {
    defaultMessage: 'March',
    id: 'taxYearMonths.march'
  },
  april: {
    defaultMessage: 'April',
    id: 'taxYearMonths.april'
  },
  may: {
    defaultMessage: 'May',
    id: 'taxYearMonths.may'
  },
  june: {
    defaultMessage: 'June',
    id: 'taxYearMonths.june'
  },
  july: {
    defaultMessage: 'July',
    id: 'taxYearMonths.july'
  },
  august: {
    defaultMessage: 'August',
    id: 'taxYearMonths.august'
  },
  september: {
    defaultMessage: 'September',
    id: 'taxYearMonths.september'
  },
  october: {
    defaultMessage: 'October',
    id: 'taxYearMonths.october'
  },
  november: {
    defaultMessage: 'November',
    id: 'taxYearMonths.november'
  },
  december: {
    defaultMessage: 'December',
    id: 'taxYearMonths.december'
  }
});

const months = {
  1: messages.january,
  2: messages.february,
  3: messages.march,
  4: messages.april,
  5: messages.may,
  6: messages.june,
  7: messages.july,
  8: messages.august,
  9: messages.september,
  10: messages.october,
  11: messages.november,
  12: messages.december
};

export const intlTaxYearMonthsOptions = (intl: ISIntl) => {
  const monthsOptions = Object.keys(months).map((key) => {
    return {
      value: parseInt(key),
      label: intl.formatMessage(months[key])
    };
  });

  return monthsOptions;
};
