import React from 'react';
import { observer } from 'mobx-react';
import localeStore from 'src/stores/LocaleStore';
import format from 'date-fns/format';

export const LocalizedDate = observer(
  ({ date, dateFormat = 'PP' }: { date: Date; dateFormat?: string }) => {
    const options = {};

    if (localeStore.datesLocale) {
      // @ts-expect-error - localeStore.datesLocale is a valid locale
      options.locale = localeStore.datesLocale;
    }
    return <span className="localized-date">{format(date, dateFormat, options)}</span>;
  }
);
