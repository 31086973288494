import React, { MouseEvent, useState } from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { useISIntl } from 'src/i18n';
import { Cadence } from 'src/models/UserModel';
import { CheveronDownIcon, CheveronUpIcon } from '../../../icons';
import { getSubscriptionBadgeDiscount, messages, TierCardDataType } from '../../../utils';
import { CardFeatureList } from './CardFeatureList';
import { CardHeader } from './CardHeader';
import { CardPrice } from './CardPrice';
import { PopularBadge } from './PopularBadge';

interface TierCardProps {
  tier: TierCardDataType;
  features: string[];
  selectedTier: SubscriptionTier;
  selectMobilePlan: (tier: SubscriptionTier) => void;
  selectedInterval: Cadence;
}

export const TierCard = ({
  tier,
  features,
  selectedTier,
  selectMobilePlan,
  selectedInterval
}: TierCardProps) => {
  const { ft } = useISIntl();

  const [selectedMobileFeatures, setSelectedMobileFeatures] = useState<SubscriptionTier | null>(
    null
  );

  const handleComparePlansMobile = (e: MouseEvent<HTMLElement>, tier: SubscriptionTier) => {
    setSelectedMobileFeatures(tier === selectedMobileFeatures ? null : tier);
    e.stopPropagation();
  };

  const isTierSelected = selectedTier === tier.name;
  const isPlusTier = tier.name === SubscriptionTier.PLUS;

  const subscriptionBadgeDiscount = getSubscriptionBadgeDiscount(tier.name, selectedInterval);

  const displayFeatures = selectedMobileFeatures === tier.name;

  const featuresText = displayFeatures ? ft(messages.hideFeatures) : ft(messages.seeFeatures);
  return (
    <div
      data-testid={`mobile-${tier.name}-tier-card`}
      onClick={() => selectMobilePlan(tier.name)}
      className={`relative p-[24px] w-full rounded-[16px] ${isTierSelected && 'bg-orange-50'} ${isPlusTier && 'mt-2'}`}
      style={{ border: `2px solid ${isTierSelected ? '#FF5721' : '#6B7280'}` }}>
      {isPlusTier && <PopularBadge isTierSelected={isTierSelected} />}

      <div className="flex flex-row justify-between">
        <div>
          <CardHeader
            name={tier.name}
            description={tier.description}
            subscriptionBadgeDiscount={subscriptionBadgeDiscount}
          />

          <CardPrice selectedInterval={selectedInterval} tierName={tier.name} />

          <div
            className="cursor-pointer w-fit text-gray-950 mt-3 text-2xl font-bold flex justify-center items-center"
            onClick={(e) => handleComparePlansMobile(e, tier.name)}>
            <div className="flex items-center gap-2">
              {featuresText}
              {displayFeatures ? (
                <CheveronUpIcon className="w-4 h-4 text-gray-800" />
              ) : (
                <CheveronDownIcon className="w-4 h-4 text-gray-800" />
              )}
            </div>
          </div>

          {displayFeatures && <CardFeatureList features={features} />}
        </div>
        <div>
          <input
            type="checkbox"
            data-testid={`mobile-${tier.name}-checkbox`}
            className="h-12 w-12 accent-orange-is focus:ring-0 cursor-pointer"
            checked={selectedTier === tier.name}
            onChange={(e) => {
              selectMobilePlan(tier.name);
              e.stopPropagation();
            }}
          />
        </div>
      </div>
    </div>
  );
};
