import React from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { useISIntl } from 'src/i18n';
import UserModel, { Cadence } from 'src/models/UserModel';
import { getFormattedCurrency, getPaywallCouponInfo, getSavingsText } from '../utils';
import { messages } from '../utils/messages';
import { SubscriptionBadge } from './SubscriptionBadge';

interface Props {
  originalPriceDisplay: number;
  discountPriceDisplay: number;
  tier: SubscriptionTier;
  cadence: Cadence;
  isDiscounted: boolean;
}

export const DiscountedPrice = ({
  originalPriceDisplay,
  discountPriceDisplay,
  tier,
  cadence,
  isDiscounted
}: Props) => {
  const { formatMessage } = useISIntl();
  const couponInfo = getPaywallCouponInfo();
  if (!couponInfo) {
    return null;
  }

  return (
    <div className="flex justify-start items-baseline md:items-start md:flex-col mt-3 mb-3 md:mb-0 ml-[30px] md:ml-0 gap-2.5">
      {isDiscounted && (
        <PriceDisplay price={originalPriceDisplay} cadence={cadence} strikeThrough />
      )}
      <PriceDisplay price={discountPriceDisplay} cadence={cadence} />
      {isDiscounted && (
        <SubscriptionBadge
          text={formatMessage(messages.discountedPaywallBadge, {
            discount: getSavingsText(couponInfo, tier, cadence)
          })}
          variant="green"
        />
      )}
    </div>
  );
};

interface PriceDisplayProps {
  price: number;
  cadence: Cadence;
  strikeThrough?: boolean;
}

const PriceDisplay = ({ price, cadence, strikeThrough }: PriceDisplayProps) => {
  const user = UserModel.getInstance();
  const styles = {
    container: strikeThrough
      ? 'flex flex-row font-medium text-gray-800 line-through text-3xl md:text-xl'
      : 'font-extrabold text-gray-900 mt-2 text-[28px] leading-[30px]',
    cadence: strikeThrough ? 'hidden md:block' : 'text-xl font-medium text-gray-800 pl-2'
  };

  return (
    <span className={styles.container}>
      {getFormattedCurrency(user, price)}
      <span className={styles.cadence}>{getPeriodicity(cadence)}</span>
    </span>
  );
};

const getPeriodicity = (cadence?: Cadence) => {
  return cadence === Cadence.ANNUAL ? '/yr' : '/mo';
};
