import React from 'react';
import { SVGProps } from 'react';

export const PenWriteIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_153_5721)">
        <path
          d="M27.5 40H0.83375C0.37375 40 0 39.6263 0 39.1663C0 38.7063 0.37375 38.3325 0.83375 38.3325H27.5C27.9137 38.3288 30 38.1863 30 35.8325C30 33.4788 27.9137 33.3375 27.495 33.3325H7.5C6.05875 33.3325 3.33375 32.4625 3.33375 29.1663C3.33375 25.87 6.05875 25 7.5 25H13.3337C13.7937 25 14.1675 25.3737 14.1675 25.8337C14.1675 26.2937 13.7937 26.6675 13.3337 26.6675H7.5C7.08625 26.6725 5 26.8137 5 29.1675C5 31.5212 7.08625 31.6637 7.505 31.6675H27.5C28.9412 31.6675 31.6663 32.5375 31.6663 35.8337C31.6663 39.13 28.9412 40 27.5 40Z"
          fill="#FF5721"
        />
        <path
          d="M31.135 8.86371C28.21 5.93996 23.4512 5.93996 20.5287 8.86371C17.9587 11.43 15.0525 23.015 15.0237 23.1337C14.975 23.33 15.0237 23.5275 15.1175 23.705L23.5862 15.235C23.145 14.3062 23.3 13.165 24.0662 12.4C25.04 11.4262 26.6262 11.4237 27.6 12.3962C28.575 13.3725 28.5762 14.9612 27.6037 15.935C26.835 16.7 25.6937 16.8537 24.7675 16.415L16.2975 24.8837C16.4737 24.9775 16.6712 25.025 16.8687 24.9775C16.985 24.9487 28.56 22.0475 31.1375 19.4712C34.0612 16.5462 34.0612 11.79 31.1375 8.86496L31.135 8.86371Z"
          fill="#FF5721"
        />
        <path
          d="M39.9937 7.59995C40.025 7.3462 39.9375 7.0912 39.755 6.90995L33.09 0.243695C32.9087 0.0624449 32.6537 -0.0263051 32.3987 0.00494492C32.145 0.0361949 31.9187 0.184945 31.7875 0.404945L29.2875 4.5712C29.0887 4.89619 29.1425 5.3162 29.4125 5.58619L34.4125 10.5899C34.6825 10.8612 35.1025 10.9137 35.4312 10.7149L39.5962 8.21119C39.8162 8.08119 39.9625 7.85495 39.995 7.59995H39.9937Z"
          fill="#FF5721"
        />
      </g>
      <defs>
        <clipPath id="clip0_153_5721">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
