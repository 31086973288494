import React, { SVGProps } from 'react';

export const CheveronDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 1.24266C0.683417 0.852137 1.31658 0.852137 1.70711 1.24266L5 4.53555L8.29289 1.24266C8.68342 0.852137 9.31658 0.852137 9.70711 1.24266C10.0976 1.63319 10.0976 2.26635 9.70711 2.65687L5.70711 6.65688C5.31658 7.0474 4.68342 7.0474 4.29289 6.65688L0.292893 2.65687C-0.0976311 2.26635 -0.0976311 1.63319 0.292893 1.24266Z"
        fill="#111827"
      />
    </svg>
  );
};
