import { ContentTypes } from '@invoice-simple/common';
import jwtDecode from 'jwt-decode';

import UserModel from 'src/models/UserModel';

let stripeToken;

async function exchangeToken(parseToken: string): Promise<{ token: string }> {
  const url = '/is-stripe/token/exchange';
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': ContentTypes.APP_JSON
      },
      body: JSON.stringify({ parseToken })
    });
    return await res.json();
  } catch (err) {
    throw new Error(`Unexpected error exchanging token: ${err.message}`);
  }
}

async function generateAndPersistNewToken(): Promise<string> {
  try {
    const parseToken = UserModel.getInstance().sessionToken;
    if (!parseToken) {
      throw new Error('Missing parse token');
    }
    const exchangeResult = await exchangeToken(parseToken);
    stripeToken = exchangeResult.token;
    return stripeToken;
  } catch (error) {
    throw new Error(error.message);
  }
}

function isTokenExpired(token: string) {
  if (!token) {
    throw new Error('No token provided');
  }
  const decoded: { exp: number } = jwtDecode(token);
  // Add some small buffer room of 10 seconds
  return decoded.exp - Date.now() / 1000 <= 10;
}

export function getStripeToken(): Promise<string> | string {
  try {
    if (!stripeToken || (stripeToken && isTokenExpired(stripeToken))) {
      return generateAndPersistNewToken();
    }
    return stripeToken;
  } catch (err) {
    throw new Error(err.message);
  }
}

export function clearStripeToken(): void {
  stripeToken = null;
}
