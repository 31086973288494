import React, { FC } from 'react';
import { PaypalBaseBox } from '../../PaypalBaseBox';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { GreenCheckMarkIcon } from 'src/components/Icons';
import { AppStore } from 'src/stores/AppStore';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import injectStore from 'src/util/injectStore';
import { getPaymentEventSource } from 'src/payments/utils';

export const PaypalAcceptingBoxComponent: FC<{
  onCTAClick: (checked: boolean) => void;
  checked: boolean;
  disabled?: boolean;
  store: AppStore;
}> = ({ onCTAClick, checked, disabled = false, store }) => {
  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    component: checked
      ? PaymentEventComponent.tileGlobalEnabled
      : PaymentEventComponent.tileGlobalDisabled
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);

  return (
    <>
      <div data-testid="paypalAcceptingBox" />
      <PaypalBaseBox
        icon={<GreenCheckMarkIcon />}
        actionElement={
          <div className="w-auto max-w-[30%] flex items-center justify-center">
            <ToggleSwitch checked={checked} onChange={onCTAClick} disabled={disabled} />
          </div>
        }
      />
    </>
  );
};

export const PaypalAcceptingBox = injectStore(PaypalAcceptingBoxComponent);
