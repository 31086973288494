import React from 'react';
import { useISIntl } from 'src/i18n/utils';
import { PaidGroup } from './ReportTypes';
import { messages } from './ReportMessages';
import FilterButton from './FilterButton';
import ReportModel from 'src/models/ReportModel';

export const PaidReportHeader = () => {
  const { ft } = useISIntl();
  return (
    <tr>
      <th></th>
      <th style={{ width: '75px' }}>{ft(messages.clients)}</th>
      <th style={{ width: '75px' }}>{ft(messages.invoices)}</th>
      <th style={{ width: '130px' }}>{ft(messages.paid)}</th>
    </tr>
  );
};

interface Props {
  list: ReportModel;
}

export const PaidReport = ({ list }: Props) => {
  const groups = list.reports.groups as PaidGroup[];
  return (
    <>
      <FilterButton list={list} />
      <div className="paid-report-list-body panel">
        <table className="table">
          <thead className="thead">
            <PaidReportHeader />
          </thead>
          <tbody>
            {groups.map((group, idg) => {
              return (
                <React.Fragment key={`paid-report-body-${idg}`}>
                  <tr key={`paid-report-year-${idg}`} className="year">
                    <td>{group.year}</td>
                    <td>{group.clients}</td>
                    <td>{group.count}</td>
                    <td>{group.totalFmt}</td>
                  </tr>
                  {group.months.map((month, idm) => {
                    return (
                      <tr key={`paid-report-year-${idg}-month-${idm}`} className="month">
                        <td>{month.month}</td>
                        <td className="data">{month.clients}</td>
                        <td className="data">{month.count}</td>
                        <td className="data">{month.totalFmt}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
