import React from 'react';

export function Mastercard(): JSX.Element {
  return (
    <svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4276 22.75C40.4276 24.5364 38.9419 26 37.1257 26H4.11053C2.29431 26 0.808594 24.5364 0.808594 22.75V3.25C0.808594 1.4625 2.29431 0 4.11053 0H37.1257C38.9419 0 40.4276 1.4625 40.4276 3.25V22.75Z"
        fill="#3E3939"
      />
      <rect x="17.0938" y="6.09082" width="7.61008" height="12.915" fill="#FF5F00" />
      <path
        d="M17.8768 12.549C17.8749 10.0291 18.9882 7.64836 20.8958 6.09258C17.655 3.44617 13.0011 3.83164 10.2047 6.9781C7.40832 10.1246 7.40832 14.9756 10.2047 18.1221C13.0011 21.2686 17.655 21.654 20.8958 19.0076C18.9876 17.4513 17.8743 15.0696 17.8768 12.549Z"
        fill="#EB001B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2656 17.5225V18.5149H34.1125V17.7635L33.8737 18.4085H33.7084L33.4697 17.7635V18.5149H33.3105V17.5225H33.5309L33.788 18.2313L34.0452 17.5225H34.2656ZM32.8136 17.6927V18.515H32.637V17.6927H32.2598V17.5226H33.2149V17.6927H32.8136Z"
        fill="#F79E1B"
      />
      <path
        d="M33.6861 12.5492C33.686 15.6937 31.958 18.5622 29.2359 19.9364C26.5138 21.3105 23.2756 20.9491 20.8965 19.0056C22.8033 17.4485 23.9166 15.068 23.9166 12.5481C23.9166 10.0281 22.8033 7.64764 20.8965 6.09054C23.2756 4.14704 26.5138 3.78561 29.2359 5.15975C31.958 6.5339 33.686 9.40237 33.6861 12.5469V12.5492Z"
        fill="#F79E1B"
      />
    </svg>
  );
}
