import React, { useEffect, useMemo, useState } from 'react';
import Alert from '../Alert';
import { BrandHeader } from '../Onboarding/brand-header';
import { Header } from '../Onboarding/header';
import { OnboardingContainer } from '../Onboarding/page-container';
import { useISIntl } from 'src/i18n';
import { z } from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '../../util/zod';
import UserModel from 'src/models/UserModel';
import { InputWithLabel } from '../Onboarding/input-with-label';
import { Footer } from '../Onboarding/footer';
import { useCountries } from 'src/i18n/getCountries';
import { DropdownSelect } from '../DropdownSelect';
import LocationModel from 'src/models/LocationModel';
import { SubscriptionTrackerStore } from 'src/subs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConsentCheckbox } from './ConsentCheckbox';
import { handleConsent } from './utils';
import LocaleStore from 'src/stores/LocaleStore';
import { observer } from 'mobx-react';
import AlertModel from 'src/models/AlertModel';
import { shouldRedirectToOnboarding } from 'src/util/onboarding';

interface SignupV2Props {
  postSignupCallback?: Function;
}

export const SignupV2 = observer(({ postSignupCallback }: SignupV2Props) => {
  const user = UserModel.getInstance();

  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>(
    user.countryCode?.toUpperCase() || 'US'
  );

  const { ft } = useISIntl();
  const countries = useCountries(LocaleStore.currentLocale);

  const getZodObject = () => {
    return z.object({
      email: z.string().email(ft({ id: 'error.invalidEmail' })),
      password: z.string().min(6, ft({ id: 'signup.form.labels.passwordLength' })),
      consent: z.boolean()
    });
  };

  const SignupSchema = useMemo(getZodObject, []);
  type SignupSchema = z.infer<typeof SignupSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<SignupSchema>({
    resolver: zodResolver(SignupSchema)
  });

  useEffect(() => {
    setValue('email', user.signupData.email);
  }, [user.signupData.email]);

  const onSubmit: SubmitHandler<SignupSchema> = async (data) => {
    setLoading(true);

    const { email, password, consent } = data;
    user.signupData = { email, password };

    const signUpResult = await user.signup(selectedCountry).finally(() => setLoading(false));
    if (!signUpResult) {
      return;
    }

    await handleConsent(consent);

    // call the postSignupCallback only if the user is not being redirected to onboarding
    if (!shouldRedirectToOnboarding()) {
      postSignupCallback?.();
    }
  };

  const formId = 'signup-form';

  return (
    <div id="tailwind" className="w-full h-full md:bg-inherit">
      <BrandHeader />
      <section className="flex justify-center w-full h-full">
        <OnboardingContainer>
          <Header title={ft({ id: 'signup.title' })} />

          <div className="w-full">
            {SubscriptionTrackerStore.isVerifying ? (
              <div className="alert-loading d-flex justify-content-center pb-4">
                <FontAwesomeIcon icon="spinner" size="lg" color="black" spin />
              </div>
            ) : (
              <Alert version="v2" />
            )}
          </div>

          <form
            id={formId}
            className={`w-full flex flex-col gap-[25px] ${AlertModel.isVisible ? 'mt-6' : ''}`}
            onSubmit={handleSubmit(onSubmit)}>
            <InputWithLabel
              id="email"
              label={ft({
                id: 'auth.form.labels.emailAddress'
              })}
              placeholder="name@example.com"
              errorMessage={errors.email?.message}
              {...register('email')}
            />

            <InputWithLabel
              id="password"
              type="password"
              label={ft({
                id: 'signup.form.labels.password'
              })}
              placeholder="******"
              errorMessage={errors.password?.message}
              {...register('password')}
            />

            <DropdownSelect
              id="location"
              label={ft({ id: 'signup.form.labels.location' })}
              form={formId}
              value={selectedCountry}
              options={countries}
              isSearchable={false}
              onChange={(value) => setSelectedCountry(value)}
            />

            <ConsentCheckbox form={formId} {...register('consent')} />
          </form>

          <Footer
            formId={formId}
            primaryButtonLoading={loading}
            onSecondaryButtonClick={() => {
              LocationModel.navAndScrollTop('invoiceList');
            }}
            footerLink={{
              href: '/login',
              text: `${ft({ id: 'signup.link.login.question' })} ${ft({
                id: 'signup.link.login.prompt'
              })}`
            }}
          />
        </OnboardingContainer>
      </section>
    </div>
  );
});
