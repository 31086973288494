import { observable, computed, action, comparer, reaction, IReactionDisposer } from 'mobx';
import { v1 as uuid } from 'uuid';
import { ParseObject } from '../types/ParseObject';
import InvoiceModel from './InvoiceModel';
import ItemModel from './ItemModel';
import { InvoiceItem, ItemDiscountType, ItemDiscountTypes, Platform } from '@invoice-simple/common';
import { trackEvent } from 'src/analytics/controller';
import EnvironmentStore from 'src/stores/EnvironmentStore';
import { SyncExecutionQueue } from 'src/util/syncQueue';
import { getByRemoteId } from 'src/apis/itemAPI';
import { omit } from 'lodash';

export default class InvoiceItemModel {
  _key: string;
  @observable invoice: InvoiceModel;
  @observable code: string;
  @observable description: string;
  @observable discountRate: number = 0;
  @observable discountType: ItemDiscountType = ItemDiscountTypes.DISCOUNT_PERCENTAGE;
  @observable quantity: number = 1;
  @observable unit: string = '';
  @observable rate: number = 0;
  @observable remoteId: string = uuid();
  @observable taxRate: number = 0;
  @observable taxable: boolean = true;
  @observable delete: boolean = false;
  @observable deleted: boolean = false;
  @observable id?: string;
  @observable itemSuggest?: boolean = false;

  @observable userSetRate: boolean = false;
  @observable userSetQuantity: boolean = true;

  syncQueue: SyncExecutionQueue = new SyncExecutionQueue();
  @observable autoSave?: IReactionDisposer;

  @computed
  get isVisible(): boolean {
    return !this.deleted;
  }

  @computed
  get isTaxNone(): boolean {
    return this.invoice && this.invoice.isTaxNone;
  }

  @computed
  get isValid(): boolean {
    return !!this.code && !!this.rate;
  }

  @computed
  get parseData(): InvoiceItem {
    return {
      code: this.code,
      description: this.description,
      discountRate: this.discountRate,
      discountType: this.discountType,
      quantity: this.quantity,
      unit: this.unit,
      rate: this.rate,
      remoteId: this.remoteId,
      taxRate: this.taxRate,
      taxable: this.taxable
    };
  }

  private asItemModel() {
    return {
      id: this.id,
      remoteId: this.remoteId,
      code: this.code,
      description: this.description,
      discountRate: this.discountRate,
      discountType: this.discountType,
      quantity: this.quantity,
      unit: this.unit,
      rate: this.rate,
      taxRate: this.taxRate,
      taxable: this.taxable
    };
  }

  // change of those fields will trigger save
  @computed
  get autoSaveData() {
    return omit(this.parseData, ['remoteId']);
  }

  constructor(invoice: InvoiceModel, obj: any) {
    this._key = uuid();
    this.invoice = invoice;
    this.update(obj);
    this.autoSave = reaction(() => this.autoSaveData, this.save, {
      equals: comparer.structural,
      delay: EnvironmentStore.debounceRate
    });
  }

  async fetchByRemoteId() {
    try {
      const remoteClient = await getByRemoteId(this.remoteId);
      if (remoteClient) {
        this.id = remoteClient.id;
      }
    } catch (error) {
      throw new Error('Cannot fetch remote client for invoice');
    }
  }

  @action.bound
  public async save(): Promise<void> {
    if (!this.isValid || this.itemSuggest) {
      return;
    }
    try {
      await this.syncQueue.push(async () => {
        await this.fetchByRemoteId();
        await new ItemModel(this.asItemModel()).save();
      });
    } catch (error) {
      this.invoice.user.handleError('invoice-item-save', error);
    }
  }

  @action
  public update(obj: Parse.Object | any = {}): void {
    this.code = this._parse(obj, 'code');
    this.description = this._parse(obj, 'description');
    this.discountRate = this._parse(obj, 'discountRate');
    this.discountType = this._parse(obj, 'discountType');
    this.quantity = this._parse(obj, 'quantity');
    this.unit = this._parse(obj, 'unit');
    this.rate = this._parse(obj, 'rate');
    this.remoteId = this._parse(obj, 'remoteId');
    this.taxRate = this._parse(obj, 'taxRate');
    this.taxable = this._parse(obj, 'taxable');
    this.userSetRate = this.rate !== 0;
    this.userSetQuantity = this.quantity !== 0;
  }

  @action.bound
  setCode(v: string): void {
    this.code = v;
  }
  @action.bound
  setDescription(v: string): void {
    this.description = v;
  }
  @action.bound
  toggleTaxable(): void {
    this.taxable = !this.taxable;
  }
  @action.bound
  setTaxable(v: boolean): void {
    this.taxable = v;
  }
  @action.bound
  setQuantity(v: number): void {
    this.userSetQuantity = v != null;
    this.quantity = v || 0;
  }
  @action.bound
  setRate(v: number): void {
    this.userSetRate = v != null;
    this.rate = v || 0.0;
  }
  @action.bound
  setDiscountRate(v: number): void {
    this.discountRate = v >= 0 ? v : 0;
  }
  @action.bound
  setItem(itemModel: ItemModel): void {
    this.remoteId = uuid();
    this.itemSuggest = true;
    this.code = itemModel.code;
    this.description = itemModel.description;
    this.rate = itemModel.rate;
    this.unit = itemModel.unit;
    this.taxable = itemModel.taxable;
    this.quantity = 1;
    this.userSetQuantity = true;
    this.userSetRate = true;
  }
  @action.bound
  toggleDelete(): void {
    this.delete = !this.delete;
  }
  @action.bound
  toggleDeleted(): void {
    this.deleted = !this.deleted;
    if (this.deleted) {
      trackEvent('invoice-item-remove', { platform: Platform.WEB });
    }
  }
  _parse(obj: Parse.Object | ParseObject, name: string) {
    return obj[name] === undefined ? this._default(name) : obj[name];
  }
  _default(name: string) {
    return {
      code: '',
      description: '',
      remoteId: uuid(),
      rate: 0,
      discountRate: 0,
      discountType: ItemDiscountTypes.DISCOUNT_PERCENTAGE,
      taxRate: 0,
      quantity: 1,
      unit: '',
      taxable: true
    }[name];
  }
}
//
// example parse object
//
// code: "Toast";
// description: "";
// discountRate: 0;
// discountType: 0;
// quantity: 3;
// rate: 100;
// remoteId: "34d90f2e-71f9-4645-b565-f5c33c82bc83";
// taxRate: 0;
// taxable: true;
