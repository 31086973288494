import React, { FC, useCallback } from 'react';
import {
  getPaymentEventSource,
  onError,
  redirectToPaypalDashboard
} from 'src/payments/utils/utils';
import { defineMessages } from 'src/i18n';
import {
  OnboardModalProps,
  OnboardModalMessages,
  PaymentEventComponent,
  PaymentEventActivePaymentProvider
} from 'src/payments/types';
import { TopContainer, ValueProps, ButtonContainer } from '../../PaymentBasicElements';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { PaypalModalImg } from './PaypalModalBase';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';

const messages = defineMessages({
  header: {
    id: 'paypalModal.prompt.finishSetup',
    defaultMessage: 'Finish Setup'
  },
  almostReadyToAccept: {
    id: 'paypalModal.prompt.almostReadyToAccept',
    defaultMessage: "You're almost ready to accept online payments and get paid twice as fast!"
  },
  continueToPaypalToFinishSetup: {
    id: 'paypalModal.prompt.continueToPaypalToFinishSetup',
    defaultMessage: 'Continue to PayPal to finish setup:'
  },
  checkYourInboxToVerifyEmail: {
    id: 'paypalModal.prompt.checkYourInboxToVerifyEmail',
    defaultMessage: 'Check your inbox to verify email'
  },
  connectYourBankAccount: {
    id: 'paypalModal.prompt.connectYourBankAccount',
    defaultMessage: 'Connect your bank account'
  },
  turnOnAutomaticTransfers: {
    id: 'paypalModal.prompt.turnOnAutomaticTransfers',
    defaultMessage: 'Turn on automatic transfers from your PayPal account to your bank account'
  },
  ctaText: { id: 'paypalModal.prompt.continueToPaypal', defaultMessage: 'Continue To PayPal' }
});

const PaypalOnboardedModalComponent: FC<
  OnboardModalProps & {
    emailConfirmed: boolean;
    store: AppStore;
  }
> = ({ closeModal, store: { user }, emailConfirmed }) => {
  const ft = (message: OnboardModalMessages) => message.defaultMessage;
  const paypalSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource()
  };
  const instructions = [
    !emailConfirmed && ft(messages.checkYourInboxToVerifyEmail),
    ft(messages.connectYourBankAccount),
    ft(messages.turnOnAutomaticTransfers)
  ].filter(Boolean);
  const handleClick = useCallback(async () => {
    try {
      user.events.trackAction('payments-cta', {
        ...paypalSharedMetadata,
        component: emailConfirmed
          ? PaymentEventComponent.modalOnboardedEmailConfirmed
          : PaymentEventComponent.modalOnboarded
      });
      redirectToPaypalDashboard();
      closeModal();
    } catch (error) {
      onError({ title: 'Error initiating PayPal onboarding', body: error.message });
    }
  }, []);

  const onClickXIcon = () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: emailConfirmed
        ? PaymentEventComponent.modalCloseOnboardedEmailConfirmed
        : PaymentEventComponent.modalCloseOnboarded
    });
    closeModal();
  };

  const paymentsViewedMeta = {
    ...paypalSharedMetadata,
    component: emailConfirmed
      ? PaymentEventComponent.modalOnboardedEmailConfirmed
      : PaymentEventComponent.modalOnboarded
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <>
      <TopContainer
        onClickXIcon={onClickXIcon}
        headerMessage={ft(messages.header)}
        dataTestId="paypal--modal-x-button"
      />
      <main>
        <PaypalModalImg />
        <ValueProps>
          <b>{ft(messages.almostReadyToAccept)}</b>
          <p className="pt-4">{ft(messages.continueToPaypalToFinishSetup)}</p>
          <ol className="px-[1.8rem] list-outside">
            {instructions.map((instruction, index) => (
              <li className="pt-4" key={index}>
                {instruction}
              </li>
            ))}
          </ol>
        </ValueProps>
      </main>
      <ButtonContainer
        dataTestId="paypal--modal-submit-button"
        handleClick={handleClick}
        buttonMessage={ft(messages.ctaText)}
      />
    </>
  );
};

export const PaypalOnboardedModal = injectStore(PaypalOnboardedModalComponent);
