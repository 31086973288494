import React from 'react';
import UserModel from '../models/UserModel';
import { WebEventName } from '@invoice-simple/is-events';

type Props = {
  children?: React.ReactNode;
};

export const trackOnMount =
  (eventName: WebEventName) => (Component: React.FC<Props> | React.ComponentClass<Props>) => {
    class TrackEventOnMount extends React.Component<Props> {
      static displayName = `TrackEventOnMount(${Component.displayName})`;
      componentDidMount() {
        UserModel.getInstance().trackAppEventViaApi(eventName);
      }
      render() {
        const { children, ...rest } = this.props;
        return React.createElement(Component, { ...rest }, children);
      }
    }

    return TrackEventOnMount;
  };

export const withAnalytics = (Component: React.FC<Props> | React.ComponentClass<Props>) => {
  class WithAnalytics extends React.Component<Props> {
    static displayName = `WithAnalytics(${Component.displayName})`;

    render() {
      const user = UserModel.getInstance();
      const { children, ...rest } = this.props;
      return React.createElement(
        Component,
        {
          // @ts-ignore
          trackEvent: user.trackAppEventViaApi.bind(user),
          trackError: user.trackError.bind(user),
          ...rest
        },
        children
      );
    }
  }
  return WithAnalytics;
};
