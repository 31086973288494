import { FeatureName } from '@invoice-simple/feature-gate';
import React from 'react';
import { LockIcon } from 'src/components/Icons/LockIcon';
import LocationModel from 'src/models/LocationModel';
import { messages } from './PaymentDeposit';
import { useISIntl } from 'src/i18n';

export const InvoiceDepositIneligible = () => {
  const { ft } = useISIntl();
  const handleUpgradeButton = () => {
    // created this function because we might need to add events in the future.
    LocationModel.navAndScrollTop('subscription', {
      feature: FeatureName.DEPOSITS,
      ref: 'DEPOSITS'
    });
  };

  return (
    <div className="payment-deposit">
      <h4 data-testid={'deposit-title'}>{ft(messages.deposit)}</h4>

      <div className="setting-form-group form-group row">
        <div className="col-md-6 col-lg-12">
          <label className="col-form-label" data-testid={'upgrade-label'}>
            {ft(messages.requestDepositsNew)}
          </label>
          <>
            <button
              className="btn btn-primary btn-block"
              type="button"
              onClick={handleUpgradeButton}
              data-testid={'upgrade-button'}>
              {ft(messages.upgradeNow)} <LockIcon style={{ width: '16px', marginLeft: '7px' }} />
            </button>
          </>
        </div>
      </div>
    </div>
  );
};
