import { FullOptions, RequestOptions } from 'parse';
import environmentStore from 'src/stores/EnvironmentStore';
import { pubsub, EVENTS } from 'src/util/pubsub';
import UserModel from 'src/models/UserModel';

const handleError = (error: Error & Parse.Error): undefined | void => {
  if (environmentStore.isSnapshot()) {
    return;
  }

  const errorLabel = error.code ? `parse-response-${error.code}` : `parse-response-unknown`;
  UserModel.getInstance().trackError(errorLabel, error);

  switch (error.code) {
    case 100:
      pubsub.emit(EVENTS.CONNECTIVITY_ERROR, `${error.code}: ${error.message}`);
      throw error;
    case 209:
      pubsub.emit(EVENTS.SESSION_EXPIRED);
      break;
    default:
      throw error;
  }
};

interface AjaxResponse {
  response: { results: any[] };
  status: number;
  xhr: XMLHttpRequest;
}

type AjaxMethod = (
  method: string,
  url: string,
  data: any,
  headers?: any,
  options?: FullOptions
) => Promise<AjaxResponse>;

type RequestMethod = (
  method: string,
  path: string,
  data: any,
  options?: RequestOptions
) => Promise<AjaxResponse>;

interface RESTController {
  ajax: AjaxMethod;
  request: RequestMethod;
}

const wrapRESTMethod: <T extends Function>(
  method: T
) => (...args: any[]) => Promise<AjaxResponse> = (method) =>
  async function wrappedRESTMethod(...args) {
    try {
      return await method.call(null, ...args);
    } catch (error) {
      handleError(error);
    }
  };

export const wrapRESTController = (controller: RESTController) => ({
  ajax: wrapRESTMethod<AjaxMethod>(controller.ajax),
  request: wrapRESTMethod<RequestMethod>(controller.request)
});
