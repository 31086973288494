// Do not update this file, we will remove it after we release is-stripe and new design to all users
export const PaymentModalTriggerName = {
  invoiceDownload: 'invoice-download',
  invoiceEmailSend: 'invoice-email-send',
  invoicePreviewSend: 'invoice-preview-send',
  invoiceLink: 'invoice-link',
  invoicePrint: 'invoice-print'
} as const;

type ValueOf<T> = T[keyof T];
export type PaymentModalTrigger = ValueOf<typeof PaymentModalTriggerName>;
