import { defineMessages } from 'src/i18n';

export const messages = defineMessages({
  title: {
    id: 'payments.list.title',
    defaultMessage: 'Invoices'
  },
  paypalPayments: {
    id: 'payments.list.paypalPayments',
    defaultMessage: 'PayPal Payments'
  },
  paymentsWillAppear: {
    id: 'payments.list.paymentsWillAppear',
    defaultMessage:
      'Once you start collecting payments on your invoices, you will be able to view your PayPal transactions here.'
  },
  fetchingPaypalDetails: {
    id: 'payments.list.fetchingPaypalDetails',
    defaultMessage: 'Fetching your PayPal details'
  },
  description: {
    id: 'payments.list.description',
    defaultMessage:
      'All your payments in one place! Invoice Simple keeps track of payments and the outstanding balance for all your invoices.'
  },
  filterAll: {
    id: 'payments.list.filter.all',
    defaultMessage: 'All payments'
  },
  filterPaid: {
    id: 'payments.list.filter.paid',
    defaultMessage: 'Paid'
  },
  filterRefunded: {
    id: 'payments.list.filter.refunded',
    defaultMessage: 'Refunded'
  },
  rowEmpty: {
    id: 'payments.list.row.empty',
    defaultMessage: 'No payments found.'
  },
  rowLoading: {
    id: 'payments.list.row.loading',
    defaultMessage: 'loading your payments...'
  },
  tableHeadInvoice: {
    id: 'payments.list.table.head.invoice',
    defaultMessage: 'invoice'
  },
  tableHeadClient: {
    id: 'payments.list.table.head.client',
    defaultMessage: 'Client'
  },
  tableHeadAmount: {
    id: 'payments.list.table.head.amount',
    defaultMessage: 'Amount'
  },
  tableHeadDate: {
    id: 'payments.list.table.head.date',
    defaultMessage: 'Date'
  },
  tableHeadStatus: {
    id: 'payments.list.table.head.status',
    defaultMessage: 'Status'
  }
});
