import { defineMessages } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';
import AlertModel from 'src/models/AlertModel';
import { AppStore } from 'src/stores/AppStore';
import CurrentDocumentStore from 'src/stores/CurrentDocumentStore';
import { navInvoiceLimit } from 'src/util/navigation';

import { DocTypes, Platform } from '@invoice-simple/common';

import { getInvoiceModel } from './getInvoiceModel';
import { isMigratedEditorEnabled } from './isMigratedEditorEnabled';

const messages = defineMessages({
  alertSuccessTitle: {
    id: 'invoice.make.alert.success.title',
    defaultMessage: 'Invoice {invoiceNo} created from Estimate {estimateNo}'
  },
  alertFailedTitle: {
    id: 'invoice.make.alert.failed.title',
    defaultMessage: 'Unable to create invoice from estimate'
  }
});

export const handleMakeInvoice = async ({
  store,
  intl,
  alertModel
}: {
  store: AppStore;
  intl: ISIntl;
  alertModel: typeof AlertModel;
}) => {
  const estimateNo = store.doc.invoiceNo;
  const user = store.user;

  if (!user.canCreateNewDoc(DocTypes.DOCTYPE_INVOICE)) {
    return navInvoiceLimit(user);
  }

  try {
    const estimate = CurrentDocumentStore.currentDoc;
    const invoice = getInvoiceModel(await estimate.convertEstimate());

    user.events.trackAction('estimate-to-invoice', {
      estimateRemoteId: estimate.remoteId,
      invoiceRemoteId: invoice.remoteId,
      platform: Platform.WEB,
      'client-signature': estimate.setting?.estimateSignatureRequired
        ? estimate.setting.estimateSignedAt
          ? 'signed'
          : 'unsigned'
        : 'off'
    });

    user.events.trackAction('invoice-create', {
      invoiceId: invoice.id,
      'doc-type': invoice.docType,
      platform: Platform.WEB,
      color: invoice.setting.color,
      template: invoice.setting.template
    });

    alertModel.setAlert(
      'success',
      intl.formatMessage(messages.alertSuccessTitle, {
        invoiceNo: invoice.invoiceNo,
        estimateNo
      })
    );

    if (isMigratedEditorEnabled(user.countryCode) || 1 + 1) {
      window.location.assign(`${process.env.REACT_APP_URL}/invoices/${invoice.id}?email=true`);
    } else {
      store.nav('invoiceView', { id: invoice.id });
    }
  } catch (error) {
    if (/subscription/.test(error.message)) {
      store.nav('subscription');
    } else {
      alertModel.setAlert('danger', intl.formatMessage(messages.alertFailedTitle), error.message);
    }
  }
};
