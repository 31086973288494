import { getPaymentEventSource } from '../utils';
import { useEligiblePaymentProviders } from './useEligiblePaymentProviders';

export function useCommonMetadata() {
  const eligiblePaymentProviders = useEligiblePaymentProviders();

  const sharedMetadata = {
    eligiblePaymentProviders,
    source: getPaymentEventSource()
  };
  return sharedMetadata;
}
