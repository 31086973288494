import React from 'react';

export const PaypalIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="15"
    viewBox="0 0 62 15"
    fill="none"
    {...props}>
    <path
      d="M23.8034 6.53394C23.6227 7.75724 22.6913 7.75723 21.7878 7.75723H21.2734L21.6349 5.47745C21.6627 5.33844 21.7739 5.24114 21.9129 5.24114H22.1492C22.7608 5.24114 23.3447 5.24114 23.6366 5.58866C23.8173 5.79718 23.8729 6.103 23.8034 6.53394ZM23.4142 3.35059H20.0223C19.786 3.35059 19.5914 3.5174 19.5497 3.75372L18.1735 12.4558C18.1457 12.6226 18.2847 12.7755 18.4515 12.7755H20.0779C20.3143 12.7755 20.5089 12.6087 20.5506 12.3724L20.9259 10.0231C20.9676 9.7868 21.1622 9.61998 21.3846 9.61998H22.455C24.6931 9.61998 25.9859 8.5357 26.3195 6.39493C26.4724 5.46355 26.3195 4.7268 25.8886 4.19855C25.416 3.65641 24.5541 3.35059 23.4142 3.35059Z"
      fill="#253B80"
    />
    <path
      d="M31.2958 9.66221C31.1429 10.5936 30.4062 11.2191 29.4609 11.2191C28.9883 11.2191 28.6129 11.0662 28.3627 10.7743C28.1264 10.4963 28.0291 10.0792 28.1125 9.6344C28.2654 8.71693 29.0022 8.06358 29.9335 8.06358C30.3923 8.06358 30.7676 8.21649 31.0178 8.50842C31.268 8.80034 31.3653 9.20347 31.2958 9.66221ZM33.5617 6.49276H31.9353C31.7963 6.49276 31.6712 6.59006 31.6573 6.72907L31.5878 7.18781L31.4766 7.021C31.129 6.50666 30.3367 6.33984 29.5582 6.33984C27.765 6.33984 26.2219 7.70215 25.93 9.6066C25.7771 10.5519 25.9995 11.4694 26.5417 12.1088C27.0421 12.6926 27.7511 12.929 28.5851 12.929C30.0308 12.929 30.8371 11.9976 30.8371 11.9976L30.7676 12.4424C30.7398 12.6092 30.8788 12.7761 31.0456 12.7761H32.5052C32.7416 12.7761 32.9362 12.6092 32.9779 12.3729L33.8536 6.81248C33.8675 6.64567 33.7424 6.49276 33.5617 6.49276Z"
      fill="#253B80"
    />
    <path
      d="M42.2227 6.49219H40.5963C40.4434 6.49219 40.2905 6.57559 40.207 6.7007L37.9551 10.0231L36.9959 6.83971C36.9403 6.6451 36.7457 6.50609 36.5372 6.50609H34.9385C34.7439 6.50609 34.6049 6.7007 34.6744 6.88142L36.4676 12.1638L34.7717 14.5548C34.6327 14.7494 34.7717 14.9997 35.008 14.9997H36.6345C36.7874 14.9997 36.9403 14.9302 37.0237 14.7911L42.459 6.95092C42.5841 6.75631 42.4451 6.49219 42.2227 6.49219Z"
      fill="#253B80"
    />
    <path
      d="M48.018 6.53394C47.8373 7.75724 46.906 7.75723 46.0024 7.75723H45.488L45.8495 5.47745C45.8773 5.33844 45.9885 5.24114 46.1275 5.24114H46.3638C46.9755 5.24114 47.5593 5.24114 47.8512 5.58866C48.0319 5.79718 48.0875 6.103 48.018 6.53394ZM47.6288 3.35059H44.2369C44.0006 3.35059 43.806 3.5174 43.7782 3.75372L42.402 12.4558C42.3742 12.6226 42.5132 12.7755 42.68 12.7755H44.4177C44.5845 12.7755 44.7235 12.6643 44.7374 12.4975L45.1266 10.037C45.1683 9.80069 45.3629 9.63388 45.5853 9.63388H46.6557C48.8938 9.63388 50.1866 8.5496 50.5202 6.40883C50.6732 5.47745 50.5202 4.7407 50.0893 4.21245C49.6167 3.65641 48.7687 3.35059 47.6288 3.35059Z"
      fill="#179BD7"
    />
    <path
      d="M55.5107 9.66221C55.3578 10.5936 54.621 11.2191 53.6757 11.2191C53.2031 11.2191 52.8278 11.0662 52.5776 10.7743C52.3412 10.4963 52.2439 10.0792 52.3273 9.6344C52.4802 8.71693 53.217 8.06358 54.1484 8.06358C54.6071 8.06358 54.9824 8.21649 55.2327 8.50842C55.4829 8.80034 55.5802 9.20347 55.5107 9.66221ZM57.7766 6.49276H56.1501C56.0111 6.49276 55.886 6.59006 55.8721 6.72907L55.8026 7.18781L55.6914 7.021C55.3439 6.50666 54.5515 6.33984 53.773 6.33984C51.9798 6.33984 50.4368 7.70215 50.1449 9.6066C49.9919 10.5519 50.2144 11.4694 50.7565 12.1088C51.2569 12.6926 51.9659 12.929 52.8 12.929C54.2457 12.929 55.052 11.9976 55.052 11.9976L54.9824 12.4424C54.9546 12.6092 55.0937 12.7761 55.2605 12.7761H56.7201C56.9564 12.7761 57.151 12.6092 57.1788 12.3729L58.0546 6.81248C58.0824 6.64567 57.9434 6.49276 57.7766 6.49276Z"
      fill="#179BD7"
    />
    <path
      d="M59.6827 3.58713L58.2926 12.456C58.2648 12.6229 58.4038 12.7758 58.5706 12.7758H59.9747C60.211 12.7758 60.4056 12.6089 60.4473 12.3726L61.8235 3.67054C61.8513 3.50373 61.7123 3.33691 61.5455 3.33691H59.9747C59.8356 3.35082 59.7105 3.46202 59.6827 3.58713Z"
      fill="#179BD7"
    />
    <path
      d="M4.0919 14.4716L4.35602 12.8173L3.77218 12.8035H1.00586L2.92421 0.639988C2.92421 0.598284 2.95201 0.570482 2.97982 0.54268C3.00762 0.514878 3.04932 0.500977 3.07712 0.500977H7.73399C9.27701 0.500977 10.3474 0.820703 10.9034 1.46015C11.1676 1.75208 11.3344 2.0718 11.4178 2.40543C11.5012 2.76686 11.5012 3.19779 11.4178 3.71213V3.75383V4.08746L11.6819 4.24037C11.9043 4.35158 12.0711 4.49059 12.2101 4.6435C12.4326 4.89372 12.5716 5.22735 12.6411 5.60268C12.7106 5.99191 12.6828 6.46455 12.5855 6.99279C12.4604 7.60444 12.2797 8.13268 12.0155 8.57752C11.7792 8.98065 11.4734 9.31427 11.112 9.56449C10.7644 9.81471 10.3613 9.99543 9.88866 10.1205C9.44383 10.2317 8.92949 10.3013 8.35954 10.3013H7.99811C7.73399 10.3013 7.48377 10.3986 7.28916 10.5654C7.09454 10.7322 6.95553 10.9685 6.91383 11.2187L6.88602 11.3716L6.42729 14.277L6.41339 14.3882C6.41339 14.416 6.39949 14.4438 6.38558 14.4438C6.37168 14.4577 6.35778 14.4577 6.34388 14.4577H4.0919V14.4716Z"
      fill="#253B80"
    />
    <path
      d="M11.9181 3.80957C11.9042 3.89298 11.8903 3.99028 11.8764 4.08759C11.2648 7.24314 9.16572 8.32743 6.48281 8.32743H5.1205C4.78687 8.32743 4.52275 8.56375 4.46715 8.88347L3.77209 13.3179L3.57748 14.569C3.54968 14.7775 3.70259 14.9722 3.92501 14.9722H6.3438C6.63572 14.9722 6.87204 14.7636 6.91374 14.4856L6.94155 14.3605L7.40028 11.4691L7.42808 11.3162C7.46979 11.0381 7.72001 10.8296 7.99803 10.8296H8.35946C10.7087 10.8296 12.5437 9.87045 13.0858 7.11803C13.3082 5.96424 13.197 5.00506 12.5993 4.32391C12.4047 4.11539 12.1823 3.94858 11.9181 3.80957Z"
      fill="#179BD7"
    />
    <path
      d="M11.2786 3.5591C11.1813 3.5313 11.0839 3.5035 10.9866 3.4896C10.8893 3.46179 10.7781 3.44789 10.6808 3.43399C10.3194 3.37839 9.90236 3.35059 9.47142 3.35059H5.82933C5.74593 3.35059 5.64862 3.36449 5.57911 3.40619C5.4123 3.4896 5.28719 3.64251 5.25939 3.83712L4.48093 8.74421L4.45312 8.88322C4.50873 8.5635 4.78675 8.32718 5.10648 8.32718H6.46878C9.1517 8.32718 11.2508 7.2429 11.8624 4.08734C11.8763 3.99004 11.8902 3.90663 11.9041 3.80932C11.7512 3.72592 11.5844 3.65641 11.4037 3.60081C11.3759 3.58691 11.3342 3.573 11.2786 3.5591Z"
      fill="#222D65"
    />
    <path
      d="M5.25984 3.8228C5.28764 3.62819 5.41275 3.47527 5.57956 3.39187C5.66297 3.35016 5.74638 3.33626 5.82978 3.33626H9.47187C9.90281 3.33626 10.3059 3.36406 10.6813 3.41967C10.7925 3.43357 10.8898 3.46137 10.9871 3.47527C11.0844 3.50308 11.1817 3.51698 11.279 3.54478C11.3207 3.55868 11.3763 3.57258 11.418 3.58648C11.5987 3.64209 11.7656 3.71159 11.9185 3.795C12.0992 2.62731 11.9185 1.83494 11.2929 1.12599C10.5979 0.333625 9.34676 0 7.74814 0H3.09127C2.75764 0 2.47962 0.236319 2.43791 0.556044L0.505661 12.8446C0.463958 13.0809 0.658573 13.3033 0.894892 13.3033H3.77242L4.49528 8.72989L5.25984 3.8228Z"
      fill="#253B80"
    />
  </svg>
);
