import axios, { AxiosError } from 'axios';

import Rollbar from 'src/analytics/rollbar';
import UserModel from 'src/models/UserModel';
import { SMSCheckoutData } from 'src/types/SMS';
import { API_V3_BASE_PATH, client } from './httpClient';

export const TokenValidationMessages = {
  activeToken: 'Active token',
  subActive: 'Subscription is active',
  invalidToken: 'Invalid token',
  tokenNotProvided: 'No token provided',
  tokenNotFound: 'Token not found'
};

type AccountSource = 'impact';

export const requestAccountDeletion = async (user: UserModel) => {
  const url = `/api/v2/account-delete/request`;
  await axios.post(url, {}, user.getApiReqOpts()).catch((err) => {
    throw err;
  });
};

export const validateAccountDeletionToken = async (
  user: UserModel,
  tokenId: string
): Promise<string> => {
  const url = `/api/v2/account-delete/validate?token=${tokenId}`;

  if (!tokenId) {
    throw new Error(TokenValidationMessages.tokenNotProvided);
  }

  try {
    await axios.get(url, user.getApiReqOpts());
  } catch (err) {
    const error = err as AxiosError<string>;
    if (error.response && error.response.status === 400 && error.response.data.match) {
      if (error.response.data.match(/Subscription is active/i))
        throw new Error(TokenValidationMessages.subActive);

      if (error.response.data.match(/Invalid token/i))
        throw new Error(TokenValidationMessages.invalidToken);

      if (error.response.data.match(/No token provided/i))
        throw new Error(TokenValidationMessages.tokenNotProvided);

      if (error.response.data.match(/Token not found/i))
        throw new Error(TokenValidationMessages.tokenNotFound);
    }
    throw error;
  }
  return TokenValidationMessages.activeToken;
};

export const confirmAccountDeletion = async (user: UserModel, tokenId: string) => {
  const url = `/api/v2/account-delete/confirm?token=${tokenId}`;
  await axios.post(url, {}, user.getApiReqOpts()).catch((err) => {
    throw err;
  });
};

export const getSMSCheckoutDataFromToken = async (
  user: UserModel,
  token: string
): Promise<SMSCheckoutData | undefined> => {
  const url = `/sms/checkout-data/${token}`;
  try {
    const result = await axios.get<SMSCheckoutData | undefined>(url, user.getApiReqOpts());
    return result.data;
  } catch (err) {
    return undefined;
  }
};

const getSource = (isGuest: boolean): AccountSource | undefined => {
  const params = new URLSearchParams(window.location.search);

  let source: AccountSource | undefined;

  params.forEach((_value, key) => {
    if (key === 'irclickid' && isGuest) {
      source = 'impact';
    }
  });

  return source;
};

export const trackAccountSource = async (user: UserModel) => {
  try {
    if (!!user.accountSource) {
      return;
    }

    const source = getSource(user.isGuest);
    if (!source) {
      return;
    }

    const { baseURL, headers } = user.getApiV3ReqOpts();

    const path = baseURL + API_V3_BASE_PATH + '/account-source';

    await client.post(path, { source }, { headers });

    user.accountSource = source;
    user.events.trackAction('account-source', { source });
  } catch (err) {
    Rollbar.trackError('Error saving account source', err);
  }
};
