import { DocTypes, InvoiceTermTypes } from '@invoice-simple/common';
import React from 'react';
import { observer } from 'mobx-react';

import { Selectv1Adapter } from 'src/components/Selectv1Adapter';
import DocNumberInput from '../DocNumberInput';
import DatePickerWrapper from '../DatePickerWrapper';
import InputWithLabel from '../InputWithLabel';
import { defineMessages } from 'src/i18n';
import { getIntlTerm, getIntlTermOptions } from 'src/i18n/intlTermOptions';
import { AppStore } from 'src/stores/AppStore';
import { useISIntl } from 'src/i18n/utils';
import TextInput from '../TextInput';
import { isPoNumberEnabled } from 'src/util/isAbTestEnabled';

const messages = defineMessages({
  number: {
    id: 'invoiceTerms.number',
    defaultMessage: 'number'
  },
  terms: {
    id: 'invoiceTerms.terms',
    defaultMessage: 'terms'
  },
  date: {
    id: 'invoiceTerms.date',
    defaultMessage: 'date'
  },
  due: {
    id: 'invoiceTerms.due',
    defaultMessage: 'due'
  },
  select: {
    id: 'invoiceTerms.selectDate',
    defaultMessage: 'select a date'
  },
  invoiceNumberPlaceholder: {
    id: 'invoiceTerms.businessNumberPlaceholder.invoice',
    defaultMessage: 'INV0000'
  },
  estimateNumberPlaceholder: {
    id: 'invoiceTerms.businessNumberPlaceholder.estimate',
    defaultMessage: 'EST0000'
  },
  poNumber: {
    id: 'invoice.poNumber',
    defaultMessage: 'PO Number'
  }
});

const InvoiceTerms = (props: { store: AppStore }) => {
  const invoice = props.store.doc;
  const user = props.store.user;
  const isLoading = props.store.isLoading;
  const { fta: t, ft: tt, fta } = useISIntl();

  const invoiceNumberLabel = `${t(messages.number)}`;

  return (
    <div className="invoice-detail-terms content-block">
      <div className="row">
        <div className="col-md-6 invoice-address invoice-address-terms">
          <InputWithLabel
            alwaysShowLabel
            baseline
            label={
              <label htmlFor="invoice-number" className="invoice-number-label">
                {invoiceNumberLabel}
              </label>
            }
            content={
              <DocNumberInput
                disabled={isLoading}
                name="invoice-number"
                label={invoiceNumberLabel}
                placeholder={fta(
                  invoice.docType === DocTypes.DOCTYPE_ESTIMATE
                    ? messages.estimateNumberPlaceholder
                    : messages.invoiceNumberPlaceholder
                )}
                source={invoice}
                fieldName="invoiceNo"
              />
            }
          />

          <InputWithLabel
            alwaysShowLabel
            label={<span>{t(messages.date)}</span>}
            content={
              <DatePickerWrapper
                disabled={isLoading}
                id="invoice-date"
                name="invoice-date"
                className="invoice-detail-date"
                selected={invoice.getInvoiceDate()}
                onChange={invoice.setInvoiceDate}
              />
            }
          />

          {!invoice.isEstimate && (
            <InputWithLabel
              alwaysShowLabel
              label={<span>{t(messages.terms)}</span>}
              content={
                <Selectv1Adapter
                  id="invoice-terms-input"
                  isDisabled={isLoading}
                  name="invoice-terms-input"
                  isClearable={false}
                  value={getIntlTerm(invoice.setting.termsDay).value}
                  options={getIntlTermOptions(fta)}
                  onChange={(s) => {
                    if (s) {
                      invoice.setTerms(s.value);
                    }
                  }}
                />
              }
            />
          )}

          {!invoice.isEstimate &&
            invoice.setting.termsDay !== InvoiceTermTypes.DUE_ON_RECEIPT &&
            invoice.setting.termsDay !== InvoiceTermTypes.NONE && (
              <InputWithLabel
                alwaysShowLabel
                label={<span>{t(messages.due)}</span>}
                content={
                  <DatePickerWrapper
                    disabled={isLoading}
                    name="invoice-due-date"
                    id="invoice-due-date"
                    placeholderText={tt(messages.select)}
                    className="invoice-detail-due-date"
                    selected={invoice.getDueDate()}
                    onChange={(v) => {
                      if (v) {
                        invoice.setDueDate(v);
                      }
                    }}
                  />
                }
              />
            )}
        </div>
        {isPoNumberEnabled(user) && (
          <div className="col-md-6 invoice-extra-fields">
            <InputWithLabel
              alwaysShowLabel
              baseline
              label={
                <label htmlFor="poNumber" className="invoice-number-label">
                  {t(messages.poNumber)}
                </label>
              }
              content={
                <TextInput
                  disabled={isLoading}
                  hideIcon={true}
                  name="poNumber"
                  label={t(messages.poNumber)}
                  placeholder=""
                  value={invoice.poNumber ?? ''}
                  onChange={(v) => {
                    invoice.setPoNumber(v);
                  }}
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(InvoiceTerms);
