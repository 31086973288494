import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import UserModel from 'src/models/UserModel';
import { buildUrl } from './helpers';

interface Client {
  objectId: string;
  remoteId: string;
  email: string;
  billingName: string;
  billingAddress1: string;
  billingAddress2: string;
  billingAddress3: string;
  phone: string;
  mobile: string;
  fax: string;
  deleted: false;
  updated: number;
  _created_at: Date;
}

export interface PaginatedClientsResponse {
  clients: Client[];
  hasNextPage: boolean;
  prev: string;
  next: string;
}

interface QueryError {
  message: string;
}

export interface PaginatedClientsParams {
  dir?: 'asc' | 'desc';
  sortBy?: 'billingName' | 'updated';
  limit?: number;
  url?: string;
}

export const fetchClients = async ({
  dir,
  sortBy,
  limit,
  url
}: PaginatedClientsParams): Promise<PaginatedClientsResponse> => {
  const { headers } = UserModel.getInstance().getApiReqOpts();
  const { data } = await axios.get<PaginatedClientsResponse>(
    buildUrl({ dir, sortBy, limit, url }),
    {
      headers
    }
  );

  return data;
};

export const usePaginatedClients = ({ dir, sortBy, limit, url }: PaginatedClientsParams) => {
  return useQuery<PaginatedClientsResponse, QueryError>(
    ['clients', { dir, sortBy, limit, url }],
    () => fetchClients({ dir, sortBy, limit, url })
  );
};
