import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useISIntl } from 'src/i18n/utils';
import classNames from 'classnames';

import { TableProps } from './Table.types';
import { messages } from '../messages';
import { Row } from './TableRow.component';

export function Table<TData extends object>({
  isLoading,
  data = [],
  loading,
  columns,
  sortColumn,
  empty,
  rowKey,
  afterEach,
  onRowClick,
  className,
  caption,
  after
}: TableProps<TData>) {
  const { formatMessage } = useISIntl();

  const renderComponent = () => {
    if (isLoading) {
      return (
        <tr>
          {loading ? (
            loading()
          ) : (
            <td colSpan={columns.length} aria-busy="true" aria-valuetext="loading">
              {formatMessage(messages.rowLoading)}
            </td>
          )}
        </tr>
      );
    }

    if (!data.length) {
      return (
        <tr>
          {empty ? (
            empty()
          ) : (
            <td colSpan={columns.length} aria-label="empty">
              {formatMessage(messages.rowEmpty)}
            </td>
          )}
        </tr>
      );
    }
    return data.map((row) => (
      <Row
        key={`table-row__${row[rowKey]}`}
        columns={columns}
        row={row}
        rowKey={rowKey}
        onRowClick={onRowClick}
        afterEach={afterEach}
      />
    ));
  };

  return (
    <table className={classNames(className)} {...(caption ? { 'aria-label': caption } : {})}>
      <thead className="thead">
        <tr>
          {columns.map((column) => (
            <th
              scope="col"
              className={classNames(column.className, {
                'text-right': column.alignText === 'right'
              })}
              key={`${column.columnName}_${String(column.columnKey)}`}
              {...(column.onSort
                ? {
                    onClick: () => {
                      column.onSort?.(column.columnKey);
                    }
                  }
                : {})}>
              {column.columnName}{' '}
              {sortColumn?.field === column.columnKey && (
                <FontAwesomeIcon
                  size="xs"
                  icon={sortColumn.direction === 'asc' ? 'chevron-up' : 'chevron-down'}
                />
              )}
            </th>
          ))}
          {afterEach && <th>&nbsp;</th>}
        </tr>
      </thead>

      <tbody>
        {renderComponent()}
        {after && after(data)}
      </tbody>
    </table>
  );
}
