import React, { FC } from 'react';

import { defineMessages } from 'react-intl';

import { useISIntl } from 'src/i18n';
import UserModel from 'src/models/UserModel';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventComponent } from 'src/payments/types';
import { getPaymentEventSource } from 'src/payments/utils';

const messages = defineMessages({
  paymentsBlocked: {
    id: 'paymentsDashboard.paymentsBlocked',
    defaultMessage: 'Your Stripe account has been blocked from receiving payments and payouts. '
  },
  contactStripe: {
    id: 'paymentsDashboard.contactStripe',
    defaultMessage: 'Contact Stripe'
  }
});

export const PaymentsDashboardRejected: FC = () => {
  const { ft } = useISIntl();

  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    component: PaymentEventComponent.tileRejected
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, UserModel.getInstance().events);

  return (
    <div id="tailwind" data-testid="PaymentsDashboardRejected">
      <div className="flex flex-col w-screen h-fit mt-6 md:mt-20 justify-center gap-10 items-center">
        <p className="text-center gap-8 max-w-xl text-3xl">{ft(messages.paymentsBlocked)}</p>
        <a
          href="https://dashboard.stripe.com/account/status/"
          target="_blank"
          className="bg-orange-is px-5 py-4 border-none text-white rounded-xl font-bold text-2xl">
          {ft(messages.contactStripe)}
        </a>
      </div>
    </div>
  );
};
