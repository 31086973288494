import formatDate from 'date-fns/format';

export const localeOptions = [
  { value: 'en-US', label: 'English (US)' }, // Default for en
  { value: 'en-CA', label: 'English (CA)' },
  { value: 'en-GB', label: 'English (UK)' },
  { value: 'en-AU', label: 'English (AU)' },
  { value: 'en-NZ', label: 'English (NZ)' },
  { value: 'en-IE', label: 'English (IE)' },
  { value: 'nl-NL', label: 'Nederlands', en: 'Dutch' },
  { value: 'fr-FR', label: 'Français', en: 'French' },
  { value: 'de-DE', label: 'Deutsch', en: 'German' },
  { value: 'it-IT', label: 'Italiano', en: 'Italian' },
  { value: 'es-ES', label: 'Español', en: 'Spanish' }
];

export const DateFormats = [
  'yyyy-MM-dd',
  'dd-MM-yyyy',
  'MM-dd-yyyy',
  'yyyy/MM/dd',
  'dd/MM/yyyy',
  'MM/dd/yyyy',
  'yyyy.MM.dd',
  'dd.MM.yyyy',
  'MM.dd.yyyy',
  'MMM d, yyyy',
  'd MMM yyyy'
];

export const dateFormatOptions = () => {
  const dateOptions = DateFormats.map((format) => {
    const date = new Date();
    return {
      value: format,
      label: `${format} (${formatDate(date, format)})`
    };
  });
  return dateOptions;
};
