import React from 'react';
import { PhotoDrop, onDropErrorWithIntl } from '../PhotoDrop';
import { observer } from 'mobx-react';
import { compose } from 'src/util/functions';
import { injectIntl, ISIntl } from 'src/i18n';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';

interface Props {
  store?: AppStore;
  intl?: ISIntl;
}

const InvoiceLogo = (props: Props) => {
  const user = props.store!.user;
  const isLoading = props.store!.isLoading;
  return (
    <>
      <PhotoDrop
        userEvents={user.events}
        photo={user.settingList.logo}
        disabled={isLoading}
        onError={onDropErrorWithIntl(props.intl!)}
      />
    </>
  );
};

export default compose(injectStore, injectIntl, observer)(InvoiceLogo);
