import React, { useState } from 'react';
import colorOptions from '../../data/colorOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ColorSelect.css';
import { observer } from 'mobx-react';
import { DownIcon, UpIcon } from './Icons';
import { CustomColorWidget } from './CustomColorWidget';
import { defineMessages, injectIntl } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';

interface Prop {
  value: string | undefined;
  isLoading: boolean | undefined;
  onChange: (color: string) => void;
  intl?: ISIntl;
}

const messages = defineMessages({
  customColorTheme: {
    id: 'settings.list.theme.custom',
    defaultMessage: 'Custom Color'
  }
});

const isColorWhite = (color?: string) => {
  return (color || '').toUpperCase() === '#FFFFFF';
};

const ColorSelect = (props: Prop) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage: f } = props.intl!;

  const options = colorOptions.map((color) => {
    const currentColorSelected = color === props.value;
    const colorSelectOptionClass = currentColorSelected
      ? 'color-select-option selected'
      : 'color-select-option';
    const isSquareWhite = isColorWhite(color);
    const nonSelectedBorderColor = isSquareWhite ? '#B7B7B7' : color;
    const squareBorderColor = currentColorSelected ? '#455EDE' : nonSelectedBorderColor;

    return (
      <button
        className={colorSelectOptionClass}
        key={color}
        style={{
          backgroundColor: color,
          border: `2px ${squareBorderColor} solid`
        }}
        onClick={() => {
          setIsOpen(false);
          return !props.isLoading && props.onChange(color);
        }}
        type="button">
        <FontAwesomeIcon
          icon={isSquareWhite ? faBan : faCheck}
          style={isSquareWhite ? { color: '#757575', scale: '120%' } : {}}
        />
      </button>
    );
  });
  const colorSelectClass = props.isLoading ? 'color-select disabled' : 'color-select';

  const isColorWithinDefaults = (color: string | undefined) => {
    if (!color) {
      return false;
    }

    return colorOptions.includes(color);
  };

  const hiddenOnIE = window && !!window.navigator.userAgent.match(/(MSIE|Trident)/) ? `hidden` : ``;

  return (
    <section id="tailwind">
      <div className={colorSelectClass}>{options}</div>
      <div
        className={`w-full border-grey-100 bg-white rounded-sm flex justify-center content-between flex-column center p-0 ${hiddenOnIE}`}
        style={{ border: '1px solid #9FA6B2' }}>
        <button
          className="w-full border-none bg-white flex justify-between items-center flex-row px-2 py-2.5"
          onClick={() => setIsOpen(!isOpen)}
          type="button">
          {isColorWithinDefaults(props.value) ? (
            <img src="/images/color-wheel.png" width="34px" height="34px" />
          ) : (
            <div
              id="widget-button-state"
              className={'color-select-option selected'}
              key={props.value}
              style={{ backgroundColor: props.value }}>
              <FontAwesomeIcon icon="check" />
            </div>
          )}
          <p
            className="text-sm w-full h-full pl-3 p-2 text-left"
            style={{ fontFamily: 'Roboto', fontSize: '13px' }}
            id="color-widget-button">
            {f(messages.customColorTheme)}
          </p>
          <div
            className="flex justify-center content-center items-center"
            style={{ height: '100%', padding: '0.5rem 1rem' }}>
            {isOpen ? (
              <UpIcon width="15px" height="15px" />
            ) : (
              <DownIcon width="15px" height="15px" />
            )}
          </div>
        </button>

        <CustomColorWidget isOpen={isOpen} {...props} />
      </div>
    </section>
  );
};

export default injectIntl(observer(ColorSelect));
