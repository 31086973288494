import React from 'react';

export const SettingsBoxRowWrapper = ({
  label,
  children
}: {
  label: string;
  children: JSX.Element;
}): JSX.Element => {
  return (
    <div className="setting-form-group form-group row" data-testid="settingsBoxRowWrapper">
      <label className="col-md-4 col-form-label">{label}</label>
      <div className="col-md-8">{children}</div>
    </div>
  );
};
