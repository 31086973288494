import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './ClientList.scss';
import { defineMessages } from 'src/i18n';
import { FormattedMessage } from 'react-intl';
import { useISIntl } from 'src/i18n/utils';
import { PaginatedClientsResponse, usePaginatedClients } from './usePaginatedClients';
import { Clients } from './Clients';
import { PaginationButtons } from '../PaginationButtons';
import { trackEvent } from 'src/analytics/controller';
import classnames from 'classnames';
import { useSearchClients } from './useSearchClients';
import { useDebounce } from 'src/util/useDebounce';
import ClientListModel from 'src/models/ClientListModel';

interface ClientListProps {
  onNav: (key: string, opts?: any) => void;
  list: ClientListModel;
  onError: (error: { title?: string; body?: string }) => void;
}

const messages = defineMessages({
  alertConnectivityErrorTitle: {
    id: 'app.alert.connectivityErrors.title',
    defaultMessage: 'Connectivity problem while contacting our servers.'
  },
  alertConnectivityErrorBody: {
    id: 'app.alert.connectivityErrors.body',
    defaultMessage: `Please try reloading the app. If the problem persists, contact our support team support@invoicesimple.com\n{details}`
  },
  title: {
    id: 'client.list.title',
    defaultMessage: 'Clients'
  },
  description: {
    id: 'client.list.description',
    defaultMessage:
      'Need to manage your Client list? Invoice Simple makes it easy, and will auto-complete Clients next time you make an invoice.'
  },
  buttonNew: {
    id: 'client.list.buttons.new',
    defaultMessage: 'New Client'
  },
  rowLoading: {
    id: 'client.list.row.loading',
    defaultMessage: 'Loading your Clients...'
  },
  search: {
    id: 'invoice.list.search.placeholder',
    defaultMessage: 'Search by Client Name'
  },
  tableHeadName: {
    id: 'client.list.table.head.name',
    defaultMessage: 'Name'
  },
  tableHeadEmail: {
    id: 'client.list.table.head.email',
    defaultMessage: 'Email'
  },
  tableHeadAddress: {
    id: 'client.list.table.head.address',
    defaultMessage: 'Address'
  },
  tableHeadPhone: {
    id: 'client.list.table.head.phone',
    defaultMessage: 'Phone'
  },
  tableFooterHint: {
    id: 'client.list.table.footer.hint',
    defaultMessage: 'All your Clients are auto saved here'
  },
  tableHeadTotalBilled: {
    id: 'client.list.table.head.totalBilled',
    defaultMessage: 'Total Billed'
  }
});

const ClientList = ({ onNav, onError, list }: ClientListProps) => {
  const intl = useISIntl();
  const [url, setUrl] = useState('');
  const [query, setQuery] = useState('');

  const {
    data: paginationData,
    isInitialLoading: isPaginationLoading,
    isError: isPaginationError
  } = usePaginatedClients({
    dir: 'desc',
    limit: 200,
    url
  });

  const {
    data: searchData,
    isInitialLoading: isSearchLoading,
    isError: isSearchError
  } = useSearchClients(
    { searchTerm: useDebounce(query, 500) },
    {
      onSuccess: () => {
        list.user.trackAppEventViaApi('web-search', {
          page: 'clients'
        });
      }
    }
  );

  const data = searchData || paginationData;
  const isLoading = isSearchLoading || isPaginationLoading;
  const isError = isSearchError || isPaginationError;

  const hasSearchResults = !!searchData?.clients?.length;

  /**
   * Invalidates the URL when the list is updated
   * to avoid paginating to a page previuosly with a single client
   */
  const resetPagination = () => {
    setUrl('');
  };

  function handleSearch(searchTerm: string) {
    setQuery(searchTerm);
  }

  const handleCreate = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    resetPagination();
    onNav('clientCreate');
  };

  const handleView = (id: string) => {
    resetPagination();
    onNav('clientEdit', { id });
  };

  const f = intl.formatMessage;

  const getPage = (data: PaginatedClientsResponse): 'other' | 'first' | 'last' => {
    if (data.prev && data.next) return 'other';
    return data.next ? 'first' : 'last';
  };

  const onNextPage = () => {
    if (paginationData) {
      trackEvent('pagination-click', {
        path: window.location.pathname,
        action: 'next',
        page: getPage(paginationData)
      });
      setUrl(paginationData.next);
    }
  };

  const onPrevPage = () => {
    if (paginationData) {
      trackEvent('pagination-click', {
        path: window.location.pathname,
        action: 'prev',
        page: getPage(paginationData)
      });
      setUrl(paginationData.prev);
    }
  };

  useEffect(() => {
    if (isError) {
      onError({
        title: f(messages.alertConnectivityErrorTitle),
        body: f(messages.alertConnectivityErrorBody, { details: '' })
      });
    }
  }, [isError]);

  return (
    <div className={classnames(['client-list', { loading: isLoading }])}>
      <div className="container">
        <Helmet>
          <title itemProp="name">{f(messages.title)}</title>
          <meta name="description" content={f(messages.description)} />
        </Helmet>
        <div className={'client-list-header page-header client-search-container'}>
          <h1>
            <FormattedMessage {...messages.title} />
          </h1>
          <div className="client-list-actions w-full">
            <input
              type="text"
              id="client-search"
              placeholder={f(messages.search)}
              onChange={(e) => handleSearch(e.target.value)}
              className="mr-2 w-auto"
              value={query}
            />

            <a
              className={`btn btn-prime btn-client-new`}
              href="/clients/new"
              onClick={handleCreate}>
              <FormattedMessage {...messages.buttonNew} />
            </a>
          </div>
        </div>

        <div className="client-list-body panel">
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="invoice-head-invoice">
                  <FormattedMessage {...messages.tableHeadName} />
                </th>
                <th className="invoice-head-invoice">
                  <FormattedMessage {...messages.tableHeadEmail} />
                </th>
                <th className="invoice-head-balance">
                  <FormattedMessage {...messages.tableHeadAddress} />
                </th>
                <th className="invoice-head-balance">
                  <FormattedMessage {...messages.tableHeadPhone} />
                </th>
                <th className="invoice-head-balance text-right">
                  <FormattedMessage {...messages.tableHeadTotalBilled} />
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr className="invoice-row-loading">
                  <td colSpan={5}>
                    <span>{f(messages.rowLoading)}</span>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <Clients
                  onView={handleView}
                  onCreate={handleCreate}
                  data={data}
                  query={query}
                  hasSearchResults={hasSearchResults}
                  isError={isError}
                  onError={onError}
                />
              </tbody>
            )}
          </table>
        </div>

        <div className="client-list-footer">
          <span className="helper-message">
            <FormattedMessage {...messages.tableFooterHint} />
          </span>
        </div>

        <PaginationButtons
          hidden={!!query}
          data={paginationData}
          onPrev={onPrevPage}
          onNext={onNextPage}
        />
      </div>
    </div>
  );
};

export default ClientList;
