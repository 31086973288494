import ReactDOM from 'react-dom';
import environmentStore from '../stores/EnvironmentStore';

const createPortal = (...args) => {
  if (environmentStore.isSnapshot()) {
    return () => null;
  }

  return ReactDOM.createPortal.call(null, ...args);
};

export default createPortal;
