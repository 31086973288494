import React from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { LoadingSpinner } from 'src/components/Button';
import { useISIntl } from 'src/i18n';
import UserModel, { Cadence } from 'src/models/UserModel';
import { getSubscriptionBadgeDiscount, getTierPrice, messages } from '../../../utils';

interface CardPurchaseFooterProps {
  selectedTier: SubscriptionTier;
  selectedInterval: Cadence;
  handleSubscriptionPurchase: (selectedTier: any) => void;
  loading: boolean;
}

export const CardPurchaseFooter = ({
  selectedTier,
  selectedInterval,
  handleSubscriptionPurchase,
  loading
}: CardPurchaseFooterProps) => {
  const { ft } = useISIntl();
  const user = UserModel.getInstance();

  const couponInfo = user.paywallCouponInfo;

  const { comparePrice, displayPrice } = getTierPrice(selectedTier, selectedInterval);
  const subscriptionBadgeDiscount = getSubscriptionBadgeDiscount(selectedTier, selectedInterval);

  return (
    <div
      data-testid="mobile-fixed-footer"
      className="bg-white w-full fixed left-0 bottom-0 p-4 z-10"
      style={{ borderTop: '1px solid #E5E7EB' }}>
      {couponInfo ? (
        <div className="flex flex-col justify-center items-center">
          <p className="text-2xl font-bold text-gray-950">
            {selectedInterval === Cadence.MONTHLY
              ? ft(messages.billedMonthly, {
                  price: displayPrice,
                  duration: couponInfo.durationMonths
                })
              : ft(messages.billedAnnually, { price: displayPrice })}
          </p>
          <p className="text-2xl font-semibold">
            {ft(messages.priceAfterDiscount, {
              price: comparePrice,
              cadence: selectedInterval === Cadence.MONTHLY ? 'month' : 'year'
            })}
          </p>
        </div>
      ) : (
        <div className="flex flex-row justify-center items-center gap-3">
          {comparePrice && <p className="line-through text-2xl">{comparePrice}</p>}
          <p className="text-2xl font-extrabold text-gray-950">
            {displayPrice}
            <span className="text-xl font-semibold text-gray-500">
              /{selectedInterval === Cadence.MONTHLY ? 'mo' : 'yr'}
            </span>
          </p>
          {subscriptionBadgeDiscount && (
            <p className="text-2xl text-green-700 font-bold">{`(${ft(messages.discountedPaywallBadge, { discount: subscriptionBadgeDiscount })})`}</p>
          )}
        </div>
      )}

      <button
        disabled={loading}
        data-testid="mobile-buy-button"
        onClick={() => handleSubscriptionPurchase(selectedTier)}
        type="button"
        className="mt-4 mb-1 no-underline w-full flex flex-row items-center justify-center flex-grow rounded-[4px] border-[1px] border-solid py-3 text-center hover:opacity-70 bg-orange-is text-white border-orange-is">
        {loading && <LoadingSpinner loadingTextStyle="text-white" />}
        <div>
          <p className="text-3xl font-semibold">{ft(messages.subscriptionBuyNowButton)}</p>
          <p className="text-2xl font-medium">
            {ft(messages.planTierCadence, { tier: selectedTier, cadence: selectedInterval })}
          </p>
        </div>
      </button>
    </div>
  );
};
