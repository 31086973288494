import { getCookie } from 'src/util/cookie';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';

import { getPaymentEventSource } from '../utils';
import { getIsPaymentsTokenFromParseToken } from './paymentsToken';
import { navigateHard } from './paypal.api';

export const redirectToPaymentsLandingPage = async (
  sessionToken: string,
  isNewTab: boolean = false
) => {
  if (process.env.REACT_APP_PAYMENTS_LANDING_PAGE) {
    try {
      const origin = getURLQueryParam(URLQueryParamKeys.ORIGIN) || undefined;
      const platform = getURLQueryParam(URLQueryParamKeys.PLATFORM) || undefined;
      const token = await getIsPaymentsTokenFromParseToken(sessionToken);
      const queryParams = new URLSearchParams({ token });

      origin && queryParams.set(URLQueryParamKeys.ORIGIN, origin);
      platform && queryParams.set(URLQueryParamKeys.PLATFORM, platform);

      navigateHard(
        `${process.env.REACT_APP_PAYMENTS_LANDING_PAGE}/auth?${queryParams.toString()}`,
        isNewTab
      );
    } catch (err) {
      navigateHard('/', isNewTab);
    }
  } else {
    throw new Error('No PAYMENTS_LANDING_PAGE setup');
  }
};

export const redirectToPaymentsLandingPageWithEvents = async (isNewTab: boolean = false) => {
  const sessionToken = getCookie('sessionToken');
  const origin = getPaymentEventSource();
  const token = await getIsPaymentsTokenFromParseToken(sessionToken);
  const queryParams = new URLSearchParams({
    token,
    origin,
    platform: 'web'
  });

  navigateHard(
    `${process.env.REACT_APP_PAYMENTS_LANDING_PAGE}/auth?${queryParams.toString()}`,
    isNewTab
  );
};
