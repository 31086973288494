import React, { FC } from 'react';
import { StripeBaseBox } from './StripeBaseBox';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { GrayTimerIcon } from 'src/components/Icons';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { getPaymentEventSource } from 'src/payments/utils';

export const StripePendingVerificationBoxComponent: FC<{
  onCTAClick: () => void;
  store: AppStore;
}> = ({ onCTAClick, store }) => {
  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    component: PaymentEventComponent.tilePendingVerification
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);

  return (
    <>
      <div data-testid="stripePendingVerificationBox" />
      <StripeBaseBox
        icon={<GrayTimerIcon />}
        actionElement={
          <div className="w-auto max-w-[30%] flex items-center justify-center">
            <ToggleSwitch checked={false} onChange={onCTAClick} disabled={false} />
          </div>
        }
      />
    </>
  );
};

export const StripePendingVerificationBox = injectStore(StripePendingVerificationBoxComponent);
