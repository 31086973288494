import React, { FC } from 'react';
import { OrangeWarningIcon } from 'src/components/Icons';
import { StripeBaseBox } from '../../StripeBaseBox';
import { BlueCTAButton } from '../../../Common';
import { usePaymentOnboardModal } from 'src/payments/hooks/usePaymentsOnboardModal';
import { PaymentsCurrencyMismatchFixModal } from 'src/payments/components/PaymentsModals';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { getPaymentEventSource } from 'src/payments/utils';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';

const StripeCurrencyMismatchBoxComponent: FC<
  {
    onFixUserCurrencyMismatchHandler: () => void;
    footer?: JSX.Element;
  } & { store: AppStore }
> = ({ onFixUserCurrencyMismatchHandler, footer, store: { user } }) => {
  const stripeSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    source: getPaymentEventSource()
  };
  const { isOpen, showModal, hideModal, origin } = usePaymentOnboardModal();

  const setCurrency = () => {
    user.events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.modalCurrencyMismatchFix
    });
    onFixUserCurrencyMismatchHandler();
    hideModal();
  };

  const onClick = () => {
    user.events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.tileCurrencyMismatchFix
    });
    showModal();
  };

  const onClickXIcon = () => {
    user.events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.modalCurrencyMismatchFixClose
    });
    hideModal();
  };

  const paymentsViewedMeta = {
    ...stripeSharedMetadata,
    component: PaymentEventComponent.tileCurrencyMismatchFix
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <div data-testid="stripeCurrencyMismatchBox">
      <StripeBaseBox
        icon={<OrangeWarningIcon />}
        actionElement={<BlueCTAButton onClick={onClick}>Fix</BlueCTAButton>}
        footer={footer}
      />
      <PaymentsCurrencyMismatchFixModal
        activePaymentProvider={PaymentEventActivePaymentProvider.stripe}
        isOpen={isOpen}
        hideModal={onClickXIcon}
        setCurrency={setCurrency}
        origin={origin}
      />
    </div>
  );
};

export const StripeCurrencyMismatchBox = injectStore(StripeCurrencyMismatchBoxComponent);
