import React from 'react';

import UserModel from 'src/models/UserModel';
import { useStripeConnectInstance } from 'src/payments/hooks/useStripeConnectInstance';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { getPaymentEventSource } from 'src/payments/utils';

import { ConnectComponentsProvider, ConnectPayouts } from '@stripe/react-connect-js';

export const StripePayouts: React.FC = () => {
  const { stripeConnectInstance } = useStripeConnectInstance();

  const paymentsViewedMeta = {
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    source: getPaymentEventSource(),
    component: PaymentEventComponent.stripePayoutsTab
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, UserModel.getInstance().events);

  return (
    <div id="tailwind" data-testid="StripePayouts">
      <div className="rounded-xl bg-white px-4 pt-4 pb-8">
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectPayouts />
        </ConnectComponentsProvider>
      </div>
    </div>
  );
};
