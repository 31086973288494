import React from 'react';
import { FormattedTextRow } from './FormattedTextRow';

interface SettingsAccountDetailsSectionDictionary {
  accountEmail: string;
  merchantId: string;
  currency: string;
}

export interface DefProps {
  dictionary: SettingsAccountDetailsSectionDictionary;
  merchantId?: string | null;
  currency?: string | null;
  email?: string | null;
}

export const SettingsAccountDetailsSection = ({
  merchantId,
  currency,
  email,
  dictionary
}: DefProps): JSX.Element => {
  return (
    <div className="grid grid-cols-1 gap-y-2 -mt-2">
      {email && <FormattedTextRow label={dictionary.accountEmail} value={email} />}
      {merchantId && <FormattedTextRow label={dictionary.merchantId} value={merchantId} />}
      {currency && (
        <FormattedTextRow label={dictionary.currency} value={currency} valueProps="uppercase" />
      )}
    </div>
  );
};
