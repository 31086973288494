import React, { useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { PaymentEvent, PaymentEventName } from '../../types';
import { PaymentSetupButton } from '../PaymentBasicElements';

import './PaypalPromotionBox.scss';

import { redirectToPaymentsLandingPageWithEvents } from 'src/payments/utils';
import { onError } from 'src/payments/utils/utils';

import { messages } from './messages';

interface Props {
  onTrackEvent: (eventName: PaymentEvent, metadata?: Record<string, number | string>) => void;
}

export const PaypalInitialBox = ({ onTrackEvent }: Props) => {
  const [loadingUrl, setLoadingUrl] = useState(false);

  useEffect(() => {
    onTrackEvent(PaymentEventName.initialCtaShown);
  }, []);

  const goToPaypalOnboarding = async () => {
    try {
      setLoadingUrl(true);
      onTrackEvent(PaymentEventName.onboardingStart);
      await redirectToPaymentsLandingPageWithEvents(true);
    } catch (error) {
      onError({ title: 'Error initiating PayPal onboarding', body: error.message });
    } finally {
      setLoadingUrl(false);
    }
  };

  return (
    <div className="row">
      <p className="text-body">
        <FormattedMessage {...messages.onboardingBody} />
      </p>
      <p className="text-note">
        <FormattedMessage {...messages.onboardingNote} />
      </p>
      <PaymentSetupButton
        className="col-5 onboarding-button"
        buttonText={<FormattedMessage {...messages.onboardingButton} />}
        buttonBackgroundColor="orange"
        loading={loadingUrl}
        onButtonClick={goToPaypalOnboarding}
      />
    </div>
  );
};
