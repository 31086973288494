import { defineMessages } from 'react-intl';

export const messages = {
  free: defineMessages({
    titleSingular: {
      id: 'docLimit.banner.free.title.singular',
      defaultMessage: '1 document left on free trial'
    },
    titlePlural: {
      id: 'docLimit.banner.free.title.plural',
      defaultMessage: '{count} documents left on free trial'
    },
    initialBody1: {
      id: 'docLimit.banner.free.initial.body1',
      defaultMessage:
        'You can create {docCount} free {docCount, plural, one {document} other {documents}}.'
    }
  })
};
