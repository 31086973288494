const getGoogleTag = (): Function | undefined => {
  type WindowWithDataLayer = Window & {
    gtag?: Function;
  };

  return (window as unknown as WindowWithDataLayer)?.gtag;
};

export const trackGoogleAdsConversion = () => {
  const gtag = getGoogleTag();

  gtag?.('event', 'conversion', {
    send_to: 'AW-1006814914/B0MTCIr10p4ZEMKNi-AD'
  });
};

export const trackGoogleFirstInvoiceCreated = () => {
  const gtag = getGoogleTag();

  gtag?.('event', 'conversion', {
    send_to: 'AW-1006814914/K4PyCNao3sUZEMKNi-AD'
  });
};
