import React from 'react';
import { Visa } from './Visa';
import { Mastercard } from './Mastercard';
import { Amex } from './Amex';
import { Discover } from './Discover';
import { Paypal } from './Paypal';

export function SupportedPaymentMethods(): JSX.Element {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <Visa />
      <Mastercard />
      <Amex />
      <Discover />
      <Paypal />
    </div>
  );
}
