import axios from 'axios';
import { action, computed, observable } from 'mobx';

import { Platform, SubscriptionTier } from '@invoice-simple/common';
import { getCouponPayload } from '@invoice-simple/is-coupon';
import { WebEventName } from '@invoice-simple/is-events';

import { sendPremiumSubscriptionEmail } from 'src/apis/emailAPI';
import {
  getPaywallCouponInfo,
  isEndOfTermSwitch,
  SubscriptionSwitchType
} from 'src/components/SubscriptionPaywall/utils';
import SubscriptionModel from 'src/models/SubscriptionModel';
import { ChargebeeSubscription } from 'src/types/Subscription';
import { insertDecimal, wholePriceToFloat } from 'src/util/number';
import { EVENTS, pubsub } from 'src/util/pubsub';
import alertModel from '../models/AlertModel';
import ErrorModel from '../models/ErrorModel';
import location, { LocationModel } from '../models/LocationModel';
import UserModel, { Cadence } from '../models/UserModel';
import { RouteOptions } from '../types/RouteOptions';
import CurrentDocumentStore from './CurrentDocumentStore';
import environmentStore from './EnvironmentStore';

export class AppStore {
  quota: number = 3;

  subCadence: Cadence;

  @observable user: UserModel;
  @observable location: LocationModel;

  // recent errors
  @observable errors: ErrorModel[] = [];

  @observable isAppDisabled: boolean = false;

  constructor() {
    this.location = location;

    this.user = UserModel.getInstance();
    this.user.trackAppEventViaApi('app-load');

    if (environmentStore.isChromeExtVisit) {
      this.user.trackAppEventViaApi('chrome-ext-visit');
    }

    pubsub.once(EVENTS.CONNECTIVITY_ERROR, this.onConnectivityError);
  }

  @action
  public disableApp() {
    this.isAppDisabled = true;
  }

  @action
  public enableApp(): void {
    this.isAppDisabled = false;
  }

  @action.bound
  onConnectivityError(details: string) {
    alertModel.setAlertObject({
      isClosable: false,
      type: 'danger',
      titleMessageId: 'alertConnectivityErrorTitle',
      bodyMessageId: 'alertConnectivityErrorBody',
      params: {
        details
      }
    });
    this.user.trackAppEventViaApi('connectivity-alert', { message: details });
    this.isAppDisabled = true;
  }

  @computed
  get doc() {
    return CurrentDocumentStore.currentDoc;
  }

  @computed
  get isLoading(): boolean {
    return this.isAppDisabled || !this.user.isLoaded || environmentStore.isSnapshot();
  }

  @computed
  get upgradeEventData(): any {
    return {
      app: environmentStore.appHost,
      'app-host': environmentStore.appHost,
      'is-trial-enabled': this.user.isSubTrial,
      'is-trial-active': this.user.isSubTrialActive,
      ...this.user.subUpgrade.eventData,

      // overrides
      'current-order-sku': this.user.lastActiveSub?.orderSku ?? null,
      'current-price': null,
      'new-price-usd': insertDecimal(this.user.getGeoSubAmount({ usdPrice: true })),
      'new-order-sku': this.user.getGeoSubOrderSku(),
      tier: this.getTierFromSku(this.user.lastActiveSub?.orderSku ?? '')
    };
  }

  public getSubscriptionEventData({
    tier,
    cadence,
    couponSku
  }: {
    tier?: SubscriptionTier;
    cadence?: Cadence;
    couponSku?: string;
  }) {
    const eventData = this.upgradeEventData;
    const subCadence = cadence || this.subCadence;
    const price = this.user.getGeoSubAmount({ cadence: subCadence, tier });
    const usdPrice = this.user.getGeoSubAmount({ cadence: subCadence, tier, usdPrice: true });
    eventData['new-price-usd'] = insertDecimal(usdPrice);
    eventData['new-order-sku'] = this.user.getGeoSubOrderSku({ cadence: subCadence, tier });
    eventData.currency = this.user.geoSubCurrencyCode;
    eventData.price = wholePriceToFloat(price);
    eventData.tier = tier;

    if (couponSku) {
      const coupon = getPaywallCouponInfo();

      if (tier) {
        const params = {
          tier,
          paywallCouponConfig: coupon,
          cadence: subCadence
        };

        const discountPrice = this.user.getDiscountTierPrice(params); // Price paid after discount
        const discountPriceUSD = this.user.getDiscountTierPrice({ ...params, usdPrice: true }); // Price paid after discount in USD

        eventData['price'] = wholePriceToFloat(discountPrice);
        eventData['discount-price-usd'] = insertDecimal(discountPriceUSD);
      }

      eventData['coupon-sku'] = coupon?.name;
      eventData['store-coupon-sku'] =
        coupon &&
        getCouponPayload({
          paywallCouponConfig: coupon,
          platform: Platform.WEB,
          tier,
          cadence: subCadence
        })?.coupon;
    }

    return eventData;
  }

  public getTieredPaywallEventData(tier?: SubscriptionTier, cadence?: Cadence) {
    const eventData = this.getSubscriptionEventData({ tier, cadence });
    delete eventData['new-price-usd'];
    delete eventData['new-order-sku'];
    delete eventData['current-price'];
    delete eventData['currency'];

    eventData['current-order-sku'] = this.user.lastActiveSub?.orderSku ?? null;

    return eventData;
  }

  public getDiscountedPaywallEventData(
    tier?: SubscriptionTier,
    cadence?: Cadence,
    couponSku?: string
  ) {
    return {
      ...this.getSubscriptionEventData({ tier, cadence, couponSku }),
      platform: Platform.WEB
    };
  }

  public upgradeSubscription = async ({
    subscriptionToSwitch,
    newOrderSku,
    switchType,
    couponSku,
    newPrice
  }: {
    subscriptionToSwitch: SubscriptionModel;
    newOrderSku: string;
    switchType: SubscriptionSwitchType;
    newPrice: number;
    couponSku?: string;
  }) => {
    const url = '/api/v2/subscription/upgrade/web';
    const endOfTerm = isEndOfTermSwitch(switchType);
    const res = await axios.post(
      url,
      { ...subscriptionToSwitch, orderSku: newOrderSku, endOfTerm, couponSku },
      this.user.getApiReqOpts()
    );

    const orderDetails: ChargebeeSubscription = JSON.parse(res.data?.charge?.orderDetails || '{}');

    this.user.events.trackAction('subscription-switch', {
      type: switchType,
      platform: Platform.WEB,
      status: res.status === 201 ? 'success' : 'failed',
      orderId: orderDetails.id ?? subscriptionToSwitch.orderId,
      oldSku: subscriptionToSwitch.orderSku ?? '',
      newSku: newOrderSku,
      changeDate: endOfTerm ? new Date(subscriptionToSwitch.expiryTimestamp!) : new Date(),
      interPlatform: false,
      price: wholePriceToFloat(newPrice),
      currency: this.user.geoSubCurrencyCode,
      tier: this.getTierFromSku(newOrderSku),
      oldTier: subscriptionToSwitch.tier,
      version: ''
    });

    // send an email to premium tier upgrades only for the first time
    if (
      res.status === 201 &&
      newOrderSku.includes('premium') &&
      !this.user.isSubTier(SubscriptionTier.PREMIUM)
    ) {
      await this.sendPremiumSubEmail();
    }
  };

  private getTierFromSku = (sku: string): SubscriptionTier | null => {
    switch (true) {
      case sku.includes('premium.legacy'):
        return SubscriptionTier.PREMIUM_LEGACY;
      case sku.includes('premium'):
        return SubscriptionTier.PREMIUM;
      case sku.includes('plus'):
        return SubscriptionTier.PLUS;
      case sku.includes('pro'):
        return SubscriptionTier.PRO;
      case sku.includes('payments'):
        return SubscriptionTier.PAYMENTS_TIER;
      case sku.includes('essentials'):
        return SubscriptionTier.ESSENTIALS;
      case sku.includes('starter'):
        return SubscriptionTier.STARTER;
      default:
        return null;
    }
  };

  public subscriptionPurchased({
    eventName,
    email,
    tier,
    cadence,
    couponSku
  }: {
    eventName: Extract<
      WebEventName,
      | 'subscription-purchase-invoices'
      | 'subscription-purchase-login'
      | 'subscription-purchase-signup'
    >;
    email: string;
    tier?: SubscriptionTier;
    cadence?: Cadence;
    couponSku?: string;
  }): void {
    this.user.syncSubscriptions();
    this.user.trackAdwords('web-purchase');
    this.user.initEmail(email);

    this.user.trackAppEventViaApi(
      eventName,
      this.getSubscriptionEventData({ tier, cadence, couponSku })
    );
  }

  // send email for premium sub purchase or upgrade
  public async sendPremiumSubEmail(email?: string, accountId?: string, firstName?: string) {
    const emailData = {
      accountId: accountId ?? this.user.accountId,
      firstName: firstName ?? this.user.firstName ?? undefined,
      email: email ?? this.user.email
    };
    await sendPremiumSubscriptionEmail(emailData);
  }

  // nav wrappers
  public docNav = (actionString: string) => {
    const id = this.doc.id || this.location.id;
    this.nav(`${this.location.canonicalDocName}${actionString}`, { id });
  };
  public docPublicNav(): void {
    this.docNav('Public');
  }
  public docListNav(opts: RouteOptions = {}): void {
    this.nav(`${this.location.canonicalDocName}List`, opts);
  }

  // nav action
  @action.bound
  public nav(name: string, opts: RouteOptions = {}, preserveQueryParams = false) {
    return this.location.nav(name, opts, preserveQueryParams);
  }

  // nav and scroll
  @action.bound
  public navAndScrollTop(name: string, opts: RouteOptions = {}) {
    return this.location.navAndScrollTop(name, opts);
  }

  @action.bound
  public logout(): void {
    this.user
      .logout()
      .catch((err) => alertModel.setAlert('danger', 'Unable to Logout.', err.message));
  }
}

export default new AppStore();
