import { ContentTypes } from '@invoice-simple/common';
import React from 'react';
import { observer } from 'mobx-react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { injectIntl, defineMessages } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';
import PhotoModel from '../models/PhotoModel';
import AlertModel from '../models/AlertModel';
import { Desktop } from './Responsive';
import './PhotoDrop.scss';
import UserEventModel from 'src/models/UserEventModel';

interface Prop {
  userEvents: UserEventModel;
  photo: PhotoModel;
  disabled: boolean;
  onError: (errorMessage: string) => void;
  intl?: ISIntl;
}

const messages = defineMessages({
  logo: {
    id: 'photoDrop.logo',
    defaultMessage: 'logo'
  },
  saving: {
    id: 'photoDrop.saving',
    defaultMessage: 'saving logo'
  },
  add: {
    id: 'photoDrop.add',
    defaultMessage: 'add your logo'
  },
  update: {
    id: 'photoDrop.update',
    defaultMessage: 'update your logo'
  },
  remove: {
    id: 'photoDrop.remove',
    defaultMessage: 'remove your logo'
  },
  removeConfirm: {
    id: 'photoDrop.removeConfirm',
    defaultMessage: 'are you sure you want to remove your logo?'
  },
  alertLogoSaveFailedTitle: {
    id: 'app.alert.logo.save.failed.title',
    defaultMessage: 'Unable to save logo'
  },
  uploadFailedGenericError: {
    id: 'photoDrop.upload.failed.genericError',
    defaultMessage: 'An error occurred uploading your photo.'
  },
  uploadFailedMaxFileSizeExceeded: {
    id: 'photoDrop.upload.failed.maxFileSizeExceeded',
    defaultMessage: 'Please upload an image file smaller than {maxFileSize}Mb.'
  },
  uploadFailedMaxResolutionExceeded: {
    id: 'photoDrop.upload.failed.maxResolutionExceeded',
    defaultMessage: 'Image resolution exceeds the maximum limit of {maxResolution}MP.'
  },
  uploadFailedTooManyFiles: {
    id: 'photoDrop.upload.failed.tooManyFiles',
    defaultMessage: 'Please upload one file at a time'
  },
  uploadFailedUnsupportedFileType: {
    id: 'photoDrop.upload.failed.unsupportedFileType',
    defaultMessage: 'Only jpg, jpeg, png, and webp file types are allowed'
  }
});

const MAX_FILE_SIZE = 5 * 1000000; // 5MB
const MAX_RESOLUTION = 20 * 1000000; // 20MP

export const onDropErrorWithIntl = (intl: ISIntl) => (errorMessage: string) => {
  AlertModel.setAlert(
    'danger',
    intl.formatMessage(messages.alertLogoSaveFailedTitle),
    errorMessage
  );
};

@injectIntl
@observer
export class PhotoDrop extends React.Component<Prop> {
  state = {
    deleteBtnHovered: false
  };

  _onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], _event: DropEvent) => {
    const { userEvents } = this.props;
    const f = this.props.intl!.formatMessage;

    function getErrorFromRejections(fileRejections: FileRejection[]): string {
      const { file, errors } = fileRejections[0];
      const errorCodes = errors.map((error) => error.code);
      if (errorCodes.includes('file-too-large')) {
        userEvents.trackAction('validation-error', {
          action: 'upload-photo',
          'file-extension': file.type,
          size: file.size,
          type: 'logo,'
        });
        return f(messages.uploadFailedMaxFileSizeExceeded, {
          maxFileSize: MAX_FILE_SIZE / 1000000
        });
      }

      if (errorCodes.includes('too-many-files')) {
        return f(messages.uploadFailedTooManyFiles);
      }

      if (errorCodes.includes('file-invalid-type')) {
        return f(messages.uploadFailedUnsupportedFileType);
      }

      return f(messages.uploadFailedGenericError);
    }

    if (fileRejections.length > 0) {
      this.props.onError(getErrorFromRejections(fileRejections));
      return;
    }

    if (acceptedFiles && acceptedFiles[0]) {
      const file = acceptedFiles[0];

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const resolution = img.width * img.height;
        if (resolution > MAX_RESOLUTION) {
          this.props.onError(
            f(messages.uploadFailedMaxResolutionExceeded, {
              maxResolution: MAX_RESOLUTION / 1000000
            })
          );
          return;
        }

        this.props.photo.upload(file);
        AlertModel.resetAlert();
      };
    } else {
      this.props.onError(f(messages.uploadFailedGenericError));
    }
  };

  render() {
    const photo = this.props.photo;
    const accept = {
      [ContentTypes.IMG_JPEG]: ['.jpg', '.jpeg'],
      [ContentTypes.IMG_PNG]: ['.png'],
      [ContentTypes.IMG_WEBP]: ['.webp']
    };
    const url = photo.previewUrl || photo.url || '';
    const showPhoto = photo.isUploading ? !!url : !photo.deleted && !!url;
    const classString = photo.isUploading ? 'photo-drop-zone uploading' : 'photo-drop-zone';
    const { ft } = this.props.intl!;

    return (
      <div className="photo-drop" data-testid="photo-drop">
        {showPhoto && (
          <div
            className="delete-logo-container"
            data-testid="delete-logo-container"
            onMouseEnter={() => this.setState({ deleteBtnHovered: true })}
            onMouseLeave={() => this.setState({ deleteBtnHovered: false })}
            onClick={() => {
              if (window.confirm(ft(messages.removeConfirm))) {
                this.props.photo.delete();
              }
              this.setState({ deleteBtnHovered: false });
            }}>
            <FontAwesomeIcon className="btn-delete-logo" icon="times" />
          </div>
        )}
        <Dropzone
          onDrop={this._onDrop}
          maxSize={MAX_FILE_SIZE}
          minSize={1}
          accept={accept}
          multiple={false}
          disabled={this.props.disabled}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: classString })}>
              <input {...getInputProps()} />
              <div className="dropzone-symbols">
                {showPhoto && (
                  <img src={url} className="logo-image" alt="" data-testid="logo-image" />
                )}
                {!showPhoto && (
                  <>
                    <FontAwesomeIcon icon="image" size="2x" pull="left" />
                    <span>{`+ ${ft(messages.logo)}`}</span>
                  </>
                )}

                <span className="upload-message">
                  <FontAwesomeIcon icon="cloud-upload-alt" />
                  <b>{`${ft(messages.saving)}...`}</b>
                </span>

                <Desktop>
                  <span className="guide-message">
                    {!showPhoto && <b>{ft(messages.add)}</b>}
                    {showPhoto && (
                      <b>
                        {this.state.deleteBtnHovered ? ft(messages.remove) : ft(messages.update)}
                      </b>
                    )}
                  </span>
                </Desktop>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}
