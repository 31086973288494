import React, { createContext, useEffect, useState } from 'react';
import DialogModal from './DialogModal';

interface DialogButtonConfig {
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export type GeneralDialogConfig = DialogButtonConfig & {
  title: string;
  message: string;
  type: 'general';
  onContinue?: () => void;
  onCancel?: () => void;
};

export type AlertDialogConfig = DialogButtonConfig & {
  title: string;
  message: string;
  type: 'alert';
  onContinue: () => void;
  onCancel?: () => void;
};

export interface SuccessDialogConfig {
  title: string;
  message: string;
  type: 'success';
}

export type DialogModalConfig = GeneralDialogConfig | SuccessDialogConfig | AlertDialogConfig;

interface DialogModalContext {
  openDialogModal: (config: DialogModalConfig) => void;
}

export const DialogModalContext = createContext<DialogModalContext | null>(null);

export const DialogModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [type, setType] = useState<DialogModalConfig['type']>('general');
  const [confirmButtonText, setConfirmButtonText] = useState('');
  const [cancelButtonText, setCancelButtonText] = useState('');
  const [onContinue, setOnContinue] = useState<() => void>(() => () => {});
  const [onCancel, setOnCancel] = useState<() => void>(() => () => {});

  const dialogModalProps = {
    open,
    setOpen,
    title,
    message,
    type,
    confirmButtonText,
    cancelButtonText,
    onContinue,
    onCancel
  };

  const openDialogModal = (config: DialogModalConfig) => {
    setTitle(config.title);
    setMessage(config.message);
    setType(config.type);
    if (config.type !== 'success') {
      setConfirmButtonText(config.confirmButtonText || '');
      setCancelButtonText(config.cancelButtonText || '');
      config.onContinue && setOnContinue(() => config.onContinue);
      config.onCancel && setOnCancel(() => config.onCancel);
    }
    setOpen(true);
  };

  const resetDialogModal = () => {
    setTitle('');
    setMessage('');
    setType('general');
    setOnContinue(() => () => {});
    setOnCancel(() => () => {});
  };

  useEffect(() => {
    if (!open) {
      resetDialogModal();
    }
  }, [open]);

  return (
    <DialogModalContext.Provider value={{ openDialogModal }}>
      <DialogModal {...dialogModalProps} />
      {children}
    </DialogModalContext.Provider>
  );
};
