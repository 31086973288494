import React from 'react';
import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';
import { AppStore } from '../../stores/AppStore';
import { Button } from '../Button';

const messages = defineMessages({
  linkBack: {
    id: 'subscription.links.back',
    defaultMessage: 'Back to Invoice List'
  }
});

type Props = React.PropsWithChildren<{ store: AppStore }>;

export function BackToInvoiceList({ store, children }: Props) {
  const intl = useISIntl();
  return (
    <>
      <p className="mt-10 text-4xl leading-10 font-bold">{children}</p>
      <div className="mt-12 flex justify-center">
        <Button onClick={() => store.nav('invoiceList')}>
          {intl.formatMessage(messages.linkBack)}
        </Button>
      </div>
    </>
  );
}
