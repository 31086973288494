import set from 'lodash/set';
import { getDocFromSettingsMap } from '../util/SettingsDocMap';

export default function getDocFromSettings(docType: number, settingsData: Record<string, any>) {
  const doc: Record<string, any> = {};
  getDocFromSettingsMap(docType).forEach((o) => {
    const value = settingsData[o.path];
    if (typeof value !== 'undefined' && o.invoicePath) {
      set(doc, o.invoicePath, value);
    }
  });
  return doc;
}
