import React from 'react';
import { observer } from 'mobx-react';

import { injectIntl, defineMessages } from 'src/i18n';
import { compose } from 'src/util/functions';

import ShowOnDemand from './ShowOnDemand';
import invoiceInputFieldsUIStore from '../../stores/InvoiceInputFieldsUIStore';
import { Mobile } from '../Responsive';

const messages = defineMessages({
  address: {
    id: 'addField.address',
    defaultMessage: 'address'
  },
  phone: {
    id: 'addField.phone',
    defaultMessage: 'phone'
  },
  businessNumber: {
    id: 'addField.businessNumber',
    defaultMessage: 'Business Number'
  }
});

export const BusinessAddFieldsButtons = compose(
  injectIntl,
  observer
)(() => (
  <Mobile>
    <div className="add-field-buttons business-add-field-buttons">
      <ShowOnDemand
        label="Additional Business Details"
        isHidden={invoiceInputFieldsUIStore.shouldShowAdditionalBusinessDetails}
        onClick={() => {
          invoiceInputFieldsUIStore.showAdditionalBusinessDetails();
        }}
      />
    </div>
  </Mobile>
));

export const ClientAddFieldsButtons = compose(
  injectIntl,
  observer
)(({ intl }) => (
  <Mobile>
    <div className="add-field-buttons client-add-field-buttons">
      <ShowOnDemand
        selector="client-address"
        label={intl.ft(messages.address)}
        isHidden={invoiceInputFieldsUIStore.shouldShowClientAddress}
        onClick={() => invoiceInputFieldsUIStore.showClientAddress()}
      />
      <ShowOnDemand
        selector="client-phone"
        label={intl.ft(messages.phone)}
        isHidden={invoiceInputFieldsUIStore.shouldShowClientPhone}
        onClick={() => invoiceInputFieldsUIStore.showClientPhone()}
      />
    </div>
  </Mobile>
));
