import React from 'react';
import Select, {
  components as RSComponents,
  OptionProps,
  CSSObjectWithLabel,
  StylesConfig
} from 'react-select';
import './Selectv1Adapter.scss';

type ReactSelectProps = React.ComponentProps<typeof Select>;

type Props<T extends string | number> = Omit<ReactSelectProps, 'onChange' | 'value'> & {
  value: T;
  onChange: (v: { value: T; label: string }) => void;
  valueRenderer?: (v: any) => React.JSX.Element;
  optionRenderer?: (v: any) => React.JSX.Element;
};

const customSelectStyles: StylesConfig<
  {
    value: unknown;
    label: string;
  },
  false
> = {
  control: (baseStyles, state): CSSObjectWithLabel => {
    return {
      ...baseStyles,
      border: state.menuIsOpen ? '1px solid #d2d6dc' : '1px solid rgb(159,166,178)',
      boxShadow: 'none',
      outline: 'none',
      cursor: state.isFocused ? 'text' : 'default',
      borderBottomLeftRadius: state.menuIsOpen ? 0 : baseStyles.borderBottomLeftRadius,
      borderBottomRightRadius: state.menuIsOpen ? 0 : baseStyles.borderBottomRightRadius
    };
  },
  valueContainer: (baseStyles): CSSObjectWithLabel => ({
    ...baseStyles,
    padding: '0.5rem 1rem'
  }),
  singleValue: (baseStyles): CSSObjectWithLabel => ({
    ...baseStyles,
    margin: 0
  }),
  input: (baseStyles): CSSObjectWithLabel => ({
    ...baseStyles,
    margin: 0,
    boxShadow: 'none',
    outline: 'none'
  }),
  menu: (baseStyles): CSSObjectWithLabel => ({
    ...baseStyles,
    margin: 0
  }),
  menuList: (baseStyles): CSSObjectWithLabel => ({
    ...baseStyles,
    padding: 0
  }),
  indicatorSeparator: (): CSSObjectWithLabel => ({
    display: 'none'
  }),
  option: (baseStyles, state): CSSObjectWithLabel => ({
    ...baseStyles,
    cursor: 'pointer',
    color: state.isSelected || state.isFocused ? '#333333' : '#666666',
    backgroundColor: state.isFocused
      ? 'rgba(178, 211, 255, 0.3)'
      : state.isSelected
      ? 'rgba(178, 211, 255, 0.2)'
      : 'transparent'
  }),
  dropdownIndicator: (baseStyles): CSSObjectWithLabel => ({
    ...baseStyles,
    cursor: 'pointer',
    color: 'rgb(159, 166, 178)'
  })
};

export function Selectv1Adapter<T extends string | number>(props: Props<T>) {
  const { value, onChange, valueRenderer, optionRenderer, styles, ...rest } = props;
  const newStyles = styles ? { ...customSelectStyles, ...styles } : customSelectStyles;
  let components = {};
  if (optionRenderer) {
    components = {
      ...components,
      Option: function (props: OptionProps<any, false>): React.JSX.Element {
        return <RSComponents.Option {...props}>{optionRenderer(props.data)}</RSComponents.Option>;
      }
    };
  }
  if (valueRenderer) {
    components = {
      ...components,
      SingleValue: function (props: OptionProps<any, false>): React.JSX.Element {
        return (
          <RSComponents.SingleValue {...props}>
            {valueRenderer(props.data)}
          </RSComponents.SingleValue>
        );
      }
    };
  }
  return (
    <Select
      {...rest}
      value={props.options?.filter((o: Record<string, unknown>) => o?.value === value)}
      onChange={(option: Record<string, unknown>) => {
        const value = option && 'value' in option ? option.value : undefined;
        const selectedValue: any = props.options?.filter(
          (v: Record<string, unknown>) => v.value === value
        )[0];

        if (selectedValue) {
          onChange(selectedValue);
        }
      }}
      classNamePrefix="react-select"
      components={components}
      styles={newStyles as StylesConfig}
    />
  );
}
