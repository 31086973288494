import { observable, action } from 'mobx';

import { getCookie, setCookie } from 'src/util/cookie';
import { trackEvent } from 'src/analytics/controller';
import UserModel from 'src/models/UserModel';
import OneSignal from 'src/util/oneSignal';
import environmentStore from './EnvironmentStore';

export const HIDE_NOTIFICATION_INSTALL_COOKIE_NAME = '_is_notification_install_prompt_11da1dfa';
const COOKIE_MAX_AGE = 60 * 60 * 24 * 14; // 14d

export const enum BROWSER_SUBSCRIPTION_STATUS {
  DENIED = 'denied',
  GRANTED = 'granted',
  DEFAULT = 'default'
}
const enum ANSWER_COOKIE_STATUS {
  ALLOWED = 'allowed',
  DISMISSED = 'dismissed'
}

const isAnsweredCookie = () => getCookie(HIDE_NOTIFICATION_INSTALL_COOKIE_NAME);
const setAnsweredCookie = (value) => () =>
  setCookie(HIDE_NOTIFICATION_INSTALL_COOKIE_NAME, value, {
    maxAge: COOKIE_MAX_AGE
  });
const setAnsweredCookiedAllowed = setAnsweredCookie(ANSWER_COOKIE_STATUS.ALLOWED);
const setAnsweredCookiedDismissed = setAnsweredCookie(ANSWER_COOKIE_STATUS.DISMISSED);

export const isNotificationCookieResponseAllowed = (): boolean =>
  isAnsweredCookie() === ANSWER_COOKIE_STATUS.ALLOWED;

export const notificationsInstallModel = observable<{
  isShown: boolean;
  isAllowed: boolean | null;
  isAnswered: boolean;
}>({
  isShown: false,
  isAllowed: null,
  isAnswered: !!isAnsweredCookie()
});

function oneSignalPushIfPossible(fn: (() => void) | (() => Promise<void>)) {
  if (environmentStore.isReviewApp) {
    return;
  }

  return OneSignal.push(fn);
}

export const showNotificationInstallPrompt = action(() =>
  oneSignalPushIfPossible(async () => {
    const browserNotificationStatus = await OneSignal.getNotificationPermission();
    const browserSupportsNotifications = OneSignal.isPushNotificationsSupported();
    if (
      browserSupportsNotifications &&
      !notificationsInstallModel.isAnswered &&
      browserNotificationStatus === BROWSER_SUBSCRIPTION_STATUS.DEFAULT
    ) {
      notificationsInstallModel.isShown = true;
    }
  })
);

const onPermissionGranted = () => {
  trackEvent('notification-permission-response', {
    accepted: true
  });
  setAnsweredCookiedAllowed();
  oneSignalPushIfPossible(() =>
    OneSignal.sendTags({
      userId: UserModel.getInstance().accountId
    })
  );
  notificationsInstallModel.isAllowed = true;
  notificationsInstallModel.isAnswered = true;
};

const onPermissionDenied = () => {
  notificationsInstallModel.isAllowed = false;
  notificationsInstallModel.isAnswered = true;
  trackEvent('notification-permission-response', {
    accepted: false
  });
};

export const dismissNotificationInstallPrompt = action(() => {
  notificationsInstallModel.isShown = false;
  notificationsInstallModel.isAnswered = true;
  setAnsweredCookiedDismissed();
  trackEvent('notification-prompt-response', {
    accepted: false
  });
});

export const closeNotificationInstallPrompt = action(() => {
  notificationsInstallModel.isShown = false;
});

export const acceptNotificationInstallPrompt = action(() => {
  trackEvent('notification-prompt-response', {
    accepted: true
  });

  return oneSignalPushIfPossible(() => {
    OneSignal.on('notificationPermissionChange', ({ to: permissionResponse }) => {
      switch (permissionResponse) {
        case BROWSER_SUBSCRIPTION_STATUS.GRANTED:
          onPermissionGranted();
          break;
        case BROWSER_SUBSCRIPTION_STATUS.DENIED:
          onPermissionDenied();
          break;
        default:
          break;
      }
    });
    OneSignal.registerForPushNotifications();
  });
});
