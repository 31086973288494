export { PaymentEventName } from './PaymentEventNames';
export type { PaymentEvent } from './PaymentEventNames';
export { PaymentEventLocation } from './PaymentEventLocations';
export type { PaymentEventLocationType } from './PaymentEventLocations';
export { PaymentModalTriggerName } from './PaymentModalTriggers';
export type { PaymentModalTrigger } from './PaymentModalTriggers';
export * from './events';

export enum OnboardOrigin {
  'webInvoiceList' = 'web-invoice-list',
  'webSettings' = 'web-settings'
}

export interface OnboardModalMessages {
  id: string;
  defaultMessage: string;
}

export interface OnboardModalProps {
  closeModal: () => void;
  origin: OnboardOrigin;
}

export enum PaymentsProviderName {
  STRIPE = 'stripe',
  PAYPAL = 'paypal'
}
