export default class ErrorModel {
  type: string;
  code: number | string;
  message: string;

  constructor(obj: {}) {
    this.type = this._parse(obj, 'type');
    this.code = this._parse(obj, 'code');
    this.message = this._parse(obj, 'message');
  }

  private _parse(obj: {}, name: string) {
    return obj[name] === undefined ? this._default(name) : obj[name];
  }

  private _default(name: string) {
    return {
      type: '',
      code: '',
      message: ''
    }[name];
  }
}
