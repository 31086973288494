import React from 'react';
import { Helmet } from 'react-helmet';
import compact from 'lodash/compact';
import injectStore from 'src/util/injectStore';
import environmentStore from 'src/stores/EnvironmentStore';
import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';

const messages = defineMessages({
  titleNew: {
    id: 'doc.head.title.new',
    defaultMessage: 'New'
  },
  titleEdit: {
    id: 'doc.head.title.edit',
    defaultMessage: 'Edit'
  },
  titleView: {
    id: 'doc.head.title.view',
    defaultMessage: 'Preview'
  },
  description: {
    id: 'doc.head.description',
    defaultMessage: 'Edit and Preview your {docType}'
  }
});

const getDocMessages = (path: string) => {
  if (/estimate/i.test(path)) {
    return defineMessages({
      title: {
        id: 'doc.head.title.estimate',
        defaultMessage: 'Estimate Maker | Free to Use & Download | Invoice Simple'
      },
      meta: {
        id: 'doc.head.description.estimate',
        defaultMessage:
          'Use this free estimate maker to quickly create estimates online with our professional estimate template. Customize, and download — no account needed.'
      }
    });
  }
  if (/receipt/i.test(path)) {
    return defineMessages({
      title: {
        id: 'doc.head.title.receipt',
        defaultMessage: 'Receipt Maker | Free to Use & Download | Invoice Simple'
      },
      meta: {
        id: 'doc.head.description.receipt',
        defaultMessage:
          'Use this free receipt maker to quickly create receipts online with our professional receipt templates. Customize and download - no account needed.'
      }
    });
  }
  return defineMessages({
    title: {
      id: 'doc.head.title.invoice',
      defaultMessage: 'Free Invoice Generator | Invoice Simple'
    },
    meta: {
      id: 'doc.head.description.invoice',
      defaultMessage:
        'Send professional invoices in minutes. Enter your information, download as a Word or PDF doc and send. 1, 2, 3.'
    }
  });
};

export const InvoiceHelmet = (props) => {
  const intl = useISIntl();
  const { store } = props;
  const location = store.location;
  const invoice = store.doc;
  const docName = store.location.docTypeLabel;
  const docMessages = getDocMessages(location.pathname);
  const f = intl.formatMessage;

  // snapshot
  if (environmentStore.isSnapshot() || environmentStore.isProxy) {
    return (
      // note titleTemplate  = %s to override GlobalHelmet
      <Helmet titleTemplate={'%s'}>
        <title itemProp="name">{f(docMessages.title)}</title>
        <meta property="og:title" content={f(docMessages.title)} />
        <meta name="description" content={f(docMessages.meta)} />
        <meta property="og:description" content={f(docMessages.meta)} />
      </Helmet>
    );
  }

  // app
  return (
    <Helmet>
      <title itemProp="name">
        {compact([
          invoice.isNew
            ? f(messages.titleNew)
            : location.isEdit
            ? f(messages.titleEdit)
            : location.isView
            ? f(messages.titleView)
            : '',
          docName,
          invoice.invoiceNo,
          invoice && invoice.company && invoice.company.name && `- ${invoice.company.name}`
        ]).join(' ')}
      </title>
      <meta name="description" content={f(messages.description)} />
    </Helmet>
  );
};

export default injectStore(InvoiceHelmet);
