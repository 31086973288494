import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import UserModel from 'src/models/UserModel';
import { buildUrl } from './helpers';

export interface Item {
  objectId: string;
  remoteId: string;
  code: string;
  description: string;
  rate: number;
  deleted: boolean;
  taxable: boolean;
  unit: string;
  updated: number;
  _created_at: string;
}

export interface PaginatedItemsResponse {
  items: Item[];
  hasNextPage: boolean;
  prev: string;
  next: string;
}

interface QueryError {
  message: string;
}

export interface PaginatedItemsParams {
  dir?: 'asc' | 'desc';
  sortBy?: 'code' | 'updated';
  limit?: number;
  url?: string;
}

export const fetchItems = async ({
  dir,
  sortBy,
  limit,
  url
}: PaginatedItemsParams): Promise<PaginatedItemsResponse> => {
  const { headers } = UserModel.getInstance().getApiReqOpts();
  const { data } = await axios.get<PaginatedItemsResponse>(buildUrl({ dir, sortBy, limit, url }), {
    headers
  });

  return data;
};

export const usePaginatedItems = ({ dir, sortBy, limit, url }: PaginatedItemsParams) => {
  return useQuery<PaginatedItemsResponse, QueryError>(['items', { dir, sortBy, limit, url }], () =>
    fetchItems({ dir, sortBy, limit, url })
  );
};
