export enum CaptureStatus {
  COMPLETED = 'COMPLETED',
  REFUNDED = 'REFUNDED',
  PENDING = 'PENDING',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED'
}

export interface PaypalOrderFormatted {
  amountCents: number;
  invoiceId: string;
  invoiceNo?: string;
  invoiceRemoteId?: string;
  orderId: string;
  currencyCode: string;
  clientName?: string;
  createdAt: string;
  captureStatus: CaptureStatus;
}

export interface AccountOrdersPaginated {
  orders: PaypalOrderFormatted[];
  pagination: {
    currentPage: number;
    totalPages: number;
    count: number;
  };
}
