import { InvoiceTermTypes } from '@invoice-simple/common';
import { defineMessages } from 'src/i18n';
import UserModel from 'src/models/UserModel';

// we are not using relative day auto-format due to lack of support for ie11 and safari
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RelativeTimeFormat
const messages = defineMessages({
  none: {
    id: 'i18n.tax.term.none',
    defaultMessage: 'none'
  },
  custom: {
    id: 'i18n.tax.term.custom',
    defaultMessage: 'custom'
  },
  dueOnReceipt: {
    id: 'i18n.tax.term.due',
    defaultMessage: 'on receipt'
  },
  nextDay: {
    id: 'i18n.tax.term.next',
    defaultMessage: 'next day'
  },
  days2: {
    id: 'i18n.tax.term.days.2',
    defaultMessage: '2 days'
  },
  days3: {
    id: 'i18n.tax.term.days.3',
    defaultMessage: '3 days'
  },
  days4: {
    id: 'i18n.tax.term.days.4',
    defaultMessage: '4 days'
  },
  days5: {
    id: 'i18n.tax.term.days.5',
    defaultMessage: '5 days'
  },
  days6: {
    id: 'i18n.tax.term.days.6',
    defaultMessage: '6 days'
  },
  days7: {
    id: 'i18n.tax.term.days.7',
    defaultMessage: '7 days'
  },
  days10: {
    id: 'i18n.tax.term.days.10',
    defaultMessage: '10 days'
  },
  days14: {
    id: 'i18n.tax.term.days.14',
    defaultMessage: '14 days'
  },
  days21: {
    id: 'i18n.tax.term.days.21',
    defaultMessage: '21 days'
  },
  days30: {
    id: 'i18n.tax.term.days.30',
    defaultMessage: '30 days'
  },
  days45: {
    id: 'i18n.tax.term.days.45',
    defaultMessage: '45 days'
  },
  days60: {
    id: 'i18n.tax.term.days.60',
    defaultMessage: '60 days'
  },
  days90: {
    id: 'i18n.tax.term.days.90',
    defaultMessage: '90 days'
  },
  days120: {
    id: 'i18n.tax.term.days.120',
    defaultMessage: '120 days'
  },
  days180: {
    id: 'i18n.tax.term.days.180',
    defaultMessage: '180 days'
  },
  days365: {
    id: 'i18n.tax.term.days.365',
    defaultMessage: '365 days'
  }
});

const customTerm = { value: InvoiceTermTypes.CUSTOM, label: messages.custom };

const intlTerms = [
  { value: InvoiceTermTypes.NONE, label: messages.none },
  customTerm,
  { value: InvoiceTermTypes.DUE_ON_RECEIPT, label: messages.dueOnReceipt },
  { value: InvoiceTermTypes.NEXT_DAY, label: messages.nextDay },
  { value: InvoiceTermTypes.DAYS_2, label: messages.days2 },
  { value: InvoiceTermTypes.DAYS_3, label: messages.days3 },
  { value: InvoiceTermTypes.DAYS_4, label: messages.days4 },
  { value: InvoiceTermTypes.DAYS_5, label: messages.days5 },
  { value: InvoiceTermTypes.DAYS_6, label: messages.days6 },
  { value: InvoiceTermTypes.DAYS_7, label: messages.days7 },
  { value: InvoiceTermTypes.DAYS_10, label: messages.days10 },
  { value: InvoiceTermTypes.DAYS_14, label: messages.days14 },
  { value: InvoiceTermTypes.DAYS_21, label: messages.days21 },
  { value: InvoiceTermTypes.DAYS_30, label: messages.days30 },
  { value: InvoiceTermTypes.DAYS_45, label: messages.days45 },
  { value: InvoiceTermTypes.DAYS_60, label: messages.days60 },
  { value: InvoiceTermTypes.DAYS_90, label: messages.days90 },
  { value: InvoiceTermTypes.DAYS_120, label: messages.days120 },
  { value: InvoiceTermTypes.DAYS_180, label: messages.days180 },
  { value: InvoiceTermTypes.DAYS_365, label: messages.days365 }
];

export const getIntlTermOptions = (formatMessage) =>
  intlTerms.map(({ value, label }) => ({ value, label: formatMessage(label) }));

export const getIntlTerm = (index?: number) => {
  const result = intlTerms.find((i) => i.value === index);
  if (!result) {
    UserModel.getInstance().trackError(
      'Unknown "setting.termsDay" value',
      Object.assign(new Error('Unknown "setting.termsDay" value'), { termsDay: index })
    );
    return customTerm;
  }
  return result;
};

export const hasTerm = (days: number) => intlTerms.some((i) => i.value === days);
