import React, { FC } from 'react';
import { BlueCTAButton } from '../Common';
import { PaypalBaseBox } from './PaypalBaseBox';
import { useISIntl } from 'src/i18n/utils';
import { paypalBoxMessages } from './messages';
import { OrangeWarningIcon } from 'src/components/Icons';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { usePaypalContext } from 'src/payments/contexts';
import { getPaymentEventSource } from 'src/payments/utils';

export const PaypalPendingOnboardedBoxComponent: FC<{
  onCTAClick: () => void;
  store: AppStore;
}> = ({ onCTAClick, store }) => {
  const paypalAccountContext = usePaypalContext();
  if (!paypalAccountContext?.isPaypalEligible()) {
    return null;
  }

  const { isPaypalPending, isPaypalOnboarded, isPrimaryEmailConfirmed } = paypalAccountContext;

  const component = isPaypalPending()
    ? PaymentEventComponent.tilePending
    : isPaypalOnboarded()
    ? isPrimaryEmailConfirmed()
      ? PaymentEventComponent.tileOnboardedEmailConfirmed
      : PaymentEventComponent.tileOnboarded
    : PaymentEventComponent.tileOnboarded;

  return (
    <PaypalPendingOnboardedBoxBaseComponent
      onCTAClick={onCTAClick}
      component={component}
      store={store}
    />
  );
};

const PaypalPendingOnboardedBoxBaseComponent: FC<{
  onCTAClick: () => void;
  store: AppStore;
  component: PaymentEventComponent;
}> = ({ onCTAClick, store, component }) => {
  const { ft } = useISIntl();

  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    component
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);
  return (
    <>
      <div data-testid="paypalPendingOnboardedBox" />
      <PaypalBaseBox
        actionElement={
          <BlueCTAButton onClick={onCTAClick}>{ft(paypalBoxMessages.finishSetupCTA)}</BlueCTAButton>
        }
        icon={<OrangeWarningIcon />}
      />
    </>
  );
};

export const PaypalPendingOnboardedBox = injectStore(PaypalPendingOnboardedBoxComponent);
