import { Platform } from '@invoice-simple/is-events';

import Rollbar from 'src/analytics/rollbar';
import UserModel from 'src/models/UserModel';
import { API_V3_BASE_PATH, client } from './httpClient';

export const trackFriendbuySignup = async (user: UserModel) => {
  try {
    const referralCode = user.advocateReferralCode;

    if (referralCode) {
      user.events.trackAction('referral-action', {
        action: 'signup',
        platform: Platform.web,
        'referral-code': referralCode,
        'referral-email': user.email
      });
    }

    const { baseURL, headers } = user.getApiV3ReqOpts();

    const path = baseURL + API_V3_BASE_PATH + '/friendbuy/signup';

    await client.post(path, { referralCode }, { headers });
  } catch (err) {
    Rollbar.trackError('Error tracking friendbuy signup', err);
  }
};
