import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';
import { observer } from 'mobx-react';
import { Loading } from 'src/components/Loading';
import FeedbackScreen from './screens/FeedbackScreen';
import ValuePropsScreen from './screens/ValuePropsScreen';
import CompletedScreen from './screens/CompletedScreen';
import { CancelSubscriptionProvider, useCancelSubscription } from './CancelSubscriptionContext';
import UserModel from 'src/models/UserModel';
import { SubscriptionCancelFlowVersion, TypeFormSurveyID } from './common';
import { Platform } from '@invoice-simple/common';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';

const messages = defineMessages({
  title: {
    id: 'subscription.cancel.title',
    defaultMessage: 'Cancel Subscription'
  }
});

interface Props {
  user: UserModel;
  version: SubscriptionCancelFlowVersion;
  onError: (error: { title?: string; body?: string }) => void;
  onLoad: () => void;
}

const _CancelSubscription = ({ user, version, onLoad, onError }: Props) => {
  const intl = useISIntl();
  useEffect(() => {
    onLoad();
  }, []);
  const { isCompletedScreen, isFeedbackScreen, isValuePropsScreen } = useCancelSubscription();

  if (!user.isLoaded) return <Loading />;

  const f = intl.formatMessage;
  const formId =
    version === SubscriptionCancelFlowVersion.V1
      ? TypeFormSurveyID.CANCEL_SURVEY_V1
      : TypeFormSurveyID.CANCEL_SURVEY_V2;
  const platform = (getURLQueryParam(URLQueryParamKeys.PLATFORM) as Platform) || Platform.WEB;
  const screenProps = { user, version, platform, intl, onError };

  return (
    <div id="tailwind">
      <Helmet>
        <title itemProp="name">{f(messages.title)}</title>
        <body className="app-theme cancel-subscription-theme" />
      </Helmet>

      <div className="max-w-4xl mx-auto pb-4 mt-64">
        {isFeedbackScreen() && <FeedbackScreen formId={formId} {...screenProps} />}
        {isValuePropsScreen() && <ValuePropsScreen {...screenProps} />}
        {isCompletedScreen() && <CompletedScreen {...screenProps} />}
      </div>
    </div>
  );
};

const CancelSubscription = observer(_CancelSubscription);

export default (props: Props) => {
  return (
    <CancelSubscriptionProvider>
      <CancelSubscription {...props} />
    </CancelSubscriptionProvider>
  );
};
