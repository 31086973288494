import React from 'react';
import { SettingsBoxRowWrapper } from './SettingsBoxRowWrapper';

export const PaypalSettingsBoxRowWrapper = ({
  children
}: {
  children: JSX.Element;
}): JSX.Element => {
  return <SettingsBoxRowWrapper label="Paypal">{children}</SettingsBoxRowWrapper>;
};
