import './Alert.scss';
import React from 'react';
import { observer } from 'mobx-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import alertModel, { AlertType, VisibleAlertType } from 'src/models/AlertModel';
import { compose } from 'src/util/functions';
import { injectIntl, defineMessages } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';

interface AlertProps {
  type: AlertType;
  title?: string;
  body: React.ReactNode;
  isClosable: boolean;
  handleClose: () => void;
  shouldShowNavbar?: boolean;
}
const AlertComp: React.FC<AlertProps> = ({
  type,
  body,
  title,
  isClosable = true,
  handleClose,
  shouldShowNavbar
}) => (
  <div
    className={`is-alert alert-${type} d-print-none ${shouldShowNavbar ? 'navbar' : 'no-navbar'}`}
    role="alert">
    <div className="container">
      <div>
        {title && <strong>{title}</strong>}
        <div>{body}</div>
      </div>
      {isClosable && (
        <a onClick={() => handleClose()} className="btn btn-close">
          <FontAwesomeIcon icon="times" />
        </a>
      )}
    </div>
  </div>
);

const AlertCompV2: React.FC<AlertProps> = ({
  type,
  body,
  title,
  isClosable = true,
  handleClose
}: AlertProps) => {
  const backgroundColourMap: Record<VisibleAlertType, string> = {
    danger: `bg-red-100`,
    success: `bg-green-100`,
    warning: `bg-amber-50`
  };

  const textColourMap: Record<VisibleAlertType, string> = {
    danger: `text-red-800`,
    success: `text-green-800`,
    warning: `text-slate-800`
  };

  const backgroundColour = backgroundColourMap[type];
  const textColour = textColourMap[type];

  const borderRadius = 'rounded-[10px]';
  const padding = 'py-[12px] px-[20px]';

  if (type === 'hidden') {
    return null;
  }

  return (
    <div id="tailwind">
      <div className={`${backgroundColour} ${borderRadius} w-full`} role="alert">
        <div className={`max-w-6xl mx-auto ${padding} flex w-full`}>
          <div className="w-full">
            {title && <h3 className={`font-bold text-[14px] mb-1 ${textColour}`}>{title}</h3>}
            <p className={`text-[14px] leading-[18px] ${textColour}`}>{body}</p>
          </div>
          {isClosable && (
            <button
              onClick={handleClose}
              className={`${textColour} px-2 bg-transparent border-none`}
              name="btn-close">
              <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const messages = defineMessages({
  alertInvoiceCreateFailedTitle: {
    id: 'app.alert.invoice.create.failed.title',
    defaultMessage: 'Unable to create new {docType}'
  },
  alertConnectivityErrorTitle: {
    id: 'app.alert.connectivityErrors.title',
    defaultMessage: 'Connectivity problem while contacting our servers.'
  },
  alertConnectivityErrorBody: {
    id: 'app.alert.connectivityErrors.body',
    defaultMessage: `Please try reloading the app. If the problem persists, contact our support team support@invoicesimple.com\n{details}`
  },
  alertLoginFailedTitle: {
    id: 'login.alert.login.failed.title',
    defaultMessage: 'Unable to login'
  },
  alertAccountMissingBody: {
    id: 'login.alert.accountMissing.body',
    defaultMessage: 'Sorry, there is a problem with your account, please contact support.'
  },
  alertUnknownEmailBody: {
    id: 'login.alert.unknownEmail.body',
    defaultMessage: '{email} is not registered'
  },
  alertWrongPasswordBody: {
    id: 'login.alert.wrongPassword.body',
    defaultMessage: 'Wrong password'
  },
  alertWrongEmailBody: {
    id: 'login.alert.wrongEmail.body',
    defaultMessage: '{email} is invalid email'
  },
  alertHasActiveSubscriptionTitle: {
    id: 'login.alert.hasActiveSubscription.title',
    defaultMessage: 'This coupon is only available for new subscriptions.'
  },
  alertHasActiveSubscriptionBody: {
    id: 'login.alert.hasActiveSubscription.body',
    defaultMessage: 'Please create an account or contact support.'
  },
  alertConnectionFailedBody: {
    id: 'login.alert.connectionFailed.body',
    defaultMessage: 'We are already on it. Please reload the page or try again later.'
  },
  alertSignupSuccessTitle: {
    id: 'signup.alert.success.title',
    defaultMessage: 'Thanks for signing up!'
  },
  alertSignupSmsSuccessTitle: {
    id: 'signup.alert.success.sms.title',
    defaultMessage: 'Successfully signed up, continue on the mobile app!'
  },
  alertSignupFailedEmailExists: {
    id: 'signup.alert.failed.emailExists.body',
    defaultMessage: 'There is already an account with that email.'
  },
  alertSignupSuccessBody: {
    id: 'signup.alert.success.body',
    defaultMessage: 'If you need any help please contact us: support@invoicesimple.com'
  },
  alertSignupFailedTitle: {
    id: 'signup.alert.failed.title',
    defaultMessage: 'Unable to create account'
  },
  alertEmptyFields: {
    id: 'signup.alert.failed.body.emptyFields',
    defaultMessage: 'Please fill in all the fields'
  },
  alertShortPassword: {
    id: 'signup.alert.failed.body.shortPassword',
    defaultMessage: 'Password must have at least {passwordLimit} characters'
  },
  pleaseSignup: {
    id: 'signup.pleasesignup',
    defaultMessage: 'Please signup to continue.'
  },
  accountRequiredCreate: {
    id: 'signup.account.required.create',
    defaultMessage: 'Account required to create new invoice'
  },
  accountRequiredSend: {
    id: 'signup.account.required.send',
    defaultMessage: 'Account required to send invoice'
  },
  accountRequiredOptions: {
    id: 'signup.account.required.options',
    defaultMessage: 'Account required to continue invoicing'
  },
  accountRequired: {
    id: 'signup.account.required',
    defaultMessage: 'Account required to continue using Free Trial'
  },
  invalidPromotionRegion: {
    id: 'subscription.alert.coupon.invalidRegion',
    defaultMessage: 'This coupon is not available in your region.'
  },
  expiredPromotion: {
    id: 'subscription.alert.coupon.expired',
    defaultMessage: 'This coupon has expired.'
  },
  notEligible: {
    id: 'subscription.alert.coupon.notEligible',
    defaultMessage: "You're not eligible for this coupon."
  },
  earnReferralBonus: {
    id: 'referral.signup.earnReferralBonus',
    defaultMessage: 'Sign up to receive your referral bonus.'
  },
  invalidReferralLink: {
    id: 'referral.signup.invalidLink',
    defaultMessage: 'Invalid referral link'
  }
});

interface Prop {
  onCancel?: () => void;
  intl: ISIntl;
  shouldShowNavbar?: boolean;
  version?: 'v1' | 'v2';
}

export const Alert: React.FC<Prop> = (props) => {
  const handleClose = () => {
    alertModel.resetAlert();
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const data = alertModel.alert || {};

  const title = data.titleMessageId
    ? props.intl.formatMessage(messages[data.titleMessageId], data.params)
    : data.title;

  const body = data.bodyMessageId
    ? props.intl.formatMessage(messages[data.bodyMessageId], data.params)
    : data.body;

  const alertProps = {
    type: data.type || 'hidden',
    body,
    title,
    handleClose,
    isClosable: !!data.isClosable,
    shouldShowNavbar: props.shouldShowNavbar
  };

  return props.version === 'v2' ? <AlertCompV2 {...alertProps} /> : <AlertComp {...alertProps} />;
};

export default compose(injectIntl, observer)(Alert);
