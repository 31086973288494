import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { AppStore } from '../stores/AppStore';
import ParseAdminLink from './ParseAdminLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import errorListModel from '../models/ErrorListModel';
import { EnvironmentStore } from '../stores/EnvironmentStore';
import browserNotificationStore from 'src/stores/BrowserNotificationStore';
import localeStore from 'src/stores/LocaleStore';
import { compose } from 'src/util/functions';
import './DevFooter.css';
import { defaultsStore } from 'src/stores/DefaultsStore';
import { Feature, useFeatureFlags } from '../feature-flags';

interface Props {
  store: AppStore;
  environment: EnvironmentStore;
}

const DevFooter = (props: Props) => {
  const [display, setDisplay] = useState(false);
  const featureFlags = useFeatureFlags();
  let featureFlagRows;

  if (!props.environment.appDev || props.environment.isSnapshot()) {
    return null;
  }
  const store = props.store;
  const user = store.user;
  const abTestRows = defaultsStore.abTests
    ? Object.keys(defaultsStore.abTests).map((key) => {
        return (
          <tr key={key}>
            <td>{key}</td>
            <td>{defaultsStore.abTests[key]}</td>
          </tr>
        );
      })
    : null;

  if (featureFlags) {
    const { isFeatureEnabled, setFeature } = featureFlags;
    featureFlagRows = Object.keys(Feature).map((feature: Feature) => {
      const value = isFeatureEnabled(feature);
      return (
        <tr key={feature}>
          <td>{`${feature} feature flag`}</td>
          <td>
            <input
              type="checkbox"
              id={feature}
              checked={value}
              onChange={() => {
                setFeature(feature, !value);
              }}
            />
          </td>
        </tr>
      );
    });
  }

  const recentErrors = errorListModel.errors.map((error, index) => (
    <tr key={index}>
      <td>{error.type}</td>
      <td>{error.code}</td>
      <td>{error.message}</td>
    </tr>
  ));
  return (
    <div className="dev-footer d-print-none">
      <div className="dev-footer-head" onClick={() => setDisplay(!display)}>
        <div className="row">
          <div className="col-lg-12">
            <span>
              {display && <FontAwesomeIcon icon="chevron-down" />}
              {!display && <FontAwesomeIcon icon="chevron-up" />}
            </span>
            <span className="app-version">{props.environment.getReactAppVersion()}</span>
            <span> | </span>
            <span className="code-version">{props.environment.getCodeVersion()}</span>
          </div>
        </div>
      </div>

      {display && (
        <div className="dev-footer-body">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <ParseAdminLink className="User" objectId={user.id}>
                        User
                      </ParseAdminLink>
                    </td>
                    <td>{user.id}</td>
                    <td>
                      {user.isLoaded ? (
                        <FontAwesomeIcon icon="check" />
                      ) : (
                        <FontAwesomeIcon icon="spinner" spin />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ParseAdminLink className="Account" objectId={user.accountId}>
                        Account
                      </ParseAdminLink>
                    </td>
                    <td>{user.accountId}</td>
                  </tr>
                  <tr>
                    <td>
                      <ParseAdminLink
                        className="Installation"
                        installationId={props.environment.installationId}>
                        Installation
                      </ParseAdminLink>
                    </td>
                    <td>{props.environment.installationId}</td>
                  </tr>
                  <tr>
                    <td>
                      <ParseAdminLink className="Session" sessionToken={user.sessionToken}>
                        Session
                      </ParseAdminLink>
                    </td>
                    <td>{user.sessionToken} </td>
                  </tr>
                  <tr>
                    <td>Mobile Sync</td>
                    <td>
                      {user.mobileSyncCount + ' '}
                      {user.isMobileSyncing && <FontAwesomeIcon icon="spinner" spin />}
                    </td>
                  </tr>
                  <tr>
                    <td>Notification</td>
                    <td>{browserNotificationStore.state.toUpperCase()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <ParseAdminLink className="Setting" accountId={user.accountId}>
                        Settings
                      </ParseAdminLink>
                    </td>
                    <td>{user.settingList.savedSettings.length}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <table>
                <tbody>{abTestRows}</tbody>
              </table>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <table className="errors">
                <tbody>
                  <tr>
                    <td>
                      <b>Locale:</b>
                    </td>
                    <td>
                      <select
                        onChange={(e) => localeStore.loadLocale(e.target.value)}
                        value={localeStore.currentLocale}>
                        <option value="en-US">en-US</option>
                        <option value="fr-FR">fr-FR</option>
                        <option value="de-DE">de-DE</option>
                        <option value="es-ES">es-ES</option>
                        <option value="it-IT">it-IT</option>
                        <option value="nl-NL">nl-NL</option>
                      </select>
                    </td>
                  </tr>
                  {recentErrors}
                </tbody>
              </table>
            </div>
            {featureFlags && (
              <div className="col-lg-2 col-md-6 col-sm-6">
                <table>
                  <tbody>{featureFlagRows}</tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(inject('store', 'environment'), observer)(DevFooter);
