import React, { FormEvent, useEffect, useRef } from 'react';
import { trackEvent } from 'src/analytics/controller';
import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';

interface Props {
  handleAcknowledgeTerms: (e: FormEvent) => void;
  isOpen: boolean;
}

const messages = defineMessages({
  modalMessage: {
    id: 'terms.acknowledgement.modal.title',
    defaultMessage:
      'Welcome to Invoice Simple. Join the 900K+ happy small businesses. By continuing you agree to our {termsOfService} and acknowledge that you have read our {privacyPolicy}'
  },
  modalButtonAction: {
    id: 'terms.acknowledgement.modal.button',
    defaultMessage: 'Agree and continue'
  },
  termsOfService: {
    id: 'terms.acknowledgement.terms',
    defaultMessage: 'Terms of Use'
  },
  privacyPolicy: {
    id: 'terms.acknowledgement.policy',
    defaultMessage: 'Privacy Policy'
  }
});

export const Modal = ({ handleAcknowledgeTerms, isOpen }: Props) => {
  const { formatMessage: f } = useISIntl();

  const ref = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    trackEvent('acknowledgement-modal-shown');
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', handleKeyPress);
      return () => window.removeEventListener('keydown', handleKeyPress);
    }
    return;
  });

  const handleKeyPress = async (e) => {
    // trigger by pressing the enter OR escape key
    if ([13, 27].includes(e.keyCode)) {
      handleAcknowledgeTerms(e);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // add when mounted
      document.addEventListener('mousedown', handleOutsideClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
    return;
  });

  const handleOutsideClick = (e) => {
    if (ref?.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    handleAcknowledgeTerms(e);
  };

  const modalText = f(messages.modalMessage, {
    termsOfService: `<strong>
        <a
          href="https://www.invoicesimple.com/terms/"
          target="_blank"
          rel="noreferrer noopener"
          className="hover:text-gray-700 focus:text-gray-600">
          ${f(messages.termsOfService)}
        </a>
      </strong>`,
    privacyPolicy: `<strong>
        <a
          href="https://www.invoicesimple.com/privacy/"
          target="_blank"
          rel="noreferrer noopener"
          className="hover:text-gray-700 focus:text-gray-600">
          ${f(messages.privacyPolicy)}
        </a>
      </strong>`
  });

  if (!isOpen) {
    return null;
  }

  return (
    <>
      {/* Background Modal */}
      <div
        style={{
          background: '#111827',
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          inset: 0,
          opacity: 0.75,
          zIndex: 99
        }}
        className="fixed inset-0 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      />

      {/* Modal Contents */}
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99999
        }}>
        <div
          style={{
            width: 'fit-content(512px)',
            minWidth: '300px',
            maxWidth: '512px',
            borderRadius: '15px'
          }}>
          <form
            onSubmit={handleAcknowledgeTerms}
            onKeyPress={handleKeyPress}
            ref={ref}
            id="tailwind"
            style={{
              borderRadius: '5px'
            }}
            className="bg-white overflow-hidden shadow-xl transform transition-all sm:align-middle">
            <div className="sm:flex sm:items-start">
              <div style={{ padding: '24px' }}>
                <p
                  className="text-gray-500"
                  style={{
                    fontSize: '14px',
                    lineHeight: '17.5px'
                  }}
                  dangerouslySetInnerHTML={{ __html: modalText }}
                />
              </div>
            </div>
            <div
              className="align-right w-full flex justify-end pt-0 px-7 pb-7"
              style={{
                fontSize: '14px'
              }}>
              <button
                type="submit"
                style={{
                  borderRadius: '5.25px'
                }}
                className="w-full sm:w-auto font-normal text-white bg-orange-is border border-transparent px-9 py-3">
                {f(messages.modalButtonAction)}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
