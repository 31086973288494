import React from 'react';

import { observer } from 'mobx-react';

import { getInvoiceBalance, getInvoiceTotal, isDepositEligible } from '@invoice-simple/calculator';
import { Platform } from '@invoice-simple/common';

import { LoveSurvey } from 'src/components/LoveSurvey';
import { defineMessages } from 'src/i18n';
import { ISIntl, useISIntl } from 'src/i18n/utils';
import alertModel from 'src/models/AlertModel';
import User from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import InvoiceEmail from './InvoiceEmail';

const messages = defineMessages({
  alertSuccessTitle: {
    id: 'invoice.email.alert.success.title',
    defaultMessage: '{docType} {invoiceNo} was sent to {userEmail}'
  },
  alertFailedTitle: {
    id: 'invoice.email.alert.failed.title',
    defaultMessage: 'Unable to send invoice.'
  },
  alertRatingQuestion: {
    id: 'invoice.email.alert.rating.question',
    defaultMessage: 'How would you rate this app?'
  }
});

export const getAlertTitle = (
  invoiceNo: string,
  userEmail: string,
  isSubPaid: boolean,
  formatMessage: ISIntl['formatMessage']
) => {
  const result = formatMessage(messages.alertSuccessTitle, {
    invoiceNo,
    userEmail: [userEmail].join(', ')
  });
  if (User.getInstance().meta.hasProvidedRating || !isSubPaid) {
    return result;
  }
  return `${result}.  ${formatMessage(messages.alertRatingQuestion)}`;
};

export const handleSuccess = (store: AppStore, formatMessage: ISIntl['formatMessage']) => () => {
  User.getInstance().trackAppEventViaApi('invoice-email-sent');

  const universalInvoice = store.doc.asUniversal;
  if (isDepositEligible(universalInvoice)) {
    User.getInstance().trackAppEventViaApi('payments-webDepositEmail-sent', {
      invoiceId: store.doc.id,
      type: universalInvoice.setting.depositType,
      depositValue: +getInvoiceBalance(universalInvoice),
      depositAmount: universalInvoice.setting.depositAmount,
      depositRate: universalInvoice.setting.depositRate
    });
  }

  const title = getAlertTitle(
    store.doc.invoiceNo,
    store.doc.draftEmail.toEmail,
    store.user.isSubPaid,
    formatMessage
  );

  store.docListNav({ action: 'invoice-email-send' });
  alertModel.setAlert('success', `${title}`, <LoveSurvey />);
};

interface Prop {
  store: AppStore;
}
export const InvoiceEmailController = (props: Prop) => {
  const { formatMessage: f } = useISIntl();
  const user = props.store.user;
  const invoice = props.store.doc;
  const email = invoice && invoice.draftEmail;
  const eventOpts = email
    ? { isFromEmailValid: email.isFromEmailValid, isToEmailValid: email.isToEmailValid }
    : {};
  if (!props.store.location.isEmail) {
    return null;
  }
  return (
    <InvoiceEmail
      invoice={invoice}
      onLoad={() => {
        user.trackAppEventViaApi('invoice-email-view', eventOpts);
      }}
      onSend={(sendEmail) => {
        user.events.trackAction('invoice-email-send', {
          'doc-type': invoice.docType,
          invoice: invoice.id,
          platform: Platform.WEB,
          invoiceTotal: getInvoiceTotal(invoice.asUniversal)
        });

        sendEmail();
      }}
      onCancel={() => {
        user.trackAppEventViaApi('invoice-email-cancel');
        email.toEmail = '';
        email.fromEmail = '';
        props.store.doc.showPreview();
      }}
      onSuccess={handleSuccess(props.store, f)}
      onError={(e) => {
        user.trackAppEventViaApi('invoice-email-error');
        alertModel.setAlert('danger', f(messages.alertFailedTitle), e.message);
      }}
    />
  );
};

export default observer(InvoiceEmailController);
