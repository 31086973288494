import Parse from 'parse';
import { computed, action, observable } from 'mobx';
import find from 'lodash/find';
import includes from 'lodash/includes';
import InvoiceModel from './InvoiceModel';
import { paymentMethodOptionsPaymentList } from '../data/paymentMethodOptions';
import PaymentMethodOption from '../types/PaymentMethodOption';
import { getValueOrDefault } from 'src/util/getValueOrDefault';
import { v4 as uuid } from 'uuid';

export default class InvoicePaymentModel {
  @observable invoice: InvoiceModel;
  @observable amount: number = 0;
  @observable delete: boolean = false;
  @observable deleted: boolean = false;
  @observable date: Date;
  @observable notes: string | undefined;
  @observable paymentMethod: string;
  @observable surcharge: number | undefined;
  @observable transactionId: string | undefined;
  @observable id = uuid();

  get = getValueOrDefault(this.getDefault);

  constructor(invoice: InvoiceModel, data: Parse.Object | any = {}) {
    this.invoice = invoice;
    this.update(data);
  }

  @action
  public update(data: Parse.Object | any) {
    this.amount = this.get(data, 'amount');
    const fetchedDate = this.get(data, 'date');
    if (fetchedDate.__type === 'Date' && fetchedDate.iso) {
      this.date = new Date(fetchedDate.iso);
    } else {
      this.date = fetchedDate;
    }
    this.notes = this.get(data, 'notes');
    this.paymentMethod = this.get(data, 'paymentMethod');
    this.transactionId = this.get(data, 'transactionId');
    this.surcharge = this.get(data, 'surcharge');
  }
  @computed
  get isValid(): boolean {
    return this.amount > 0 && this.paymentMethod !== 'chooseMethod';
  }

  @computed
  get amountDisplay(): number | undefined {
    return this.amount === 0 ? undefined : this.amount;
  }

  @computed
  get paymentMethodLabel(): string {
    const paymentMethodOption: PaymentMethodOption | undefined = find(
      paymentMethodOptionsPaymentList,
      {
        value: this.paymentMethod
      }
    );

    if (paymentMethodOption && paymentMethodOption.label) {
      return paymentMethodOption.label;
    }

    return this.paymentMethod;
  }

  @computed
  get paymentMethodValues(): string[] {
    return paymentMethodOptionsPaymentList.map((e: PaymentMethodOption) => e.value);
  }

  @computed
  get isMethodBank(): boolean {
    return this.paymentMethod === 'bank';
  }

  @computed
  get isMethodOther(): boolean {
    return this.paymentMethod === 'other';
  }

  @action
  setPaymentMethod(value: string): void {
    if (includes(this.paymentMethodValues, value)) {
      this.paymentMethod = value;
    }
  }
  @action
  setNotes(value: string): void {
    this.notes = value;
  }
  @action
  setAmount(value: number): void {
    this.amount = value || 0;
  }
  @action.bound
  setDate(value: Date | null): void {
    if (value === null) {
      this.date = new Date();
    } else {
      this.date = value;
    }
  }

  @action
  toggleDelete(): void {
    this.delete = !this.delete;
  }
  @action
  toggleDeleted(): void {
    this.deleted = !this.deleted;
  }
  @computed
  public get parseData(): any {
    return {
      amount: this.amount,
      date: this.date,
      notes: this.notes,
      paymentMethod: this.paymentMethod,
      transactionId: this.transactionId,
      surcharge: this.surcharge
    };
  }
  @computed
  public get autoSaveData() {
    return this.parseData;
  }
  protected getDefault(name: string) {
    return { amount: 0, date: new Date(), notes: '', paymentMethod: 'chooseMethod' }[name];
  }
}
