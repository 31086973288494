function convertError(e: Parse.Error | Error) {
  if (e instanceof Error) {
    if (!e.stack) {
      e.stack = new Error().stack;
    }
    return e;
  } else {
    const e2 = new Error(e.message);
    if (e.code) {
      (e2 as any).code = e.code; // keep the e.code in the newly created error
    }
    return e2;
  }
}

export default function isPromise<T>(promise: Promise<T>) {
  return promise.catch((e) => {
    throw convertError(e);
  });
}
