/**
 * Synchronious execution queue is a utility that
 * allows to create "queue" of tasks. Every time only 1 task is being
 * executed. when a task is done, the next one is being executing.
 * The queue is limited in size to 1 - i.e. adding more tasks will substitute
 * already exisitng pending tasks.
 */
export class SyncExecutionQueue {
  current: Promise<any>;
  next: () => Promise<any>;
  running: boolean;
  queue: (() => Promise<any>)[];
  /**
   * Creates an instance of SyncExecutionQueue.
   */
  constructor() {
    this.running = false;
    this.queue = [];
  }

  /**
   * Adds a task for execution. The last added task will be executed as
   * soon as the current task is done. Intermediate tasks are ignored.
   * The first tasks executes immediately.
   *
   *
   * @param {() => Promise<any>} task - a function that should be added to the queue.
   * @returns {Promise<void>} empty promise that should be awaited to catch exceptions.
   */
  public async push(task: () => Promise<any>): Promise<void> {
    this.queue[0] = task;

    if (this.running) {
      return;
    }

    try {
      this.running = true;
      await this.run();
    } finally {
      this.running = false;
    }
  }

  private async run() {
    let next = this.queue.pop();
    while (next) {
      await next();
      next = this.queue.pop();
    }
  }
}
