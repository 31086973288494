import { FeatureName, hasFullAccessToFeature } from '@invoice-simple/feature-gate';
import React from 'react';
import { defineMessages, useISIntl } from 'src/i18n';
import InvoiceModel from 'src/models/InvoiceModel';
import UserModel from 'src/models/UserModel';
import { getDaysRemaining } from 'src/util/date';
import { navToPaywallWithFeature } from 'src/util/navigation';
import { LockIcon } from '../Icons/LockIcon';

const messages = defineMessages({
  overdue: {
    id: 'invoiceSettings.share.reminder.overdue',
    defaultMessage: 'Overdue'
  },
  dueToday: {
    id: 'invoiceSettings.share.reminder.dueToday',
    defaultMessage: 'Due Today'
  },
  dueTomorrow: {
    id: 'invoiceSettings.share.reminder.dueTomorrow',
    defaultMessage: 'Due Tomorrow'
  },
  dueInDays: {
    id: 'invoiceSettings.share.reminder.dueInDays',
    defaultMessage: 'Due in {days} days'
  },
  sendReminder: {
    id: 'invoiceSettings.share.reminder.send',
    defaultMessage: 'Send Reminder'
  }
});

interface InvoiceSendReminderProps {
  invoice: InvoiceModel;
}

export const InvoiceSendReminder = ({ invoice }: InvoiceSendReminderProps) => {
  const intl = useISIntl();

  const user = UserModel.getInstance();
  const daysRemaining = getDaysRemaining(invoice.getDueDate());
  const hasDueDateReminderAccess = hasFullAccessToFeature({
    feature: FeatureName.DUE_DATE_REMINDERS,
    user
  });

  const getReminderTitle = () => {
    if (daysRemaining === 1) {
      return intl.formatMessage(messages.dueTomorrow);
    }
    switch (Math.sign(daysRemaining)) {
      case -1:
        return intl.formatMessage(messages.overdue);

      case 1:
        return intl.formatMessage(messages.dueInDays, { days: daysRemaining });

      default:
        return intl.formatMessage(messages.dueToday);
    }
  };

  const handleSendReminderClick = () => {
    if (!hasDueDateReminderAccess) {
      return navToPaywallWithFeature(FeatureName.DUE_DATE_REMINDERS);
    }

    user.events.trackAction('send-reminder-button-clicked');
    invoice.showEmailForm('reminder');
  };
  return (
    <div
      data-testid="reminder-wrapper"
      className="send-reminder-wrapper"
      onClick={handleSendReminderClick}>
      <div data-testid="reminder-title" className="due-date">
        {getReminderTitle()}
      </div>
      <div data-testid="reminder-button" className="send-reminder-link">
        {intl.formatMessage(messages.sendReminder)}
        {!hasDueDateReminderAccess && <LockIcon style={{ width: '12px', marginLeft: '3px' }} />}
      </div>
    </div>
  );
};
