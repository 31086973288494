import React from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { getIntervalText, getTierPrice } from 'src/components/SubscriptionPaywall/utils';
import { Cadence } from 'src/models/UserModel';

interface CardPriceProps {
  tierName: SubscriptionTier;
  selectedInterval: Cadence;
}

export const CardPrice = ({ tierName, selectedInterval }: CardPriceProps) => {
  const { comparePrice, displayPrice } = getTierPrice(tierName, selectedInterval);
  const isDiscounted = !!comparePrice && comparePrice !== displayPrice;

  const intervalText = getIntervalText(selectedInterval);

  return (
    <>
      {isDiscounted && <p className="line-through text-2xl">{comparePrice}</p>}
      <p
        className="text-[26px] font-extrabold text-gray-950"
        data-testid={`mobile-${tierName}-price`}>
        {displayPrice}
        <span className="text-2xl font-semibold text-gray-500 ml-1">{intervalText} </span>
      </p>
    </>
  );
};
