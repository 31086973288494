import React, { useLayoutEffect } from 'react';
import Modal from 'react-modal';

type PaymentModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  onRequestClose: () => void;
  onAfterClose?: () => void;
}>;

export const PaymentModalWrapper = ({
  isOpen,
  onRequestClose,
  onAfterClose,
  children
}: PaymentModalProps) => {
  useLayoutEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);
  if (process.env.NODE_ENV !== 'test') {
    // Hide page content for screenreaders when modal is open
    Modal.setAppElement('#app');
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      padding: 0,
      borderRadius: '12px',
      minWidth: '280px'
    } as React.CSSProperties,
    overlay: {
      position: 'fixed',
      inset: '0px',
      backgroundColor: 'rgba(68, 68, 68, 0.75)',
      zIndex: 50
    } as React.CSSProperties
  };

  return (
    <Modal
      onAfterClose={onAfterClose}
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose();
      }}
      style={customStyles}>
      <div id="tailwind">
        <div className="payment-modal-wrapper" data-testid="payment-modal-wrapper">
          <div
            className="h-full w-full relative max-w-[480px] [@media(min-height:663px)]:h-auto"
            data-testid="learnMoreModalContent">
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
};
