import React from 'react';
import ItemRow from './ItemRow';
import { PaginatedItemsResponse } from './usePaginatedItems';
import { defineMessages } from 'src/i18n';
import { FormattedMessage } from 'react-intl';
import { SearchItemsResponse } from './useSearchItems';

interface Props {
  onView: (id?: string) => void;
  onCreate: (e: React.MouseEvent<HTMLElement>) => void;
  data?: PaginatedItemsResponse | SearchItemsResponse;
  isError: boolean;
  query: string;
  hasSearchResults: boolean;
  onError: (error: { title?: string; body?: string }) => void;
}

const messages = defineMessages({
  rowEmpty: {
    id: 'item.list.row.empty',
    defaultMessage: 'You have no Items'
  },
  rowEmptySearch: {
    id: 'item.list.row.empty.search',
    defaultMessage: 'No items match this search.'
  },
  rowNewItem: {
    id: 'item.list.row.button.new',
    defaultMessage: 'add your first Item today.'
  }
});

export const Items: React.FunctionComponent<Props> = ({
  onView,
  onCreate,
  data,
  query,
  hasSearchResults
}) => {
  if (!data || (data && !data.items.length)) {
    return (
      <tr className="invoice-row-empty">
        <td colSpan={5}>
          {!hasSearchResults && !!query ? (
            <span>
              <FormattedMessage {...messages.rowEmptySearch} />
            </span>
          ) : (
            <>
              <span>
                <FormattedMessage {...messages.rowEmpty} />
              </span>
              <span>
                ,
                <a className={`btn-link btn-link-item-new`} onClick={onCreate}>
                  <FormattedMessage {...messages.rowNewItem} />
                </a>
              </span>
            </>
          )}
        </td>
      </tr>
    );
  }

  const currencySymbol = '$'; // TODO update from settings

  return (
    <>
      {data.items.map((item, index) => (
        <ItemRow
          item={item}
          key={item.objectId}
          onClick={onView}
          index={index}
          currencySymbol={currencySymbol}
        />
      ))}
    </>
  );
};
