import React from 'react';

export const ValueProp: React.FC<{
  width: number;
  height: number;
  containerWidth?: number;
  containerHeight?: number;
  imageUrl: string;
  message: string;
}> = (props) => {
  const { width, height, imageUrl, message, containerWidth } = props;
  return (
    <div className="flex flex-row justify-center items-center" style={{ paddingBottom: 20 }}>
      <div className="icon flex-grow-0 mr-3.5 ml-6">
        <div>
          <img
            style={{
              width,
              height,
              marginLeft: containerWidth ? Math.floor((containerWidth - width) / 2) : undefined,
              marginRight: containerWidth ? Math.floor((containerWidth - width) / 2) : undefined
            }}
            src={imageUrl}
            alt="invoices icon"
          />
        </div>
      </div>
      <div
        className="valueProp flex-grow-1 text-gray-800"
        style={{ fontSize: 14, fontWeight: 400, lineHeight: '20px', textAlign: 'left' }}>
        {message}
      </div>
    </div>
  );
};
