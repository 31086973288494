import Parse from 'parse';
import { Setting } from 'src/util/IsParseDomain';
import ErrorListModel from 'src/models/ErrorListModel';

export const getAccountSettingsQuery = (account: Parse.Object) =>
  new Parse.Query<Parse.Object>(Setting)
    .equalTo('account', account)
    .equalTo('deleted', false)
    .select('remoteId', 'type', 'valStr', 'valBool', 'valNum', 'valDec');

export const getAccountSettings = async (account: Parse.Object): Promise<Parse.Object[]> => {
  try {
    return getAccountSettingsQuery(account).find();
  } catch (error) {
    ErrorListModel.pushError(error);
    throw error;
  }
};
