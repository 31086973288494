import React, { FC } from 'react';

import InvoiceModel from 'src/models/InvoiceModel';
import { useStripeContext } from 'src/payments/contexts/StripeAccountContext';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { getPaymentEventSource, hasCurrencyMismatch } from 'src/payments/utils';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';

import InvoiceCurrencyMismatchFooter from '../../Common/InvoiceCurrencyMismatchFooter';
import {
  StripeCurrencyMismatchBox,
  StripeGlobalPaymentToggleBox,
  StripeInvoicePaymentToggleBox
} from './SupportingComponents';

export const StripeInvoiceAcceptingBoxComponent: FC<{ store: AppStore }> = ({ store }) => {
  const stripeContext = useStripeContext();
  if (!stripeContext) {
    return null;
  }

  const stripeSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    source: getPaymentEventSource()
  };

  const { isStripeEnabled, updateStripeEnabled, stripePrimaryCurrency } = stripeContext;

  const user = store.user;
  const { currencyCode } = user.settingList;
  const invoice: InvoiceModel = store.doc;

  const isStripeCurrencyMismatch = hasCurrencyMismatch(stripePrimaryCurrency, currencyCode);
  const activatePayments = async () => {
    user.events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.tileGlobalEnabled
    });
    await updateStripeEnabled(true);
    if (invoice?.stripePaymentSuppressed) {
      invoice.setStripePaymentSuppressed(false);
    }
  };

  const updateStripePaymentSuppressed = (value: boolean) => {
    user.events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: !value
        ? PaymentEventComponent.tileDisabledInvoiceAccepting
        : PaymentEventComponent.tileEnabledInvoiceAccepting
    });
    invoice.setStripePaymentSuppressed(!value);
  };

  const fixUserCurrencyMismatch = () => {
    user.settingList.setCurrencyCode(stripePrimaryCurrency?.toUpperCase() ?? 'USD');
  };

  return (
    <>
      <div data-testid="stripeInvoiceAcceptingBox" />
      {!isStripeEnabled && (
        <StripeGlobalPaymentToggleBox
          checked={isStripeEnabled}
          activatePayments={activatePayments}
        />
      )}
      {isStripeEnabled && isStripeCurrencyMismatch && (
        <StripeCurrencyMismatchBox
          onFixUserCurrencyMismatchHandler={fixUserCurrencyMismatch}
          footer={<InvoiceCurrencyMismatchFooter dataTestId="stripeCurrencyMismatchFooter" />}
        />
      )}
      {isStripeEnabled && !isStripeCurrencyMismatch && (
        <StripeInvoicePaymentToggleBox
          isPaymentSuppressed={invoice.setting?.stripePaymentSuppressed ?? false}
          onToggleChange={updateStripePaymentSuppressed}
        />
      )}
    </>
  );
};

export const StripeInvoiceAcceptingBox = injectStore(StripeInvoiceAcceptingBoxComponent);
