import React from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props extends React.HTMLProps<HTMLAnchorElement> {
  isActive?: boolean;
  isDisabled?: boolean;
  customClasses?: string;
}

export const NavMenu = ({ children, title, customClasses }: Props) => {
  const classes = cn(`dropdown ${customClasses ? customClasses : ''}`);

  return (
    <div role="menu" tabIndex={0} className={classes}>
      <>
        <div className="more-btn">
          {title}
          <FontAwesomeIcon icon="chevron-down" className={`ml-2`} />
        </div>
        <div className="dropdown-content">{children}</div>
      </>
    </div>
  );
};
