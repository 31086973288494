import React from 'react';

import { StripeIcon } from 'src/components/Icons';

export const StripeBaseBox = ({
  icon,
  actionElement,
  footer
}: {
  icon?: JSX.Element;
  actionElement: JSX.Element;
  footer?: JSX.Element;
}): JSX.Element => {
  return (
    <div
      data-testid="stripeBaseBox"
      style={{
        border: '1px solid'
      }}
      className="flex flex-column justify-between rounded-[0.4rem] !border-gray-300 w-full bg-white">
      <div className="flex flex-row justify-between px-4 py-3">
        <div className="max-w-[70%] grid grid-cols-1 gap-y-[0.4rem]">
          <div className="flex flex-row justify-start gap-2">
            <StripeIcon />
            {icon}
          </div>
          <p className="text-lg leading-5 font-medium text-gray-700 [@media(min-width:992px)]:w-[90%] [@media(min-width:1200px)]:w-[100%]">
            Credit, Debit & ACH Bank Transfer
          </p>
        </div>
        {actionElement}
      </div>
      {footer}
    </div>
  );
};
