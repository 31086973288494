import './styles/index.scss';

import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.string.starts-with';
import 'core-js/modules/es.string.ends-with';

import React from 'react';
import { createRoot } from 'react-dom/client';
import Root from './components/Root';
import store from './stores/AppStore';
import { maybeLoadIntlPolyfill } from 'src/i18n/polyfillLoader';

const env = process.env.REACT_APP_ENV;
const isBot = /bot|google|baidu|bing|msn|duckduckgo|teoma|slurp|yandex/i.test(navigator.userAgent);
const isProd = !!env && /production/i.test(env);

if (!isBot) {
  if (!isProd) {
    window.store = store;
  }

  maybeLoadIntlPolyfill()
    .then(() => store.user.initPromise)
    .then(() => {
      /*
      Using hydration is dangerous because we use snapshots
      When the snapshot content is different from the first hydration
      (e.g. restricted area and user w/o account) - the results are
      unpredictable
      */
      const container = document.getElementById('app')!;
      const root = createRoot(container);
      root.render(<Root store={store} />);

      // @ts-ignore
      document
        .getElementById('loading-logo')
        .setAttribute('class', 'loading-logo d-print-none hidden');

      // Enable Hot Reload, doesn't affect production
      // @ts-ignore
      if (!isProd && module.hot) {
        root.render(<Root store={store} />);
        // @ts-ignore
        module.hot.accept();
      }
    });
}
