import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';
import EmailInput from './EmailInput';
import Alert from './Alert';
import { injectIntl, defineMessages } from 'src/i18n';
import { FormattedMessage } from 'react-intl';
import location from 'src/models/LocationModel';
import { resetPassword } from 'src/models/UserAuth';

const messages = defineMessages({
  title: {
    id: 'password.title',
    defaultMessage: 'Reset your Password'
  },
  description: {
    id: 'password.description',
    defaultMessage:
      'Forgot your password? No worries, it happens. Request a password reset for your Invoice Simple account.'
  },
  header: {
    id: 'password.header',
    defaultMessage: 'Reset your password'
  },
  subHeader: {
    id: 'password.body',
    defaultMessage: "Enter your email and we'll send you a link to change your password."
  },
  formLabelEmail: {
    id: 'password.form.labels.email',
    defaultMessage: 'Email'
  },
  formPlaceholderEmail: {
    id: 'password.form.placeholders.email',
    defaultMessage: 'name@example.com'
  },
  formButtonLabel: {
    id: 'password.form.button.label',
    defaultMessage: 'Reset your Password'
  },
  formButtonLabelPending: {
    id: 'password.form.button.label.pending',
    defaultMessage: 'Sending...'
  },
  formButtonLabelLoading: {
    id: 'password.form.button.label.loading',
    defaultMessage: 'Loading...'
  },

  cancelButtonLabel: {
    id: 'password.cancel.button.label',
    defaultMessage: 'Cancel'
  },
  altLinkQuestion: {
    id: 'password.alt.link.question',
    defaultMessage: 'Got your password?'
  },
  altLinkLabel: {
    id: 'password.alt.link.label',
    defaultMessage: 'Sign In'
  }
});

@injectIntl
@observer
class PasswordReset extends Component<any, any> {
  handleNav(path: string, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    location.navigateHard(path);
  }
  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    resetPassword(this.props.store.user);
  }
  render() {
    const store = this.props.store;
    const user = store.user;

    const f = this.props.intl.formatMessage;
    return (
      <div className="password-reset">
        <Helmet>
          <title itemProp="name">{f(messages.title)}</title>
          <body className="app-theme app-theme-focus" />
          <meta name="description" content={f(messages.description)} />
        </Helmet>
        <div className="focus-container">
          <div className="focus-header">
            <a className="brand" href="/" onClick={(e) => this.handleNav('/', e)}>
              <img
                className="brand-logo"
                src="/images/logo.png"
                role="presentation"
                alt="invoice simple logo"
                data-pin-nopin="true"
              />
            </a>
            <h1>
              <FormattedMessage {...messages.header} />
            </h1>
            <h2>
              <FormattedMessage {...messages.subHeader} />
            </h2>
          </div>
          <div className="focus-body">
            <Alert />
            <form acceptCharset="UTF-8" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="form-group">
                <label htmlFor="email">
                  <FormattedMessage {...messages.formLabelEmail} />
                </label>
                <EmailInput
                  key="user-password-reset"
                  name="email"
                  className="form-control"
                  placeholder={f(messages.formPlaceholderEmail)}
                  value={user.passwordResetData.email}
                  onChange={(e) => (user.passwordResetData.email = e.target.value)}
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block btn-prime"
                  data-selector="reset-password-submit">
                  <FormattedMessage {...messages.formButtonLabel} />
                </button>
              </div>
              <a
                className="btn btn-cancel"
                onClick={(e) => this.handleNav('/', e)}
                data-selector="reset-password-cancel">
                <FormattedMessage {...messages.cancelButtonLabel} />
              </a>
            </form>
          </div>
          <div className="focus-footer">
            <FormattedMessage {...messages.altLinkQuestion} />
            &nbsp;
            <a href="/login" className="btn" onClick={(e) => this.handleNav('/login', e)}>
              <FormattedMessage {...messages.altLinkLabel} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default PasswordReset;
