import React from 'react';
import { observer } from 'mobx-react';
import PhotoModel from 'src/models/PhotoModel';
import { PhotoDrop, onDropErrorWithIntl } from '../PhotoDrop';
import { ISIntl, injectIntl } from 'src/i18n';
import UserEventModel from 'src/models/UserEventModel';

interface Prop {
  userEvents: UserEventModel;
  logo: PhotoModel;
  disabled: boolean;
  intl?: ISIntl;
}

@injectIntl
@observer
export default class SettingFormGroupLogo extends React.Component<Prop, any> {
  render() {
    const intl = this.props.intl!;
    return (
      <div className="setting-form-group form-group row">
        <label htmlFor="company-logo" className="col-md-4 col-form-label">
          Logo
        </label>
        <div className="col-md-8">
          <PhotoDrop
            userEvents={this.props.userEvents}
            photo={this.props.logo}
            disabled={this.props.disabled}
            onError={onDropErrorWithIntl(intl)}
          />
        </div>
      </div>
    );
  }
}
