/**
 * return file extension
 *
 * param: tester.png
 * return: png
 */
export default function FileExt(fileName: string = '') {
  const parts = fileName.split('.');
  return parts[parts.length - 1].toLowerCase();
}
