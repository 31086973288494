import React from 'react';
import { defineMessages } from 'src/i18n';
import { ISIntl, useISIntl } from 'src/i18n/utils';
import location from 'src/models/LocationModel';
import { AlertDialogConfig } from '../DialogModal/DialogModalContext';
import { useDialog } from '../DialogModal/useDialog';

export const messages = defineMessages({
  header: {
    id: 'settings.list.subscription',
    defaultMessage: 'Subscription'
  },
  labelAnnualUpgrade: {
    id: 'settings.list.subscription.annual.upgrade',
    defaultMessage: 'Upgrade'
  },
  buttonAnnualUpgrade: {
    id: 'settings.list.subscription.annual.btn',
    defaultMessage: 'Upgrade to Annual'
  },
  labelAccount: {
    id: 'settings.list.subscription.account',
    defaultMessage: 'Account'
  },
  labelCancel: {
    id: 'settings.list.subscription.cancel',
    defaultMessage: 'Cancel'
  },
  buttonCancel: {
    id: 'subscription.cancel.title',
    defaultMessage: 'Cancel Subscription'
  },
  cancelConfirmPrompt: {
    id: 'subscription.cancel.confirmPrompt',
    defaultMessage: 'Are you sure you want to cancel your subscription?'
  },
  yes: {
    id: 'app.alert.yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'app.alert.no',
    defaultMessage: 'No'
  }
});

export const testIds = {
  header: 'sub-setting-header',
  labelAnnualUpgrade: 'sub-setting-upgrade-label',
  buttonAnnualUpgrade: 'sub-setting-upgrade-button',
  labelCancel: 'sub-setting-cancel-label',
  buttonCancel: 'sub-setting-cancel-button',
  labelAccount: 'sub-setting-account-label'
};

interface SubscriptionSettingsProps {
  showAnnualUpgrade: boolean;
  accountEmail?: string;
}

export const SubscriptionSettings = ({
  showAnnualUpgrade,
  accountEmail
}: SubscriptionSettingsProps): JSX.Element => {
  const { ft, fta } = useISIntl();
  const { openDialogModal } = useDialog();
  const settingGroupClassName = 'setting-form-group form-group row';
  const buttonClassName = 'btn w-100';

  return (
    <>
      <h4 data-testid={testIds.header}>{ft(messages.header)}</h4>
      {accountEmail && (
        <div className={settingGroupClassName}>
          <label className="col-md-4 col-form-label" data-testid={testIds.labelAccount}>
            {ft(messages.labelAccount)}
          </label>
          <div className="col-md-8 d-flex flex-column justify-content-center">
            <p>{accountEmail}</p>
          </div>
        </div>
      )}

      {showAnnualUpgrade && (
        <div className={settingGroupClassName}>
          <label className="col-md-4 col-form-label" data-testid={testIds.labelAnnualUpgrade}>
            {ft(messages.labelAnnualUpgrade)}
          </label>
          <div className="col-md-8">
            <button
              type="button"
              className={`${buttonClassName} btn-prime`}
              data-testid={testIds.buttonAnnualUpgrade}
              onClick={() => location.nav('subscription', { ref: 'setting' })}>
              {ft(messages.labelAnnualUpgrade)}
            </button>
          </div>
        </div>
      )}
      <div className={settingGroupClassName}>
        <label className="col-md-4 col-form-label" data-testid={testIds.labelCancel}>
          {ft(messages.labelCancel)}
        </label>
        <div className="col-md-8">
          <button
            type="button"
            className={buttonClassName}
            data-testid={testIds.buttonCancel}
            onClick={(e) => {
              e.preventDefault();
              openDialogModal(subscriptionCancelDialogConfig(ft, fta));
            }}>
            {ft(messages.buttonCancel)}
          </button>
        </div>
      </div>
    </>
  );
};

const subscriptionCancelDialogConfig = (
  ft: ISIntl['ft'],
  fta: ISIntl['fta']
): AlertDialogConfig => ({
  title: fta(messages.buttonCancel),
  message: ft(messages.cancelConfirmPrompt),
  confirmButtonText: fta(messages.yes),
  cancelButtonText: fta(messages.no),
  type: 'alert',
  onContinue: () => location.nav('subscriptionCancelV2', { ref: 'setting' })
});
