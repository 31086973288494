import { observable, action, IReactionDisposer, computed } from 'mobx';
import UserModel from './UserModel';
import axios from 'axios';
import EnvironmentStore from '../stores/EnvironmentStore';
import isPromise from '../util/IsPromise';
import { ReportList, ReportType } from 'src/components/Reports/ReportTypes';

export default class ReportModel {
  @observable user: UserModel;
  @observable autoLoad?: IReactionDisposer;

  // state
  @observable isLoading: boolean = false;
  @observable isLoaded: boolean = false;
  @observable loadError: Error | undefined = undefined;

  @observable reports: ReportList;

  @observable url: string;

  @observable reportType: ReportType = 'paid';

  constructor(user: UserModel) {
    this.user = user;
  }

  @computed
  get isPaid(): boolean {
    return this.reportType === 'paid';
  }

  @computed
  get isClients(): boolean {
    return this.reportType === 'clients';
  }

  @computed
  get isItems(): boolean {
    return this.reportType === 'items';
  }

  @computed
  get canSeeItemsReport(): boolean {
    return this.reports && !!this.reports.itemsReportUrl;
  }

  @action
  setReportType(reportType: ReportType): void {
    this.reportType = reportType;
    this.url = '';
    this.load();
  }

  @action
  changeYear(url: string): void {
    this.url = url;
    this.load();
  }

  @action
  public async load(): Promise<void> {
    if (this.isLoading) {
      return;
    }
    this.startLoading();
    try {
      const res = await isPromise(this.query());
      this.reports = res;
      this.stopLoading();
    } catch (error) {
      this.user.handleError('report-list-load', error);
      this.stopLoading(error);
    }
  }

  @action
  private startLoading(): void {
    this.isLoading = true;
  }
  @action
  private stopLoading(error?: Error) {
    this.isLoading = false;
    this.isLoaded = !error;
    this.loadError = error;
  }

  private async query() {
    const url = this.url
      ? `${EnvironmentStore.appApiUrl}${this.url}`
      : `${EnvironmentStore.appApiUrl}/api/v3/app/report/${this.reportType}?sessionToken=${this.user?.sessionToken}&useTaxYear=true`;
    const res = await axios.get(url);
    if (res.status === 400) return [];
    return res.data;
  }
}
