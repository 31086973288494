import React from 'react';
import { isValidEmail } from '../util/isValidEmail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './EmailInput.css';

// key as required prop to ensure expected behavior for controlled component with 'draft'
// https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key

interface Props {
  key: string;
  name: string;
  className?: string;
  autoComplete?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValidEmail: (validEmail: string) => void;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default class EmailInput extends React.Component<Props, {}> {
  static defaultProps = {
    name: 'email',
    onValidEmail: () => {}
  };

  state = {
    rawValue: this.props.value
  };

  handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };
  handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.which === 32) {
      e.preventDefault();
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rawValue: e.target.value });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    const validValue = isValidEmail(e.target.value) ? e.target.value : '';
    this.props.onValidEmail(validValue);
  };

  render() {
    const value = this.state.rawValue || this.props.value;
    const className = this.props.className || this.props.name;
    const autoComplete = this.props.autoComplete || 'email';
    const isPresent = value && value.length > 0;
    const invalidColor = isPresent ? 'red' : 'grey';
    const icon = isValidEmail(value) ? (
      <FontAwesomeIcon icon="check-circle" color="grey" />
    ) : isPresent ? (
      <FontAwesomeIcon icon="exclamation-circle" color={invalidColor} />
    ) : (
      ''
    );
    return (
      <div className="text-with-icon w-100">
        {icon}
        <input
          type="email"
          disabled={this.props.disabled}
          id={this.props.name}
          name={this.props.name}
          className={className}
          autoComplete={autoComplete}
          placeholder={this.props.placeholder}
          value={value}
          onFocus={(e) => this.handleFocus(e)}
          onBlur={(e) => this.handleBlur(e)}
          onChange={(e) => this.handleChange(e)}
          onKeyDown={(e) => this.handleKeyDown(e)}
        />
      </div>
    );
  }
}
