import React, { useContext } from 'react';
import { inject } from 'mobx-react';
import CurrencySelect from './CurrencySelect';
import { useDialog } from '../DialogModal/useDialog';
import { PaypalAccountContext } from 'src/payments/contexts/PaypalAccountContext';
import {
  AlertDialogConfig,
  DialogModalConfig,
  SuccessDialogConfig
} from '../DialogModal/DialogModalContext';
import { defineMessages, injectIntl } from 'src/i18n';
import { compose } from 'src/util/functions';
import SettingListModel from 'src/models/SettingListModel';
import { ISIntl } from 'src/i18n/utils';
import { useStripeContext } from 'src/payments/contexts/StripeAccountContext';
import { usePaymentOnboardModal } from 'src/payments/hooks/usePaymentsOnboardModal';
import { PaymentsCurrencyModal } from 'src/payments';
import { useIsPaypalRedesignFlagEnabled } from 'src/payments/hooks/useIsPaypalRedesignFlagEnabled';

interface CurrencyOptionParams {
  value: string;
}

const messages = defineMessages({
  currencyUpdatedTitle: {
    id: 'currency.updated.dialog.title',
    defaultMessage: 'Currency Updated'
  },
  currencyUpdatedMessage: {
    id: 'currency.updated.dialog.message',
    defaultMessage: 'All your Invoices and estimates changed to {newCurrency}.'
  },
  currencyMismatchTitle: {
    id: 'currency.mismatch.dialog.title',
    defaultMessage: 'Are you sure?'
  },
  currencyMismatchMessage: {
    id: 'currency.mismatch.dialog.message',
    defaultMessage:
      'All your Invoices and Estimates will be changed to {newCurrency}, and PayPal Payments will be disabled.'
  },
  currencyMatchTitle: {
    id: 'currency.match.dialog.title',
    defaultMessage: 'Ready to Accept Payments'
  },
  currencyMatchMessage: {
    id: 'currency.match.dialog.message',
    defaultMessage:
      'All your Invoices and Estimates changed to {newCurrency}, and PayPal Payments is enabled.'
  }
});

export const currencyUpdatedDialog = (
  newCurrency: CurrencyOptionParams,
  formatMessage: ISIntl['formatMessage']
): SuccessDialogConfig => ({
  type: 'success',
  title: formatMessage(messages.currencyUpdatedTitle),
  message: formatMessage(messages.currencyUpdatedMessage, { newCurrency: newCurrency.value })
});

export const currencyMismatchDialog = (
  newCurrency: CurrencyOptionParams,
  settingList: SettingListModel,
  formatMessage: ISIntl['formatMessage']
): AlertDialogConfig => ({
  type: 'alert',
  title: formatMessage(messages.currencyMismatchTitle),
  message: formatMessage(messages.currencyMismatchMessage, { newCurrency: newCurrency.value }),
  onContinue: () => settingList.setCurrencyCode(newCurrency.value)
});

export const currencyMatchDialog = (
  newCurrency: CurrencyOptionParams,
  formatMessage: ISIntl['formatMessage']
): SuccessDialogConfig => ({
  type: 'success',
  title: formatMessage(messages.currencyMatchTitle),
  message: formatMessage(messages.currencyMatchMessage, { newCurrency: newCurrency.value })
});

export const handleCurrencyChange = (
  newCurrency: CurrencyOptionParams,
  paypalCurrency: string | null,
  settingList: SettingListModel,
  openDialogModal: (dialogModalConfig: DialogModalConfig) => void,
  formatMessage: ISIntl['formatMessage']
) => {
  if (!newCurrency?.value || !settingList) return;

  if (paypalCurrency && paypalCurrency !== newCurrency.value) {
    openDialogModal(currencyMismatchDialog(newCurrency, settingList, formatMessage));
    return;
  }
  updateCurrencyAndShowDialog(formatMessage, newCurrency, settingList, openDialogModal);
};

export const handleCurrencyUpdate = ({
  newCurrency,
  paypalCurrency,
  stripeCurrency,
  settingList,
  openDialogModal,
  formatMessage,
  showModal
}: {
  newCurrency: CurrencyOptionParams;
  paypalCurrency: string | null;
  stripeCurrency: string | null;
  settingList: SettingListModel;
  openDialogModal: (dialogModalConfig: DialogModalConfig) => void;
  formatMessage: ISIntl['formatMessage'];
  showModal: () => void;
}) => {
  if (!newCurrency?.value || !settingList) return;

  if (paypalCurrency || stripeCurrency) {
    showModal();
    return;
  }
  updateCurrencyAndShowDialog(formatMessage, newCurrency, settingList, openDialogModal);
};

const updateCurrencyAndShowDialog = (
  formatMessage: ISIntl['formatMessage'],
  newCurrency: CurrencyOptionParams,
  settingList: SettingListModel,
  openDialogModal: (dialogModalConfig: DialogModalConfig) => void
) => {
  settingList.setCurrencyCode(newCurrency.value);
  openDialogModal(currencyUpdatedDialog(newCurrency, formatMessage));
};

const SettingsCurrencySelector = (props: { intl: ISIntl; settingList: SettingListModel }) => {
  const isPaypalRedesignFlagEnabled = useIsPaypalRedesignFlagEnabled();
  const { openDialogModal } = useDialog();
  const { isOpen, showModal, hideModal, origin } = usePaymentOnboardModal();

  const [selectedCurrency, setSelectedCurrency] = React.useState<string | null>(null);

  const paypalAccountContext = useContext(PaypalAccountContext);
  const stripeAccountContext = useStripeContext();

  const paypalCurrency = paypalAccountContext?.getPaypalAccountCurrency() ?? null;
  const stripeCurrency = stripeAccountContext?.stripePrimaryCurrency ?? null;

  const formatMessage = props.intl.formatMessage;
  const settingList = props.settingList;

  const setCurrency = () => {
    settingList.setCurrencyCode(selectedCurrency ?? 'USD');
    hideModal();
  };

  return (
    <>
      <CurrencySelect
        onChange={(newCurrency: CurrencyOptionParams) => {
          setSelectedCurrency(newCurrency.value);
          if (isPaypalRedesignFlagEnabled) {
            handleCurrencyUpdate({
              newCurrency,
              paypalCurrency,
              stripeCurrency,
              settingList,
              openDialogModal,
              formatMessage,
              showModal
            });
            return;
          }
          handleCurrencyChange(
            newCurrency,
            paypalCurrency,
            settingList,
            openDialogModal,
            formatMessage
          );
        }}
        blurInputOnSelect={true}
        {...props}
      />
      {isPaypalRedesignFlagEnabled && (
        <PaymentsCurrencyModal
          isOpen={isOpen}
          origin={origin}
          hideModal={hideModal}
          setCurrency={setCurrency}
          stripeCurrency={stripeCurrency}
          paypalCurrency={paypalCurrency}
          selectedCurrency={selectedCurrency}
        />
      )}
    </>
  );
};

export default compose(injectIntl, inject('settingList'))(SettingsCurrencySelector);
