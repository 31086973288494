import { getDialCodeFromCountryCode } from 'src/util/phone';
import { useEffect, useState } from 'react';

export interface Country {
  name: string;
  cca2: string;
}

interface CountryOption {
  label: string;
  value: string;
}

const localizedCountries: Record<string, () => Promise<Country[]>> = {
  'en-US': async () =>
    await import('src/languages/countries/en_US.json').then((module) => module.default),
  'en-AU': async () =>
    await import('src/languages/countries/en_US.json').then((module) => module.default),
  'en-GB': async () =>
    await import('src/languages/countries/en_US.json').then((module) => module.default),
  'en-CA': async () =>
    await import('src/languages/countries/en_US.json').then((module) => module.default),
  'en-NZ': async () =>
    await import('src/languages/countries/en_US.json').then((module) => module.default),
  'en-IE': async () =>
    await import('src/languages/countries/en_US.json').then((module) => module.default),
  'es-ES': async () =>
    await import('src/languages/countries/es_ES.json').then((module) => module.default),
  'nl-NL': async () =>
    await import('src/languages/countries/nl_NL.json').then((module) => module.default),
  'fr-FR': async () =>
    await import('src/languages/countries/fr_FR.json').then((module) => module.default),
  'it-IT': async () =>
    await import('src/languages/countries/it_IT.json').then((module) => module.default),
  'de-DE': async () =>
    await import('src/languages/countries/de_DE.json').then((module) => module.default)
} as const;

export const getCountries = async (locale = 'en-US'): Promise<Country[]> => {
  return await localizedCountries[locale]();
};

export const useCountries = (locale = 'en-US'): CountryOption[] => {
  const [countries, setCountries] = useState<CountryOption[]>([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const data = await getCountries(locale);
      setCountries(
        data
          .filter(
            (country) =>
              !!getDialCodeFromCountryCode(country.cca2) &&
              getDialCodeFromCountryCode(country.cca2) !== '0'
          )
          .map((country) => ({
            label: country.name,
            value: country.cca2
          }))
      );
    };

    fetchCountries();
  }, [locale]);

  return countries;
};
