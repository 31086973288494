import React from 'react';
import { defineMessages, injectIntl } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';

const messages = defineMessages({
  loading: {
    id: 'statusIndicator.loading',
    defaultMessage: 'loading'
  },
  saving: {
    id: 'statusIndicator.saving',
    defaultMessage: 'saving'
  }
});
export default injectIntl(
  (
    {
      isLoading = false,
      isSaving = false,
      intl
    }: { isLoading: boolean; isSaving: boolean; intl?: ISIntl } = {
      isLoading: false,
      isSaving: false
    }
  ) => (
    <div className="loading-indicator">
      {isLoading && <span>{intl!.ft(messages.loading)}</span>}
      {isSaving && <span>{intl!.ft(messages.saving)}</span>}
    </div>
  )
);
