import { useQuery } from '@tanstack/react-query';

import axios from 'axios';
import UserModel from 'src/models/UserModel';

import { DocType } from '@invoice-simple/common';

export interface YearlyBalanceDueSummary {
  balanceDue: number;
  year: number;
}
export interface YearlyTotalSummary {
  total: number;
  year: number;
}

export interface YearlySummaryResponse {
  results: YearlyBalanceDueSummary[] | YearlyTotalSummary[];
}

export function isYearlyTotalSummary(
  summary: YearlyBalanceDueSummary | YearlyTotalSummary
): summary is YearlyTotalSummary {
  return (summary as YearlyTotalSummary).total !== undefined;
}

interface QueryError {
  message: string;
}

interface SummaryDocsParams {
  years: number[];
  docType: DocType;
  field: 'balance-due' | 'total';
  state?: 'paid' | 'open' | 'all';
  isSearching: boolean;
}

export const fetchSummary = async ({ years, docType, field, state }: SummaryDocsParams) => {
  const { headers } = UserModel.getInstance().getApiReqOpts();

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_URL ?? 'https://localhost:3000'}/api/docs/summary`,
      {
        headers,
        params: {
          years: years.join(','),
          docType,
          field,
          state
        }
      }
    );

    return data as YearlySummaryResponse;
  } catch (error) {
    throw new Error(error);
  }
};

export const useYearlyBalanceOrTotals = ({
  years,
  docType,
  field,
  state,
  isSearching
}: Partial<SummaryDocsParams>) => {
  return useQuery<YearlySummaryResponse, QueryError>(
    ['yearly-balance-total', years, docType, field, state],
    () => fetchSummary({ years, docType, field, state } as SummaryDocsParams),
    {
      enabled: (!!years || !!docType) && !isSearching && !!years?.length
    }
  );
};
