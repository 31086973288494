import React from 'react';
import { trackEvent } from 'src/analytics/controller';
import { ToggleSwitch } from 'src/components/ToggleSwitch';

interface IProps {
  disabled: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const PaymentQRCodeToggle = (props: IProps) => (
  <div className="h-full flex items-center">
    <ToggleSwitch
      {...props}
      onChange={(checked) => {
        props.onChange(checked);
        trackEvent('payments-cta', {
          ctaType: 'qr-enable-toggle',
          ctaValue: checked
        });
      }}
    />
  </div>
);
