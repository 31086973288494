import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { PaypalAccountContext } from '../../contexts/PaypalAccountContext';
import { PaymentEventLocationType, PaymentEventLocation, PaymentEvent } from '../../types';
import { PaypalInitialBox } from './PaypalInitialBox';
import { PaypalPendingBox } from './PaypalPendingBox';
import { PaypalOnboardedBox } from './PaypalOnboardedBox';
import { PaypalClosedBox } from './PaypalClosedBox';
import './PaypalPromotionBox.scss';
import { AppStore } from 'src/stores/AppStore';
import { PaypalInitialToggle } from './PaypalInitialToggle';
import { messages } from './messages';
import { useIsPaypalRedesignFlagEnabled } from 'src/payments/hooks/useIsPaypalRedesignFlagEnabled';
import { useIsPaymentsEnabled } from 'src/payments/hooks/useIsPaymentsEnabled';

interface Props {
  store?: AppStore;
  location: PaymentEventLocationType;
  onTrackEvent: (eventName: PaymentEvent, metadata?: Record<string, number | string>) => void;
  closePaymentModal?: () => void;
}

enum SubAndPaypalStatus {
  initial = 'initial',
  pending = 'pending',
  onboarded = 'onboarded',
  closed = 'closed'
}

const shouldShowInitialToggle = (location: PaymentEventLocationType) => {
  return (
    location === PaymentEventLocation.promotionBoxInvoiceEditor ||
    location === PaymentEventLocation.promotionBoxSettings
  );
};

const renderInitialToggle = ({ location, onTrackEvent, store }: Props) => {
  return <PaypalInitialToggle location={location} onTrackEvent={onTrackEvent} store={store} />;
};

const renderPromotionBox = (
  status: SubAndPaypalStatus,
  { location, onTrackEvent, closePaymentModal }: Props
) => {
  let paymentBoxClass;
  switch (location) {
    case PaymentEventLocation.paymentModal:
      paymentBoxClass = 'payment-modal';
      break;
    case PaymentEventLocation.promotionBoxInvoiceEditor:
      paymentBoxClass = 'invoice-editor mx-0 my-1 px-5';
      break;
    default:
      paymentBoxClass = '';
  }

  return (
    <div>
      {location === PaymentEventLocation.promotionBoxInvoiceEditor && (
        <h4>
          <FormattedMessage {...messages.header} />
        </h4>
      )}
      <div className="paypal-promotion-box-container">
        <div className={`paypal-promotion-box ${paymentBoxClass}`}>
          <div className="row">
            <img className="is-logo" src="/images/is-logo.png" />
            <div className="text-header-container">
              <p className="text-header">
                <FormattedMessage {...messages.header} />
              </p>
            </div>
          </div>

          {status === SubAndPaypalStatus.initial && (
            <PaypalInitialBox onTrackEvent={onTrackEvent} />
          )}
          {status === SubAndPaypalStatus.pending && (
            <PaypalPendingBox onTrackEvent={onTrackEvent} />
          )}
          {status === SubAndPaypalStatus.onboarded && (
            <PaypalOnboardedBox location={location} closePaymentModal={closePaymentModal} />
          )}
          {status === SubAndPaypalStatus.closed && <PaypalClosedBox />}
        </div>
      </div>
    </div>
  );
};

export const PaypalPromotionBox = (props: Props) => {
  const paypalSetting = useContext(PaypalAccountContext);
  const isPaypalRedesignFlagEnabled = useIsPaypalRedesignFlagEnabled();
  const isPaymentsEnabled = useIsPaymentsEnabled();

  if (!isPaymentsEnabled) {
    return null;
  }

  if (!paypalSetting || isPaypalRedesignFlagEnabled) {
    return null;
  }

  if (!paypalSetting.isPaypalEligible() || paypalSetting.isPaypalAccepting()) {
    return null;
  }

  switch (true) {
    case paypalSetting.isPaypalInitial():
      if (shouldShowInitialToggle(props.location)) {
        return renderInitialToggle(props);
      }
      return renderPromotionBox(SubAndPaypalStatus.initial, props);
    case paypalSetting.isPaypalPending():
      return renderPromotionBox(SubAndPaypalStatus.pending, props);
    case paypalSetting.isPaypalOnboarded():
      return renderPromotionBox(SubAndPaypalStatus.onboarded, props);
    case paypalSetting.isPaypalClosed():
      return renderPromotionBox(SubAndPaypalStatus.closed, props);
    default:
      return null;
  }
};
