import React, { useContext, useState } from 'react';

export type CancelSubscriptionScreen = 'feedback' | 'value-props' | 'completed';

interface Metadata {
  hasRedeemedOffer: boolean;
}

export interface CancelSubscriptionContextValue {
  isFeedbackScreen: () => boolean;
  isValuePropsScreen: () => boolean;
  isCompletedScreen: () => boolean;
  goTo: (screen: CancelSubscriptionScreen, metadata?: Partial<Metadata>) => void;
  metadata: Metadata;
}

const CancelSubscriptionContext = React.createContext<CancelSubscriptionContextValue | null>(null);

export const useCancelSubscription = () =>
  useContext<CancelSubscriptionContextValue>(CancelSubscriptionContext as any);

export const CancelSubscriptionProvider = ({ children }) => {
  const [metadata, setMetadata] = useState<Metadata>({ hasRedeemedOffer: false });
  const [screen, setScreen] = useState<CancelSubscriptionScreen>('feedback');

  const isFeedbackScreen = () => screen === 'feedback';
  const isValuePropsScreen = () => screen === 'value-props';
  const isCompletedScreen = () => screen === 'completed';

  const goTo = (screen: CancelSubscriptionScreen, metadata?: Partial<Metadata>) => {
    setScreen(screen);

    if (metadata) {
      setMetadata((currentMetadata) => ({
        ...currentMetadata,
        ...metadata
      }));
    }
  };

  return (
    <CancelSubscriptionContext.Provider
      value={{ isFeedbackScreen, isValuePropsScreen, isCompletedScreen, goTo, metadata }}>
      {children}
    </CancelSubscriptionContext.Provider>
  );
};
