import values from 'lodash/values';
import compact from 'lodash/compact';
import { isValidEmail } from './isValidEmail';
import { isOnboardingV1Enabled } from './onboarding';

export const VALIDATION_ERRORS = {
  EMPTY_FIELDS: 'EMPTY_FIELDS',
  WRONG_EMAIL: 'WRONG_EMAIL',
  SHORT_PASSWORD: 'SHORT_PASSWORD'
};

const isValidUser = function (data, passwordLimit: number, signup?: boolean): boolean {
  const _values = values(data);
  const isPresent = compact(_values).length === _values.length;
  const onboardingV1Enabled = isOnboardingV1Enabled();

  // Specific case for signing up that shouldn't affect login: check for removed firstName and lastName
  if (
    signup &&
    // Skip firstName and lastName check if onboardingV1 is enabled
    (!isPresent || !data.password || (!onboardingV1Enabled && (!data.firstName || !data.lastName)))
  ) {
    throw new Error(VALIDATION_ERRORS.EMPTY_FIELDS);
  }

  if (!isPresent || !data.password) {
    throw new Error(VALIDATION_ERRORS.EMPTY_FIELDS);
  }
  if (!isValidEmail(data.email)) {
    throw new Error(VALIDATION_ERRORS.WRONG_EMAIL);
  }

  if (data.password && data.password.length < passwordLimit) {
    throw new Error(VALIDATION_ERRORS.SHORT_PASSWORD);
  }
  return true;
};

export const validateUser = (data, passwordLimit = 0, signup?: boolean) =>
  isValidUser(data, passwordLimit, signup);
