import React from 'react';

import { FormattedMessage } from 'react-intl';

import { ConsentStatus, ConsentType } from '@invoice-simple/common';

import { saveConsentData } from 'src/apis/consentAPI';
import { defineMessages, useISIntl } from 'src/i18n';
import UserModel from 'src/models/UserModel';
import { ModalWrapper } from '../ModalWrapper';
import { PromoModalContent } from '../PromoModalContent';

const messages = defineMessages({
  title: {
    id: 'advocate.consent.modal.title',
    defaultMessage: 'Refer friends and earn $20'
  },
  terms: {
    id: 'advocate.consent.modal.terms',
    defaultMessage:
      'By clicking "Generate your referral link", you agree to the <link>Referral Program Terms & Conditions</link>.'
  },
  generateReferralLink: {
    id: 'advocate.consent.modal.cta',
    defaultMessage: 'Generate your referral link'
  }
});

interface AdvocateConsentModalProps {
  closeModal: (consent: ConsentStatus) => void;
}

export const AdvocateConsentModal = ({ closeModal }: AdvocateConsentModalProps) => {
  const { ft } = useISIntl();
  const user = UserModel.getInstance();

  const consentType = ConsentType.REFERRAL_PROGRAM;
  const consentValue = user.email ?? user.id ?? user.username;
  const consentMessage = messages.terms.defaultMessage.replace(/<\/?link>/g, '');

  const onClose = () => {
    saveConsentData({
      user,
      data: [
        {
          consentType,
          consentValue,
          consentMessage,
          consentStatus: ConsentStatus.CONSENT_NOT_GIVEN
        }
      ]
    });
    closeModal(ConsentStatus.CONSENT_NOT_GIVEN);
  };

  const onConfirm = async () => {
    saveConsentData({
      user,
      data: [
        {
          consentType,
          consentValue,
          consentMessage,
          consentStatus: ConsentStatus.CONSENT_GIVEN
        }
      ]
    });
    closeModal(ConsentStatus.CONSENT_GIVEN);
  };

  const body = (
    <div
      className="flex flex-col gap-8 text-[14px] leading-[150%]"
      data-testid="advocate-consent-modal-body">
      <p>
        <FormattedMessage
          id="advocate.consent.modal.description"
          values={{ b: (text) => <strong className="font-bold">{text}</strong> }}
        />
      </p>
    </div>
  );

  const image = (
    <img
      src="/images/advocate-reward.png"
      alt={ft(messages.title)}
      className="w-full object-contain"
    />
  );

  const footerText = (
    <FormattedMessage
      id="advocate.consent.modal.terms"
      values={{
        link: (text) => (
          <a
            href="https://www.invoicesimple.com/referral-terms"
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer text-link-primary font-semibold">
            {text}
          </a>
        )
      }}
    />
  );

  return (
    <ModalWrapper isOpen={true} shouldCloseOnOverlayClick={true} onRequestClose={onClose}>
      <PromoModalContent
        title={ft(messages.title)}
        body={body}
        image={image}
        onClose={onClose}
        onConfirm={onConfirm}
        confirmButtonText={ft(messages.generateReferralLink)}
        footerText={footerText}
      />
    </ModalWrapper>
  );
};
