import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';

import { ConsentStatus, SettingKeys } from '@invoice-simple/common';

import Rollbar from 'src/analytics/rollbar';
import UserModel from 'src/models/UserModel';
import { AdvocateConsentModal } from '../AdvocateConsentModal';
import { hasReferralConsent, isReferralShareRef } from '../AdvocateConsentModal/utils';
import { ModalContainer } from './components/ModalContainer';
import { trackAdvocateAction } from './referral.util';
import { customStyles } from './styles';

export const FriendbuyShareModal = () => {
  const user = UserModel.getInstance();

  const [isOpen, setIsOpen] = useState(false);
  const [shouldOpenConsentModal, setShouldOpenConsentModal] = useState(false);

  useEffect(() => {
    if (user.isGuest || !user.email) {
      return;
    }

    try {
      const companyName = user.settingList.getSetting(SettingKeys.CompanyName)?.value;
      // @ts-ignore
      friendbuyAPI?.push([
        'track',
        'customer',
        {
          email: user.email,
          id: user.id,
          firstName: companyName ?? `${user.firstName} ${user.lastName}`
        }
      ]);
    } catch (error) {
      const err = error as Error;
      Rollbar.trackError('Error tracking customer in Friendbuy', err);
    }

    shouldOpenShareModal();
  }, []);

  const shouldOpenShareModal = async () => {
    const isReferralShare = isReferralShareRef();

    if (isReferralShare) {
      const isReferralConsentGiven = await hasReferralConsent();

      switch (true) {
        case isReferralConsentGiven:
          setIsOpen(true);
          trackAdvocateAction('share-url', { source: 'invoices' });
          break;
        case !isReferralConsentGiven:
          setShouldOpenConsentModal(true);
          break;
      }
    }
  };

  const closeShareModal = () => {
    setIsOpen(false);
  };

  const closeConsentModal = (consent: ConsentStatus) => {
    setShouldOpenConsentModal(false);

    if (consent === ConsentStatus.CONSENT_GIVEN) {
      setIsOpen(true);
      trackAdvocateAction('share-url', { source: 'invoices' });
    }
  };

  if (shouldOpenConsentModal) {
    return <AdvocateConsentModal closeModal={closeConsentModal} />;
  }

  return (
    <div>
      <Modal isOpen={isOpen} onRequestClose={closeShareModal} style={customStyles}>
        <ModalContainer>
          <div id="fb-advocate-share-modal" />
        </ModalContainer>
      </Modal>
    </div>
  );
};
