import React from 'react';
import Sticky from 'react-stickynode';
import cn from 'classnames';
import NavItems from './NavItems';
import NavAccessItems from './NavAccessItems';
import NavBrand from './NavBrand';
import MobileNavItems from './MobileNavItems';
import MenuToggler from './MenuToggler';
import { Desktop, Mobile } from '../Responsive';
import scrollAware from '../ScrollAware';
import { observer } from 'mobx-react';
import uiStore from '../../stores/UIStore';
import './Navbar.scss';

@scrollAware
@observer
class NavBar extends React.Component<any, any> {
  render() {
    const { direction, directionDistance } = this.props;
    const classes = cn('d-print-none', 'nav-container', {
      'nav-down': direction === 'down' && directionDistance > 100 && uiStore.isNavMenuCollapsed
    });

    return (
      <Sticky enabled={!uiStore.isNavMenuCollapsed} innerZ={31}>
        <header className={classes}>
          <div className={`nav-content`}>
            <NavBrand />

            <Desktop minWidth={1024}>
              <NavItems />
              <NavAccessItems />
            </Desktop>

            <Mobile maxWidth={1023}>
              <MenuToggler />
              <MobileNavItems />
            </Mobile>
          </div>
        </header>
      </Sticky>
    );
  }
}

export default NavBar;
