import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import UserModel from 'src/models/UserModel';

export type ClientSummariesResponse = {
  clientRemoteId: string;
  totalBilled: number;
  invoiceCount: number;
}[];

interface QueryError {
  message: string;
}

export const fetchClientSummaries = async ({
  clientRemoteIds
}: {
  clientRemoteIds: string[];
}): Promise<ClientSummariesResponse> => {
  const { headers } = UserModel.getInstance().getApiReqOpts();
  const { data } = await axios.post<ClientSummariesResponse>(
    `${process.env.REACT_APP_URL ?? 'https://localhost:3000'}/api/clients/summary`,
    { clientRemoteIds },
    {
      headers
    }
  );

  return data;
};

export const useClientSummaries = ({ clientRemoteIds }: { clientRemoteIds: string[] }) => {
  return useQuery<ClientSummariesResponse, QueryError>(
    ['clients', { clientRemoteIds }],
    () => fetchClientSummaries({ clientRemoteIds }),
    {
      enabled: !!clientRemoteIds.length
    }
  );
};
