import React, { FC, ReactNode } from 'react';

export const OnboardingContainer: FC<{ children: ReactNode }> = ({ children }) => {
  const baseStyles = 'flex flex-col justify-start items-start bg-white w-full md:max-w-[700px]';
  const mobileStyles = 'p-[25px] h-full';
  const desktopStyles =
    'md:h-fit md:p-[50px] md:rounded-[20px] md:shadow md:border border-slate-200';

  return <div className={`${baseStyles} ${desktopStyles} ${mobileStyles}`}>{children}</div>;
};
