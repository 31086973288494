import React from 'react';
import { defineMessages } from 'src/i18n';

export const messages = defineMessages({
  perYear: {
    id: 'per.year',
    defaultMessage: '/yr'
  },
  perMonth: {
    id: 'per.month',
    defaultMessage: '/mo'
  },
  annualSublabel: {
    id: 'subscription.free.annual',
    defaultMessage: '({amount} billed annually)'
  },
  premiumUpgradeSuccessTitle: {
    id: 'subscription.premium.alert.upgradeSuccessTitle',
    defaultMessage: 'You now have a Premium subscription!'
  },
  premiumUpgradeSuccessAppLink: {
    id: 'subscription.premium.alert.upgradeSuccessAppLink',
    defaultMessage: 'Access our mobile app <link>here</link>',
    values: {
      link: (chunks) => (
        <a
          href="https://help.invoicesimple.com/en/articles/6106506-how-do-i-get-started-with-the-mobile-app-and-the-web-app"
          target="_blank"
          rel="noreferrer">
          {chunks}
        </a>
      )
    }
  },
  premiumUpgradeSuccessMessage: {
    id: 'subscription.premium.alert.upgradeSuccessMessage',
    defaultMessage: 'If you need any help please contact us: support@invoicesimple.com'
  },
  premiumPaywallTitle: {
    id: 'subscription.premium.paywall.title',
    defaultMessage: 'Upgrade to <span>Premium</span> plan to unlock',
    values: { span: (chunks) => <span className="text-orange-is">{chunks}</span> }
  },
  premiumFeature1: {
    id: 'subscription.premium.feature.1',
    defaultMessage: 'Advanced reporting'
  },
  premiumFeature2: {
    id: 'subscription.premium.feature.2',
    defaultMessage: 'iOS and Android app'
  },
  premiumFeature3: {
    id: 'subscription.premium.feature.3',
    defaultMessage: 'Real-time Notifications when clients view documents'
  },
  premiumFeature4: {
    id: 'subscription.premium.feature.4',
    defaultMessage: 'Offline mode capability'
  },
  premiumFeature5: {
    id: 'subscription.premium.feature.5',
    defaultMessage: '24/7 Support'
  },
  upgradeNowBtn: {
    id: 'subscription.premium.upgradeNow',
    defaultMessage: 'Upgrade Now'
  },
  annualPaywallTitle: {
    id: 'subscription.annual.paywall.title',
    defaultMessage: 'Switch to an <span>Annual subscription</span> and save',
    values: { span: (chunks) => <span className="text-orange-is">{chunks}</span> }
  },
  annualPaywallSubtitle: {
    id: 'subscription.annual.paywall.subtitle',
    defaultMessage: 'Save up to 20% a year and get:'
  },
  annualFeature1: {
    id: 'subscription.annual.feature1',
    defaultMessage: 'Unlimited Invoices & Estimates'
  },
  annualFeature2: {
    id: 'subscription.annual.feature2',
    defaultMessage: 'Notifications when clients view documents'
  },
  annualFeature3: {
    id: 'subscription.annual.feature3',
    defaultMessage: 'Reuse items, contacts & client details'
  },
  annualFeature4: {
    id: 'subscription.annual.feature4',
    defaultMessage: '24/7 Support'
  },
  annualFeature5: {
    id: 'subscription.annual.feature5',
    defaultMessage: 'Invoices/Estimates per month'
  },
  upgradeAnnualBtn: {
    id: 'subscription.annual.upgrade.btn',
    defaultMessage: 'Make the switch'
  },
  alreadyAnnualSub: {
    id: 'subscription.already.annual.sub',
    defaultMessage: 'You already have an <span>Annual subscription</span>!',
    values: { span: (chunks) => <span className="text-orange-is">{chunks}</span> }
  },
  successAnnualUpgrade: {
    id: 'subscription.annual.upgrade.success',
    defaultMessage: 'You now have an <span>Annual subscription</span>!',
    values: { span: (chunks) => <span className="text-orange-is">{chunks}</span> }
  },
  alreadyPremiumSub: {
    id: 'subscription.already.premium.sub',
    defaultMessage: 'You already have a <span>Premium subscription</span>!',
    values: { span: (chunks) => <span className="text-orange-is">{chunks}</span> }
  },
  premiumTitle: {
    id: 'premium.title',
    defaultMessage: 'Premium Upgrade'
  },
  premiumDescription: {
    id: 'premium.description',
    defaultMessage: 'Switch to a Premium subscription and save.'
  },
  premiumIneligible: {
    id: 'annual.ineligible',
    defaultMessage: `Unfortunately you're not eligible for an upgrade. Please contact support if you think this is incorrect.`
  },
  annaulTitle: {
    id: 'annual.title',
    defaultMessage: 'Annual Upgrade'
  },
  annualDescription: {
    id: 'annual.description',
    defaultMessage: `Switch to an annual subscription and save.`
  },
  annualIneligible: {
    id: 'annual.ineligible',
    defaultMessage: `Unfortunately you're not eligible for an upgrade. Please contact support if you think this is incorrect.`
  },
  premiumPlanTitle: {
    id: 'premium.plan.title',
    defaultMessage: 'Premium plan'
  },
  premiumIncomeInsights: {
    id: 'premium.income.insights',
    defaultMessage: 'Get more insights on your income'
  },
  premiumIncomeInsightsSubtext: {
    id: 'premium.income.insights.subtext',
    defaultMessage:
      'Unlock the ability to generate yearly breakdown of your clients and invoices billed.'
  },
  premiumFeatureList: {
    id: 'premium.feature.list',
    defaultMessage: 'Full Feature List'
  }
});
