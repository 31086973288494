import React from 'react';

type ButtonProps = React.PropsWithChildren<{
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  testId?: string;
  style?: string;
  className?: string;
}>;
export function Button({
  onClick,
  children,
  loading,
  disabled,
  testId,
  style,
  className
}: ButtonProps) {
  const onClickInternal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    onClick();
  };

  let btnStyle = '';
  switch (style) {
    case 'red':
      btnStyle =
        'bg-red-500 hover:bg-red-600 focus:ring-red-500 px-16 py-4 sm:px-32 sm:py-10 lg:px-36 lg:py-11 text-3xl';
      break;
    case 'gray':
      btnStyle =
        'bg-gray-500 hover:bg-gray-600 focus:ring-gray-500 px-16 py-4 sm:px-32 sm:py-10 lg:px-36 lg:py-11 text-3xl';
      break;
    default:
      btnStyle =
        'bg-orange-is hover:bg-orange-700 focus:ring-orange-500 px-16 py-4 sm:px-32 sm:py-10 lg:px-48 lg:py-11 text-4xl';
      break;
  }

  return (
    <button
      data-testid={testId}
      className={`${
        className || ''
      } ${btnStyle} inline-flex items-center border border-transparent font-medium rounded-xl text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition ease-in-out duration-150 ${
        loading || disabled ? 'cursor-not-allowed' : ''
      }`}
      onClick={onClickInternal}
      disabled={disabled || loading}>
      {loading && <LoadingSpinner />}
      {children}
    </button>
  );
}

export const LoadingSpinner = ({
  loadingTextStyle = 'text-white'
}: {
  loadingTextStyle?: string;
}) => {
  return (
    <svg
      data-testid="loading-spinner"
      className={`animate-spin -ml-1 mr-3 h-5 w-5 ${loadingTextStyle}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};
