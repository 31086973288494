import React from 'react';

export const GreenCheckMarkIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    data-testid="greenCheckMarkIcon"
    xmlns="http://www.w3.org/2000/svg"
    width={'14'}
    height={'14'}
    viewBox={'0 0 14 14'}
    fill="none"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29917 13.4001C10.8338 13.4001 13.6992 10.5347 13.6992 7.0001C13.6992 3.46548 10.8338 0.600098 7.29917 0.600098C3.76455 0.600098 0.89917 3.46548 0.89917 7.0001C0.89917 10.5347 3.76455 13.4001 7.29917 13.4001ZM10.2649 5.96578C10.5773 5.65336 10.5773 5.14683 10.2649 4.83441C9.95244 4.52199 9.4459 4.52199 9.13349 4.83441L6.49917 7.46873L5.46486 6.43441C5.15244 6.12199 4.6459 6.12199 4.33348 6.43441C4.02107 6.74683 4.02107 7.25336 4.33348 7.56578L5.93349 9.16578C6.2459 9.4782 6.75244 9.4782 7.06486 9.16578L10.2649 5.96578Z"
      fill="#22C55E"
    />
  </svg>
);
