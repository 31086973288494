//
// colorOptions
// - order respected in UI
// - first color = default
//

export default [
  '#FFFFFF',
  '#333333',
  '#555555',
  '#455A64',
  '#C62828',
  '#D81B60',
  '#7B1FA2',
  '#4527A0',
  '#283593',
  '#1565C0',
  '#0277BD',
  '#00695C',
  '#2E7D32',
  '#558B2F'
];
