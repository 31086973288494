import React, { useContext } from 'react';

import { PassingFeesType } from '@invoice-simple/common';

import SettingFormGroup from 'src/components/Settings/SettingFormGroup';
import { Option } from 'src/components/shared/RadioButton';
import SettingModel from 'src/models/SettingModel';
import { useStripeContext } from 'src/payments/contexts';
import { PaypalAccountContext } from 'src/payments/contexts/PaypalAccountContext';
import { useIsPaymentsEnabled } from 'src/payments/hooks/useIsPaymentsEnabled';
import { useIsPaypalRedesignFlagEnabled } from 'src/payments/hooks/useIsPaypalRedesignFlagEnabled';
import { usePaymentsUsability } from 'src/payments/hooks/usePaymentsUsability';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';

interface PaymentsEnableSurchargeProps {
  alwaysAddSurchargeSetting: SettingModel | undefined;
  alwaysAddSurchargeSettingLabel?: string;
  feesTypeSetting: SettingModel | undefined;
  transactionFeeslabel: string;
  options: Option[];
  store: AppStore;
}

const SurchargeSettingComponent = ({
  alwaysAddSurchargeSetting,
  alwaysAddSurchargeSettingLabel,
  feesTypeSetting,
  transactionFeeslabel,
  options,
  store
}: PaymentsEnableSurchargeProps) => {
  const paypalAccountContext = useContext(PaypalAccountContext);
  const isPaypalRedesignFlagEnabled = useIsPaypalRedesignFlagEnabled();
  const isPaymentsEnabled = useIsPaymentsEnabled();
  const stripeAccountContext = useStripeContext();
  const { isPaypalUsable, isStripeUsable } = usePaymentsUsability(
    store.user.settingList.currencyCode
  );

  if (!isPaymentsEnabled) {
    return null;
  }

  const showAlwaysAddSurchargeSetting =
    feesTypeSetting?.value === PassingFeesType.SURCHARGE && alwaysAddSurchargeSetting;

  const isPaypalSurchargeEligible = isPaypalUsable && paypalAccountContext?.getPaypalFees();
  const isStripeSurchargeEligible = isStripeUsable && stripeAccountContext?.getStripeFees;

  const showTransactionFeesSetting =
    (isPaypalSurchargeEligible || isStripeSurchargeEligible) && feesTypeSetting;

  if (!showTransactionFeesSetting) {
    return null;
  }
  return (
    <div id="tailwind">
      <div
        className={`${isPaypalRedesignFlagEnabled ? 'pt-3' : ''}`}
        style={{
          borderTop: isPaypalRedesignFlagEnabled ? '2px solid #eeeeee' : 'none'
        }}>
        <SettingFormGroup
          label={transactionFeeslabel}
          setting={feesTypeSetting}
          radioOptions={options}
          defaultValue={PassingFeesType.MARKUP.toString()}
        />

        {showAlwaysAddSurchargeSetting && (
          <SettingFormGroup
            label={alwaysAddSurchargeSettingLabel}
            setting={alwaysAddSurchargeSetting}
            helperText={isPaypalRedesignFlagEnabled ? 'Always add surcharge' : undefined}
            wrapperClasses={isPaypalRedesignFlagEnabled ? '-mt-6 md:mt-0' : undefined}
          />
        )}
      </div>
    </div>
  );
};
export const SurchargeSetting = injectStore(SurchargeSettingComponent);
