import React from 'react';
import { SVGProps } from 'react';

export const StarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.31122 1.40889C8.68541 0.257256 10.3147 0.257257 10.6889 1.4089L12.0258 5.52364C12.1932 6.03867 12.6731 6.38737 13.2146 6.38737H17.5411C18.7521 6.38737 19.2555 7.93689 18.2759 8.64864L14.7757 11.1917C14.3376 11.51 14.1542 12.0742 14.3216 12.5892L15.6585 16.704C16.0327 17.8556 14.7146 18.8133 13.735 18.1015L10.2348 15.5585C9.79667 15.2402 9.20342 15.2402 8.76531 15.5585L5.2651 18.1015C4.28546 18.8133 2.96736 17.8556 3.34155 16.704L4.67851 12.5892C4.84585 12.0742 4.66253 11.51 4.22442 11.1917L0.724207 8.64864C-0.255435 7.93689 0.248035 6.38737 1.45894 6.38737H5.78544C6.32697 6.38737 6.80692 6.03867 6.97426 5.52364L8.31122 1.40889Z"
        fill="#FC5B31"
      />
    </svg>
  );
};
