import React from 'react';
import { SVGProps } from 'react';

export const ClipboardCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="21"
      viewBox="0 0 15 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6.25 0.5C5.55964 0.5 5 1.05964 5 1.75C5 2.44036 5.55964 3 6.25 3H8.75C9.44036 3 10 2.44036 10 1.75C10 1.05964 9.44036 0.5 8.75 0.5H6.25Z"
        fill="#FC5B31"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.25C0 2.86929 1.11929 1.75 2.5 1.75C2.5 3.82107 4.17893 5.5 6.25 5.5H8.75C10.8211 5.5 12.5 3.82107 12.5 1.75C13.8807 1.75 15 2.86929 15 4.25V18C15 19.3807 13.8807 20.5 12.5 20.5H2.5C1.11929 20.5 0 19.3807 0 18V4.25ZM12.1339 11.3839C12.622 10.8957 12.622 10.1043 12.1339 9.61612C11.6457 9.12796 10.8543 9.12796 10.3661 9.61612L6.25 13.7322L4.63388 12.1161C4.14573 11.628 3.35427 11.628 2.86612 12.1161C2.37796 12.6043 2.37796 13.3957 2.86612 13.8839L5.36612 16.3839C5.85427 16.872 6.64573 16.872 7.13388 16.3839L12.1339 11.3839Z"
        fill="#FC5B31"
      />
    </svg>
  );
};
