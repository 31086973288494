import pickBy from 'lodash/pickBy';
import negate from 'lodash/negate';
import flowRight from 'lodash/flowRight';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

export const removeUndefinedOrNull = (subject: Record<string, any>): any =>
  pickBy(subject, (value) => negate(isUndefined)(value) && negate(isNull)(value));

export const compose = flowRight;
