import { useEffect } from 'react';
import { PaymentEventName, PaymentEvent } from '../types';
import { startPaypalOnboardingNoTerm, startPaypalOnboarding } from '../utils/apis/paypal.api';

type PaypalOnboardingPath = 'learnmore' | 'onboarding';

interface Prop {
  path: PaypalOnboardingPath;
  onError: (error: { title?: string; body?: string }) => void;
  onTrackEvent: (eventName: PaymentEvent) => void;
}

export const PaypalOnboardingLink = (props: Prop) => {
  const { path, onError, onTrackEvent } = props;
  const gotoPaypalOnboarding = async () => {
    try {
      switch (path) {
        case 'learnmore':
          onTrackEvent(PaymentEventName.staticLearnMore);
          await startPaypalOnboarding(false);
          break;
        case 'onboarding':
          onTrackEvent(PaymentEventName.staticOnboarding);
          await startPaypalOnboardingNoTerm({ isNewTab: false });
          break;
        default:
          const neverPath: never = path;
          throw Error(`${neverPath} Unknown path selected`);
      }
    } catch (error) {
      onError({ title: 'Failed to redirect to PayPal Onboarding page', body: error.message });
    }
  };
  useEffect(() => {
    gotoPaypalOnboarding();
  }, []);
  return null;
};
