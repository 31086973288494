import React, { useEffect } from 'react';
import { defineMessages, useISIntl } from 'src/i18n';

import { PaymentModalWrapper } from '../PaypalModalWrapper';
import './PaymentsUnbrandedUpgradePrompt.scss';
import { XIcon } from '../../PaymentBasicElements';
import { useUnbrandedUpgradeModal } from './useUnbrandedUpgradePrompt';
import { startPaypalOnboardingNoTerm } from 'src/payments/utils/apis/paypal.api';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { useIsPaypalRedesignFlagEnabled } from 'src/payments/hooks/useIsPaypalRedesignFlagEnabled';

const messages = defineMessages({
  title: {
    id: 'unbrandedUpgrade.prompt.title',
    defaultMessage: 'New: Payments Upgrade'
  },
  titleNew: {
    id: 'unbrandedUpgrade.prompt.titleNew',
    defaultMessage: 'New PayPal Features Available'
  },
  description1: {
    id: 'unbrandedUpgrade.prompt.description1',
    defaultMessage:
      "Now available: Apple Pay, in addition to customers' other preferred ways like VISA, Mastercard, Amex and PayPal"
  },
  description2: {
    id: 'unbrandedUpgrade.prompt.description2',
    defaultMessage: 'Streamlined credit and debit card form to save your customers time'
  },
  description3: {
    id: 'unbrandedUpgrade.prompt.description3',
    defaultMessage:
      'Coming soon: direct bank transfers, card vaulting and more with Payments upgrade'
  },
  description3New: {
    id: 'unbrandedUpgrade.prompt.description3New',
    defaultMessage: 'Coming soon: Apple Pay, Google Pay, card vaulting and more with PayPal upgrade'
  },
  upgradeForFree: {
    id: 'unbrandedUpgrade.prompt.upgradeForFree',
    defaultMessage: 'Upgrade For Free'
  },
  later: {
    id: 'unbrandedUpgrade.prompt.maybeLater',
    defaultMessage: 'Maybe Later'
  }
});

enum UnbrandedUpgradePromptActions {
  VIEWED = 'viewed',
  CLICKED = 'clicked'
}

const feature = 'payments-unbranded-upgrade-prompt';

const PaymentsUnbrandedUpgradePromptComponent = ({
  store: {
    user: {
      events: { trackButtonClick, trackModalView }
    }
  }
}: {
  store: AppStore;
}) => {
  const {
    hideUnbrandedUpgradeModal,
    shouldShowUnbrandedUpgradeModal,
    isEligibleForUnbrandedUpgradeModal,
    showUnbrandedUpgradeModal
  } = useUnbrandedUpgradeModal();
  const { ft, fta } = useISIntl();
  const isPaymentsSettingsRedesignFlagEnabled = useIsPaypalRedesignFlagEnabled();

  useEffect(() => {
    if (isEligibleForUnbrandedUpgradeModal) {
      showUnbrandedUpgradeModal();
      trackModalView({
        action: UnbrandedUpgradePromptActions.VIEWED,
        feature
      });
    }
  }, [isEligibleForUnbrandedUpgradeModal]);

  function onClickMaybeLater() {
    hideUnbrandedUpgradeModal();
    trackButtonClick({
      text: 'Maybe Later',
      action: UnbrandedUpgradePromptActions.CLICKED,
      feature
    });
  }

  function onClickXIcon() {
    hideUnbrandedUpgradeModal();
    trackButtonClick({
      text: 'X',
      action: UnbrandedUpgradePromptActions.CLICKED,
      feature
    });
  }

  function onClickOutsideModal() {
    hideUnbrandedUpgradeModal();
    trackButtonClick({
      text: 'Outside modal',
      action: UnbrandedUpgradePromptActions.CLICKED,
      feature
    });
  }

  function onSubmit() {
    trackButtonClick({
      text: 'Upgrade For Free',
      action: UnbrandedUpgradePromptActions.CLICKED,
      feature
    });
    startPaypalOnboardingNoTerm({
      upgradeToPPCP: true
    });
    hideUnbrandedUpgradeModal();
  }

  return (
    <PaymentModalWrapper
      isOpen={shouldShowUnbrandedUpgradeModal}
      onRequestClose={onClickOutsideModal}>
      <div
        className="unbranded-upgrade-modal-content"
        data-testid="unbranded-upgrade-modal-content">
        <header>
          <h3>{ft(isPaymentsSettingsRedesignFlagEnabled ? messages.titleNew : messages.title)}</h3>
          <button
            onClick={onClickXIcon}
            data-testid="unbranded-upgrade-modal-x-button"
            type="button">
            <XIcon />
          </button>
        </header>
        <main>
          <section>
            <img className="unbranded-upgrade-img" src="/images/unbranded-upgrade.png" />
          </section>

          <section className="new-features">
            <ul>
              {!isPaymentsSettingsRedesignFlagEnabled && <li>{ft(messages.description1)}</li>}
              <li>{ft(messages.description2)}</li>
              <li>
                {ft(
                  isPaymentsSettingsRedesignFlagEnabled
                    ? messages.description3New
                    : messages.description3
                )}
              </li>
            </ul>
          </section>
        </main>

        <footer className="modal-content-footer" id="tailwind">
          <button
            data-testid="unbranded-upgrade-modal-reject-button"
            onClick={onClickMaybeLater}
            type="button">
            {fta(messages.later)}
          </button>
          <button
            data-testid="unbranded-upgrade-modal-submit-button"
            className="flex justify-center items-center"
            onClick={onSubmit}
            style={{ minWidth: '97px', maxHeight: '30px' }}>
            {fta(messages.upgradeForFree)}
          </button>
        </footer>
      </div>
    </PaymentModalWrapper>
  );
};

export const PaymentsUnbrandedUpgradePrompt = injectStore(PaymentsUnbrandedUpgradePromptComponent);
