import axios from 'axios';

import { ISIntl } from 'src/i18n';
import UserModel from 'src/models/UserModel';
import { DocHistoryInfo, EventType } from './types';

export function getDisplayTextFromEventType(eventType: EventType, intl: ISIntl): string {
  switch (eventType) {
    case 'sent':
      return intl.ft({ id: 'doc.history.sent', defaultMessage: 'Sent' });
    case 'delivered':
      return intl.ft({ id: 'doc.history.delivered', defaultMessage: 'Delivered' });
    case 'opened':
      return intl.ft({ id: 'doc.history.opened', defaultMessage: 'Opened' });
    case 'signed':
      return intl.ft({ id: 'doc.history.signed', defaultMessage: 'Signed' });
    case 'dropped':
    case 'failed_to_send':
      return intl.ft({ id: 'doc.history.dropped', defaultMessage: 'Dropped' });
    default:
      return '';
  }
}

export async function getDocHistory(invoiceRemoteId: string, user: UserModel) {
  const { headers } = user.getApiReqOpts();

  const { data } = await axios.get<DocHistoryInfo[]>(
    `${
      process.env.REACT_APP_URL ?? 'https://localhost:3000'
    }/api/messages/statuses?invoiceRemoteId=${invoiceRemoteId}`,
    {
      headers
    }
  );
  return data;
}
