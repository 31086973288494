import React from 'react';
import NavItems from './NavItems';
import NavAccessItems from './NavAccessItems';
import uiStore from '../../stores/UIStore';
import { observer } from 'mobx-react';

const MobileNavItems = () => {
  const visible = uiStore.isNavMenuCollapsed ? '' : 'visible';
  return (
    <div className={`mobileMenu d-print-none ${visible}`}>
      <NavItems />
      <NavAccessItems />
    </div>
  );
};

export default observer(MobileNavItems);
