import React from 'react';

export const XIcon = () => {
  return (
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.767518 0.434266C1.07994 0.121846 1.58647 0.121846 1.89889 0.434266L5.3332 3.86858L8.76752 0.434266C9.07994 0.121846 9.58647 0.121846 9.89889 0.434266C10.2113 0.746685 10.2113 1.25322 9.89889 1.56564L6.46457 4.99995L9.89889 8.43427C10.2113 8.74669 10.2113 9.25322 9.89889 9.56564C9.58647 9.87806 9.07994 9.87806 8.76752 9.56564L5.3332 6.13132L1.89889 9.56564C1.58647 9.87806 1.07994 9.87806 0.767518 9.56564C0.455098 9.25322 0.455098 8.74669 0.767518 8.43427L4.20183 4.99995L0.767518 1.56564C0.455098 1.25322 0.455098 0.746685 0.767518 0.434266Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};
