import React from 'react';
import { SVGProps } from 'react';

export const CameraIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3.19971C1.61929 3.19971 0.5 4.319 0.5 5.69971V15.6997C0.5 17.0804 1.61929 18.1997 3 18.1997H18C19.3807 18.1997 20.5 17.0804 20.5 15.6997V5.69971C20.5 4.319 19.3807 3.19971 18 3.19971H16.0178C15.6862 3.19971 15.3683 3.06801 15.1339 2.83359L13.7322 1.43194C13.2634 0.963099 12.6275 0.699707 11.9645 0.699707H9.03553C8.37249 0.699707 7.73661 0.963098 7.26777 1.43194L5.86612 2.83359C5.6317 3.06801 5.31375 3.19971 4.98223 3.19971H3ZM10.5 14.4497C12.5711 14.4497 14.25 12.7708 14.25 10.6997C14.25 8.62864 12.5711 6.94971 10.5 6.94971C8.42893 6.94971 6.75 8.62864 6.75 10.6997C6.75 12.7708 8.42893 14.4497 10.5 14.4497Z"
        fill="#FF5721"
      />
    </svg>
  );
};
