import React, { Fragment } from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISIntl } from 'src/i18n';
import UserModel, { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { titleizeAll } from 'src/util/Titleize';
import { LoadingSpinner } from '../../Button';
import { useUpgrade } from '../hooks/useUpgrade';
import { messages } from '../utils/messages';

const tiers: SubscriptionTier[] = [
  SubscriptionTier.STARTER,
  SubscriptionTier.PRO,
  SubscriptionTier.PREMIUM
];

type FeatureTiers = {
  [tier in SubscriptionTier]?: boolean | string;
};

type FeaturesMap = {
  name: string;
  tiers: FeatureTiers;
};

interface SectionsMap {
  name: string;
  features: FeaturesMap[];
}

interface TieredMatrixProps {
  store: AppStore;
  intl: ISIntl;
  selectedInterval: Cadence;
  shouldDisallowSwitch: (destinationTier: SubscriptionTier, user: UserModel) => boolean;
}

export const TieredMatrixList = ({
  store,
  intl,
  selectedInterval,
  shouldDisallowSwitch
}: TieredMatrixProps) => {
  const { user } = store;
  const { fta, ft } = intl;

  const cancelAnytime = fta(messages.cancelAnytime);
  const support = fta(messages.support);
  const starterLimit = ft(messages.tieredStarterDocLimit, {
    starterQuota: user.tierOneMonthlyQuota
  });
  const unlimitedInvoices = ft(messages.unlimitedDocLimit);
  const accessToMobileApps = fta(messages.accessToMobileApps);
  const invoicesOrEstimates = ft(messages.invoicesOrEstimates);

  const sections: SectionsMap[] = [
    {
      name: ft(messages.tieredFeatures),
      features: [
        {
          name: invoicesOrEstimates,
          tiers: { starter: starterLimit, pro: unlimitedInvoices, premium: unlimitedInvoices }
        },
        { name: support, tiers: { starter: true, pro: true, premium: true } },
        { name: cancelAnytime, tiers: { starter: true, pro: true, premium: true } },
        { name: accessToMobileApps, tiers: { premium: true } },
        { name: 'Reports', tiers: { premium: true } }
      ]
    }
  ];

  const { switching, loadingCheckout, onUpgradeClick, OriginalPrice, FinalPrice, isCurrentPlan } =
    useUpgrade({
      store,
      intl,
      selectedInterval,
      shouldDisallowSwitch
    });

  const loading = loadingCheckout || switching;

  return (
    <div>
      <div className="mx-auto py-16 sm:py-24 lg:px-8">
        {/* md+ */}
        <div className="hidden md:block">
          <table className="h-px w-full table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead className="border-bottom border-gray-200">
              <tr>
                <th className="px-6 pb-4 text-left text-2xl font-medium text-gray-900" scope="col">
                  <span className="sr-only">Feature by</span>
                  <span>{ft(messages.tieredPlans)}</span>
                </th>
                {tiers.map((tier) => (
                  <th
                    key={tier}
                    className="w-1/4 px-6 pb-4 text-left text-2xl font-medium text-gray-900"
                    scope="col">
                    {titleizeAll(tier)}
                    {isCurrentPlan(tier) && (
                      <span className="ml-3 font-semibold text-orange-is text-xl">
                        {fta(messages.currentActivePlan)}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 border-t border-gray-200">
              <tr>
                <th
                  className="py-8 px-6 text-left align-top text-xl font-medium text-gray-900"
                  scope="row">
                  {ft(messages.tieredPricing)}
                </th>
                {tiers.map((tier) => (
                  <td
                    key={tier}
                    data-testid={`${tier}-tier-details`}
                    className="h-full py-8 px-6 align-top">
                    <div className="relative table h-full">
                      <p className="mb-20 w-max">
                        <span className="text-4xl font-extrabold tracking-tight text-gray-900">
                          <span className="font-bold">
                            {selectedInterval === Cadence.ANNUAL && <OriginalPrice tier={tier} />}
                          </span>
                          <FinalPrice tier={tier} isTieredMatrix={true} />
                        </span>{' '}
                        <span className="text-xl font-medium text-gray-500">
                          {selectedInterval === Cadence.ANNUAL ? '/yr' : '/mo'}
                        </span>
                      </p>
                      <button
                        onClick={() => onUpgradeClick(tier)}
                        data-testid={`btn-upgrade-tier-${tier}`}
                        className={`absolute no-underline bottom-0 block w-full border-none flex-grow rounded-md bg-orange-is py-[6px] text-center text-xl font-semibold text-white hover:bg-orange-700 ${
                          loading || shouldDisallowSwitch(tier, user)
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                        }`}>
                        {loading && <LoadingSpinner loadingTextStyle="text-white" />}
                        Buy {titleizeAll(tier)}
                      </button>
                    </div>
                  </td>
                ))}
              </tr>
              {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="bg-gray-100 border-top border-bottom border-gray-200 py-3 pl-6 text-left text-xl font-medium text-gray-900"
                      colSpan={4}
                      scope="colgroup">
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name} className="border-bottom border-gray-200">
                      <th
                        className="py-4 px-6 text-left text-lg font-normal text-gray-800"
                        scope="row">
                        {feature.name}
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier} className="py-4 px-6">
                          {typeof feature.tiers[tier] === 'string' ? (
                            <span className="block text-lg text-gray-800">
                              {feature.tiers[tier]}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[tier] === true ? (
                                <FontAwesomeIcon
                                  className="h-5 w-5 text-orange-is"
                                  aria-hidden="true"
                                  icon="check"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                  icon="minus"
                                />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier] === true ? 'Included' : 'Not included'} in{' '}
                                {tier}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        {/* xs to md */}
        <div className="space-y-16 block md:hidden border border-gray-200 pt-5 rounded-lg">
          {tiers.map((tier) => (
            <section key={tier}>
              <div className="mb-8 px-6 sm:px-4">
                <h2 className="text-4xl font-medium leading-6 text-gray-900">
                  {titleizeAll(tier)}
                  {isCurrentPlan(tier) && (
                    <span className="ml-3 font-semibold text-orange-is text-3xl">
                      {fta(messages.currentActivePlan)}
                    </span>
                  )}
                </h2>
                <p className="mt-4">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    <span className="font-bold">
                      {selectedInterval === Cadence.ANNUAL && <OriginalPrice tier={tier} />}
                    </span>
                    <FinalPrice tier={tier} isTieredMatrix={true} />
                  </span>{' '}
                  <span className="text-2xl font-medium text-gray-500">
                    {selectedInterval === Cadence.ANNUAL ? '/yr' : '/mo'}
                  </span>
                </p>
                <p className="mt-4 text-sm text-gray-500"></p>
                <button
                  onClick={() => onUpgradeClick(tier)}
                  className={`mt-6 block w-full rounded-md border-none bg-orange-is py-3 text-center text-2xl font-semibold text-white hover:bg-orange-700 ${
                    loading || shouldDisallowSwitch(tier, user)
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}>
                  {loading && <LoadingSpinner loadingTextStyle="text-white" />}
                  Buy {titleizeAll(tier)}
                </button>
              </div>

              {sections.map((section) => (
                <table key={section.name} className="w-full">
                  <tr>
                    <th
                      className="bg-gray-200 border-top border-bottom border-gray-200 py-3 pl-6 text-left text-2xl font-medium text-gray-900"
                      colSpan={4}
                      scope="colgroup">
                      {section.name}
                    </th>
                  </tr>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {section.features.map((feature) => (
                      <tr key={feature.name} className="border-top border-gray-200 border-bottom">
                        <th
                          className="py-5 pl-6 text-left text-xl sm:text-2xl font-normal text-gray-800"
                          scope="row">
                          {feature.name}
                        </th>
                        <td className="py-5 pr-6 text-right">
                          {typeof feature.tiers[tier] === 'string' ? (
                            <span className="block text-xl sm:text-2xl text-gray-800">
                              {feature.tiers[tier]}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[tier] === true ? (
                                <FontAwesomeIcon
                                  className="ml-auto h-6 w-6 text-orange-is"
                                  aria-hidden="true"
                                  icon="check"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ml-auto h-6 w-6 text-gray-400"
                                  aria-hidden="true"
                                  icon="minus"
                                />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier] === true ? 'Yes' : 'No'}
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};
