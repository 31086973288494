import React from 'react';
import { useISIntl } from 'src/i18n/utils';
import { ClientsGroup } from './ReportTypes';
import { messages } from './ReportMessages';
import FilterButton from './FilterButton';
import ReportModel from 'src/models/ReportModel';

interface Props {
  list: ReportModel;
}

const ClientsReport = ({ list }: Props) => {
  const intl = useISIntl();
  const { ft } = intl;
  const groups = list.reports.groups as ClientsGroup[];

  return (
    <>
      <FilterButton list={list} />
      <div className="client-report-list-body panel">
        <table className="table">
          <thead className="thead">
            <tr>
              <th></th>
              <th style={{ width: '75px' }}>{ft(messages.invoices)}</th>
              <th style={{ width: '130px' }}>{ft(messages.paid)}</th>
            </tr>
          </thead>
          <tbody>
            {groups?.map((group, idg) => {
              return (
                <React.Fragment key={`clients-report-body-${idg}`}>
                  <tr key={`clients-report-year-${idg}`} className="year">
                    <td>{group.year}</td>
                    <td>{group.count}</td>
                    <td>{group.totalFmt}</td>
                  </tr>
                  {group.clients.map((client, idm) => {
                    return (
                      <tr key={`clients-report-year-${idg}-month-${idm}`}>
                        <td>{client.client.name}</td>
                        <td className="month">{client.count}</td>
                        <td className="month">{client.totalFmt}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ClientsReport;
