import { DocType } from '@invoice-simple/common';
import { Document } from '@invoice-simple/domain-invoicing';
import { QueryFunctionContext, QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import UserModel from 'src/models/UserModel';

export interface SearchDocumentsResponse {
  documents: Document[];
}

interface QueryError {
  message: string;
}

export interface SearchDocsParams {
  searchTerm?: string;
  docType?: DocType;
}

export const fetchDocs = async ({
  queryKey
}: QueryFunctionContext): Promise<SearchDocumentsResponse> => {
  const { headers } = UserModel.getInstance().getApiReqOpts();

  const [_, searchTerm, docType] = queryKey;

  const { data } = await axios.get<SearchDocumentsResponse>(
    `${process.env.REACT_APP_URL ?? 'https://localhost:3000'}/api/docs/search`,
    {
      headers,
      params: {
        q: searchTerm,
        docType
      }
    }
  );

  return data;
};

export const useSearchDocuments = (
  { searchTerm, docType }: SearchDocsParams,
  options?: UseQueryOptions<SearchDocumentsResponse, QueryError, SearchDocumentsResponse, QueryKey>
) => {
  return useQuery<SearchDocumentsResponse, QueryError>(
    ['documentsSearch', searchTerm, docType],
    fetchDocs,
    {
      enabled: !!searchTerm,
      ...options
    }
  );
};
