import React from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { trackBingEvent } from 'src/analytics/bing';
import { trackFacebookEvent } from 'src/analytics/facebook';
import { trackGoogleAdsConversion } from 'src/analytics/google';
import { getSMSCheckoutDataFromToken } from 'src/apis/accountAPI';
import { sendPremiumSubscriptionEmail } from 'src/apis/emailAPI';
import { maybeGetOldTierFromNewTier } from 'src/components/SubscriptionPaywall/utils';
import { AlertBodyWithAppLink } from 'src/components/Upgrades/UpgradeSection';
import { ISIntl } from 'src/i18n/utils';
import alertModel from 'src/models/AlertModel';
import location from 'src/models/LocationModel';
import UserModel, { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { getStripeCheckoutSession, getStripeCustomerFromSession } from 'src/util/stripeCheckout';
import titleize from 'src/util/Titleize';
import { URLQueryParamKeys } from 'src/util/url';
import { SubscriptionTrackerStore } from '.';
import { messages } from './messages';

export function handleNewPurchaseSuccess(
  sessionId: string,
  formatMessage: ISIntl['formatMessage'],
  store: AppStore
) {
  const tier = store.user.lastPaidWebSub?.tier ?? undefined;
  let cadence: Cadence = Cadence.MONTHLY;

  // Is this an annual tiered subscription?
  const isAnnualSub: boolean = store.user.lastPaidWebSub?.planInterval === 'annual';
  // Is there "annual" on the SKU name?
  const isAnnualSku: boolean = !!store.user.lastPaidWebSub?.orderSku?.includes('annual');

  if (isAnnualSub || isAnnualSku) {
    cadence = Cadence.ANNUAL;
  }

  const getPurchaseAlertTitle = (): string => {
    return tier
      ? formatMessage(messages.alertTierPurchaseTitle, {
          tier: titleize(maybeGetOldTierFromNewTier(tier))
        })
      : formatMessage(messages.alertPurchaseTitle);
  };

  const getPurchaseAlertBody = ({
    message,
    messageOptions,
    showAppLink = false
  }: {
    message: {
      id: string;
      defaultMessage: string;
    };
    messageOptions?: Record<string, string>;
    showAppLink?: boolean;
  }): React.ReactNode => {
    const defaultMessage = messageOptions
      ? formatMessage(message, messageOptions)
      : formatMessage(message);

    if (showAppLink) {
      return React.createElement(AlertBodyWithAppLink, {
        subMessage: defaultMessage
      });
    }

    return defaultMessage;
  };

  return getStripeCheckoutSession(sessionId).then(async (checkoutSession) => {
    const purchaseAlertTitle = getPurchaseAlertTitle();
    const {
      customer_details: { email },
      metadata
    } = checkoutSession;
    const couponSku = metadata?.couponSku;
    const couponName = metadata?.couponName;
    const commonPurchaseParams: Omit<
      Parameters<typeof store.subscriptionPurchased>[0],
      'eventName'
    > = {
      email,
      tier,
      cadence,
      couponSku
    };

    trackGoogleAdsConversion();

    switch (true) {
      case location.isSignup:
        alertModel.setAlert(
          'success',
          purchaseAlertTitle,
          getPurchaseAlertBody({
            message: messages.alertPurchaseBodySignup,
            messageOptions: { email }
          })
        );
        store.subscriptionPurchased({
          ...commonPurchaseParams,
          eventName: 'subscription-purchase-signup'
        });

        location.history.push('/signup');
        break;

      case location.isLogin:
        alertModel.setAlert(
          'success',
          purchaseAlertTitle,
          getPurchaseAlertBody({
            message: messages.alertPurchaseBodyLogin
          })
        );
        store.subscriptionPurchased({
          ...commonPurchaseParams,
          eventName: 'subscription-purchase-login'
        });

        location.history.push('/login');
        break;

      case location.isInvoicesList:
        alertModel.setAlert(
          'success',
          purchaseAlertTitle,
          getPurchaseAlertBody({
            message: messages.alertPurchaseBody,
            showAppLink: tier === SubscriptionTier.PRO
          })
        );
        store.subscriptionPurchased({
          ...commonPurchaseParams,
          eventName: 'subscription-purchase-invoices'
        });

        if (store.user.isAbTestVariant('subscription-payments-modal')) {
          SubscriptionTrackerStore.showPaypalBox = true;
        }

        if (couponName === 'referral50off3months') {
          location.history.push(
            `/invoices?${URLQueryParamKeys.REF}=${URLQueryParamKeys.REFERRAL_PURCHASE}`
          );
          break;
        }

        location.history.push('/invoices');
        break;
      default:
        break;
    }
    // send an email to new premium tier purchases
    if (store.user.isSubTier(SubscriptionTier.PREMIUM)) {
      await store.sendPremiumSubEmail(email);
    }

    const sub = store.user.lastPaidWebSub;
    if (sub) {
      const subAmount = store.user.getGeoSubAmount({
        tier: sub.tier,
        cadence: sub.isMonthly ? Cadence.MONTHLY : Cadence.ANNUAL,
        usdPrice: true
      });
      mntnPurchasePixel(sub.orderId, subAmount);
      trackBingEvent('purchase', {
        revenue_value: subAmount,
        currency: store.user.geoSubCurrencyCode
      });
      trackFacebookEvent('track', 'Purchase', {
        value: subAmount,
        currency: store.user.geoSubCurrencyCode
      });
    }
  });
}

export function handleNewPurchaseFailure(formatMessage: any) {
  alertModel.setAlert(
    'danger',
    formatMessage(messages.purchaseFailedAlertTitle),
    formatMessage(messages.purchaseFailedAlertBody)
  );
}

export async function handleNewSMSCampaignPurchase(params: {
  smsToken: string;
  sessionId: string;
  user: UserModel;
  store: AppStore;
  formatMessage: ISIntl['formatMessage'];
}): Promise<void> {
  const { smsToken, sessionId, user, store, formatMessage } = params;

  // Simultaneously get the stripe customer and the SMS checkout data
  const [tokenCheck, sessionCheck] = await Promise.all([
    getSMSCheckoutDataFromToken(user, smsToken),
    getStripeCustomerFromSession(sessionId)
  ]);

  // If the token check fails, treat it as a failed purchase
  if (!tokenCheck) {
    handleNewPurchaseFailure(formatMessage);
    return;
  }

  // If the user already has a login, just redirect them to the login page
  if (!tokenCheck.isAnonymous) {
    store.nav('login');
  }

  // Render the success alert message
  const email = sessionCheck?.email;
  const alertTitle = formatMessage(messages.alertTierPurchaseTitle, { tier: 'Premium' });
  const alertMessage = formatMessage(
    tokenCheck.isAnonymous ? messages.alertPurchaseBodySignupNoEmail : messages.alertPurchaseBody
  );
  alertModel.setAlert('success', alertTitle, alertMessage);

  // Register events
  store.subscriptionPurchased({
    eventName: 'subscription-purchase-signup',
    email,
    tier: SubscriptionTier.PREMIUM,
    cadence: Cadence.MONTHLY
  });

  // Campaign is available only for premium tier - send email
  sendPremiumSubscriptionEmail({ email, accountId: tokenCheck.accountId, firstName: undefined });
}

function mntnPurchasePixel(orderId: string, subAmount: number) {
  const env = process.env.REACT_APP_ENV;
  const isProd = !!env && /production/i.test(env);
  if (!isProd || subAmount <= 0) {
    return;
  }

  const orderTotal = (subAmount / 100).toString();

  let p, q, m;

  const o = '37238',
    l = orderId,
    i = orderTotal,
    c = '',
    k = '',
    g = '',
    j = '',
    u = '',
    shadditional = '';

  try {
    p =
      top && top.document.referrer !== ''
        ? encodeURIComponent(top.document.referrer.substring(0, 512))
        : '';
  } catch (n) {
    p = document.referrer !== null ? document.referrer.toString().substring(0, 512) : '';
  }
  try {
    q =
      window && window.top && document.location && window.top.location === document.location
        ? document.location
        : window && window.top && window.top.location
          ? window.top.location
          : document.location;
  } catch (b) {
    q = document.location;
  }
  try {
    m =
      parent.location.href !== ''
        ? encodeURIComponent(parent.location.href.toString().substring(0, 512))
        : '';
  } catch (z) {
    try {
      m = q !== null ? encodeURIComponent(q.toString().substring(0, 512)) : '';
    } catch (h) {
      m = '';
    }
  }
  let A,
    w = '';

  const y = document.createElement('script'),
    v = document.getElementsByTagName('script'),
    t = Number(v.length) - 1,
    r = document.getElementsByTagName('script')[t];
  if (typeof A === 'undefined') {
    A = Math.floor(Math.random() * 100000000000000000);
  }
  w =
    'dx.mountain.com/spx?conv=1&shaid=' +
    o +
    '&tdr=' +
    p +
    '&plh=' +
    m +
    '&cb=' +
    A +
    '&shoid=' +
    l +
    '&shoamt=' +
    i +
    '&shocur=' +
    c +
    '&shopid=' +
    k +
    '&shoq=' +
    g +
    '&shoup=' +
    j +
    '&shpil=' +
    u +
    shadditional;
  y.type = 'text/javascript';
  y.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + w;
  r.parentNode?.insertBefore(y, r);
}
