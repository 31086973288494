import { reaction, IReactionDisposer } from 'mobx';

import intercom from 'src/analytics/intercom';
import UserModel from 'src/models/UserModel';
import { default as SettingModel } from 'src/models/SettingModel';
import environmentStore from 'src/stores/EnvironmentStore';
import { SETTING_LABELS } from 'src/data/settingsDisplayTypes';

export const DEBOUNCE_RATE = 2000;

const intercomTrackedSettings = [
  SETTING_LABELS.COMPANY_NAME,
  SETTING_LABELS.COMPANY_BUSINESS_NUMBER,
  SETTING_LABELS.COMPANY_EMAIL,
  SETTING_LABELS.COMPANY_PHONE,
  SETTING_LABELS.COMPANY_LOGO
];

const getIntercomPayload = (userData, changedSetting) => {
  const { name, value } = changedSetting;
  switch (name) {
    case SETTING_LABELS.COMPANY_NAME:
      return {
        ...userData,
        company: {
          ...userData.company,
          name: value
        }
      };
    case SETTING_LABELS.COMPANY_EMAIL:
      if (!userData.email || userData.email === '') {
        userData.email = value;
      }
      return {
        ...userData,
        company: {
          ...userData.company,
          email: value,
          'Invoice email': value
        }
      };
    case SETTING_LABELS.COMPANY_PHONE:
      return {
        ...userData,
        phone: value,
        company: {
          ...userData.company,
          phone: value
        }
      };
    case SETTING_LABELS.COMPANY_LOGO: {
      return {
        ...userData,
        avatar: {
          type: 'avatar',
          image_url: `${environmentStore.assetsURL}/account/${userData.company.company_id}/photo/${value}.jpg`
        }
      };
    }
    default:
      break;
  }
};

const getModifiedSetting = (setting) => () => {
  if (intercomTrackedSettings.indexOf(setting.name) !== -1) {
    return {
      name: setting.name,
      value: setting.value
    };
  }
  return;
};

const triggerReaction = (user) => (changedSetting, thisReaction) => {
  if (!changedSetting) {
    return thisReaction.dispose();
  }
  intercom('update', getIntercomPayload(user.forIntercom(), changedSetting));
};

export const createSettingIntercomSyncReaction = (
  user: UserModel,
  setting: SettingModel
): IReactionDisposer =>
  reaction(getModifiedSetting(setting), triggerReaction(user), {
    delay: DEBOUNCE_RATE
  });
