import React from 'react';

import { CheckIcon } from 'src/components/Icons';
import { useISIntl } from 'src/i18n';
import { smoothScrollById } from 'src/util/smoothScroll';
import { messages } from '../../../utils';

interface MobileFeatureListProps {
  features: string[];
}

export const CardFeatureList = ({ features }: MobileFeatureListProps) => {
  const { ft } = useISIntl();

  return (
    <div className="flex flex-col gap-3 mt-3">
      {features.map((feature: string) => (
        <div key={feature} className="flex flex-row items-center gap-2">
          <CheckIcon className="w-7 h-7 text-gray-800" strokeWidth={3} />

          <p className="text-2xl">{feature}</p>
        </div>
      ))}
      <div
        className="cursor-pointer w-fit text-gray-950 mt-2 text-xl font-bold"
        style={{ borderBottom: '1px solid' }}
        onClick={(e) => {
          smoothScrollById('feature-matrix');
          e.stopPropagation();
        }}>{`${ft(messages.compareAllPlans)} >`}</div>
    </div>
  );
};
