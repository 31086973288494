import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimes,
  faCheck,
  faUndo,
  faTrash,
  faChevronUp,
  faChevronDown,
  faSpinner,
  faExclamationCircle,
  faCheckCircle,
  faCheckSquare,
  faSquare,
  faLock,
  faSyncAlt,
  faPlus,
  faBars,
  faImage,
  faCloudUploadAlt,
  faArrowRight,
  faCrown,
  faCookieBite,
  faComment,
  faMinus
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faComment,
  faCookieBite,
  faCrown,
  faArrowRight,
  faCloudUploadAlt,
  faImage,
  faBars,
  faPlus,
  faTimes,
  faCheck,
  faUndo,
  faTrash,
  faChevronUp,
  faChevronDown,
  faSpinner,
  faExclamationCircle,
  faCheckCircle,
  faCheckSquare,
  faSquare,
  faLock,
  faSyncAlt,
  faMinus
);
