import { useEffect } from 'react';

import { AppStore } from 'src/stores/AppStore';
import { BaseURLQueryParamKeys } from 'src/util/url';

import { useEligiblePaymentProviders } from './useEligiblePaymentProviders';
import { usePaymentsLoaded } from './usePaymentsLoaded';

export const useTrackPaymentIntentInitialSetup = ({
  store: { user, location }
}: {
  store: AppStore;
}) => {
  const paymentsLoaded = usePaymentsLoaded();
  const eligiblePaymentProviders = useEligiblePaymentProviders();

  useEffect(() => {
    if (!paymentsLoaded) return;
    if (location.isSetting && location.search) {
      const urlParams = new URLSearchParams(location.search);
      const origin = urlParams.get(BaseURLQueryParamKeys.ORIGIN);

      if (origin === 'paymentIntentRequest') {
        user.events.trackAction('client-online-payments-request-initialSetup', {
          eligiblePaymentProviders
        });
        if (user.isGuest) {
          return location.navigateTo(`/login?${BaseURLQueryParamKeys.RETURN_PATH}=settings`);
        }
      }
    }
  }, [paymentsLoaded, eligiblePaymentProviders]);
};
