import React, { FormEvent, useState } from 'react';
import { Modal } from './Modal';
import { getCookie, setCookie } from 'src/util/cookie';
import environmentStore from 'src/stores/EnvironmentStore';

export const TermsAcknowledgement = () => {
  const hasAcknowledgedTerms = getCookie('hasAcknowledgedTerms') === 'true';
  const [isOpen, setIsOpen] = useState(!hasAcknowledgedTerms);

  const handleAcknowledgeTerms = async (e: FormEvent) => {
    e.preventDefault();
    setCookie('hasAcknowledgedTerms', true);
    setIsOpen(false);
  };

  if (hasAcknowledgedTerms || !isOpen || environmentStore.isSnapshot()) {
    return null;
  }

  return <Modal handleAcknowledgeTerms={handleAcknowledgeTerms} isOpen={isOpen} />;
};
