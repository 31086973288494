import React from 'react';
import { observer } from 'mobx-react';
import type InvoiceModel from 'src/models/InvoiceModel';
import { PaymentDeposit } from 'src/payments';
import { DepositAmounts, DepositRates, DepositTypes } from '@invoice-simple/common';

const InvoicePaymentDepositObserver = observer(PaymentDeposit);

type Props = {
  invoice: InvoiceModel;
};

export const InvoicePaymentDeposit = observer(({ invoice }: Props) => (
  <InvoicePaymentDepositObserver
    document={{
      docType: invoice.docType,
      paymentSuppressed: invoice.paymentSuppressed,
      stripePaymentSuppressed: invoice.stripePaymentSuppressed,
      currencyCode: invoice.currencyCode,
      invoiceId: invoice.id,
      paymentsCount: invoice.payments.length
    }}
    onComponentShown={(isEligibleForDeposit) => {
      trackEventDepositShown(isEligibleForDeposit, invoice);
    }}
    onInvoiceDepositUpdate={(updateDeposit) => invoice.updatePaymentDeposit(updateDeposit)}
    deposit={{
      depositType: invoice.setting.depositType || DepositTypes.NONE,
      depositRate: invoice.setting.depositRate || DepositRates.DEPOSIT_NONE,
      depositAmount: invoice.setting.depositAmount || DepositAmounts.DEPOSIT_NONE
    }}
  />
));

export const trackEventDepositShown = (isEligibleForDeposit: boolean, invoice: InvoiceModel) => {
  const eventName = isEligibleForDeposit
    ? 'payments-webDepositEnabled-shown'
    : 'payments-webDepositDisabled-shown';
  invoice.user.trackAppEventViaApi(eventName, {
    invoiceId: invoice.id
  });
};
