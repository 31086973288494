import React from 'react';
import InlineButton from './InlineButton';
import { observer } from 'mobx-react';
import invoiceInputFieldsUIStore from '../../stores/InvoiceInputFieldsUIStore';

interface Props {
  isHidden: boolean;
  onClick: () => void;
  label: string;
  selector?: string;
}
@observer
export default class ShowOnDemand extends React.Component<Props, {}> {
  componentWillUnmount() {
    invoiceInputFieldsUIStore.reset();
  }
  render() {
    const { onClick, label, isHidden, selector = '' } = this.props;
    if (isHidden) {
      return null;
    }
    return (
      <InlineButton
        icon="plus"
        onClick={onClick}
        label={label}
        data-selector={`show-on-demand-button-${selector}`}
      />
    );
  }
}
