import React from 'react';
import classNames from 'classnames';

import { CaptureStatus } from './PaymentsOrder.types';

const captureStatusDisplay: Record<CaptureStatus, string> = {
  COMPLETED: 'Paid',
  REFUNDED: 'Refunded',
  PARTIALLY_REFUNDED: 'Partially Refunded',
  PENDING: 'Pending'
};

export function PaymentsOrderStatus({ status }: { status: CaptureStatus }) {
  return (
    <span
      className={classNames('payments-order-status', {
        paid: status === CaptureStatus.COMPLETED,
        refunded: status === CaptureStatus.REFUNDED
      })}>
      {captureStatusDisplay[status]}
    </span>
  );
}
