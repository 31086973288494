import React from 'react';
import { defineMessages, useISIntl } from 'src/i18n';

const messages = defineMessages({
  rowEmpty: {
    id: 'invoice.list.row.empty',
    defaultMessage: 'You have no {pluralDocType}'
  },
  rowEmptySearch: {
    id: 'invoice.list.row.empty.search',
    defaultMessage: 'No {pluralDocType} match this search.'
  },
  rowButtonNew: {
    id: 'invoice.list.row.button.new',
    defaultMessage: 'add your first {docType} today.'
  }
});

interface Props {
  onCreate: () => void;
  hasSearchResults: boolean;
  query: string;
}

export const EmptyRow = ({ onCreate, hasSearchResults, query }: Props) => {
  const { f, getLocalizedDocumentType } = useISIntl();

  return (
    <tr className="invoice-row-empty">
      <td colSpan={6}>
        {!hasSearchResults && !!query ? (
          <span>{f(messages.rowEmptySearch)}</span>
        ) : (
          <>
            <span>{f(messages.rowEmpty)}</span>
            <span>
              ,
              <a
                className={`btn-link btn-link-${getLocalizedDocumentType().toLowerCase()}-new`}
                onClick={onCreate}>
                {f(messages.rowButtonNew)}
              </a>
            </span>
          </>
        )}
      </td>
    </tr>
  );
};
