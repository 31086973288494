import { useFlags } from 'flagsmith/react';
import { useContext, useMemo, useState } from 'react';
import { PaypalAccountContext } from 'src/payments/contexts/PaypalAccountContext';
import { ModalName, useModalManagerContext } from '../../components/ModalManagerContext';

export enum FeatureFlagName {
  UnbrandedUpgradePrompt = 'payments_unbranded_upgrade_prompt'
}

export enum PaymentsModalLocalStorageKey {
  UnbrandedUpgradePrompt = 'payment-unbranded-upgrade-last-shown',
  SurchargePromoModal = 'payment-surcharge-promo-last-shown'
}

interface ModalConfig {
  modalName: ModalName;
  featureFlagName?: FeatureFlagName;
  localStorageKey?: PaymentsModalLocalStorageKey;
  isEligibleForModal: () => boolean;
}

interface UsePaymentsModal {
  shouldShowPaymentsModal: boolean;
  showPaymentsModal: () => void;
  hidePaymentsModal: () => void;
  isEligibleForPaymentsModal: boolean;
}

interface UsePaymentsModalParams {
  modalConfig: ModalConfig;
  initialState: boolean;
}

export const usePaymentsModal = ({
  modalConfig,
  initialState
}: UsePaymentsModalParams): UsePaymentsModal => {
  const { modalName, featureFlagName, localStorageKey, isEligibleForModal } = modalConfig;

  const [shouldShowPaymentsModal, setShouldShowPaymentModal] = useState<boolean>(initialState);

  let isFeatureFlagEnabled = true;
  if (featureFlagName) {
    const featureFlag = useFlags([featureFlagName]);
    isFeatureFlagEnabled = featureFlag && featureFlag[featureFlagName].enabled;
  }
  const paypalAccountContext = useContext(PaypalAccountContext);
  const { modalNameVisible, setModalNameVisible } = useModalManagerContext();

  const isEligibleForPaymentsModal = useMemo(() => {
    return !!(
      modalNameVisible === ModalName.None &&
      isEligibleForModal() &&
      isFeatureFlagEnabled &&
      !getPaymentsModalLastShown()
    );
  }, [paypalAccountContext, isFeatureFlagEnabled]);

  function getPaymentsModalLastShown() {
    if (localStorageKey) {
      return localStorage.getItem(localStorageKey);
    }
    return null;
  }

  function setPaymentsModalLastShown() {
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, new Date().toString());
    }
  }

  function showPaymentsModal() {
    setModalNameVisible(modalName);
    setPaymentsModalLastShown();
    setShouldShowPaymentModal(true);
  }

  function hidePaymentsModal() {
    setModalNameVisible(ModalName.None);
    setShouldShowPaymentModal(false);
  }

  return {
    shouldShowPaymentsModal,
    showPaymentsModal,
    hidePaymentsModal,
    isEligibleForPaymentsModal
  };
};
