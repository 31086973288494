import React, { FC } from 'react';
import { usePaypalContext } from 'src/payments/contexts/PaypalAccountContext';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import {
  PaypalGlobalPaymentToggleBox,
  PaypalInvoicePaymentToggleBox,
  PaypalCurrencyMismatchBox
} from './SupportingComponents';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { getPaymentEventSource, hasCurrencyMismatch } from 'src/payments/utils';
import InvoiceCurrencyMismatchFooter from '../../Common/InvoiceCurrencyMismatchFooter';

export const PaypalInvoiceAcceptingBoxComponent: FC<{ store: AppStore }> = ({ store }) => {
  const paypalAccountContext = usePaypalContext();

  if (!paypalAccountContext) {
    return null;
  }
  const paypalSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource()
  };
  const { isPaypalEnabled, updatePaypalEnabled, getPaypalAccountCurrency } = paypalAccountContext;

  const paypalStatusEnabled = isPaypalEnabled();

  const { user, doc: invoice } = store;
  const paypalPrimaryCurrency = getPaypalAccountCurrency();

  const isPaypalCurrencyMismatch = hasCurrencyMismatch(
    paypalPrimaryCurrency,
    user.settingList.currencyCode
  );

  const activatePayments = async () => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.tileGlobalEnabled
    });
    await updatePaypalEnabled(true);
    if (invoice.paymentSuppressed) {
      invoice.setPaymentSuppressed(false);
    }
  };

  const updatePaypalPaymentSuppressed = (currentValue: boolean) => {
    user.events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: !currentValue
        ? PaymentEventComponent.tileDisabledInvoiceAccepting
        : PaymentEventComponent.tileEnabledInvoiceAccepting
    });
    invoice.setPaymentSuppressed(!currentValue);
  };

  const fixUserCurrencyMismatch = () => {
    user.settingList.setCurrencyCode(paypalPrimaryCurrency?.toUpperCase() ?? 'USD');
  };

  const isPaypalEnabledWithValidCurrency = paypalStatusEnabled && !isPaypalCurrencyMismatch;
  const isPaypalEnabledWithInvalidCurrency = paypalStatusEnabled && isPaypalCurrencyMismatch;

  return (
    <>
      <div data-testid="paypalInvoiceAcceptingBox" />
      {!paypalStatusEnabled && (
        <PaypalGlobalPaymentToggleBox
          checked={paypalStatusEnabled}
          activatePayments={activatePayments}
        />
      )}
      {isPaypalEnabledWithInvalidCurrency && (
        <PaypalCurrencyMismatchBox
          onFixUserCurrencyMismatchHandler={fixUserCurrencyMismatch}
          footer={<InvoiceCurrencyMismatchFooter dataTestId="paypalCurrencyMismatchFooter" />}
        />
      )}
      {isPaypalEnabledWithValidCurrency && (
        <PaypalInvoicePaymentToggleBox
          isPaymentsSuppressed={invoice.setting?.paymentSuppressed ?? false}
          updatePaymentsSuppressed={updatePaypalPaymentSuppressed}
        />
      )}
    </>
  );
};

export const PaypalInvoiceAcceptingBox = injectStore(PaypalInvoiceAcceptingBoxComponent);
