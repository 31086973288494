import React, { FC } from 'react';
import {
  PaymentsCtaViewedEventMeta,
  useTrackPaymentsViewedEvent
} from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';

const ManageAccountButtonComponent: FC<{
  paymentsViewedMeta: PaymentsCtaViewedEventMeta;
  store: AppStore;
  onClick: () => void;
  buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>;
  children?: React.ReactNode;
}> = ({ paymentsViewedMeta, store, buttonProps, children, onClick }) => {
  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);
  return (
    <div className="setting-form-group form-group row">
      <div
        className="col-md-4"
        style={{
          margin: '0'
        }}
      />
      <div className="col-md-8">
        <div className="row">
          <div className="col-md-12 mt-2 mb-2">
            <button
              onClick={onClick}
              data-testid="manageAccountButton"
              type="button"
              className={`btn btn-payment-setup btn-linear-gradient text-neutral-600 font-semibold w-100 border`}
              style={{ position: 'relative' }}
              {...buttonProps}>
              {children}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ManageAccountButton = injectStore(ManageAccountButtonComponent);
