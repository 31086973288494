import React from 'react';
export interface NumberProps {
  value: number;
}

export function NumberIcon(props: NumberProps): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <circle cx="50%" cy="50%" r="9" stroke="#4a64da" strokeWidth="1.1px" fill="white" />
      <text
        x="50%"
        y="50%"
        fill="#4a64da"
        fontFamily="Quicksand"
        fontWeight="bold"
        textAnchor="middle"
        dominantBaseline="central">
        {props.value}
      </text>
    </svg>
  );
}
