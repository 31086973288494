import React from 'react';

export const FormattedTextRow = ({
  label,
  value,
  labelProps,
  valueProps,
  children
}: {
  label: string;
  value: string;
  labelProps?: string;
  valueProps?: string;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <div
      className="setting-form-group form-group row"
      style={{
        marginBottom: '0'
      }}>
      <label className={`col-md-4 col-form-label ${labelProps}`}>{label}:</label>
      <div className={`col-md-8 col-form-label text-truncate ${valueProps}`}>{value}</div>
      {children}
    </div>
  );
};
