import React from 'react';
import { observer } from 'mobx-react';

import { defineMessages } from 'src/i18n';
import ColorSelect from '../ColorSelect/ColorSelect';
import { SettingsCurrencySelector } from '../Currency';
import InvoicePDFButton from './InvoicePDFButton';
import { WhenLegacyPdfDownload } from '../conditionalRenderer';
import SendToSelfEmail from './SendToSelfEmail';
import currentDocStore from '../../stores/CurrentDocumentStore';
import {
  PaymentPromoModal,
  PaypalPromotionBox,
  PaymentEventLocation,
  usePaymentPromoModal,
  PaymentEventName,
  PaymentModalTriggerName,
  PaymentModalTrigger,
  PAYMENT_MODAL_LONG_DELAYED_TIME,
  PAYMENT_MODAL_DELAYED_TIME
} from 'src/payments';
import type InvoiceModel from 'src/models/InvoiceModel';
import { AppStore } from 'src/stores/AppStore';
import { useISIntl } from 'src/i18n/utils';
import { DiscountSettings } from './DiscountSettings';
import { Desktop } from '../Responsive';
import { RequestClientSignature } from '../RequestClientSignature';
import { RequestReview } from '../RequestReview';
import { TaxSettings } from './TaxSettings';
import { SignedEstimateMakeInvoice } from './SignedEstimateMakeInvoice';
import { InvoiceSendReminder } from './InvoiceSendReminder';
import { useIsPaypalRedesignFlagEnabled } from 'src/payments/hooks/useIsPaypalRedesignFlagEnabled';
import { InvoicePayments } from './InvoicePayments';
import { useIsPaymentsEnabled } from 'src/payments/hooks/useIsPaymentsEnabled';

const messages = defineMessages({
  customize: {
    id: 'settings.list.customize',
    defaultMessage: 'Customize'
  },
  template: {
    id: 'invoiceSettings.template',
    defaultMessage: 'Template'
  },
  color: {
    id: 'invoiceSettings.color',
    defaultMessage: 'Color'
  },
  currency: {
    id: 'invoiceSettings.currency',
    defaultMessage: 'Currency'
  },
  options: {
    id: 'invoiceSettings.options',
    defaultMessage: 'Options'
  },
  share: {
    id: 'invoiceSettings.share',
    defaultMessage: 'Share'
  },
  shareButtonLabel: {
    id: 'invoiceSettings.share.button.label',
    defaultMessage: 'Get Link'
  },
  shareButtonTitle: {
    id: 'invoiceSettings.share.button.title',
    defaultMessage: 'Get a link to your {docType} to share with your client'
  },
  printButtonLabel: {
    id: 'invoiceSettings.print.button.label',
    defaultMessage: 'Print {docType}'
  },
  printButtonTitle: {
    id: 'invoiceSettings.print.button.title',
    defaultMessage: 'Print your {docType}'
  },
  overdue: {
    id: 'invoiceSettings.share.reminder.overdue',
    defaultMessage: 'Overdue'
  }
});

export const InvoiceSettings = observer((props: { store: AppStore }) => {
  const { shouldShowPaymentPromoModal, showPaymentPromoModal, isEligibleForPaymentPromoModal } =
    usePaymentPromoModal(false);
  const isPaypalRedesignFlagEnabled = useIsPaypalRedesignFlagEnabled();
  const isPaymentsEnabled = useIsPaymentsEnabled();
  const intl = useISIntl();

  const store = props.store;
  const user = store.user;
  const invoice: InvoiceModel = store.doc;
  const isLoading = store.isLoading;
  const docType = intl.getTitleizedDocumentType(currentDocStore.currentDoc.docType);

  const handlePaymentModalShowing = (triggerName: PaymentModalTrigger, delayedTime: number) => {
    setTimeout(() => {
      if (isEligibleForPaymentPromoModal()) {
        showPaymentPromoModal();
        user.trackAppEventViaApi(PaymentEventName.paymentModalViewed, {
          location: PaymentEventLocation.invoiceEditor,
          trigger: triggerName
        });
      }
    }, delayedTime);
  };

  return (
    <div className="invoice-settings">
      {invoice.hasDueDate && +invoice.balance > 0 && <InvoiceSendReminder invoice={invoice} />}
      {invoice.isSignedEstimate && <SignedEstimateMakeInvoice invoice={invoice} store={store} />}
      {shouldShowPaymentPromoModal && isPaymentsEnabled && (
        <PaymentPromoModal
          onTrackEventAndLocation={(eventName, paymentEventLocation) => {
            user.trackAppEventViaApi(eventName, {
              location: paymentEventLocation
            });
          }}
        />
      )}

      <SendToSelfEmail />

      <div className="invoice-delete-hidden">
        {!isPaypalRedesignFlagEnabled && isPaymentsEnabled && (
          <PaypalPromotionBox
            store={store}
            location={PaymentEventLocation.promotionBoxInvoiceEditor}
            onTrackEvent={(eventName, metadata = {}) => {
              user.trackAppEventViaApi(eventName, {
                ...metadata,
                location: PaymentEventLocation.promotionBoxInvoiceEditor
              });
            }}
          />
        )}

        <Desktop>
          <InvoicePayments store={store} />
        </Desktop>

        {invoice.isEstimate && <RequestClientSignature user={user} invoice={invoice} />}
        <RequestReview user={user} />

        <h4>{intl.formatMessage(messages.template)}</h4>
        <ColorSelect
          isLoading={isLoading}
          value={invoice.color}
          onChange={(color: string) => {
            props.store.doc.setColor(color);
          }}
        />

        <button
          title={intl.formatMessage(messages.customize)}
          className={`btn btn-print btn-block mt-3`}
          onClick={(e) => {
            e.preventDefault();
            const params = new URLSearchParams();
            params.set('invoiceId', invoice.id || 'new-document');
            params.set('docType', invoice.docType.toString());

            const templatesUrl = `${
              process.env.REACT_APP_URL || ''
            }/templates?${params.toString()}`;

            store.location.navigateHard(templatesUrl);
          }}>
          <span>{intl.fta(messages.customize)}</span>
        </button>

        <TaxSettings invoice={invoice} isLoading={isLoading} />

        <DiscountSettings invoice={invoice} isLoading={isLoading} />

        <h4>{intl.formatMessage(messages.currency)}</h4>
        <table className="invoice-detail-about">
          <tbody>
            <tr>
              <td>
                <SettingsCurrencySelector settingList={store.user.settingList} />
              </td>
            </tr>
          </tbody>
        </table>

        <h4>{intl.formatMessage(messages.options)}</h4>
        <table className="invoice-detail-about">
          <tbody>
            <tr>
              <td>
                <button
                  disabled={isLoading}
                  title={intl.formatMessage(messages.shareButtonTitle)}
                  className="btn btn-share btn-block btn-invoice-share btn-prime"
                  onClick={() => {
                    invoice.openPublicLink();
                    handlePaymentModalShowing(
                      PaymentModalTriggerName.invoiceLink,
                      PAYMENT_MODAL_DELAYED_TIME
                    );
                  }}>
                  <span>{intl.formatMessage(messages.shareButtonLabel)}</span>
                </button>

                <WhenLegacyPdfDownload>
                  <InvoicePDFButton className="btn-block" />
                </WhenLegacyPdfDownload>

                <button
                  disabled={isLoading || invoice.isNew}
                  title={intl.formatMessage(messages.printButtonTitle)}
                  className={`btn btn-print btn-block`}
                  onClick={() => {
                    invoice.print();
                    handlePaymentModalShowing(
                      PaymentModalTriggerName.invoicePrint,
                      PAYMENT_MODAL_LONG_DELAYED_TIME
                    );
                  }}>
                  <span>{intl.fta(messages.printButtonLabel, { docType })}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});
