import { ContentTypes } from '@invoice-simple/common';

import { IsIdentifyPayload } from 'src/types/IsEvents';
import Rollbar from './rollbar';

export default async function identify(visitor: IsIdentifyPayload): Promise<void> {
  // userId is undefined on the login screen, don't send a request in this scenario since Amplitude will not accept it anyway
  if (!visitor.userId) return;

  const url = '/analytics/identify';
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': ContentTypes.APP_JSON
      },
      body: JSON.stringify(visitor)
    });

    if (res.status >= 400) {
      const text = await res.text();
      throw new Error(`Failed to identify user: ${text}`);
    }
  } catch (err) {
    Rollbar.trackError('is-identify', err, visitor);
  }
}
