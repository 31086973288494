import { client, API_V3_BASE_PATH } from './httpClient';
import UserModel from 'src/models/UserModel';
import { AxiosResponse, AxiosError } from 'axios';
import { RequestPasswordResetPayload, LoginResult, LoginData } from 'src/types/AuthApi';
import { ApiRequestOptions } from 'src/types/ApiRequestOptions';

export const PASSWORD_RESET_REQUEST_RESULTS = {
  SUCCESS: 'SUCCESS',
  UNKNOWN_EMAIL: 'UNKNOWN_EMAIL'
};

export const requestPasswordReset = async (data: RequestPasswordResetPayload) => {
  try {
    const response = await client.post(`${API_V3_BASE_PATH}/request-password-reset`, data);
    switch (response.status) {
      case 200:
        UserModel.getInstance().trackAppEventViaApi('password-reset', data);
        return PASSWORD_RESET_REQUEST_RESULTS.SUCCESS;
      case 204:
        UserModel.getInstance().trackAppEventViaApi('password-reset-attempt', {
          status: 'not found',
          email: data.email,
          message: '204 User not found with email'
        });
        return PASSWORD_RESET_REQUEST_RESULTS.UNKNOWN_EMAIL;
      default:
        throw new Error(`Unexpected response ${response.status} from password reset request`);
    }
  } catch (error) {
    UserModel.getInstance().trackError('password-reset-attempt', Object.assign(error, data));
    throw error;
  }
};

export const LOGIN_REQUEST_ERRORS = {
  INVALID_RESPONSE: 'INVALID_RESPONSE',
  NO_ACCOUNT: 'NO_ACCOUNT',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
  UNKNOWN_EMAIL: 'UNKNOWN_EMAIL',
  INVALID_EMAIL: 'INVALID_EMAIL',
  SUBSCRIPTION_TRANSFER_ERROR: 'SUBSCRIPTION_TRANSFER_ERROR'
};

interface RequestLoginResult {
  sessionToken: string;
  subscriptionTransferred?: boolean;
}

export const requestLogin = async (
  loginData: LoginData,
  withSubscriptionTransfer?: boolean
): Promise<RequestLoginResult> => {
  let result: AxiosResponse<LoginResult>;
  const path = withSubscriptionTransfer ? 'subscription-transfer' : 'login';
  const url = `${API_V3_BASE_PATH}/${path}`;

  // The subscription-transfer endpoint requires a session
  let headers: ApiRequestOptions['headers'] | undefined;
  if (withSubscriptionTransfer) {
    const user = UserModel.getInstance();
    user.getApiV3ReqOpts();
    headers = user.getApiV3ReqOpts().headers;
  }

  try {
    result = await client.post<LoginResult>(url, loginData, { headers });
  } catch (err) {
    const error = err as AxiosError<string>;
    if (error.response) {
      if (
        error.response.status === 500 &&
        error.response.data.match &&
        error.response.data.match(/No account found for user/i)
      ) {
        throw new Error(LOGIN_REQUEST_ERRORS.NO_ACCOUNT);
      }

      if (
        error.response.status === 400 &&
        error.response.data.match &&
        error.response.data.match('must be a valid email')
      ) {
        throw new Error(LOGIN_REQUEST_ERRORS.INVALID_EMAIL);
      }
      throw new Error(error.response.data);
    }
    throw error;
  }

  const {
    data: { succeeded, usernameExists, session, subscriptionTransferred }
  } = result;

  if (succeeded === undefined || usernameExists === undefined) {
    throw new Error(LOGIN_REQUEST_ERRORS.INVALID_RESPONSE);
  }

  if (!succeeded && !usernameExists) {
    throw new Error(LOGIN_REQUEST_ERRORS.UNKNOWN_EMAIL);
  }

  if (!succeeded && usernameExists) {
    throw new Error(LOGIN_REQUEST_ERRORS.WRONG_PASSWORD);
  }

  if (subscriptionTransferred?.eligible === false) {
    throw new Error(LOGIN_REQUEST_ERRORS.SUBSCRIPTION_TRANSFER_ERROR);
  }

  return {
    sessionToken: session!.sessionToken,
    subscriptionTransferred: subscriptionTransferred?.status
  };
};
