import React from 'react';

import { injectStore } from 'src/util/injectStore';
import { defineMessages } from 'src/i18n';
import {
  PaymentPromoModal,
  usePaymentPromoModal,
  PaymentEventName,
  PaymentEventLocation,
  PaymentModalTriggerName,
  PAYMENT_MODAL_LONG_DELAYED_TIME
} from 'src/payments';
import { AppStore } from 'src/stores/AppStore';
import { useISIntl } from 'src/i18n/utils';
import { navFreeTrial } from 'src/util/navigation';
import { URLQueryParamKeys } from 'src/util/url';

const messages = defineMessages({
  title: {
    id: 'invoicePDFButton.title',
    defaultMessage: 'download a PDF copy of the {docType} to your device'
  },
  label: {
    id: 'invoicePDFButton.label',
    defaultMessage: 'download PDF'
  }
});

interface Prop extends React.HTMLProps<HTMLButtonElement> {
  store: AppStore;
}

export const InvoicePDF = (props: Prop) => {
  const { shouldShowPaymentPromoModal, showPaymentPromoModal, isEligibleForPaymentPromoModal } =
    usePaymentPromoModal(false);
  const invoice = props.store.doc;
  const { ft } = useISIntl();
  const onDownloadButtonClick = () => {
    if (invoice.user.isSubActive) {
      invoice.download();
      setTimeout(() => {
        if (isEligibleForPaymentPromoModal()) {
          showPaymentPromoModal();
          invoice.user.trackAppEventViaApi(PaymentEventName.paymentModalViewed, {
            location: PaymentEventLocation.invoiceEditor,
            trigger: PaymentModalTriggerName.invoiceDownload
          });
        }
      }, PAYMENT_MODAL_LONG_DELAYED_TIME);
    } else {
      navFreeTrial({ user: invoice.user, ref: URLQueryParamKeys.DOCUMENT_ACTIONS });
    }
  };

  return (
    <>
      {shouldShowPaymentPromoModal && (
        <PaymentPromoModal
          onTrackEventAndLocation={(eventName, paymentEventLocation) => {
            invoice.user.trackAppEventViaApi(eventName, {
              location: paymentEventLocation
            });
          }}
        />
      )}
      <button
        disabled={props.store.isLoading}
        title={ft(messages.title)}
        className={`btn btn-download ${props.className ? props.className : ''}`}
        onClick={onDownloadButtonClick}>
        <span>{props.value || ft(messages.label)}</span>
      </button>
    </>
  );
};

export default injectStore(InvoicePDF);
