import { DocType, PaymentInstructions, SettingKeys, TemplateCodes } from '@invoice-simple/common';
import { observable, computed, action } from 'mobx';
import { IPromiseBasedObservable, fromPromise, PENDING } from 'mobx-utils';
import { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import defaultsDeep from 'lodash/defaultsDeep';
import Parse from 'parse';

// models
import UserModel from './UserModel';
import SettingModel from './SettingModel';
import PhotoModel from './PhotoModel';
import location from '../models/LocationModel';

// data
import { DOCUMENT_NUMBER, SETTING_LABELS } from '../data/settingsDisplayTypes';

// types
import { ServerDataSetting } from '../types/ServerData';

// API
import { getAccountSettings } from 'src/apis/settingAPI';
import { getLastDocumentForAccount } from 'src/apis/docsAPI';

// util
import nextNumber from '../util/NextNumber';
import getDocFromSettings from '../util/DocFromSettings';
import SettingsFromDoc from '../util/SettingsFromDoc';
import { getDocFromSettingsMap } from 'src/util/SettingsDocMap';
import EnvironmentStore from 'src/stores/EnvironmentStore';
import { titleize } from 'src/util/Titleize';
import { docTypeToMessage } from 'src/i18n';
import localeStore from 'src/stores/LocaleStore';
import isEmpty from 'src/util/isEmpty';
import { FeatureName } from '@invoice-simple/feature-gate';

export default class SettingListModel {
  debounceSyncLastInvoiceNo: DebouncedFunc<(docType: number) => void>;

  @observable loadTransaction?: IPromiseBasedObservable<Parse.Object[]>;
  @observable user: UserModel;
  @observable settings: SettingModel[] = [];
  @observable logo: PhotoModel;

  constructor(user: UserModel) {
    this.user = user;
    this.logo = new PhotoModel(user, this.photoSave);
    this.debounceSyncLastInvoiceNo = debounce((docType) => {
      this.syncLastInvoiceNo(docType);
    }, EnvironmentStore.debounceRate);
  }

  @computed get isLoading(): boolean {
    if (!this.loadTransaction) {
      return true;
    }
    return this.loadTransaction.state === PENDING;
  }

  toJSON() {
    return this.settings
      .map((s) => s.toJSON())
      .reduce((result, setting) => ({ ...result, ...setting }), {});
  }

  @computed
  get isSaving(): boolean {
    return this.settings.some((settingItem) => settingItem.isSaving);
  }

  @computed
  get savedSettings(): SettingModel[] {
    return this.settings.filter((s) => !s.isNew);
  }

  @computed
  get isEmpty(): boolean {
    return this.settings.length === 0;
  }

  @action.bound
  public updateFromInvoice(docType: number, docData: any = {}): void {
    const data = SettingsFromDoc(docType, docData);
    Object.keys(data).forEach((k) => {
      const setting = this.getSetting(k);
      if (setting) {
        if (setting.getDisplayType() === DOCUMENT_NUMBER) {
          this.debounceSyncLastInvoiceNo(docType);
        } else {
          setting.setValue(data[k]);
        }
      }
    });
  }

  public getDocumentsDefaults(docType: number): Record<string, any> {
    const hasAccessToPremiumTemplates = this.user.canUseFeature(FeatureName.PREMIUM_TEMPLATES);

    // build data for getDocFromSettings
    const data = {};
    this.settings.forEach((s) => {
      if (s.isPremiumTemplate && !hasAccessToPremiumTemplates) {
        data[s.name] = TemplateCodes.STYLE_1;
        return;
      }
      data[s.name] = s.value;
    });

    // build parts
    const base = { docType, invoiceNo: this.getNextInvoiceNo(docType) };
    const defaults = {
      title: titleize(localeStore.formatMessage(docTypeToMessage(location.docType)))
    };
    const docFromSettings = getDocFromSettings(docType, data);

    // return data merged by preference
    return location.isReceipt
      ? defaultsDeep(base, defaults, docFromSettings)
      : defaultsDeep(base, docFromSettings, defaults);
  }

  @computed
  public get paymentInstructions(): PaymentInstructions | undefined {
    const res: PaymentInstructions = {
      paypalEmail: this.getSettingValueAsString(SettingKeys.PaymentPaypalEmail),
      bank: this.getSettingValueAsString(SettingKeys.PaymentBank),
      cheque: this.getSettingValueAsString(SettingKeys.PaymentCheque),
      other: this.getSettingValueAsString(SettingKeys.PaymentOther),
      qrCodeEnabled: this.getSettingValueAsBoolean(SettingKeys.PaymentQRCodeEnabled)
    };
    return !isEmpty(res) ? res : undefined;
  }

  @computed
  public get logoRemoteId(): string | undefined {
    const s = this.getSetting(SettingKeys.CompanyLogo);
    return s && s.value;
  }

  @computed
  get currencyCode(): string {
    const currencyCode = this.getSetting(SettingKeys.LocaleCurrencyCode);
    if (!currencyCode) {
      return 'USD';
    }
    return currencyCode.value;
  }

  @action
  setCurrencyCode(value: string) {
    const currencyCodeSetting = this.getSetting(SettingKeys.LocaleCurrencyCode);
    if (currencyCodeSetting) {
      currencyCodeSetting.setValue(value);
    }
    this.user &&
      this.user.trackAppEventViaApi('setting-set-field', {
        field: 'currency',
        value
      });
  }

  public setLogoRemoteId(remoteId: string): void {
    const s = this.getSetting(SettingKeys.CompanyLogo);
    if (s) {
      s.setValue(remoteId);
    }
  }

  public getSetting(name: string): SettingModel | undefined {
    return this.settings.find((s) => s.name === name);
  }
  public getSettingValueAsString(name: string): string | undefined {
    const s = this.getSetting(name);
    if (s) {
      return s.value as string;
    } else {
      return;
    }
  }

  public getSettingValueAsBoolean(name: string): boolean | undefined {
    const s = this.getSetting(name);
    if (s) {
      return s.value as boolean;
    } else {
      return;
    }
  }

  @action
  ensureLastInvoiceNo(docType: number): Promise<void> {
    if (this.getLastInvoiceNo(docType)) {
      return Promise.resolve();
    } else {
      return this.syncLastInvoiceNo(docType);
    }
  }

  @action
  public initFromServerData(data: ServerDataSetting[]): void {
    this.settings = map(data, (s) => new SettingModel(this.user, s));
    this.updateDefaults();
    this.startAutoSave();
  }

  @action.bound
  public async load(): Promise<void> {
    try {
      const settings = await getAccountSettings(this.user.getParseAccount());
      this.update(settings);
      this.updateDefaults();
      this.loadTransaction = fromPromise(
        Promise.all([this.photoLoad(), this.ensureLastInvoiceNo(0), this.ensureLastInvoiceNo(1)])
      );
      await this.loadTransaction;
      this.startAutoSave();
    } catch (error) {
      this.user.handleError('setting-list-load', error);
    }
  }

  @action
  public async syncLastInvoiceNo(docType: number): Promise<void> {
    try {
      const lastDocument = await getLastDocumentForAccount(
        this.user.getParseAccount(),
        docType as DocType
      );
      if (lastDocument && lastDocument.has('invoiceNo')) {
        this.setLastInvoiceNo(docType, lastDocument.get('invoiceNo'));
      }
    } catch (error) {
      this.user.handleError('setting-list-syncLastInvoiceNo', error);
    }
  }
  private setLastInvoiceNo(docType: number, value: string): void {
    const docName = ['invoice', 'estimate'][docType];
    const setting = docName && this.getSetting(`${docName}.last-no`);
    if (setting) {
      setting.setValue(value);
    }
  }
  private getLastInvoiceNo(docType: number): string | undefined {
    const docName = ['invoice', 'estimate'][docType];
    const setting = docName && this.getSetting(`${docName}.last-no`);
    return setting ? setting.value : undefined;
  }

  private getNextInvoiceNo(docType: number): string {
    const last = this.getLastInvoiceNo(docType);
    return last ? nextNumber(last) : ['INV0001', 'EST0001', 'INV0001'][docType];
  }

  // update from parse
  @action
  private update(data: Parse.Object[]): void {
    this.settings = map(data, (s) => new SettingModel(this.user, s));
  }

  // ensure all settings created
  @action
  private updateDefaults(): void {
    getDocFromSettingsMap().forEach((option) => {
      if (!this.getSetting(option.path)) {
        this.settings.push(
          new SettingModel(this.user, {
            name: option.path,
            type: option.type,
            valStr: option.valStr ?? undefined
          })
        );
      }
    });

    // Add email.default-message setting
    if (!this.getSetting(SETTING_LABELS.DEFAULT_EMAIL_MESSAGE)) {
      this.settings.push(
        new SettingModel(this.user, {
          name: SETTING_LABELS.DEFAULT_EMAIL_MESSAGE,
          type: 'str',
          valStr: undefined
        })
      );
    }
  }

  @action.bound
  private startAutoSave(): void {
    this.settings.forEach((s) => s.startAutoSave());
  }

  @action
  private photoSave = (photo: PhotoModel) => {
    this.setLogoRemoteId(photo.remoteId);
  };

  @action
  private photoLoad(): Promise<any> {
    if (this.logoRemoteId) {
      return this.logo.loadByRemoteId(this.logoRemoteId);
    }
    return Promise.resolve();
  }
}
