import { ContentTypes } from '@invoice-simple/common';
import { FeatureName } from '@invoice-simple/feature-gate';
import React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';

import { defineMessages, ISIntl } from 'src/i18n';
import { PortraitIcon } from './PortraitIcon';
import { ImageFile } from 'src/types/ImageFile';
import UserModel from 'src/models/UserModel';
import { LockIcon } from '../Icons/LockIcon';
import { navToPaywallWithFeature } from 'src/util/navigation';
import { LoadingSpinnerIcon } from '../Icons';

const MAX_FILE_SIZE = 5 * 1000000; // 5MB

const messages = defineMessages({
  photosHeader: {
    id: 'invoice.photos.section.header',
    defaultMessage: 'Photos'
  },
  addPhoto: {
    id: 'invoice.photos.form.addPhoto',
    defaultMessage: 'Add Photo'
  },
  invoicePhotoTooManyFiles: {
    id: 'invoice.photos.error.too.many.files',
    defaultMessage: 'Max number of photos exceeded'
  },
  uploadFailedMaxFileSizeExceeded: {
    id: 'photoDrop.upload.failed.maxFileSizeExceeded',
    defaultMessage: 'Please upload an image file smaller than {maxFileSize}Mb.'
  },
  uploadFailedUnsupportedFileType: {
    id: 'photoDrop.upload.failed.unsupportedFileType',
    defaultMessage: 'Only jpg, jpeg, png, and webp file types are allowed'
  },
  uploadFailedGenericError: {
    id: 'photoDrop.upload.failed.genericError',
    defaultMessage: 'An error occurred uploading your photo.'
  }
});

export const AddPhoto = ({
  user,
  onUpload,
  intl,
  onError,
  isUploading,
  hasAccess
}: {
  user: UserModel;
  onUpload: (file: ImageFile) => void;
  onError: (message: string) => void;
  intl: ISIntl;
  isUploading: boolean;
  hasAccess?: boolean;
}) => {
  const navToPaywallWithAlert = () => {
    navToPaywallWithFeature(FeatureName.INVOICE_PHOTOS);
  };

  function getIntlErrorMessage(fileRejections: FileRejection[]) {
    const { file, errors } = fileRejections[0];
    const errorCodes = errors.map((error) => error.code);
    if (errorCodes.includes('file-too-large')) {
      user.events.trackAction('validation-error', {
        action: 'upload-photo',
        'file-extension': file.type,
        size: file.size,
        type: 'invoice-photo'
      });
      return intl.formatMessage(messages.uploadFailedMaxFileSizeExceeded, {
        maxFileSize: MAX_FILE_SIZE / 1000000
      });
    }

    if (errorCodes.includes('too-many-files')) {
      return intl.formatMessage(messages.invoicePhotoTooManyFiles);
    }

    if (errorCodes.includes('file-invalid-type')) {
      return intl.formatMessage(messages.uploadFailedUnsupportedFileType);
    }

    return intl.formatMessage(messages.uploadFailedGenericError);
  }

  const onDrop = (acceptedFiles: ImageFile[], fileRejections: FileRejection[]) => {
    if (!hasAccess) {
      return navToPaywallWithAlert();
    }
    user.events.trackAction('invoice-photo-add-attempted');

    if (fileRejections.length > 0) {
      onError(getIntlErrorMessage(fileRejections));
      return;
    }

    if (acceptedFiles && acceptedFiles[0]) {
      onUpload(acceptedFiles[0]);
    } else {
      onError(intl.formatMessage(messages.uploadFailedGenericError));
    }
  };

  const accept = {
    [ContentTypes.IMG_JPEG]: ['.jpg', '.jpeg'],
    [ContentTypes.IMG_PNG]: ['.png'],
    [ContentTypes.IMG_WEBP]: ['.webp']
  };

  if (isUploading) {
    return (
      <article data-testid="loader" className="invoice-photo-add" id="tailwind">
        <button disabled className="relative overflow-hidden cursor-not-allowed">
          <LoadingSpinnerIcon color="black" className="w-10 h-10" />
          <div className="z-1 animate-pulse absolute h-full w-full bg-gray-100 opacity-80 w-full top-0 bottom-0 left-0 right-0"></div>
        </button>
      </article>
    );
  }

  return (
    <article data-testid="add-photo-button" className="invoice-photo-add">
      <Dropzone
        onDrop={onDrop}
        maxSize={MAX_FILE_SIZE}
        minSize={1}
        accept={accept}
        multiple={false}
        disabled={false}>
        {({ getRootProps, getInputProps }) => {
          const inputProps = !hasAccess ? { ...getInputProps(), type: '' } : getInputProps();

          return (
            <div {...getRootProps()}>
              <input
                {...inputProps}
                data-testid="add-photo-input"
                onClick={() => {
                  user.events.trackAction('invoice-photo-add-attempted');
                  if (!hasAccess) {
                    navToPaywallWithAlert();
                  }
                }}
              />
              <button>
                {hasAccess ? <PortraitIcon /> : <LockIcon data-testid="lock-icon" />}
                <p>{intl.ft(messages.addPhoto)}</p>
              </button>
            </div>
          );
        }}
      </Dropzone>
    </article>
  );
};
