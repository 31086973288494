import {
  DepositRates,
  DepositTypes,
  Deposit,
  DepositAmounts,
  DocType,
  DocTypes
} from '@invoice-simple/common';
import React, { useContext, useEffect } from 'react';
import NumericInput from 'react-numeric-input';

import { Selectv1Adapter } from 'src/components/Selectv1Adapter';
import { PaypalAccountContext } from 'src/payments/contexts/PaypalAccountContext';
import './PaymentDeposit.scss';
import UserModel from 'src/models/UserModel';
import { FeatureName, hasFullAccessToFeature } from '@invoice-simple/feature-gate';
import { useDepositFeatureGate } from './useDepositFeatureGate';
import { InvoiceDepositIneligible } from './InvoiceDepositIneligible';
import { InvoiceDepositSetup } from './InvoiceDepositSetup';
import { defineMessages, useISIntl } from 'src/i18n';
import { usePaymentsUsability } from 'src/payments/hooks/usePaymentsUsability';
import { useStripeContext } from 'src/payments/contexts/StripeAccountContext';
import { getDepositTypeOptions, getDepositRateOptions } from './DepositTypeOptions';

export const messages = defineMessages({
  requestDeposits: {
    id: 'invoice.deposits.requestDeposits',
    defaultMessage: 'Request a deposit to be paid digitally.'
  },
  onlinePayments: {
    id: 'invoice.deposits.onlinePayments',
    defaultMessage: 'Start accepting Online Payments to request a deposit on this invoice.'
  },
  requestDepositsNew: {
    id: 'invoice.deposits.requestDepositsNew',
    defaultMessage: 'Request a percentage or fixed-amount deposit on your invoice.'
  },
  upgradeNow: {
    id: 'invoice.deposits.upgradeNow',
    defaultMessage: 'Upgrade Now'
  },
  deposit: {
    id: 'invoice.deposits.deposit',
    defaultMessage: 'DEPOSIT'
  },
  depositAmount: {
    id: 'invoice.deposits.depositAmount',
    defaultMessage: 'Amount'
  },
  depositType: {
    id: 'invoice.deposits.depositType',
    defaultMessage: 'Type'
  },
  depositTypeNone: {
    id: 'invoice.deposits.depositTypeNone',
    defaultMessage: 'None'
  },
  depositTypePercent: {
    id: 'invoice.deposits.depositTypePercent',
    defaultMessage: 'Percent'
  },
  depositTypeFlat: {
    id: 'invoice.deposits.depositTypeFlat',
    defaultMessage: 'Flat amount'
  }
});

export interface PaymentDepositProps {
  document: {
    docType: DocType;
    paymentSuppressed: boolean | undefined;
    stripePaymentSuppressed: boolean | undefined;
    currencyCode: string | undefined;
    invoiceId: string | undefined;
    paymentsCount: number;
  };
  onComponentShown: (isEligibleForDeposit: boolean) => void;
  onInvoiceDepositUpdate: ({ depositRate, depositType, depositAmount }: Deposit) => void;
  deposit: Deposit;
}

export const PaymentDeposit = (props: PaymentDepositProps) => {
  const { docType, currencyCode, paymentsCount, paymentSuppressed, stripePaymentSuppressed } =
    props.document;
  const { isPaypalUsable, isStripeUsable } = usePaymentsUsability(currencyCode);
  const paypalAccountContext = useContext(PaypalAccountContext);
  const stripeAccountContext = useStripeContext();
  const user = UserModel.getInstance();
  const isNewDepositExperience = !user?.abTests?.payments_deposit_legacy && useDepositFeatureGate();

  if (docType !== DocTypes.DOCTYPE_INVOICE || paymentsCount > 0) {
    return null;
  }

  if (isNewDepositExperience) {
    const hasAccessToDepositsFeature = hasFullAccessToFeature({
      feature: FeatureName.DEPOSITS,
      user
    });
    if (hasAccessToDepositsFeature) {
      // ff on, premium user
      return <InvoiceDepositSetup isEligibleForDeposit={hasAccessToDepositsFeature} {...props} />;
    } else {
      // ff on, non-premium user
      return <InvoiceDepositIneligible />;
    }
  } else {
    // ff off/user is payments_deposit_legacy, same eligibility as before
    if (
      (!paypalAccountContext || !paypalAccountContext.isPaypalEligible()) &&
      (!stripeAccountContext || !stripeAccountContext.isStripeEligible)
    ) {
      return null;
    }

    const isPaypalUsableForDeposit = isPaypalUsable && !paymentSuppressed;
    const isStripeUsableForDeposit = isStripeUsable && !stripePaymentSuppressed;

    const isEligibleForDeposit = isPaypalUsableForDeposit || isStripeUsableForDeposit;

    return <PaymentDepositComponent isEligibleForDeposit={isEligibleForDeposit} {...props} />;
  }
};

const PaymentDepositComponent = ({
  document,
  isEligibleForDeposit,
  onComponentShown,
  onInvoiceDepositUpdate,
  deposit
}: PaymentDepositProps & { isEligibleForDeposit: boolean }) => {
  const { ft } = useISIntl();
  const { depositRate, depositType, depositAmount } = deposit;

  useEffect(() => {
    if (!document.invoiceId) {
      return;
    }
    onComponentShown(isEligibleForDeposit);
  }, [document.invoiceId, isEligibleForDeposit]);

  useEffect(() => {
    if (!isEligibleForDeposit && (!!depositType || !!depositRate || !!depositAmount)) {
      onInvoiceDepositUpdate({
        depositType: DepositTypes.NONE,
        depositRate: DepositRates.DEPOSIT_NONE,
        depositAmount: DepositAmounts.DEPOSIT_NONE
      });
    }
  }, [depositType, depositRate, depositAmount, isEligibleForDeposit]);

  const DEPOSIT_TYPE_OPTIONS = getDepositTypeOptions();
  const DEPOSIT_RATE_OPTIONS = getDepositRateOptions();

  return (
    <div className="payment-deposit">
      <h4 data-testid={'deposit-title'}>{ft(messages.deposit)}</h4>

      <div className="setting-form-group form-group row">
        <div className="col-md-6 col-lg-12">
          <label className="col-form-label">
            {isEligibleForDeposit ? (
              <>{ft(messages.requestDeposits)}</>
            ) : (
              <>{ft(messages.onlinePayments)}</>
            )}
          </label>
          {isEligibleForDeposit && (
            <table className="invoice-detail-about">
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="invoice-deposit-type">{ft(messages.depositType)}</label>
                  </td>
                  <td>
                    <Selectv1Adapter
                      id="invoice-deposit-type"
                      className="invoice-deposit-type-input"
                      name="invoiceDepositType"
                      isClearable={false}
                      value={depositType}
                      options={DEPOSIT_TYPE_OPTIONS}
                      onChange={(newType) => {
                        onInvoiceDepositUpdate({
                          ...deposit,
                          depositType: newType.value
                        });
                      }}
                    />
                  </td>
                </tr>
                {depositType === DepositTypes.PERCENT && (
                  <tr>
                    <td>
                      <label htmlFor="invoice-deposit-rate">{ft(messages.depositAmount)}</label>
                    </td>
                    <td>
                      <Selectv1Adapter
                        id="invoice-deposit-rate"
                        className="invoice-deposit-rate-input"
                        name="invoiceDepositRate"
                        isClearable={false}
                        value={depositRate}
                        options={DEPOSIT_RATE_OPTIONS}
                        onChange={(newRate) => {
                          onInvoiceDepositUpdate({
                            ...deposit,
                            depositRate: newRate.value
                          });
                        }}
                      />
                    </td>
                  </tr>
                )}
                {depositType === DepositTypes.FLAT && (
                  <tr>
                    <td>
                      <label htmlFor="invoice-deposit-flat">{ft(messages.depositAmount)}</label>
                    </td>
                    <td>
                      <NumericInput
                        className="invoice-deposit-flat-input"
                        id="invoice-deposit-flat"
                        value={depositAmount}
                        precision={2}
                        min={0}
                        onChange={(v) => {
                          onInvoiceDepositUpdate({
                            ...deposit,
                            depositAmount: v ?? 0
                          });
                        }}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
