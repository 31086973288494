import React from 'react';
import { SVGProps } from 'react';

export const InvoicesIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_20_233)">
        <path
          d="M17.7078 24.739C16.3438 24.739 15.2328 23.6281 15.2328 22.264C15.2328 21.689 14.7656 21.2218 14.1906 21.2218C13.6156 21.2218 13.1484 21.689 13.1484 22.264C13.1484 24.4156 14.6547 26.2156 16.6656 26.6906V28.1234C16.6656 28.6984 17.1328 29.1656 17.7078 29.1656C18.2828 29.1656 18.75 28.6984 18.75 28.1234V26.6906C20.7609 26.2156 22.2672 24.4171 22.2672 22.264C22.2672 19.7515 20.2219 17.7078 17.7094 17.7078C16.3453 17.7078 15.2344 16.5968 15.2344 15.2328C15.2344 13.8687 16.3422 12.7578 17.7094 12.7578C19.0766 12.7578 20.1844 13.8687 20.1844 15.2328C20.1844 15.8078 20.6516 16.275 21.2266 16.275C21.8016 16.275 22.2687 15.8078 22.2687 15.2328C22.2687 13.0781 20.7625 11.2781 18.7516 10.8062V9.37495C18.7516 8.79995 18.2844 8.33276 17.7094 8.33276C17.1344 8.33276 16.6672 8.79995 16.6672 9.37495V10.8062C14.6562 11.2812 13.15 13.0812 13.15 15.2328C13.15 17.7468 15.1953 19.7906 17.7078 19.7906C19.0719 19.7906 20.1828 20.9015 20.1828 22.264C20.1828 23.6281 19.0719 24.739 17.7078 24.739Z"
          fill="#FF5721"
        />
        <path
          d="M43.6688 13.1422C43.6172 13.0156 43.5391 12.9 43.4438 12.8047L30.9484 0.30625C30.8531 0.2125 30.7375 0.132812 30.6109 0.0828125C30.4844 0.028125 30.3469 0 30.2094 0H7.29219C6.71719 0 6.25 0.467187 6.25 1.04219V48.9594C6.25 49.5344 6.71719 50.0016 7.29219 50.0016H42.7094C43.2844 50.0016 43.7516 49.5344 43.7516 48.9594V13.5422C43.7516 13.4031 43.7219 13.2672 43.6703 13.1422H43.6688ZM31.25 3.55625L40.1938 12.5H31.25V3.55625ZM8.33281 47.9172V2.08437H29.1656V13.5422C29.1656 14.1172 29.6328 14.5844 30.2078 14.5844H41.6656V47.9172H8.33281Z"
          fill="#FF5721"
        />
        <path
          d="M36.4578 33.3328H13.7141C13.1391 33.3328 12.6719 33.8 12.6719 34.375C12.6719 34.95 13.1391 35.4171 13.7141 35.4171H36.4578C37.0328 35.4171 37.5 34.95 37.5 34.375C37.5 33.8 37.0328 33.3328 36.4578 33.3328Z"
          fill="#FF5721"
        />
        <path
          d="M36.4578 29.1672H26.075C25.5 29.1672 25.0328 29.6344 25.0328 30.2094C25.0328 30.7844 25.5 31.2516 26.075 31.2516H36.4578C37.0328 31.2516 37.5 30.7844 37.5 30.2094C37.5 29.6344 37.0328 29.1672 36.4578 29.1672Z"
          fill="#FF5721"
        />
        <path
          d="M36.4578 25H30.2078C29.6328 25 29.1656 25.4672 29.1656 26.0422C29.1656 26.6172 29.6328 27.0844 30.2078 27.0844H36.4578C37.0328 27.0844 37.5 26.6172 37.5 26.0422C37.5 25.4672 37.0328 25 36.4578 25Z"
          fill="#FF5721"
        />
        <path
          d="M36.4578 20.8328H30.2078C29.6328 20.8328 29.1656 21.3 29.1656 21.875C29.1656 22.45 29.6328 22.9171 30.2078 22.9171H36.4578C37.0328 22.9171 37.5 22.45 37.5 21.875C37.5 21.3 37.0328 20.8328 36.4578 20.8328Z"
          fill="#FF5721"
        />
        <path
          d="M36.4578 37.5H13.7141C13.1391 37.5 12.6719 37.9672 12.6719 38.5422C12.6719 39.1172 13.1391 39.5844 13.7141 39.5844H36.4578C37.0328 39.5844 37.5 39.1172 37.5 38.5422C37.5 37.9672 37.0328 37.5 36.4578 37.5Z"
          fill="#FF5721"
        />
        <path
          d="M36.4578 41.6672H13.7141C13.1391 41.6672 12.6719 42.1344 12.6719 42.7094C12.6719 43.2844 13.1391 43.7516 13.7141 43.7516H36.4578C37.0328 43.7516 37.5 43.2844 37.5 42.7094C37.5 42.1344 37.0328 41.6672 36.4578 41.6672Z"
          fill="#FF5721"
        />
      </g>
      <defs>
        <clipPath id="clip0_20_233">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
