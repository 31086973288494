import React, { useContext, useState } from 'react';
import { PaypalAccountContext } from '../../contexts/PaypalAccountContext';
import { PaymentSetupProgress } from '../PaymentSetupProgress';
import { PaymentEventName, PaymentEvent } from '../../types';
import { SupportedPaymentMethods } from '../PaymentBasicElements';
import { onError } from 'src/payments/utils/utils';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { SubscriptionTier } from '@invoice-simple/common';
import { ToggleSwitch } from 'src/components/ToggleSwitch';

interface PaymentSettingDictionary {
  onlinePayments: string;
  acceptPayments: string;
  paypalAccountEmail: string;
  paypalMerchantId: string;
}

interface PaymentSettingProps {
  dictionary: PaymentSettingDictionary;
  onTrackEvent: (eventName: PaymentEvent, metadata?: Record<string, number | string>) => void;
}

export const paymentSetupAlertDictionary = {
  alertBody:
    'Your account setup is incomplete. Please finish your registration in order to accept payments.',
  continueSetupButtonText: 'Continue Setup',
  manageAccountButtonText: 'Manage PayPal Account',
  closedAlertText:
    'Your PayPal account is not connected. Please contact support to connect a valid PayPal account.',
  contactSupportButtonText: 'Contact Support'
};

const PaymentSettingComponent = ({
  dictionary,
  onTrackEvent,
  store: { user }
}: PaymentSettingProps & { store: AppStore }) => {
  const [fetchingData, setFetchingData] = useState(false);
  const paypalAccountContext = useContext(PaypalAccountContext);
  if (
    !paypalAccountContext ||
    !paypalAccountContext.isPaypalEligible() ||
    paypalAccountContext.isPaypalInitial()
  ) {
    return null;
  }

  const {
    updatePaypalEnabled,
    isPaypalEnabled,
    getPaypalAccountEmail,
    getPaypalMerchantId,
    isPaypalAccepting,
    isPaypalClosed
  } = paypalAccountContext;

  const accountEmail = getPaypalAccountEmail();
  const merchantId = getPaypalMerchantId();
  const isPaymentEnabled = isPaypalEnabled() && !isPaypalClosed();

  const handleChange = async (checked: boolean) => {
    try {
      setFetchingData(true);
      await updatePaypalEnabled(checked);
      const eventName = checked
        ? PaymentEventName.paymentsEnable
        : PaymentEventName.paymentsDisable;
      onTrackEvent(eventName);
    } catch (error) {
      const action = checked ? 'enable' : 'disable';
      onError({ title: `Failed to ${action} payment status`, body: error.message });
    } finally {
      setFetchingData(false);
    }
  };

  return (
    <div>
      <div className="setting-form-group" />
      <h4 data-testid="Online Payments Header">
        <span>{dictionary.onlinePayments}</span>
      </h4>
      <div>
        {accountEmail && (
          <div className="setting-form-group form-group row">
            <label className="col-md-4 col-form-label">{`${dictionary.paypalAccountEmail}: `}</label>
            <div className="col-md-8 col-form-label text-truncate">{accountEmail}</div>
          </div>
        )}
        {merchantId && (
          <div className="setting-form-group form-group row">
            <label className="col-md-4 col-form-label">{dictionary.paypalMerchantId}:</label>
            <div className="col-md-8 col-form-label text-truncate">{merchantId}</div>
          </div>
        )}
      </div>
      <div className="setting-form-group form-group row mb-md-4">
        <label className="col-form-label col-md-4">{dictionary.onlinePayments}</label>
        <div className="col-form-label col-md-8">
          <SettingPageToggle
            disabled={
              fetchingData || !isPaypalAccepting() || user.isSubTier(SubscriptionTier.PAYMENTS_TIER)
            }
            onChange={handleChange}
            isPaypalEnabled={isPaymentEnabled}
            dictionary={dictionary}
          />
          <div className="row">
            <div className="col-form-label col-md-12">
              <SupportedPaymentMethods />
            </div>
          </div>
          <div className="row">
            <PaymentSetupProgress
              dictionary={paymentSetupAlertDictionary}
              onTrackEvent={onTrackEvent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PaymentSetting = injectStore(PaymentSettingComponent);

function SettingPageToggle({
  disabled,
  isPaypalEnabled,
  onChange,
  dictionary
}: {
  disabled: boolean;
  onChange: (checked: boolean) => void;
  isPaypalEnabled: boolean;
  dictionary: {
    acceptPayments: string;
  };
}) {
  return (
    <div className="row">
      <label className="col-md-7">{dictionary.acceptPayments}</label>
      <div className="col-sm-8 col-md">
        <ToggleSwitch checked={isPaypalEnabled} onChange={onChange} disabled={disabled} />
      </div>
    </div>
  );
}
