import React, { useContext } from 'react';
import { PaymentEventLocationType, PaymentEvent, PaymentEventLocation } from '../../../types';
import { PaypalAccountContext } from '../../../contexts/PaypalAccountContext';
import { PaypalPromotionBox } from '../../PaypalPromotionBox';
import { usePaymentPromoModal } from './usePaymentPromoModal';
import { PaymentModalWrapper } from '../PaypalModalWrapper/PaymentModalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PaymentPromoModal.scss';
interface PaymentModalProps {
  onTrackEventAndLocation: (eventName: PaymentEvent, location: PaymentEventLocationType) => void;
}

export function PaymentPromoModal({
  onTrackEventAndLocation
}: PaymentModalProps): JSX.Element | null {
  const paypalAccountContext = useContext(PaypalAccountContext);
  const { shouldShowPaymentPromoModal, hidePaymentPromoModal } = usePaymentPromoModal(true);

  if (
    !paypalAccountContext ||
    !paypalAccountContext?.isPaypalEligible() ||
    paypalAccountContext?.isPaypalAccepting()
  ) {
    return null;
  }

  return (
    <PaymentModalWrapper
      isOpen={shouldShowPaymentPromoModal}
      onRequestClose={hidePaymentPromoModal}>
      <a
        title={'Close'}
        className="payment-modal-btn-close"
        data-testid="payment-modal-close-btn"
        style={{ cursor: 'pointer' }}
        onClick={hidePaymentPromoModal}>
        <FontAwesomeIcon icon="times" />
      </a>
      <PaypalPromotionBox
        location={PaymentEventLocation.paymentModal}
        onTrackEvent={(eventName) =>
          onTrackEventAndLocation(eventName, PaymentEventLocation.paymentModal)
        }
        closePaymentModal={hidePaymentPromoModal}
      />
    </PaymentModalWrapper>
  );
}
