import { observable, action, computed } from 'mobx';

class InvoiceNoModel {
  @observable source: any;
  fieldName: string;
  @observable dirty: boolean = false;
  @observable value: string = '';

  constructor(source: any, fieldName: string) {
    this.source = source;
    this.fieldName = fieldName;
    this.value = source[fieldName] ? source[fieldName] : '';
  }

  @action.bound
  setValue(value: string) {
    this.value = value;
    this.dirty = true;
    if (this.isValueValid) {
      this.source[this.fieldName] = this.value;
    }
  }

  @computed
  get isValueValid(): boolean {
    if (!this.dirty) {
      return true;
    }
    return !this.invoiceNumberValidationError;
  }

  @computed
  get invoiceNumberValidationError(): string | undefined {
    if (this.dirty) {
      const _value = this.value && this.value.trim();
      if (!_value || _value.length === 0) {
        return 'is required';
      }
      if (!_value.match(/^[A-Za-z]*\d+$/)) {
        return 'should be letters followed by digits';
      }
    }
    return undefined;
  }
}

export default InvoiceNoModel;
