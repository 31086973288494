import React from 'react';
import { SVGProps } from 'react';

export const PencilIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.9822 0.982233C14.9585 0.00592233 16.5415 0.00592233 17.5178 0.982233C18.4941 1.95854 18.4941 3.54146 17.5178 4.51777L16.5266 5.50888L12.9911 1.97335L13.9822 0.982233Z"
        fill="#FC5B31"
      />
      <path
        d="M11.2234 3.74112L0.75 14.2145V17.75H4.28553L14.7589 7.27665L11.2234 3.74112Z"
        fill="#FC5B31"
      />
    </svg>
  );
};
