import React from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { CheckIcon } from 'src/components/Icons';
import { useISIntl } from 'src/i18n';
import { smoothScrollById } from 'src/util/smoothScroll';
import { messages, TierCardDataType } from '../../../utils';

interface CardFeatureListProps {
  tier: TierCardDataType;
}

export const CardFeatureList = ({ tier }: CardFeatureListProps) => {
  const { ft } = useISIntl();

  const isPlusTier = tier.name === SubscriptionTier.PLUS;
  const isPremiumTier = tier.name === SubscriptionTier.PREMIUM;

  return (
    <div
      className="px-[20px] pt-[20px] rounded-b-[30px] pb-24 h-[230px]"
      style={{
        border: `2px solid ${isPlusTier ? '#FED7AA' : '#E5E7EB'}`,
        borderTopWidth: '0px'
      }}>
      {tier.featureTitle && (
        <p className={`text-xl font-bold text-gray-900 mb-3`}>{tier.featureTitle}</p>
      )}
      <div className="flex flex-col gap-2" data-testid={`desktop-${tier.name}-feature-list`}>
        {tier.features.map((feature, idx) => (
          <div key={feature} className={`flex items-center gap-2`}>
            <CheckIcon className="w-6 h-6 text-gray-800" strokeWidth={3} />
            <p
              className={`text-xl text-gray-900 ${isPremiumTier && idx === 0 ? 'font-extrabold' : 'font-medium'}`}>
              {feature}
            </p>
          </div>
        ))}
      </div>
      <div
        className="cursor-pointer w-fit text-gray-900 mt-4 text-lg lg:text-xl font-medium text-nowrap"
        style={{ borderBottom: '1px solid' }}
        onClick={() =>
          smoothScrollById('feature-matrix')
        }>{`${ft(messages.compareAllFeatures)} >`}</div>
    </div>
  );
};
