import React, { FC } from 'react';
import { BlueCTAButton } from '../Common';
import { PaypalBaseBox } from './PaypalBaseBox';
import { AppStore } from 'src/stores/AppStore';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import injectStore from 'src/util/injectStore';
import { getPaymentEventSource } from 'src/payments/utils';
export const PaypalInitialBoxComponent: FC<{ onCTAClick: () => void; store: AppStore }> = ({
  onCTAClick,
  store
}) => {
  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    component: PaymentEventComponent.tileInitial
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, store.user.events);

  return (
    <>
      <div data-testid="paypalInitialBox" />
      <PaypalBaseBox actionElement={<BlueCTAButton onClick={onCTAClick}>Set Up</BlueCTAButton>} />
    </>
  );
};

export const PaypalInitialBox = injectStore(PaypalInitialBoxComponent);
