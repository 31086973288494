import React, { useState } from 'react';

import Modal from 'react-modal';

import { RoundedButton } from 'src/components/Onboarding/rounded-button';
import { useISIntl } from 'src/i18n';
import LocationModel from 'src/models/LocationModel';
import UserModel from 'src/models/UserModel';
import { androidPlaystoreURL, iosAppstoreURL, openMobileLink } from 'src/util/mobile';
import titleize from 'src/util/Titleize';
import { BonusIcon } from '../Icons/BonusIcon';
import { maybeGetOldTierFromNewTier } from '../SubscriptionPaywall/utils';
import { ButtonContainer } from './components/ButtonsContainer';
import { Header } from './components/Header';
import { ModalContainer } from './components/ModalContainer';
import { ValueProps } from './components/ValueProps';
import { customStyles } from './styles';
import { messages } from './translations';

const ReferralPurchaseModal = () => {
  const intl = useISIntl();
  const user = UserModel.getInstance();

  const [isOpen, setIsOpen] = useState(true);

  const f = intl.formatMessage;
  const tier = user.lastPaidWebSub?.tier;
  const isAnnual = user.lastPaidWebSub?.isAnnual;

  if (!tier) {
    return null;
  }

  const closeModal = () => {
    LocationModel.history.push('/invoices');
    setIsOpen(false);
  };

  const formattedTierName = titleize(maybeGetOldTierFromNewTier(tier));

  const welcomeToPlanDescription = isAnnual
    ? f(messages.welcomeToPlanDescriptionAnnual)
    : f(messages.welcomeToPlanDescriptionMonthly);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
      <ModalContainer style="md:max-w-[600px]">
        <Header
          onClose={closeModal}
          title={f(messages.alertTierPurchaseTitle, {
            tier: formattedTierName
          })}
          subTitle={f(messages.welcomeToPlan, {
            tier: formattedTierName
          })}
        />
        <div className="hidden md:block md:col-span-2 md:mt-4">
          <ValueProps
            title={f(messages.referralBonus)}
            description={welcomeToPlanDescription}
            icon={<BonusIcon />}
          />
        </div>
        <div className="block md:hidden">
          <p className="w-full text-3xl text-gray-800 font-normal mt-4 md:mt-0">
            {welcomeToPlanDescription}
          </p>
        </div>
        <div className="block md:hidden h-full">
          <ButtonContainer
            primaryText={f(messages.continueButton)}
            secondaryText={f(messages.downloadMobileApp)}
            primaryHandleClick={closeModal}
            secondaryHandleClick={() => openMobileLink(iosAppstoreURL, androidPlaystoreURL)}
          />
        </div>
        <RoundedButton color="primary" onClick={closeModal} customStyles="md:mt-10 hidden md:block">
          {f(messages.continueButton)}
        </RoundedButton>
      </ModalContainer>
    </Modal>
  );
};

export default ReferralPurchaseModal;
