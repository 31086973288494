import { SettingKeys } from '@invoice-simple/common';
import { FeatureName, hasFullAccessToFeature } from '@invoice-simple/feature-gate';
import React, { useEffect, useState } from 'react';

import UserModel from 'src/models/UserModel';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { useISIntl } from 'src/i18n';
import { navToPaywallWithFeature } from 'src/util/navigation';
import { LockIcon } from '../Icons/LockIcon';
import './RequestReview.scss';

interface RequestReviewProps {
  user: UserModel;
}

export const RequestReview = ({ user }: RequestReviewProps) => {
  const intl = useISIntl();
  const requestReviewEnabled = user.settingList.getSetting(SettingKeys.RequestReviewEnabled);
  const requestReviewLink = user.settingList.getSetting(SettingKeys.RequestReviewLink1);
  const hasRequestReviewAccess = hasFullAccessToFeature({
    feature: FeatureName.REQUEST_REVIEW,
    user
  });

  useEffect(() => {
    if (requestReviewEnabled?.valBool === undefined) {
      return requestReviewEnabled?.setValue(hasRequestReviewAccess);
    }
    setToggleSwitch(requestReviewEnabled.valBool);
  }, [requestReviewEnabled?.valBool]);

  const [toggleSwitch, setToggleSwitch] = useState(
    requestReviewEnabled?.valBool ?? hasRequestReviewAccess
  );
  const [reviewLink, setReviewLink] = useState(decodeURI(requestReviewLink?.valStr ?? ''));

  const handleToggle = (checked: boolean) => {
    if (!hasRequestReviewAccess) {
      return navToPaywallWithFeature(FeatureName.REQUEST_REVIEW);
    }
    setToggleSwitch(checked);
    user.events.trackAction('toggle-request-review', { enabled: checked });
    requestReviewEnabled?.setValue(checked);
  };

  const saveReviewLink = (url: string) => {
    setReviewLink(url);

    const encodedUrl = encodeURI(url);
    if (isValidLink(encodedUrl))
      setTimeout(() => {
        user.events.trackAction('save-review-link', {
          field: SettingKeys.RequestReviewLink1,
          value: url
        });
        requestReviewLink?.setValue(encodedUrl);
      }, 500);
  };

  const isValidLink = (value: string) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return value === '' || pattern.test(value);
  };

  return (
    <>
      <h4>
        {intl.formatMessage({ id: 'invoiceSettings.request.reviews', defaultMessage: 'Reviews' })}
      </h4>
      <div className="request-review mt-3">
        <span
          data-testid="request-review-heading"
          className="d-inline-flex align-items-center mr-3">
          {intl.formatMessage({ id: 'request.review.label', defaultMessage: 'Request reviews' })}
          {!hasRequestReviewAccess && <LockIcon style={{ width: '15px', marginLeft: '2px' }} />}
        </span>
        <ToggleSwitch disabled={false} checked={toggleSwitch} onChange={handleToggle} />
        <input
          data-testid="review-link-input"
          value={reviewLink}
          onChange={(e) => saveReviewLink(e.target.value)}
          className="mt-3"
          placeholder="Review website link"
        />

        {!isValidLink(encodeURI(reviewLink)) && (
          <p data-testid="review-link-error" className="link-error mt-2 ml-2">
            {intl.formatMessage({
              id: 'request.review.link.error',
              defaultMessage: 'Please enter a valid URL'
            })}
          </p>
        )}

        <div className="request-review-info">
          <p>
            {intl.formatMessage({
              id: 'request.review.info',
              defaultMessage: 'Grow your business by collecting rating and reviews.'
            })}
          </p>
        </div>
      </div>
    </>
  );
};
