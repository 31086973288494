import React from 'react';
import NumericInput from 'react-numeric-input';
import TextareaAutosize from 'react-textarea-autosize';

import { injectIntl, defineMessages } from 'src/i18n';

import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import { CurrencyDisplay } from '../Currency';
import InvoiceItemModel from 'src/models/InvoiceItemModel';
import ItemSuggest from '../ItemSuggest';
import InlineButton from '../Form/InlineButton';
import ConfirmDelete from '../Form/ConfirmDelete';
import ItemQuantityInput from '../ItemQuantityInput';
import './InvoiceItemsListMobileEdit.scss';
import { getItemTotal } from '@invoice-simple/calculator';
import { ISIntl } from 'src/i18n/utils';

const messages = defineMessages({
  item: {
    id: 'invoice.mobileEdit.item',
    defaultMessage: 'item'
  },
  price: {
    id: 'invoice.mobileEdit.price',
    defaultMessage: 'price'
  },
  additionalDetails: {
    id: 'invoice.itemRow.additionalDetails',
    defaultMessage: 'additional details'
  }
});

interface Props {
  store?: AppStore;
  intl?: ISIntl;
}

@injectIntl
@injectStore
export default class InvoiceItemList extends React.Component<Props, {}> {
  render() {
    const store = this.props.store;

    const items = store
      ? store.doc.visibleItems.map((item, i, allItems) => (
          <InvoiceItemRow
            item={item}
            key={item._key}
            index={i}
            isLast={i === allItems.length - 1}
            isMarkingUpPaymentFees={store.doc.isMarkingUpPaymentFees}
          />
        ))
      : [];
    if (items.length === 0) {
      return (
        <div className="content-block">
          <InlineButton
            icon="plus"
            label={this.props.intl!.ft(messages.item)}
            onClick={store && store.doc.addItem}
          />
        </div>
      );
    }
    return <div className="invoice-items-list content-block">{items}</div>;
  }
}

interface ItemProps {
  store?: AppStore;
  item: InvoiceItemModel;
  index: number;
  isLast?: boolean;
  intl?: ISIntl;
  isMarkingUpPaymentFees: boolean;
}

@injectIntl
@injectStore
class InvoiceItemRow extends React.Component<ItemProps, {}> {
  render() {
    const { item } = this.props;
    const store = this.props.store!;
    return (
      <div className="invoice-item-container">
        <ItemSuggest
          value={item.code}
          disabled={store.isLoading}
          suggest={store.user.itemSuggest}
          onChange={item.setCode}
          onSelect={item.setItem}
        />
        <TextareaAutosize
          style={{ height: 60, margin: '6px 0 3px' }}
          disabled={store.isLoading}
          className="item-description-input"
          placeholder={this.props.intl!.ft(messages.additionalDetails)}
          minRows={3}
          maxRows={10}
          value={item.description}
          onChange={(e) => item.setDescription(e.target.value)}
        />
        <div className="numbers-container">
          <div className="price-quantity">
            <span style={{ minWidth: '80px' }}>
              <NumericInput
                style={false}
                type="number"
                size={8}
                disabled={store.isLoading}
                placeholder={this.props.intl!.ft(messages.price)}
                step={1}
                precision={2}
                value={item.userSetRate ? item.rate : undefined}
                onChange={item.setRate}
                maxLength={20}
                className={`${this.props.isMarkingUpPaymentFees ? 'animate' : ''}`}
              />
            </span>
            <span className="multiplication-sign">✕</span>
            <ItemQuantityInput
              placeholder="0"
              disabled={store.isLoading}
              style={{ width: '25%', minWidth: '60px' }}
              value={item.userSetQuantity ? item.quantity : 0}
              onChange={item.setQuantity}
            />
          </div>
          <div>
            <CurrencyDisplay className="amount" value={+getItemTotal(item)} />
          </div>
        </div>
        <div className="invoice-item-actions">
          <ConfirmDelete onConfirm={item.toggleDeleted} />
          {this.props.isLast ? (
            <InlineButton
              icon="plus"
              label={this.props.intl!.ft(messages.item)}
              onClick={store && store.doc.addItem}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
