import React, { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';
import { defineMessages, injectIntl } from 'src/i18n';
import { compose } from 'src/util/functions';
import { CheckIcon, ExclamationIcon } from '../Icons';
import { ISIntl } from 'src/i18n/utils';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    width: 'fit-content',
    borderRadius: '1rem',
    maxWidth: '90%'
  },
  overlay: {
    position: 'fixed',
    inset: '0px',
    backgroundColor: 'rgba(68, 68, 68, 0.75)',
    zIndex: 50
  } as React.CSSProperties
};

const messages = defineMessages({
  continueButton: {
    id: 'dialog.modal.continue.button',
    defaultMessage: 'Continue'
  },
  cancelButton: {
    id: 'dialog.modal.cancel.button',
    defaultMessage: 'Cancel'
  }
});

interface DialogModalProps {
  intl: ISIntl;
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  type: 'success' | 'alert' | 'general';
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onContinue: () => void;
  onCancel: () => void;
}

const DialogModal = ({
  intl,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  type,
  open,
  setOpen,
  onContinue,
  onCancel
}: DialogModalProps) => {
  if (process.env.NODE_ENV !== 'test') {
    // Hide page content for screenreaders when modal is open
    Modal.setAppElement('#app');
  }

  return (
    <Modal isOpen={open} style={customStyles} ariaHideApp={process.env.NODE_ENV !== 'test'}>
      <div
        id="tailwind"
        className="bg-white px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
        <DialogBody title={title} message={message} type={type} />
        <DialogButtons
          intl={intl}
          setOpen={setOpen}
          type={type}
          confirmButtonText={confirmButtonText}
          cancelButtonText={cancelButtonText}
          onContinue={onContinue}
          onCancel={onCancel}
        />
      </div>
    </Modal>
  );
};

const DialogBody = ({
  title,
  message,
  type
}: Pick<DialogModalProps, 'title' | 'message' | 'type'>) => (
  <div className="sm:flex sm:items-start">
    <DialogIcon type={type} />
    <div className="max-w-2xl mt-3 text-center text-sm-left sm:text-left sm:mt-0 sm:ml-4">
      <span className="text-3xl leading-6 font-medium text-gray-900">{title}</span>
      <div className="mt-2">
        <p className="text-2xl text-gray-500">{message}</p>
      </div>
    </div>
  </div>
);

const DialogIcon = ({ type }: Pick<DialogModalProps, 'type'>) => {
  switch (type) {
    case 'alert':
      return (
        <div className="mr-auto ml-auto mr-sm-4 flex-shrink-0 flex items-center justify-center rounded-full bg-red-100 h-14 w-14">
          <ExclamationIcon className="h-12 w-12 text-red-600" aria-hidden="true" />
        </div>
      );
    case 'success':
      return (
        <div className="mr-auto ml-auto mr-sm-4 flex-shrink-0 flex items-center justify-center rounded-full bg-green-100 h-14 w-14">
          <CheckIcon className="h-12 w-12 text-green-600" aria-hidden="true" />
        </div>
      );
    case 'general':
    default:
      return null;
  }
};

const DialogButtons = ({
  intl,
  setOpen,
  type,
  confirmButtonText,
  cancelButtonText,
  onContinue,
  onCancel
}: Pick<
  DialogModalProps,
  'intl' | 'setOpen' | 'type' | 'onContinue' | 'onCancel' | 'confirmButtonText' | 'cancelButtonText'
>) => {
  const continueBtnColourStyles =
    type === 'alert'
      ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
      : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500';
  const f = intl.formatMessage;
  const confirmText = confirmButtonText || f(messages.continueButton);
  const cancelText = cancelButtonText || f(messages.cancelButton);
  return (
    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        type="button"
        id="dialogModalContinueBtn"
        className={`${continueBtnColourStyles} mt-sm-3 w-full inline-flex justify-center rounded-xl border border-transparent shadow-sm px-5 py-3 text-2xl font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto`}
        onClick={() => {
          onContinue();
          setOpen(false);
        }}>
        {confirmText}
      </button>
      {type !== 'success' && (
        <button
          type="button"
          id="dialogModalCancelBtn"
          className="mt-3 w-full inline-flex justify-center rounded-xl border border-gray-300 shadow-sm px-5 py-3 bg-white text-2xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto"
          onClick={() => {
            onCancel();
            setOpen(false);
          }}>
          {cancelText}
        </button>
      )}
    </div>
  );
};

export default compose(injectIntl)(DialogModal);
