import { observable, computed, reaction, action, comparer, IReactionDisposer } from 'mobx';

import { v1 as uuid } from 'uuid';
import compact from 'lodash/compact';
import omit from 'lodash/omit';

import * as ClientAPI from 'src/apis/clientAPI';
import { Client as ParseClient } from '../util/IsParseDomain';
import environmentStore from 'src/stores/EnvironmentStore';
import { getValueOrDefault } from 'src/util/getValueOrDefault';
import SyncableEntity from './SyncableEntity';

export default class ClientModel extends SyncableEntity {
  autoSave?: IReactionDisposer;

  @observable remoteId: string;
  @observable email: string;
  @observable name: string;
  @observable address1: string;
  @observable address2: string;
  @observable address3: string;
  @observable phone: string;
  @observable mobile: string;
  @observable fax: string;

  get = getValueOrDefault(this.default);

  constructor(data: any = {}) {
    super(ParseClient, ClientAPI, 'client');
    this.update(data);
  }

  @computed
  get isNamed() {
    return !!this.name;
  }
  @computed
  get isFilled() {
    return !!this.name || !!this.email || !!this.address1;
  }
  @computed
  get address() {
    return compact([this.address1, this.address2, this.address3]).join(', ');
  }
  @computed
  get parseData() {
    return {
      id: this.id,
      remoteId: this.remoteId,
      email: this.email,
      billingName: this.name.trim(),
      billingAddress1: this.address1,
      billingAddress2: this.address2,
      billingAddress3: this.address3,
      phone: this.phone,
      mobile: this.mobile,
      fax: this.fax,
      deleted: this.deleted
    };
  }
  @computed
  get autoSaveData() {
    return omit(this.parseData, ['id', 'remoteId']);
  }

  startAutoSave() {
    if (this.autoSave) {
      return;
    }

    this.autoSave = reaction(() => this.autoSaveData, this.save.bind(this), {
      equals: comparer.structural,
      name: 'ClientAutosave',
      delay: environmentStore.debounceRate
    });
  }

  protected default(name: string): any {
    return {
      remoteId: uuid(),
      email: '',
      billingName: '',
      billingAddress1: '',
      billingAddress2: '',
      billingAddress3: '',
      phone: '',
      mobile: '',
      fax: '',
      deleted: false
    }[name];
  }

  @action
  protected update(client: Parse.Object | any = {}): void {
    this.id = client.id;
    this.remoteId = this.get(client, 'remoteId');
    this.email = this.get(client, 'email');
    this.name = this.get(client, 'billingName');
    this.address1 = this.get(client, 'billingAddress1');
    this.address2 = this.get(client, 'billingAddress2');
    this.address3 = this.get(client, 'billingAddress3');
    this.phone = this.get(client, 'phone');
    this.mobile = this.get(client, 'mobile');
    this.fax = this.get(client, 'fax');
    this.deleted = this.get(client, 'deleted');
  }
}
