import { DEFAULT_DICTIONARY, Dictionary } from '@invoice-simple/invoice-viewer';

import { ServerData } from 'src/types/ServerData';

export interface LocaleShape {
  messages: Record<string, any>;
  viewerDictionary: Dictionary;
  name: string;
}
const defaultLocale: LocaleShape = {
  messages: {},
  viewerDictionary: DEFAULT_DICTIONARY,
  name: 'en-US'
};

class DefaultsStore {
  get requestId(): string | undefined {
    if (!window.__is_defaults) {
      return undefined;
    }
    return window.__is_defaults.app.request.id;
  }

  get locale(): LocaleShape {
    const locale = (window.__is_defaults && window.__is_defaults.locale) || defaultLocale;
    if (locale && locale.name === null) {
      return {
        ...locale,
        name: 'en-US'
      };
    }
    return locale;
  }

  get bootstrapData(): ServerData {
    // @ts-ignore
    return window.__is_tweak_data ? window.__is_tweak_data(window.initialData) : window.initialData;
  }

  get abTests() {
    try {
      if (this.bootstrapData.account && this.bootstrapData.account.abtests) {
        return this.bootstrapData.account.abtests || {};
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  }

  get advocateReferralCode() {
    if (this.bootstrapData?.account?.advocateReferralCode) {
      return this.bootstrapData.account.advocateReferralCode || '';
    }
    return '';
  }

  get accountSource() {
    if (this.bootstrapData?.account?.source) {
      return this.bootstrapData.account.source || '';
    }
    return '';
  }
}

export const defaultsStore = new DefaultsStore();
