import React from 'react';

export type BonusIconProps = React.ComponentPropsWithRef<'svg'>;

export const BonusIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5.76355C5 4.1067 6.34315 2.76355 8 2.76355C8.76836 2.76355 9.46924 3.0524 10 3.52745C10.5308 3.0524 11.2316 2.76355 12 2.76355C13.6569 2.76355 15 4.1067 15 5.76355C15 6.11419 14.9398 6.45077 14.8293 6.76355H16C17.1046 6.76355 18 7.65898 18 8.76355C18 9.86812 17.1046 10.7635 16 10.7635H11V9.76355C11 9.21127 10.5523 8.76355 10 8.76355C9.44772 8.76355 9 9.21126 9 9.76355V10.7635H4C2.89543 10.7635 2 9.86812 2 8.76355C2 7.65898 2.89543 6.76355 4 6.76355H5.17071C5.06015 6.45077 5 6.11419 5 5.76355ZM9 6.76355V5.76355C9 5.21127 8.55228 4.76355 8 4.76355C7.44772 4.76355 7 5.21127 7 5.76355C7 6.31583 7.44772 6.76355 8 6.76355H9ZM12 6.76355C12.5523 6.76355 13 6.31583 13 5.76355C13 5.21127 12.5523 4.76355 12 4.76355C11.4477 4.76355 11 5.21127 11 5.76355V6.76355H12Z"
      fill="#111827"
    />
    <path d="M9 11.7635H3V16.7635C3 17.8681 3.89543 18.7635 5 18.7635H9V11.7635Z" fill="#111827" />
    <path
      d="M11 18.7635H15C16.1046 18.7635 17 17.8681 17 16.7635V11.7635H11V18.7635Z"
      fill="#111827"
    />
  </svg>
);

BonusIcon.displayName = 'BonusIcon';
