import { DocType } from '@invoice-simple/common';
import { FeatureName } from '@invoice-simple/feature-gate';

import LocationModel from 'src/models/LocationModel';
import UserModel from 'src/models/UserModel';
import { BaseURLQueryParamKeys, URLQueryParamKeys } from './url';

export const navToPaywallWithFeature = (feature: FeatureName) => {
  LocationModel.navAndScrollTop('subscription', { feature });
};

export const navToPaywallWithFeatureAndRef = (feature: FeatureName, ref: string) => {
  LocationModel.navAndScrollTop('subscription', { feature, ref });
};

export const navInvoiceLimit = (user: UserModel) => {
  if (!user.isSubActive) {
    if (user.isGuest) {
      return LocationModel.nav('signup', { ref: URLQueryParamKeys.CREATE_DOCUMENT });
    }
    return LocationModel.nav('subscription', { ref: URLQueryParamKeys.CREATE_DOCUMENT });
  }
  return LocationModel.nav('subscription');
};

interface NavFreeTrialProps {
  user: UserModel;
  ref: BaseURLQueryParamKeys;
  documentId?: string;
  docType?: DocType;
}

export const navFreeTrial = ({ user, ref, documentId, docType }: NavFreeTrialProps) => {
  if (user.isGuest) {
    return documentId && typeof docType === 'number'
      ? LocationModel.nav('signup', { ref, id: documentId, 'email-docType': docType })
      : LocationModel.nav('signup', { ref });
  }
  return LocationModel.nav('subscription', { ref });
};
