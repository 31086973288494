import React from 'react';

/**
 * Page header containing Invoice Simple logo.
 */
export const BrandHeader = () => {
  return (
    <header className="hidden md:flex justify-center bg-white h-[64px] mb-20 w-full border-b border-b-[#E5E7EB]">
      <img
        className="h-[48px] sm:h-18 rounded self-center"
        src="/images/is-logo.png"
        alt="Invoice Simple Logo"
      />
    </header>
  );
};
