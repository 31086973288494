import { SubscriptionTier } from '@invoice-simple/common';

import { useISIntl } from 'src/i18n/utils';
import UserModel from 'src/models/UserModel';
import { messages } from '../utils/messages';

type IncludedFeatures = {
  includedFeatures: string[];
};

type Tiers = {
  [tier in Extract<
    SubscriptionTier,
    // Extracting only the OG tiers since this is used for the old tiered paywall only
    SubscriptionTier.STARTER | SubscriptionTier.PRO | SubscriptionTier.PREMIUM
  >]: IncludedFeatures;
};

interface TiersProps {
  user: UserModel;
  shouldShowPhotosFeature?: boolean;
}

export const useTiers = ({ user, shouldShowPhotosFeature }: TiersProps) => {
  const { formatMessage, ft } = useISIntl();

  const commonFeatures = [ft(messages.support), ft(messages.cancelAnytime)];
  const starterLimit = formatMessage(messages.starterDocLimit, {
    starterQuota: user.tierOneMonthlyQuota
  });
  const unlimitedInvoices = ft(messages.proDocLimit);
  const uploadPhotos = 'Upload photos';
  const accessToMobileApps = ft(messages.accessToMobileApps);
  const advancedReporting = ft(messages.advancedReporting);
  const featuresHighlighted = [uploadPhotos, advancedReporting, accessToMobileApps];
  const photosFeauture = shouldShowPhotosFeature ? [uploadPhotos] : [];

  const tiers: Tiers = {
    [SubscriptionTier.STARTER]: {
      includedFeatures: [starterLimit, ...commonFeatures, accessToMobileApps]
    },
    [SubscriptionTier.PRO]: {
      includedFeatures: [
        unlimitedInvoices,
        ...commonFeatures,
        ...photosFeauture,
        accessToMobileApps
      ]
    },
    [SubscriptionTier.PREMIUM]: {
      includedFeatures: [
        unlimitedInvoices,
        ...commonFeatures,
        ...photosFeauture,
        accessToMobileApps,
        advancedReporting
      ]
    }
  };

  return { tiers, featuresHighlighted };
};
