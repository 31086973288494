import { useEffect, useState } from 'react';

import { ModalName, useModalManagerContext } from '../../components/ModalManagerContext';

interface ModalConfig {
  modalName: ModalName;
  isEligibleForModal?: (() => Promise<boolean>) | (() => boolean);
  showIfEligible?: boolean;
}

interface UsePromoModal {
  shouldShowModal: boolean;
  showModal: () => void;
  hideModal: () => void;
}

interface UsePromoModalParams {
  modalConfig: ModalConfig;
  initialState: boolean;
}

export const usePromoModal = ({
  modalConfig,
  initialState
}: UsePromoModalParams): UsePromoModal => {
  const { modalName, isEligibleForModal, showIfEligible } = modalConfig;
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(initialState);
  const { setModalNameVisible } = useModalManagerContext();

  useEffect(() => {
    if (showIfEligible) {
      showModalIfEligible();
    }

    async function showModalIfEligible() {
      const isEligible = await isEligibleForModal?.();
      if (isEligible !== undefined) {
        isEligible ? showModal() : hideModal();
      }
    }
  }, []);

  function showModal() {
    setModalNameVisible(modalName);
    setShouldShowModal(true);
  }

  function hideModal() {
    setModalNameVisible(ModalName.None);
    setShouldShowModal(false);
  }

  return {
    shouldShowModal,
    showModal,
    hideModal
  };
};
