import { ConsentStatus, ConsentType } from '@invoice-simple/common';
import UserModel from 'src/models/UserModel';
import { saveConsentData } from './consentAPI';
import Rollbar from 'src/analytics/rollbar';

export interface SaveConsentAccountInput {
  consent: boolean;
  message: string;
  user: UserModel;
}

export interface SaveConsentAccountData {
  consentType: ConsentType;
  consentStatus: ConsentStatus;
  consentValue: string;
  consentMessage: string;
}

export const saveSignUpAccountConsentData = async (
  input: SaveConsentAccountInput
): Promise<void> => {
  const { consent, message, user } = input;
  const email = user.signupData.email;

  const data: SaveConsentAccountData[] = [
    {
      consentType: ConsentType.EMAIL_PROMOTIONAL,
      consentValue: email,
      consentStatus: consent ? ConsentStatus.CONSENT_GIVEN : ConsentStatus.CONSENT_NOT_GIVEN,
      consentMessage: message
    },
    {
      consentType: ConsentType.EMAIL_TRANSACTIONAL,
      consentValue: email,
      consentStatus: consent ? ConsentStatus.CONSENT_GIVEN : ConsentStatus.CONSENT_IMPLICIT,
      consentMessage: message
    }
  ];

  try {
    await saveConsentData({ user, data });
  } catch (error) {
    Rollbar.trackError('Error while saving consent details', error, data);
  }
};
