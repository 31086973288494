import React from 'react';
import { SVGProps } from 'react';

export const Templates2Icon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M3.75 5.94971C3.75 5.25935 4.30964 4.69971 5 4.69971H20C20.6904 4.69971 21.25 5.25935 21.25 5.94971V8.44971C21.25 9.14006 20.6904 9.69971 20 9.69971H5C4.30964 9.69971 3.75 9.14006 3.75 8.44971V5.94971Z"
        fill="#FF5721"
      />
      <path
        d="M3.75 13.4497C3.75 12.7594 4.30964 12.1997 5 12.1997H12.5C13.1904 12.1997 13.75 12.7594 13.75 13.4497V20.9497C13.75 21.6401 13.1904 22.1997 12.5 22.1997H5C4.30964 22.1997 3.75 21.6401 3.75 20.9497V13.4497Z"
        fill="#FF5721"
      />
      <path
        d="M17.5 12.1997C16.8096 12.1997 16.25 12.7594 16.25 13.4497V20.9497C16.25 21.6401 16.8096 22.1997 17.5 22.1997H20C20.6904 22.1997 21.25 21.6401 21.25 20.9497V13.4497C21.25 12.7594 20.6904 12.1997 20 12.1997H17.5Z"
        fill="#FF5721"
      />
    </svg>
  );
};
