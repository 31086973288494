import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { DocTypes } from '@invoice-simple/common';

import './ClientRow.css';

import { docTypeToMessage, docTypeToPluralMessage, ISIntl, useISIntl } from 'src/i18n';
import ClientModel from 'src/models/ClientModel';
import InvoiceModel from 'src/models/InvoiceModel';
import LocationModel from 'src/models/LocationModel';
import UserModel from 'src/models/UserModel';
import AppStore from 'src/stores/AppStore';
import { isMigratedEditorEnabled } from 'src/util/isMigratedEditorEnabled';
import { navInvoiceLimit } from 'src/util/navigation';
import { URLQueryParamKeys } from 'src/util/url';
import { CurrencyDisplay } from '../Currency';
import { useDialog } from '../DialogModal/useDialog';
import { KebabMenu, MenuItem } from '../DropdownMenu/DropdownMenu';
import { PaginatedClientsResponse } from './usePaginatedClients';

export interface Props {
  index: number;
  client: PaginatedClientsResponse['clients'][0];
  onClick: (objectId: string) => void;
  intl: ISIntl;
  invoiceCount: number;
  totalBilled: number;
  isLoading: boolean;
}

const ClientRow: React.FC<Props> = (props) => {
  const { client, onClick } = props;
  const { formatMessage } = useISIntl();
  const { openDialogModal } = useDialog();
  const queryClient = useQueryClient();

  const userModel = AppStore.user;
  const clientModel = new ClientModel({ id: client.objectId, ...client });

  const createInvoice = async (data: any = {}) => {
    const invoice = await InvoiceModel.createFromClient(userModel, clientModel, {
      docType: DocTypes.DOCTYPE_INVOICE,
      ...data
    });
    return invoice;
  };

  const createEstimate = async (data: any = {}) => {
    const estimate = await InvoiceModel.createFromClient(userModel, clientModel, {
      docType: DocTypes.DOCTYPE_ESTIMATE,
      ...data
    });
    return estimate;
  };

  const handleClick = () => {
    onClick(client.objectId);
  };

  const handleNewInvoice = async () => {
    if (!userModel.canCreateNewDoc(DocTypes.DOCTYPE_INVOICE)) {
      return navInvoiceLimit(userModel);
    }

    try {
      const newInvoice = await createInvoice();
      queryClient.invalidateQueries();

      if (isMigratedEditorEnabled(UserModel.getInstance().countryCode)) {
        window.location.assign(`${process.env.REACT_APP_URL}/invoices/${newInvoice.id}`);
      } else {
        LocationModel.navAndScrollTop('invoiceEdit', { id: newInvoice.id });
      }
    } catch (e) {
      LocationModel.navAndScrollTop('subscription', { ref: URLQueryParamKeys.CREATE_DOCUMENT });
    }
  };

  const handleNewEstimate = async () => {
    try {
      const newEstimate = await createEstimate();
      queryClient.invalidateQueries();

      if (isMigratedEditorEnabled(UserModel.getInstance().countryCode)) {
        window.location.assign(`${process.env.REACT_APP_URL}/estimates/${newEstimate.id}`);
      } else {
        LocationModel.navAndScrollTop('estimateEdit', { id: newEstimate.id });
      }
    } catch (e) {
      LocationModel.navAndScrollTop('subscription', { ref: URLQueryParamKeys.CREATE_DOCUMENT });
    }
  };

  const handleDelete = () => {
    const title = `Delete Client`;
    const message = `Are you sure you want to delete Client ${client.billingName}?`;
    const alertConfig = {
      title,
      message,
      type: 'alert' as const,
      onContinue: async () => {
        await clientModel.load();
        clientModel.deleted = true;
        await clientModel.save();
        queryClient.invalidateQueries();
      }
    };
    openDialogModal(alertConfig);
  };

  const hasName = client.billingName && client.billingName.length > 0;
  const name = hasName ? client.billingName : `Client #${props.index}`;
  const rowClass = hasName ? 'client-row' : 'client-row client-row-empty';
  const invoiceText =
    props.invoiceCount === 1
      ? formatMessage(docTypeToMessage(DocTypes.DOCTYPE_INVOICE))
      : formatMessage(docTypeToPluralMessage(DocTypes.DOCTYPE_INVOICE));

  return (
    <tr className={rowClass} key={client.remoteId} onClick={handleClick}>
      <td className="client-col-name">
        <p>{name}</p>
        {!props.isLoading && (
          <p className="client-invoice-count">{`${props.invoiceCount} ${invoiceText}`}</p>
        )}
      </td>

      <td className="client-col-email">
        <p>{client.email}</p>
      </td>
      <td className="client-col-address">
        <p>{client.billingAddress1}</p>
      </td>
      <td className="client-col-phone">
        <p>{client.phone}</p>
      </td>
      <td
        className="client-col-total-billed"
        style={{
          width: '100px'
        }}>
        <p className="text-right">
          <CurrencyDisplay
            value={props.totalBilled}
            textStyle={{
              opacity: props.isLoading ? 0.5 : 1
            }}
          />
        </p>
      </td>

      <td className="client-col-actions">
        <KebabMenu>
          <MenuItem label="New Invoice" onClick={handleNewInvoice} />
          <MenuItem label="New Estimate" onClick={handleNewEstimate} />
          <MenuItem className="separator-top delete-button" label="Delete" onClick={handleDelete} />
        </KebabMenu>
      </td>
    </tr>
  );
};

export default ClientRow;
