import React, { FC, ReactNode } from 'react';

type Props = {
  title: string;
  description: string;
  icon?: ReactNode;
};

export const ValueProps: FC<Props> = (props: Props) => {
  const { title, description, icon } = props;

  return (
    <div className="flex flex-col px-[15px] py-[10px] md:px-[30px] md:py-[20px] bg-slate-50 justify-center content-center items-start">
      <div data-testid="value-prop-title" className="justify-start items-center gap-3 inline-flex">
        {icon}
        <p className="self-stretch text-gray-800 text-[18px] font-bold">{title}</p>
      </div>
      <p
        data-testid="value-prop-description"
        className="self-stretch text-gray-800 text-[16px] sm:text-[18px] font-normal leading-normal mt-2 tracking-normal">
        {description}
      </p>
    </div>
  );
};
