import Parse from 'parse';
import { Invoice } from '../util/IsParseDomain';
import ErrorListModel from 'src/models/ErrorListModel';
import { API_V3_BASE_PATH, client } from './httpClient';
import UserModel from 'src/models/UserModel';
import { DocType } from '@invoice-simple/common';

const getQueryBase = (): Parse.Query<Parse.Object> => {
  return new Parse.Query<Parse.Object>(Invoice);
};

export const getDocumentById = async (id: string): Promise<Parse.Object> => {
  try {
    const query = getQueryBase();
    return await query.get(id);
  } catch (error) {
    ErrorListModel.pushError(error);
    throw error;
  }
};

export const getById = getDocumentById;

export const getLastDocumentForAccount = async (
  account: Parse.Object,
  docType: DocType
): Promise<Parse.Object | undefined> => {
  try {
    return await new Parse.Query<Parse.Object>(Invoice)
      .equalTo('docType', docType)
      .equalTo('account', account)
      .equalTo('deleted', false)
      .descending('createdAt')
      .select('invoiceNo')
      .first();
  } catch (error) {
    ErrorListModel.pushError(error);
    throw error;
  }
};

export const getInvoiceCsv = async (user: UserModel) => {
  try {
    return await client.get(`${API_V3_BASE_PATH}/invoice-export.csv`, {
      headers: {
        'x-is-session': user.sessionToken
      }
    });
  } catch (error) {
    ErrorListModel.pushError(error);
    throw error;
  }
};
