import React from 'react';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { PaypalSettingsBoxRowWrapper } from '../Common';
import { usePaypalContext } from 'src/payments/contexts/PaypalAccountContext';
import { PaypalInitialBox } from './PaypalInitialBox';
import { PaypalClosedBox } from './PaypalClosedBox';
import { PaypalPendingOnboardedBox } from './PaypalPendingOnboardedBox';
import { PaypalSettingsAcceptingBox } from './PaypalAcceptingBox';
import { PaymentMethod, useCTAHandler } from '../useCTAHandler';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { getPaymentEventSource } from 'src/payments/utils';

const SettingsPaypalBoxComponent = ({ store }: { store: AppStore }) => {
  const paypalSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.paypal,
    source: getPaymentEventSource()
  };
  const paypalAccountContext = usePaypalContext();
  const { handleInitialClick } = useCTAHandler(PaymentMethod.Paypal);

  if (!paypalAccountContext?.isPaypalEligible()) {
    return null;
  }

  const events = store.user.events;

  const {
    showPaypalModal,
    isPaypalInitial,
    isPaypalClosed,
    isPaypalPending,
    isPaypalOnboarded,
    isPrimaryEmailConfirmed,
    isPaypalAccepting
  } = paypalAccountContext;

  function handlePaypalInitialClick() {
    handleInitialClick(store, showPaypalModal);
  }

  function handlePaypalClosedClick() {
    events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.tileClosed
    });
    showPaypalModal();
  }

  function handlePaypalPendingClick() {
    events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: PaymentEventComponent.tilePending
    });
    showPaypalModal();
  }

  function handlePaypalOnboardedClick() {
    events.trackAction('payments-cta', {
      ...paypalSharedMetadata,
      component: isPrimaryEmailConfirmed()
        ? PaymentEventComponent.tileOnboardedEmailConfirmed
        : PaymentEventComponent.tileOnboarded
    });
    showPaypalModal();
  }

  return (
    <div data-testid="IntegratedPaymentsPaypalBox" id="tailwind">
      {isPaypalInitial() && (
        <PaypalSettingsBoxRowWrapper>
          <PaypalInitialBox onCTAClick={handlePaypalInitialClick} />
        </PaypalSettingsBoxRowWrapper>
      )}
      {isPaypalPending() && (
        <PaypalSettingsBoxRowWrapper>
          <PaypalPendingOnboardedBox onCTAClick={handlePaypalPendingClick} />
        </PaypalSettingsBoxRowWrapper>
      )}
      {isPaypalOnboarded() && (
        <PaypalSettingsBoxRowWrapper>
          <PaypalPendingOnboardedBox onCTAClick={handlePaypalOnboardedClick} />
        </PaypalSettingsBoxRowWrapper>
      )}
      {isPaypalAccepting() && <PaypalSettingsAcceptingBox />}
      {isPaypalClosed() && (
        <PaypalSettingsBoxRowWrapper>
          <PaypalClosedBox onCTAClick={handlePaypalClosedClick} />
        </PaypalSettingsBoxRowWrapper>
      )}
    </div>
  );
};

export const SettingsPaypalBox = injectStore(SettingsPaypalBoxComponent);
