export interface InvoiceSentStatus {
  name: string;
  updatedAt: Date | string;
}

export enum InvoiceExportAction {
  PDF = 'pdf',
  EMAIL = 'email',
  PRINT = 'print',
  GET_LINK = 'get_link'
}
