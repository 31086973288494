import React from 'react';
import { useISIntl } from 'src/i18n/utils';
import { ItemGroup } from './ReportTypes';
import { messages } from './ReportMessages';
import FilterButton from './FilterButton';
import ReportModel from 'src/models/ReportModel';

export const ItemReportHeader = () => {
  const { ft } = useISIntl();
  return (
    <tr>
      <th></th>
      <th style={{ width: '75px' }}>{ft(messages.invoices)}</th>
      <th style={{ width: '75px' }}>{ft(messages.quantity)}</th>
      <th style={{ width: '130px' }}>{ft(messages.paid)}</th>
    </tr>
  );
};

interface Props {
  list: ReportModel;
}

export const ItemReport = ({ list }: Props) => {
  const groups = list.reports.groups as ItemGroup[];
  return (
    <>
      <FilterButton list={list} />
      <div className="items-report-list-body panel">
        <table className="table">
          <thead className="thead">
            <ItemReportHeader />
          </thead>
          <tbody>
            {groups.map((group, idg) => {
              return (
                <React.Fragment key={`items-report-body-${idg}`}>
                  <tr key={`items-report-year-${idg}`} className="year">
                    <td colSpan={3}>{group.year}</td>
                    <td>{group.totalFmt}</td>
                  </tr>
                  {group.items.map((itemData, idm) => {
                    return (
                      <tr key={`items-report-year-${idg}-month-${idm}`} className="month">
                        <td>{itemData.item.code}</td>
                        <td className="data">{itemData.count}</td>
                        <td className="data">{itemData.quantities}</td>
                        <td className="data">{itemData.totalFmt}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
