import React from 'react';

import { useISIntl } from 'src/i18n';
import { messages } from '../utils/messages';

interface Props {
  isHidden: boolean;
}

export const Testimonials = ({ isHidden }: Props) => {
  const { ft } = useISIntl();

  if (isHidden) return null;

  return (
    <>
      <h3
        className="text-gray-800 testimonials-title text-center font-semibold mt-[40px] mx-auto sm:mt-[60px] mb-[10px] sm:mb-0 sm:max-w-[500px] tracking-normal"
        style={{ fontSize: 20, lineHeight: '28px' }}>
        {ft(messages.testimonialsTitle)}
      </h3>

      <div className="tracking-normal gap-6 grid grid-cols-1 md:grid-cols-3 mt-[30px]">
        <Testimonial
          imageUrl="/images/faces/face_1.png"
          name={ft(messages.testimonials1Name)}
          message={ft(messages.testimonials1Message)}
        />
        <Testimonial
          imageUrl="/images/faces/face_2.png"
          name={ft(messages.testimonials2Name)}
          message={ft(messages.testimonials2Message)}
        />
        <Testimonial
          imageUrl="/images/faces/face_3.png"
          name={ft(messages.testimonials3Name)}
          message={ft(messages.testimonials3Message)}
        />
      </div>
    </>
  );
};

const Testimonial: React.FC<{
  imageUrl: string;
  name: string;
  message: string;
}> = (props) => {
  const { imageUrl, name, message } = props;
  return (
    <div className="flex flex-row justify-center align-middle">
      <div className="icon flex-grow-0 mr-4 my-auto" style={{ width: 60 }}>
        <div>
          <img
            className="rounded-full"
            style={{ width: 60, height: 60 }}
            src={imageUrl}
            alt="invoices icon"
          />
        </div>
      </div>
      <div
        className="valueProp flex-grow-1 flex flex-col"
        style={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '20px',
          textAlign: 'left'
        }}>
        <div className="flex pb-[13px] space-x-[8px]">
          <img src="/images/icons/star.svg" />
          <img src="/images/icons/star.svg" />
          <img src="/images/icons/star.svg" />
          <img src="/images/icons/star.svg" />
          <img src="/images/icons/star.svg" />
        </div>
        <div className="flex flex-grow items-center">
          <p style={{ color: '#1b1b1b' }}>{message}</p>
        </div>
        <p className="text-gray-700">{name}</p>
      </div>
    </div>
  );
};
