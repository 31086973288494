import React from 'react';
import { observable } from 'mobx';

import EmailInput from '../EmailInput';
import InvoiceModel from 'src/models/InvoiceModel';
import { isValidEmail } from 'src/util/isValidEmail';
import { injectStore } from 'src/util/injectStore';
import { defineMessages } from 'src/i18n';
import { withAnalytics } from 'src/analytics/componentAnalytics';
import {
  PaymentPromoModal,
  usePaymentPromoModal,
  PaymentEventName,
  PaymentEventLocation,
  PaymentModalTriggerName,
  PAYMENT_MODAL_DELAYED_TIME
} from 'src/payments';
import { AppStore } from 'src/stores/AppStore';
import { ISIntl, useISIntl } from 'src/i18n/utils';
import { URLQueryParamKeys } from 'src/util/url';
import { navFreeTrial } from 'src/util/navigation';
import { InvoiceExportAction } from 'src/types/Invoice';

const messages = defineMessages({
  title: {
    id: 'sendToSelfEmail.title',
    defaultMessage: 'Preview via Email'
  },
  buttonLabel: {
    id: 'sendToSelfEmail.button.label',
    defaultMessage: 'Send'
  },
  buttonPendingLabel: {
    id: 'sendToSelfEmail.button.pending.label',
    defaultMessage: 'Sending...'
  },
  inputPlaceholder: {
    id: 'sendToSelfEmail.input.placeholder',
    defaultMessage: 'name@example.com'
  },
  alertSuccess: {
    id: 'sendToSelfEmail.alert.success',
    defaultMessage: 'Successfully sent preview to {invoiceCompanyEmail}'
  },
  alertError: {
    id: 'sendToSelfEmail.alert.error',
    defaultMessage: 'We had a problem sending the preview. Please use "Share Invoice" button below'
  }
});

let state;

const handleSendClick =
  (
    invoice: InvoiceModel,
    trackEvent: (input: string) => Promise<void>,
    intl: ISIntl,
    showPaymentPromoModal: () => void,
    isEligibleForPaymentPromoModal: () => boolean
  ) =>
  async () => {
    if (await invoice.user.canExportDocument(InvoiceExportAction.EMAIL)) {
      state.sending = true;
      trackEvent('self-email-preview-send-clicked');
      try {
        await invoice.draftEmail.sendToSelf();
        state.sent = true;
        setTimeout(() => {
          if (isEligibleForPaymentPromoModal()) {
            showPaymentPromoModal();
            invoice.user.trackAppEventViaApi(PaymentEventName.paymentModalViewed, {
              location: PaymentEventLocation.invoiceEditor,
              trigger: PaymentModalTriggerName.invoicePreviewSend
            });
          }
        }, PAYMENT_MODAL_DELAYED_TIME);
      } catch (error) {
        state.sendError = intl.formatMessage(messages.alertError);
      } finally {
        state.sending = false;
      }
    } else {
      navFreeTrial({ user: invoice.user, ref: URLQueryParamKeys.SEND_DOCUMENT });
    }
  };

const SendToSelf = injectStore(
  ({ store, trackEvent }: { store: AppStore; trackEvent: (input: string) => Promise<void> }) => {
    const intl = useISIntl();
    const invoice: InvoiceModel = store.doc;
    let content;

    const { shouldShowPaymentPromoModal, showPaymentPromoModal, isEligibleForPaymentPromoModal } =
      usePaymentPromoModal(false);
    if (state.sendError) {
      content = <div>{state.sendError}</div>;
    } else if (state.sent && !state.sending) {
      content = (
        <div>
          {intl.formatMessage(messages.alertSuccess, {
            invoiceCompanyEmail: invoice.company.email
          })}
        </div>
      );
    } else {
      content = (
        <>
          <form onSubmit={(e) => e.preventDefault()}>
            <div style={{ marginBottom: 8 }}>
              <EmailInput
                key={`self-email-${invoice.remoteId}`}
                name="self-email"
                placeholder={intl.formatMessage(messages.inputPlaceholder)}
                value={invoice.company.email}
                onChange={(e) => invoice.setCompanyEmail(e.target.value)}
                disabled={state.sending || store.isLoading}
              />
            </div>
          </form>
          <button
            type="button"
            disabled={state.sending || (!isValidEmail(invoice.company.email) && !store.isLoading)}
            className="btn btn-prime btn-block btn-send-email-preview"
            onClick={handleSendClick(
              invoice,
              trackEvent,
              intl,
              showPaymentPromoModal,
              isEligibleForPaymentPromoModal
            )}>
            {state.sending
              ? intl.formatMessage(messages.buttonPendingLabel)
              : intl.formatMessage(messages.buttonLabel)}
          </button>
        </>
      );
    }
    return (
      <div>
        {shouldShowPaymentPromoModal && (
          <PaymentPromoModal
            onTrackEventAndLocation={(eventName, paymentEventLocation) => {
              invoice.user.trackAppEventViaApi(eventName, {
                location: paymentEventLocation
              });
            }}
          />
        )}
        <h4>{intl.formatMessage(messages.title)}</h4>
        {content}
      </div>
    );
  }
);

export default withAnalytics((props) => {
  state = observable({
    sendError: false,
    sending: false,
    sent: false
  });
  return <SendToSelf {...props} />;
});
