import React, { useEffect } from 'react';
import { DepositAmounts, DepositRates, DepositTypes } from '@invoice-simple/common';
import { PaymentDepositProps, messages } from './PaymentDeposit';
import { Selectv1Adapter } from 'src/components/Selectv1Adapter';
import NumericInput from 'react-numeric-input';
import { useISIntl } from 'src/i18n';
import { getDepositRateOptions, getDepositTypeOptions } from './DepositTypeOptions';

export const InvoiceDepositSetup = ({
  document,
  isEligibleForDeposit,
  onComponentShown,
  onInvoiceDepositUpdate,
  deposit
}: PaymentDepositProps & {
  isEligibleForDeposit: boolean;
}) => {
  const { depositRate, depositType, depositAmount } = deposit;
  const { ft } = useISIntl();
  useEffect(() => {
    if (!document.invoiceId) {
      return;
    }
    onComponentShown(isEligibleForDeposit);
  }, [document.invoiceId, isEligibleForDeposit]);

  useEffect(() => {
    if (!isEligibleForDeposit && (!!depositType || !!depositRate || !!depositAmount)) {
      onInvoiceDepositUpdate({
        depositType: DepositTypes.NONE,
        depositRate: DepositRates.DEPOSIT_NONE,
        depositAmount: DepositAmounts.DEPOSIT_NONE
      });
    }
  }, [depositType, depositRate, depositAmount, isEligibleForDeposit]);

  const DEPOSIT_TYPE_OPTIONS = getDepositTypeOptions();
  const DEPOSIT_RATE_OPTIONS = getDepositRateOptions();

  return (
    <div className="payment-deposit">
      <h4 data-testid={'deposit-title'}>{ft(messages.deposit)}</h4>

      <div className="setting-form-group form-group row">
        <div className="col-md-6 col-lg-12">
          <label className="col-form-label">
            <>{ft(messages.requestDeposits)}</>
          </label>
          <table className="invoice-detail-about">
            <tbody>
              <tr>
                <td>
                  <label htmlFor="invoice-deposit-type">{ft(messages.depositType)}</label>
                </td>
                <td>
                  <Selectv1Adapter
                    id="invoice-deposit-type"
                    className="invoice-deposit-type-input"
                    name="invoiceDepositType"
                    isClearable={false}
                    value={depositType}
                    options={DEPOSIT_TYPE_OPTIONS}
                    onChange={(newType) => {
                      onInvoiceDepositUpdate({
                        ...deposit,
                        depositType: newType.value
                      });
                    }}
                  />
                </td>
              </tr>
              {depositType === DepositTypes.PERCENT && (
                <tr>
                  <td>
                    <label htmlFor="invoice-deposit-rate">{ft(messages.depositAmount)}</label>
                  </td>
                  <td>
                    <Selectv1Adapter
                      id="invoice-deposit-rate"
                      className="invoice-deposit-rate-input"
                      name="invoiceDepositRate"
                      isClearable={false}
                      value={depositRate}
                      options={DEPOSIT_RATE_OPTIONS}
                      onChange={(newRate) => {
                        onInvoiceDepositUpdate({
                          ...deposit,
                          depositRate: newRate.value
                        });
                      }}
                    />
                  </td>
                </tr>
              )}
              {depositType === DepositTypes.FLAT && (
                <tr>
                  <td>
                    <label htmlFor="invoice-deposit-flat">{ft(messages.depositAmount)}</label>
                  </td>
                  <td>
                    <NumericInput
                      className="invoice-deposit-flat-input"
                      id="invoice-deposit-flat"
                      value={depositAmount}
                      precision={2}
                      min={0}
                      onChange={(v) => {
                        onInvoiceDepositUpdate({
                          ...deposit,
                          depositAmount: v ?? 0
                        });
                      }}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
