import Parse from 'parse';
import isPromise from '../util/IsPromise';

export async function parseUserLogout(): Promise<Parse.User<Parse.Attributes>> {
  return Parse.User.logOut();
}

export async function parseUserSaveData(data: any): Promise<Parse.User<Parse.Attributes>> {
  const user = Parse.User.current();
  if (!user) {
    throw new Error('No current user');
  }
  return user.save(data, { sessionToken: user.getSessionToken() });
}

export async function parseUserFetchData(
  userId: string
): Promise<Parse.User<Parse.Attributes> | undefined> {
  const q = new Parse.Query(Parse.User);
  q.equalTo('objectId', userId);
  return isPromise(q.first());
}
