import React, { useEffect, useState } from 'react';
import { v1 as uuid } from 'uuid';
import { FeatureName } from '@invoice-simple/feature-gate';
import { Platform } from '@invoice-simple/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';

import Rollbar from 'src/analytics/rollbar';
import { getPhoto, updatePhoto, uploadPhoto } from 'src/apis/photosAPI';
import { LockIcon } from 'src/components/Icons/LockIcon';
import { getCloudinaryUrl } from 'src/components/InvoicePhotos/getCloudinaryUrl';
import { LocalizedDate } from 'src/components/Localized/LocalizedDate';
import AlertModel from 'src/models/AlertModel';
import InvoiceModel from 'src/models/InvoiceModel';
import LocationModel from 'src/models/LocationModel';
import UserModel from 'src/models/UserModel';
import { stringToDate } from 'src/util/date';
import { navToPaywallWithFeature } from 'src/util/navigation';
import { Photo as ParsePhoto } from 'src/util/IsParseDomain';

import { SignaturePhotoActions } from './SignaturePhotoActions';
import { SignatureModal } from './SignatureModal';

import './InvoiceSignature.css';

interface InvoiceSignatureProps {
  invoice: InvoiceModel;
}
export const InvoiceSignature = ({ invoice }: InvoiceSignatureProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState<string | null>(null);

  const user = UserModel.getInstance();
  const hasSignatureAccess = user.canUseFeature(FeatureName.SIGNATURE);

  useEffect(() => {
    if (invoice.signature) {
      getSignaturePhotoUrl();
    }

    async function getSignaturePhotoUrl() {
      try {
        const photo = await getPhoto(invoice.signature!);
        if (photo) {
          setImgUrl(getCloudinaryUrl(photo.attributes.url, 'c_fit,w_400,h_400'));
        }
      } catch (err) {
        Rollbar.trackError('Error getting signature photo url', err, { invoice });
      }
    }
  }, [invoice.signature]);

  async function onSave(dataUrl: string) {
    let signatureInfo;
    try {
      if (invoice.signature) {
        signatureInfo = await updateSignature(dataUrl);
      } else {
        signatureInfo = await saveNewSignature(dataUrl);
      }
      user.events.trackAction('invoice-signature-save', {
        documentId: invoice.id,
        docType: invoice.docType,
        platform: Platform.WEB,
        ...signatureInfo
      });
    } catch (err) {
      AlertModel.setAlert(
        'danger',
        'Error saving signature. Please try again.',
        'Unable to save signature'
      );
      LocationModel.scrollTop();
      Rollbar.trackError('Error saving signature', err, { invoice });
    }
  }

  function getFormattedSignDate(): string {
    return format(new Date(), 'yyyy-LL-dd');
  }

  async function createPhotoFile(dataUrl: string) {
    const blob = await (await fetch(dataUrl)).blob();
    const file = new File([blob], '', { type: blob.type });
    return file;
  }

  async function saveNewSignature(dataUrl: string) {
    const file = await createPhotoFile(dataUrl);
    const { uploadRes, urlRes } = await uploadPhoto({
      accountId: user.accountId,
      file,
      apiReqOpts: user.getApiReqOpts()
    });
    const signaturePhotoRemoteId = uuid();
    const photo = new ParsePhoto({
      remoteId: signaturePhotoRemoteId,
      deleted: false,
      url: urlRes.data.url,
      md5: uploadRes.headers.etag,
      updated: Date.now()
    });
    await photo.save();
    const signDate = getFormattedSignDate();
    await invoice.saveSignature({
      signaturePhotoRemoteId,
      signaturePhotoUrl: urlRes.data.url,
      signDate
    });
    return { signatureRemoteId: signaturePhotoRemoteId, signatureUrl: urlRes.data.url, signDate };
  }

  async function updateSignature(dataUrl: string) {
    if (!invoice.signature) return;
    const file = await createPhotoFile(dataUrl);
    const { uploadRes, urlRes } = await uploadPhoto({
      accountId: user.accountId,
      file,
      apiReqOpts: user.getApiReqOpts()
    });
    await updatePhoto(invoice.signature, {
      url: urlRes.data.url,
      md5: uploadRes.headers.etag
    });
    const signDate = getFormattedSignDate();
    await invoice.saveSignature({
      signaturePhotoRemoteId: invoice.signature,
      signaturePhotoUrl: urlRes.data.url,
      signDate
    });
    setImgUrl(getCloudinaryUrl(urlRes.data.url, 'c_fit,w_400,h_400'));
    return { signatureRemoteId: invoice.signature, signatureUrl: urlRes.data.url, signDate };
  }

  function maybeOpenSignatureModal() {
    // Nav to paywall with upgrade message if user doesn't have signature access
    if (!hasSignatureAccess) {
      return navToPaywallWithFeature(FeatureName.SIGNATURE);
    }
    return setIsModalOpen(true);
  }

  return (
    <div style={{ margin: '25px 0', display: !imgUrl && !hasSignatureAccess ? 'flex' : 'block' }}>
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        {!hasSignatureAccess && <LockIcon style={{ width: '20px', marginRight: '0.3rem' }} />}
        <h3
          className="invoice-signature-header"
          style={{
            display: 'inline-block',
            fontSize: '14px',
            marginRight: '0.4rem',
            marginBottom: 0
          }}>
          Signature
        </h3>
      </div>

      {imgUrl ? (
        <div className="invoice-photo" style={{ marginTop: '1rem' }}>
          <article>
            <header style={{ height: '50px', aspectRatio: 'auto' }}>
              <img
                data-testid="invoice-signature-photo"
                src={imgUrl}
                style={{ display: 'block', objectFit: 'contain' }}
              />
            </header>
            {invoice.signDate && (
              <main>
                <strong>
                  Signed on: <LocalizedDate date={stringToDate(invoice.signDate)} />
                </strong>
              </main>
            )}
            <SignaturePhotoActions
              invoice={invoice}
              onEdit={maybeOpenSignatureModal}
              removeSigFromEditor={() => setImgUrl(null)}
            />
          </article>
        </div>
      ) : (
        <button
          id="invoice-signature-add"
          title="Add signature"
          className={`btn btn-prime`}
          onClick={maybeOpenSignatureModal}
          disabled={false}
          style={{ minWidth: '30px', padding: '4px' }}>
          <FontAwesomeIcon icon="plus" />
        </button>
      )}

      {isModalOpen && <SignatureModal onSave={onSave} onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};
