import React, { FC } from 'react';
import { useStripeContext } from 'src/payments/contexts/StripeAccountContext';
import { getPaymentEventSource, hasCurrencyMismatch } from 'src/payments/utils';
import injectStore from 'src/util/injectStore';
import { AppStore } from 'src/stores/AppStore';
import {
  DefProps,
  ManageAccountButton,
  SettingsAccountDetailsSection,
  StripeSettingsBoxRowWrapper
} from '../../Common';
import { StripeAcceptingBox } from './SupportingComponents/StripeAcceptingBox';
import { PaymentMethod, useCTAHandler } from '../../useCTAHandler';
import { StripeCurrencyMismatchBox } from './SupportingComponents';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';

export const StripeSettingsAcceptingBoxComponent: FC<{ store: AppStore; defProps: DefProps }> = ({
  store,
  defProps
}) => {
  const { handleAcceptingClick, loading } = useCTAHandler(PaymentMethod.Stripe);

  const stripeContext = useStripeContext();
  if (!stripeContext) {
    return null;
  }

  const stripeSharedMetadata = {
    activePaymentProvider: PaymentEventActivePaymentProvider.stripe,
    source: getPaymentEventSource()
  };

  const { isStripeEnabled, updateStripeEnabled, stripePrimaryCurrency } = stripeContext;

  const user = store.user;
  const { currencyCode } = user.settingList;

  const isStripeCurrencyMismatch = hasCurrencyMismatch(stripePrimaryCurrency, currencyCode);

  const fixUserCurrencyMismatch = () => {
    user.settingList.setCurrencyCode(stripePrimaryCurrency?.toUpperCase() ?? 'USD');
  };

  const handleStripeAcceptingClick = () => {
    user.events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: !isStripeEnabled
        ? PaymentEventComponent.tileGlobalEnabled
        : PaymentEventComponent.tileGlobalDisabled
    });
    handleAcceptingClick(!isStripeEnabled, updateStripeEnabled);
  };

  const handleManageStripeAccount = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    user.events.trackAction('payments-cta', {
      ...stripeSharedMetadata,
      component: PaymentEventComponent.manageAccount
    });
    e.preventDefault();
    window.open('https://dashboard.stripe.com/dashboard', '_blank');
  };

  if (isStripeEnabled && isStripeCurrencyMismatch) {
    return (
      <StripeSettingsBoxRowWrapper>
        <StripeCurrencyMismatchBox onFixUserCurrencyMismatchHandler={fixUserCurrencyMismatch} />
      </StripeSettingsBoxRowWrapper>
    );
  }
  const paymentsViewedMeta = {
    ...stripeSharedMetadata,
    component: PaymentEventComponent.manageAccount
  };

  return (
    <>
      <StripeSettingsBoxRowWrapper>
        <StripeAcceptingBox
          onCTAClick={handleStripeAcceptingClick}
          checked={isStripeEnabled}
          disabled={loading}
        />
      </StripeSettingsBoxRowWrapper>
      <SettingsAccountDetailsSection {...defProps} />
      <ManageAccountButton
        paymentsViewedMeta={paymentsViewedMeta}
        onClick={handleManageStripeAccount}>
        Manage Stripe Account
      </ManageAccountButton>
    </>
  );
};

export const StripeSettingsAcceptingBox = injectStore(StripeSettingsAcceptingBoxComponent);
