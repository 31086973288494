import React from 'react';

import { GiftIcon } from 'src/components/Icons';

interface DiscountBannerProps {
  title: string;
  message: string;
}

export const DiscountBanner = ({ title, message }: DiscountBannerProps) => {
  return (
    <div className="flex items-center my-4 gap-x-3 h-fit w-full flex-row px-8 py-3.5 rounded-2xl bg-green-50">
      <GiftIcon className="w-8" />

      <div className="flex flex-col">
        <h3 data-testid="discount-banner-title" className="text-2xl font-semibold text-green-700">
          {title}
        </h3>
        <p className="text-gray-900 text-lg" data-testid="discount-banner-message">
          {message}
        </p>
      </div>
    </div>
  );
};
