import React from 'react';

export interface InputProps extends React.ComponentPropsWithRef<'input'> {
  label: string;
  errorMessage?: string;
}

export const InputWithLabel = React.forwardRef<HTMLInputElement, InputProps>(
  (props, forwardedRef) => {
    const { id, label, errorMessage, required, ...rest } = props;

    return (
      <div className="flex justify-start items-start w-full flex-col relative">
        <label className="text-gray-700 text-[14px] font-semibold leading-tight pb-1" htmlFor={id}>
          {required ? `${label}*` : label}
        </label>
        <input
          {...rest}
          id={id}
          ref={forwardedRef}
          className="w-full px-[13px] py-[9px] rounded-[6px] border border-gray-300 text-gray-900 text-[14px] font-normal leading-tight focus:ring-0"
          data-testid="text-input"
        />
        {errorMessage && <label className="text-red-700 text-[14px] p-[2px]">{errorMessage}</label>}
      </div>
    );
  }
);

InputWithLabel.displayName = 'InputWithLabel';
