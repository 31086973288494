import React from 'react';
import InputWithLabel from '../InputWithLabel';
import injectStore from 'src/util/injectStore';
import invoiceInputFieldsUIStore from 'src/stores/InvoiceInputFieldsUIStore';
import { injectIntl, defineMessages } from 'src/i18n';
import { compose } from 'src/util/functions';
import CurrentDocumentStore from 'src/stores/CurrentDocumentStore';
import { AppStore } from 'src/stores/AppStore';
import { ISIntl } from 'src/i18n/utils';
import { mayBe } from './MaybeRender';

const messages = defineMessages({
  address: {
    id: 'addressInput.address',
    defaultMessage: 'address'
  },
  street: {
    id: 'addressInput.street',
    defaultMessage: 'street'
  },
  city: {
    id: 'addressInput.city',
    defaultMessage: 'city'
  },
  state: {
    id: 'addressInput.state',
    defaultMessage: 'state'
  },
  postalCode: {
    id: 'addressInput.postalCode',
    defaultMessage: 'zip code'
  }
});

const _AddressInput = ({
  store,
  attributesPrefix,
  storeAccessField,
  intl,
  withAutocomplete = false
}: {
  store: AppStore;
  intl: ISIntl;
  attributesPrefix: string;
  storeAccessField: string;
  withAutocomplete?: boolean;
}) => {
  const invoice = CurrentDocumentStore.currentDoc;
  const { fta: t, ft: tt } = intl;
  return (
    <InputWithLabel
      baseline
      label={<label htmlFor={`${attributesPrefix}-address1`}>{t(messages.address)}</label>}
      content={
        <div>
          <input
            type="text"
            disabled={store.isLoading}
            id={`${attributesPrefix}-address1`}
            name={`${attributesPrefix}-address1`}
            placeholder={t(messages.street)}
            autoComplete={withAutocomplete ? 'street-address' : ''}
            value={invoice[storeAccessField].address1}
            onChange={(e) => (invoice[storeAccessField].address1 = e.target.value)}
          />
          {invoice[storeAccessField].address1 && (
            <>
              <input
                type="text"
                disabled={store.isLoading}
                id={`${attributesPrefix}-address2`}
                name={`${attributesPrefix}-address2`}
                autoComplete={withAutocomplete ? 'address-level2' : ''}
                placeholder={`${t(messages.city)}, ${t(messages.state)}`}
                value={invoice[storeAccessField].address2}
                onChange={(e) => (invoice[storeAccessField].address2 = e.target.value)}
              />

              <input
                type="text"
                disabled={store.isLoading}
                id={`${attributesPrefix}-address3`}
                name={`${attributesPrefix}-address3`}
                autoComplete={withAutocomplete ? 'postal-code' : ''}
                placeholder={tt(messages.postalCode)}
                value={invoice[storeAccessField].address3}
                onChange={(e) => (invoice[storeAccessField].address3 = e.target.value)}
              />
            </>
          )}
        </div>
      }
    />
  );
};

const AddressInput = compose(injectIntl, injectStore)(_AddressInput);

const _BusinessAddressInput = (props) => (
  <AddressInput
    attributesPrefix="invoice-company"
    storeAccessField="company"
    withAutocomplete
    {...props}
  />
);

const _ClientAddressInput = (props) => (
  <AddressInput attributesPrefix="invoice-client" storeAccessField="client" {...props} />
);

export const BusinessAddressInput = mayBe(
  _BusinessAddressInput,
  () => invoiceInputFieldsUIStore.shouldShowBusinessAddress
);
export const ClientAddressInput = mayBe(
  _ClientAddressInput,
  () => invoiceInputFieldsUIStore.shouldShowClientAddress
);
