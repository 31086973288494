import { defineMessages } from 'src/i18n';

export const messages = defineMessages({
  clients: {
    id: 'reports.list.table.clients',
    defaultMessage: 'Clients'
  },
  quantity: {
    id: 'reports.list.table.quantity',
    defaultMessage: 'Quantity'
  },
  invoices: {
    id: 'reports.list.table.invoices',
    defaultMessage: 'Invoices'
  },
  paid: {
    id: 'reports.list.table.paid',
    defaultMessage: 'Paid'
  },
  rowLoading: {
    id: 'reports.list.row.loading',
    defaultMessage: 'Loading your reports...'
  },
  rowEmpty: {
    id: 'reports.list.row.empty',
    defaultMessage: 'You have no reports'
  },
  filterPaid: {
    id: 'reports.list.filter.paid',
    defaultMessage: 'Paid'
  },
  filterClients: {
    id: 'reports.list.filter.clients',
    defaultMessage: 'Clients'
  },
  filterItems: {
    id: 'reports.list.filter.items',
    defaultMessage: 'Items'
  },
  rowButtonNew: {
    id: 'invoice.list.row.button.new',
    defaultMessage: 'add your first {docType} today.'
  }
});
