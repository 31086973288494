import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import SignaturePad from 'react-signature-canvas';

import './SignatureModal.css';

if (process.env.NODE_ENV !== 'test') {
  // Hide page content for screenreaders when modal is open
  Modal.setAppElement('#app');
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    borderRadius: '12px',
    minWidth: '250px',
    overflow: 'hidden'
  } as React.CSSProperties,
  overlay: {
    position: 'fixed',
    inset: '0px',
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
    zIndex: 50
  } as React.CSSProperties
};

interface SignatureModalProps {
  onSave: (dataUrl: string) => Promise<void>;
  onClose: () => void;
  isSigEmpty?: boolean; // for testing purposes
}
export const SignatureModal = ({ onSave, onClose, isSigEmpty = true }: SignatureModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(isSigEmpty);
  const sigPadRef = useRef<SignaturePad | null>(null);

  function onClear() {
    sigPadRef.current?.clear();
    setIsEmpty(true);
  }

  async function onSigSave() {
    if (sigPadRef.current) {
      sigPadRef.current.off();
      setIsLoading(true);
      try {
        await onSave(sigPadRef.current.toDataURL());
      } finally {
        setIsLoading(false);
      }
    }
    onClose();
  }

  return (
    <Modal
      isOpen={true}
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      shouldReturnFocusAfterClose={true}
      shouldFocusAfterRender={true}
      shouldCloseOnEsc={true}
      preventScroll={true}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onClose}
      style={customStyles}>
      <div data-testid="signature-pad-container" className="signature-pad-container">
        <SignaturePad
          ref={(ref) => {
            sigPadRef.current = ref;
          }}
          canvasProps={{ style: { width: '100%', height: '100%' } }}
          clearOnResize={false}
          onEnd={() => setIsEmpty(false)}
        />
      </div>

      <footer
        id="signature-actions"
        data-testid="signature-actions"
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: isLoading ? '#444444' : 'initial'
        }}>
        {isLoading ? (
          <FontAwesomeIcon icon="spinner" spin size="4x" color="white" className="p-4" />
        ) : (
          <>
            <button className="btn btn-prime btn-signature-action" onClick={onClose}>
              Cancel
            </button>
            <button
              className="btn btn-prime btn-signature-action"
              style={{ borderLeft: '1px solid white', borderRight: '1px solid white' }}
              onClick={onClear}>
              Clear
            </button>
            <button
              className="btn btn-prime btn-signature-action"
              onClick={onSigSave}
              disabled={isEmpty}>
              Save
            </button>
          </>
        )}
      </footer>
    </Modal>
  );
};
