import { defineMessages, injectIntl, ISIntl } from 'src/i18n';
import React, { FormEvent, useLayoutEffect, useState } from 'react';
import Modal from 'react-modal';
import './InvoicePhotos.scss';
import { XIcon } from './XIcon';
import TextareaAutosize from 'react-textarea-autosize';
import { compose } from 'src/util/functions';
import InvoicePhotoModel from 'src/models/InvoicePhotoModel';
import { AppStore } from 'src/stores/AppStore';
import isPromise from 'src/util/IsPromise';
import { v1 as uuid } from 'uuid';
import { InvoicePhotoData } from './InvoicePhotos';
import { LoadingSpinnerIcon } from '../Icons';

if (process.env.NODE_ENV !== 'test') {
  // Hide page content for screenreaders when modal is open
  Modal.setAppElement('#app');
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    borderRadius: '12px',
    minWidth: '280px'
  } as React.CSSProperties,
  overlay: {
    position: 'fixed',
    inset: '0px',
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
    zIndex: 50
  } as React.CSSProperties
};

const messages = defineMessages({
  addPhoto: {
    id: 'invoice.photos.form.addPhoto',
    defaultMessage: 'Add Photo'
  },
  description: {
    id: 'invoice.photos.form.description',
    defaultMessage: 'Description'
  },
  additionalDetails: {
    id: 'invoice.photos.form.additionalDetails',
    defaultMessage: 'Additional Details'
  },
  photoDetails: {
    id: 'invoice.photos.form.add.header',
    defaultMessage: 'Photo Details'
  },
  saveChanges: {
    id: 'invoice.photos.form.saveChanges',
    defaultMessage: 'Save Changes'
  },
  cancel: {
    id: 'invoice.photos.form.cancel',
    defaultMessage: 'Cancel'
  }
});

interface PhotoModalProps {
  isOpen: boolean;
  isEditing: boolean;
  onClose: () => void;
  previewUrl: string;
  intl: ISIntl;
  store: AppStore;
  remoteData: InvoicePhotoData;
  onError: () => void;
}

const PhotoModal = ({
  isOpen,
  onClose,
  isEditing,
  intl,
  previewUrl,
  store,
  remoteData,
  onError
}: PhotoModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      shouldReturnFocusAfterClose={true}
      shouldFocusAfterRender={true}
      shouldCloseOnEsc={true}
      preventScroll={true}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onClose}
      style={customStyles}>
      <ModalContents
        intl={intl}
        onClose={onClose}
        isEditing={isEditing}
        previewUrl={previewUrl}
        remoteData={remoteData}
        store={store}
        onError={onError}
      />
    </Modal>
  );
};
export default compose(injectIntl)(PhotoModal);

const ModalContents = ({
  onClose,
  isEditing,
  intl,
  previewUrl,
  store,
  remoteData,
  onError
}: Pick<PhotoModalProps, 'isEditing' | 'onClose' | 'intl' | 'previewUrl' | 'store' | 'onError'> & {
  remoteData: InvoicePhotoData;
}) => {
  const [description, setDescription] = useState(remoteData?.description || '');
  const [name, setName] = useState(remoteData?.name || '');
  const [isLoading, setIsLoading] = useState(false);

  // Prevents scrolling the document when the modal is open
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const onSave = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const invoicePhoto = new InvoicePhotoModel(store.doc, {
      remoteId: remoteData.remoteId,
      deleted: false,
      url: remoteData.url,
      updated: Date.now(),
      name,
      description
    });

    if (isEditing) {
      isPromise(invoicePhoto.editInvoicePhoto(remoteData.remoteId))
        .catch((err) => {
          onError();
          store.user.handleError('edit-photo-save', err);
        })
        .finally(() => {
          setIsLoading(false);
          onClose();
        });
    } else {
      const remoteId = uuid();
      isPromise(invoicePhoto.addPhotoToInvoice(remoteId))
        .catch((err) => {
          onError();
          store.user.handleError('add-photo-save', err);
        })
        .finally(() => {
          setIsLoading(false);
          onClose();
        });
    }
  };

  const buttonText = isEditing ? intl.ft(messages.saveChanges) : intl.ft(messages.addPhoto);

  return (
    <form className="invoice-photo-modal-content" onSubmit={onSave}>
      <header>
        <h3>{intl.ft(messages.photoDetails)}</h3>
        <button onClick={onClose} data-testid="invoice-photo-modal-x-button" type="button">
          <XIcon />
        </button>
      </header>

      <main>
        <section className="invoice-photo-image-boundary">
          <img src={remoteData?.url || previewUrl} />
        </section>
        <section className="data-entry">
          <div>
            <label htmlFor="invoice-photo-description-input" hidden>
              {intl.ft(messages.description)}
            </label>
            <input
              type="text"
              autoFocus
              id="invoice-photo-description-input"
              name="invoice-photo-description-input"
              placeholder={intl.ft(messages.description)}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="invoice-photo-details-input" hidden>
              {intl.ft(messages.additionalDetails)}
            </label>
            <TextareaAutosize
              name="invoice-photo-details-input"
              id="invoice-photo-details-input"
              className="invoice-photo-details-input"
              minRows={5}
              maxRows={5}
              placeholder={intl.ft(messages.additionalDetails)}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </section>
      </main>

      <footer className="modal-content-footer" id="tailwind">
        <button
          data-testid="invoice-photo-modal-close-button"
          disabled={isLoading}
          onClick={onClose}
          type="button">
          {intl.ft(messages.cancel)}
        </button>
        <button
          data-testid="invoice-photo-modal-save-button"
          disabled={isLoading}
          onClick={onSave}
          type="submit"
          className="flex justify-center items-center"
          style={{ minWidth: '97px', maxHeight: '30px' }}>
          {isLoading ? <LoadingSpinnerIcon color={'white'} className="h-7 w-7" /> : buttonText}
        </button>
      </footer>
    </form>
  );
};
