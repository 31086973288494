import { observable, computed } from 'mobx';
import { ApiSubscriptionUpgrade } from '../types/ApiSubscriptionUpgrade';

export default class SubscriptionUpgradeModel {
  @observable accountId: string;
  @observable currency: string; // USD,CAD etc
  @observable currentPrice: number;
  @observable newPrice: number;
  @observable orderSku: string;
  @observable description: string | null;
  @observable planInterval: string | null;
  @observable
  replaceSub: {
    orderId: string;
    orderType: 'itunes' | 'google-play' | 'chargebee' | 'invoicesimple';
    orderSku: string;
    action: 'manual-cancel' | 'cancel' | 'upgrade' | 'none';
  };
  @computed
  get eventData(): any {
    return {
      'account-id': this.accountId,
      'new-order-sku': this.orderSku,
      'new-price-usd': this.newPrice,
      'current-order-sku': this.replaceSub && this.replaceSub.orderSku,
      'current-price': this.currentPrice
    };
  }
  constructor(upgrade: ApiSubscriptionUpgrade) {
    this.accountId = this._parse(upgrade, 'accountId');
    this.currency = this._parse(upgrade, 'currency');
    this.currentPrice = this._parse(upgrade, 'currentPrice');
    this.newPrice = this._parse(upgrade, 'newPrice');
    this.orderSku = this._parse(upgrade, 'orderSku');
    this.description = this._parse(upgrade, 'description');
    this.planInterval = this._parse(upgrade, 'planInterval');
    this.replaceSub = this._parse(upgrade, 'replaceSub');
  }
  _parse(obj: any, name: string): any {
    return obj[name] || this._default(name);
  }
  _default(name: string) {
    return {
      currency: 'USD',
      currentPrice: 0,
      newPrice: 599,
      description: '',
      orderSku: 'com.invoicesimple.web.monthly1.usd',
      planInterval: 'monthly',
      replaceSub: {
        action: 'none',
        orderSku: 'com.invoicesimple.freetrial.web.14day',
        orderType: 'invoicesimple',
        orderId: 'is.default'
      }
    }[name];
  }
}
