import React from 'react';

export const OrangeWarningIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    data-testid="orangeWarningIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6984 7.0001C13.6984 10.5347 10.8331 13.4001 7.29844 13.4001C3.76382 13.4001 0.898438 10.5347 0.898438 7.0001C0.898438 3.46548 3.76382 0.600098 7.29844 0.600098C10.8331 0.600098 13.6984 3.46548 13.6984 7.0001ZM8.09844 10.2001C8.09844 10.6419 7.74027 11.0001 7.29844 11.0001C6.85661 11.0001 6.49844 10.6419 6.49844 10.2001C6.49844 9.75827 6.85661 9.4001 7.29844 9.4001C7.74027 9.4001 8.09844 9.75827 8.09844 10.2001ZM7.29844 3.0001C6.85661 3.0001 6.49844 3.35827 6.49844 3.8001V7.0001C6.49844 7.44192 6.85661 7.8001 7.29844 7.8001C7.74027 7.8001 8.09844 7.44192 8.09844 7.0001V3.8001C8.09844 3.35827 7.74027 3.0001 7.29844 3.0001Z"
      fill="#FB923C"
    />
  </svg>
);
