import React from 'react';
import { SVGProps } from 'react';

export const TemplatesIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.25 3C9.86929 3 8.75 4.11929 8.75 5.5V15.5C8.75 16.8807 9.86929 18 11.25 18H18.75C20.1307 18 21.25 16.8807 21.25 15.5V8.51777C21.25 7.85473 20.9866 7.21884 20.5178 6.75L17.5 3.73223C17.0312 3.26339 16.3953 3 15.7322 3H11.25Z"
        fill="#FC5B31"
      />
      <path
        d="M3.75 10.5C3.75 9.11929 4.86929 8 6.25 8V20.5H16.25C16.25 21.8807 15.1307 23 13.75 23H6.25C4.86929 23 3.75 21.8807 3.75 20.5V10.5Z"
        fill="#FC5B31"
      />
    </svg>
  );
};
