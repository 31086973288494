import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import MediaQuery, { MediaQueryAllQueryable } from 'react-responsive';
import { MediaQueryMatchers } from 'react-responsive/types/types';

// taken from react-responsive unexported types
interface MediaQueryProps extends MediaQueryAllQueryable {
  component?: ReactNode;
  children?: ReactNode | ((matches: boolean) => ReactNode);
  query?: string;
  style?: CSSProperties;
  className?: string;
  device?: MediaQueryMatchers;
  values?: Partial<MediaQueryMatchers>;
  onBeforeChange?: (_matches: boolean) => void;
  onChange?: (_matches: boolean) => void;
}

export const Mobile = (props: MediaQueryProps) => <MediaQuery maxWidth={767} {...props} />;
export const Desktop = (props: MediaQueryProps) => <MediaQuery minWidth={768} {...props} />;

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
