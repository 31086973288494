import { useContext } from 'react';
import { PaypalAccountContext } from 'src/payments/contexts/PaypalAccountContext';
import { ModalName } from '../../../../components/ModalManagerContext';
import {
  FeatureFlagName,
  PaymentsModalLocalStorageKey,
  usePaymentsModal
} from '../../../hooks/usePaymentsModal';

interface UseUnbrandedUpgradeModal {
  shouldShowUnbrandedUpgradeModal: boolean;
  showUnbrandedUpgradeModal: () => void;
  hideUnbrandedUpgradeModal: () => void;
  isEligibleForUnbrandedUpgradeModal: boolean;
}

export const useUnbrandedUpgradeModal = (
  initialState: boolean = false
): UseUnbrandedUpgradeModal => {
  const paypalAccountContext = useContext(PaypalAccountContext);

  const {
    shouldShowPaymentsModal,
    showPaymentsModal,
    hidePaymentsModal,
    isEligibleForPaymentsModal
  } = usePaymentsModal({
    modalConfig: {
      modalName: ModalName.UnbrandedUpgradePrompt,
      featureFlagName: FeatureFlagName.UnbrandedUpgradePrompt,
      localStorageKey: PaymentsModalLocalStorageKey.UnbrandedUpgradePrompt,
      isEligibleForModal: () => {
        return !!(
          paypalAccountContext?.isPaypalAccepting() &&
          paypalAccountContext.isEligibleForPPCPUpgrade()
        );
      }
    },
    initialState
  });

  return {
    shouldShowUnbrandedUpgradeModal: shouldShowPaymentsModal,
    showUnbrandedUpgradeModal: showPaymentsModal,
    hideUnbrandedUpgradeModal: hidePaymentsModal,
    isEligibleForUnbrandedUpgradeModal: isEligibleForPaymentsModal
  };
};
