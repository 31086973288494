import React from 'react';

export const PencilIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.488 3.48816L12.8451 5.84518M11.488 2.48816C12.1389 1.83728 13.1942 1.83728 13.8451 2.48816C14.4959 3.13903 14.4959 4.1943 13.8451 4.84518L4.66659 14.0237H2.33325V11.6429L11.488 2.48816Z"
        stroke="#9CA3AF"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
