import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import NumericInput from 'react-numeric-input';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { injectIntl, defineMessages } from 'src/i18n';
import InvoiceItemModel from 'src/models/InvoiceItemModel';
import ItemSuggestModel from 'src/models/ItemSuggestModel';
import { CurrencyDisplay } from '../Currency';
import { ConfirmDeleteItem } from '../ConfirmDeleteButton';
import ItemSuggest from '../ItemSuggest';
import ItemQuantityInput from '../ItemQuantityInput';
import { getItemTotal } from '@invoice-simple/calculator';
import './InvoiceItemRow.scss';
import { ISIntl } from 'src/i18n/utils';

interface Prop {
  item: InvoiceItemModel;
  suggest: ItemSuggestModel;
  index: number;
  isLoading: boolean;
  isLast: boolean;
  intl?: ISIntl;
  isMarkingUpPaymentFees: boolean;
}

const messages = defineMessages({
  additionalDetails: {
    id: 'invoice.itemRow.additionalDetails',
    defaultMessage: 'additional details'
  },
  discount: {
    id: 'invoice.itemRow.discount',
    defaultMessage: 'dicount'
  },
  inclusive: {
    id: 'invoice.itemRow.inclusiveIndicator',
    defaultMessage: 'inc'
  }
});

@injectIntl
@observer
export default class InvoiceItemRow extends React.Component<Prop, any> {
  render() {
    const item = this.props.item;
    const isDisabled = this.props.isLoading;
    const rowClass = cn('item-row', `item-row-${this.props.index + 1}`, {
      'with-tax': !item.isTaxNone,
      'item-row-delete': item.delete
    });
    const { ft } = this.props.intl!;

    return (
      <>
        <tr className={rowClass}>
          <td className="item-row-actions">
            <ConfirmDeleteItem
              disabled={isDisabled}
              pending={item.delete}
              confirmed={item.deleted}
              displayIcon={true}
              onPending={() => item.toggleDelete()}
              onConfirm={() => item.toggleDeleted()}
            />
          </td>

          <td data-label={`Item #${this.props.index + 1}`} className="item-row-summary">
            <span className="item-row-name">
              {
                <ItemSuggest
                  value={item.code}
                  disabled={isDisabled}
                  suggest={this.props.suggest}
                  onChange={(v) => item.setCode(v)}
                  onSelect={(s) => item.setItem(s)}
                />
              }
            </span>
            <span className="item-row-description">
              <TextareaAutosize
                style={{ height: 80 }}
                disabled={isDisabled}
                className="item-description-input"
                placeholder={ft(messages.additionalDetails)}
                minRows={3}
                maxRows={10}
                value={item.description}
                onChange={(e) => item.setDescription(e.target.value)}
              />
            </span>
          </td>
          <td data-label="Price" className="item-row-rate">
            <NumericInput
              style={false}
              type="text"
              disabled={isDisabled}
              placeholder="0.00"
              precision={2}
              value={item.userSetRate ? item.rate : undefined}
              onChange={(v) => item.setRate(v ?? 0)}
              maxLength={20}
              className={`${this.props.isMarkingUpPaymentFees ? 'animate' : ''}`}
            />
          </td>
          <td data-label="Quantity" className="item-row-quantity">
            <ItemQuantityInput
              placeholder="0"
              disabled={isDisabled}
              value={item.userSetQuantity ? item.quantity : 0}
              onChange={item.setQuantity}
            />
          </td>

          <td data-label="Amount" className="item-row-amount">
            <CurrencyDisplay className={`edit amount`} value={+getItemTotal(item.parseData)} />
          </td>

          {!item.isTaxNone && (
            <td data-label={item.invoice.taxLabel} className="item-row-tax">
              <button
                disabled={isDisabled}
                className={cn('btn-checkbox', {
                  disabled: isDisabled,
                  'invoice-item-tax-enabled': item.taxable,
                  'invoice-item-tax-disabled': !item.taxable
                })}
                onClick={() => !isDisabled && item.toggleTaxable()}>
                <FontAwesomeIcon icon={item.taxable ? 'check-square' : 'square'} />
              </button>
            </td>
          )}
        </tr>
      </>
    );
  }
}
