import React from 'react';
import cn from 'classnames';

interface Props extends React.HTMLProps<HTMLAnchorElement> {
  isActive?: boolean;
  isDisabled?: boolean;
  customClasses?: string;
}

const NavItem = ({ children, isActive = false, isDisabled, customClasses, ...rest }: Props) => {
  const classes = cn(`nav-item ${customClasses ? customClasses : ''}`, {
    active: isActive,
    disabled: isDisabled
  });
  return (
    <a rel="nofollow" className={classes} {...rest}>
      {children}
    </a>
  );
};

export default NavItem;
