import React from 'react';

export type ButtonProps = React.ComponentPropsWithRef<'button'>;

export type RoundedButtonProps = ButtonProps & {
  color: 'primary' | 'secondary';
  customStyles?: string;
};

const btnColorClasses: Record<NonNullable<RoundedButtonProps['color']>, string> = {
  primary: 'bg-black text-white',
  secondary: 'bg-white text-neutral-800'
};

export const RoundedButton = (props: RoundedButtonProps) => {
  const { color, children, disabled, customStyles, ...rest } = props;

  const btnColor = btnColorClasses[color];
  const isDisabled = disabled ? 'opacity-50 cursor-not-allowed' : '';

  return (
    <button
      {...rest}
      disabled={disabled}
      className={`px-[17px] py-[9px] rounded-[6px] justify-center items-center inline-flex text-[16px] font-medium ${btnColor} ${isDisabled} ${customStyles}`}>
      {children}
    </button>
  );
};
