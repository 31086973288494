import { useCallback, useState } from 'react';
import { URLQueryParamKeys } from 'src/util/url';
import { getPaymentEventSource, onError } from 'src/payments/utils';
import { AppStore } from 'src/stores/AppStore';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';

interface ClickHandlerHookType {
  handleInitialClick: (store: AppStore, showModal: () => void) => void;
  handleAcceptingClick: (
    checked: boolean,
    updateEnabled: (checked: boolean) => Promise<void>
  ) => void;
  loading: boolean;
  handleActivatingPaymentsFromInvoice: (activatePayments: () => Promise<void>) => void;
}

export enum PaymentMethod {
  Paypal = 'paypal',
  Stripe = 'stripe'
}

export const useCTAHandler = (paymentMethod: PaymentMethod): ClickHandlerHookType => {
  const [loading, setLoading] = useState(false);
  const handleInitialClick = useCallback<ClickHandlerHookType['handleInitialClick']>(
    (store, showModal) => {
      const user = store.user;
      const isPaypal = paymentMethod === PaymentMethod.Paypal;
      const isGuest = !user.isSignedUp;
      user.events.trackAction('payments-cta', {
        activePaymentProvider: isPaypal
          ? PaymentEventActivePaymentProvider.paypal
          : PaymentEventActivePaymentProvider.stripe,
        source: getPaymentEventSource(),
        'from-guest-account': isGuest,
        component: PaymentEventComponent.tileInitial
      });
      if (!user.isSignedUp) {
        store?.location.nav('signup', {
          ref: isPaypal ? URLQueryParamKeys.PAYPAL_ONBOARDING : URLQueryParamKeys.STRIPE_ONBOARDING
        });
      } else {
        showModal();
      }
    },
    []
  );

  const handleAcceptingClick = useCallback<ClickHandlerHookType['handleAcceptingClick']>(
    async (checked, updateEnabled) => {
      try {
        setLoading(true);
        await updateEnabled(checked);
      } catch (error) {
        const action = checked ? 'enable' : 'disable';
        onError({
          title: `Failed to ${action} ${paymentMethod} payment status`,
          body: error.message
        });
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handleActivatingPaymentsFromInvoice = useCallback<
    ClickHandlerHookType['handleActivatingPaymentsFromInvoice']
  >(async (activatePayments) => {
    try {
      setLoading(true);
      await activatePayments();
    } catch (error) {
      onError({
        title: `Failed to activate payments from Invoice for provider ${paymentMethod}`,
        body: error.message
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    handleInitialClick,
    handleAcceptingClick,
    handleActivatingPaymentsFromInvoice,
    loading
  };
};
