import React from 'react';
import { useISIntl } from 'src/i18n/utils';
import ReportModel from 'src/models/ReportModel';
import { messages } from './ReportMessages';
import { ReportType } from './ReportTypes';

const FilterButton = ({ list }: { list: ReportModel }) => {
  const { ft } = useISIntl();

  const handleFilter = (reportType: ReportType) => {
    list.user.events.trackTabClick({ text: reportType, action: 'view' });

    list.setReportType(reportType);
  };

  return (
    <div className="report-list-header page-header">
      <div className="btn-group btn-group-sm btn-group-filter" role="group">
        <button
          type="button"
          data-selector="paid-reports-filter-button"
          className={`btn btn-filter ${list.isPaid ? 'active' : ''}`}
          onClick={() => handleFilter('paid')}>
          {ft(messages.filterPaid)}
        </button>
        <button
          type="button"
          data-selector="client-reports-filter-button"
          className={`btn btn-filter ${list.isClients ? 'active' : ''}`}
          onClick={() => handleFilter('clients')}>
          {ft(messages.filterClients)}
        </button>
        {list.canSeeItemsReport && (
          <button
            type="button"
            data-selector="client-reports-filter-button"
            className={`btn btn-filter ${list.isItems ? 'active' : ''}`}
            onClick={() => handleFilter('items')}>
            {ft(messages.filterItems)}
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterButton;
