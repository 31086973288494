import { flatten } from 'flat';
import { getSettingsFromDocMap } from '../util/SettingsDocMap';

/** return settings data from docData */
const SettingsFromDoc = (docType: number, docData: any) => {
  const settings = {};
  const data = flatten<any, any>(docData);
  getSettingsFromDocMap(docType).forEach((o) => {
    const value = o.invoicePath ? data[o.invoicePath] : undefined;
    if (typeof value !== 'undefined') {
      settings[o.path] = value;
    }
  });
  return settings;
};

export default SettingsFromDoc;
