import { observer } from 'mobx-react';
import React from 'react';
import { defineMessages } from 'src/i18n';
import { useISIntl } from 'src/i18n/utils';

const messages = defineMessages({
  buttonNext: {
    id: 'invoice.list.button.next',
    defaultMessage: 'Next'
  },
  buttonPrev: {
    id: 'invoice.list.button.prev',
    defaultMessage: 'Prev'
  }
});
interface PaginatedData {
  hasNextPage: boolean;
  next: string | null;
  prev: string | null;
  [key: string]: any;
}

interface Props {
  onNext: () => void;
  onPrev: () => void;
  data?: PaginatedData;
  hidden?: boolean;
}

export const PaginationButtons = observer(({ onNext, onPrev, data, hidden }: Props) => {
  const { formatMessage: f } = useISIntl();

  if (!data || hidden) {
    return null;
  }

  return (
    <nav style={{ display: 'flex', justifyContent: 'flex-end' }} aria-label="Pagination">
      <button
        aria-label="Previous Page"
        onClick={onPrev}
        disabled={!data.prev}
        className="btn is-prev-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          className="mr-2 mb-1"
          width="6"
          height="6">
          <polygon points="0,3 6,0 6,6" />
        </svg>
        {f(messages.buttonPrev)}
      </button>
      <button
        aria-label="Next Page"
        onClick={onNext}
        disabled={!data.next}
        className="btn is-next-btn">
        {f(messages.buttonNext)}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          className="ml-2 mb-1"
          width="6"
          height="6">
          <polygon points="6,3 0,6 0,0" />
        </svg>
      </button>
    </nav>
  );
});
