import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { getIntlCurrency, SubscriptionTier } from '@invoice-simple/common';

import { ISIntl, useISIntl } from 'src/i18n/utils';
import AlertModel from 'src/models/AlertModel';
import SubscriptionModel from 'src/models/SubscriptionModel';
import { Cadence } from 'src/models/UserModel';
import LocaleStore from 'src/stores/LocaleStore';
import { SubscriptionUpgrade, SubscriptionUpgradeType } from 'src/types/Subscription';
import { AppStore } from '../../stores/AppStore';
import { SubscriptionSwitchType } from '../SubscriptionPaywall/utils';
import { BackToInvoiceList } from './BackToInvoiceList';
import { messages } from './messages';
import { PremiumUpgradeSection } from './PremiumUpgradeSection';

const localeCode = LocaleStore.currentLocale;

function getLabel({
  price,
  interval,
  currencyCode,
  formatMessage,
  type
}: {
  price: number;
  interval?: Cadence;
  currencyCode: string;
  localeCode: string | undefined;
  formatMessage: ISIntl['formatMessage'];
  type: SubscriptionUpgradeType;
}) {
  const isMonthly = interval === Cadence.MONTHLY;
  let priceInterval = '';
  if (type === SubscriptionUpgradeType.PREMIUM) {
    priceInterval = isMonthly ? formatMessage(messages.perMonth) : formatMessage(messages.perYear);
  }
  return `${getIntlCurrency({
    number: price / 100,
    currencyCode,
    localeCode
  })}${priceInterval}`;
}

function getPriceLabels(
  store: AppStore,
  price: number,
  formatMessage: ISIntl['formatMessage'],
  type: SubscriptionUpgradeType,
  interval?: Cadence
) {
  const currencyCode = store.user.geoSubCurrencyCode;

  if (store.user.isLegacySubscriber) {
    return {
      label: null
    };
  }

  return {
    label: getLabel({ price, interval, currencyCode, formatMessage, type, localeCode })
  };
}

export type UpgradeState = 'already-upgraded' | 'initial' | 'loading';

export function UpgradeSection({
  store,
  subscription,
  onError,
  upgradeParams
}: {
  store: AppStore;
  subscription: SubscriptionModel;
  onError: (error: { title?: string; body?: string }) => void;
  upgradeParams: SubscriptionUpgrade;
}) {
  const { formatMessage } = useISIntl();
  const { user } = store;
  const { type } = upgradeParams;
  const canUpgrade =
    type === SubscriptionUpgradeType.PREMIUM &&
    (!user.isSubWebMobile ||
      user.isSubTier(SubscriptionTier.ESSENTIALS) ||
      user.isSubTier(SubscriptionTier.PLUS));

  const [upgradeState, setUpgradeState] = useState<UpgradeState>(
    canUpgrade ? 'initial' : 'already-upgraded'
  );

  async function upgradeToPremium(cadence: Cadence) {
    try {
      setUpgradeState('loading');
      await store.upgradeSubscription({
        subscriptionToSwitch: subscription,
        newOrderSku: user.getGeoSubOrderSku({ tier: SubscriptionTier.PREMIUM, cadence }),
        switchType: SubscriptionSwitchType.UPGRADE,
        newPrice: user.getGeoSubAmount({ tier: SubscriptionTier.PREMIUM, cadence })
      });
      await user.syncSubscriptions();
      user.trackAppEventViaApi('upgrade-web-mobile');
      store.nav('reportList');
      AlertModel.setAlert(
        'success',
        formatMessage(messages.premiumUpgradeSuccessTitle),
        <AlertBodyWithAppLink subMessage={formatMessage(messages.premiumUpgradeSuccessMessage)} />
      );
      return;
    } catch (error) {
      onError({ title: 'Failed to upgrade subscription', body: error.message });
      setUpgradeState('initial');
      AlertModel.setAlert('danger', 'Upgrade Error', error.message);
    }
  }

  if (upgradeState === 'already-upgraded') {
    return (
      <div className="bg-white max-w-7xl mx-auto text-center rounded-lg shadow-lg py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <BackToInvoiceList store={store}>
          <FormattedMessage {...messages.alreadyPremiumSub} />
        </BackToInvoiceList>
      </div>
    );
  }

  const upgradePrice = user.getGeoSubAmount({
    tier: SubscriptionTier.PREMIUM,
    cadence: upgradeParams.planInterval
  });

  const { label } = getPriceLabels(
    store,
    upgradePrice,
    formatMessage,
    type,
    upgradeParams.type === SubscriptionUpgradeType.PREMIUM ? upgradeParams.planInterval : undefined
  );

  const monthlyPricePerYear = `${getIntlCurrency({
    number:
      (user.getGeoSubAmount({ tier: SubscriptionTier.PREMIUM, cadence: Cadence.MONTHLY }) / 100) *
      12,
    currencyCode: user.geoSubCurrencyCode,
    localeCode
  })}${formatMessage(messages.perYear)}`;

  return (
    <PremiumUpgradeSection
      user={user}
      formatMessage={formatMessage}
      onUpgrade={upgradeToPremium}
      interval={upgradeParams.planInterval}
      monthlyPricePerYear={monthlyPricePerYear}
      label={label}
      upgradeState={upgradeState}
    />
  );
}

export function AlertBodyWithAppLink({ subMessage }: { subMessage: string }): JSX.Element {
  return (
    <>
      <FormattedMessage {...messages.premiumUpgradeSuccessAppLink} />
      <br />
      {subMessage}
    </>
  );
}
