import Parse from 'parse';
import { Msg, MsgEvent } from 'src/util/IsParseDomain';

const getMsgQueryBase = (): Parse.Query<Parse.Object> => {
  return new Parse.Query<Parse.Object>(Msg);
};

const getMsgEventQueryBase = (): Parse.Query<Parse.Object> => {
  return new Parse.Query<Parse.Object>(MsgEvent);
};

// Retrieves a list of all invoices that were sent by this user
export const getSentDocuments = async (accountId: string): Promise<Parse.Object[]> => {
  const msgs = await getMsgQueryBase()
    .equalTo('account', {
      __type: 'Pointer',
      className: 'Account',
      objectId: accountId
    })
    .find();

  if (msgs.length === 0) return [];

  const msgRemoteIds = msgs.map((msg) => msg.get('remoteId'));
  const events = await getMsgEventQueryBase().containedIn('msgRemoteId', msgRemoteIds).find();

  return msgs.map((m) => {
    m.set(
      'events',
      events.filter((e) => e.get('msgRemoteId') === m.get('remoteId')).map((a) => a.attributes)
    );

    return m;
  });
};
