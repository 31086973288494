import { defineMessages } from 'src/i18n';
import { ISIntl } from './utils';
import { TaxTypes } from '@invoice-simple/common';

const messages = defineMessages({
  total: {
    id: 'i18n.tax.type.label.total',
    defaultMessage: 'On Total'
  },
  deducted: {
    id: 'i18n.tax.type.label.deducted',
    defaultMessage: 'Deducted'
  },
  item: {
    id: 'i18n.tax.type.label.item',
    defaultMessage: 'Per Item'
  },
  none: {
    id: 'i18n.tax.type.label.none',
    defaultMessage: 'None'
  }
});

export const intlTaxTypeOptions = (intl: ISIntl) => {
  return [
    { value: TaxTypes.TAX_SUBTOTAL, label: intl.formatMessage(messages.total) },
    { value: TaxTypes.TAX_DEDUCTED, label: intl.formatMessage(messages.deducted) },
    { value: TaxTypes.TAX_ITEM, label: intl.formatMessage(messages.item) },
    { value: TaxTypes.TAX_NONE, label: intl.formatMessage(messages.none) }
  ];
};
