import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useCancelSubscription } from '../CancelSubscriptionContext';
import UserModel from 'src/models/UserModel';
import { XCircleIcon } from 'src/components/ColorSelect/Icons';
import { startConversation } from 'src/analytics/intercom';
import { SubscriptionCancelFlowVersion } from '../common';
import { Platform } from '@invoice-simple/common';
import { cancelSubscription } from 'src/apis/subscriptionsAPI';
import { ReturnToAppButton } from '../ReturnToAppButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertModel from 'src/models/AlertModel';

interface Props {
  user: UserModel;
  version: SubscriptionCancelFlowVersion;
  platform: Platform;
  onError: (error: { title?: string; body?: string }) => void;
}

const ValuePropsScreen = ({ user, version, platform }: Props) => {
  const [loading, setLoading] = useState(false);
  const { goTo } = useCancelSubscription();

  const showDiscount = () => {
    return user.hasAnnualSub ? (
      <ReturnToAppButton platform={platform} />
    ) : (
      <div
        className={`flex content-center flex-col space-y-6 self-center ${
          platform === Platform.WEB ? 'mt-16' : 'mt-4'
        }`}>
        <div className="text-4xl text-gray-800 flex flex-col text-center md:flex-row space-x-2">
          <span className="font-bold">Exclusive offer</span>
          <span className="font-normal">3 months at 50% off!</span>
        </div>
        <button
          className="rounded-xl bg-orange-is border-transparent self-center py-3 px-4 text-white text-3xl font-bold"
          onClick={async () => {
            try {
              await startConversation(
                user.id!,
                '[REQUEST] I have chosen to apply a 50% off discount'
              );
              user.trackAppEventViaApi('subscription-cancel-applyDiscountButtonClicked');
              goTo('completed', { hasRedeemedOffer: true });
            } catch (e) {
              // TODO: Implement error workflow
              goTo('completed', { hasRedeemedOffer: true });
            }
          }}>
          Apply Discount
        </button>
      </div>
    );
  };

  useEffect(() => {
    user.trackAppEventViaApi('subscription-cancel-pageViewed', {
      page: 'offer'
    });
  }, []);

  const handleCancelAnyway = async (): Promise<void> => {
    if (version === SubscriptionCancelFlowVersion.V1) {
      return requestCancellation();
    }

    if (platform === Platform.WEB) {
      try {
        setLoading(true);
        await cancelSubscription(user);
        return goTo('completed');
      } catch (e) {
        return AlertModel.setAlert(
          'danger',
          'Unable to cancel subscription',
          'Please try again or contact support.'
        );
      } finally {
        setLoading(false);
      }
    }

    /* Must be from mobile */
    window.location.href = 'settings://subscription-cancel';
  };

  const requestCancellation = async () => {
    try {
      await startConversation(user.id!, '[REQUEST] I have chosen to cancel my subscription');
      user.trackAppEventViaApi('subscription-cancel-cancelAnywayButtonClicked');
      goTo('completed');
    } catch (e) {
      // TODO: Implement error workflow
      goTo('completed');
    }
  };

  return (
    <div className="value-props-screen" style={{ fontFamily: 'Quicksand' }}>
      <div className="flex flex-col justify-center content-center">
        <img
          className="h-24 sm:h-28 rounded self-center"
          src="images/is-logo.png"
          alt="Invoice Simple Logo"
        />
        <h1
          data-testid="value-props-title"
          role="heading"
          className="mt-12 self-center font-bold leading-10 text-gray-800">
          Before you go
        </h1>
        <div
          className="mt-16 p-16 w-11/12 self-center rounded"
          style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}>
          <span className="text-4xl font-semibold text-gray-700">Here’s what you’ll lose</span>
          <p className="text-2xl text-gray-800 flex items-center" style={{ marginTop: '20px' }}>
            {XCircleIcon}
            Ability to send invoices
          </p>
          <p className="text-2xl text-gray-800 flex items-center" style={{ marginTop: '10px' }}>
            {XCircleIcon}
            Customer read receipts
          </p>
          <p className="text-2xl text-gray-800 flex items-center" style={{ marginTop: '10px' }}>
            {XCircleIcon}
            Managing your clients and items
          </p>
        </div>
        {version === SubscriptionCancelFlowVersion.V1 && showDiscount()}
        {version === SubscriptionCancelFlowVersion.V2 && <ReturnToAppButton platform={platform} />}
        <button
          type="button"
          className="self-center text-2xl bg-transparent text-blue-500 font-semibold py-2 px-4 outline-none border-0 underline cancel-button mt-16"
          onClick={handleCancelAnyway}>
          No thanks, I want to cancel
          {loading && <FontAwesomeIcon className="mx-2" icon="spinner" spin />}
        </button>
      </div>
    </div>
  );
};

export default observer(ValuePropsScreen);
