import React from 'react';
import InputWithLabel from '../InputWithLabel';
import injectStore from 'src/util/injectStore';
import invoiceInputFieldsUIStore from 'src/stores/InvoiceInputFieldsUIStore';
import { defineMessages } from 'src/i18n';
import { Mobile, Desktop, useWindowSize } from '../Responsive';
import { AppStore } from 'src/stores/AppStore';
import { useISIntl } from 'src/i18n/utils';
import { capitalize } from 'lodash';
import { observer } from 'mobx-react';

const messages = defineMessages({
  phone: {
    id: 'phoneInput.phone',
    defaultMessage: 'phone'
  },
  fax: {
    id: 'phoneInput.fax',
    defaultMessage: 'fax'
  },
  mobile: {
    id: 'phoneInput.mobile',
    defaultMessage: 'mobile'
  }
});

interface PhoneInputProps {
  store: AppStore;
  attributesPrefix: string;
  storeAccessField: 'client' | 'company';
  withAutocomplete: boolean;
  placeholder: string;
  storeAccessValue: 'phone' | 'fax' | 'mobile';
}

type PhoneProps = Pick<PhoneInputProps, 'storeAccessValue'>;

const PhoneInput = injectStore(
  ({
    store,
    attributesPrefix,
    storeAccessField,
    storeAccessValue,
    placeholder = '(123) 456 789',
    withAutocomplete = false
  }: PhoneInputProps) => {
    const intl = useISIntl();

    return (
      <InputWithLabel
        label={
          <label htmlFor={`${attributesPrefix}-${storeAccessValue}`}>
            {intl.ft(messages[storeAccessValue])}
          </label>
        }
        content={
          <input
            type="text"
            disabled={store.isLoading}
            id={`${attributesPrefix}-${storeAccessValue}`}
            name={`${attributesPrefix}-${storeAccessValue}`}
            data-selector={`${attributesPrefix}-${storeAccessValue}-input`}
            placeholder={placeholder}
            autoComplete={withAutocomplete ? 'tel' : ''}
            value={store.doc[storeAccessField][storeAccessValue]}
            onChange={(e) => (store.doc[storeAccessField][storeAccessValue] = e.target.value)}
          />
        }
      />
    );
  }
);

const _BusinessPhoneInput = (props: PhoneProps) => {
  return (
    <PhoneInput
      attributesPrefix="invoice-company"
      storeAccessField="company"
      withAutocomplete
      {...props}
    />
  );
};

const _ClientPhoneInput = (props: PhoneProps) => {
  const { width } = useWindowSize();
  const isMobile = !!width && width < 768;

  const placeholder = isMobile
    ? `Client ${capitalize(props.storeAccessValue)} e.g. (123) 456 789`
    : `(123) 456 789`;

  return (
    <PhoneInput
      attributesPrefix="invoice-client"
      storeAccessField="client"
      placeholder={placeholder}
      {...props}
    />
  );
};

export const BusinessPhoneInput = observer((props: PhoneProps) => {
  return (
    <>
      <Desktop>
        <_BusinessPhoneInput {...props} />
      </Desktop>
      <Mobile>
        {invoiceInputFieldsUIStore.shouldShowBusinessPhone ? (
          <_BusinessPhoneInput {...props} />
        ) : null}
      </Mobile>
    </>
  );
});

export const ClientPhoneInput = observer((props: PhoneProps) => {
  return (
    <>
      <Desktop>
        <_ClientPhoneInput {...props} />
      </Desktop>
      <Mobile>
        {invoiceInputFieldsUIStore.shouldShowClientPhone ? <_ClientPhoneInput {...props} /> : null}
      </Mobile>
    </>
  );
});
