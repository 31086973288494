export enum PaywallType {
  FREE = 'free',
  TIERED_V1 = 'tiered_v1',
  TIERED_V2 = 'tiered_v2',
  TIERED_V3 = 'tiered_v3',
  TRIAL = 'trial',
  PRO = 'pro',
  DISCOUNTED = 'discounted',
  PREMIUM_ONLY = 'premium_only',
  PREMIUM_LEGACY = 'premium_legacy'
}
