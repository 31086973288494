import { PaypalAccountContext } from 'src/payments/contexts/PaypalAccountContext';
import { ModalName } from '../../../../components/ModalManagerContext';
import { PaymentsModalLocalStorageKey, usePaymentsModal } from '../../../hooks/usePaymentsModal';
import { useContext } from 'react';
import { differenceInHours } from 'date-fns';
import { useIsPaymentsEnabled } from 'src/payments/hooks/useIsPaymentsEnabled';

interface UseSurchargePromoModal {
  shouldShowSurchargePromoModal: boolean;
  showSurchargePromoModal: () => void;
  hideSurchargePromoModal: () => void;
  isEligibleForSurchargePromoModal: boolean;
}

export const useSurchargePromoModal = (initialState: boolean = false): UseSurchargePromoModal => {
  const paypalAccountContext = useContext(PaypalAccountContext);
  const isPaymentsEnabled = useIsPaymentsEnabled();

  const {
    shouldShowPaymentsModal,
    showPaymentsModal,
    hidePaymentsModal,
    isEligibleForPaymentsModal
  } = usePaymentsModal({
    modalConfig: {
      modalName: ModalName.SurchargePromoModal,
      localStorageKey: PaymentsModalLocalStorageKey.SurchargePromoModal,
      isEligibleForModal: () => {
        const unbrandedUpgradeLastShown = localStorage.getItem(
          PaymentsModalLocalStorageKey.UnbrandedUpgradePrompt
        );
        const isUnbrandedModalShownThresholdExceeded = unbrandedUpgradeLastShown
          ? differenceInHours(new Date(), new Date(Date.parse(unbrandedUpgradeLastShown))) > 24
          : true;

        return !!(
          paypalAccountContext?.isPaypalAccepting() &&
          paypalAccountContext?.isPaypalEnabled() &&
          isUnbrandedModalShownThresholdExceeded &&
          isPaymentsEnabled
        );
      }
    },
    initialState
  });

  return {
    shouldShowSurchargePromoModal: shouldShowPaymentsModal,
    showSurchargePromoModal: showPaymentsModal,
    hideSurchargePromoModal: hidePaymentsModal,
    isEligibleForSurchargePromoModal: isEligibleForPaymentsModal
  };
};
