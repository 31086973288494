import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ModalClose = ({
  title = 'Close',
  onClick
}: {
  title?: string;
  onClick?: React.MouseEventHandler;
}) => (
  <a title={title} className="btn-link btn-close" style={{ cursor: 'pointer' }} onClick={onClick}>
    <FontAwesomeIcon icon="times" />
  </a>
);
