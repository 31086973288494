import UserModel from 'src/models/UserModel';
import intercom from 'src/analytics/intercom';
import rollbar from 'src/analytics/rollbar';
import isEventsIdentify from 'src/analytics/is-events';
import { GenericEventMeta, WebEventName } from '@invoice-simple/is-events';
import { updateBrazeCustomAttributes } from 'src/util/braze';

export const updateAnalyticsProviders = async ({
  user
}: {
  user: UserModel;
  withReset?: boolean;
}): Promise<void> => {
  try {
    await user.syncSubscriptions();
    isEventsIdentify(user.events.forAmplitude());
    updateBrazeCustomAttributes(user.forBraze());
    intercom('update', user.forIntercom());
    rollbar.update(user.forRollbar());
  } catch (error) {
    user.trackError('user-update-tracking-services', error);
  }
};

export const trackEvent = <T extends WebEventName>(eventName: T, data?: GenericEventMeta<T>) =>
  UserModel.getInstance().trackAppEventViaApi(eventName, data);
