import React from 'react';
import createPortal from '../util/createPortal';

const ModalPortal = (props) =>
  createPortal(
    <div className="modal-wrapper">{props.children}</div>,
    document.getElementById('modal')
  );

export default ModalPortal;
