import React from 'react';
import { PaymentSetupButton } from '../PaymentBasicElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface PaypalClosedAlertDictionary {
  closedAlertText: string;
  contactSupportButtonText: string;
}

interface Props {
  dictionary: PaypalClosedAlertDictionary;
}

export function PaypalClosedAlert({ dictionary }: Props) {
  const handleContactSupportButtonClick = () => {
    window.Intercom('show');
  };

  return (
    <>
      <div className="payment-setup-progress-container col-md-12 my-4">
        <div className="payment-setup-progress-body d-flex align-items-center rounded p-4">
          <div>
            <FontAwesomeIcon icon="exclamation-circle" color="orange" size="3x" />
          </div>
          <div className="payment-closed-alert-body pl-4">{dictionary.closedAlertText}</div>
        </div>
      </div>
      <div className="col-md-12">
        <PaymentSetupButton
          buttonText={dictionary.contactSupportButtonText}
          buttonBackgroundColor="orange"
          loading={false}
          onButtonClick={handleContactSupportButtonClick}
        />
      </div>
    </>
  );
}
