import { useFlags } from 'flagsmith/react';

export const useIsPaymentsLegacyUser = (): boolean => {
  const {
    paypal_redesign_web: isPaypalRedesignWeb,
    paypal_v_stripe_variant: isPaypalVStripeVariant
  } = useFlags(['paypal_redesign_web', 'paypal_v_stripe_variant']);

  return !(isPaypalRedesignWeb.enabled || isPaypalVStripeVariant.enabled);
};
