import { observable, computed, action } from 'mobx';
import store from './AppStore';

class InvoiceInputFieldsUIStore {
  @observable
  businessAddressShown: boolean = false;

  @observable
  businessPhoneShown: boolean = false;

  @observable
  businessNumberShown: boolean = false;

  @observable
  businessWebsiteShown: boolean = false;

  @observable
  businessOwnerShown: boolean = false;

  @observable
  clientAddressShown: boolean = false;

  @observable
  clientPhoneShown: boolean = false;

  @action.bound
  showBusinessAddress() {
    this.businessAddressShown = true;
  }

  @action.bound
  showBusinessPhone() {
    this.businessPhoneShown = true;
  }

  @action.bound
  showBusinessNumber() {
    this.businessNumberShown = true;
  }

  @action.bound
  showAdditionalBusinessDetails() {
    this.businessAddressShown = true;
    this.businessPhoneShown = true;
    this.businessWebsiteShown = true;
    this.businessOwnerShown = true;
  }

  @action.bound
  showClientAddress() {
    this.clientAddressShown = true;
  }

  @action.bound
  showClientPhone() {
    this.clientPhoneShown = true;
  }

  @action.bound
  reset() {
    this.businessAddressShown = false;
    this.businessPhoneShown = false;
    this.businessNumberShown = false;
    this.clientAddressShown = false;
    this.clientPhoneShown = false;
  }

  @computed
  get shouldShowAdditionalBusinessDetails(): boolean {
    return (
      this.shouldShowBusinessAddress &&
      this.shouldShowBusinessPhone &&
      this.shouldShowBusinessWebsite &&
      this.shouldShowBusinessOwner
    );
  }
  @computed
  get shouldShowBusinessAddress(): boolean {
    if (store.doc.company.address1 || store.doc.company.address2 || store.doc.company.address3) {
      return true;
    }
    if (this.businessAddressShown) {
      return true;
    }
    return false;
  }

  @computed
  get shouldShowBusinessPhone(): boolean {
    if (store.doc.company.phone) {
      return true;
    }
    if (this.businessPhoneShown) {
      return true;
    }
    return false;
  }

  @computed
  get shouldShowBusinessNumber(): boolean {
    if (store.doc.company.businessNumber) {
      return true;
    }
    if (this.businessNumberShown) {
      return true;
    }
    return false;
  }

  @computed
  get shouldShowBusinessWebsite(): boolean {
    if (store.doc.company.website) {
      return true;
    }
    if (this.businessWebsiteShown) {
      return true;
    }
    return false;
  }

  @computed
  get shouldShowBusinessOwner(): boolean {
    if (store.doc.company.ownerName) {
      return true;
    }
    if (this.businessOwnerShown) {
      return true;
    }
    return false;
  }

  @computed
  get shouldShowClientAddress(): boolean {
    if (store.doc.client.address1) {
      return true;
    }
    if (this.clientAddressShown) {
      return true;
    }
    return false;
  }

  @computed
  get shouldShowClientPhone(): boolean {
    if (store.doc.client.phone) {
      return true;
    }
    if (this.clientPhoneShown) {
      return true;
    }
    return false;
  }
}

export default new InvoiceInputFieldsUIStore();
