import React from 'react';
import { OnboardOrigin } from 'src/payments/types';
import { PaymentsCurrencyMismatchFixModal } from './PaymentsCurrencyMismatchFixModal';
import { PaymentsCurrencyMismatchModal } from './PaymentsCurrencyMismatchModal';

export const PaymentsCurrencyModal = ({
  stripeCurrency,
  paypalCurrency,
  selectedCurrency,
  ...rest
}: {
  isOpen: boolean;
  origin: OnboardOrigin;
  hideModal: () => void;
  setCurrency: () => void;
  stripeCurrency: string | null;
  paypalCurrency: string | null;
  selectedCurrency: string | null;
}) => {
  if (!selectedCurrency) return null;
  if (!stripeCurrency && !paypalCurrency) return null;

  if (
    stripeCurrency?.toUpperCase() === selectedCurrency ||
    paypalCurrency?.toUpperCase() === selectedCurrency
  )
    return <PaymentsCurrencyMismatchFixModal {...rest} />;
  return <PaymentsCurrencyMismatchModal {...rest} />;
};
