import { observable, computed, action, reaction, comparer, IReactionDisposer } from 'mobx';
import omit from 'lodash/omit';
import { v1 as uuid } from 'uuid';
import { Platform } from '@invoice-simple/common';

import { Item as ParseItem } from '../util/IsParseDomain';
import * as ItemAPI from 'src/apis/itemAPI';
import environmentStore from 'src/stores/EnvironmentStore';
import SyncableEntity from 'src/models/SyncableEntity';
import UserModel from './UserModel';

export default class ItemModel extends SyncableEntity {
  autoSave?: IReactionDisposer;
  isUpdated: boolean; // used to differenciate between an item that was updated, or not, for the entire session
  isFresh: boolean; // used to differenciate between an item that is new and existing, for the entire session
  user: UserModel | undefined;

  @observable remoteId: string = uuid();
  @observable code: string = '';
  @observable description: string = '';
  @observable rate: number = 0;
  @observable unit: string = '';
  @observable taxable: boolean = true;
  @observable userSetRate: boolean = false;

  constructor(data: Parse.Object | any = {}, user?: UserModel) {
    super(ParseItem, ItemAPI, 'item');
    this.user = user;
    this.update(data);
  }

  @computed
  get parseData() {
    if (!this.isFresh) {
      this.isFresh = this.id === undefined;
    }

    return {
      id: this.id,
      remoteId: this.remoteId,
      code: this.code,
      description: this.description,
      rate: this.rate,
      unit: this.unit,
      deleted: this.deleted,
      taxable: this.taxable
    };
  }

  @computed
  get autoSaveData() {
    return omit(this.parseData, ['id']);
  }

  @action
  setCode(value: string): void {
    this.code = value;
    this.isUpdated = true;
  }

  @action
  setDescription(value: string): void {
    this.description = value;
    this.isUpdated = true;
  }
  @action
  setRate(v: number): void {
    this.userSetRate = v != null;
    this.rate = v || 0.0;
    this.isUpdated = true;
  }
  @action
  setTaxable(value: boolean): void {
    this.taxable = value;
    this.isUpdated = true;
  }
  @action
  setUnit(value: string): void {
    this.unit = value;
    this.isUpdated = true;
  }

  onCreate() {
    if (this.isNew && this.user)
      this.user.trackAppEventViaApi('item-create', {
        platform: Platform.WEB,
        source: 'item-list'
      });
  }

  startAutoSave() {
    if (this.autoSave) {
      return;
    }

    this.autoSave = reaction(() => this.autoSaveData, this.save.bind(this), {
      name: 'ItemAutoSave',
      equals: comparer.structural,
      delay: environmentStore.debounceRate
    });
  }

  @action
  protected update(data: Parse.Object | any) {
    this.id = data.id;
    this.remoteId = this._parse(data, 'remoteId');
    this.code = this._parse(data, 'code');
    this.description = this._parse(data, 'description');
    this.rate = this._parse(data, 'rate');
    this.unit = this._parse(data, 'unit');
    this.deleted = this._parse(data, 'deleted');
    this.taxable = this._parse(data, 'taxable');
    this.userSetRate = this.rate !== 0;
  }

  private _parse(obj: Parse.Object | any, name: string) {
    if (typeof obj.get === 'function') {
      return obj.get(name) === undefined ? this._default(name) : obj.get(name);
    } else {
      return obj[name] === undefined ? this._default(name) : obj[name];
    }
  }
  private _default(name: string) {
    return {
      code: '',
      description: '',
      rate: 0,
      unit: '',
      taxable: true,
      remoteId: uuid(),
      deleted: false
    }[name];
  }
}
