import React, { useState } from 'react';

import { SubscriptionTier } from '@invoice-simple/common';

import { Desktop, Mobile } from 'src/components/Responsive';
import { useISIntl } from 'src/i18n';
import UserModel, { Cadence } from 'src/models/UserModel';
import { AppStore } from 'src/stores/AppStore';
import { useUpgrade } from '../../hooks';
import { DesktopTierCards } from './DesktopTierCards/DesktopTierCards';
import { MobileTierCards } from './MobileTierCards/MobileTierCards';

interface TierCardsProps {
  store: AppStore;
  selectedInterval: Cadence;
  shouldDisallowSwitch: (destinationTier: SubscriptionTier, user: UserModel) => boolean;
  className?: string;
}

export const SubscriptionTierCards = ({
  store,
  selectedInterval,
  shouldDisallowSwitch,
  className
}: TierCardsProps) => {
  const intl = useISIntl();
  const user = UserModel.getInstance();
  const couponInfo = user.paywallCouponInfo;

  const { onUpgradeClick, onBuyClick, loadingCheckout } = useUpgrade({
    store,
    intl,
    selectedInterval,
    shouldDisallowSwitch
  });

  const loading = loadingCheckout;

  const [selectedTier, setSelectedTier] = useState<SubscriptionTier>(SubscriptionTier.PLUS);

  const handleSubscriptionPurchase = (tier: SubscriptionTier) => {
    setSelectedTier(tier);
    if (couponInfo) {
      onBuyClick(tier, selectedInterval);
      return;
    }
    onUpgradeClick(tier);
  };

  return (
    <div className={className} data-testid="plan-tier-cards">
      {/* md+ */}
      <Desktop>
        <DesktopTierCards
          handleSubscriptionPurchase={handleSubscriptionPurchase}
          loading={loading}
          selectedInterval={selectedInterval}
          selectedTier={selectedTier}
        />
      </Desktop>

      {/* xs to md */}
      <Mobile>
        <MobileTierCards
          handleSubscriptionPurchase={handleSubscriptionPurchase}
          loading={loading}
          selectedInterval={selectedInterval}
          selectedTier={selectedTier}
          setSelectedTier={setSelectedTier}
        />
      </Mobile>
    </div>
  );
};
