import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'src/util/functions';
import { injectIntl, defineMessages } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';

const messages = defineMessages({
  name: {
    id: 'navbar.navBrand.appName',
    defaultMessage: 'Invoice Simple'
  }
});

const NavBrand = (props: { store: AppStore; intl: ISIntl }) => {
  const name = props.intl.formatMessage(messages.name);
  return (
    <a rel="nofollow" className="brand" onClick={() => props.store.nav('invoiceList')}>
      <img alt={name} src="/images/logo.png" role="presentation" />
      <div className="name">{name}</div>
    </a>
  );
};

export default compose(inject('store'), injectIntl, observer)(NavBrand);
