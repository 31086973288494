import axios from 'axios';
import environmentStore from 'src/stores/EnvironmentStore';

import { ContentTypes } from '@invoice-simple/common';

import { getPaypalToken } from './paypalToken';

async function getHeaders(isPublicEndpoint?: boolean) {
  const headers: Record<string, string> = {
    'Content-Type': ContentTypes.APP_JSON,
    'x-is-platform': 'web',
    'x-is-version': environmentStore.getReactAppVersion()
  };
  if (isPublicEndpoint) {
    return headers;
  }

  const token = await getPaypalToken();
  headers.Authorization = `Bearer ${token}`;
  return headers;
}

export async function invokeIsPaypal<T = unknown, U = unknown>(
  method: 'POST' | 'GET' | 'PUT',
  endpoint: string,
  body?: U,
  isPublicEndpoint?: boolean
): Promise<T> {
  const url = `${process.env.REACT_APP_PAYPAL_URL}${endpoint}`;
  const headers = await getHeaders(isPublicEndpoint);
  try {
    const res = await axios.request({ url, method, headers, data: JSON.stringify(body) });
    return res.data;
  } catch (error) {
    throw new Error(`Unexpected error calling api ${method} ${url}: ${error.message}`);
  }
}

export const navigateHard = (url: string, isNewTab: boolean = false) => {
  if (isNewTab) {
    window.open(url, '_blank');
    return;
  }
  window.location.assign(url);
};

async function getMinimalIsPayPalQueryParams() {
  const token = await getPaypalToken();
  return `token=${token}&platform=web&version=${environmentStore.getReactAppVersion()}`;
}

// Go to paypal landing page.
export async function startPaypalOnboarding(isNewTab: boolean = true) {
  try {
    navigateHard(
      `${process.env.REACT_APP_PAYPAL_URL}/?${await getMinimalIsPayPalQueryParams()}`,
      isNewTab
    );
  } catch (error) {
    throw new Error(error.message);
  }
}

// Go directly to paypal onboarding page, skipping the landing page.
export async function startPaypalOnboardingNoTerm({
  isNewTab = true,
  upgradeToPPCP = false,
  paypalRedesign = false
}: {
  isNewTab?: boolean;
  upgradeToPPCP?: boolean;
  paypalRedesign?: boolean;
}) {
  try {
    const searchParams = new URLSearchParams({
      tos: 'v0_implicit',
      ...(upgradeToPPCP && { ppcpUpgrade: '1' }),
      ...(paypalRedesign && { paypalRedesign: '1' }),
      ...(paypalRedesign && { onboardingRedirectUrl: window.location.href })
    });
    navigateHard(
      `${
        process.env.REACT_APP_PAYPAL_URL
      }/connect?${await getMinimalIsPayPalQueryParams()}&${searchParams.toString()}`,
      isNewTab
    );
  } catch (error) {
    throw new Error(error.message);
  }
}
