import React from 'react';

import { twMerge } from 'tailwind-merge';

import { Cadence } from 'src/models/UserModel';
import { SubscriptionBadgeV2 } from './SubscriptionBadgeV2';

interface PlanIntervalButtonProps {
  interval: Cadence;
  isSelected: boolean;
  buttonText: string;
  onClick: () => void;
  badgeText?: string;
  className?: string;
}

export const PlanIntervalButton = ({
  interval,
  isSelected,
  buttonText,
  onClick,
  badgeText,
  className
}: PlanIntervalButtonProps) => {
  const selectedClasses = 'rounded-xl border-orange-is bg-orange-100';
  const unselectedClasses = 'border-transparent border-2 border-solid';

  return (
    <button
      type="button"
      data-testid={`btn-${interval}`}
      className={twMerge(
        'text-2xl font-semibold relative inline-flex px-20 py-2.5 border-solid hover:rounded-xl hover:border-orange-100 hover:bg-orange-100 focus:rounded-xl focus:border-orange-is focus:bg-orange-100',
        isSelected ? selectedClasses : unselectedClasses,
        className
      )}
      onClick={onClick}>
      {buttonText}
      {badgeText && (
        <SubscriptionBadgeV2
          text={badgeText}
          variant="dark"
          className="absolute -top-7 -right-1 px-3 py-[2px] tracking-tighter"
        />
      )}
    </button>
  );
};
