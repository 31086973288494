import React from 'react';

export const Loading = () => (
  <div className="loading-logo d-print-none">
    <img
      className="brand-logo"
      width="80"
      src="/images/brand.png"
      role="presentation"
      alt="invoice simple logo"
      data-pin-nopin="true"
    />
  </div>
);
