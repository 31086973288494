import React from 'react';
import DatePicker from 'react-datepicker';
import { observer } from 'mobx-react';
import { injectIntl, defineMessages } from 'src/i18n';
import { compose } from 'src/util/functions';
import localeStore from 'src/stores/LocaleStore';
import addYears from 'date-fns/addYears';
import subYears from 'date-fns/subYears';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerWrapper.scss';

const messages = defineMessages({
  today: {
    id: 'datePicker.today',
    defaultMessage: 'today'
  }
});

const MAX_DATE = addYears(new Date(), 20);
const MIN_DATE = subYears(new Date(), 20);

const DatePickerWrapper = ({ onChange, intl, ...rest }) => (
  <DatePicker
    dateFormat={[
      'PP',
      'yyyy-MM-dd',
      'yyyy/MM/dd',
      'dd-MM-yyyy',
      'dd/MM/yyyy',
      'MM-dd-yyyy',
      'dd-MM-yyyy',
      'MMM dd, yyyy',
      'MMM dd yyyy',
      'MMMM dd, yyyy',
      'MMMM dd yyyy',
      'dd MMMM yyyy',
      'dd MMM yyyy'
    ]}
    locale={localeStore.datesLocale}
    todayButton={intl.ft(messages.today)}
    onChange={(date: Date | null) => {
      if (date === null) {
        return;
      }
      if (date > MIN_DATE && date < MAX_DATE) {
        return onChange(date);
      }
      return onChange(new Date());
    }}
    {...rest}
  />
);

export default compose(injectIntl, observer)(DatePickerWrapper);
