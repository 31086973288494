import React, { useContext } from 'react';

import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { PaymentEvent } from 'src/payments/types';
import { hasCurrencyMismatch as hasCurrencyMismatchFunc } from 'src/payments/utils/utils';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';

import { DocType, DocTypes, SubscriptionTier } from '@invoice-simple/common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PaypalAccountContext } from '../../contexts/PaypalAccountContext';
import { PaymentsInstructionsLink, SupportedPaymentMethods } from '../PaymentBasicElements';

import './InvoicePaymentSetting.css';

import { isMigratedSettingsEnabled } from 'src/util/isMigratedSettingsEnabled';

interface PaymentSettingDictionary {
  acceptPayments: string;
  currencyMismatch: string;
}
interface PaymentSettingProps {
  title?: string;
  document: {
    docType: DocType;
    setting: {
      paymentSuppressed?: boolean;
    };
  };
  onTogglePayments: (value: boolean) => void;
  dictionary: PaymentSettingDictionary;
  currency?: string | undefined;
  store?: AppStore;
  onTrackEvent: (eventName: PaymentEvent, metadata?: Record<string, string>) => void;
}

const InvoicePaymentSettingComponent = (props: PaymentSettingProps) => {
  const { document, onTogglePayments, title, dictionary, currency, store } = props;
  const paypalAccountContext = useContext(PaypalAccountContext);
  if (
    !paypalAccountContext ||
    !paypalAccountContext.isPaypalEligible() ||
    document.docType !== DocTypes.DOCTYPE_INVOICE
  ) {
    return null;
  }

  const { isPaypalEnabled, isPaypalAccepting, getPaypalAccountCurrency } = paypalAccountContext;

  if (!isPaypalAccepting() || !isPaypalEnabled()) {
    return null;
  }

  const paypalCurrency = getPaypalAccountCurrency();
  const hasCurrencyMismatch = hasCurrencyMismatchFunc(paypalCurrency, currency);

  return (
    <div>
      {!!title && <h4>{title}</h4>}
      <div style={{ opacity: hasCurrencyMismatch ? 0.2 : 1 }}>
        <div className="setting-form-group form-group row mb-md-4">
          <div className="col-md-6 col-lg-12 d-flex align-items-center">
            <label className="col-form-label">{dictionary.acceptPayments}</label>
            <div className="ml-2 ml-md-3">
              <ToggleSwitch
                checked={!document.setting.paymentSuppressed}
                onChange={(toggleValue: boolean) => {
                  onTogglePayments(!toggleValue);
                }}
                disabled={
                  hasCurrencyMismatch || store!.user.isSubTier(SubscriptionTier.PAYMENTS_TIER)
                }
              />
            </div>
          </div>
        </div>

        <div className="setting-form-group form-group row mb-md-3">
          <div className="col-md-6 col-lg-12 d-flex align-items-center w-75">
            <SupportedPaymentMethods />
          </div>
        </div>

        <div className="setting-form-group form-group row mb-md-4">
          <div className="col-md-6 col-lg-12 d-flex align-items-center w-75">
            <PaymentsInstructionsLink
              onClick={() => {
                if (isMigratedSettingsEnabled(store?.user.countryCode)) {
                  window.location.assign('/settings');
                  return;
                }
                store?.nav('settingList');
              }}
            />
          </div>
        </div>
      </div>
      {hasCurrencyMismatch && (
        <div className="currency-mismatch-body">
          <FontAwesomeIcon icon={'exclamation-circle'} color="orange" size="2x" />
          <div className="text-note">{dictionary.currencyMismatch}</div>
        </div>
      )}
    </div>
  );
};

type InvoicePaymentSetting = ({
  document,
  onTogglePayments,
  title,
  dictionary,
  currency,
  onTrackEvent
}: PaymentSettingProps) => JSX.Element | null;

export const InvoicePaymentSetting: InvoicePaymentSetting = injectStore(
  InvoicePaymentSettingComponent
);
