import { DepositRates, DepositTypes } from '@invoice-simple/common';
import { useISIntl } from 'src/i18n';
import { messages } from './PaymentDeposit';

export function getDepositTypeOptions() {
  const { ft } = useISIntl();

  return [
    { value: DepositTypes.NONE, label: ft(messages.depositTypeNone) },
    { value: DepositTypes.PERCENT, label: ft(messages.depositTypePercent) },
    { value: DepositTypes.FLAT, label: ft(messages.depositTypeFlat) }
  ];
}

export function getDepositRateOptions() {
  const { ft } = useISIntl();
  return [
    { value: DepositRates.DEPOSIT_NONE, label: ft(messages.depositTypeNone) },
    { value: DepositRates.DEPOSIT_TEN, label: `${DepositRates.DEPOSIT_TEN}%` },
    {
      value: DepositRates.DEPOSIT_TWENTY,
      label: `${DepositRates.DEPOSIT_TWENTY}%`
    },
    {
      value: DepositRates.DEPOSIT_THIRTY,
      label: `${DepositRates.DEPOSIT_THIRTY}%`
    },
    {
      value: DepositRates.DEPOSIT_FORTY,
      label: `${DepositRates.DEPOSIT_FORTY}%`
    },
    {
      value: DepositRates.DEPOSIT_FIFTY,
      label: `${DepositRates.DEPOSIT_FIFTY}%`
    }
  ];
}
