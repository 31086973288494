// Update nextjs/util/url.ts as well
export enum NextJSRefValues {
  /* Expenses */
  EXPENSE_DROP_ZONE_INPUT = 'expense-drop-zone-input',
  EXPENSE_DROP_ZONE_PROVIDER = 'expense-drop-zone-provider',
  NEW_EXPENSE = 'new-expense',

  /* Misc */
  DEPOSITS = 'DEPOSITS',
  NAVBAR_UPGRADE_CROWN = 'navbar-upgrade-crown',
  INVOICE_TEMPLATES = 'invoice-templates',
  TRIAL_BANNER = 'trial-banner',
  REVIEW_LINK = 'reviewLink',
  INVOICE_SIGNATURE = 'invoice-signature',
  INVALID_REFERRAL_LINK = 'invalidReferralLink',
  SETTING = 'setting',
  REACTIVATION_PROMPT = 'reactivation-prompt',
  DOC_LIMIT_BANNER = 'doc-limit-banner'
}

export enum BaseURLQueryParamKeys {
  /* Generic */
  REF = 'ref',
  ALERT = 'alert',
  ACTION = 'action',

  INVOICE = 'invoice',
  ESTIMATE = 'estimate',
  INVOICE_NO = 'invoice-no',

  /* Email Sending */
  ID = 'id',
  INVOICE_SEND_ACTION = 'invoice-email-send',
  EMAIL_DOCTYPE = 'email-docType',
  TO_EMAIL = 'to-email',

  /* Profit margin calculator */
  LABOR_COSTS = 'labor-costs',
  MATERIAL_COSTS = 'material-costs',
  INVOICE_ID = 'invoiceId',

  /* Ref values */
  CREATE_DOCUMENT = 'create-document',
  SEND_DOCUMENT = 'send-document',
  DOCUMENT_ACTIONS = 'document-action',
  SETTINGS = 'settings',
  ESTIMATE_EDITOR = 'estimate-editor',
  RETURN_PATH = 'returnPath',

  /* Referral Program */
  REFERRAL_SIGNUP = 'referral-signup',
  REFERRAL_PURCHASE = 'referral-purchase',
  REFERRAL_CODE = 'referralCode',
  REFERRAL_SHARE = 'referral-share',

  /* Feature Gate */
  FEATURE = 'feature',

  /* campaign */
  CAMPAIGN = 'campaign',

  /* Paypal and Stripe onboarding */
  OLD_PAYPAL_ONBOARDING = 'old-paypal-onboarding',
  PAYPAL_ONBOARDING = 'paypal-onboarding',
  STRIPE_ONBOARDING = 'stripe-onboarding',

  /* Subscription cancel page */
  PLATFORM = 'platform',
  USER_ID = 'userId',
  USD_PRICE = 'usdPrice',
  ANNUAL_SUB = 'annualSub',
  USER_CREATED_AT = 'userCreatedAt',
  SUB_EXPIRY_MS = 'subExpiryMs',
  EMAIL = 'email',

  /* Subscription transfer */
  SUB_TRANSFER = 'sub-transfer',

  /* payment intent request */
  PAYMENT_INTENT = 'payment-intent',

  /* Subscription Coupon */
  COUPON = 'coupon',

  /* Landing page */
  PAYMENTS_LANDING = 'payments-landing',
  ORIGIN = 'origin'
}

export const URLQueryParamKeys = { ...BaseURLQueryParamKeys, ...NextJSRefValues };

export const getURLQueryParam = (key: string) =>
  new URLSearchParams(window.location.search).get(key);

export const queryParamsToObject = (params: URLSearchParams) => {
  const obj: { [key: string]: string } = {};

  Array.from(params.entries()).forEach(([key, value]) => {
    obj[key] = value;
  });

  return obj;
};
