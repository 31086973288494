import React from 'react';
import { SVGProps } from 'react';

export const ChartIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 0.75C1.86929 0.75 0.75 1.86929 0.75 3.25V15.75C0.75 17.1307 1.86929 18.25 3.25 18.25H15.75C17.1307 18.25 18.25 17.1307 18.25 15.75V3.25C18.25 1.86929 17.1307 0.75 15.75 0.75H3.25ZM14.5 5.75C14.5 5.05964 13.9404 4.5 13.25 4.5C12.5596 4.5 12 5.05964 12 5.75V13.25C12 13.9404 12.5596 14.5 13.25 14.5C13.9404 14.5 14.5 13.9404 14.5 13.25V5.75ZM10.75 8.25C10.75 7.55964 10.1904 7 9.5 7C8.80964 7 8.25 7.55964 8.25 8.25V13.25C8.25 13.9404 8.80964 14.5 9.5 14.5C10.1904 14.5 10.75 13.9404 10.75 13.25V8.25ZM7 12C7 11.3096 6.44036 10.75 5.75 10.75C5.05964 10.75 4.5 11.3096 4.5 12V13.25C4.5 13.9404 5.05964 14.5 5.75 14.5C6.44036 14.5 7 13.9404 7 13.25V12Z"
        fill="#FF5721"
      />
    </svg>
  );
};
