import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { useISIntl } from 'src/i18n/utils';
import ReportModel from 'src/models/ReportModel';
import { AppStore } from 'src/stores/AppStore';
import ClientsReport from './ClientsReport';
import FilterButton from './FilterButton';
import { PaidReport, PaidReportHeader } from './PaidReport';
import { messages } from './ReportMessages';
import { ReportType, ReportYear } from './ReportTypes';
import './ReportList.scss';
import { ItemReport } from './ItemReport';
import { isAllowedReports } from './util';
import { FeatureName } from '@invoice-simple/feature-gate';
import { navToPaywallWithFeature } from 'src/util/navigation';

interface ReportListProps {
  list: ReportModel;
  store: AppStore;
  onLoad: () => void;
  onCreate: () => void;
}

interface EmptyRowProps {
  list: ReportModel;
  onCreate: () => void;
}

const renderReport = (type: ReportType, list: ReportModel): JSX.Element => {
  const reports: Record<ReportType, JSX.Element> = {
    clients: <ClientsReport list={list} />,
    paid: <PaidReport list={list} />,
    items: <ItemReport list={list} />
  };

  return reports[type];
};

const ReportList = ({ list, store, onLoad, onCreate }: ReportListProps) => {
  const isLoaded = store.user.isLoaded;
  useEffect(() => {
    if (!isLoaded) return;
    if (isLoaded && !isAllowedReports(store.user)) {
      return navToPaywallWithFeature(FeatureName.REPORTS);
    }

    store.user.events.trackAction('report-list-view');
    onLoad();
  }, [isLoaded]);

  const handleYear = (year: ReportYear) => {
    store.user.events.trackLinkClick({ text: year.year, action: 'pagination' });

    list.changeYear(year.url);
  };

  const reports = list.reports;
  const isEmpty = !reports || !reports.groups || reports.groups.length === 0;

  return (
    <div className="report-list">
      <div className="container">
        {isEmpty && <EmptyRow list={list} onCreate={onCreate} />}
        {!isEmpty && renderReport(reports.activeReport, list)}

        {reports && reports.years && reports.years.length >= 1 && (
          <div className="report-list-footer">
            <div className="btn-group" role="group">
              {reports.years.map((year, idg) => (
                <button
                  key={`report-year-filter-${idg}`}
                  type="button"
                  className={`btn btn-filter ${year.active ? 'active' : ''}`}
                  onClick={() => handleYear(year)}>
                  {year.year}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ReportList);

const EmptyRow = ({ list, onCreate }: EmptyRowProps) => {
  const { formatMessage: f, getLocalizedDocumentType } = useISIntl();

  return (
    <>
      <FilterButton list={list} />
      <table className="table">
        <thead className="thead">
          <PaidReportHeader />
          <tr className="invoice-row-empty">
            <td colSpan={5}>
              <>
                {!list.reports ? (
                  <span>{f(messages.rowLoading)}</span>
                ) : (
                  <>
                    <span>{f(messages.rowEmpty)}</span>
                    <span>
                      ,
                      <a
                        className={`btn-link btn-link-${getLocalizedDocumentType().toLowerCase()}-new`}
                        onClick={onCreate}>
                        {f(messages.rowButtonNew)}
                      </a>
                    </span>
                  </>
                )}
              </>
            </td>
          </tr>
        </thead>
      </table>
    </>
  );
};
