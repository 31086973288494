import { useEffect, useState } from 'react';
import { stripePaymentsConnectClientSecret } from '../utils';
import { StripeConnectInstance, loadConnectAndInitialize } from '@stripe/connect-js';

interface IStripeConnectInstance {
  stripeConnectInstance: StripeConnectInstance;
}

export const useStripeConnectInstance = (): IStripeConnectInstance => {
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      const { client_secret } = await stripePaymentsConnectClientSecret();
      return client_secret;
    };

    return loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_CONNECT_PUBLISHABLE_KEY || '',
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          colorBackground: '#FFFFFF',
          badgeSuccessColorBackground: '#DCFCE7',
          badgeSuccessColorText: '#1E293B',
          badgeDangerColorBackground: '#FEE2E2',
          badgeDangerColorText: '#1E293B',
          borderRadius: '4px'
        }
      }
    });
  });

  useEffect(() => {
    return () => {
      stripeConnectInstance.logout();
    };
  }, [stripeConnectInstance]);

  return { stripeConnectInstance };
};
