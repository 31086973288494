import { defineMessages } from 'src/i18n';

export const paypalBoxMessages = defineMessages({
  closedCTA: {
    id: 'paypalBox.cta.closed',
    defaultMessage: 'Closed'
  },
  setUpCTA: {
    id: 'paypalBox.cta.setup',
    defaultMessage: 'Set up'
  },
  finishSetupCTA: {
    id: 'paypalBox.cta.finishSetup',
    defaultMessage: 'Finish Setup'
  },
  managePaypalAccount: {
    id: 'paypalBox.cta.managePaypalAccount',
    defaultMessage: 'Manage PayPal Account'
  },
  email: {
    id: 'paypalBox.label.email',
    defaultMessage: 'Email'
  },
  accountId: {
    id: 'paypalBox.label.accountId',
    defaultMessage: 'Account ID'
  },
  currency: {
    id: 'paypalBox.label.currency',
    defaultMessage: 'Currency'
  },
  creditDebitPaypal: {
    id: 'paypalBox.label.creditDebitPaypal',
    defaultMessage: 'Credit, Debit & PayPal'
  },
  paypalVenmo: {
    id: 'paypalBox.label.paypalVenmo',
    defaultMessage: 'PayPal & Venmo'
  }
});
