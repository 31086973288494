import React from 'react';
import { observer } from 'mobx-react';
import Autosuggest from 'react-autosuggest';
import ClientSuggestModel from '../models/ClientSuggestModel';
import { injectIntl, defineMessages } from 'src/i18n';

import './AutoSuggest.css';
import './ClientSuggest.scss';
import { ISIntl } from 'src/i18n/utils';
import ClientModel from 'src/models/ClientModel';

const messages = defineMessages({
  clientName: {
    id: 'clientSuggest.clientName.placeholder',
    defaultMessage: 'client name'
  }
});

interface Prop {
  value: string;
  disabled: boolean;
  onChange: (newValue: any) => void;
  onSelect: (suggestion: any) => void;
  suggest: ClientSuggestModel;
  intl?: ISIntl;
}

@injectIntl
@observer
export default class ClientSuggest extends React.Component<Prop, any> {
  onChange = (_, { newValue }) => {
    this.props.onChange(newValue);
  };
  onSuggestionsClearRequested = () => {
    this.props.suggest.clearName();
  };
  onSuggestionsFetchRequested = ({ value }) => {
    this.props.suggest.setName(value);
  };
  onSuggestionSelected = (_, data) => {
    this.props.onSelect(data.suggestion);
  };
  getSuggestionValue = (suggestion: ClientModel) => {
    return suggestion.name;
  };
  renderSuggestion(suggestion: ClientModel) {
    return (
      <div className="invoice-suggestion-option clearfix">
        <span className="invoice-suggestion-name">{suggestion.name}</span>
        <span className="invoice-suggestion-meta">{suggestion.email}</span>
      </div>
    );
  }
  render() {
    const suggestions = this.props.suggest.clients.slice();
    return (
      <div className="client-suggest">
        <Autosuggest
          inputProps={{
            id: 'invoice-client-name',
            placeholder: this.props.intl!.fta(messages.clientName),
            disabled: this.props.disabled,
            value: this.props.value,
            onChange: this.onChange
          }}
          suggestions={suggestions}
          getSuggestionValue={this.getSuggestionValue}
          onSuggestionSelected={this.onSuggestionSelected}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          renderSuggestion={this.renderSuggestion}
        />
      </div>
    );
  }
}
