export const customStyles = {
  content: {
    position: 'relative',
    border: 'none',
    background: 'none',
    inset: '0px',
    overflow: 'none',
    padding: 'none'
  } as React.CSSProperties,
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
    zIndex: 50
  } as React.CSSProperties
};
