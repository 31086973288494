import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { onError, redirectToPaypalDashboard } from '../../utils/utils';
import { PaypalAccountContext } from '../../contexts/PaypalAccountContext';
import { PaymentEventLocation, PaymentEventLocationType } from '../../types';
import { NumberIcon } from './NumberIcon';
import { PaymentSetupButton } from '../PaymentBasicElements';

import './PaypalPromotionBox.scss';
import { messages } from './messages';

interface Props {
  location: PaymentEventLocationType;
  closePaymentModal?: () => void;
}

export const PaypalOnboardedBox = ({ location, closePaymentModal }: Props) => {
  const paypalSetting = useContext(PaypalAccountContext);

  if (!paypalSetting) {
    return null;
  }

  const goToPaypalDashboard = () => {
    try {
      if (location === PaymentEventLocation.paymentModal && closePaymentModal) {
        closePaymentModal();
      }

      redirectToPaypalDashboard();
    } catch (error) {
      onError({ title: 'Error loading PayPal Dashboard', body: error.message });
    }
  };

  return (
    <div>
      <div className="row">
        <p className="text-body">
          <FormattedMessage {...messages.onboardedBody} />
        </p>
      </div>
      <div className="row note-container">
        <NumberIcon value={1} />
        <div className="text-note-onboarded">
          <FormattedMessage {...messages.onboardedNote1} />
        </div>
      </div>
      <div className="row note-container">
        <NumberIcon value={2} />
        <div className="text-note-onboarded">
          <FormattedMessage {...messages.onboardedNote2} />
        </div>
      </div>
      <div className="row note-container">
        <NumberIcon value={3} />
        <div className="text-note-onboarded">
          <FormattedMessage {...messages.onboardedNote3} />
        </div>
      </div>
      <div className="row">
        <PaymentSetupButton
          className="col-12 pending-onboarded-button"
          buttonText={<FormattedMessage {...messages.onboardedButton} />}
          buttonBackgroundColor="orange"
          loading={false}
          onButtonClick={goToPaypalDashboard}
        />
      </div>
    </div>
  );
};
