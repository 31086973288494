import React, { useContext } from 'react';
import { PaymentEvent } from '../../types';
import { PaypalAccountContext } from '../../contexts/PaypalAccountContext';
import { PaypalSetupAlert, PaypalSetupAlertDictionary } from './PaypalSetupAlert';
import { ManageAccount, ManageAccountDictionary } from './ManageAccount';
import { PaypalClosedAlert, PaypalClosedAlertDictionary } from './PaypalClosedAlert';
import './styles/PaymentSetupProgress.css';

type PaymentSetupProgressDictionary = PaypalSetupAlertDictionary &
  ManageAccountDictionary &
  PaypalClosedAlertDictionary;

interface PaymentSetupProgressProps {
  dictionary: PaymentSetupProgressDictionary;
  onTrackEvent: (eventName: PaymentEvent, metadata?: Record<string, number | string>) => void;
}

export function PaymentSetupProgress({ dictionary, onTrackEvent }: PaymentSetupProgressProps) {
  const paypalAccountContext = useContext(PaypalAccountContext);
  if (!paypalAccountContext) {
    return null;
  }

  const { isPaypalPending, isPaypalOnboarded, isPaypalAccepting, isPaypalClosed } =
    paypalAccountContext;

  if (isPaypalClosed()) {
    return <PaypalClosedAlert dictionary={dictionary} />;
  }

  if (isPaypalAccepting()) {
    return <ManageAccount dictionary={dictionary} />;
  }
  if (isPaypalPending() || isPaypalOnboarded()) {
    return <PaypalSetupAlert dictionary={dictionary} onTrackEvent={onTrackEvent} />;
  }
  return null;
}
