const intlSupported = () => {
  if (typeof Intl === 'undefined') {
    return false;
  }

  if ([Intl.DateTimeFormat, Intl.NumberFormat].filter((i) => i).length !== 2) {
    return false;
  }

  return true;
};

export const maybeLoadIntlPolyfill = () =>
  Promise.resolve().then(() => {
    if (intlSupported()) {
      return Promise.resolve();
    }

    return import('intl').then((intl) => {
      Object.assign(Intl, {
        NumberFormat: intl.default.NumberFormat,
        DateTimeFormat: intl.default.DateTimeFormat
      });
    });
  });
