import React, { FC } from 'react';
import { ButtonContainer, TopContainer, ValueProps } from '../../PaymentBasicElements';
import { PaymentModalWrapper } from '../PaypalModalWrapper';
import { defineMessages, useISIntl } from 'src/i18n';
import { PaymentEventActivePaymentProvider, PaymentEventComponent } from 'src/payments/types';
import { useTrackPaymentsViewedEvent } from 'src/payments/hooks/useTrackPaymentsViewedEvent';
import { AppStore } from 'src/stores/AppStore';
import injectStore from 'src/util/injectStore';
import { getPaymentEventSource } from 'src/payments/utils';

const messages = defineMessages({
  unlockOnlinePayments: {
    id: 'payments.modal.title.unlockOnlinePayments',
    defaultMessage: 'Unlock Online Payments'
  },
  unlockOnlinePaymentsDescription: {
    id: 'payments.modal.description.unlockOnlinePaymentsDescription',
    defaultMessage: 'You are enabling Online Payments for all invoices.'
  },
  unlock: {
    id: 'payments.modal.cta.unlock',
    defaultMessage: 'Unlock'
  }
});

const PaymentsEnablingModalComponent: FC<{
  activePaymentProvider: PaymentEventActivePaymentProvider;
  isOpen: boolean;
  hideModal: () => void;
  onClickHandler: () => void;
  store: AppStore;
}> = ({ activePaymentProvider, isOpen, hideModal, onClickHandler, store: { user } }) => {
  const { fta, ft } = useISIntl();

  const paymentsViewedMeta = {
    source: getPaymentEventSource(),
    activePaymentProvider,
    component: PaymentEventComponent.modalEnabling
  };

  useTrackPaymentsViewedEvent(paymentsViewedMeta, user.events);

  return (
    <PaymentModalWrapper isOpen={isOpen} onRequestClose={hideModal}>
      <div id={'tailwind'} data-testid={'paymentsEnablingModal'}>
        <TopContainer onClickXIcon={hideModal} headerMessage={fta(messages.unlockOnlinePayments)} />
        <main>
          <ValueProps>
            <p>{ft(messages.unlockOnlinePaymentsDescription)}</p>
          </ValueProps>
        </main>
        <ButtonContainer
          handleClick={onClickHandler}
          buttonMessage={ft(messages.unlock)}
          dataTestId="paymentsEnablingModalCTA"
        />
      </div>
    </PaymentModalWrapper>
  );
};

export const PaymentsEnablingModal = injectStore(PaymentsEnablingModalComponent);
