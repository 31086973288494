import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  stripeTransactions: {
    id: 'paymentsDashboard.stripeTransactions',
    defaultMessage: 'Stripe Transactions'
  },
  stripePayouts: {
    id: 'paymentsDashboard.stripePayouts',
    defaultMessage: 'Stripe Payouts'
  },
  paypalPayments: {
    id: 'paymentsDashboard.paypalPayments',
    defaultMessage: 'PayPal Payments'
  }
});
