import { DocType, DocTypes } from '@invoice-simple/common';
import titleize from './Titleize';

export const DOCUMENT_NAME = {
  INVOICE: 'invoice',
  ESTIMATE: 'estimate',
  RECEIPT: 'receipt'
};

export const docTypeToName = (type: number): string => {
  switch (type) {
    case DocTypes.DOCTYPE_INVOICE:
      return DOCUMENT_NAME.INVOICE;
    case DocTypes.DOCTYPE_ESTIMATE:
      return DOCUMENT_NAME.ESTIMATE;
    case DocTypes.DOCTYPE_STATEMENT:
      return DOCUMENT_NAME.RECEIPT;
    default:
      return '';
  }
};

export const docNameToType = (name: string): DocType => {
  switch (name) {
    case DOCUMENT_NAME.INVOICE:
      return DocTypes.DOCTYPE_INVOICE;
    case DOCUMENT_NAME.ESTIMATE:
      return DocTypes.DOCTYPE_ESTIMATE;
    case DOCUMENT_NAME.RECEIPT:
      return DocTypes.DOCTYPE_STATEMENT;
    default:
      return 0;
  }
};

export const docTypeToLabel = (type: DocType): string => titleize(docTypeToName(type));
