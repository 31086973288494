import React, { useContext, useEffect, useState } from 'react';

import { UserAttributes } from 'src/util/braze';

import * as PaypalUtils from '@invoice-simple/is-paypal-sdk';

import { getUser } from '@braze/web-sdk';

import { PaypalModal } from '../components/IntegratedPayments';
import { usePaymentOnboardModal } from '../hooks/usePaymentsOnboardModal';
import { getPaypalAccountStatus, paypalDisable, paypalEnable } from '../utils/Accounts';

interface PaypalAccountContext {
  paypalStatus: PaypalUtils.PaypalAccountSettings | undefined;
  updatePaypalStatus: () => Promise<void>;
  updatePaypalEnabled: (enable: boolean) => Promise<void>;
  isPaypalEnabled: () => boolean;
  isPaypalEligible: () => boolean;
  isPaypalInitial: () => boolean;
  isPaypalPending: () => boolean;
  isPaypalOnboarded: () => boolean;
  isPaypalAccepting: () => boolean;
  isPaypalClosed: () => boolean;
  getPaypalAccountEmail: () => string | null;
  getPaypalMerchantId: () => string | null;
  getPaypalAccountCurrency: () => string | null;
  getPaypalFees: () => PaypalUtils.PaypalFees | null;
  isPaypalOnboardingCompleted: () => boolean;
  isEligibleForPPCPUpgrade: () => boolean;
  isPrimaryEmailConfirmed: () => boolean;
  isPaypalModalOpen: boolean;
  showPaypalModal: () => void;
  hidePaypalModal: () => void;
}

export const PaypalAccountContext = React.createContext<PaypalAccountContext | null>(null);

export const PaypalAccountProvider = ({ children }: { children: React.ReactNode }) => {
  const [paypalStatus, setPaypalStatus] = useState<PaypalUtils.PaypalAccountSettings>();
  const { isOpen, showModal, hideModal, origin } = usePaymentOnboardModal();

  async function updatePaypalStatus(): Promise<void> {
    try {
      const accountStatus = await getPaypalAccountStatus();

      sendCustomAttributesToBraze(accountStatus);

      setPaypalStatus(accountStatus);
    } catch (error) {
      return;
    }
  }

  function sendCustomAttributesToBraze(newAccountStatus?: PaypalUtils.PaypalAccountSettings): void {
    const newPaypalStatus = newAccountStatus?.paypal?.paypalStatus;
    const oldPaypalStatus = paypalStatus?.paypal?.paypalStatus;
    if (
      newPaypalStatus !== undefined &&
      newPaypalStatus !== null &&
      newPaypalStatus !== oldPaypalStatus
    ) {
      getUser()?.setCustomUserAttribute(
        UserAttributes.PAYMENT_STATUS,
        PaypalUtils.PaypalStatus[newPaypalStatus]
      );
    }
  }

  useEffect(() => {
    updatePaypalStatus();
    window.addEventListener('focus', updatePaypalStatus);
    return () => {
      window.removeEventListener('focus', updatePaypalStatus);
    };
  }, []);

  const updatePaypalEnabled = async (enable: boolean) => {
    enable ? await paypalEnable() : await paypalDisable();
    const accountStatus = await getPaypalAccountStatus();
    setPaypalStatus(accountStatus);
  };

  function isPaypalEnabled(): boolean {
    return paypalStatus ? PaypalUtils.isPaypalEnabled(paypalStatus) : false;
  }

  function isPaypalEligible(): boolean {
    return paypalStatus ? PaypalUtils.isPaypalEligible(paypalStatus) : false;
  }

  function isPaypalInitial(): boolean {
    return paypalStatus ? PaypalUtils.isPaypalInitial(paypalStatus) : false;
  }

  function isPaypalPending(): boolean {
    return paypalStatus ? PaypalUtils.isPaypalPending(paypalStatus) : false;
  }

  function isPaypalOnboarded(): boolean {
    return paypalStatus ? PaypalUtils.isPaypalOnboarded(paypalStatus) : false;
  }

  function isPaypalAccepting(): boolean {
    return paypalStatus ? PaypalUtils.isPaypalAccepting(paypalStatus) : false;
  }

  function isPaypalClosed(): boolean {
    return paypalStatus ? PaypalUtils.isPaypalClosed(paypalStatus) : false;
  }

  function isPaypalOnboardingCompleted(): boolean {
    return paypalStatus ? PaypalUtils.isPaypalOnboardingCompleted(paypalStatus) : false;
  }

  function getPaypalAccountEmail(): string | null {
    if (!paypalStatus) return null;
    return paypalStatus.paypal?.paypalPrimaryEmail ? paypalStatus.paypal?.paypalPrimaryEmail : null;
  }

  function getPaypalMerchantId(): string | null {
    if (!paypalStatus) return null;
    return paypalStatus.paypal?.paypalMerchantId || null;
  }

  function getPaypalAccountCurrency(): string | null {
    if (!paypalStatus) return null;
    return paypalStatus.paypal?.paypalPrimaryCurrency || null;
  }

  function getPaypalFees(): PaypalUtils.PaypalFees | null {
    if (!paypalStatus) return null;
    return paypalStatus.paypal?.['paypalFees'] || null;
  }

  function isEligibleForPPCPUpgrade(): boolean {
    return paypalStatus ? !!paypalStatus.paypal?.['isPPCPUpgradeEligible'] : false;
  }

  function isPrimaryEmailConfirmed(): boolean {
    return paypalStatus ? !!paypalStatus.paypal?.['isPrimaryEmailConfirmed'] : false;
  }

  return (
    <PaypalAccountContext.Provider
      value={{
        paypalStatus,
        updatePaypalStatus,
        updatePaypalEnabled,
        isPaypalEnabled,
        isPaypalEligible,
        isPaypalInitial,
        isPaypalPending,
        isPaypalOnboarded,
        isPaypalAccepting,
        isPaypalClosed,
        getPaypalAccountEmail,
        getPaypalMerchantId,
        getPaypalAccountCurrency,
        getPaypalFees,
        isPaypalOnboardingCompleted,
        isEligibleForPPCPUpgrade,
        isPrimaryEmailConfirmed,
        isPaypalModalOpen: isOpen,
        showPaypalModal: showModal,
        hidePaypalModal: hideModal
      }}>
      {isOpen && <PaypalModal closeModal={hideModal} origin={origin} />}
      {children}
    </PaypalAccountContext.Provider>
  );
};

export const usePaypalContext = (): PaypalAccountContext | null => useContext(PaypalAccountContext);
