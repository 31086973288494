import React from 'react';

import { messages } from 'src/components/SubscriptionPaywall/utils';
import { useISIntl } from 'src/i18n';

export const PopularHeader = () => {
  const { ft } = useISIntl();

  return (
    <div className="bg-orange-200 rounded-t-[30px] py-3">
      <p className="text-center text-2xl font-bold text-gray-800 capitalize">
        {ft(messages.mostPopular)}
      </p>
    </div>
  );
};
