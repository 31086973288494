import React from 'react';
import { observer } from 'mobx-react';
import { compose } from 'src/util/functions';
import { injectIntl, defineMessages } from 'src/i18n';
import { FormattedMessage } from 'react-intl';
import './Content.css';

const docUrl = 'https://www.invoicesimple.com/estimate-template';
const contactUrl = 'https://www.invoicesimple.com/contact';

const messages = defineMessages({
  header: {
    id: 'seo.estimate.header',
    defaultMessage: 'Estimate Maker'
  },

  // feature
  featureHeader: {
    id: 'seo.estimate.feature.header',
    defaultMessage: 'Benefits of using an estimate maker'
  },
  featureBody0: {
    id: 'seo.estimate.feature.body',
    defaultMessage:
      "Instead of working hard, we should work smart! The smart thing is to use the right tool for the job, an estimate maker! When you use Invoice Simple's estimate maker you save yourself a lot of time and effort. Here are some of the reasons why:"
  },
  featureBody1: {
    id: 'seo.invoice.feature.2.body.1',
    defaultMessage:
      'See our guide to <link>invoicing software for small businesses</link> for more detail on all your options.',
    values: {
      link: (chunks) => (
        <a href="https://www.invoicesimple.com/invoice-generator/small-business-invoicing-software">
          {chunks}
        </a>
      )
    }
  },
  featureItem0: {
    id: 'seo.estimate.feature.item.0',
    defaultMessage:
      "The app saves your clients and items, having them ready for use the next time you're making an estimate"
  },
  featureItem1: {
    id: 'seo.estimate.feature.item.1',
    defaultMessage: 'You can easily change estimates to invoices with a single click'
  },
  featureItem2: {
    id: 'seo.estimate.feature.item.2',
    defaultMessage: 'Organize your estimates without needing to put in any additional time in'
  },
  featureItem3: {
    id: 'seo.estimate.feature.item.3',
    defaultMessage:
      "Use a professionally designed estimate template that's compatible with printers and mobile devices"
  },
  featureItem4: {
    id: 'seo.estimate.feature.item.4',
    defaultMessage: 'See an overview of your business at a glance, without any effort'
  },
  featureItem5: {
    id: 'seo.estimate.feature.item.5',
    defaultMessage: 'Know when customers receive your estimates'
  },
  featureItem6: {
    id: 'seo.estimate.feature.item.6',
    defaultMessage: 'Get notified when customers view your estimate'
  },
  featureItem7: {
    id: 'seo.estimate.feature.item.7',
    defaultMessage:
      'Present a professional demeanour by showing that you use the right tool for each job'
  },
  featureItemLink: {
    id: 'seo.estimate.feature.item.link',
    defaultMessage: 'and <link>many more amazing benefits</link>',
    values: {
      link: (chunks) => (
        <a href="https://www.invoicesimple.com/features-invoicing-billing-tools">{chunks}</a>
      )
    }
  },

  // special
  specialQuestion: {
    id: 'seo.estimate.special.question',
    defaultMessage: "What's special about our free estimate template?"
  },
  specialAnswer: {
    id: 'seo.estimate.special.answer',
    defaultMessage:
      "Firstly, our estimate template has been designed and tweaked by expert user experience designers and graphic designers. People who are paid to make products easy to use and beautiful. This means your estimates will look great on mobile, desktop and paper. We've worked on making sure that estimates are always easy to read. This is done by removing any section that's not filled in from the final estimate PDF, as well as keeping the colorized elements minimal."
  },

  // alt
  alternativeHeader: {
    id: 'seo.estimate.alternative.header',
    defaultMessage: 'Alternatives to using an estimate maker'
  },
  alternativeBody: {
    id: 'seo.estimate.alternative.body',
    defaultMessage:
      "You may have heard about using a simple excel or word <link>estimate template</link> to make your receipts. Using those applications is fine at the start but when you get to making your second or third estimate you'll realize that you're wasting a lot of your time on repetitive tasks. For example:",
    values: {
      link: (chunks) => <a href={docUrl}>{chunks}</a>
    }
  },
  alternativeItem0: {
    id: 'seo.estimate.alternative.item.0',
    defaultMessage:
      'You need to duplicate and rename your original file every time you want to make a new estimate.'
  },
  alternativeItem1: {
    id: 'seo.estimate.alternative.item.1',
    defaultMessage:
      "There will be moments of intense frustration! Since Word and Excel aren't made for designing these sorts of documents and they will refuse to do what you want them to do."
  },
  alternativeItem2: {
    id: 'seo.estimate.alternative.item.2',
    defaultMessage:
      "You end up re-typing client and item details even though you've sent them an estimate before."
  },
  alternativeItem3: {
    id: 'seo.estimate.alternative.item.3',
    defaultMessage: "It's a hassle to organize and find old estimates."
  },
  alternativeItem4: {
    id: 'seo.estimate.alternative.item.4',
    defaultMessage:
      "If you want to know how much you've billed over a certain period you'll have to open up every single file and manually add up the totals."
  },
  alternativeItem5: {
    id: 'seo.estimate.alternative.item.5',
    defaultMessage:
      'Turning an estimate into an invoice requires several additional steps (duplication of file, renaming titles, updating dates...)'
  },
  alternativeItem6: {
    id: 'seo.estimate.alternative.item.6',
    defaultMessage:
      "This is time you spend doing administrative work. Time that you're not being paid by anyone to do. My guess is you didn't start your own business to do administrative work."
  },

  // template
  templateQuestion: {
    id: 'seo.estimate.template.question',
    defaultMessage: 'More estimate templates'
  },
  templateAnswer: {
    id: 'seo.estimate.template.answer',
    defaultMessage:
      "If at the end of the day you still feel like the estimate maker isn't for you, you can always fall back onto traditional methods. We've created a selection of commonly used <docLink>free estimate templates</docLink> to help with that. Any questions or comments? Feel free to <contactLink>reach out to us</contactLink>.",
    values: {
      docLink: (chunks) => <a href={docUrl}>{chunks}</a>,
      contactLink: (chunks) => <a href={contactUrl}>{chunks}</a>
    }
  },

  // faq
  faqHeader: {
    id: 'seo.estimate.faq.header',
    defaultMessage: 'Frequently asked questions'
  },
  faqItem0Q: {
    id: 'seo.estimate.faq.question.0',
    defaultMessage: 'Can I put my own logo on the estimate template?'
  },
  faqItem0A: {
    id: 'seo.estimate.faq.answer.0',
    defaultMessage:
      'Yes. Click on the logo box in the top right corner. From there, you can either upload the logo for your estimate template, or simply drag and drop it into place. JPEG and PNG images are supported.'
  },
  faqItem1Q: {
    id: 'seo.estimate.faq.question.1',
    defaultMessage: 'How do I send an estimate?'
  },
  faqItem1A: {
    id: 'seo.estimate.faq.answer.1',
    defaultMessage:
      'You can email your estimate, download a PDF copy, or send your estimate as a link, all using our estimate maker. If you email your estimate directly from Invoice Simple, we’ll notify you when your customer views the estimate — useful for following up with them later. If you send a link to your customer, they can download or print the estimate from the link.'
  },
  faqItem2Q: {
    id: 'seo.estimate.faq.question.2',
    defaultMessage: "Will my clients see 'Invoice Simple' on my estimate?"
  },
  faqItem2A: {
    id: 'seo.estimate.faq.answer.2',
    defaultMessage:
      'Never. Regardless of whether you have a registered account or not, your generated estimates are always 100% unbranded.'
  },
  faqItem3Q: {
    id: 'seo.estimate.faq.question.3',
    defaultMessage: 'Can I generate a PDF estimate?'
  },
  faqItem3A: {
    id: 'seo.estimate.faq.answer.3',
    defaultMessage:
      'Absolutely. The PDF button at the top of the estimate lets you instantly download a PDF estimate that you can save for your records or send to your customer.'
  },
  faqItem4Q: {
    id: 'seo.estimate.faq.question.4',
    defaultMessage: 'Can I change the currency?'
  },
  faqItem4A: {
    id: 'seo.estimate.faq.answer.4',
    defaultMessage:
      'Yes. Our estimate maker should detect your currency automatically. However, if you want to make an estimate in a different currency, you can easily change currencies using the Settings panel on the right.'
  },
  faqItem5Q: {
    id: 'seo.estimate.faq.question.5',
    defaultMessage: 'Can I save my estimates and customer details?'
  },
  faqItem5A: {
    id: 'seo.estimate.faq.answer.5',
    defaultMessage:
      'Yes. Your estimates are automatically saved to your estimates list. There is also a clients list where customer information is automatically saved to.'
  },
  faqItem6Q: {
    id: 'seo.estimate.faq.question.6',
    defaultMessage: 'As a freelancer, how do I make an estimate for time?'
  },
  faqItem6A: {
    id: 'seo.estimate.faq.answer.6',
    defaultMessage:
      'What works well is to put your hours into the quantity field and your hourly rate into the price field. The estimate maker will then calculate the amount for you.'
  },
  faqItem8Q: {
    id: 'seo.estimate.faq.question.8',
    defaultMessage: 'How do I charge taxes using the estimate maker?'
  },
  faqItem8A: {
    id: 'seo.estimate.faq.answer.8',
    defaultMessage:
      "If you'd like your estimate to include taxes on top of your prices, use the Settings pane on the right to enable taxes. You can also enter a tax rate and label."
  },
  faqItem9Q: {
    id: 'seo.estimate.faq.question.9',
    defaultMessage: 'What about line items that already have tax applied?'
  },
  faqItem9A: {
    id: 'seo.estimate.faq.answer.9',
    defaultMessage:
      "If your prices already include tax, and you'd like your estimate to show the amount of tax included, then select the Inclusive? checkbox when enabling taxes."
  }
});

const EstimateSeoContent = () => {
  const featureList = [0, 1, 2, 3, 4, 5, 6, 7].map((i) => {
    const item = messages[`featureItem${i}`];
    return (
      <li key={`feature-${i}`}>
        <FormattedMessage {...item} />
      </li>
    );
  });

  const alternativeList = [0, 1, 2, 3, 4, 5, 6].map((i) => {
    const item = messages[`alternativeItem${i}`];
    return (
      <li key={`alternative-${i}`}>
        <FormattedMessage {...item} />
      </li>
    );
  });

  const faqList = [0, 1, 2, 3, 4, 5, 6, 8, 9].map((i) => {
    const question = messages[`faqItem${i}Q`];
    const answer = messages[`faqItem${i}A`];
    return (
      <li key={`faq-${i}`}>
        <h4>
          <FormattedMessage {...question} />
        </h4>
        <p>
          <FormattedMessage {...answer} />
        </p>
      </li>
    );
  });

  return (
    <div id="seo-content" className="d-print-none seo-content">
      <div className="container d-print-none">
        <div className="col-md">
          <div>
            <h1 id="seoTitle">
              <FormattedMessage {...messages.header} />
            </h1>
            {/* features */}

            <section id="estimate-maker-features">
              <h2>
                <FormattedMessage {...messages.featureHeader} />
              </h2>
              <p>
                <FormattedMessage {...messages.featureBody0} />
              </p>
              <ul>
                {featureList}
                <li>
                  <FormattedMessage {...messages.featureItemLink} />
                </li>
              </ul>
            </section>

            {/* special */}
            <section id="estimate-maker-special">
              <h2>
                <FormattedMessage {...messages.specialQuestion} />
              </h2>
              <p>
                <FormattedMessage {...messages.specialAnswer} />
              </p>
            </section>

            {/* alternatives */}
            <section id="estimate-maker-alternatives">
              <h2>
                <FormattedMessage {...messages.alternativeHeader} />
              </h2>
              <p>
                <FormattedMessage {...messages.alternativeBody} />
              </p>
              <ul>{alternativeList}</ul>
            </section>

            {/* templates */}
            <section id="estimate-maker-templates">
              <h2>
                <FormattedMessage {...messages.templateQuestion} />
              </h2>
              <p>
                <FormattedMessage {...messages.templateAnswer} />
              </p>
              <p>
                <FormattedMessage {...messages.featureBody1} />
              </p>
            </section>

            {/* faq */}
            <section id="estimate-maker-faq">
              <h2>
                <FormattedMessage {...messages.faqHeader} />
              </h2>
              <ul className="list-columns">{faqList}</ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(injectIntl, observer)(EstimateSeoContent);
