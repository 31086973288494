import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles/PaymentSetupButton.css';

interface PaymentSetupButtonProps {
  className?: string;
  buttonText: string | React.JSX.Element;
  buttonBackgroundColor: 'orange' | 'grey' | 'linear-gradient' | 'white';
  loading: boolean;
  onButtonClick: () => void;
  buttonTextColor?: string;
  dataTestId?: string;
}

export function PaymentSetupButton({
  className,
  buttonText,
  buttonBackgroundColor,
  loading,
  onButtonClick,
  buttonTextColor = 'text-white',
  dataTestId = 'payment-setup-button'
}: PaymentSetupButtonProps): JSX.Element {
  const buttonTextStyle: React.CSSProperties = { visibility: loading ? 'hidden' : 'visible' };
  const buttonSpinnerStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

  return (
    <button
      type="button"
      data-testid={dataTestId}
      className={`btn btn-payment-setup btn-${buttonBackgroundColor} ${className} ${buttonTextColor} w-100 border`}
      style={{ position: 'relative' }}
      disabled={loading}
      onClick={(e) => {
        e.preventDefault();
        onButtonClick();
      }}>
      <span style={buttonTextStyle}>{buttonText}</span>
      {loading && (
        <span className="spinner" data-testid="spinner" style={buttonSpinnerStyle}>
          <FontAwesomeIcon icon="spinner" size="lg" color="white" spin />
        </span>
      )}
    </button>
  );
}
