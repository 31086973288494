import identity from 'lodash/identity';
import { observable, computed, action } from 'mobx';
import { fromPromise, IPromiseBasedObservable, PENDING, FULFILLED, REJECTED } from 'mobx-utils';
import Parse from 'parse';
import { syncTransactions } from 'src/models/syncTransactions';
import { ParseObject } from 'src/types/ParseObject';
import UserModel from './UserModel';

interface SyncableAPI {
  getById: (id: string) => Promise<Parse.Object>;
}

export default abstract class SyncableEntity {
  EntityType: typeof Parse.Object;
  apiController: SyncableAPI | null;
  typeHint: string;
  user: UserModel | undefined;

  createRequest?: Promise<Parse.Object>;

  abstract parseData: any;

  @observable id: string;
  @observable delete: boolean = false; // pending delete
  @observable deleted: boolean = false; // confirmed delete

  @observable loadTransaction?: IPromiseBasedObservable<Parse.Object>;
  @observable private saveTransactionId?: string = undefined;
  protected onCreate(): void {}

  constructor(
    EntityType: typeof Parse.Object,
    apiController: SyncableAPI | null,
    typeHint: string
  ) {
    this.EntityType = EntityType;
    this.apiController = apiController;
    this.typeHint = typeHint;
  }

  @computed
  get isSaving() {
    return !!(this.saveTransactionId && syncTransactions[this.saveTransactionId].state === PENDING);
  }

  @computed
  get isLoading() {
    return !!(this.loadTransaction && this.loadTransaction.state === PENDING);
  }

  @computed
  get isLoaded() {
    return !!(
      this.loadTransaction &&
      (this.loadTransaction.state === FULFILLED || this.loadTransaction.state === REJECTED)
    );
  }

  @computed
  get isNew(): boolean {
    return !(typeof this.id === 'string' && this.id !== '');
  }

  public async save(): Promise<Parse.Object> {
    try {
      const { syncEntity } = require('src/controllers/syncController'); // eslint-disable-line

      if (this.createRequest) {
        await this.createRequest;
      }

      const { resultPromise, transactionId } = syncEntity({
        entity: new this.EntityType(this.parseData)
      });

      if (this.isNew) {
        this.createRequest = resultPromise;
      }

      this.saveTransactionId = transactionId;
      const saved = await resultPromise;
      this.onCreate();
      this.id = saved.id;
      return saved;
    } catch (error) {
      /*
      inline "require" is needed to avoid circular dependecies
      and undefined modules errors.
      Can be removed as soon as analytics and errors handling 
      functionality is extracted from UserModule 
      */

      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const UserModel = require('src/models/UserModel').default;
      UserModel.getInstance().handleError(`${this.typeHint}-save`, error);
      throw error;
    }
  }

  public async load(
    {
      apiMethod = 'getById',
      transformForUpdate = identity
    }: {
      apiMethod: string;
      transformForUpdate: (parseObj: Parse.Object<Parse.Attributes>) => any;
    } = {
      apiMethod: 'getById',
      transformForUpdate: identity
    }
  ): Promise<any> {
    try {
      if (this.apiController === null) {
        return;
      }
      this.loadTransaction = fromPromise(this.apiController[apiMethod](this.id));
      const results = await this.loadTransaction;
      this.update(transformForUpdate(results));
      return results;
    } catch (error) {
      /*
      inline "require" is needed to avoid circular dependecies
      and undefined modules errors.
      Can be removed as soon as analytics and errors handling 
      functionality is extracted from UserModule 
      */

      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const UserModel = require('src/models/UserModel').default;
      UserModel.getInstance().handleError(`${this.typeHint}-load`, error);
      throw error;
    }
  }

  @action
  public toggleDelete(): void {
    this.delete = !this.delete;
  }

  @action
  public toggleDeleted(): void {
    if (this.delete) {
      this.delete = false;
    }
    this.deleted = !this.deleted;
  }

  protected abstract update(data: ParseObject | any): void;
}
