import ErrorListModel from 'src/models/ErrorListModel';
import { Photo } from 'src/util/IsParseDomain';
import Parse from 'parse';
import isPromise from 'src/util/IsPromise';
import axios from 'axios';
import { v1 as uuid } from 'uuid';
import fileExt from '../util/FileExt';
import { ImageFile } from 'src/types/ImageFile';
import { ApiRequestOptions } from 'src/types/ApiRequestOptions';

export const getPhotos = async (photoRemoteIds: string[]) => {
  try {
    const query = new Parse.Query(Photo)
      .containedIn('remoteId', photoRemoteIds)
      .select('url', 'remoteId', 'md5')
      .descending('createdAt');

    const data = await isPromise<Parse.Object[]>(query.find());
    return data;
  } catch (error) {
    ErrorListModel.pushError(error);
    throw error;
  }
};

export const getPhoto = async (photoRemoteId: string) => {
  try {
    const photo = await new Parse.Query(Photo)
      .equalTo('remoteId', photoRemoteId)
      .equalTo('deleted', false)
      .select('url', 'remoteId', 'md5')
      .first();
    return photo;
  } catch (error) {
    ErrorListModel.pushError(error);
    throw error;
  }
};

export const updatePhoto = async (photoRemoteId: string, fields: { url: string; md5: string }) => {
  try {
    const photo = await new Parse.Query(Photo).equalTo('remoteId', photoRemoteId).first();
    photo?.set({ ...fields, updated: Date.now() });
    return photo?.save();
  } catch (error) {
    ErrorListModel.pushError(error);
    throw error;
  }
};

export const deletePhoto = async (photoRemoteId: string) => {
  try {
    const photo = await new Parse.Query(Photo).equalTo('remoteId', photoRemoteId).first();
    photo?.set({ deleted: true, updated: Date.now() });
    return photo?.save();
  } catch (error) {
    ErrorListModel.pushError(error);
    throw error;
  }
};

const getPresignedUrl = async ({
  accountId,
  filename,
  apiReqOpts
}: {
  accountId: string;
  filename: string;
  apiReqOpts: ApiRequestOptions;
}) => {
  const remoteId = uuid();
  const url = '/api/v2/request-upload-photo';
  const data = {
    photoId: remoteId,
    accountId: accountId,
    extension: fileExt(filename)
  };

  // Requests our endpoint to get signed s3 upload url
  return axios.post(url, data, apiReqOpts);
};

const uploadToS3 = async ({ presignedUrl, file }: { presignedUrl: string; file: ImageFile }) => {
  const opts = { headers: { 'Content-Type': file.type } };
  return axios.put(presignedUrl, file, opts);
};

export const uploadPhoto = async ({
  accountId,
  file,
  apiReqOpts
}: {
  accountId: string;
  file: ImageFile;
  apiReqOpts: ApiRequestOptions;
}) => {
  const urlRes = await getPresignedUrl({
    accountId,
    filename: file.name,
    apiReqOpts
  });

  const uploadRes = await uploadToS3({
    presignedUrl: urlRes.data.signedRequest,
    file
  });

  return { uploadRes, urlRes };
};
