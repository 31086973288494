import React from 'react';
import { observer } from 'mobx-react';
import type InvoiceModel from 'src/models/InvoiceModel';
import { InvoicePaymentSetting } from 'src/payments';

const InvoicePaymentSettingObserver = observer(InvoicePaymentSetting);

type InvoicePaymentSettingObserverProps = React.ComponentProps<
  typeof InvoicePaymentSettingObserver
>;

type Props = {
  invoice: InvoiceModel;
} & Pick<InvoicePaymentSettingObserverProps, 'title' | 'onTrackEvent'>;

export const InvoicePaymentToggle = observer(({ invoice, title, onTrackEvent }: Props) => (
  <InvoicePaymentSettingObserver
    title={title}
    document={{
      docType: invoice.docType,
      setting: { paymentSuppressed: invoice.paymentSuppressed }
    }}
    onTogglePayments={(value) => {
      invoice.setPaymentSuppressed(value);
    }}
    currency={invoice.currencyCode}
    dictionary={{
      acceptPayments: 'Accept Online Payments',
      currencyMismatch:
        "Account's currency does not match your PayPal currency, unable to accept payments."
    }}
    onTrackEvent={onTrackEvent}
  />
));
