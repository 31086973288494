import { pubsub, EVENTS } from 'src/util/pubsub';

import { AxiosResponse } from 'axios';
import axios from 'axios';
import EnvironmentStore from 'src/stores/EnvironmentStore';

export async function startConversation(userId: string, message: string) {
  try {
    await axios.post<any, AxiosResponse<void>>(
      `${EnvironmentStore.appUrl}/intercom/start-conversation`,
      {
        userId,
        message
      }
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const mayBeRun = (...args) => {
  if (!window.intercomLoaded) {
    return;
  }
  window.intercomLoaded.then(() => {
    if ('Intercom' in window) {
      return window.Intercom.call(window.Intercom, ...args);
    }
  });
};

pubsub.once(EVENTS.CONNECTIVITY_ERROR, () => mayBeRun('show'));

export default mayBeRun;
