import UserModel from 'src/models/UserModel';

import { ConsentStatus, ConsentType } from '@invoice-simple/common';

import { API_V3_BASE_PATH, client } from './httpClient';

const CONSENT_ENDPOINT = `${API_V3_BASE_PATH}/account-consent`;

export interface SaveConsentAccountInput {
  user: UserModel;
  data: SaveConsentAccountData[];
}

export interface SaveConsentAccountData {
  consentType: ConsentType;
  consentStatus: ConsentStatus;
  consentValue: string;
  consentMessage: string;
}

export const saveConsentData = async (input: SaveConsentAccountInput): Promise<void> => {
  const { user, data } = input;
  const { headers } = user.getApiV3ReqOpts();
  await client.post(`${CONSENT_ENDPOINT}/save`, data, { headers });
};

export interface GetConsentAccountInput {
  consentType: ConsentType;
  consentValue?: string;
  user: UserModel;
}

interface GetConsentAccountOutput {
  consent?: ConsentStatus;
}

export const getConsentData = async ({
  consentType,
  consentValue,
  user
}: GetConsentAccountInput): Promise<GetConsentAccountOutput> => {
  const { headers } = user.getApiV3ReqOpts();
  const url = `${CONSENT_ENDPOINT}/${consentType}${consentValue ? `/${consentValue}` : ''}`;
  const result = await client.get(url, { headers });
  return result.data;
};

export const isConsentGiven = async ({
  consentType,
  consentValue,
  user
}: GetConsentAccountInput): Promise<boolean> => {
  try {
    const result = await getConsentData({ consentType, consentValue, user });
    return !!result && result.consent === ConsentStatus.CONSENT_GIVEN;
  } catch (error) {
    return false;
  }
};

export const isConsentGivenOrImplicit = async ({
  consentType,
  consentValue,
  user
}: GetConsentAccountInput): Promise<boolean> => {
  try {
    const result = await getConsentData({ consentType, consentValue, user });
    return (
      !!result &&
      (result.consent === ConsentStatus.CONSENT_GIVEN ||
        result.consent === ConsentStatus.CONSENT_IMPLICIT)
    );
  } catch (error) {
    return false;
  }
};
