import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  text: string;
  variant: 'blue' | 'green';
  inline?: boolean;
}

const colorMap = {
  blue: {
    backgroundColor: 'rgba(69, 94, 222, 0.1)',
    color: 'rgba(69, 94, 222, 1)'
  },
  green: {
    backgroundColor: '#1557241A',
    color: '#155724'
  }
};

export const SubscriptionBadge = ({ text, variant, inline }: Props) => {
  const { backgroundColor, color } = colorMap[variant];

  const baseContainer = 'w-fit rounded-full py-1 px-4 uppercase block mt-1';
  const inlineContainer = inline ? 'inline-block mt-0' : '';
  const containerStyles = twMerge(baseContainer, inlineContainer);

  return (
    <div
      data-testid="subscription-discount-badge"
      style={{
        backgroundColor,
        color,
        fontWeight: 700,
        lineHeight: '22px',
        fontSize: 12,
        cursor: 'default',
        fontFamily:
          'Roboto, system-ui, -apple-system, Segoe UI, Ubuntu, Cantarell, Noto Sans, sans-serif'
      }}
      className={containerStyles}>
      {text}
    </div>
  );
};
