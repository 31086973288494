import { CountrySettingOptions } from 'src/types/CountrySettings';

// SKU's are plan identifiers within chargebee.
// The SKU's are grouped by USD - for example grouped by $3 USD:
// com.invoicesimple.web.monthly.3.usd = 2.99
// com.invoicesimple.web.monthly.3.aud = 3.99
// com.invoicesimple.web.monthly.3.cad = 3.49

// legacy subscribers are using the { price } key
// new subscribers      subscription.(monthly | annual)[0]
// abtest segment       subscription.(monthly | annual)[x]

const euroPrices = {
  subscription: {
    price: [250, 85], // com.invoicesimple.web.monthly.3.eur.1.coupon
    monthly: [250],
    annual: [2500],
    tierPrice: {
      monthly: {
        starter: 449,
        pro: 799,
        premium: 1249,
        essentials: 799,
        plus: 1499,
        newPremium: 2299,
        premiumLegacy: 2299,
        paymentsTier: 0
      },
      annual: {
        starter: 4149,
        pro: 8099,
        premium: 10849,
        essentials: 7999,
        plus: 14999,
        newPremium: 22999,
        premiumLegacy: 22999,
        paymentsTier: 0
      }
    },
    currencyCode: 'EUR'
  }
};

const countrySettingOptions: CountrySettingOptions = {
  US: {
    subscription: {
      price: [299, 99], // com.invoicesimple.web.monthly.3.usd.1.coupon
      monthly: [299],
      annual: [2999],
      tierPrice: {
        monthly: {
          starter: 499,
          pro: 849,
          premium: 1349,
          paymentsTier: 0,
          lowEssentials: 499,
          essentials: 499,
          plus: 1349,
          newPremium: 1999,
          premiumLegacy: 1999
        },
        annual: {
          starter: 4499,
          pro: 8799,
          premium: 11799,
          paymentsTier: 0,
          lowEssentials: 4999,
          essentials: 4999,
          plus: 13499,
          newPremium: 19999,
          premiumLegacy: 19999
        }
      },
      currencyCode: 'USD'
    }
  },
  AU: {
    subscription: {
      price: [399, 135], // com.invoicesimple.web.monthly.3.aud.1.coupon
      monthly: [399],
      annual: [3999],
      tierPrice: {
        monthly: {
          starter: 749,
          pro: 1299,
          premium: 1999,
          essentials: 999,
          plus: 1999,
          newPremium: 2999,
          premiumLegacy: 2999,
          paymentsTier: 0
        },
        annual: {
          starter: 6699,
          pro: 13099,
          premium: 17599,
          essentials: 9999,
          plus: 19999,
          newPremium: 29999,
          premiumLegacy: 29999,
          paymentsTier: 0
        }
      },
      localizedTierPrice: {
        monthly: {
          essentials: 999,
          plus: 1999,
          premium: 2999,
          premiumLegacy: 2999
        },
        annual: {
          essentials: 9999,
          plus: 19999,
          premium: 29999,
          premiumLegacy: 28999
        }
      },
      currencyCode: 'AUD'
    }
  },
  CA: {
    subscription: {
      price: [349, 125], // com.invoicesimple.web.monthly.3.cad.1.coupon
      monthly: [349],
      annual: [3499],
      tierPrice: {
        monthly: {
          starter: 699,
          pro: 1149,
          premium: 1849,
          essentials: 999,
          plus: 1999,
          newPremium: 2499,
          premiumLegacy: 2499,
          paymentsTier: 0
        },
        annual: {
          starter: 6049,
          pro: 11849,
          premium: 15899,
          essentials: 9999,
          plus: 19999,
          newPremium: 24999,
          premiumLegacy: 24999,
          paymentsTier: 0
        }
      },
      localizedTierPrice: {
        monthly: {
          essentials: 749,
          plus: 1499,
          premium: 2599,
          premiumLegacy: 2599
        },
        annual: {
          essentials: 7490,
          plus: 14990,
          premium: 25990,
          premiumLegacy: 25990
        }
      },
      currencyCode: 'CAD'
    }
  },
  GB: {
    subscription: {
      price: [199, 75], // com.invoicesimple.web.monthly.3.gbp.1.coupon
      monthly: [199],
      annual: [1999],
      tierPrice: {
        monthly: {
          starter: 349,
          pro: 699,
          premium: 1099,
          essentials: 699,
          plus: 1299,
          newPremium: 1999,
          premiumLegacy: 1999,
          paymentsTier: 0
        },
        annual: {
          starter: 3599,
          pro: 7049,
          premium: 7999,
          essentials: 6999,
          plus: 12999,
          newPremium: 19999,
          premiumLegacy: 19999,
          paymentsTier: 0
        }
      },
      localizedTierPrice: {
        monthly: {
          essentials: 449,
          plus: 999,
          premium: 1499,
          premiumLegacy: 1499
        },
        annual: {
          essentials: 4490,
          plus: 9990,
          premium: 14990,
          premiumLegacy: 14990
        }
      },
      currencyCode: 'GBP'
    }
  },
  IN: {
    subscription: {
      price: [],
      monthly: [],
      annual: [],
      tierPrice: {
        monthly: {
          starter: 0,
          pro: 0,
          premium: 89900,
          essentials: 21000,
          plus: 41900,
          newPremium: 89900,
          premiumLegacy: 89900,
          paymentsTier: 0
        },
        annual: {
          starter: 0,
          pro: 0,
          premium: 899000,
          essentials: 210000,
          plus: 419000,
          newPremium: 899000,
          premiumLegacy: 899000,
          paymentsTier: 0
        }
      },
      localizedTierPrice: {
        monthly: {
          essentials: 69900,
          plus: 129900,
          premium: 199900,
          premiumLegacy: 199900
        },
        annual: {
          essentials: 690000,
          plus: 1290000,
          premium: 1990000,
          premiumLegacy: 1990000
        }
      },
      currencyCode: 'INR'
    }
  },
  ZA: {
    subscription: {
      price: [4349, 1449], // com.invoicesimple.web.monthly.3.zar.1.coupon
      monthly: [4349],
      annual: [43499],
      tierPrice: {
        monthly: {
          starter: 9499,
          pro: 15999,
          premium: 25599,
          essentials: 14999,
          plus: 29999,
          newPremium: 39999,
          premiumLegacy: 39999,
          paymentsTier: 0
        },
        annual: {
          starter: 85499,
          pro: 167300,
          premium: 224400,
          essentials: 149999,
          plus: 299999,
          newPremium: 399999,
          premiumLegacy: 399999,
          paymentsTier: 0
        }
      },
      currencyCode: 'ZAR'
    }
  },
  NZ: {
    subscription: {
      price: [429, 145], // com.invoicesimple.web.monthly.3.nzd.1.coupon
      monthly: [429],
      annual: [4299],
      tierPrice: {
        monthly: {
          starter: 799,
          pro: 1349,
          premium: 2149,
          essentials: 1299,
          plus: 2499,
          newPremium: 3999,
          premiumLegacy: 3999,
          paymentsTier: 0
        },
        annual: {
          starter: 7199,
          pro: 14099,
          premium: 18899,
          essentials: 12999,
          plus: 24999,
          newPremium: 39999,
          premiumLegacy: 39999,
          paymentsTier: 0
        }
      },
      currencyCode: 'NZD'
    }
  },
  NG: {
    subscription: {
      price: [122499, 40849], // com.invoicesimple.web.monthly.3.ngn.1.coupon
      monthly: [122499],
      annual: [1224999],
      tierPrice: {
        monthly: {
          starter: 230000,
          pro: 390000,
          premium: 620000,
          essentials: 349999,
          plus: 690000,
          newPremium: 990000,
          premiumLegacy: 990000,
          paymentsTier: 0
        },
        annual: {
          starter: 2070000,
          pro: 4065000,
          premium: 5450000,
          essentials: 3490000,
          plus: 6990000,
          newPremium: 9990000,
          premiumLegacy: 9990000,
          paymentsTier: 0
        }
      },
      currencyCode: 'NGN'
    }
  },
  MY: {
    subscription: {
      price: [1249, 415], // com.invoicesimple.web.monthly.3.myr.1.coupon
      monthly: [1249],
      annual: [12499],
      tierPrice: {
        monthly: {
          starter: 2199,
          pro: 3799,
          premium: 5999,
          essentials: 2999,
          plus: 5999,
          newPremium: 9999,
          premiumLegacy: 9999,
          paymentsTier: 0
        },
        annual: {
          starter: 19999,
          pro: 39499,
          premium: 52999,
          essentials: 29999,
          plus: 59999,
          newPremium: 99999,
          premiumLegacy: 99999,
          paymentsTier: 0
        }
      },
      currencyCode: 'MYR'
    }
  },
  FR: { ...euroPrices },
  ES: { ...euroPrices }
};

export default countrySettingOptions;
