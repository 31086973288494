import { observable, computed, action } from 'mobx';
import InvoiceModel from './InvoiceModel';
import { isValidEmail } from '../util/isValidEmail';
import { sendInvoiceEmail, InvoiceEmailData } from '../apis/emailAPI';
import { SETTING_LABELS } from 'src/data/settingsDisplayTypes';

export default class InvoiceEmailModel {
  @observable invoice: InvoiceModel;
  @observable isSending: boolean = false;
  @observable toEmail: string = '';
  @observable fromEmail: string = '';
  @observable message: string = '';
  @observable disableBranding: boolean = true;

  constructor(invoice: InvoiceModel) {
    this.invoice = invoice;
    this.message =
      invoice.user.settingList.getSetting(SETTING_LABELS.DEFAULT_EMAIL_MESSAGE)?.valStr || '';
  }

  @computed
  get sendData(): any {
    return {
      toEmail: this.toEmail,
      fromEmail: this.fromEmail,
      msgBody: this.message,
      no_branding: this.disableBranding,
      color: this.invoice.color,
      accountId: this.invoice.accountId,
      invoiceId: this.invoice.id,
      invoiceNo: this.invoice.invoiceNo,
      invoiceUrl: this.invoice.publicUrl
    };
  }
  @computed
  get hasToEmail(): boolean {
    return !!this.toEmail && this.toEmail.length > 0;
  }

  @computed
  get hasFromEmail(): boolean {
    return !!this.fromEmail && this.fromEmail.length > 0;
  }

  @computed
  get isToEmailValid(): boolean {
    return isValidEmail(this.toEmail);
  }

  @computed
  get isFromEmailValid(): boolean {
    return isValidEmail(this.fromEmail);
  }

  @computed
  get isValid(): boolean {
    return this.isToEmailValid && this.isFromEmailValid;
  }

  @action
  setToEmail(value: string): void {
    this.toEmail = value;
  }

  @action
  setFromEmail(value: string): void {
    this.fromEmail = value;
  }

  @action
  setMessage(value: string): void {
    this.message = value;
  }

  async send(): Promise<void> {
    if (this.isValid) {
      await this._deliver(this.sendData);
    } else {
      return Promise.reject(new Error('Please provide a valid to and from email address.'));
    }
  }

  async sendToSelf(): Promise<void> {
    if (!this.invoice.id) {
      await this.invoice.save();
    }
    const data = this.sendData;
    data.isPreview = true;
    data.toEmail = this.fromEmail;
    data.no_branding = false;
    data.invoiceUrl = this.invoice.publicUrl;
    if (this.isFromEmailValid) {
      return this._deliver(data);
    } else {
      return Promise.reject(new Error('Please provide a valid email address'));
    }
  }

  async _deliver(sendData: InvoiceEmailData): Promise<void> {
    this.isSending = true;
    try {
      return await sendInvoiceEmail(sendData);
    } finally {
      this.isSending = false;
    }
  }
}
