import React, { useState, useEffect } from 'react';
import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  useMergeRefs,
  useListNavigation,
  useHover,
  useTypeahead,
  useInteractions,
  useRole,
  useClick,
  useDismiss,
  safePolygon,
  FloatingPortal,
  useFloatingTree,
  useFloatingNodeId,
  useFloatingParentNodeId,
  FloatingNode,
  FloatingTree,
  FloatingFocusManager
} from '@floating-ui/react';
import './DropdownMenu.css';
import classNames from 'classnames';

interface MenuItemProps {
  label: string;
  disabled?: boolean;
  className?: string;
}

export const MenuItem = React.forwardRef<
  HTMLButtonElement,
  MenuItemProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ label, disabled, ...props }, ref) => {
  return (
    <button
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick && props.onClick(e);
      }}
      ref={ref}
      role="menuitem"
      className={props.className}
      disabled={disabled}>
      {label}
    </button>
  );
});

interface MenuProps {
  label?: string;
  nested?: boolean;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  inKebabMenu?: boolean;
}

export const MenuComponent = React.forwardRef<
  HTMLElement,
  MenuProps & React.HTMLProps<HTMLElement>
>(({ children, label, icon, inKebabMenu, ...props }, forwardedRef) => {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
  const [allowHover, setAllowHover] = React.useState(false);

  const listItemsRef = React.useRef<Array<HTMLButtonElement | null>>([]);
  const listContentRef = React.useRef(
    React.Children.map(children, (child) =>
      React.isValidElement(child) ? child.props.label : null
    ) as Array<string | null>
  );

  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const nested = parentId != null;

  const { x, y, strategy, refs, context } = useFloating<HTMLElement>({
    open,
    nodeId,
    onOpenChange: setOpen,
    placement: nested ? 'right-start' : 'bottom-start',
    middleware: [offset({ mainAxis: 5, alignmentAxis: nested ? -4 : 0 }), flip(), shift()],
    whileElementsMounted: autoUpdate
  });

  // Add interaction handlers to the reference element.
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useHover(context, {
      handleClose: safePolygon({
        blockPointerEvents: true
      }),
      enabled: nested && allowHover,
      delay: { open: 75 }
    }),
    useClick(context, {
      toggle: !nested || !allowHover,
      event: 'mousedown',
      ignoreMouse: nested
    }),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
    useListNavigation(context, {
      listRef: listItemsRef,
      activeIndex,
      nested,
      onNavigate: setActiveIndex
    }),
    useTypeahead(context, {
      listRef: listContentRef,
      onMatch: open ? setActiveIndex : undefined,
      activeIndex
    })
  ]);

  // Closes all menus when an item gets clicked anywhere in the tree.
  useEffect(() => {
    const handleTreeClick = () => {
      setOpen(false);
    };

    const onSubMenuOpen = (event: { nodeId: string; parentId: string }) => {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        setOpen(false);
      }
    };

    tree?.events.on('click', handleTreeClick);
    tree?.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree?.events.off('click', handleTreeClick);
      tree?.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId]);

  React.useEffect(() => {
    if (open) {
      tree?.events.emit('menuopen', {
        parentId,
        nodeId
      });
    }
  }, [tree, open, nodeId, parentId]);

  // Determine if "hover" logic can run based on the modality of input. This
  // prevents unwanted focus synchronization as menus open and close with
  // keyboard navigation and the cursor is resting on the menu.
  React.useEffect(() => {
    function onPointerMove({ pointerType }: PointerEvent) {
      if (pointerType !== 'touch') {
        setAllowHover(true);
      }
    }

    function onKeyDown() {
      setAllowHover(false);
    }

    window.addEventListener('pointermove', onPointerMove, {
      once: true,
      capture: true
    });
    window.addEventListener('keydown', onKeyDown, true);
    return () => {
      window.removeEventListener('pointermove', onPointerMove, {
        capture: true
      });
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, [allowHover]);

  const referenceRef = useMergeRefs([refs.setReference, forwardedRef]);
  const menuItemStyle = inKebabMenu ? 'btn MenuItem' : 'MenuItem';

  return (
    <FloatingNode id={nodeId}>
      <div
        ref={referenceRef}
        data-open={open ? '' : undefined}
        {...getReferenceProps({
          ...props,
          className: `${nested ? menuItemStyle : 'RootMenu'}`,
          onClick(event: React.MouseEvent<HTMLElement>) {
            event.stopPropagation();
            event.preventDefault();
          },
          ...(nested && {
            // Indicates this is a nested <Menu /> acting as a <MenuItem />.
            role: 'menuitem'
          })
        })}>
        {icon ? icon : label || ''}{' '}
        {nested && (
          <span aria-hidden style={{ marginLeft: 10 }}>
            ➔
          </span>
        )}
      </div>
      <FloatingPortal>
        {open && (
          <FloatingFocusManager
            context={context}
            // Prevent outside content interference.
            modal={!nested}
            // Only initially focus the root floating menu.
            initialFocus={nested ? -1 : 0}
            // Only return focus to the root menu's reference when menus close.
            returnFocus={!nested}
            // Allow touch screen readers to escape the modal root menu
            // without selecting anything.
            visuallyHiddenDismiss>
            <div
              ref={refs.setFloating}
              className="Menu"
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: 'max-content'
              }}
              {...getFloatingProps({
                // Pressing tab dismisses the menu due to the modal
                // focus management on the root menu.
                onKeyDown(event) {
                  if (event.key === 'Tab') {
                    setOpen(false);

                    if (event.shiftKey) {
                      event.preventDefault();
                    }
                  }
                }
              })}>
              {React.Children.map(
                children,
                (child, index) =>
                  React.isValidElement(child) &&
                  React.cloneElement(
                    child,
                    getItemProps({
                      tabIndex: activeIndex === index ? 0 : -1,
                      role: 'menuitem',
                      className: classNames('btn MenuItem', child.props.className),
                      ref(node: HTMLButtonElement) {
                        listItemsRef.current[index] = node;
                      },
                      onClick(event) {
                        child.props.onClick?.(event);
                        tree?.events.emit('click');
                      },
                      // Allow focus synchronization if the cursor did not move.
                      onMouseEnter() {
                        if (allowHover && open) {
                          setActiveIndex(index);
                        }
                      }
                    })
                  )
              )}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </FloatingNode>
  );
});

export const KebabIcon: React.FC<{ strokeWidth?: number }> = ({ strokeWidth = 1.5 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      stroke="currentColor"
      className="w-6 h-6">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
      />
    </svg>
  );
};

export const DropdownMenu = React.forwardRef<
  HTMLButtonElement,
  MenuProps & React.HTMLProps<HTMLButtonElement>
>((props, ref) => {
  const parentId = useFloatingParentNodeId();

  if (parentId == null) {
    return (
      <FloatingTree>
        <MenuComponent {...props} ref={ref} />
      </FloatingTree>
    );
  }

  return <MenuComponent {...props} ref={ref} />;
});

export const KebabMenu = React.forwardRef<
  HTMLButtonElement,
  MenuProps & React.HTMLProps<HTMLButtonElement>
>((props, ref) => {
  const parentId = useFloatingParentNodeId();

  if (parentId == null) {
    return (
      <FloatingTree>
        <MenuComponent
          icon={
            <div className="icon-wrapper">
              <KebabIcon />
            </div>
          }
          {...props}
          ref={ref}
        />
      </FloatingTree>
    );
  }

  return <MenuComponent {...props} ref={ref} />;
});
