import React, { useEffect } from 'react';

import { ISIntl } from 'src/i18n';
import { AppStore } from 'src/stores/AppStore';
import { isAbTestEnabled } from 'src/util/isAbTestEnabled';
import { BackButton } from './components/BackButton';
import { PlanIntervalSelector } from './components/PlanIntervalSelector';
import { TieredMatrixList } from './components/TieredMatrixList';
import { TierList } from './components/TierList';
import { useInterval } from './hooks/useInterval';
import { maybeShowAlertBanner } from './utils';
import { messages } from './utils/messages';

interface Props {
  store: AppStore;
  intl: ISIntl;
}

export const SubscriptionTieredV1 = ({ store, intl }: Props) => {
  const { user } = store;
  const { formatMessage, ft } = intl;

  const { selectedInterval, toggleInterval, shouldDisallowSwitch } = useInterval(null);

  useEffect(() => {
    maybeShowAlertBanner(store, selectedInterval, intl);
  }, []);

  const isFeatureComparisonAbtest =
    isAbTestEnabled(user.abTests, 'feature-comparison') && user.countryCode?.toLowerCase() === 'us';

  return (
    <div id="tailwind">
      <div
        className="relative mx-auto sm:py-24 px-4 sm:px-6 lg:px-8"
        style={{ maxWidth: '100rem' }}>
        <BackButton />
        <div className="sm:align-center sm:flex sm:flex-col">
          <div className="text-5xl font-bold tracking-tight text-gray-900 sm:text-center">
            <img
              data-testid="is-logo"
              className="h-24 sm:h-28 w-auto rounded"
              src="images/is-logo.png"
              alt="Invoice Simple Logo"
            />
            <h1
              data-testid="upgrade-now-title"
              className="my-4 text-gray-900"
              style={{ fontSize: '3rem' }}>
              {ft(messages.upgrade)}
            </h1>
          </div>
          <p
            data-testid="upgrade-now-subtitle"
            className="text-2xl text-gray-700 sm:text-center mb-8">
            {ft(messages.subtitle)}
          </p>
          <PlanIntervalSelector
            selectedInterval={selectedInterval}
            onToggleInterval={toggleInterval}
            formatMessage={formatMessage}
            isHidden={false}
          />
        </div>
        {isFeatureComparisonAbtest ? (
          <TieredMatrixList
            store={store}
            intl={intl}
            selectedInterval={selectedInterval}
            shouldDisallowSwitch={shouldDisallowSwitch}
          />
        ) : (
          <TierList
            store={store}
            intl={intl}
            selectedInterval={selectedInterval}
            shouldDisallowSwitch={shouldDisallowSwitch}
          />
        )}
      </div>
    </div>
  );
};
